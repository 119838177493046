export const INITIALISE_REQUEST_RESPONSIBILITY = 'initialise_request_responsibility';
export const SELECT_OU_TYPE_FOR_REQUEST = 'select_ou_type_for_request';
export const SELECT_OU_FOR_REQUEST = 'select_ou_for_request';
export const POSSIBLE_POSITIONS_FOR_REQUEST_FETCHED = 'possible_positions_for_request_fetched';
export const CHANGE_STARTDATE_FOR_REQUEST = 'change_startDate_for_request';
export const CHANGE_POSITION_FOR_REQUEST = 'change_position_for_request';
export const CHANGE_ROLE_DESCRIPTION_FOR_REQUEST = 'change_role_description_for_request';
export const REQUEST_RESPONSIBILITY = 'request_responsibility';
export const REQUEST_RESPONSIBILITY_SUCCEEDED = 'request_responsibility_succeeded';
export const REQUEST_RESPONSIBILITY_FAILED = 'request_responsibility_failed';
export const APPROVE_REQUEST = 'approve_request';
export const APPROVE_REQUEST_SUCCEEDED = 'approve_request_succeeded';
export const APPROVE_REQUEST_FAILED = 'approve_request_failed';
export const DECLINE_REQUEST = 'decline_request';
export const INITIALISE_HANDLE_REQUEST = 'initialise_handle_request';
export const REQUEST_FETCHED = 'request_fetched';
export const FETCH_REQUEST_FAILED = 'fetch_request_failed';
export const ADD_REQUEST_TO_REGISTRATION = 'add_request_to_registration';
export const TRY_CREATING_RESPONSIBILITY_SUCCEEDED = 'try_creating_responsibility_succeeded';
export const TRY_CREATING_RESPONSIBILITY_FAILED = 'try_creating_responsibility_failed';
export const GO_FROM_CONFIRM_REQUEST_TO_TEAM = 'go_from_confirm_request_to_team';

export const initialiseRequestResponsibility = (urlParameters) => ({
  type: INITIALISE_REQUEST_RESPONSIBILITY,
  payload: urlParameters,
});

export const selectOuTypeForRequest = (ouType) => ({
  type: SELECT_OU_TYPE_FOR_REQUEST,
  payload: ouType,
});

export const selectOuForRequest = (ou) => ({
  type: SELECT_OU_FOR_REQUEST,
  payload: ou,
});

export const possiblePositionsForRequestFetched = (possiblePositions) => ({
  type: POSSIBLE_POSITIONS_FOR_REQUEST_FETCHED,
  payload: possiblePositions,
});

export const changeStartDateForRequest = (newStartDate) => ({
  type: CHANGE_STARTDATE_FOR_REQUEST,
  payload: newStartDate,
});

export const changePositionForRequest = (newPosition) => ({
  type: CHANGE_POSITION_FOR_REQUEST,
  payload: newPosition,
});

export const changeRoleDescriptionForRequest = (roleDescription) => ({
  type: CHANGE_ROLE_DESCRIPTION_FOR_REQUEST,
  payload: roleDescription,
});

export const requestResponsibility = () => ({
  type: REQUEST_RESPONSIBILITY,
});

export const addRequestToRegistration = () => ({
  type: ADD_REQUEST_TO_REGISTRATION,
});

export const requestResponsibilitySucceeded = (request, ou, apiResponse) => ({
  type: REQUEST_RESPONSIBILITY_SUCCEEDED,
  payload: { request, ou, apiResponse },
});

export const requestResponsibilityFailed = (error) => ({
  type: REQUEST_RESPONSIBILITY_FAILED,
  payload: error,
});

export const approveRequest = (requestPermalink) => ({
  type: APPROVE_REQUEST,
  payload: {
    requestPermalink,
  },
});

export const approveRequestSucceeded = (responseBody, requestPermalink) => ({
  type: APPROVE_REQUEST_SUCCEEDED,
  payload: {
    responsibility: responseBody,
    requestPermalink,
  },
});

export const approveRequestFailed = (error, requestPermalink) => ({
  type: APPROVE_REQUEST_FAILED,
  payload: {
    error,
    requestPermalink,
  },
});

export const tryCreatingResponsibilitySucceeded = (responsibility) => ({
  type: TRY_CREATING_RESPONSIBILITY_SUCCEEDED,
  payload: {
    responsibility,
  },
});

export const tryCreatingResponsibilityFailed = (error, request) => ({
  type: TRY_CREATING_RESPONSIBILITY_FAILED,
  payload: {
    error,
    request,
  },
});

export const declineRequest = (requestPermalink) => ({
  type: DECLINE_REQUEST,
  payload: {
    requestPermalink,
  },
});

export const initialiseHandleRequest = (urlParameters) => ({
  type: INITIALISE_HANDLE_REQUEST,
  payload: urlParameters,
});

export const requestFetched = (request) => ({
  type: REQUEST_FETCHED,
  payload: request,
});

export const fetchRequestFailed = (error) => ({
  type: FETCH_REQUEST_FAILED,
  payload: error,
});

export const goFromConfirmRequestToTeam = () => ({
  type: GO_FROM_CONFIRM_REQUEST_TO_TEAM,
});

export const WORKGROUP_OF_SELECTED_OU_FETCHED = 'WORKGROUP_OF_SELECTED_OU_FETCHED';
export const workgroupOfSelectedOuFetched = (workgroup) => ({
  type: WORKGROUP_OF_SELECTED_OU_FETCHED,
  payload: workgroup,
});
