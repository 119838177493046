import { validatePasswordStrength } from 'Js/module/personUtils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'ReduxLoop/notifications/notificationActions';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export class MyChangePassword {
  constructor($ngRedux, $scope) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.lockIcon = require('Img/lock.svg');
    this.validatePasswordStrength = validatePasswordStrength;
  }

  passwordChanged(e, password) {
    this.password = password;
    this.manageDuplicatePasswordErrors();
  }

  oldPasswordChanged(e, password) {
    this.oldPassword = password;
    this.oldPasswordErrors = [];
  }

  repeatPasswordChanged(e, password) {
    this.repeatPassword = password;
    if (this.password && this.passwordForm.formGroups.huidig_wachtwoord.valid) {
      this.manageDuplicatePasswordErrors();
    }
  }

  manageDuplicatePasswordErrors() {
    this.repeatPasswordErrors = [];
    if (
      this.passwordForm.formGroups.huidig_wachtwoord.valid &&
      this.repeatPassword &&
      this.password !== this.repeatPassword
    ) {
      this.repeatPasswordErrors.push({
        code: 'passwords.do.not.match',
        message: 'De wachtwoorden komen niet overeen.',
      });
    }
  }

  async save() {
    this.saveAttempted = true;
    if (!this.passwordForm.valid || this.repeatPasswordErrors.length > 0) {
      return;
    }
    try {
      this.loading = true;
      await sriClient.post('/persons/changepassword', {
        person: this.resolve.personHref,
        oldPassword: this.oldPassword,
        newPassword: this.password,
      });
      this.$ngRedux.dispatch(addSuccessNotification('Jouw wachtwoord is gewijzigd.'));
      this.close();
    } catch (apiError) {
      if (apiError instanceof SriClientError && apiError.body) {
        this.oldPasswordErrors = [];
        apiError.body.errors.forEach((error) => {
          if (error.code === 'invalid.password') {
            this.oldPasswordErrors.push({
              code: 'wrong.password',
              message: 'Het huidige wachtwoord is niet correct.',
            });
          }
        });
      } else {
        console.error('Error when changing password', apiError);
        this.$ngRedux.dispatch(
          addErrorNotification(
            'Er deed zich een onbekende fout voor, probeer het eventueel opnieuw.'
          )
        );
      }
    } finally {
      this.loading = false;
      this.$scope.$apply();
    }
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
