const { settings } = require('Js/config/settings');

/**
 * This defines your own module for this application. If you have other dependencies on other modules, add them here.
 *
 * Remark that we get the modulename from the window.vskoConfiguration object (see config/constants.js),
 * which makes it easier to update the modulename in one place !
 *
 * This configuration will be added to the application module as a constant also, so it can be used 'the angular way'
 * later on in your modules, controllers, directives (both the OAuth module and the RemoteErrorLogger
 * need some info from the 'configuration' ! )
 */

const standaloneCookiesModalScript = document.createElement('script');
standaloneCookiesModalScript.id = 'standaloneCookiesModal';
standaloneCookiesModalScript.src = settings.cookies.standaloneModalWidgetEndpoint;
standaloneCookiesModalScript.type = 'module';
document.head.appendChild(standaloneCookiesModalScript);

// Node Modules
require('angular-sanitize');
require('@uirouter/angularjs');
require('angulartics');
require('angulartics-google-analytics');

require('ng-tags-input/build/ng-tags-input.min');
require('ng-tags-input/build/ng-tags-input.min.css');
require('angular-ui-bootstrap');
require('ng-redux');

// VSKO Modules
// require('vsko-angular-oauth').client;
// require('@kathondvla/sri-client/ng-sri-client');
require('@kathondvla/angular-shared-utils');

require('angular-cookies');

const dummyConfig = {
  name: 'sriClient',
  baseUrl: 'itIsAPittyThatWeStillHaveToConfigurAnNgSriClient',
};

// Module loading
// require('./moduleLoader.js');
require('../myComponents');
require('../module/services');
// require('@kathondvla/angular-shared-components/kovNotificationService');
// require('@kathondvla/react-shared-components/src/index.js');

// create the module
const requires = [
  'ng',
  'ui.router',
  'ui.bootstrap',
  require('@kathondvla/sri-client/ng-sri-client')(dummyConfig),
  'angulartics',
  'myComponents',
  'myServices',
  'onEventModule',
  'ngRedux',
  require('@kathondvla/angular-shared-components/kovHeaderBar'),
  require('@kathondvla/angular-shared-components/kovFooterBar'),
  require('@kathondvla/angular-shared-components/kovDatepicker'),
  require('@kathondvla/angular-shared-components/kovResourcePicker'),
  require('@kathondvla/angular-shared-components/kovButton'),
  require('@kathondvla/angular-shared-components/kovTitleWithIcon'),
  require('@kathondvla/angular-shared-components/kovCheckbox'),
  require('@kathondvla/angular-shared-components/kovVerticalTabs'),
  require('@kathondvla/angular-shared-components/kovForms'),
  require('@kathondvla/angular-shared-components/kovForms/kovAddressForm'),
  require('@kathondvla/angular-shared-components/kovSpinner'),
  require('@kathondvla/angular-shared-components/kovNotifications'),
  require('@kathondvla/angular-shared-components/kovImageEditor'),
  'ngTagsInput',
  'ngCookies',
];

console.log('sentry enabled? ', settings.sentry.enabled);
const app = angular.module('Mijn2', requires);

module.exports = app;
