import { loop, Cmd } from 'redux-loop';
import { Map } from 'immutable';
import { getStartOfSchoolYearIncludingSummerGap } from '@kathondvla/sri-client/date-utils';
import * as COMMANDS from 'ReduxLoop/ouManager/studyProgrammes/studyProgrammesCommands';
import { storeResource } from 'ReduxLoop/utils';
import * as ACTIONS from 'ReduxLoop/ouManager/studyProgrammes/studyProgrammesActions';
import { createSchoolStudyProgrammeRelation } from 'ReduxLoop/utils/apiResourceFactory';
import { arrayToMap } from 'ReduxLoop/index';

const inititalState = {
  possibleStudyProgrammes: null,
  studyProgrammes: Map(),
  selectedGrade: 'all',
};

export const studyProgrammesReducer = (state = inititalState, action) => {
  let updatedState;

  switch (action.type) {
    case ACTIONS.FETCH_POSSIBLE_STUDYPROGRAMMES:
      if (state.possibleStudyProgrammes) {
        return state;
      }
      return loop(
        state,
        Cmd.run(COMMANDS.getPossibleStudyProgrammes, {
          successActionCreator: ACTIONS.possibleStudyProgrammesFetched,
        })
      );

    case ACTIONS.POSSIBLE_STUDYPROGRAMMES_FETCHED: {
      return { ...state, possibleStudyProgrammes: Map(arrayToMap(action.payload)) };
    }

    case ACTIONS.FETCH_STUDYPROGRAMMES:
      return loop(
        state,
        Cmd.run(COMMANDS.getStudyProgrammes, {
          args: [action.payload],
          successActionCreator: ACTIONS.studyProgrammesFetched,
        })
      );

    case ACTIONS.STUDYPROGRAMMES_FETCHED: {
      return { ...state, studyProgrammes: Map(arrayToMap(action.payload)) };
    }

    case ACTIONS.ADD_STUDYPROGRAMME_TO_SCHOOL: {
      let adjoiningRelation = null;
      const studyProgrammeRelations = state.studyProgrammes.toJS();
      Object.keys(studyProgrammeRelations).forEach((relKey) => {
        const rel = studyProgrammeRelations[relKey];
        if (
          rel.studyProgramme.href === action.payload.studyProgrammePermalink &&
          rel.endDate &&
          rel.endDate <= action.payload.startDate
        ) {
          adjoiningRelation = rel;
        }
      });
      if (adjoiningRelation) {
        console.log('REOPENING EXISTING STUDY PROGRAMME RELATION');
        const newStudyProgrammeRelation = { ...adjoiningRelation, endDate: null };
        return loop(
          {
            ...state,
            studyProgrammes: state.studyProgrammes.set(
              action.payload.relationPermalink,
              newStudyProgrammeRelation
            ),
          },
          Cmd.run(storeResource, {
            args: [newStudyProgrammeRelation],
          })
        );
      }
      const studyProgrammeRelation = createSchoolStudyProgrammeRelation(
        action.payload.ouPermalink,
        action.payload.studyProgrammePermalink,
        action.payload.startDate
      );

      updatedState = {
        studyProgrammes: state.studyProgrammes.set(
          studyProgrammeRelation.$$meta.permalink,
          studyProgrammeRelation
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(COMMANDS.addStudyProgrammeToSchool, {
          args: [studyProgrammeRelation],
        })
      );
    }

    case ACTIONS.DELETE_STUDYPROGRAMME_FROM_SCHOOL: {
      const newEndDate = getStartOfSchoolYearIncludingSummerGap();
      if (newEndDate <= action.payload.startDate) {
        updatedState = {
          ...state,
          studyProgrammes: state.studyProgrammes.delete(action.payload.relationPermalink),
        };

        return loop(
          updatedState,
          Cmd.run(COMMANDS.deleteStudyProgrammeFromSchool, {
            args: [action.payload.relationPermalink],
          })
        );
      }
      const studyProgrammeRelation = state.studyProgrammes.get(action.payload.relationPermalink);
      const newStudyProgrammeRelation = { ...studyProgrammeRelation, endDate: newEndDate };
      return loop(
        {
          ...state,
          studyProgrammes: state.studyProgrammes.set(
            action.payload.relationPermalink,
            newStudyProgrammeRelation
          ),
        },
        Cmd.run(storeResource, {
          args: [newStudyProgrammeRelation],
        })
      );
    }

    case ACTIONS.CHANGE_STUDYPROGRAMME_GRADE_FILTER: {
      return {
        ...state,
        selectedGrade: action.payload,
      };
    }

    default:
      return state;
  }
};
