import { myVakkennieuwsbriefSection } from './myVakkennieuwsbriefSection';

require('./myVakkennieuwsbriefSection.scss');

export const myVakkennieuwsbriefSectionComponent = {
  template: require('./myVakkennieuwsbriefSection.html'),
  controllerAs: 'ctrl',
  controller: myVakkennieuwsbriefSection,
  bindings: {
    sOptions: '<',
  },
};
