import * as ACTION_TYPES from '../../constants/actionTypes';

export const getNewsletterUnsubscribeReasons = () => ({
  type: ACTION_TYPES.NEWSLETTER_GET_UNSUBSCRIBE_REASONS_FROM_EXTERNAL_LINK,
});

export const newsletterUnsubcribeReasonsFecthed = (newsletterUnsubscriptionReasons) => ({
  type: ACTION_TYPES.NEWSLETTER_OPTIONS_FETCHED_FROM_EXTERNAL_LINK,
  payload: newsletterUnsubscriptionReasons,
});

export const newsletterUnsubscribe = (secret) => ({
  type: ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_FROM_EXTERNAL_LINK,
  payload: secret,
});

export const newsletterUnsubscriptionFailed = (error) => ({
  type: ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_FAILED_FROM_EXTERNAL_LINK,
  payload: {
    error,
  },
});

export const newsletterUnsubscriptionSucceeded = () => ({
  type: ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_SUCCEEDED_FROM_EXTERNAL_LINK,
});

export const newsletterSetUnsubscribeReasons = (secret, predefinedReasons, customReason) => ({
  type: ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_FROM_EXTERNAL_LINK,
  payload: { secret, predefinedReasons, customReason },
});

export const newsletterSetUnsubscribeReasonSucceeded = () => ({
  type: ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_SUCCEEDED_FROM_EXTERNAL_LINK,
});

export const newsletterSetUnsubscribeReasonFailed = (error) => ({
  type: ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_FAILED_FROM_EXTERNAL_LINK,
  payload: {
    error,
  },
});
