const piwikAnalyticsActions = require('../reduxLoop/piwikAnalytics/piwikAnalyticsActions');

module.exports = function ($stateProvider, $urlRouterProvider) {
  const noEmptyParams = (params) => {
    const obj = {};
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        obj[key] = params[key];
      }
    });
    return obj;
  };

  $urlRouterProvider.otherwise('/');

  const routeStates = [
    { name: 'home', url: '/', component: 'myHome' },
    { name: 'profile', url: '/profiel?key,back_url,wijzig-profiel-foto', component: 'myProfile' },
    { name: 'schoolSelecteer', url: '/:type/selecteer', component: 'myHomeOuSelector' },
    { name: 'createTeam', url: '/registreer-team', component: 'myCreateTeam' },
    {
      name: 'school',
      url: '/{type:school|bestuur|clb|leersteuncentrum|organisatie|team|lerarennetwerk|instelling}/{schoolId:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}}',
      component: 'mySchoolManager',
    },
    {
      name: 'school.team',
      url: '/team?filter',
      component: 'mySchoolManagerTeam',
      params: {
        filter: {
          dynamic: true,
        },
      },
    },
    { name: 'school.group', url: '/groepen', component: 'mySchoolManagerGroups' },
    {
      name: 'school.group.info',
      url: '/{groupId}',
      component: 'mySchoolManagerGroupInfo',
      resolve: {
        params: ($stateParams) => {
          'ngInject';

          return noEmptyParams($stateParams);
        },
        sGroup: (groupService, params) => {
          'ngInject';

          return groupService.getGroup(params.groupId);
        },
      },
    },
    {
      name: 'school.studyprogrammes',
      url: '/studierichtingen',
      component: 'mySchoolManagerStudyProgrammes',
    },
    { name: 'school.klassen', url: '/klassen', component: 'mySchoolManagerKlassen' },
    { name: 'school.oudetails', url: '/teamdetails', component: 'mySchoolManagerOuDetails' },
    { name: 'school.workgrouptools', url: '/teamtools', component: 'mySchoolManagerOuTools' },
    {
      name: 'school.contactdetails',
      url: '/contactgegevens',
      component: 'mySchoolManagerContactDetails',
    },
    {
      name: 'helpdesk-search',
      url: '/zoeken?filter,type',
      component: 'myHelpdeskSearch',
      params: {
        filter: {
          dynamic: true,
        },
        type: {
          dynamic: true,
        },
      },
    },
    {
      name: 'ou-admin',
      url: '/admin?nr',
      component: 'myOuAdminForm',
      params: {
        nr: {
          dynamic: true,
        },
      },
    },
    {
      name: 'logIn',
      url: '/aanmelden?key&functie&ou&ous&email&username&start',
      component: 'myAanmelden',
    },
    {
      name: 'register',
      url: '/registreren?redirect_url&client_name',
      component: 'myRegistreren',
    },
    {
      name: 'showDuplicate',
      url: '/dubbel-gededecteerd',
      component: 'myRegistrerenShowDuplicate',
    },
    {
      name: 'acceptTerms',
      url: '/accepteer-voorwaarden',
      component: 'myRegistrerenAcceptTerms',
    },
    {
      name: 'acceptInvitation',
      url: '/accepteer-uitnodiging',
      component: 'myConfirmInvitation',
    },
    {
      name: 'selectOuForInit',
      url: '/accepteer-uitnodiging/selecteer',
      component: 'myConfirmInvitationOuSelector',
    },
    {
      name: 'contactHelpdesk',
      url: '/contacteer-helpdesk/:type?organisatie-type&lidwoord',
      component: 'myContactHelpdesk',
    },
    {
      name: 'mailSendConfirmed',
      url: '/mail-verzonden?type&ou&functie&geen-beheerders',
      component: 'myRegisterMailSend',
    },
    {
      name: 'activateAccount',
      url: '/activeer-account?key&type&ou&ouType&functie&position_key&redirect_url',
      component: 'myRegisterActivate',
    },
    {
      name: 'linkAccounts',
      url: '/link-accounts?userinfo&username',
      component: 'myLinkAccounts',
    },
    {
      name: 'askPasswordReset',
      url: '/vraag-nieuw-wachtwoord?email&redirect_url',
      component: 'myAskPasswordResetLink',
    },
    {
      name: 'askPasswordResetSent',
      url: '/wachtwoord-reset-link-verstuurd',
      component: 'myAskPasswordResetLinkSent',
    },
    {
      name: 'changePasswordFromLink',
      url: '/wijzig-wachtwoord-met-link/{token:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}}?redirect_url',
      component: 'myChangePasswordFromLink',
    },
    {
      name: 'changePasswordFromLinkSuccess',
      url: '/wijzig-wachtwoord-gelukt?redirect_url',
      component: 'myChangePasswordFromLinkSuccess',
    },
    {
      name: 'newsletterPreferences',
      url: '/nieuwsbrieven',
      component: 'myNewsletterPreferences',
    },
    {
      name: 'requestResponsibility',
      url: '/aanvraag-team?type&ou&start&functie',
      component: 'myRequestResponsibility',
    },
    {
      name: 'handleRequestResponsibility',
      url: '/aanvraag-afhandelen?key&actie',
      component: 'myConfirmRequest',
    },
    {
      name: 'unsubscribeNewsletter',
      url: '/nieuwsbrief-uitschrijven?secret',
      component: 'myNewsletterUnsubscription',
    },
  ];

  // Dispatching 'view_page' piwik analytics event on page enter.
  routeStates.forEach((state) => {
    (state.onEnter = ($ngRedux) => {
      'ngInject'
      // This timeout is here because document is not entirely ready when onEnter event executes, so some data...
      // ... is not ready yet for Piwik and we need to wait for it, i.e.: document.location.pathname.
      setTimeout(() => {
        $ngRedux.dispatch(piwikAnalyticsActions.trackPiwikEvent('view_page'));
      }, 30);
    }),
      $stateProvider.state(state);
  });
};
