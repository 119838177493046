import { initPiwikAnalytics, disablePiwikAnalytics } from '../../../js/app/piwikManager';
import { analyticCookiesAccepted } from 'ReduxLoop/utils/cookiesUtils';

require('./appRoot.scss');

export class AppRoot {
  constructor(settings, $ngRedux) {
    'ngInject';

    this.settings = settings;
    this.$ngRedux = $ngRedux;
  }

  $onInit = async () => {
    if (analyticCookiesAccepted()) {
      initPiwikAnalytics(this.$ngRedux);
    }
  };

  setCookies = (cookies) => {
    Object.keys(cookies).forEach((key, index) => console.log(`cookie ${index}`, key, cookies[key]));

    if (!analyticCookiesAccepted()) {
      disablePiwikAnalytics(this.$ngRedux);
    } else {
      initPiwikAnalytics(this.$ngRedux);
    }
  };
}
