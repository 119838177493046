import { sriClient } from 'ReduxLoop/utils/apiConfig';

export const getPossibleStudyProgrammes = async () => {
  const studyProgrammeTypes = await sriClient.getAll('/sam/commons/studyprogrammes/types', {
    codeIn: 'BASISOPTIE,CUSTOM,STUDIERICHTING',
  });

  const gradeHrefs = {
    1: '/sam/commons/studyprogrammegroups/b1a7f179-6e7c-497b-be9a-7ca30e4e8c65',
    2: '/sam/commons/studyprogrammegroups/2ea33583-8819-4137-bb4e-c3efd9e91346',
    3: '/sam/commons/studyprogrammegroups/954369e2-99c1-4ef4-be19-f5496956a270',
  };

  const setStudyProgrameGrade = (studyPrograme) => {
    const grade = Object.keys(gradeHrefs).find((g) =>
      studyPrograme.studyProgrammeGroups.some((group) =>
        group.studyProgrammeGroup.href.includes(gradeHrefs[g])
      )
    );

    return grade ? grade.toString() : null;
  };

  const studyProgrammes = await sriClient.getAll('/sam/commons/studyprogrammes', {
    expand: 'FULL',
    type: studyProgrammeTypes.map((studyProgrammeType) => studyProgrammeType.$$meta.permalink),
  });

  return studyProgrammes.map((studyProgramme) => {
    return {
      ...studyProgramme,
      grade: setStudyProgrameGrade(studyProgramme),
    };
  });
};

export const getStudyProgrammes = async (ouPermalink) => {
  return sriClient.getAll('/sam/educationalprogrammedetails', {
    organisationalUnit: ouPermalink,
    hasStudyProgramme: true,
    expand: 'FULL',
  });
};

export async function addStudyProgrammeToSchool(studyProgrammeRelation) {
  return sriClient.put(
    `/sam/educationalprogrammedetails/${studyProgrammeRelation.key}`,
    studyProgrammeRelation
  );
}

export async function deleteStudyProgrammeFromSchool(studyProgrammeRelationPermalink) {
  return sriClient.delete(studyProgrammeRelationPermalink);
}
