import { AddEditGroupModal } from './addEditGroupModal';

export const myAddEditGroupModalComponent = {
  template: require('./addEditGroupModal.html'),
  controllerAs: 'ctrl',
  controller: AddEditGroupModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
