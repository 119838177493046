export class Popup {
  constructor($scope, $sce) {
    'ngInject';

    this.$scope = $scope;
    this.$sce = $sce;
  }

  $onInit() {
    this.title = this.resolve.title;
    this.body = this.$sce.trustAsHtml(this.resolve.body);
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
