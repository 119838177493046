const { MySearchBar } = require('./mySearchBar');

require('./mySearchBar.scss');

export const mySearchBarComponent = {
  template: require('./mySearchBar.html'),
  controllerAs: 'ctrl',
  controller: MySearchBar,
  bindings: {
    sSearchValue: '<',
    sPlaceholder: '@',
    sSize: '@',
  },
};
