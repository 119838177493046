import {
  GROUP_MANAGER,
  VAKGROEPEN_TAB,
  STUDYPROGRAMMES_TAB,
  CLASSES_TAB,
  READ_WRITE,
  STRATEGIC_PURCHASER,
  OPERATIONEEL_INKOPER,
  VOORZITTER,
  LID,
} from 'ReduxLoop/constants';
import {
  getPermittedTabs,
  getCurrentUserRole,
  getCurrentUserManagement,
  OU_TYPE_CONFIG,
} from 'ReduxLoop/appConfig';
import { buildResults, getSearchResults } from 'ReduxLoop/helpDesk/helpdeskSearchModel';
import { getUserInfo, getUserTabPermission } from 'ReduxLoop/user/userModel';
import { getProfileInfo } from 'ReduxLoop/profile/profileModel';
import { getRequestResponsibilityViewModel } from 'ReduxLoop/requestResponsibility/requestResponsibilityModel';
import { getNewsletterPreferenceModel } from 'ReduxLoop/newsletters/preferences/newsletterPreferencesModel';
import { getNewsletterUnsubscriptionModel } from 'ReduxLoop/newsletters/unsubscription/newsletterUnsubscriptionModel';
import { getCreateTeamWizardModel } from 'ReduxLoop/ouManager/createTeamWizard/createTeamWizardModel';
import { getShowCreateInvitationLink } from './ouManager/team/teamModel';
import {
  getTeam,
  getGroups,
  getGroupInfoTabs,
  getSelectedGroupInfo,
  getCoordinatorPosition,
  getPossibleStudyProgrammes,
  getStudyProgrammeInfo,
  getCampusClasses,
  getDefaultPosition,
  calculateDelegationCandidates,
  getCurrentOrganisationalUnit,
  getOuDetails,
} from './ouManager/schoolManagerModel';
import { getContactDetailsModel } from './ouManager/contactDetails/contactDetailsModel';

export const mapToArray = (map) => {
  if (!map) {
    return [];
  }
  const array = [];
  Object.keys(map).forEach((key) => array.push(map[key]));
  return array;
};

const getPossiblePositions = (state, ouTypeConfigKey) => {
  if (
    !state.userState.positions ||
    state.userState.positions.size === 0 ||
    !state.schoolManager.currentOrganisationalUnit
  ) {
    return [];
  }
  const ouTypeConfig = OU_TYPE_CONFIG[ouTypeConfigKey];

  let possiblePositions = state.teamTab.possiblePositions
    .map((entry) => {
      const position = state.userState.positions.get(entry.href);
      [position.definition] = position.definitions.filter((definition) => {
        return definition.applicableIn.ouTypes.some(
          (ouType) => ouType === state.schoolManager.currentOrganisationalUnit.type
        );
      });
      return position;
    })
    .filter((position) => position.definition.positionClass !== 'DELEGATION_ROLE');
  const mgmt = state.vm.user.manages[ouTypeConfigKey].filter(
    (m) => m.ouKey === state.schoolManager.currentOrganisationalUnit.key
  )[0];
  if (mgmt && mgmt.role === STRATEGIC_PURCHASER) {
    possiblePositions = possiblePositions.filter(
      (position) => position.$$meta.permalink === OPERATIONEEL_INKOPER
    );
  } else if (!state.userState.isSuperAdmin && ouTypeConfig.excludePositions) {
    possiblePositions = possiblePositions.filter(
      (position) =>
        !ouTypeConfig.excludePositions.some(
          (positionToExclude) => position.$$meta.permalink === positionToExclude
        )
    );
  }
  if (
    state.workgroup.teamMetaInfo &&
    ouTypeConfigKey === 'TEAM' &&
    state.workgroup.teamMetaInfo.isCreatedByLoket
  ) {
    possiblePositions = possiblePositions.filter(
      (pos) => pos.$$meta.permalink === VOORZITTER || pos.$$meta.permalink === LID
    );
  }
  possiblePositions.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  if (ouTypeConfig.emptyPosition && (!mgmt || mgmt.role !== STRATEGIC_PURCHASER)) {
    possiblePositions.push({
      $$meta: {},
      name: ouTypeConfig.emptyPosition,
      positionClass: {
        sortOrder: 5,
      },
    });
    if (ouTypeConfig.emptyPosition.match(/^[a-zA-Z]/)) {
      possiblePositions.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    }
  }
  return possiblePositions;
};

const getSchoolManagementTabs = (state, userInfo) => {
  let tabs = [];
  const currentUserRole = getCurrentUserRole(
    state.schoolManager.currentOrganisationalUnit,
    userInfo
  );

  if (state.schoolManager.currentOrganisationalUnit) {
    tabs = getPermittedTabs(state.schoolManager.currentOrganisationalUnit.type, currentUserRole);

    if (state.schoolManager.selectedTab) {
      tabs.forEach((tab) => {
        tab.active = tab.state === state.schoolManager.selectedTab;
      });
    }
  }

  return tabs;
};

export const buildViewModel = function (state) {
  let ouTypeConfigKey = null;
  if (state.schoolManager.currentOrganisationalUnit) {
    ouTypeConfigKey = OU_TYPE_CONFIG[state.schoolManager.currentOrganisationalUnit.type]
      ? state.schoolManager.currentOrganisationalUnit.type
      : Object.keys(OU_TYPE_CONFIG).filter(
          (configKey) =>
            OU_TYPE_CONFIG[configKey].types &&
            OU_TYPE_CONFIG[configKey].types.some(
              (type) => type === state.schoolManager.currentOrganisationalUnit.type
            )
        )[0];
  }

  const campusesInfo = getCampusClasses(state);
  const groupsInfo = getGroups(state);
  const userInfo = getUserInfo(state);

  const studyProgrammesInfo = getStudyProgrammeInfo(state);
  const possibleStudyProgrammes = getPossibleStudyProgrammes(state);

  const currentUserRole = getCurrentUserManagement(
    state.schoolManager.currentOrganisationalUnit,
    userInfo
  );
  let currentUserGroups = groupsInfo;
  if (currentUserRole.role === GROUP_MANAGER) {
    currentUserGroups = groupsInfo.filter((group) =>
      currentUserRole.management.groups.some(
        (userGroup) => userGroup.ouPermalink === group.permalink
      )
    );
  }
  const permittedTabs = getSchoolManagementTabs(state, userInfo);

  const teamInfo = getTeam(state, currentUserRole);
  calculateDelegationCandidates(state, teamInfo);

  // const teamTabPermission = getUserTabPermission(TEAM_TAB, currentUserRole.role);
  const groupsTabPermission = getUserTabPermission(VAKGROEPEN_TAB, currentUserRole.role);
  const classesTabPermission = getUserTabPermission(CLASSES_TAB, currentUserRole.role);
  const studyProgrammesTabPermission = getUserTabPermission(
    STUDYPROGRAMMES_TAB,
    currentUserRole.role
  );

  const searchResultsObj = getSearchResults(state.helpdesk);
  buildResults(searchResultsObj.results, searchResultsObj.words);

  // const newsletterPreferences = getNewsletterPreferences(state);

  return {
    user: userInfo,
    schoolManagementTabs: permittedTabs,
    notifications: mapToArray(state.notifications.notificationList.toJS()),
    profile: getProfileInfo(state),
    newsletters: getNewsletterPreferenceModel(state),
    newsletterUnsubscription: getNewsletterUnsubscriptionModel(state),
    schoolManager: {
      currentOU: getCurrentOrganisationalUnit(state, currentUserRole, ouTypeConfigKey),
      team: teamInfo.members,
      groups: currentUserGroups,
      campusClasses: campusesInfo,
      details: getOuDetails(state),
    },
    teamTab: {
      filterValue: state.teamTab.filterValue,
      members: teamInfo.filteredMembers,
      possiblePositions: getPossiblePositions(state, ouTypeConfigKey),
      defaultPosition: getDefaultPosition(state.schoolManager.currentOrganisationalUnit),
      delegations: teamInfo.delegations,
      showCreateInvitationLink: getShowCreateInvitationLink(state, currentUserRole),
    },
    groupsTab: {
      loadingGroupInfo: state.groupsTab.loadingGroupInfo,
      coordinatorPosition: getCoordinatorPosition(state),
      selectedGroup: getSelectedGroupInfo(state, teamInfo.members),
      showDeletedGroups: state.groupsTab.showDeletedGroups,
      groupInfoTabs: getGroupInfoTabs(state, currentUserGroups, currentUserRole),
      candidateMembers: teamInfo.members.filter((member) => member.type === 'RESPONSIBILITY'),
      userCanCreateGroups: groupsTabPermission ? groupsTabPermission.role !== GROUP_MANAGER : false,
      filter: state.groupsTab.filter,
    },
    classesTab: {
      filter: state.classesTab.filter,
      userCanEditClasses: classesTabPermission
        ? classesTabPermission.permission === READ_WRITE
        : false,
    },
    studyProgrammesTab: {
      possibleStudyProgrammes,
      studyProgrammes: studyProgrammesInfo,
      selectedGrade: state.studyProgrammesTab.selectedGrade,
      userCanUpdateStudyProgrammes: studyProgrammesTabPermission
        ? studyProgrammesTabPermission.permission === READ_WRITE
        : false,
    },
    contactDetailsTab: getContactDetailsModel(state),
    helpdesk: {
      searchValue: state.helpdesk.searchValue,
      ouType: state.helpdesk.ouType,
      results: searchResultsObj.results,
      loading: searchResultsObj.loading || state.helpdesk.searchingForInstitutionNumber,
      isMore: searchResultsObj.isMore,
    },
    requestResponsibility: getRequestResponsibilityViewModel(state),
    createTeamWizard: getCreateTeamWizardModel(state),
  };
};
