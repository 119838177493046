import { DeleteModal } from './myDeleteModal';

require('./myDeleteModal.scss');

export const myDeleteModalComponent = {
  template: require('./myDeleteModal.html'),
  controllerAs: 'ctrl',
  controller: DeleteModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
