import { getNow, isAfterOrEqual, isAfter, printDate } from '@kathondvla/sri-client/date-utils';
import * as GROUP_ACTIONS from 'ReduxLoop/ouManager/vakGroepen/groupsActions';
import { GROUP_POSITIONS } from 'ReduxLoop/appConfig';

require('./groupInfo.scss');

export class GroupInfo {
  constructor($scope, $uibModal, $ngRedux, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;
    this.$state = $state;

    this.addIcon = require('Img/add.svg');
    // this.deleteIcon = require('Img/delete.svg');
    this.removeIcon = require('Img/cross.svg');

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      selectedGroup: state.vm.groupsTab.selectedGroup,
      isDeletedGroup: state.vm.groupsTab.selectedGroup.isDeletedGroup,
      groupMembers: state.vm.groupsTab.selectedGroup.members,
      groupMemberCandidates: state.vm.groupsTab.selectedGroup.memberCandidates,
      groupAdmins: state.vm.groupsTab.selectedGroup.admins,
      groupAdminCandidates: state.vm.groupsTab.selectedGroup.adminCandidates,
    }))(this);
  }

  $onInit() {
    this.positions = GROUP_POSITIONS;
    this.newGroupMemberPosition = GROUP_POSITIONS[1].permalink;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  filterMembers(data, str) {
    return data.filter((member) => {
      return new RegExp(str, 'gi').test(member.familiarName);
    });
  }

  displayMember(member) {
    if (!member) {
      return null;
    }

    return `${member.familiarName}`;
  }

  onSelectNewMember(ev, person) {
    this.newGroupMember = person;
  }

  addNewMember() {
    this.$ngRedux.dispatch(
      GROUP_ACTIONS.addMemberToGroupAction({
        person: this.newGroupMember,
        position: this.newGroupMemberPosition,
        startDate: isAfterOrEqual(this.selectedGroup.startDate, getNow())
          ? this.selectedGroup.startDate
          : getNow(),
        endDate: this.selectedGroup.endDate,
      })
    );
    this.newGroupMember = undefined;
  }

  cancelGroupMembership(ev, member) {
    const deleteGroupModal = this.createDeleteResponsibilityModal(member);

    deleteGroupModal.result.then(
      (result) => {
        /* if (isAfterOrEqual(member.startDate, result.endDate)) {
        this.$ngRedux.dispatch(GROUP_ACTIONS.deleteMemberResponsibilityAction({
          responsibilityPermalink: member.responsibilityPermalink
        }));
      } else { */
        this.$ngRedux.dispatch(
          GROUP_ACTIONS.endMemberResponsibilityAction({
            endDate: result.endDate,
            responsibilityPermalink: member.responsibilityPermalink,
          })
        );
        // }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  modifyGroupMemberPosition(ev, member, eventArgs) {
    /* const newPositionName = getPositionFromModifyPositionMessage(newPositionMessage);
    const newPosition = GROUP_POSITIONS.find(position => position.name === newPositionName); */

    this.$ngRedux.dispatch(
      GROUP_ACTIONS.editMemberResponsibilityAction({
        responsibilityPermalink: member.responsibilityPermalink,
        positionPermalink: eventArgs.position.permalink,
      })
    );
  }

  groupNameModified(ev, newGroupName) {
    if (this.selectedGroup.name !== newGroupName) {
      this.$ngRedux.dispatch(
        GROUP_ACTIONS.editGroupAction({
          name: newGroupName,
        })
      );
    }
  }

  onMakeGroupAdministrator(ev, groupMember) {
    this.$ngRedux.dispatch(
      GROUP_ACTIONS.makeGroupAdministratorAction({
        person: groupMember,
      })
    );
  }

  onRemoveGroupAdministrator(ev, groupMember) {
    this.$ngRedux.dispatch(
      GROUP_ACTIONS.removeGroupAdminstratorAction({
        responsibilityPermalink: groupMember.responsibilityHref,
      })
    );
  }

  deleteGroup(e) {
    const deleteGroupModal = this.createDeleteGroupModal(this.selectedGroup);

    deleteGroupModal.result.then(
      (result) => {
        this.$ngRedux.dispatch(GROUP_ACTIONS.endGroupAction(this.selectedGroup, result.endDate));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  reActivateGroup(e) {
    this.$ngRedux.dispatch(GROUP_ACTIONS.reActivateGroup(this.selectedGroup));
  }

  createDeleteGroupModal(group) {
    return this.$uibModal.open({
      animation: true,
      component: 'myDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        startDate: () => group.startDate,
        endDate: () => group.endDate,
        subtitle: () => 'Deze groep afschaffen.',
        endDateHelpText: () => 'Wanneer stop(te) deze groep?',
        startDateInfo: () => `${group.name} bestaat sinds ${printDate(group.startDate)}`,
        validatePeriod: () => true,
      },
    });
  }

  createDeleteResponsibilityModal(member) {
    return this.$uibModal.open({
      animation: true,
      component: 'myDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        startDate: () => member.startDate,
        endDate: () => member.endDate,
        subtitle: () => 'Deze persoon verwijderen uit groep.',
        endDateHelpText: () => 'Wanneer stop(te) deze persoon zijn rol in groep?',
        startDateInfo: () =>
          `${member.name} is ${member.mainPosition.name} sinds ${printDate(member.startDate)}`,
        extraValidation: () => {
          return (endDate) => {
            if (isAfter(endDate, this.selectedGroup.endDate)) {
              return {
                code: 'enddate.after.group.enddate',
                message: 'De einddatum valt na de einddatum van de groep.',
              };
            }
            return null;
          };
        },
      },
    });
  }
}
