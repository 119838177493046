import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { validatePasswordStrength } from 'Js/module/personUtils';

const apiClient = sriClient;

export class MyChangePasswordFromLink {
  constructor($scope, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.emailValue = $stateParams.email;
    this.redirectUrl = $stateParams.redirect_url;
    this.token = $stateParams.token;
  }

  validatePassword() {
    this.error = null;
    if (!this.password) {
      this.error = 'Vul een wachtwoord in.';
      return;
    }
    const invalidPasswordError = validatePasswordStrength(this.password);
    if (invalidPasswordError) {
      this.error = invalidPasswordError.code === 'password.too.short' ? invalidPasswordError.message : 'Het wachtwoord is niet sterk genoeg';
    }
  }

  validateRepeatPassword() {
    this.error = null;
    if (!this.repeatPassword) {
      this.error = 'Vul het wachtwoord nog eens in in het tweede veld.';
      return;
    }
    if (this.password !== this.repeatPassword) {
      this.error = 'De wachtwoorden komen niet overeen.';
    }
  }

  async changePassword() {
    this.validatePassword();
    if (this.error) {
      console.log("first password was too weak")
      return;
    }
    this.validateRepeatPassword();
    if (this.error) {
      console.log("second password does not equal ferst")
      return;
    }
    console.log("password is valid, continue")

    try {
      await sriClient.post('/persons/changepassword-with-token', {
        token: this.token,
        newPassword: this.password,
      });
      this.$state.go('changePasswordFromLinkSuccess', { redirect_url: this.redirectUrl });
    } catch (error) {
      if (error.status === 409 && error.body && error.body.errors.some(e => e.code === 'link.already.used')) {
        this.error = 'Deze link is al gebruikt. Vraag een nieuwe link aan.';
      } else if (error.status === 409 && error.body && error.body.errors.some(e => e.code === 'link.expired')) {
        this.error = 'Deze link is niet langer geldig. Vraag een nieuwe link aan.';
      } else {
        console.error(error);
        this.error = 'Er is iets misgegaan. Probeer het later opnieuw.';
      }
    } finally {
      this.$scope.$apply();
    }
  }
}