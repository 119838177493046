const moment = require('moment');

export const createExpiredCookieForPopup = function (cookies, popupSettings) {
  const expires = moment()
    .add(popupSettings.ttl || 1, popupSettings.ttlUnit || 'days')
    .toDate();
  cookies.put(`popup-${popupSettings.key}`, true, { expires });
};

export const shouldShowPopup = function ({ startDate, endDate }) {
  let show = true;
  const today = moment();

  if (startDate && startDate !== '') {
    show = moment(startDate).isSameOrBefore(today);
  }

  if (show && endDate && endDate !== '') {
    show = moment(endDate).isSameOrAfter(today);
  }

  return show;
};
