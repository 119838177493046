export class MyOuTypeSelector {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  selectOuType(ouType) {
    this.$scope.$emit('ouTypeSelected', ouType);
  }

  noTypeClicked() {
    this.$scope.$emit('noTypeClicked');
  }
}
