import { settings } from 'Js/config/settings';

export class OuToolsTab {
  constructor($ngRedux, $window) {
    'ngInject';

    this.collaborationIcon = require('Img/collaboration.svg');
    this.office365Icon = require('Img/msteams.svg');
    this.$window = $window;

    this.unsubscribe = $ngRedux.connect((state) => ({
      ou: state.vm.schoolManager.currentOU,
      isLoading: Object.keys(state.vm.schoolManager.currentOU.teamMetaInfo).length === 0,
      hasMicrosoftTeamsEnvironment:
        state.vm.schoolManager.currentOU.teamMetaInfo.hasMicrosoftTeamsEnvironment,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  goToMicrosoftTeams() {
    this.$window.open(settings.applications.microsoftTeams);
  }
}
