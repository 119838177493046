// Screen component for the screen where you can log in OR register
import { Aanmelden } from './aanmelden';

require('./aanmelden.scss');

export const myAanmeldenComponent = {
  template: require('./aanmelden.html'),
  controllerAs: 'ctrl',
  controller: Aanmelden,
};
