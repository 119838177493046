// import { reactToAngularComponent } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import KovWizardSteps from '@kathondvla/react-shared-components/src/components/wizardSteps/wizardSteps.jsx';
import StoreProvider from 'ReduxLoop/StoreProvider';
import OuWithAddressPicker from 'Js/myComponents/myOuWithAddressPicker/myOuWithAddressPicker';
import { reactToAngularComponent } from './utils.jsx';
import CurriculumSelector from '../../myScreenComponents/myNewsletterPreferences/myReactCurriculumSelector/CurriculumSelector';
import MyCookiesModal from '../../myScreenComponents/myCookiesModal/MyCookiesModal';
import MyReactFooter from '../../myComponents/myReactFooter/MyReactFooter';
// import AskPasswordResetLink from '../../myScreenComponents/oauthScreens/passwordReset/AskPasswordResetLink.jsx';
// import * as test from '@kathondvla/react-shared-components/dist/kov-shared-components.esm';

const app = require('../../app/app');

app.component('ngWizardSteps', reactToAngularComponent(KovWizardSteps));
app.component('ngCurriculaSelector', reactToAngularComponent(CurriculumSelector, StoreProvider));
app.component('ngOuWithAddressPicker', reactToAngularComponent(OuWithAddressPicker));
app.component('myCookiesModal', reactToAngularComponent(MyCookiesModal));
app.component('reactFooter', reactToAngularComponent(MyReactFooter));
// app.component('myAskPasswordResetLink', reactToAngularComponent(AskPasswordResetLink));
