import { getEndOfSchoolYear } from '@kathondvla/sri-client/date-utils';
import { createRequest, createResponsibility } from 'ReduxLoop/utils/apiResourceFactory';
import {
  BEHEERDER,
  BESTUURDER,
  CONTACTPERSOON,
  COORDINEREND_DIRECTEUR,
  DIRECTEUR,
  DIRECTEUR_COORDINATIE,
  GOVERNINGINSTITUTION,
  LERARENNETWERK,
  LID_VAN_HET_DAGELIJKS_BESTUUR,
  SCHOOLCOMMUNITY,
  STUDENT,
  VSKO_VERANTWOORDELIJKE,
} from 'ReduxLoop/constants';
import { OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import {
  createPerson,
  createEmailAddress,
  createChangePassword,
  createAccount,
} from '../../utils/personResourceFactory';
import { sriClient } from '../../utils/apiConfig';

const Batch = require('@kathondvla/sri-client/batch');
const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export const goToRegisterPage = () => {
  // console.log('location', window.location);
  /* var $injector = window.angular.injector(['Mijn2']);
  const $state = $injector.get('$state');

  $state.go('register'); */

  window.location.href = '/#!/registreren?client_name=mijn_invite';
};

export const goToDuplicatesPage = () => {
  window.location.href = '/#!/dubbel-gededecteerd';
};

export const goToAcceptConditionsPage = () => {
  window.location.href = '/#!/accepteer-voorwaarden';
};

export const goToMailSendPage = () => {
  window.location.href = '/#!/mail-verzonden?type=registreer';
};

export const validate = async (registerForm, password, invitationKey, requestState, type) => {
  if (password) {
    registerForm.password = password;
  }
  console.log('registerForm', registerForm);
  const batch = new Batch();
  const person = createPerson(registerForm);
  batch.put(person);
  if (type === 'LOGIN_OR_REGISTER') {
    person.username = 'a.very.unique.username.xs7ij9';
    const fakeEmail = createEmailAddress('a.very.unique.xs7ij9@email.com', person);
    batch.put(fakeEmail);
  } else if (type === 'SET_USERINFO_FROM_OAUTH') {
    person.username = 'a.very.unique.username.xs7ij9';
    if (!person.dateOfBirth) {
      person.dateOfBirth = '1940-09-01';
    }
  }
  if (registerForm.emailAddress) {
    const email = createEmailAddress(registerForm.emailAddress, person);
    batch.put(email);
  }
  if (registerForm.password) {
    const passwordObj = createChangePassword(registerForm.password, person);
    batch.post('/persons/changepassword', passwordObj);
  }
  if (invitationKey) {
    batch.post('/responsibilities/pending/accept', { confirmationCode: invitationKey });
  } else if (
    requestState &&
    requestState.ou &&
    !requestState.noManagers &&
    !(
      requestState.ou.types[0] === 'GOVERNINGINSTITUTION' &&
      requestState.position &&
      (requestState.position.$$meta.permalink === BESTUURDER ||
        requestState.position.$$meta.permalink === LID_VAN_HET_DAGELIJKS_BESTUUR)
    )
  ) {
    const ouType = requestState.ou.types[0];
    if (
      (ouType === LERARENNETWERK && person.registrationNumber) ||
      (requestState.position && requestState.position.$$meta.permalink === STUDENT)
    ) {
      const ouPermalink = [SCHOOLCOMMUNITY, 'SCHOOL', 'BOARDING'].some(
        (vosOuType) => vosOuType === ouType
      )
        ? requestState.ou.$$meta.vosPermalink
        : requestState.ou.$$meta.permalink;
      const responsibility = createResponsibility(
        ouPermalink,
        person.$$meta.permalink,
        requestState.position ? requestState.position.$$meta.permalink : null,
        requestState.startDate
      );
      responsibility.remark = requestState.roleDescription
        ? requestState.roleDescription
        : undefined;
      if (requestState.position && requestState.position.$$meta.permalink === STUDENT) {
        responsibility.endDate = getEndOfSchoolYear();
      }
      batch.put(responsibility);
    } else {
      const request = createRequest(
        person.$$meta.permalink,
        requestState.ou,
        requestState.position ? requestState.position.$$meta.permalink : null,
        requestState.startDate
      );
      request.remark = requestState.roleDescription ? requestState.roleDescription : undefined;
      if (
        requestState.ou.$$meta.vosPermalink &&
        (ouType === GOVERNINGINSTITUTION || ouType === 'CLB')
      ) {
        request.vosOrganisationalUnit = { href: requestState.ou.$$meta.vosPermalink };
      }
      const typeConfig = OU_TYPE_CONFIG[ouType];
      if (typeConfig) {
        request.to = typeConfig.managers;
        if (typeConfig.admins) {
          request.to = [...request.to, ...typeConfig.admins];
        }
      } else if (ouType === 'SCHOOL') {
        request.to = [DIRECTEUR, VSKO_VERANTWOORDELIJKE];
      } else if (ouType === 'BOARDING') {
        request.to = [BEHEERDER, CONTACTPERSOON, VSKO_VERANTWOORDELIJKE];
      } else if (ouType === SCHOOLCOMMUNITY) {
        request.to = [COORDINEREND_DIRECTEUR, DIRECTEUR_COORDINATIE, VSKO_VERANTWOORDELIJKE];
      }
      batch.put(request);
    }
  }
  if (registerForm.loginProviderKey) {
    const account = createAccount(
      person,
      registerForm.loginProviderKey,
      registerForm.userKey,
      registerForm.userAlias,
      registerForm.loginProviderDomain
    );
    batch.put(account);
  }
  try {
    const resp = await sriClient.post(
      '/persons/register/validate',
      { body: batch.array },
      { fullResponse: true }
    );
    console.log('response', resp);
    return {
      validationCode: resp.headers['vsko-validation-code'],
      batch: batch.array,
    };
  } catch (apiError) {
    if (apiError instanceof SriClientError && apiError.body) {
      console.log('validate error:', apiError);
      const duplicateErrors = [];
      let duplicateEmails = [];
      apiError.body.forEach((obj) => {
        if (obj.status >= 400) {
          if (!obj.body.errors) {
            console.warn('Unexpected error with no errors array in the body: ', obj.body);
          } else {
            obj.body.errors.forEach((error) => {
              if (error.code === 'duplicate.registration.number') {
                duplicateErrors.push({
                  code: error.code,
                  type: error.type,
                  permalink: error.duplicate.href,
                  username: error.duplicate.$$details.username,
                  emailAddress: error.duplicate.$$details.emailAddress,
                });
              } else if (error.code === 'similar.spelling') {
                error.similars.forEach((similar) => {
                  if (
                    similar.$$details.hasSameDateOfBirth &&
                    !similar.$$details.hasDifferentRegistrationNumber
                  ) {
                    duplicateErrors.push({
                      code: error.code,
                      type: error.type,
                      reason: similar.reason,
                      distance: similar['dl-distance'],
                      permalink: similar.href,
                      username: similar.$$details.username,
                      emailAddress: similar.$$details.emailAddress,
                    });
                  }
                });
              } else if (error.code === 'non.unique.primary.emailaddress') {
                duplicateEmails = error.duplicates.map((duplicate) => duplicate.href);
              } else if (type !== 'VALIDATE_EMAILADDRESS') {
                console.warn('Unexpected error code: ', error);
              }
            });
          }
        }
      });
      if (type === 'VALIDATE_EMAILADDRESS') {
        if (duplicateEmails.length > 0) {
          throw duplicateEmails;
        }
        return null;
      }
      if (duplicateErrors.length > 0) {
        const duplicateError = {
          validationCode: apiError.headers['vsko-validation-code'],
          duplicates: duplicateErrors,
        };
        throw duplicateError;
      } else {
        return {
          validationCode: apiError.headers['vsko-validation-code'],
          batch: batch.array,
        };
      }
    } else {
      throw apiError;
    }
  }
};

export const validateEmailAddress = (emailAddress) => {
  // const email = createEmailAddress(emailAddress, createPerson({}));
  const fakeForm = {
    emailAddress,
    firstName: 'Xytbquseu',
    lastName: 'Gdspysv',
    username: 'a.very.unique.username.xs7ij9',
    dateOfBirth: '1927-03-15',
  };
  return validate(fakeForm, undefined, undefined, undefined, 'VALIDATE_EMAILADDRESS');
};

export const execute = (
  batch,
  profilePictureSmall,
  profilePictureMedium,
  redirectUrl,
  validationCode
) => {
  return sriClient.post(
    '/persons/register',
    { body: batch, profilePictureSmall, profilePictureMedium, redirectUrl },
    { headers: { 'vsko-validation-code': validationCode } }
  );
};

export const getUsername = (firstName, lastName) => {
  return sriClient.post('/persons/generateusername', { firstName, lastName });
};
