const members = [
  {
    key: '10',
    name: 'Ria Ruisbroek',
    type: 'RESPONSIBILITY',
    emailAddress: 'ria.ruisbroek@katholiekonderwijs.vlaanderen',
  },
  {
    key: '1',
    name: 'Herman Vermeiren',
    type: 'RESPONSIBILITY',
    position: {
      name: 'Directeur',
      class: {
        sortOrder: 1,
        name: 'MEMBER',
      },
    },
    emailAddress: 'herman.vermeiren@hotmail.com',
  },
  {
    key: '2',
    name: 'Piet Daele',
    type: 'RESPONSIBILITY',
    position: {
      name: 'Zorg-coördinator',
      class: {
        sortOrder: 1,
        name: 'MEMBER',
      },
    },
    emailAddress: 'piet.daele@katholiekonderwijs.vlaanderen',
  },
  {
    key: '3',
    name: 'Jo Vandamme',
    type: 'REQUEST',
    position: {
      name: 'ICT-coördinator',
      class: {
        sortOrder: 1,
        name: 'COORDINATOR',
      },
    },
    emailAddress: 'jjo.vandamme@katholiekonderwijs.vlaanderen',
  },
  {
    key: '4',
    name: 'Fred TIlly',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'fred.tilly@katholiekonderwijs.vlaanderen',
  },
  {
    key: '12',
    name: 'Jo Vandamme',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'jo.vandamme@katholiekonderwijs.vlaanderen',
  },
  {
    key: '13',
    name: 'Joske Vermeulen',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'jos.vermeulen@katholiekonderwijs.vlaanderen',
  },
  {
    key: '14',
    name: 'Willy Wonka',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'willy.wonka@katholiekonderwijs.vlaanderen',
  },
  {
    key: '15',
    name: 'Peter Van Petegem',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'peter@katholiekonderwijs.vlaanderen',
  },
  {
    key: '5',
    name: 'Piet Vanschep',
    type: 'REQUEST',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 2,
        name: 'SUPPORT',
      },
    },
    emailAddress: 'piet.vanschep@katholiekonderwijs.vlaanderen',
  },
  {
    key: '6',
    name: 'Freddy Mangelhof',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'freddy.mangelhof@katholiekonderwijs.vlaanderen',
  },
  {
    key: '7',
    name: 'beatrijs.debacker@hotmail.com',
    type: 'INVITATION',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'beatrijs.debacker@hotmail.com',
  },
  {
    key: '8',
    name: 'Jan Dediener',
    type: 'RESPONSIBILITY',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddress: 'jan.dediener@katholiekonderwijs.vlaanderen',
  },
  {
    key: '9',
    name: 'Kim Vos',
    type: 'INVITATION',
    position: {
      name: 'leraar',
      class: {
        sortOrder: 3,
        name: 'MEMBER',
      },
    },
    emailAddressBounced: true,
    emailAddress: 'kim.vos@gmail.com',
  },
];

const adminDelegations = [
  {
    name: 'Freddy Mangelhof',
  },
];

const nascholingDelegations = [
  {
    name: 'Jan Dediener',
  },
];

module.exports = {
  members,
  adminDelegations,
  nascholingDelegations,
};
