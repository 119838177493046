import { printAddress } from '@kathondvla/sri-client/address-utils';
import { printDate } from '@kathondvla/sri-client/date-utils';
import { getStatePropertyName } from 'ReduxLoop/helpDesk/helpdeskReducer';

export const getSearchResults = (helpdeskState) => {
  if (!helpdeskState.searchValue || !helpdeskState.ouType) {
    return { results: [] };
  }
  const dataSet = helpdeskState[getStatePropertyName(helpdeskState.ouType)];
  const words = helpdeskState.searchValue
    .split(' ')
    .filter((word) => word.length > 1)
    .map((word) => word.toLowerCase());
  if (words.length === 0) {
    return [];
  }
  if (!dataSet) {
    return { results: [], loading: true };
  }
  if (helpdeskState.institutionNumberSearch) {
    if (helpdeskState.abolishedInstitution) {
      return {
        results: [
          {
            ...helpdeskState.abolishedInstitution,
            extraInfo: `Dit instellingsnummer is afgeschaft op ${printDate(
              helpdeskState.abolishedInstitution.endDate
            )}`,
          },
        ],
      };
    }
    if (helpdeskState.ouType === 'SECONDARY_SCHOOL' && helpdeskState.secondaryInstitution) {
      const relatedSchoolEntities = helpdeskState.secondaryInstitution.relatedSchoolEntities.map(
        (href) => {
          return {
            ...dataSet.filter((se) => `/sam/organisationalunits/${se.key}` === href)[0],
            extraInfo: `SE: gerelateerd aan <strong>${helpdeskState.secondaryInstitution.institutionNumber}</strong>`,
          };
        }
      );
      relatedSchoolEntities.sort((a, b) => (a.name > b.name ? 1 : -1));
      return {
        results: [
          { ...helpdeskState.secondaryInstitution, extraInfo: undefined },
          ...relatedSchoolEntities,
        ],
        words,
      };
    }
  }
  const results = dataSet.map((ou) => {
    let points = 0;
    let matches = words.length;
    words.forEach((word) => {
      let campusPoints = 0;
      ou.addresses.forEach((address) => {
        let thisCampusPoints = 0;
        if (address.name && word === address.name.toLowerCase()) {
          thisCampusPoints += 10;
        } else if (word === address.zipCode) {
          thisCampusPoints += 9;
        } else if (address.subCity && word === address.subCity.toLowerCase()) {
          thisCampusPoints += 9;
        } else if (address.name && address.name.toLowerCase().includes(word)) {
          thisCampusPoints += 8;
        } else if (word === address.city.toLowerCase()) {
          thisCampusPoints += 7;
        } else if (word === address.street.toLowerCase()) {
          thisCampusPoints += 6;
        } else if (address.subCity && address.subCity.toLowerCase().includes(word)) {
          thisCampusPoints += 5;
        } else if (address.city.toLowerCase().includes(word)) {
          thisCampusPoints += 4;
        } else if (address.street.toLowerCase().includes(word)) {
          thisCampusPoints += 3;
        }
        if (thisCampusPoints > campusPoints) {
          campusPoints = thisCampusPoints;
        }
      });
      if (ou.institutionNumber === word) {
        points += 14;
      } else if (ou.name.toLowerCase() === word) {
        points += 12;
      } else if (ou.name.toLowerCase().includes(word)) {
        points += 10;
      } else if (ou.fullName && ou.fullName.toLowerCase().includes(word)) {
        points += 9;
      } else if (ou.description && ou.description.toLowerCase().includes(word)) {
        points += 4;
      } else if (campusPoints > 0) {
        points += campusPoints;
      } else {
        matches -= 1;
      }
    });
    return { ...ou, points, matches };
  });
  dataSet.sort((a, b) => b.points - a.points);
  const result = results.filter((ou) => ou.matches === words.length);
  const limitedResult =
    result.length > helpdeskState.nbOfResultsShown
      ? result.slice(0, helpdeskState.nbOfResultsShown)
      : result;
  limitedResult.sort((a, b) => {
    if (a.points === b.points) {
      return a.name > b.name ? 1 : -1;
    }
    return b.points - a.points;
  });
  return { results: limitedResult, isMore: result.length > helpdeskState.nbOfResultsShown, words };
};

export const buildResults = (results, words) => {
  results.forEach((ou) => {
    if (!ou.addresses) {
      console.log('An ou without addresses?', ou);
    }
    ou.addresses.forEach((address) => {
      address.display = '';
      if (address.name) {
        address.display += `${address.name} - `;
      }
      address.display +=
        printAddress(address).split('(')[0] +
        (address.city !== address.subCity ? ` (${address.city})` : '');
    });
    if (words) {
      words.forEach((word) => {
        const reg = `(${word})(?![^<]*>|[^<>]*</)`;
        const regex = new RegExp(reg, 'i');
        Object.keys(ou).forEach((property) => {
          if (property === 'addresses') {
            ou.addresses.forEach((address) => {
              address.display = address.display.replace(regex, '<strong>$1</strong>');
            });
          } else if (
            (property === 'name' ||
              property === 'institutionNumber' ||
              property === 'description' ||
              property === 'fullName') &&
            ou[property]
          ) {
            try {
              ou[property] = ou[property].replace(regex, '<strong>$1</strong>');
            } catch (err) {
              console.log(`ou zonder ${property}`, ou);
              console.error(err);
            }
          }
        });
      });
    }
  });
};
