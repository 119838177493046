import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { emailAddressPattern } from '../../../module/personUtils';

const apiClient = sriClient;

export class MyAskPasswordResetLink {
  constructor($scope, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.emailValue = $stateParams.email;
    this.redirectUrl = $stateParams.redirect_url;
  }

  validateEmail() {
    this.error = '';
    if (!this.emailValue) {
      this.error = 'Vul een e-mailadres in.';
      return;
    }
    if (!emailAddressPattern.test(this.emailValue)) {
      this.error = 'Dit is geen geldig e-mailadres.';
    }
  }

  async askPasswordResetLinkEmail() {
    this.validateEmail();
    if (this.error) {
      console.log("doorgaan button pressed with invalid e-mail", this.emailValue)
      return;
    }
    console.log("doorgaan button pressed with valid e-mail", this.emailValue)

    try {
      await apiClient.post('/persons/ask-password-reset-link', { emailAddress: this.emailValue, redirectUrl: this.redirectUrl });
      this.$state.go('askPasswordResetSent');
    } catch (error) {
      console.error(error);
      this.error = 'Er is iets misgegaan. Probeer het later opnieuw.';
    } finally {
      this.$scope.$apply();
    }
  }
}