import { loop, Cmd } from 'redux-loop';
import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { getNow, isBefore, getNextDay } from '@kathondvla/sri-client/date-utils';
import { arrayToMap } from 'ReduxLoop/index';
import { removeResource, storeResource } from 'ReduxLoop/utils';
import { getWorkgroupInfo } from 'ReduxLoop/ouManager/workgroups/workgroupDataAccess';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { CURRENT_OU_FETCHED } from 'ReduxLoop/ouManager/schoolManagerActions';
import * as ACTIONS from './workgroupActions';

const initialState = {
  teamMetaInfo: null,
  memberOus: {},
  memberRelations: {},
  presidents: [],
  parentRelations: [],
};

export const workgroupReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case CURRENT_OU_FETCHED: {
      const ou = action.payload;
      if (ou.type === 'VERGADERGROEP' || ou.type === 'LEERPLANCOMMISSIE') {
        return loop(state, Cmd.action(ACTIONS.fetchWorkgroupInfo()));
      }
      return state;
    }

    case ACTIONS.FETCH_WORKGROUP_INFO: {
      return loop(
        state,
        Cmd.run(getWorkgroupInfo, {
          args: [rootState.schoolManager.currentOrganisationalUnit],
          successActionCreator: ACTIONS.workgroupInfoFetched,
        })
      );
    }

    case ACTIONS.WORKGROUP_INFO_FETCHED: {
      return {
        ...state,
        teamMetaInfo: action.payload.workgroup,
        memberRelations: arrayToMap(action.payload.memberRelations),
        memberOus: arrayToMap(action.payload.memberOus),
        presidents: action.payload.presidents,
        parentRelations: action.payload.memberParentRelations,
      };
    }

    case ACTIONS.ADD_MEMBER_OU: {
      const newMemberOu = action.payload;
      const newMemberOus = { ...state.memberOus };
      newMemberOus[newMemberOu.$$meta.permalink] = { ...newMemberOu, $$name: newMemberOu.name };
      const newRelKey = generateUUID();
      const newMemberRel = {
        $$meta: { permalink: `/organisationalunits/relations/${newRelKey}` },
        key: newRelKey,
        from: { href: newMemberOu.$$meta.permalink },
        to: { href: rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink },
        type: 'IS_MEMBER_OF',
        startDate: getNow(),
      };
      const newMemberRels = { ...state.memberRelations };
      newMemberRels[newMemberRel.$$meta.permalink] = newMemberRel;
      const newState = { ...state, memberOus: newMemberOus, memberRelations: newMemberRels };
      return loop(
        newState,
        Cmd.run(storeResource, {
          args: [newMemberRel],
          successActionCreator: ACTIONS.fetchWorkgroupInfo,
          failActionCreator: (error) => ACTIONS.addMemberOuFailed(error, newMemberRel),
        })
      );
    }

    case ACTIONS.ADD_MEMBER_OU_FAILED: {
      const newMemberRelations = { ...state.memberRelations };
      delete newMemberRelations[action.payload.memberRelation.$$meta.permalink];
      if (action.payload.error.status === 403) {
        return loop(
          { ...state, memberRelations: newMemberRelations },
          Cmd.action(
            addErrorNotification(
              'Jouw toegangsrechten om groepen die meewerken toe te voegen worden nog gesynchroniseerd. Dit kan enkele uren duren. Probeer het morgen nog eens.'
            )
          )
        );
      }
      return loop(
        { ...state, memberRelations: newMemberRelations },
        Cmd.action(
          addErrorNotification(
            'Er is een onverwachte fout opgetreden bij het toevoegen van de nieuwe groep.'
          )
        )
      );
    }

    case ACTIONS.REMOVE_MEMBER_OU: {
      const memberOu = action.payload;
      const relation = {
        ...Object.values(state.memberRelations).find((rel) => rel.from.href === memberOu.permalink),
      };
      if (!isBefore(getNextDay(relation.startDate, 30), getNow())) {
        const newRelations = { ...state.memberRelations };
        delete newRelations[relation.$$meta.permalink];
        return loop(
          { ...state, memberRelations: newRelations },
          Cmd.run(removeResource, {
            args: [relation.$$meta.permalink],
            failActionCreator: (error) => ACTIONS.removeMemberOuFailed(error, relation),
          })
        );
      }
      relation.endDate = getNow();
      const newRelations = { ...state.memberRelations };
      newRelations[relation.$$meta.permalink] = relation;
      return loop(
        { ...state, memberRelations: newRelations },
        Cmd.run(storeResource, {
          args: [relation],
          failActionCreator: (error) => ACTIONS.removeMemberOuFailed(error, relation),
        })
      );
    }

    case ACTIONS.REMOVE_MEMBER_OU_FAILED: {
      const newMemberRelations = { ...state.memberRelations };
      newMemberRelations[action.payload.memberRelation.$$meta.permalink] =
        action.payload.memberRelation;
      return loop(
        { ...state, memberRelations: newMemberRelations },
        Cmd.action(
          addErrorNotification(
            'Er is een onverwachte fout opgetreden bij het verwijderen van de groep.'
          )
        )
      );
    }

    default:
      return state;
  }
};
