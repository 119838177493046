import {
  getNonAbolishedResources,
  isAfter,
  printDate,
  printFutureForPeriodic,
  getNow,
} from '@kathondvla/sri-client/date-utils';
import {
  BEHEERDER,
  EXTERNE_MEDEWERKER,
  INTREDEND,
  LID,
  NASCHOLINGSVERANTWOORDELIJKE,
  PEDAGOGISCH_VERANTWOORDELIJKE,
  UITTREDEND,
  TREKKER,
  VOORZITTER,
  VSKO_VERANTWOORDELIJKE,
  CONTACTPERSOON,
  KATHOLIEK_ONDERWIJS_VLAANDEREN,
  GOVERNINGINSTITUTION,
  LERARENNETWERK,
  BESTUURDER,
  LID_VAN_HET_DAGELIJKS_BESTUUR,
  LEERPLANCOMMISSIE,
  VERGADERGROEP,
  LEERSTEUNCENTRUM,
} from 'ReduxLoop/constants';
import { getPositionClass } from 'ReduxLoop/ouManager/schoolManagerModel';
import { mapToArray } from 'ReduxLoop/viewModel';
import { OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import { printIban } from 'ReduxLoop/utils/stringUtils';

const getWorkgroupsTitle = (workgroupTypes) => {
  const stringParts = workgroupTypes.map((type) => {
    switch (type) {
      case VERGADERGROEP:
        return 'vergadergroepen';
      case LEERPLANCOMMISSIE:
        return 'leerplancommissies';
      case 'BELEIDSORGAAN':
        return 'beleidsorganen';
      case 'PROJECTGROEP':
        return 'projectgroepen';
      default:
        return '';
    }
  });
  const ret = stringParts.join(',').replace(/,([^,]*)$/, ' en $1');
  return ret.charAt(0).toUpperCase() + ret.substring(1);
};

const sortPositions = (a, b) => {
  if (a.positionClass.sortOrder !== b.positionClass.sortOrder) {
    return a.positionClass.sortOrder - b.positionClass.sortOrder;
  }
  return a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1;
};

const sortTeams = (a, b) => {
  if (
    a.positionClass &&
    b.positionClass &&
    a.positionClass.sortOrder !== b.positionClass.sortOrder
  ) {
    return a.positionClass.sortOrder - b.positionClass.sortOrder;
  }
  return a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1;
};

const handlePosition = (ou, resource, allPositions) => {
  if (!resource.position) {
    const ouTypeConfig =
      OU_TYPE_CONFIG[ou.type] ||
      Object.values(OU_TYPE_CONFIG).find(
        (config) => config.types && config.types.some((type) => type === ou.type)
      );
    ou.positions.push({
      name:
        ouTypeConfig && ouTypeConfig.emptyPosition && ouTypeConfig.emptyPosition.match(/^[a-zA-Z]/)
          ? ouTypeConfig.emptyPosition
          : null,
      resource: resource.$$meta.permalink,
      type: resource.$$meta.type === 'RESPONSIBILITY' ? 'RESPONSIBILITY' : 'REQUEST',
      positionClass: { sortOrder: 5 },
      startDate: resource.startDate,
      endDate: resource.endDate,
    });
    return;
  }
  if (resource.position.href === VSKO_VERANTWOORDELIJKE) {
    ou.delegations.push('administratieve rechten');
  } else if (resource.position.href === PEDAGOGISCH_VERANTWOORDELIJKE) {
    ou.delegations.push('pedagogische rechten');
  } else if (resource.position.href === NASCHOLINGSVERANTWOORDELIJKE) {
    ou.delegations.push('rechten nascholingstoepassing');
  } else if (resource.position.href === BEHEERDER) {
    ou.delegations.push('beheersrechten');
  } else if (resource.position.href === CONTACTPERSOON) {
    ou.delegations.push('contactpersoon');
  } else {
    const position = allPositions.get(resource.position.href);
    const definitionOuType = ou.type === 'DIENST' ? ou.root.type : ou.type;
    const definition = position.definitions.find((def) =>
      def.applicableIn.ouTypes.some((ouType) => ouType === definitionOuType)
    );
    const result = {
      name: position.name,
      resource: resource.$$meta.permalink,
      type: resource.$$meta.type === 'RESPONSIBILITY' ? 'RESPONSIBILITY' : 'REQUEST',
      permalink: position.$$meta.permalink,
      startDate: resource.startDate,
      endDate: resource.endDate,
    };
    if (definition) {
      ou.positions.push({
        ...result,
        positionClass: getPositionClass(position, definition, ou.type),
        externalDefinition: definition.externalDefinition,
        externalConsequence: definition.externalConsequence,
      });
    } else {
      ou.positions.push({
        ...result,
        positionClass: { sortOrder: 5 },
      });
    }
  }
};

const handleTeamPeriod = (ou, resp) => {
  if (resp.startDate < ou.startDate) {
    ou.startDate = resp.startDate;
  }
  if (isAfter(resp.endDate, ou.endDate)) {
    ou.endDate = resp.endDate;
  }
};

const getTeamAddress = (address) => {
  return {
    addressLine1: `${address.street} ${address.houseNumber}${
      address.mailboxNumber ? ` bus ${address.mailboxNumber}` : ''
    }`,
    addressLine2: `${address.zipCode} ${address.subCity}`,
  };
};

const createOu = (ou, resp) => {
  return {
    ouKey: ou.key,
    permalink: ou.$$meta.permalink,
    name: ou.$$displayName,
    type: ou.type,
    startDate: resp.startDate,
    endDate: resp.endDate,
    positions: [],
    delegations: [],
  };
};

const transformTeam = (team) => {
  team.positions.sort(sortPositions);
  team.delegations.sort((a, b) => (a.toLowerCase() <= b.toLowerCase() ? -1 : 1));
  team.positionClass =
    team.positions.length > 0 ? team.positions[0].positionClass : { sortOrder: 5 };
  team.respType =
    team.positions.length === 0 || team.positions.some((p) => p.type === 'RESPONSIBILITY')
      ? 'RESPONSIBILITY'
      : 'REQUEST';
  team.positionString =
    (team.respType === 'REQUEST' ? `aanvraag${team.positions.length > 0 ? ': ' : ''}` : '') +
    team.positions
      .filter((p) => p.name)
      .map((p) =>
        p.type === 'REQUEST' && team.respType === 'RESPONSIBILITY'
          ? `<span class="request-position"> aanvraag: ${p.name}</span>`
          : p.name
      )
      .join(', ')
      .replace(/,\s([^,]+)$/, ' & $1');
  team.period = printDate(team.startDate) + (team.endDate ? ` - ${printDate(team.endDate)}` : '');
};

const getTeamInfo = (state) => {
  const { profileState } = state;
  const ret = {
    schools: [],
    boarding: [],
    governinginstitution: [],
    schoolcommunity: [],
    cvo: [],
    clb: [],
    leersteuncentrum: [],
    kathOndVla: [],
    kathOndVlaWorkgroups: {
      chairmen: [],
      members: [],
      others: [],
    },
    lerarennetwerken: {
      chairmen: [],
      members: [],
      others: [],
    },
    others: [],
    isMemberOfTeacherGroups: false,
    positionWhichRequiresRegistrationNumber: null,
    isKathOndVlaEmployee: false,
  };
  if (!profileState.responsibilities.sam || !profileState.ous.sam || !state.userState.positions) {
    return ret;
  }
  const schools = {};
  const samOus = {};
  const vosInstiutions = {};
  const teacherGroups = {};
  const kathondVlaWorkgroups = {};
  const organisations = {};
  getNonAbolishedResources([
    ...Object.values(profileState.responsibilities.sam),
    ...Object.values(profileState.pendingRequests.sam),
  ]).forEach((resp) => {
    const ouHref =
      resp.$$meta.type === 'RESPONSIBILITY' ? resp.organisation.href : resp.organisationalUnit.href;
    const ou = profileState.ous.sam[ouHref];
    if (isAfter(ou.endDate, getNow())) {
      if (ou && ou.type === 'SCHOOLENTITY') {
        if (!schools[ou.$$meta.permalink]) {
          const contactDetails = mapToArray(state.userState.ouContactDetails.toJS());
          const mailAddress = contactDetails.find(
            (cd) => cd.organisationalUnit.href === ou.$$meta.permalink && cd.type === 'MAIL'
          );
          schools[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
            address: mailAddress ? getTeamAddress(mailAddress.value) : null,
            teacherGroups: [],
          });
        } else {
          handleTeamPeriod(schools[ou.$$meta.permalink], resp);
        }
        handlePosition(schools[ou.$$meta.permalink], resp, state.userState.positions);
      } else if (
        ou &&
        [GOVERNINGINSTITUTION, LEERSTEUNCENTRUM, 'CLB'].some((ouType) => ou.type === ouType)
      ) {
        if (!samOus[ou.$$meta.permalink]) {
          const mailContactDetails = mapToArray(state.userState.ouContactDetails.toJS());
          const mailAddress = mailContactDetails.find(
            (cd) => cd.organisationalUnit.href === ou.$$meta.permalink && cd.type === 'MAIL'
          );
          samOus[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
            address: mailAddress ? getTeamAddress(mailAddress.value) : null,
          });
        } else {
          handleTeamPeriod(samOus[ou.$$meta.permalink], resp);
        }
        handlePosition(samOus[ou.$$meta.permalink], resp, state.userState.positions);
      } else if (ou && ou.type === 'TEACHER_GROUP') {
        if (!teacherGroups[ou.$$meta.permalink]) {
          teacherGroups[ou.$$meta.permalink] = createOu(ou, resp);
        } else {
          handleTeamPeriod(teacherGroups[ou.$$meta.permalink], resp);
        }
        handlePosition(teacherGroups[ou.$$meta.permalink], resp, state.userState.positions);
      }
    }
  });
  getNonAbolishedResources(Object.values(profileState.ous.groupParentRelations)).forEach((rel) => {
    ret.isMemberOfTeacherGroups = true;
    const group = teacherGroups[rel.from.href];
    if (group) {
      // teacher group can be removed from profile by user
      group.positionString = group.positions
        .map((p) => p.name)
        .join(', ')
        .replace(/,\s(.+)$/, ' & $1');
      group.description = (group.positionString ? `${group.positionString} ` : '') + group.name;
      group.positionClass = group.positions.length > 0 ? group.positions[0].positionClass : null;
      group.futureInfo = printFutureForPeriodic(group);
      schools[rel.to.href].teacherGroups.push(group);
    }
  });
  if (profileState.ous.campuses) {
    getNonAbolishedResources(profileState.ous.campuses).forEach((campus) => {
      if (schools[campus.organisationalUnit.href]) {
        // school can be removed from profile by user
        schools[campus.organisationalUnit.href].campuses.push(
          getTeamAddress(campus.physicalLocation.$$expanded.address)
        );
      }
    });
  }
  if (profileState.responsibilities.vos && profileState.ous.vos) {
    getNonAbolishedResources([
      ...Object.values(profileState.responsibilities.vos),
      ...Object.values(profileState.pendingRequests.vos),
    ]).forEach((resp) => {
      const ouHref =
        resp.$$meta.type === 'RESPONSIBILITY'
          ? resp.organisation.href
          : resp.organisationalUnit.href;
      const ou = profileState.ous.vos[ouHref];
      if (ou.$$meta.type === 'SCHOOL' && ou.educationLevel !== 'SECUNDAIR') {
        if (!schools[ou.$$meta.permalink]) {
          schools[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
            name: ou.$$name,
            type: ou.$$meta.type,
            institutionNumber: ou.institutionNumber,
            address: getTeamAddress(ou.seatAddresses[0].address),
            teacherGroups: [],
          });
        } else {
          handleTeamPeriod(schools[ou.$$meta.permalink], resp);
        }
        handlePosition(schools[ou.$$meta.permalink], resp, state.userState.positions);
      } else if (['BOARDING', 'CVO', 'SCHOOLCOMMUNITY'].some((type) => type === ou.$$meta.type)) {
        if (!vosInstiutions[ou.$$meta.permalink]) {
          vosInstiutions[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
            type: ou.$$meta.type,
            institutionNumber: ou.institutionNumber,
            name: ou.$$name,
            address: getTeamAddress(ou.seatAddresses[0].address),
          });
        } else {
          handleTeamPeriod(vosInstiutions[ou.$$meta.permalink], resp);
        }
        handlePosition(vosInstiutions[ou.$$meta.permalink], resp, state.userState.positions);
      } else if (
        ou.$$meta.type === 'ORGANISATION' &&
        (!resp.$$relationsFrom || resp.$$relationsFrom.length === 0)
      ) {
        if (
          ou.type === VERGADERGROEP ||
          ou.type === 'BELEIDSORGAAN' ||
          ou.type === LEERPLANCOMMISSIE ||
          ou.type === 'PROJECTGROEP' ||
          ou.type === LERARENNETWERK
        ) {
          if (!kathondVlaWorkgroups[ou.$$meta.permalink]) {
            kathondVlaWorkgroups[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
              name: ou.$$name,
            });
          } else {
            handleTeamPeriod(kathondVlaWorkgroups[ou.$$meta.permalink], resp);
          }
          handlePosition(
            kathondVlaWorkgroups[ou.$$meta.permalink],
            resp,
            state.userState.positions
          );
        } else if (profileState.ous.dienstParentRelations) {
          if (!organisations[ou.$$meta.permalink]) {
            organisations[ou.$$meta.permalink] = Object.assign(createOu(ou, resp), {
              type: ou.type,
              name: ou.$$name,
              permalink: ou.$$meta.permalink,
              isKathOndVla: false,
              subTeams: [],
            });
            let currentTeam = organisations[ou.$$meta.permalink];
            const names = [currentTeam.name];
            while (currentTeam.type === 'DIENST') {
              // eslint-disable-next-line no-loop-func
              const parentRel = profileState.ous.dienstParentRelations.find(
                (rel) => rel.from.href === currentTeam.permalink
              );
              if (!parentRel) {
                console.error(
                  `${currentTeam.name} (${currentTeam.permalink}) has no active parent!!!!!!!!!!!!!!!!!!!!!! This is a data error!`
                );
                break;
              }
              const parentOu = profileState.ous.vos[parentRel.to.href];
              if (!parentOu) {
                console.log('geen to van parentRel?', parentRel);
              }
              const parent = {
                permalink: parentOu.$$meta.permalink,
                name: parentOu.$$name,
                type: parentOu.type,
              };
              if (parent.permalink !== KATHOLIEK_ONDERWIJS_VLAANDEREN) {
                names.push(parent.name);
              }
              currentTeam.parent = parent;
              currentTeam = parent;
            }
            organisations[ou.$$meta.permalink].root = currentTeam;
            organisations[ou.$$meta.permalink].fullName = names.reverse().join(' - ');
            if (currentTeam.permalink === KATHOLIEK_ONDERWIJS_VLAANDEREN) {
              organisations[ou.$$meta.permalink].isKathOndVla = true;
              if (
                ![EXTERNE_MEDEWERKER, UITTREDEND, INTREDEND].some(
                  (positionHref) => positionHref === resp.position.href
                )
              ) {
                ret.isKathOndVlaEmployee = true;
              }
            }
          } else {
            handleTeamPeriod(organisations[ou.$$meta.permalink], resp);
          }
          handlePosition(organisations[ou.$$meta.permalink], resp, state.userState.positions);
          if (resp.$$relationsTo) {
            getNonAbolishedResources(resp.$$relationsTo).forEach((rel) => {
              const subResp = profileState.responsibilities.vos[rel.$$expanded.from.href];
              const subOu = profileState.ous.vos[subResp.organisation.href];
              const subPosition = state.userState.positions.get(subResp.position.href);
              organisations[ou.$$meta.permalink].subTeams.push({
                position: subResp.customPositionTitle
                  ? subResp.customPositionTitle
                  : subPosition.name,
                name: subOu.$$name,
              });
            });
          } else {
            console.log('resp heeft geen $$relationsTo, wrs omdat het een aanvraag is ', resp);
          }
        }
      }
    });
  }
  Object.values(schools).forEach((team) => {
    transformTeam(team);
    team.teacherGroups.sort(sortTeams);
    const extraInfo = [...team.delegations];
    if (team.teacherGroups.length > 0) {
      extraInfo.push(
        `${team.teacherGroups.length} (vak)groep${team.teacherGroups.length > 1 ? 'en' : ''}`
      );
    }
    team.extraInfo = extraInfo.join(', ').replace(/,\s([^,]+)$/, ' & $1');
    team.actions = team.positions.some((pos) => pos.type === 'REQUEST') ? [{ label: 'stuur herinnering', glyphicon: 'envelope', event: 'sendReminder' }] : [];
    ret.schools.push(team);
  });
  ret.schools.sort(sortTeams);
  if (ret.schools.length > 0 && ret.schools[0].positionClass.sortOrder <= 3) {
    [ret.positionWhichRequiresRegistrationNumber] = ret.schools[0].positions;
  }
  Object.values(samOus).forEach((team) => {
    transformTeam(team);
    team.extraInfo = team.delegations.join(', ').replace(/,\s([^,]+)$/, ' & $1');
    ret[team.type.toLowerCase()].push(team);
    if (
      team.type === GOVERNINGINSTITUTION &&
      team.positions.some(
        (pos) => pos.permalink === BESTUURDER || pos.permalink === LID_VAN_HET_DAGELIJKS_BESTUUR
      )
    ) {
      team.isNotAllowedToEnd = true;
    }
    team.actions = team.positions.some((pos) => pos.type === 'REQUEST') ? [{ label: 'stuur herinnering', glyphicon: 'envelope', event: 'sendReminder' }] : [];
  });
  Object.values(vosInstiutions).forEach((team) => {
    transformTeam(team);
    team.extraInfo = team.delegations.join(', ').replace(/,\s([^,]+)$/, ' & $1');
    team.actions = team.positions.some((pos) => pos.type === 'REQUEST') ? [{ label: 'stuur herinnering', glyphicon: 'envelope', event: 'sendReminder' }] : [];
    ret[team.type.toLowerCase()].push(team);
  });
  ret.boarding.sort(sortTeams);
  ret.governinginstitution.sort(sortTeams);
  ret.cvo.sort(sortTeams);
  ret.clb.sort(sortTeams);
  ret.schoolcommunity.sort(sortTeams);
  const workgroupTypes = new Set();
  Object.values(kathondVlaWorkgroups).forEach((team) => {
    team.positions.sort(sortPositions);
    [team.position] = team.positions;
    team.positionClass =
      team.positions.length > 0 ? team.positions[0].positionClass : { sortOrder: 5 };
    team.respType =
      team.positions.length === 0 || team.positions.some((p) => p.type === 'RESPONSIBILITY')
        ? 'RESPONSIBILITY'
        : 'REQUEST';
    team.positionString =
      (team.respType === 'REQUEST' ? `aanvraag${team.positions.length > 0 ? ': ' : ''}` : '') +
      team.positions
        .filter((p) => p.name)
        .map((p) =>
          p.type === 'REQUEST' && team.respType === 'RESPONSIBILITY'
            ? `<span class="request-position"> aanvraag: ${p.name}</span>`
            : p.name
        )
        .join(', ')
        .replace(/,\s([^,]+)$/, ' & $1');
    team.period = printDate(team.startDate) + (team.endDate ? ` - ${printDate(team.endDate)}` : '');
    team.extraInfo = team.delegations.join(', ').replace(/,\s([^,]+)$/, ' & $1');
    if (team.type === LERARENNETWERK) {
      if (team.position && team.position.permalink === TREKKER) {
        team.isNotAllowedToEnd = true;
        ret.lerarennetwerken.chairmen.push(team);
      } else if (team.position && team.position.permalink === LID) {
        ret.lerarennetwerken.members.push(team);
      } else {
        ret.lerarennetwerken.others.push(team);
      }
    } else {
      workgroupTypes.add(team.type);
      if (team.position && team.position.permalink === VOORZITTER) {
        team.isNotAllowedToEnd = true;
        ret.kathOndVlaWorkgroups.chairmen.push(team);
      } else if (team.position && team.position.permalink === LID) {
        ret.kathOndVlaWorkgroups.members.push(team);
      } else {
        ret.kathOndVlaWorkgroups.others.push(team);
      }
    }
  });
  ret.kathOndVlaWorkgroupsTitle = getWorkgroupsTitle(Array.from(workgroupTypes));
  ret.kathOndVlaWorkgroups.chairmen.sort(sortTeams);
  ret.kathOndVlaWorkgroups.members.sort(sortTeams);
  ret.kathOndVlaWorkgroups.others.sort(sortTeams);
  ret.lerarennetwerken.chairmen.sort(sortTeams);
  ret.lerarennetwerken.members.sort(sortTeams);
  ret.lerarennetwerken.others.sort(sortTeams);
  Object.values(organisations).forEach((team) => {
    transformTeam(team);
    team.extraInfo = team.delegations.join(', ').replace(/,\s([^,]+)$/, ' & $1');
    team.actions = team.positions.some((pos) => pos.type === 'REQUEST') ? [{ label: 'stuur herinnering', glyphicon: 'envelope', event: 'sendReminder' }] : [];
    if (team.isKathOndVla) {
      team.isNotAllowedToEnd = true;
      ret.kathOndVla.push(team);
    } else {
      ret.others.push(team);
    }
  });
  return ret;
};

export const getProfileInfo = (state) => {
  const { person } = state.profileState;
  if (!person) {
    return {};
  }
  const profilePictures = person.attachments.filter(
    (att) => att.$$expanded.type === 'PROFILE_IMAGE' && att.$$expanded.name === 'profile-medium.jpg'
  );
  const vmPerson = {
    permalink: person.$$meta.permalink,
    sex: person.sex,
    title: person.title,
    firstName: person.firstName,
    lastName: person.lastName,
    username: person.username,
    dateOfBirth: person.dateOfBirth,
    registrationNumber: person.registrationNumber,
    primaryEmail: state.profileState.primaryEmail ? state.profileState.primaryEmail.value : null,
    secondaryEmail: state.profileState.secondaryEmail
      ? state.profileState.secondaryEmail.value
      : null,
    officePhone: state.profileState.officePhone ? state.profileState.officePhone.number : null,
    officeMobilePhone: state.profileState.officeMobilePhone
      ? state.profileState.officeMobilePhone.number
      : null,
    officeAddress: state.profileState.officeAddress,
    personalPhone: state.profileState.personalPhone
      ? state.profileState.personalPhone.number
      : null,
    personalMobilePhone: state.profileState.personalMobilePhone
      ? state.profileState.personalMobilePhone.number
      : null,
    personalAddress: state.profileState.personalAddress,
    ibanExpenses: state.profileState.expensesBankAccount
      ? printIban(state.profileState.expensesBankAccount.iban)
      : null,
    biography: person.biography,
  };
  const teamInfo = getTeamInfo(state);
  const registrationNumberWarnings = [];
  if (!person.registrationNumber && teamInfo.positionWhichRequiresRegistrationNumber) {
    registrationNumberWarnings.push({
      code: 'registration.number.required',
      message: `Als ${teamInfo.positionWhichRequiresRegistrationNumber.name} zou je een stamboeknummer moeten hebben. Je kan het stamboeknummer vinden op jouw lerarenkaart.`,
    });
  }
  const registrationNumberErrors = [];
  if (
    state.profileState.reservedRegistrationNumbers.some(
      (registrationNumber) => registrationNumber === person.registrationNumber
    )
  ) {
    registrationNumberErrors.push({
      code: 'duplicate.registrationnumber',
      message:
        'Dit stamboeknummer staat al genoteerd bij een andere account. Vermoedelijk zit je dubbel in de databank. Neem contact op met Katholiek Onderwijs Vlaanderen op op 02 507 08 88 om jouw beide accounts samen te laten voegen.',
    });
  }
  const usernameErrors = [];
  if (state.profileState.reservedUsernames.some((username) => username === person.username)) {
    usernameErrors.push({
      code: 'username.reserved',
      message: 'Er is al een andere account met deze gebruikersnaam',
    });
  }
  const primaryEmailErrors = [];
  const primaryEmailWarnings = [];
  const secondaryEmailErrors = [];
  const secondaryEmailWarnings = [];
  if (
    teamInfo.isKathOndVlaEmployee &&
    vmPerson.primaryEmail &&
    !vmPerson.primaryEmail.match(/@katholiekonderwijs\.vlaanderen$/)
  ) {
    primaryEmailErrors.push({
      code: 'kathondvla.required',
      message:
        'Als medewerker van Katholiek Onderwijs Vlaanderen ben je verplicht om een @katholiekonderwijs.vlaanderen e-mailadres te gebruiken.',
    });
  } else if (
    !teamInfo.isKathOndVlaEmployee &&
    state.profileState.hasKathOndVlaMailbox &&
    vmPerson.primaryEmail &&
    !vmPerson.primaryEmail.match(/@katholiekonderwijs\.vlaanderen$/)
  ) {
    primaryEmailWarnings.push({
      code: 'has.access.to.kathondvla.mailbox',
      message:
        'Je hebt recht op een @katholiekonderwijs.vlaanderen mailbox. Indien je hier een ander e-mailadres registreert, wordt die mailbox verwijderd met verlies van alle inhoud. Daarnaast zal je "Vergaderingen" binnen Office 365 Teams niet zien.',
    });
  }
  if (!teamInfo.isKathOndVlaEmployee && !state.profileState.hasKathOndVlaMailbox) {
    if (vmPerson.primaryEmail && vmPerson.primaryEmail.match(/@katholiekonderwijs\.vlaanderen$/)) {
      primaryEmailErrors.push({
        code: 'no.access.to.kathondvla.mailbox',
        message:
          'Je hebt geen toegang tot een @katholiekonderwijs.vlaanderen mailbox. E-mails naar dit e-mailadres zullen niet aankomen.',
      });
    } else if (
      vmPerson.secondaryEmail &&
      vmPerson.secondaryEmail.match(/@katholiekonderwijs\.vlaanderen$/)
    ) {
      secondaryEmailErrors.push({
        code: 'no.access.to.kathondvla.mailbox',
        message:
          'Je hebt geen toegang tot een @katholiekonderwijs.vlaanderen mailbox. E-mails naar dit e-mailadres zullen niet aankomen.',
      });
    }
  }
  if (
    state.profileState.reservedEmailAddresses.some(
      (emailAddress) => emailAddress === vmPerson.primaryEmail
    )
  ) {
    primaryEmailWarnings.push({
      code: 'email.address.not.unique',
      message:
        'Dit e-mailadres is ook ingevuld bij een andere account. De informatie die naar dit e-mailadres wordt gestuurd, is enkel voor jou bestemd, zeker als het over wachtwoorden gaat. Je vult hier dus beter geen e-mailadres in dat je met iemand anders deelt.',
    });
  }
  if (vmPerson.secondaryEmail && vmPerson.primaryEmail === vmPerson.secondaryEmail) {
    secondaryEmailWarnings.push({
      code: 'primary.equals.secondary',
      message:
        'Het reserve e-mailadres is hetezelfde als het primaire e-mailadres. Dit heeft geen enkele zin. Je mag het reserve e-mailadres ook gewoon leeg laten.',
    });
  } else if (
    state.profileState.reservedEmailAddresses.some(
      (emailAddress) => emailAddress === vmPerson.secondaryEmail
    )
  ) {
    secondaryEmailWarnings.push({
      code: 'email.address.not.unique',
      message:
        'Dit e-mailadres is ook ingevuld bij een andere account. Dit e-mailadres is enkel bedoeld om jouw wachtwoord te wijzigen en je vult hier dus beter geen e-mailadres in dat je met iemand anders deelt.',
    });
  }
  return {
    person: vmPerson,
    teams: teamInfo,
    profilePicture:
      profilePictures.length > 0
        ? `${profilePictures[0].$$expanded.href}?${profilePictures[0].$$expanded.key}`
        : null,
    linkedAccounts: !state.profileState.account
      ? null
      : state.profileState.account.externalLoginProviders.map((elp) => {
          let type = null;
          if (elp.loginProvider.href === '/loginproviders/1b0760e8-71a1-11e9-b9f8-43f786c5ce5d') {
            if (elp.loginProviderAlias === 'SCHOOLWARE') {
              type = 'Schoolware';
            } else {
              type = 'Office365';
            }
          } else if (
            elp.loginProvider.href === '/loginproviders/bd0f545a-71a0-11e9-845d-9fdda140c8d8'
          ) {
            type = 'Smartschool';
          }
          let { alias } = elp;
          if (elp.domain) {
            alias += ` (${elp.domain})`;
          }
          return {
            providerId: elp.providerKey,
            loginProviderAlias: elp.loginProviderAlias,
            type,
            alias,
          };
        }),
    hasKathOndVlaMailbox: state.profileState.hasKathOndVlaMailbox,
    managedExternalBy: person.$$meta.managedExternalBy,
    showPersonalContactDetails: state.profileState.showPersonalContactDetails,
    validation: {
      titleErrors: state.profileState.titleErrors,
      registrationNumberErrors,
      registrationNumberWarnings,
      dateOfBirthErrors: state.profileState.dateOfBirthErrors,
      usernameErrors,
      primaryEmailErrors,
      primaryEmailWarnings,
      secondaryEmailErrors,
      secondaryEmailWarnings,
      officePhoneErrors: state.profileState.officePhoneErrors,
      officeMobilePhoneErrors: state.profileState.officeMobilePhoneErrors,
      personalPhoneErrors: state.profileState.personalPhoneErrors,
      personalMobilePhoneErrors: state.profileState.personalMobilePhoneErrors,
      ibanExpenseErrors: state.profileState.ibanExpenseErrors,
    },
  };
};
