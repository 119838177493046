import { MyDelegateRole } from './myDelegateRole';

export const myDelegationComponent = {
  template: require('./myDelegateRole.html'),
  controllerAs: 'ctrl',
  controller: MyDelegateRole,
  transclude: false,
  bindings: {
    sDelegation: '<',
  },
};
