import { getNow, getPreviousYear, isAfter } from '@kathondvla/sri-client/date-utils';

const currentYear = new Date().getFullYear();

export const emailAddressPattern = /^[_a-zA-Z0-9-&!#\\$%´`\*\+/=\?\^']+(\.[_a-zA-Z0-9-&!#\$%´`\*\+/=\?\^']+)*(?!.*[@\-.]{2})@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z][a-zA-Z]+$/

export const getDateOfBirthFromRegistrationNumber = (rn) => {
  const yearDigits = rn.substring(1, 3);
  return `${
    (yearDigits <= currentYear.toString().substring(2, 4) ? '20' : '19') + yearDigits
  }-${rn.substring(3, 5)}-${rn.substring(5, 7)}`;
};

export const getDateOfBirthFromNationalIdentityNumber = (rn) => {
  const yearDigits = rn.substring(0, 2);
  const firstTwoDigitsOfYearOfBirth =
    yearDigits <= currentYear.toString().substring(2, 4)
      ? currentYear.toString().substring(0, 2)
      : parseInt(currentYear.toString().substring(0, 2), 10) - 1;
  return `${firstTwoDigitsOfYearOfBirth + yearDigits}-${rn.substring(2, 4)}-${rn.substring(4, 6)}`;
};

export const validateRegistrationNumber = (registrationNumber) => {
  if (
    parseInt(registrationNumber.substr(0, 9), 10) % 97 !==
    parseInt(registrationNumber.substr(9), 10) % 97
  ) {
    return {
      code: 'invalid.controlnumber',
      message: 'Dit is geen geldig stamboeknummer',
    };
  }
  return null;
};

export const validateDateOfBirthAndRegistrationNumber = (dateOfBirth, rn, rnValid) => {
  if (!rn || !dateOfBirth || !rnValid) {
    return null;
  }
  return getDateOfBirthFromRegistrationNumber(rn) === dateOfBirth
    ? null
    : 'De geboortedatum komt niet overeen met het stamboeknummer.';
};

export const validateSexAndRegistrationNumber = (sex, rn, rnValid) => {
  if (!rn || !sex || !rnValid || rn.charAt(0) === '3') {
    return null;
  }
  return (rn.charAt(0) === '1' && sex === 'MALE') || (rn.charAt(0) === '2' && sex === 'FEMALE')
    ? null
    : 'De aanspreektitel komt niet overeen met het stamboeknummer.';
};

export const getTitleErrors = (sex, registrationNumber, registrationNumberValid) => {
  const titleErrors = [];
  if (!sex) {
    titleErrors.push({
      code: 'title.required',
      message: 'De aanspreektitel is verplicht',
    });
  }
  if (validateSexAndRegistrationNumber(sex, registrationNumber, registrationNumberValid)) {
    titleErrors.push({
      code: 'title.does.not.match.registrationnumber',
      message: 'De aanspreektitel komt niet overeen met het stamboeknummer',
    });
  }
  return titleErrors;
};

export const getDateOfBirthErrors = (dateOfBirth, registrationNumber, registrationNumberValid) => {
  const dateOfBirthErrors = [];
  if (
    validateDateOfBirthAndRegistrationNumber(
      dateOfBirth,
      registrationNumber,
      registrationNumberValid
    )
  ) {
    dateOfBirthErrors.push({
      code: 'dateofbirth.does.not.match.registrationnumber',
      message: 'De geboortedatum komt niet overeen met het stamboeknummer',
    });
  }
  if (isAfter(dateOfBirth, getPreviousYear(getNow(), 2))) {
    dateOfBirthErrors.push({
      code: 'dateofbirth.too.late',
      message: 'Het jaartal van de geboortedatum klopt niet.',
    });
  }
  return dateOfBirthErrors;
};

export const validatePasswordStrength = (password) => {
  const rulesMessage =
    'moet minstens één hoofdletter en minstens één cijfer bevatten, gebruik letters, cijfers of volgende speciale tekens: !@#$%^$&+-=[]{}|:/?_';
  const rules = ['[A-Z]', '[0-9]', '[a-z]', '[^A-Za-z0-9]'];

  if (password.length < 8) {
    return {
      code: 'password.too.short',
      message: `Het wachtwoord is niet sterk genoeg. Het moet minimum 8 karakters bevatten.`,
    };
  }

  let matches = 0;

  rules.forEach((rule) => {
    if (password.match(rule)) {
      matches += 1;
    }
  });

  if (matches < 3) {
    return {
      code: 'password.too.weak',
      message: `Het wachtwoord is niet sterk genoeg. Het ${rulesMessage}`,
    };
  }
  return null;
};
