import pDebounce from 'p-debounce';
import { emailAddressPattern } from '../../module/personUtils';

require('./myEmailList.scss');

export class MyEmailList {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.pendingTags = [];
    if (!this.sEmailList) {
      this.sEmailList = [];
    }
    this.debouncedTagAdded = pDebounce(this.manageDuplicates, 50);
    this.emailPattern = emailAddressPattern.toString().substring(1, emailAddressPattern.toString().length - 1);
    this.invitedMemberIcon = require('Img/icon_invited_member.svg');
  }

  /* $onInit() {
    this.$scope.$watch(() => this.emailList, (n, o) => {
      console.log('the email list changed', n)
    });
  } */

  delegateClickEvent() {
    this.$scope.$emit('emailButtonClicked', this.sEmailList);
  }

  manageDuplicates() {
    const allEmails = this.sEmailList.map((item) => item.text);
    const uniqueEmails = Array.from(new Set(allEmails));
    if (allEmails.length !== uniqueEmails.length) {
      console.log(
        'Oh my god! er zijn dubbels! We lossen dit even op en maken er een unieke lijst van.'
      );
      this.sEmailList = uniqueEmails.map((text) => ({ text }));
      this.$scope.$apply();
    }
  }

  onTagAdded() {
    this.debouncedTagAdded();
  }
}
