import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { displayOuType } from 'ReduxLoop/appConfig';
import { DIRECTEUR } from 'ReduxLoop/constants';

export class MyConfirmInvitation {
  constructor($scope, $ngRedux, $state, settings) {
    'ngInject';

    this.$scope = $scope;
    this.settings = settings;
    this.showIcon = require('Img/check.svg');
    this.teamIcon = require('Img/people.svg');
    this.profileIcon = require('Img/user.svg');
    this.showTitle = '';
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.errors = [];
    this.successes = [];

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      type: state.invite.type,
      invitationKey: state.invite.invitationKey,
      invitationMap: state.invite.invitationMap,
      position: state.invite.position,
      isManager: state.invite.position && state.invite.position.$$meta.permalink === DIRECTEUR,
      ouMap: state.invite.organisationalUnits.reduce(
        (acc, cur) => ({ ...acc, [cur.key]: cur }),
        {}
      ),
      ou: state.invite.organisationalUnits.length > 0 ? state.invite.organisationalUnits[0] : null,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.execute();
  }

  goToProfile() {
    this.$state.go('profile');
  }

  goToTeam() {
    this.$state.go('schoolSelecteer', { type: displayOuType(this.ou.type) });
  }

  async execute() {
    if (this.type === 'INVITATION') {
      const error = await this.acceptInvitation(this.invitationKey);
      if (error) {
        this.showIcon = require('Img/cross.svg');
        this.showTitle = 'Mislukt';
        this.errors.push(error);
      } else {
        this.successes.push({ position: this.position, ou: this.ou });
      }
      this.$scope.$apply();
    } else if (this.type === 'MULTIPLE_INVITATIONS') {
      for (const ouKey in this.invitationMap) {
        const invitation = this.invitationMap[ouKey];
        if (invitation.selected) {
          const error = await this.acceptInvitation(invitation.invitationKey);
          if (!error) {
            this.successes.push({ position: this.position, ou: this.ouMap[ouKey] });
          } else if (
            error.code === 'overlapping.period' ||
            !this.errors.some((other) => other.code === error.code)
          ) {
            this.errors.push(error);
          }
        }
      }
      if (this.successes.length === 0) {
        this.showIcon = require('Img/cross.svg');
        this.showTitle = 'Mislukt';
      } else if (this.errors.length > 0) {
        this.showTitle = 'Gedeeltelijk gelukt!';
      }
      this.$scope.$apply();
    } else {
      this.errors.push({
        code: 'no.invitation.type',
        message: 'Er is iets mis gegaan. Gelieve het nog eens te proberen.',
      });
    }
  }

  async acceptInvitation(key) {
    try {
      await sriClient.post('/responsibilities/pending/accept', { confirmationCode: key });
      this.showTitle = 'Gelukt!';
      return null;
    } catch (err) {
      console.log('error', err);
      if (!err.body || !err.body.errors) {
        return {
          code: 500,
          message: 'Er is een onverwachte fout opgetreden. Probeer het later nog eens opnieuw.',
        };
      }
      let ret = null;
      err.body.errors.forEach((error) => {
        switch (error.code) {
          case 'link.expired':
            ret = {
              code: error.code,
              message:
                'De uitnodiging is niet langer geldig. Ga naar je profiel om je lidmaatschap aan het team aan te vragen.',
            };
            break;
          case 'link.already.used':
            ret = {
              code: error.code,
              message: 'Deze link is al eens gebruikt om de uitnodiging goed te keuren.',
            };
            break;
          case 'invitation.declined':
            ret = {
              code: error.code,
              message: `De uitnodiging is ingetrokken door één van de beheerders van ${this.ou.name}`,
            };
            break;
          case 'overlapping.period':
            ret = {
              code: error.code,
              message: `${
                (this.position
                  ? `Je bent al ${this.position.name} in `
                  : 'Je zit al in het team van ') + this.ou.name
              }. Je kan deze uitnodiging dus negeren.`,
            };
            break;
          case 'forbidden':
            console.warn(
              `USER_NOT_INVITED_PERSON: authenticated user is not the person who was invited. Invitation: ${key}`
            );
            ret = {
              code: error.code,
              message:
                'Deze uitnodiging is niet voor jou bedoeld en is in de verkeerde mailbox terecht gekomen. Indien je een dubbelbaan uitoefent is deze uitnodiging waarschijnlijk voor jouw collega bedoeld. Jouw uitnodiging heb je ontvangen op het e-mailadres dat bij jouw account is ingevuld.',
            };
            break;
          default:
            ret = { code: error.code, message: error.code };
            break;
        }
      });
      return ret;
    }
  }
}
