export const CLASSES_INFO_FETCHED = 'classes_info_fetched';
export const ADD_CLASS = 'add_class';
export const END_CLASS = 'end_class';
export const DELETE_CLASS = 'delete_class';
export const EDIT_CLASS = 'edit_class';
export const APPLY_FILTER = 'apply_filter';
export const CLASS_ACTION_FAILED = 'class_action_failed';

export const classesInfoFetchedAction = (clazzInfo) => ({
  type: CLASSES_INFO_FETCHED,
  payload: clazzInfo,
});

export const addClassAction = (clazzInfo) => ({
  type: ADD_CLASS,
  payload: clazzInfo,
});

export const endClassAction = (clazzInfo) => ({
  type: END_CLASS,
  payload: clazzInfo,
});

export const deleteClassAction = (clazzInfo) => ({
  type: DELETE_CLASS,
  payload: clazzInfo,
});

export const editClassAction = (clazzInfo) => ({
  type: EDIT_CLASS,
  payload: clazzInfo,
});

export const applyFilterAction = (filter) => ({
  type: APPLY_FILTER,
  payload: filter,
});

export const classActionFailed = (error, state) => {
  return {
    type: CLASS_ACTION_FAILED,
    payload: {
      error,
      previousState: state,
    },
  };
};
