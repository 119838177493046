import { COOKIE_TYPE } from '../constants';

export function getCookie(name) {
  const cookie = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (cookie) return cookie[2];
  return null;
}

export function cookiesAccepted() {
  return getCookie(COOKIE_TYPE.CONSENT) === 'true';
}

export function analyticCookiesAccepted() {
  return getCookie(COOKIE_TYPE.ANALYTIC) === 'true';
}
