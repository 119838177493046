export const OU_CONTACT_DETAILS_FETCHED = 'OU_CONTACT_DETAILS_FETCHED';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const VALIDATE_EMAIL_ADDRESS = 'validate_email_address';
export const UPDATE_PHONE = 'update_phone';
export const UPDATE_WEBSITE = 'update_website';
export const UPDATE_ADDRESS = 'update_address';
export const UPDATE_CONTACT_DETAILS_FORM = 'update_contact_details_form';
export const UPDATE_CONTACT_DETAIL_SUCCESS = 'update_contact_detail_success';
export const REMOVE_CONTACT_DETAIL_SUCCESS = 'remove_contact_detail_success';
export const UPDATE_CONTACT_DETAIL_FAILED = 'update_contact_detail_failed';

export const ouContactDetaislFetched = (contactDetails) => ({
  type: OU_CONTACT_DETAILS_FETCHED,
  payload: contactDetails,
});

export const updateEmail = (email) => ({
  type: UPDATE_EMAIL,
  payload: email,
});

export const validateEmailAddress = (email) => ({
  type: VALIDATE_EMAIL_ADDRESS,
  payload: email,
});

export const updatePhone = (phone) => ({
  type: UPDATE_PHONE,
  payload: phone,
});

export const updateWebsite = (website) => ({
  type: UPDATE_WEBSITE,
  payload: website,
});

export const updateAddress = (address) => ({
  type: UPDATE_ADDRESS,
  payload: address,
});

export const updateContactDetailsForm = (errorSumary) => ({
  type: UPDATE_CONTACT_DETAILS_FORM,
  payload: errorSumary,
});

export const updateContactDetailSuccess = (contactDetail, successMessage) => ({
  type: UPDATE_CONTACT_DETAIL_SUCCESS,
  payload: {
    contactDetail,
    successMessage,
  },
});

export const removeContactDetailSuccess = (contactDetail, successMessage) => ({
  type: REMOVE_CONTACT_DETAIL_SUCCESS,
  payload: {
    contactDetail,
    successMessage,
  },
});

export const updateContactDetailFailed = (type, error) => ({
  type: UPDATE_CONTACT_DETAIL_FAILED,
  payload: {
    type,
    error,
  },
});
