import { sriClient } from 'ReduxLoop/utils/apiConfig';

const Batch = require('@kathondvla/sri-client/batch');

export const newsletterUnsubscribeCmd = async (newsletterSubscriptionKeys) => {
  const batch = new Batch(sriClient);

  newsletterSubscriptionKeys.forEach((prefereceKey) =>
    batch.post('/newsletter/anonymousunsubscribe', {
      secretKey: prefereceKey,
    })
  );

  return batch.send('/newsletter/anonymousunsubscribe/batch', sriClient);
};

export const newsletterSetUnsubscribeReasonsCmd = async (
  newsletterSubscriptionKeys,
  predefinedReasons,
  customReason
) => {
  const batch = new Batch(sriClient);

  newsletterSubscriptionKeys.forEach((prefereceKey) =>
    batch.post('/newsletter/anonymousunsubscriptions', {
      secretKey: prefereceKey,
      unsubscription: {
        predefinedReasons,
        customReason,
      },
    })
  );

  return batch.send('/newsletter/anonymousunsubscriptions/batch', sriClient);
};
