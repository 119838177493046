import { DELEGATED_ADMIN, LERARENNETWERK, MANAGER } from 'ReduxLoop/constants';

export const getShowCreateInvitationLink = (state, currentUserRole) => {
  if (!state.schoolManager.currentOrganisationalUnit) {
    return false;
  }
  return (
    state.schoolManager.currentOrganisationalUnit.type === LERARENNETWERK ||
    (currentUserRole.role !== MANAGER && currentUserRole.role !== DELEGATED_ADMIN)
  );
};
