// global fetch, URL
// import {
//   DIRECTEUR, VSKO_VERANTWOORDELIJKE, OU_TYPE_CONFIG, CHILD_MANAGERS
// } from 'ReduxLoop/api/apiResourceFactory';
import { getNonAbolishedResources } from '@kathondvla/sri-client/date-utils';
// const LogRocket = require('logrocket');
import LogRocket from 'logrocket';
import {
  sriClient,
  oauthClient,
  securityClient,
  goToOauthLogin,
  goToLogOut,
} from 'ReduxLoop/utils/apiConfig';
import { settings } from 'Js/config/settings';
import { getKathOndvlaRespInfo } from 'ReduxLoop/profile/profileCommands';
import { LOGIN_METHODS } from 'ReduxLoop/constants';
import { getUserTargetGroup } from './piwikUtils';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export async function getUser() {
  const me = await oauthClient.get('/me', undefined, { credentials: 'include' });
  return sriClient.get(`/persons/${me.uuid}`);
}

export function goToOauthLoginScreen() {
  goToOauthLogin();
}

export function identifyUserInLogRocket(userInfo) {
  if (settings.logRocket.enabled) {
    LogRocket.identify(userInfo.key, {
      name: `${userInfo.firstName} ${userInfo.lastName}`,
      email: userInfo.$$email,
    });
  }
}

export function doLogOut() {
  goToLogOut();
}

export const getOrganisationalUnit = async (key) => {
  const ouPermalink = `/sam/organisationalunits/${key}`;
  let ou = null;
  try {
    ou = await sriClient.get(ouPermalink);
  } catch (err) {
    if (err instanceof SriClientError && err.status === 404) {
      const vosPermalink = `/organisations/${key}`;
      ou = await sriClient.get(vosPermalink);
    } else {
      throw err;
    }
  }
  return ou;
};

export const getPositions = () => {
  return sriClient.getAll('/positions', { expand: 'FULL' });
};

const getOusFromResourcesRaw = (resourcesRaw, regex) => {
  const ret = [];
  const samOrgsToRead = resourcesRaw.filter((raw) => raw.match(regex));
  samOrgsToRead.forEach((raw) => {
    const regExpUuid = new RegExp(
      /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g
    );
    let m;
    do {
      m = regExpUuid.exec(raw);
      if (m) {
        ret.push(m[1]);
      }
    } while (m);
  });
  return ret;
};

export const getUserResponsibilities = async (user) => {
  const responsibilities = await sriClient.getAll('/responsibilities', {
    persons: user.$$meta.permalink,
    expand: 'FULL',
    expandRelationsTo: 'SUMMARY',
    expandPosition: 'NONE',
  });

  const nonAbolishedResponsibilities = getNonAbolishedResources(responsibilities);

  const samHrefs = Array.from(
    new Set(
      nonAbolishedResponsibilities
        .filter((resp) => resp.organisation.href.match(/^\/sam\//g))
        .map((resp) => resp.organisation.href)
    )
  );
  const ous = await sriClient.getAllHrefs(samHrefs);

  // const groupManagement = nonAbolishedResponsibilities.filter(resp => resp.organisation.href.match(/^\/sam\//g) && resp.position && CHILD_MANAGERS.some(positionHref => positionHref === resp.position.href)); // TODO make this work with OU_TYPE_CONFIG when there are more types
  const teacherGroupHrefs = [];
  nonAbolishedResponsibilities
    .filter((resp) => resp.organisation.href.match(/^\/sam\//g))
    .forEach((resp) => {
      const ou = ous.filter((thisOu) => thisOu.$$meta.permalink === resp.organisation.href)[0];
      if (ou && ou.type === 'TEACHER_GROUP') {
        teacherGroupHrefs.push(ou.$$meta.permalink);
      }
    });
  const parentRelations =
    teacherGroupHrefs.length === 0
      ? []
      : await sriClient.getAll(
          '/sam/organisationalunits/relations',
          { type: 'IS_PART_OF', from: teacherGroupHrefs },
          { inBatch: '/sam/organisationalunits/batch' }
        );

  let ousWithAccessToInvitations = [];
  let ousWithReadAccessToResps = [];
  let ousWithUpdateAccessToResps = [];
  let ousWithGrantAccessToSensitiveInformation = [];
  const isSuperAdmin = await securityClient.get(
    '/security/query/allowed',
    {
      ability: 'super_admin',
      component: '/security/components/mijn-app',
      person: user.$$meta.permalink,
    },
    { inBatch: '/security/query/batch' }
  );
  const isKerndataAdmin = await securityClient.get(
    '/security/query/allowed',
    {
      ability: settings.abiltiyForAdminForm,
      component: '/security/components/mijn-app',
      person: user.$$meta.permalink,
    },
    { inBatch: '/security/query/batch' }
  );
  // eslint-disable-next-line no-constant-condition
  if (true || !isSuperAdmin) {
    // TODO check out if this is added for debugging purposses or should if just disappear?
    const pendingOuRegex =
      /^\/responsibilities\/pending\?organisations=\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+)(?:,\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+))*$/g;

    const ouRegex =
      /^\/responsibilities\?organisations=\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+)(?:,\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+))*,?$/g;
    const resourceRawRead = await securityClient.getRaw('/security/query/resources/raw', {
      ability: 'read',
      component: '/security/components/persons-api',
      person: user.$$meta.permalink,
    });
    ousWithAccessToInvitations = getOusFromResourcesRaw(resourceRawRead, pendingOuRegex);
    ousWithReadAccessToResps = getOusFromResourcesRaw(resourceRawRead, ouRegex);

    /* const samOrgsToRead = resourceRawRead.filter(raw => raw.match(regex));
    samOrgsToRead.forEach(raw => {
      const regExpUuid = new RegExp(/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/g);
      let m;
      do {
        m = regExpUuid.exec(raw);
        if (m) {
          readableOus.push(m[1]);
        }
      } while (m);
    }); */

    const resourceRawUpdate = await securityClient.getRaw('/security/query/resources/raw', {
      ability: 'update',
      component: '/security/components/persons-api',
      person: user.$$meta.permalink,
    });
    ousWithUpdateAccessToResps = getOusFromResourcesRaw(resourceRawUpdate, ouRegex);

    const resourcesRawCreate = await securityClient.getRaw('/security/query/resources/raw', {
      ability: 'create',
      component: '/security/components/persons-api',
      person: user.$$meta.permalink,
    });
    ousWithGrantAccessToSensitiveInformation = getOusFromResourcesRaw(
      resourcesRawCreate,
      /^\/responsibilities\?positions=\/positions\/0f61688e-05c2-4e3f-8cea-9da46f5d92cc(:?,\/positions\/[0-9a-f-]{36})*&organisations=\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+)(?:,\/(?:organisations|sam\/organisationalunits)\/(?:[0-9a-f-]+))*,?$/g
    );
  }

  return {
    userResponsibilities: nonAbolishedResponsibilities,
    ous,
    parentRelations,
    ousWithAccessToInvitations,
    ousWithReadAccessToResps,
    ousWithUpdateAccessToResps,
    ousWithGrantAccessToSensitiveInformation,
    isSuperAdmin,
    isKerndataAdmin,
  };
};

export const getUserLoginMethod = async (user) => {
  const result = await sriClient.getList('/loginsessions', {
    persons: `/persons/${user.key}`,
    limit: 1,
    applications: 'mijn-2.0',
    descending: true,
    orderBy: 'start',
    omit: '$$meta.count',
  });
  return LOGIN_METHODS[result[0]?.loginProvider?.href] || null;
};

export const calculateUserTargetGroup = async (
  userResponsibilities,
  ous,
  dienstParentRelations
) => {
  const targetGroups = await sriClient.getAll('/namedsets', {
    tags: 'doelgroepen',
  });
  const result = getUserTargetGroup({
    userResponsibilities,
    targetGroups,
    ous,
    dienstParentRelations,
  });
  return result;
};

export const getVosOus = async (vosResps) => {
  const vosHrefs = Array.from(new Set(vosResps.map((resp) => resp.organisation.href)));
  const ous =
    vosHrefs.length === 0
      ? []
      : await sriClient.getAllReferencesTo('/organisationalunits', undefined, 'hrefs', vosHrefs);
  const map = {};
  ous.forEach((ou) => {
    map[ou.$$meta.permalink] = ou;
  });
  const kathOndVlaRespInfo = await getKathOndvlaRespInfo(vosResps, map);
  kathOndVlaRespInfo.parentOus.forEach((ou) => {
    map[ou.$$meta.permalink] = ou;
  });
  return {
    ous: map,
    parentRelations: kathOndVlaRespInfo.parentRelations,
  };
};

/* export const getCampusses = (ous) => {
  const ouHrefs = ous.map(ou => ou.$$meta.permalink);
  if (ouHrefs.length === 0) {
    return [];
  }
  return sriClient.getAll('/sam/organisationalunits/locations', { organisationalUnit: ouHrefs, expand: 'results.physicalLocation', type: 'CAMPUS', endDateAfter: getNow() });
}; */

export const getAllContactDetails = async (samOus) => {
  const ouHrefs = samOus
    .filter((ou) => ou.type !== 'TEACHER_GROUP')
    .map((ou) => ou.$$meta.permalink);
  if (ouHrefs.length === 0) {
    return [];
  }
  const allContactDetails = await sriClient.getAll('/sam/organisationalunits/contactdetails', {
    organisationalUnit: ouHrefs,
  });
  return allContactDetails.filter((cd) => !cd.physicalLocation);
};
