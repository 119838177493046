import React from 'react';
import {
  KovFooter,
  KovFooterLeftSection,
  KovFooterRightSection,
} from '@kathondvla/react-shared-components/src';
import { getAngularService } from '../../module/ngReactMigrationUtils/utils.jsx';
import MyCookiesLink from './MyCookiesLink.js';

import logo from '../../../img/logo_ko_vl.png';
import './MyReactFooter.scss';

const MyReactFooter = () => {
  const settings = getAngularService('settings');

  const logoSection = {
    imageSrc: logo,
  };

  const helpSectionLink = {
    text: 'handleiding',
    href: settings.applications.manual,
  };

  return (
    <KovFooter>
      <KovFooterLeftSection logoSection={logoSection}>
        <MyCookiesLink />
      </KovFooterLeftSection>
      <div className="hidden-xs footerbar-text">
        Voor jou ontwikkeld door Katholiek Onderwijs Vlaanderen
      </div>
      <KovFooterRightSection helpSectionLink={helpSectionLink}>
        <div className="version">{settings.projectVersion}</div>
      </KovFooterRightSection>
    </KovFooter>
  );
};

export default MyReactFooter;
