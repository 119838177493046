import { Popup } from './myPopup';

export const myPopup = {
  template: require('./myPopup.html'),
  controllerAs: 'ctrl',
  controller: Popup,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
