// eslint-disable-next-line max-classes-per-file
import { settings } from 'Js/config/settings';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  getDateOfBirthFromRegistrationNumber,
  getDateOfBirthFromNationalIdentityNumber,
  validateRegistrationNumber,
} from 'Js/module/personUtils';
import { createEmailAddress } from 'ReduxLoop/utils/personResourceFactory';
import { addProfilePicturesToPerson } from 'ReduxLoop/profile/profileCommands';
import { registerForLinkAccounts } from 'ReduxLoop/registerAndConfirmInvitation/register/registerActions';

const SMARTSCHOOL_ANONYMOUS_AVATAR_MALE_HASHCODE = -2055221148; // -1991553049;// 1161416923;// -32613574;
const SMARTSCHOOL_ANONYMOUS_AVATAR_FEMALE_HASHCODE = -1804673538; // -466965367;// 1842522028;// 1607879434;

const oauthClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.oauth.oauthURL,
});

const apiClient = sriClient;
const Batch = require('@kathondvla/sri-client/batch');

class LoginError {
  constructor(code, message) {
    this.code = code;
    this.message = message;
  }
}

const b64DecodeUnicode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
};

/**
 * Helper function to compute a hash code from a string as found here:
 *  https://stackoverflow.com/a/7616484
 */
const hashCode = (theString) => {
  let hash = 0;
  let chr;
  if (theString.length === 0) {
    return hash;
  }
  for (let i = 0; i < theString.length; i += 1) {
    chr = theString.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

navigator.sayswho = (function () {
  var ua = navigator.userAgent, // eslint-disable-line
    tem, // eslint-disable-line
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; // eslint-disable-line
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem !== null) {
      // return tem.slice(1).join(' ').replace('OPR', 'Opera');
      tem = tem.slice(1);
      return {
        browser: tem[0].replace('OPR', 'Opera'),
        version: tem[1],
      };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) { // eslint-disable-line
    M.splice(1, 1, tem[1]);
  }
  return {
    browser: M[0],
    version: M[1],
  };
})();

const scaleImage = (image, imageSize) => {
  const canvas = document.createElement('canvas');
  canvas.width = imageSize;
  canvas.height = imageSize;
  canvas.getContext('2d').drawImage(image, 0, 0, imageSize, imageSize);
  const result = canvas.toDataURL('image/jpeg', 0.6);
  return result;
};

export class LinkAccounts {
  constructor($scope, $state, $stateParams, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$stateParams = $stateParams;
    this.mijn1 = settings.applications.mijn1;
    this.askPasswordReset = `/#!/vraag-nieuw-wachtwoord`;
  }

  async $onInit() {
    if (!this.$stateParams.userinfo) {
      console.error('There is no userinfo parameter provided!');
    }
    if (this.$stateParams.username) {
      this.username = this.$stateParams.username;
    }
    console.log('userinfo link', this.$stateParams.userinfo);
    // this.userInfo = JSON.parse(atob(this.$stateParams.userinfo));

    try {
      const userInfoResp = await fetch(this.$stateParams.userinfo);
      const userInfoEncodedString = await userInfoResp.text();
      this.userInfo = JSON.parse(b64DecodeUnicode(userInfoEncodedString));
      console.log('userinfo', this.userInfo);

      this.loginProvider = 'Onbekende login provider';
      if (this.userInfo.loginProviderKey === 'bd0f545a-71a0-11e9-845d-9fdda140c8d8') {
        this.coupleIcon = require('../assets/images/couple-account-smartschool.svg');
        this.loginProvider = 'Smartschool';
      } else if (this.userInfo.loginProviderKey === '1b0760e8-71a1-11e9-b9f8-43f786c5ce5d') {
        if (this.userInfo.loginProviderAlias === 'SCHOOLWARE') {
          this.coupleIcon = require('../assets/images/couple-account-schoolware.svg');
          this.loginProvider = 'Schoolware';
        } else {
          this.coupleIcon = require('../assets/images/couple-account-365.svg');
          this.loginProvider = 'Office 365';
        }
      }
      this.alias = this.userInfo.alias + (this.userInfo.domain ? `(${this.userInfo.domain})` : '');
      this.$scope.$apply();
      if (this.userInfo.profilePicture) {
        console.log('profile picture hash', hashCode(this.userInfo.profilePicture.slice(0, 2000)));
      }
      if (
        this.userInfo.profilePicture &&
        this.userInfo.profilePicture.length >= 2000 &&
        (hashCode(this.userInfo.profilePicture.slice(0, 2000)) ===
          SMARTSCHOOL_ANONYMOUS_AVATAR_MALE_HASHCODE ||
          hashCode(this.userInfo.profilePicture.slice(0, 2000)) ===
            SMARTSCHOOL_ANONYMOUS_AVATAR_FEMALE_HASHCODE)
      ) {
        console.warn('The profile picture is an avatar! We will ignore it.');
        this.userInfo.profilePicture = null;
      }
    } catch (e) {
      console.error(e);
      if (!settings.debugMode) {
        window.location.href = `${settings.oauth.oauthURL}/katholiekonderwijs_vlaanderen/onverwachte_fout.html`;
      } else {
        console.error(
          'Onverwachte fout bij koppel accounts! Als de applicatie niet in debug_mode staat zou er nu geredirect worden.'
        );
      }
    }
  }

  async logIn() {
    // log out
    await oauthClient.get('', undefined, {
      baseUrl: settings.oauth.logOut,
      credentials: 'include',
    });

    // send /authenticate call to bootstrap oauth (send client id, response type)
    const data = {
      client_id: settings.oauth.clientID,
      redirect_uri: settings.oauth.redirectUri,
      response_type: 'manual',
      scope: settings.oauth.scope,
    };
    await oauthClient.get('', data, {
      baseUrl: settings.oauth.authenticate,
      credentials: 'include',
      logging: 'get',
    });

    // log in
    const formData = new URLSearchParams();
    formData.append('username', this.username);
    formData.append('password', this.password);
    formData.append('response_type', 'manual');
    const resp = await oauthClient.post('/login', formData.toString(), {
      baseUrl: settings.oauth.oauthURL,
      raw: true,
      mode: 'cors',
      credentials: 'include',
      logging: 'post',
      fullResponse: true,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    });

    return resp;
  }

  async syncPersonData(loginResp, person) {
    if (loginResp.passedUserDataValidation && !this.userInfo.stamboeknummer) {
      console.warn(
        `LINK_ACCOUNTS_NO_REGISTRATIONNUMBER: ${this.username} needs to do user data validation and there is no stamboeknummer provided`
      );
    }
    let stamboekNummer =
      this.userInfo.stamboeknummer.toLowerCase() !== 'null' ? this.userInfo.stamboeknummer : null;
    if (stamboekNummer) {
      stamboekNummer = stamboekNummer.replace(/\s/g, '').replace(/-/g, '');
      if (
        stamboekNummer.length > 11 &&
        validateRegistrationNumber(stamboekNummer.substring(0, 11))
      ) {
        stamboekNummer = stamboekNummer.substring(0, 11);
      }
      if (validateRegistrationNumber(stamboekNummer)) {
        stamboekNummer = null;
        if (this.userInfo.loginProviderAlias !== 'OFFICE365') {
          console.warn(
            `INVALID_EXTERNAL_REGISTRATION_NUMBER: ${this.userInfo.stamboeknummer} received from ${this.loginProvider} for ${this.alias} is not a valid. Linked person is: ${person.username}-${person.key}`
          );
        }
      }
    }
    if (
      person.registrationNumber &&
      stamboekNummer &&
      person.registrationNumber !== stamboekNummer
    ) {
      console.warn(
        `CONFLICTING_REGISTRATION_NUMBERS: ${person.username}-${person.key}: ${person.registrationNumber} for KathOndVla versus ${this.alias}: ${this.userInfo.stamboeknummer} for ${this.loginProvider}`
      );
      throw new LoginError(
        'conflicting.registrationnumbers',
        `Jouw stamboeknummer bij Katholiek Onderwijs Vlaanderen (${person.registrationNumber}) en jouw stamboeknummer bij ${this.loginProvider} (${this.userInfo.stamboeknummer}) komen niet overeen. Neem contact op met Katholiek Onderwijs Vlaanderen op 02 507 07 80 (tussen 8u en 11u45) of mail naar <a href="mailto:gegevensbeheer@katholiekonderwijs.vlaanderen">gegevensbeheer@katholiekonderwijs.vlaanderen</a>)`
      );
    }
    if (
      person.$$nationalIdentityNumber &&
      stamboekNummer &&
      getDateOfBirthFromRegistrationNumber(stamboekNummer) !==
        getDateOfBirthFromNationalIdentityNumber(person.$$nationalIdentityNumber.$$expanded.value)
    ) {
      console.warn(
        `CONFLICTING_REGISTRATION_NUMBERS: ${person.username}-${person.key}: national identity number ${person.$$nationalIdentityNumber.$$expanded.value} for KathOndVla versus ${this.alias}: ${this.userInfo.stamboeknummer} for ${this.loginProvider}`
      );
      throw new LoginError(
        'conflicting.nationalidentitynumber',
        `Het stamboeknummer dat we ontvingen van ${this.loginProvider} (${this.userInfo.stamboeknummer}) komt niet overeen met het rijksregisternummer (${person.$$nationalIdentityNumber.$$expanded.value}) dat gekoppeld is aan jouw Katholiek Onderwijs Vlaanderen account. Neem contact op met Katholiek Onderwijs Vlaanderen op 02 507 07 80 (tussen 8u en 11u45) of mail naar <a href="mailto:gegevensbeheer@katholiekonderwijs.vlaanderen">gegevensbeheer@katholiekonderwijs.vlaanderen</a>)`
      );
    }
    if (!person.registrationNumber) {
      if (stamboekNummer) {
        console.warn(
          `ADDING_STAMBOEKNUMMER: ${stamboekNummer} to ${person.username}-${person.key}`
        );
        person.registrationNumber = stamboekNummer;
        const newDateofBirth = getDateOfBirthFromRegistrationNumber(stamboekNummer);
        if (person.dateOfBirth !== newDateofBirth) {
          if (person.$$meta.managedExternalBy) {
            throw new LoginError(
              'conflicting.dateofbirths',
              `Het stamboeknummer dat we ontvingen van ${this.loginProvider}, ${stamboekNummer}, bevat geboortedatum ${newDateofBirth}, wat niet overeenkomt met jouw geboortedatum, ${person.dateOfBirth}, terwijl jouw persoonsgegevens beheerd worden in ${person.$$meta.managedExternalBy}`
            );
          } else {
            console.warn(
              `DATEOFBIRTH_CHANGED_ON_LINKING: ${person.username}-${person.key} with date of birth ${person.dateOfBirth} is linking with ${this.alias} which has registrationNumber ${stamboekNummer} so we change the date of birth to ${newDateofBirth}`
            );
          }
          person.dateOfBirth = newDateofBirth;
        }
        if (stamboekNummer.charAt(0) === '1') {
          person.title = 'De heer';
          person.sex = 'MALE';
        } else {
          person.title = 'Mevrouw';
          person.sex = 'FEMALE';
        }
        this.batch.put(person);
      } else if (!person.dateOfBirth) {
        console.warn(
          `LINK_ACCOUNTS_NO_DATEOFBIRTH: ${this.username} has no date of birth and there is no stamboeknummer provided by ${this.loginProvider}`
        );
      }
    }
    if (this.userInfo.email) {
      this.userInfo.email = this.userInfo.email.trim();
      if (person.emailAddresses.primary) {
        const emailOccurences = await apiClient.getAll('/contactdetails', {
          expand: 'NONE',
          values: person.emailAddresses.primary.$$expanded.value,
        });
        if (emailOccurences.length > 1) {
          console.warn(
            `LINKED_USER_NO_UNIQUE_EMAIL: ${person.username}-${person.key}, is linking with ${this.loginProvider} which has ${this.userInfo.email} as registered e-mailaddress, ${person.emailAddresses.primary.$$expanded.value} is not unique.`
          );
        }
        if (
          person.emailAddresses.primary.$$expanded.value !== this.userInfo.email &&
          !person.emailAddresses.office
        ) {
          const email = createEmailAddress(this.userInfo.email, person, true);
          this.batch.put(email);
        }
      } else if (!person.emailAddresses.primary) {
        const email = createEmailAddress(this.userInfo.email, person);
        this.batch.put(email);
      }
    }
  }

  async linkAccounts(person) {
    const account = (await apiClient.getList(`/accounts?persons=/persons/${person.key}`))[0];
    const providerHref = `/loginproviders/${this.userInfo.loginProviderKey}`;
    const existing = account.externalLoginProviders.filter(
      (lp) =>
        lp.providerKey === this.userInfo.uuid &&
        lp.loginProvider.href === providerHref &&
        lp.loginProviderAlias === this.userInfo.loginProviderAlias
    );
    if (!existing.length > 0) {
      account.externalLoginProviders.push({
        providerKey: this.userInfo.uuid,
        alias: this.userInfo.alias,
        domain: this.userInfo.domain,
        loginProviderAlias: this.userInfo.loginProviderAlias,
        loginProvider: {
          href: providerHref,
        },
      });
      this.batch.put(account);
    } else {
      console.warn(
        'There already is an existing external login provider linked to this person with the same id and alias!'
      );
      this.loginFailed = `Je bent al gekoppeld met ${this.loginProvider}. Er is iets misgegaan bij het aanmelden want in dat geval kan je niet op dit scherm komen. Sluit de browser en probeer het nog eens opnieuw.  Als het probleem zich blijft voordoen kan je contact opnemen met Katholiek Onderwijs Vlaanderen op 02 507 07 80 (tussen 8u en 11u45) of mail naar <a href="mailto:gegevensbeheer@katholiekonderwijs.vlaanderen">gegevensbeheer@katholiekonderwijs.vlaanderen</a>)`;
    }
  }

  async getProfilePictures() {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = async () => {
        console.log('image', image);
        console.log('image size: ', image.width, 'x', image.height);
        const images = {
          image128: scaleImage(image, 128),
          image256: scaleImage(image, 256),
          // image1024: scaleImage(image, 1024)
        };
        resolve(images);
      };
      image.src = `data:image/png;base64,${this.userInfo.profilePicture}`;
    });
  }

  async link() {
    if (this.loading) {
      return;
    }
    this.loginFailed = null;
    try {
      this.loading = true;
      const resp = await this.logIn();

      if (
        resp.headers['content-type'] &&
        resp.headers['content-type'].match(/application\/json/g)
      ) {
        console.log('login response: ', resp.body);
        this.batch = new Batch();
        const person = await apiClient.get(`/persons/${resp.body.uuid}`, {
          expandEmailAddresses: 'FULL',
          expandNationalIdentityNumber: 'FULL',
        });
        const currentProfilePicture = person.attachments.filter(
          (att) =>
            att.$$expanded.type === 'PROFILE_IMAGE' && att.$$expanded.name === 'profile-medium.jpg'
        )[0];
        try {
          await this.syncPersonData(resp.body, person);
        } catch (err) {
          if (err instanceof LoginError) {
            this.loginFailed = err.message;
            return;
          }
          console.error(
            `LINK_ERROR: Unexpected error when building person resource for ${person.username}-${person.key} when taking over account info from ${this.userInfo.alias}`,
            err
          );
        }

        await this.linkAccounts(person);
        if (this.batch.array.length > 0) {
          try {
            await apiClient.put('/persons/batch', this.batch.array);
            if (!currentProfilePicture && this.userInfo.profilePicture) {
              try {
                await addProfilePicturesToPerson(
                  { original: `data:image/png;base64,${this.userInfo.profilePicture}` },
                  person.$$meta.permalink
                );
              } catch (imgError) {
                console.error(
                  `LINK_ERROR: An error occurred when adding the profile picture to the account of ${person.username} - ${person.key} with ${this.alias}`,
                  imgError
                );
              }
            }
            window.location.href = `${settings.oauth.authenticate}?login_provider=${this.userInfo.loginProviderKey}&callback=true`;
          } catch (apiError) {
            if (apiError.status === 409) {
              console.error('body of apiError: ', apiError.body);
              const personErrorResp = apiError.body.filter((errorResp) =>
                errorResp.href.match(/\/persons\/.+/)
              );
              if (personErrorResp.length > 0) {
                const personErrors = personErrorResp[0].body.errors;
                const duplicateRegistrationErrors = personErrors.filter(
                  (error) => error.code === 'duplicate.registration.number'
                );
                if (duplicateRegistrationErrors.length > 0) {
                  console.warn(
                    `DUPLICATE_PERSON: ${person.username} - ${person.key} tries to link his/her profile, with his/her ${this.loginProvider} account (${this.alias}) with stamboeknummer ${this.userInfo.stamboeknummer} but this is already registered for another user: ${duplicateRegistrationErrors[0].duplicate.href}`
                  );
                  this.loginFailed = `Jouw stamboeknummer dat we ontvingen van ${this.loginProvider} (${this.userInfo.stamboeknummer}) staat al genoteerd bij een andere gebruiker in Katholiek Onderwijs Vlaanderen. Vermoedelijk ben je dubbel geregistreerd in de databank. Neem contact op met Katholiek Onderwijs Vlaanderen op 02 507 07 80 (tussen 8u en 11u45) of mail naar <a href="mailto:gegevensbeheer@katholiekonderwijs.vlaanderen">gegevensbeheer@katholiekonderwijs.vlaanderen</a>) om jouw beide accounts te laten samenvoegen.`;
                } else {
                  console.error(
                    `LINK_ERROR: 409 error occurred for PUT on person resource that we did not expected for user ${person.username} - ${person.key} with ${this.alias}`,
                    apiError.body
                  );
                  this.loginFailed =
                    'Er is een onverwachte fout opgetreden bij het koppelen van jouw account.';
                }
              } else {
                console.error(
                  `LINK_ERROR: 409 error occurred for PUT on account, e-mailaddress or profile picture for user ${person.username} - ${person.key} with ${this.alias}`,
                  apiError.body
                );
                this.loginFailed =
                  'Er is een onverwachte fout opgetreden bij het koppelen van jouw account.';
              }
            } else {
              console.error(
                `LINK_ERROR: unexpected error occurred when trying to link accounts and complete profile information for user ${person.username} - ${person.key} with ${this.alias}`,
                apiError
              );
              this.loginFailed =
                'Er is een onverwachte fout opgetreden bij het koppelen van jouw account.';
            }
          }
        }
      }
    } catch (err) {
      if (err.response) {
        console.error('error', err);
        if (err.status === 401) {
          console.warn(`WRONG_USERNAME_PASSWORD: ${this.username} has entered a wrong password`);
          this.loginFailed = `De gebruikersnaam of het wachtwoord is fout. Probeer het opnieuw of <a href="${this.askPasswordReset}" target="_blank">kies een nieuw wachtwoord.</a>`;
        } else {
          console.warn(
            `UNKNOWN_ERROR_FROM_OAUTH: POST /login returned a none 401 error for ${this.username}`
          );
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw met een recente versie van Chrome of Firefox';
        }
      } else {
        const userAgent = navigator.sayswho;
        console.error(
          `LOGIN_ERROR: ${this.username} got an unexpected exception on ${userAgent.browser} ${userAgent.version}`,
          err
        );
        if (userAgent.browser === 'Chrome') {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw in een incognito venster of in Mozilla Firefox';
        } else if (userAgent.browser === 'Firefox') {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw in een incognito venster of in Google Chrome';
        } else {
          this.loginFailed =
            'Er deed zich een onbekende fout voor. Probeer het eventueel opnieuw met een recente versie van Google Chrome of Mozilla Firefox';
        }
      }
      this.$scope.$apply();
    } finally {
      this.loading = false;
      this.$scope.$apply();
    }
  }

  async register() {
    let profilePictures = null;
    if (this.userInfo.profilePicture) {
      profilePictures = await this.getProfilePictures();
    }
    this.$ngRedux.dispatch(
      registerForLinkAccounts(this.userInfo, profilePictures, this.$stateParams.userinfo)
    );
  }
}
