import oauthSettings from './vsko-oauth-configuration-stripped.json';

export const settings = {
  projectVersion: 'main',
  environment: '',
  debugMode: false,
  abiltiyForAdminForm: 'kerndata_super_admin_prod',
  leeromgevingEnabled: true,
  nascholingsverantwoordelijkeEnabled: true,
  googlAnalytics: {
    enabled: false,
  },
  piwikAnalytics: {
    enabled: true,
    token: '230599e0-6f1e-4826-82fc-abf8ad5eb5ca',
  },
  apis: {
    kathOndVla: 'https://api.katholiekonderwijs.vlaanderen',
    cachedKathOndVla: 'https://cached-api.katholiekonderwijs.vlaanderen',
    samApi: 'https://samenscholing-api.katholiekonderwijs.vlaanderen',
    security: 'https://beveiliging-nodejs.katholiekonderwijs.vlaanderen',
  },
  applications: {
    leeromgeving: 'https://leeromgeving.katholiekonderwijs.vlaanderen/slides/all?my=1',
    mijn1: 'https://mijn-1.katholiekonderwijs.vlaanderen',
    personen: 'https://personen.katholiekonderwijs.vlaanderen',
    newsletter: 'http://nieuwsbrief.katholiekonderwijs.vlaanderen',
    nascholingen: 'https://nascholing.be/deelninschroverz.aspx',
    microsoftTeams: 'https://teams.microsoft.com/',
    manual: 'https://drive.google.com/file/d/1XrkIYyPOKgy0A7G-8aCBX0NiMND8zMJj/view?usp=sharing',
  },
  cookies: {
    standaloneModalWidgetEndpoint:
      'https://standalone-cookie-modal.katholiekonderwijs.vlaanderen/widget.js',
    contentHref:
      'https://content-api-html.katholiekonderwijs.vlaanderen/content/8f850783-7df7-4e38-b144-2ff07dca82b1/html',
    essentialId: '2c2a0cbc-2210-4960-975b-9cb10722562b',
    analyticId: 'c0b19af9-2f77-487a-853a-63522b4e9aca',
  },
  logging: {
    logentries: {
      enabled: true,
      debug: false,
      token: 'ae21c21b-a0ac-4ed7-b8d9-c1c8f8be6dcb',
    },
  },
  oauth: oauthSettings,
  sentry: {
    enabled: true,
    url: 'https://00acb0037a3546978c9c2aafbaf43c4b@o268751.ingest.sentry.io/6236921',
    sentryPerformanceSampleRate: 1.0,
  },
  logRocket: {
    enabled: false,
    token: 'ydysdg/mijn20',
  },
  kathOndVlaCentralDepartments: {
    ACADEMIE: '/organisations/0e1a8f0a-4a38-4a33-af92-fe3e032b51a8',
    BES: '/organisations/127cc3dd-c01a-4b90-b40d-7f2351536ce5',
    CUR_VOR: '/organisations/5a510f9d-4adf-453b-8ca7-c048f635175a',
    ID_KWAL: '/organisations/2678d67b-1e10-4fb2-9b8f-a13e2a131953',
    LERENDEN: '/organisations/a2ea7b10-bf7c-4487-ae81-93da9b695dd0',
    ONDERSTEUNING: '/organisations/af923f7f-dbd4-419f-a078-43cd6af5b454',
    PERSONEEL: '/organisations/2b90a77f-b786-4224-9e81-e82eff213530',
    SCHOOL_KWAL: '/organisations/3f42479c-5478-466b-adbd-07f182888f39',
    STAFDIENST: '/organisations/6250e98e-0b53-431c-abe3-5d600a108fcc',
  },
  vicarialeDiensten: {
    ANTWERPEN: '/organisations/86047e5d-1e29-42ed-80e2-bf038247741a',
    LIMBURG: '/organisations/05166757-3fd2-4798-a8a1-3df98306ab90',
    MECHELEN_BRUSSEL: '/organisations/c8f16845-f65c-4b67-8c74-fc01ddadf0d5',
    OOST_VLAANDEREN: '/organisations/7bcf479a-cd9b-4d3a-b814-9cedca013d4f',
    WEST_VLAANDEREN: '/organisations/771eab9e-3876-43f8-8c23-828295782e09',
  },
  congregaties: {
    PB_BROEDERS_VAN_LIEFDE: '/organisations/c000eaea-9d50-2590-e044-d4856467bfb8',
    PB_JEZUIETEN: '/organisations/c000eaea-9c5b-2590-e044-d4856467bfb8',
    PB_SALESIANEN_VAN_DON_BOSCO: '/organisations/c000eaea-9cc4-2590-e044-d4856467bfb8',
    PB_VLAAMS_LASILLIAANS_PERSPECTIEF: '/organisations/c000eaea-a6d8-2590-e044-d4856467bfb8',
  },
  loketResponsible: {
    name: 'Magda Sabbe',
    email: 'magda.sabbe@katholiekonderwijs.vlaanderen',
    href: '/persons/cf2dccb1-3056-4402-e044-d4856467bfb8',
  },
  popup: [
    {
      key: 1,
      ttl: 7,
      endDate: '2021-03-14T00:00:00Z',
    },
  ],
};