import {
  getDateOfBirthFromRegistrationNumber,
  validateRegistrationNumber,
  getDateOfBirthErrors,
  validateSexAndRegistrationNumber,
  validatePasswordStrength,
} from 'Js/module/personUtils';
import * as ACTIONS from 'ReduxLoop/registerAndConfirmInvitation/register/registerActions';
import {
  changePositionForRequest,
  changeStartDateForRequest,
} from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import { removeNotification } from 'ReduxLoop/notifications/notificationActions';
import { emailAddressPattern } from '../../module/personUtils';

export class Registreren {
  constructor($ngRedux, $uibModal, $stateParams) {
    'ngInject';

    this.titles = ['De heer', 'Mevrouw'];
    this.editIcon = require('Img/edit.svg');
    this.arrowRight = require('Img/arrow-right.svg');
    // this.selectedTitle = this.titles[0];
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.$stateParams = $stateParams;
    this.validateRegistrationNumber = validateRegistrationNumber;
    this.validatePasswordStrength = validatePasswordStrength;
    this.registrationNumberWarnings = [];
    this.emailPattern = {
      pattern: emailAddressPattern,
      message: 'Dit is geen geldig e-mailadres',
    };
    this.namePattern = {
      pattern: /^[^\s!?.0-9][^!?.0-9]*[^\s!?.,;:0-9]$|^[^\s!?.,;:0-9]$/,
      message: 'Geef je volledige naam zonder cijfers of leestekens',
    };
    if (window.innerWidth > 768) {
      this.inputWidth = '50%';
    }

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      invitationKey: state.invite.invitationKey,
      registrationNumberStore: state.register.registrationNumber,
      firstNameStore: state.register.firstName,
      lastNameStore: state.register.lastName,
      dateOfBirthStore: state.register.dateOfBirth,
      emailAddressStore: state.register.emailAddress,
      emailWarnings:
        state.register.duplicateEmails.length > 0
          ? [
              {
                code: 'duplicate.email',
                message: `Dit e-mailadres staat al genoteerd bij ${
                  state.register.duplicateEmails.length
                } andere gebruiker${state.register.duplicateEmails.length > 1 ? 's.' : '.'}`,
              },
            ]
          : [],
      username: state.register.username,
      position: state.invite.position,
      possiblePositions:
        state.vm.requestResponsibility.possiblePositions || state.register.possiblePositions,
      ou: state.invite.organisationalUnits[0],
      startDate: state.invite.startDate,
      requestOu: state.vm.requestResponsibility.ou,
      requestPosition: state.vm.requestResponsibility.selectedPosition,
      requestStartDate: state.vm.requestResponsibility.startDate,
      positionErrors: state.vm.requestResponsibility.positionErrors,
      positionEditable: state.vm.requestResponsibility.positionEditable,
      startDateErrors: state.vm.requestResponsibility.startDateErrors,
      startDateEditable: state.vm.requestResponsibility.startDateEditable,
      requestApiError: state.vm.requestResponsibility.apiError,
      bestuurderSelected: state.vm.requestResponsibility.bestuurderSelected,
      notificationList: state.vm.notifications,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
    if (this.noRnModal) {
      this.noRnModal.dismiss();
    }
    if (this.confirmEmail) {
      this.confirmEmail.dismiss();
    }
  }

  $onInit() {
    this.firstName = this.firstNameStore;
    this.lastName = this.lastNameStore;
    this.dateOfBirth = this.dateOfBirthStore;
    this.registrationNumber = this.registrationNumberStore;
    this.emailAddress = this.emailAddressStore;
    if (this.$stateParams.redirect_url) {
      this.$ngRedux.dispatch(ACTIONS.setRedirectUrl(this.$stateParams.redirect_url));
    }
    /* if (this.$stateParams.userinfo) {
      const userInfo = JSON.parse(atob(this.$stateParams.userinfo));
      this.loginProviderKey = userInfo.loginProviderKey;
      this.userKey = userInfo.uuid;
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      userInfo.registrationNumber = userInfo.stamboeknummer && userInfo.stamboeknummer !== 'NULL' ? userInfo.stamboeknummer : null;
      this.registrationNumber = userInfo.registrationNumber;
      if (this.registrationNumber) {
        this.dateOfBirth = getDateOfBirthFromRegistrationNumber(this.registrationNumber);
      }
      this.emailAddress = userInfo.email;
      this.$ngRedux.dispatch(ACTIONS.setUserinfoFromOauth(userInfo, this.$stateParams.userinfo));
    } */
    if (this.firstName && this.lastName) {
      this.$ngRedux.dispatch(ACTIONS.generateUsername(this.firstName, this.lastName));
    }
    if (this.emailAddress) {
      this.$ngRedux.dispatch(ACTIONS.validateRegisteringEmailAddress(this.emailAddress));
    }
    if (this.registrationNumber) {
      this.selectTitleFromRegistrationNumber();
    }
    this.dateOfBirthErrors = [];
    if (this.position) {
      this.selectedPosition = this.possiblePositions.filter(
        (position) => position.key === this.position.key
      )[0];
      this.selectedPosition.definition = this.selectedPosition.definitions.filter((definition) =>
        definition.applicableIn.ouTypes.some((ouType) => ouType === this.ou.type)
      )[0];
      this.isRegistrationNumberRequired =
        this.ou.type === 'SCHOOLENTITY' &&
        (this.position.definitions[0].positionClass === 'COORDINATOR' ||
          this.position.definitions[0].positionClass === 'MEMBER');
    }
    this.manageRegistrationNumberWarnings();
  }

  manageRegistrationNumberWarnings() {
    this.registrationNumberWarnings = [];
    if (!this.registrationNumber && this.isRegistrationNumberRequired) {
      this.registrationNumberWarnings.push({
        code: 'registration.number.required',
        message: `Als ${this.position.name} zou je een stamboeknummer moeten hebben.`,
      });
    }
  }

  registrationNumberChanged(e, rn) {
    this.registrationNumber = rn;
    if (this.registrationNumber && this.registerForm.formGroups.stamboeknummer.valid) {
      this.dateOfBirth = getDateOfBirthFromRegistrationNumber(this.registrationNumber);
      this.selectTitleFromRegistrationNumber();
    }
    this.manageTitleErrors();
    this.manageDateOfBirthErrors();
    this.manageRegistrationNumberWarnings();
  }

  selectTitleFromRegistrationNumber() {
    if (this.registrationNumber.charAt(0) === '1') {
      this.title = this.titles[0];
      this.selectedTitle = this.titles[0];
      this.sex = 'MALE';
    } else {
      this.title = this.titles[1];
      this.selectedTitle = this.titles[1];
      this.sex = 'FEMALE';
    }
  }

  titleSelected() {
    this.title = this.selectedTitle;
    this.sex = this.title === this.titles[0] ? 'MALE' : 'FEMALE';
    this.manageTitleErrors();
  }

  manageTitleErrors() {
    this.titleErrors = [];
    if (!this.title) {
      this.titleErrors.push({
        code: 'title.required',
        message: 'De aanspreektitel is verplicht',
      });
    }
    if (
      validateSexAndRegistrationNumber(
        this.sex,
        this.registrationNumber,
        this.registerForm.formGroups.stamboeknummer.valid
      )
    ) {
      this.titleErrors.push({
        code: 'title.does.not.match.registrationnumber',
        message: 'De aanspreektitel komt niet overeen met het stamboeknummer',
      });
    }
  }

  dateOfBirthChanged(e, dateOfBirth) {
    this.dateOfBirth = dateOfBirth;
    this.manageDateOfBirthErrors();
  }

  positionChanged(e, position) {
    this.selectedPosition = position;
  }

  manageDateOfBirthErrors() {
    this.dateOfBirthErrors = getDateOfBirthErrors(
      this.dateOfBirth,
      this.registrationNumber,
      this.registerForm.formGroups.stamboeknummer.valid
    );
    /* if (validateDateOfBirthAndRegistrationNumber(this.dateOfBirth, this.registrationNumber, this.registerForm.formGroups.stamboeknummer.valid)) {
      this.dateOfBirthErrors.push({
        code: 'dateofbirth.does.not.match.registrationnumber',
        message: 'De geboortedatum komt niet overeen met het stamboeknummer'
      });
    } */
  }

  firstNameChanged(e, firstName) {
    this.firstName = firstName;
    if (this.firstName && this.lastName) {
      this.$ngRedux.dispatch(ACTIONS.generateUsername(this.firstName, this.lastName));
    }
  }

  lastNameChanged(e, lastName) {
    this.lastName = lastName;
    if (this.firstName && this.lastName) {
      this.$ngRedux.dispatch(ACTIONS.generateUsername(this.firstName, this.lastName));
    }
  }

  emailAddressChanged(e, emailAddress) {
    this.emailAddress = emailAddress;
    if (this.registerForm.formGroups.email.valid) {
      this.$ngRedux.dispatch(ACTIONS.validateRegisteringEmailAddress(this.emailAddress));
    }
  }

  passwordChanged(e, password) {
    this.password = password;
    this.manageDuplicatePasswordErrors();
  }

  repeatPasswordChanged(e, password) {
    this.repeatPassword = password;
    if (this.password && this.registerForm.formGroups.wachtwoord.valid) {
      this.manageDuplicatePasswordErrors();
    }
  }

  manageDuplicatePasswordErrors() {
    this.duplicatePasswordErrors = [];
    if (
      this.registerForm.formGroups.wachtwoord.valid &&
      this.repeatPassword &&
      this.password !== this.repeatPassword
    ) {
      this.duplicatePasswordErrors.push({
        code: 'passwords.do.not.match',
        message: 'De wachtwoorden komen niet overeen.',
      });
    }
  }

  createNoRegistrationModal() {
    this.noRnModal = this.$uibModal.open({
      animation: true,
      component: 'myConfirmNoRegistrationNumber',
      size: 'md',
      backdrop: 'static',
      resolve: {
        position: () => this.position.name,
      },
    });

    this.noRnModal.result.then(
      (result) => {
        if (result) {
          this.registrationNumberChanged(null, result);
        }
        this.noRegistrationNumberConfirmed = true;
        this.register();
      },
      () => {}
    );
  }

  startDateChanged(ev, startDate) {
    this.$ngRedux.dispatch(changeStartDateForRequest(startDate));
  }

  positionChanged() {
    this.$ngRedux.dispatch(changePositionForRequest(this.requestPosition));
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  createConfirmEmailModal() {
    this.emailAddressConfirmed = true;
    this.confirmEmail = this.$uibModal.open({
      animation: true,
      component: 'myRegisterConfirmEmail',
      size: 'md',
      backdrop: 'static',
      resolve: {
        emailAddress: () => this.emailAddress,
        name: () => `${this.firstName} ${this.lastName} (${this.username})`,
      },
    });

    this.confirmEmail.result.then(
      (result) => {
        if (result) {
          this.emailAddress = result;
        }
        this.register();
      },
      () => {}
    );
  }

  register() {
    this.registerAttempted = true;
    this.manageTitleErrors();
    if (
      !this.registerForm.valid ||
      this.dateOfBirthErrors.length > 0 ||
      this.titleErrors.length > 0 ||
      this.duplicatePasswordErrors.length > 0
    ) {
      return;
    }
    if (!this.noRegistrationNumberConfirmed && this.registrationNumberWarnings.length > 0) {
      this.createNoRegistrationModal();
      return;
    }
    if (!this.emailAddressConfirmed && this.emailWarnings.length > 0) {
      this.createConfirmEmailModal();
      return;
    }
    this.registerLoading = true;
    try {
      this.$ngRedux.dispatch(
        ACTIONS.validateRegistration({
          registrationNumber: this.registrationNumber,
          title: this.title,
          sex: this.sex,
          firstName: this.firstName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth,
          emailAddress: this.emailAddress.toLowerCase(),
          username: this.username,
          password: this.password,
        })
      );
    } catch (err) {
    } finally {
      this.registerLoading = false;
    }
  }
}
