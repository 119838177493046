import { userDidSkipContactDetailsModal } from 'ReduxLoop/user/userActions';

require('./missingContactDetailsModal.scss');

export class MissingContactDetailsModal {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.schoolIcon = require('Img/school.svg');
    this.arrowRightIcon = require('Img/arrow-right.svg');
  }

  $onInit() {
    this.schoolEntititiesWithoutMailAddress = this.resolve.schoolEntititiesWithoutMailAddress;
  }

  goToSchoolContactDetails(index) {
    if (!index) {
      index = 0;
    }

    window.location.href =
      window.location.origin + this.schoolEntititiesWithoutMailAddress[index].href;

    this.close({ $value: {} });
  }

  cancel() {
    this.$ngRedux.dispatch(userDidSkipContactDetailsModal());
    this.close({ $value: {} });
  }
}
