import * as ACTION_TYPES from '../../constants/actionTypes';

export const generateUsername = (firstName, lastName) => ({
  type: ACTION_TYPES.GENERATE_USERNAME,
  payload: {
    firstName,
    lastName,
  },
});

export const usernameGenerated = (username) => ({
  type: ACTION_TYPES.USERNAME_GENERATED,
  payload: username,
});

export const validateRegisteringEmailAddress = (emailAddress) => ({
  type: ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS,
  payload: emailAddress,
});

export const validateEmailAddressSuccess = () => ({
  type: ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS_SUCCESS,
});

export const validateEmailAddressFailed = (duplicates) => ({
  type: ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS_FAILED,
  payload: duplicates,
});

export const duplicatesDetected = (payload) => ({
  type: ACTION_TYPES.DUPLICATES_DETECTED,
  payload,
});

export const registerForLinkAccounts = (userInfo, profilePictures, userinfoParam) => ({
  type: ACTION_TYPES.REGISTER_FOR_LINK_ACCOUNTS,
  payload: {
    userInfo,
    profilePictures,
    userinfoParam,
  },
});

export const tryGoingToRegisterPage = (registerForm) => ({
  type: ACTION_TYPES.TRY_GOING_TO_REGISTER_PAGE,
  payload: registerForm,
});

export const goToRegisterPage = () => ({
  type: ACTION_TYPES.GO_TO_REGISTER_PAGE,
});

export const validateRegistration = (registerForm) => ({
  type: ACTION_TYPES.VALIDATE_REGISTRATION,
  payload: registerForm,
});

export const executeRegistration = () => ({
  type: ACTION_TYPES.EXECUTE_REGISTRATION,
});

export const validateRegistrationSuccess = (payload) => ({
  type: ACTION_TYPES.VALIDATE_REGISTRATION_SUCCESS,
  payload,
});

export const executeRegistrationSuccess = () => ({
  type: ACTION_TYPES.EXECUTE_REGISTRATION_SUCCESS,
});

export const executeRegistrationFailed = (error) => ({
  type: ACTION_TYPES.EXECUTE_REGISTRATION_FAILED,
  payload: error,
});

export const setRedirectUrl = (redirectUrl) => ({
  type: ACTION_TYPES.SET_REDIRECT_URL,
  payload: redirectUrl,
});

/* export const setUserinfoFromOauth = (registerForm, userinfoParam) => ({
  type: ACTION_TYPES.SET_USERINFO_FROM_OAUTH,
  payload: {
    registerForm,
    userinfoParam
  }
}); */
