const mock = require('./team.data');

const service = () => {
  'ngInject';

  const getMembers = () => {
    return mock.members;
  };

  const getAdminDelegations = () => {
    return mock.adminDelegations;
  };

  const getNascholingDelegations = () => {
    return mock.nascholingDelegations;
  };

  const memberComparison = (m1, m2) => {
    const m1Name = m1.name.toUpperCase();
    const m2Name = m2.name.toUpperCase();

    if (m1.position && m2.position) {
      if (m1.position.class.sortOrder < m2.position.class.sortOrder) {
        return -1;
      }
      if (m1.position.class.sortOrder > m2.position.class.sortOrder) {
        return 1;
      }

      if (m1Name < m2Name) {
        return -1;
      }
      if (m1Name > m2Name) {
        return 1;
      }
      return 0;
    }

    if (!m1.position && !m2.position) {
      if (m1Name < m2Name) {
        return -1;
      }
      if (m1Name > m2Name) {
        return 1;
      }
      return 0;
    }

    if (!m1.position) {
      return 1;
    }

    if (!m2.position) {
      return -1;
    }

    return 0;
  };

  return {
    memberComparison,
    getMembers,
    getAdminDelegations,
    getNascholingDelegations,
  };
};

angular.module('myServices').factory('teamService', service);
