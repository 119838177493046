import { isAfter } from '@kathondvla/sri-client/date-utils';
import { storeResource } from 'ReduxLoop/utils';
import { sriClient } from '../../utils/apiConfig';

export const getTeamInfo = async (ouPermalink, isAllowedToSeeTeam) => {
  const responsibilities = await sriClient.getAll('/responsibilities', {
    organisations: ouPermalink,
    expandPosition: 'NONE',
  });
  const pendingResponsibilities = !isAllowedToSeeTeam
    ? []
    : await sriClient.getAll('/responsibilities/pending', {
        organisations: ouPermalink,
        status: 'PENDING',
      });
  const personHrefs = [
    ...responsibilities.map((resp) => resp.person.href),
    ...pendingResponsibilities
      .filter((resp) => resp.type === 'REQUEST')
      .map((resp) => resp.from.href),
  ];

  // let persons = [];

  /* if (personHrefs.length > 0) {
    persons = await sriClient.getAllHrefs(personHrefs, {expand: 'SUMMARY'});
  } */

  const persons = await sriClient.getAllHrefs(personHrefs, { expand: 'SUMMARY' });
  // const emails = sriClient.getAllReferencesTo('/contactdetails', {types: 'EMAILADDRESS_PRIMARY'}, 'person', personHrefs);
  return {
    responsibilities,
    invitations: pendingResponsibilities.filter((resp) => resp.type === 'INVITATION'),
    requests: pendingResponsibilities.filter((resp) => resp.type === 'REQUEST'),
    persons,
  };
};

export const getPendingResponsibilities = (ouPermalink) => {
  // It might be cleaner to do this in seperate action and not with boolean in function above, but that would be an extra request to fetch the /persons which we now don't have to do
  return sriClient.getAll('/responsibilities/pending', {
    organisations: ouPermalink,
    status: 'PENDING',
  });
};

export const getPossiblePositionsForOu = (ouPermalink, expand = 'NONE') => {
  return sriClient.getAll('/positions', { organisation: ouPermalink, expand });
};

export const getUndeliveredInvitations = async (invitations) => {
  const emailJobs = await sriClient.getAllReferencesTo(
    '/mailer/emailjobs',
    undefined,
    'externalReferences',
    invitations.map((invitation) => invitation.$$meta.permalink),
    {
      include: {
        alias: '$$email',
        href: '/mailer/emails',
        reference: 'job',
        singleton: true,
      },
    }
  );
  const undeliveredJobs = emailJobs.filter(
    (job) =>
      ['REJECTED', 'SOFT-BOUNCE', 'HARD-BOUNCE'].some((status) => status === job.$$email.status) &&
      !emailJobs.some((other) => other.key === job.key && other.$$meta.created > job.$$meta.created)
  );
  const undeliveredInvitations = invitations.filter((invitation) =>
    undeliveredJobs.some((job) => job.externalReferences[0].href === invitation.$$meta.permalink)
  );
  return undeliveredInvitations.map((invitation) => invitation.$$meta.permalink);
};

export const getEmailJobs = async (invitations) => {
  const emailJobs = await sriClient.getAllReferencesTo(
    '/mailer/emailjobs',
    undefined,
    'externalReferences',
    invitations.map((invitation) => invitation.$$meta.permalink)
  );
  const jobMap = {};
  invitations.forEach((invitation) => {
    const correspondingJobs = emailJobs.filter((job) =>
      job.externalReferences.some((reference) => reference.href === invitation.$$meta.permalink)
    );
    if (correspondingJobs.length > 0) {
      jobMap[invitation.$$meta.permalink] = correspondingJobs.reduce((acc, curr) =>
        !acc || isAfter(curr.$$meta.created, acc.$$meta.created) ? curr : acc
      );
    }
  });
  const emails = await sriClient.getAllReferencesTo(
    '/mailer/emails',
    undefined,
    'job',
    Object.values(jobMap).map((job) => job.$$meta.permalink)
  );
  Object.keys(jobMap).forEach((key) => {
    const [matchingEmail] = emails.filter(
      (email) => email.job.href === jobMap[key].$$meta.permalink
    );
    jobMap[key].$$email = matchingEmail;
  });
  return jobMap;
};

export const postAcceptRequest = (requestPermalink, managerDescription) => {
  return sriClient.post('/responsibilities/pending/approve', {
    request: { href: requestPermalink },
    approverDescription: managerDescription,
  });
};

export const postDeclineRequest = (requestPermalink, managerDescription) => {
  return sriClient.post('/responsibilities/pending/decline', {
    request: { href: requestPermalink },
    approverDescription: managerDescription,
  });
};

export const getRequest = (permalink) => {
  return sriClient.get(
    permalink,
    { expandPosition: 'FULL' },
    { expand: ['organisationalUnit', 'from'] }
  );
};

export const trySaveResponsibilityAndOtherwiseARequest = async (
  userHref,
  responsibility,
  request
) => {
  if (responsibility) {
    try {
      await storeResource(responsibility);
    } catch (error) {
      if (error && error.status === 403) {
        return storeResource(responsibility);
      }
      throw error;
    }
  }
  return storeResource(request);
};
