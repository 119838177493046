import { myThemanieuwsbriefSection } from './myThemanieuwsbriefSection';

require('./myThemanieuwsbriefSection.scss');

export const myThemanieuwsbriefSectionComponent = {
  template: require('./myThemanieuwsbriefSection.html'),
  controllerAs: 'ctrl',
  controller: myThemanieuwsbriefSection,
  bindings: {
    sOptions: '<',
  },
};
