import { loop, combineReducers } from 'redux-loop';
import { buildViewModel } from 'ReduxLoop/viewModel';
import { helpdeskReducer } from 'ReduxLoop/helpDesk/helpdeskReducer';
import { SUPER_ADMIN } from 'ReduxLoop/constants';
import { requestResponsibilityReducer } from 'ReduxLoop/requestResponsibility/requestResponsibilityReducer';
import { workgroupReducer } from 'ReduxLoop/ouManager/workgroups/workgroupReducer';
import { createTeamWizardReducer } from 'ReduxLoop/ouManager/createTeamWizard/createTeamWizardReducer_';
import { OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import { schoolManagerReducer } from './ouManager/schoolManagerReducer';
import { studyProgrammesReducer } from './ouManager/studyProgrammes/studyProgrammesReducer';
import { classesReducer } from './ouManager/classes/classesReducer';
import { contactDetailsReducer } from './ouManager/contactDetails/contactDetailsReducer';
import { groupsReducer } from './ouManager/vakGroepen/groupsReducer';
import { teamStateReducer } from './ouManager/team/teamReducer';
import { userReducer } from './user/userReducer';
import { profileReducer } from './profile/profileReducer';
import { registerReducer } from './registerAndConfirmInvitation/register/registerReducer';
import { invitationReducer } from './registerAndConfirmInvitation/inviteConfirmation/invitationReducer';
import { notificationReducer } from './notifications/notificationReducer';
import { newsletterReducer } from './newsletters/preferences/newsletterReducer';
import { newsletterUnsubscriptionReducer } from './newsletters/unsubscription/newsletterUnsubscriptionReducer';
import { piwikAnalyticsReducer } from './piwikAnalytics/piwikAnalyticsReducer';

export const arrayToMap = (array) => {
  const ret = {};
  array.forEach((item) => {
    ret[item.$$meta ? item.$$meta.permalink : item.href] = item;
  });

  return ret;
};

export const getCurrentUserMgmt = (rootState, currentOrganisationalUnit) => {
  const ouTypeConfigKey = OU_TYPE_CONFIG[currentOrganisationalUnit.type]
    ? currentOrganisationalUnit.type
    : Object.keys(OU_TYPE_CONFIG).find(
        (configKey) =>
          OU_TYPE_CONFIG[configKey].types &&
          OU_TYPE_CONFIG[configKey].types.some((type) => type === currentOrganisationalUnit.type)
      );
  const currentUserMgmts = rootState.vm.user.manages[ouTypeConfigKey].filter(
    (mgmt) => mgmt.ouKey === currentOrganisationalUnit.key
  );
  return currentUserMgmts.length > 0 ? currentUserMgmts[0] : null;
};

export const getCurrentUserRole = (rootState, currentOrganisationalUnit) => {
  if (rootState.vm.user.isSuperAdmin) {
    return SUPER_ADMIN;
  }
  const currentUserMgmt = getCurrentUserMgmt(rootState, currentOrganisationalUnit);
  return currentUserMgmt ? currentUserMgmt.role : null;
};

export const rootReducer = function (state, action) {
  const rawStateReducer = combineReducers({
    userState: userReducer,
    profileState: profileReducer,
    teamTab: teamStateReducer,
    schoolManager: schoolManagerReducer,
    groupsTab: groupsReducer,
    studyProgrammesTab: studyProgrammesReducer,
    classesTab: classesReducer,
    contactDetails: contactDetailsReducer,
    workgroup: workgroupReducer,
    helpdesk: helpdeskReducer,
    requestResponsibility: requestResponsibilityReducer,
    invite: invitationReducer,
    register: registerReducer,
    notifications: notificationReducer,
    newsletters: newsletterReducer,
    newsletterUnsubscription: newsletterUnsubscriptionReducer,
    createTeamWizard: createTeamWizardReducer,
    piwikAnalyticsState: piwikAnalyticsReducer,
  });

  const newLoop = rawStateReducer(state, action, state);
  const newState = newLoop[0];
  const vmState = { ...newState, vm: buildViewModel(newState, action) };

  console.log(`ViewModel after ${action.type}`, {
    payload: action.payload,
    state: vmState,
    viewModel: vmState.vm,
  });
  return loop(vmState, newLoop[1]);
};
