// const mock = require('./global.data');

const service = () => {
  'ngInject';

  const getCurrentUser = () => {
    return {};
  };

  const getCurrentOrganisationalUnit = () => {
    return {
      name: 'Oscar Romero College - Het Laar',
      address: 'Stationsstraat 13 - 9200 Dendermonde',
    };
  };

  return {
    getCurrentUser,
    getCurrentOrganisationalUnit,
  };
};

angular.module('myServices').factory('globalService', service);
