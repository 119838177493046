import {
  printAddress,
  isSameStreet,
  isSameHouseNumberAndMailbox,
} from '@kathondvla/sri-client/address-utils';
import {
  getNow,
  getPreviousDay,
  isAfter,
  isAfterOrEqual,
  isBefore,
  isBeforeOrEqual,
  printDate,
} from '@kathondvla/sri-client/date-utils';
import { generateUUID } from '@kathondvla/sri-client/common-utils';
import Batch from '@kathondvla/sri-client/batch';
import { priority } from 'ReduxLoop/utils/contactDetailsResourceFactory';

export const transformRelation = (relation, identifiers, ouEndDate) => {
  const otherOuHref = relation.from.$$expanded ? relation.from.href : relation.to.href;
  const otherOu = relation.from.$$expanded || relation.to.$$expanded;
  return {
    resource: relation,
    href: otherOuHref,
    ouType: otherOu.type,
    institutionNumber:
      otherOu.code ||
      identifiers.find(
        (id) => id.type === 'INSTITUTION_NUMBER' && id.organisationalUnit.href === otherOuHref
      )?.value,
    name: otherOu.$$displayName,
    periodInfo: relation.endDate
      ? `van ${printDate(relation.startDate)} tot ${printDate(relation.endDate)}`
      : `${isAfter(relation.startDate, getNow()) ? 'vanaf' : 'sinds'} ${printDate(
          relation.startDate
        )}`,
    isActive:
      (ouEndDate && relation.endDate === ouEndDate) ||
      (isAfter(relation.endDate, getNow()) && isBeforeOrEqual(relation.startDate, getNow())),
    isFuture: isAfter(relation.startDate, getNow()),
    startDate: printDate(relation.startDate),
  };
};

export const transformOu = (
  { ouResource, contactDetails, relations, locations, identifiers },
  pendingResources,
  referenceDate
) => {
  const allRelations = relations.filter(
    (existingRel) =>
      !Object.keys(pendingResources.relations).some((href) => existingRel.$$meta.permalink === href)
  );
  Object.values(pendingResources.relations).forEach((rel) => {
    allRelations.push(rel);
  });
  const allLocations = [
    ...locations.filter(
      (loc) =>
        !Object.keys(pendingResources.locations).some((href) => loc.$$meta.permalink === href)
    ),
    ...Object.values(pendingResources.locations),
  ];
  allLocations.sort((a, b) =>
    !b.discimusSerialNumber || a.discimusSerialNumber < b.discimusSerialNumber ? -1 : 1
  );
  const transformedRelations = allRelations.map((rel) =>
    transformRelation(rel, identifiers, referenceDate)
  );
  transformedRelations.sort((a, b) => {
    if (a.resource.startDate === b.resource.startDate) {
      return a.name < b.name ? -1 : 1;
    }
    return isBefore(a.resource.startDate, b.resource.startDate) ? -1 : 1;
  });
  return {
    href: ouResource.$$meta.permalink,
    resource: ouResource,
    name: ouResource.names.find(
      (nameObj) => nameObj.type === 'OFFICIAL' && nameObj.endDate === ouResource.endDate
    ).value,
    shortName: ouResource.names.find(
      (nameObj) => nameObj.type === 'SHORT' && nameObj.endDate === ouResource.endDate
    ).value,
    startDate: ouResource.startDate,
    endDate: ouResource.endDate,
    institutionNumber: identifiers.find(
      (id) =>
        id.type === 'INSTITUTION_NUMBER' &&
        id.organisationalUnit.href === ouResource.$$meta.permalink
    )?.value,
    companyNumber: identifiers.find(
      (id) =>
        id.type === 'COMPANY_NUMBER' && id.organisationalUnit.href === ouResource.$$meta.permalink
    ),
    vatNumber: identifiers.find(
      (id) =>
        id.type === 'VAT_NUMBER' && id.organisationalUnit.href === ouResource.$$meta.permalink
    ),
    seatAddresses: allLocations.filter((loc) => loc.type === 'SEAT'),
    locations: allLocations
      .filter((loc) => loc.type === 'VESTIGINGSPLAATS')
      .map((loc) => ({
        resource: loc,
        address: loc.physicalLocation.$$expanded.address,
        discimusSerialNumber: loc.discimusSerialNumber,
        startDate: loc.startDate,
        endDate: loc.endDate,
        asString: printAddress(loc.physicalLocation.$$expanded.address),
        periodInfo: loc.endDate
          ? `van ${printDate(loc.startDate)} tot ${printDate(loc.endDate)}`
          : `${isAfter(loc.startDate, getNow()) ? 'vanaf' : 'sinds'} ${printDate(loc.startDate)}`,
        isActiveOrFuture:
          (referenceDate && loc.endDate === referenceDate) || isAfter(loc.endDate, getNow()),
      })),
    email: contactDetails.find((cd) => cd.type === 'EMAIL'),
    phone: contactDetails.find((cd) => cd.type === 'PHONE'),
    website: contactDetails.reduce(
      (website, cd) =>
        cd.type === 'WEBSITE' && (!website || website.priority > cd.priority) ? cd : website,
      null
    ),
    mail: contactDetails.find((cd) => cd.type === 'MAIL'),
    bestuur:
      ouResource.type !== 'GOVERNINGINSTITUTION'
        ? transformedRelations.find((rel) => rel.resource.type === 'GOVERNS' && rel.isActive)
        : null,
    historicalGoverningInstitutions:
      ouResource.type !== 'GOVERNINGINSTITUTION'
        ? transformedRelations.filter(
            (rel) => rel.resource.type === 'GOVERNS' && !rel.isActive && !rel.isFuture
          )
        : null,
    futureGoverningInstitutions:
      ouResource.type !== 'GOVERNINGINSTITUTION'
        ? transformedRelations.filter((rel) => rel.resource.type === 'GOVERNS' && rel.isFuture)
        : null,
    schoolCommunity:
      ouResource.type === 'SCHOOL'
        ? transformedRelations.find((rel) => rel.resource.type === 'IS_MEMBER_OF' && rel.isActive)
        : null,
    historicalSchoolCommunities:
      ouResource.type === 'SCHOOL'
        ? transformedRelations.filter(
            (rel) => rel.resource.type === 'IS_MEMBER_OF' && !rel.isActive && !rel.isFuture
          )
        : null,
    futureSchoolCommunities:
      ouResource.type === 'SCHOOL'
        ? transformedRelations.filter((rel) => rel.resource.type === 'IS_MEMBER_OF' && rel.isFuture)
        : null,
    koepel:
      ouResource.type === 'GOVERNINGINSTITUTION'
        ? transformedRelations.find((rel) => rel.resource.type === 'IS_MEMBER_OF' && rel.isActive)
        : null,
    historicalKoepels:
      ouResource.type === 'GOVERNINGINSTITUTION'
        ? transformedRelations.filter(
            (rel) => rel.resource.type === 'IS_MEMBER_OF' && !rel.isActive && !rel.isFuture
          )
        : null,
    futureKoepels:
      ouResource.type === 'GOVERNINGINSTITUTION'
        ? transformedRelations.filter((rel) => rel.resource.type === 'IS_MEMBER_OF' && rel.isFuture)
        : null,
    schools: transformedRelations
      .filter(
        (rel) =>
          (rel.isActive || rel.isFuture) &&
          rel.ouType === 'SCHOOL' &&
          ((ouResource.type === 'GOVERNINGINSTITUTION' && rel.resource.type === 'GOVERNS') ||
            (ouResource.type === 'SCHOOLCOMMUNITY' && rel.resource.type === 'IS_MEMBER_OF'))
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
    schoolEntities: transformedRelations
      .filter(
        (rel) =>
          (rel.isActive || rel.isFuture) &&
          rel.ouType === 'SCHOOLENTITY' &&
          ((ouResource.type === 'GOVERNINGINSTITUTION' && rel.resource.type === 'GOVERNS') ||
            (ouResource.type === 'SCHOOLCOMMUNITY' && rel.resource.type === 'IS_MEMBER_OF'))
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  };
};

export const getFormModel = (ou) => ({
  name: ou.name,
  shortName: ou.shortName,
  institutionNumber: ou.institutionNumber,
  companyNumber: ou.companyNumber ? ou.companyNumber.value : null,
  vatNumber: ou.vatNumber ? ou.vatNumber.value : null,
  startDate: ou.startDate,
  endDate: ou.endDate,
  email: ou.email ? ou.email.value.email : null,
  phone: ou.phone ? ou.phone.value.phone : null,
  website: ou.website ? ou.website.value.website : null,
  mail: ou.mail ? { ...ou.mail.value } : null,
});

export const getKeyAndMeta = (path) => {
  const key = generateUUID();
  return {
    $$meta: { permalink: `${path}/${key}` },
    key,
  };
};

export const createOuResource = (ouType, name, shortName, startDate) => ({
  ...getKeyAndMeta('/sam/organisationalunits'),
  type: ouType,
  startDate,
  names: [
    {
      type: 'OFFICIAL',
      startDate,
      value: name,
    },
    {
      type: 'SHORT',
      startDate,
      value: shortName,
    },
  ],
});

const findNameObjOnDate = (names, type, date) => {
  const ret = names.find(
    (nameObj) =>
      nameObj.type === type &&
      isBeforeOrEqual(nameObj.startDate, date) &&
      ((!date && !nameObj.endDate) || isAfter(nameObj.endDate, date))
  );
  return { ...ret };
};

const getOuNamesOfType = (name, type, ou, newEndDate) => {
  // console.log('ou.names', ou.names);
  const currentNameObj = findNameObjOnDate(ou.names, type, getPreviousDay(ou.endDate));
  if (!currentNameObj.value) {
    console.error(
      `not a current ${type.toLowerCase()} name found on ${getPreviousDay(ou.endDate)}!`,
      ou.names
    );
  }
  const previousNameObj = findNameObjOnDate(ou.names, getPreviousDay(currentNameObj.startDate));
  // console.log('current name obj', currentNameObj);
  if (isBeforeOrEqual(newEndDate, currentNameObj.startDate)) {
    const lastValidNameObj = findNameObjOnDate(ou.names, newEndDate);
    lastValidNameObj.endDate = newEndDate;
    // lastValidNameObj.value = name;
    return [
      ...ou.names.filter(
        (nameObj) =>
          nameObj.type === type && isBefore(nameObj.startDate, lastValidNameObj.startDate)
      ),
      lastValidNameObj,
    ];
  }
  const newNames = ou.names.filter(
    (nameObj) => nameObj.type === type && isBefore(nameObj.startDate, currentNameObj.startDate)
  );
  if (previousNameObj && name === previousNameObj.value) {
    previousNameObj.endDate = newEndDate;
    return newNames;
  }
  newNames.push(currentNameObj);
  if (newEndDate !== currentNameObj.endDate) {
    console.warn(
      'endDate on the ou and not on the current name object. We will also end the current name'
    );
    currentNameObj.endDate = newEndDate;
  }
  if (currentNameObj.value === name) {
    return newNames;
  }
  if (currentNameObj.startDate === getNow()) {
    currentNameObj.value = name;
    return newNames;
  }
  currentNameObj.endDate = getNow();
  newNames.push({
    type,
    startDate: getNow(),
    endDate: newEndDate,
    value: name,
  });
  return newNames;
};

export const createExternalIdentifier = (ouHref, value, type) => ({
  ...getKeyAndMeta('/sam/organisationalunits/externalidentifiers'),
  type,
  organisationalUnit: { href: ouHref },
  value,
});

const createContactDetail = (ouHref, type, value) => ({
  ...getKeyAndMeta('/sam/organisationalunits/contactdetails'),
  type,
  organisationalUnit: { href: ouHref },
  value,
  priority: priority(type),
});

const createSeat = (ouHref, physicalLocation, startDate, endDate) => ({
  ...getKeyAndMeta('/sam/organisationalunits/locations'),
  type: 'SEAT',
  organisationalUnit: { href: ouHref },
  physicalLocation: { href: physicalLocation.$$meta.permalink },
  startDate,
  endDate,
});

export const calculateChanges = ({
  ouType,
  ouModel,
  formModel,
  dirtyResources,
  pendingResources,
  ouData,
  physicalLocationsInCityOfMail,
}) => {
  const newOuData = { ...ouData };
  const batch = new Batch();
  const newOu = ouModel
    ? { ...ouModel.resource }
    : createOuResource(ouType, formModel.name, formModel.shortName, formModel.startDate);
  if (dirtyResources.ou) {
    newOu.names = [
      ...getOuNamesOfType(formModel.name, 'OFFICIAL', newOu, formModel.endDate),
      // ...getOuNamesOfType(formModel.shortName, 'SHORT', newOu, formModel.endDate),
      {
        ...findNameObjOnDate(newOu.names, 'SHORT', getPreviousDay(newOu.endDate)),
        value: formModel.shortName,
        endDate: formModel.endDate,
      },
    ];
    newOu.startDate = formModel.startDate;
    newOu.endDate = formModel.endDate;
    newOuData.ouResource = newOu;
    batch.put(newOu);
  }
  if (!ouModel) {
    batch.put(
      createExternalIdentifier(
        newOu.$$meta.permalink,
        formModel.institutionNumber,
        'INSTITUTION_NUMBER'
      )
    );
    return { batch: batch.array, newOuData };
  }

  const pendingRelations = pendingResources.relations;

  if (formModel.endDate !== ouModel.endDate) {
    console.warn('endDate changed');

    // adapt seat addresses (not managed in screen)
    newOuData.locations = newOuData.locations.filter((loc) => loc.type !== 'SEAT');
    ouData.locations
      .filter(
        (seat) =>
          seat.type === 'SEAT' &&
          (isAfter(seat.endDate, formModel.endDate) ||
            (!formModel.endDate && seat.endDate === ouModel.endDate))
      )
      .forEach((seat) => {
        if (isBeforeOrEqual(formModel.endDate, seat.startDate)) {
          batch.delete(seat.$$meta.permalink);
        } else {
          newOuData.locations.push(seat);
          batch.put({ ...seat, endDate: formModel.endDate });
        }
      });
    // edge case to also adapt relation to an endDate that makes relations obsolete
    ouData.relations
      .filter(
        (existingRel) =>
          !Object.keys(pendingRelations).some((href) => existingRel.$$meta.permalink === href) &&
          isBefore(formModel.endDate, existingRel.endDate)
      )
      .forEach((relAffectedByNewEndDate) => {
        console.warn('historical rel updated because of new end date', relAffectedByNewEndDate);
        pendingRelations[relAffectedByNewEndDate.$$meta.permalink] = {
          ...relAffectedByNewEndDate,
          endDate: formModel.endDate,
        };
      });
  }

  newOuData.relations = ouData.relations.filter(
    (existingRel) =>
      !Object.keys(pendingRelations).some((href) => existingRel.$$meta.permalink === href)
  );
  Object.values(pendingRelations).forEach((rel) => {
    if (isBeforeOrEqual(formModel.endDate, rel.startDate)) {
      batch.delete(rel.$$meta.permalink);
    } else {
      newOuData.relations.push(rel);
      batch.put(rel);
    }
  });

  newOuData.locations = ouData.locations.filter(
    (existingRel) =>
      !Object.keys(pendingResources.locations).some((href) => existingRel.$$meta.permalink === href)
  );
  Object.values(pendingResources.locations).forEach((loc) => {
    if (isBeforeOrEqual(formModel.endDate, loc.startDate)) {
      batch.delete(loc.$$meta.permalink);
    } else {
      newOuData.locations.push(loc);
      batch.put(loc);
    }
  });
  Object.values(pendingResources.physicalLocations).forEach((pl) => {
    batch.put(pl);
  });

  if (dirtyResources.companyNumber) {
    if (formModel.companyNumber && !ouModel.companyNumber) {
      const companyNumber = createExternalIdentifier(
        newOu.$$meta.permalink,
        formModel.companyNumber,
        'COMPANY_NUMBER'
      );
      newOuData.identifiers = [companyNumber, ...ouData.identifiers];
      batch.put(companyNumber);
    } else if (!formModel.companyNumber && ouModel.companyNumber) {
      newOuData.identifiers = ouData.identifiers.filter(
        (id) => id.$$meta.permalink !== ouModel.companyNumber.$$meta.permalink
      );
      batch.delete(ouModel.companyNumber.$$meta.permalink);
    } else if (formModel.companyNumber && formModel.companyNumber !== ouModel.companyNumber.value) {
      const companyNumber = { ...ouModel.companyNumber, value: formModel.companyNumber };
      newOuData.identifiers = [
        companyNumber,
       ...ouData.identifiers.filter(
          (id) => id.$$meta.permalink !== ouModel.companyNumber.$$meta.permalink
        ),
      ];
      batch.put(companyNumber);
    }
  }

  if (dirtyResources.vatNumber) {
    if (formModel.vatNumber && !ouModel.vatNumber) {
      const vatNumber = createExternalIdentifier(
        newOu.$$meta.permalink,
        formModel.vatNumber,
        'VAT_NUMBER'
      );
      newOuData.identifiers = [vatNumber, ...ouData.identifiers];
      batch.put(vatNumber);
    } else if (!formModel.vatNumber && ouModel.vatNumber) {
      newOuData.identifiers = ouData.identifiers.filter(
        (id) => id.$$meta.permalink !== ouModel.vatNumber.$$meta.permalink
      );
      batch.delete(ouModel.vatNumber.$$meta.permalink);
    } else if (formModel.vatNumber && formModel.vatNumber !== ouModel.vatNumber.value) {
      const vatNumber = { ...ouModel.vatNumber, value: formModel.vatNumber };
      newOuData.identifiers = [
        vatNumber,
        ...ouData.identifiers.filter(
          (id) => id.$$meta.permalink !== ouModel.vatNumber.$$meta.permalink
        ),
      ];
      batch.put(vatNumber);
    }
  }

  newOuData.contactDetails = [];
  if (dirtyResources.email) {
    if (formModel.email && !ouModel.email) {
      const cd = createContactDetail(newOu.$$meta.permalink, 'EMAIL', { email: formModel.email });
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    } else if (!formModel.email && ouModel.email) {
      batch.delete(ouModel.email.$$meta.permalink);
    } else if (formModel.email) {
      const cd = { ...ouModel.email, value: { email: formModel.email } };
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    }
  } else if (ouModel.email) {
    newOuData.contactDetails.push(ouModel.email);
  }
  if (dirtyResources.phone) {
    if (formModel.phone && !ouModel.phone) {
      const cd = createContactDetail(newOu.$$meta.permalink, 'PHONE', { phone: formModel.phone });
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    } else if (!formModel.phone && ouModel.phone) {
      batch.delete(ouModel.phone.$$meta.permalink);
    } else if (formModel.phone) {
      const cd = { ...ouModel.phone, value: { phone: formModel.phone } };
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    }
  } else if (ouModel.phone) {
    newOuData.contactDetails.push(ouModel.phone);
  }
  if (dirtyResources.website) {
    if (formModel.website && !ouModel.website) {
      const cd = createContactDetail(newOu.$$meta.permalink, 'WEBSITE', {
        website: formModel.website,
      });
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    } else if (!formModel.website && ouModel.website) {
      batch.delete(ouModel.website.$$meta.permalink);
    } else if (formModel.website) {
      const cd = { ...ouModel.website, value: { website: formModel.website } };
      newOuData.contactDetails.push(cd);
      batch.put(cd);
    }
  } else if (ouModel.website) {
    newOuData.contactDetails.push(ouModel.website);
  }
  if (dirtyResources.mail) {
    // also make sure the seat address is a historical representation of the contact mail
    const currentSeat = ouData.locations.find(
      (loc) =>
        loc.type === 'SEAT' &&
        isBeforeOrEqual(loc.startDate, getNow()) &&
        ((formModel.endDate && loc.endDate === formModel.endDate) || isAfter(loc.endDate, getNow()))
    );
    console.log('current seat', currentSeat);
    const seatThatEndedToday = ouData.locations.find(
      (loc) => loc.type === 'SEAT' && loc.endDate === getNow()
    );
    const existingPhysicalLocation = !formModel.mail
      ? null
      : physicalLocationsInCityOfMail.find(
          (pl) =>
            isSameStreet(pl.address, formModel.mail) &&
            isSameHouseNumberAndMailbox(pl.address, formModel.mail)
        );
    const physicalLocation = existingPhysicalLocation || {
      ...getKeyAndMeta('/sam/physicallocations'),
      type: 'DOMAIN',
      address: formModel.mail,
    };

    if (formModel.mail && formModel.mail.subCity && !ouModel.mail) {
      const cd = createContactDetail(newOu.$$meta.permalink, 'MAIL', { ...formModel.mail });
      batch.put(cd);
      newOuData.contactDetails.push(cd);
      if (
        seatThatEndedToday &&
        seatThatEndedToday.physicalLocation.href === physicalLocation.$$meta.permalink
      ) {
        const newSeat = { ...seatThatEndedToday, endDate: formModel.endDate };
        batch.put(newSeat);
      } else {
        const newSeat = createSeat(
          newOu.$$meta.permalink,
          physicalLocation,
          !newOuData.locations.some((loc) => loc.type === 'SEAT') ? newOu.startDate : getNow(),
          formModel.endDate
        );
        batch.put(newSeat);
        if (!existingPhysicalLocation) {
          batch.put(physicalLocation);
        }
        newOuData.locations.push(newSeat);
      }
    } else if (!(formModel.mail && formModel.mail.subCity) && ouModel.mail) {
      batch.delete(ouModel.mail.$$meta.permalink);
      if (isAfterOrEqual(currentSeat.startDate, getNow())) {
        newOuData.locations = newOuData.locations.filter((loc) => loc.key !== currentSeat.key);
        batch.delete(currentSeat.$$meta.permalink);
      }
      if (
        currentSeat.endDate !== getNow() &&
        !(currentSeat.endDate === formModel.endDate && isBeforeOrEqual(formModel.endDate, getNow()))
      ) {
        const newSeat = { ...currentSeat, endDate: getNow() };
        newOuData.locations = [
          ...newOuData.locations.filter((loc) => loc.key !== currentSeat.key),
          newSeat,
        ];
        batch.put(newSeat);
      }
    } else if (formModel.mail && formModel.mail.subCity) {
      const cd = { ...ouModel.mail, value: { ...formModel.mail } };
      newOuData.contactDetails.push(cd);
      batch.put(cd);
      if (
        currentSeat.physicalLocation.href !== physicalLocation.$$meta.permalink &&
        isAfter(formModel.endDate, getNow())
      ) {
        if (isAfterOrEqual(currentSeat.startDate, getPreviousDay(getNow(), 21))) {
          const newSeat = {
            ...currentSeat,
            physicalLocation: {
              href: physicalLocation.$$meta.permalink,
              $$expanded: physicalLocation,
            },
          };
          batch.put(newSeat);
          newOuData.locations = [
            ...newOuData.locations.filter((loc) => loc.key !== currentSeat.key),
            newSeat,
          ];
        } else {
          const previousSeat = { ...currentSeat, endDate: getNow() };
          const newSeat = createSeat(
            newOu.$$meta.permalink,
            physicalLocation,
            getNow(),
            formModel.endDate
          );
          batch.put(previousSeat);
          batch.put(newSeat);
          newOuData.locations = [
            ...newOuData.locations.filter((loc) => loc.key !== currentSeat.key),
            previousSeat,
            newSeat,
          ];
        }
        if (!existingPhysicalLocation) {
          batch.put(physicalLocation);
        }
      }
    }
  } else if (ouModel.mail) {
    newOuData.contactDetails.push(ouModel.mail);
  }

  return { batch: batch.array, newOuData };
};
