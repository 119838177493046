import {
  getLast,
  isAfterOrEqual,
  getStartOfSchoolYearIncludingSummerGap,
} from '@kathondvla/sri-client/date-utils';
import {
  fetchPossibleStudyProgrammes,
  fetchStudyProgrammes,
  addStudyProgrammeToSchool,
  deleteStudyProgrammeFromSchool,
  changeStudyProgrammeGradeFilter,
} from 'ReduxLoop/ouManager/studyProgrammes/studyProgrammesActions';

require('./studyProgrammesTab.scss');

export class StudyProgrammesTab {
  constructor($stateParams, $ngRedux) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;

    this.pupilsIcon = require('Img/pupils.svg');
    this.addIcon = require('Img/add.svg');
    this.booksIcon = require('Img/books-stack-of-three.svg');

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      school: state.vm.schoolManager.currentOU,
      userRole: state.vm.schoolManager.currentOU.userRole,
      possibleStudyProgrammes:
        state.vm.studyProgrammesTab.selectedGrade === 'all'
          ? state.vm.studyProgrammesTab.possibleStudyProgrammes
          : state.vm.studyProgrammesTab.possibleStudyProgrammes.filter(
              (studyProgramme) => studyProgramme.grade === state.vm.studyProgrammesTab.selectedGrade
            ),
      studyProgrammes: state.vm.studyProgrammesTab.studyProgrammes,
      userCanUpdateStudyProgrammes: state.vm.studyProgrammesTab.userCanUpdateStudyProgrammes,
      selectedGrade: state.vm.studyProgrammesTab.selectedGrade,
    }))(this);
  }

  $onInit() {
    this.$ngRedux.dispatch(fetchPossibleStudyProgrammes());
    this.$ngRedux.dispatch(
      fetchStudyProgrammes(`/sam/organisationalunits/${this.$stateParams.schoolId}`)
    );
  }

  $onDestroy() {
    this.unsubscribe();
  }

  onCheckboxClicked(ev, checkboxValue, studyProgrammeKey) {
    if (checkboxValue) {
      const studyProgramme = this.possibleStudyProgrammes.find(
        (studyPr) => studyPr.key === studyProgrammeKey
      );
      const minStartDate = getLast([this.school.startDate, studyProgramme.startDate]);

      this.$ngRedux.dispatch(
        addStudyProgrammeToSchool({
          ouPermalink: this.school.permalink,
          studyProgrammePermalink: studyProgramme.$$meta.permalink,
          startDate: isAfterOrEqual(minStartDate, getStartOfSchoolYearIncludingSummerGap())
            ? minStartDate
            : getStartOfSchoolYearIncludingSummerGap(),
        })
      );
    } else {
      this.$ngRedux.dispatch(
        deleteStudyProgrammeFromSchool(this.studyProgrammes[studyProgrammeKey])
      );
    }
  }

  changedStudyProgrammeGrade() {
    this.$ngRedux.dispatch(changeStudyProgrammeGradeFilter(this.selectedGrade));
  }
}
