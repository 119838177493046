import { ClassDetails } from './myClassDetails';

export const myClassDetailsComponent = {
  template: require('./myClassDetails.html'),
  controllerAs: 'ctrl',
  controller: ClassDetails,
  bindings: {
    sClass: '<',
    sUserCanEdit: '<',
    sPeriod: '<?',
    sFuturePeriodInfo: '<',
  },
};
