import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';
import CurriculumSelectorButton from './curriculaSelectorButton/CurriculumSelectorButton';
import CurriculumSelectorModal from './curriculaSelectorModal/CurriculumSelectorModal';

import * as NEWSLETTER_ACTIONS from '../../../reduxLoop/newsletters/preferences/newsletterActions';

import './CurriculumSelector.scss';
import CurriculumSelectorListOfSubjects from './curriculaSelectorListOfSubjects/CurriculumSelectorListOfSubjects';

const CurriculumSelector = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const listOfThemes =
    useSelector((state) => state.vm.newsletters.subjectsNewsletterPreferences.subjects) || [];

  const dispatch = useDispatch();

  const openCurriculumSelectorModal = async (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const submitCurriculumSelectorModal = (data) => {
    if (data) {
      const removedThemes = listOfThemes
        .filter((theme) => !data.some((item) => theme.href === item.href))
        .map((theme) => theme.href);
      const addedThemes = data
        .filter((item) => !listOfThemes.some((theme) => theme.href === item.href && theme.checked))
        .map((item) => item.href);

      removedThemes.forEach((themeHref) => {
        dispatch(
          NEWSLETTER_ACTIONS.newsletterSetOption(
            false,
            NEWSLETTER_TYPES.SUBJECTS,
            themeHref,
            'SUBJECTS',
            null
          )
        );
      });
      addedThemes.forEach((themeHref) => {
        dispatch(
          NEWSLETTER_ACTIONS.newsletterSetOption(
            true,
            NEWSLETTER_TYPES.SUBJECTS,
            themeHref,
            'SUBJECTS',
            null
          )
        );
      });
    }

    setIsModalOpen(false);
  };

  return (
    <div className="newsletter-curriculum-section">
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12 section-subtitle-small">
            Voor welke leerplannen wil je nieuws ontvangen?
          </div>

          {listOfThemes.some((theme) => theme.type === 'CURRICULUM_THEME') ? (
            <div className="selected-subjects">
              <CurriculumSelectorListOfSubjects
                subjects={listOfThemes.filter((theme) => theme.type === 'CURRICULUM_THEME')}
                showMoreOption
              />
            </div>
          ) : null}
        </div>
      </div>

      {listOfThemes.some((theme) => theme.type !== 'CURRICULUM_THEME') ? (
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 section-subtitle-small">
              Vakken en studiegebieden (oude structuur)
            </div>
            <div className="selected-subjects">
              <CurriculumSelectorListOfSubjects
                subjects={listOfThemes.filter((theme) => theme.type !== 'CURRICULUM_THEME')}
                showMoreOption
              />
            </div>
          </div>
        </div>
      ) : null}

      <div className="col-sm-12">
        <CurriculumSelectorButton
          icon="glyphicon-plus"
          label="Pas deze selectie aan"
          onClick={(event) => openCurriculumSelectorModal(event)}
        />
      </div>

      {isModalOpen && (
        <CurriculumSelectorModal
          handleSubmit={submitCurriculumSelectorModal}
          handleClose={() => setIsModalOpen(false)}
          selectedThemes={listOfThemes.filter((theme) => theme.checked).map((theme) => theme.href)}
        />
      )}
    </div>
  );
};

export default CurriculumSelector;
