import React from 'react';

const MyCookiesLink = () => {
  const openCookiesModal = () => {
    document.dispatchEvent(
      new CustomEvent('openKovCookieModal', { bubbles: true, composed: true })
    );
  };

  return (
    <div className="footerbar-link-container">
      <a className="footerbar-link" onClick={openCookiesModal}>
        cookie-instellingen
      </a>
    </div>
  );
};

export default MyCookiesLink;
