import { getClosestSchoolYearSwitch, getNextDay } from '@kathondvla/sri-client/date-utils';
import { isSameStreet, isSameHouseNumberAndMailbox } from '@kathondvla/sri-client/address-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { getKeyAndMeta } from '../transformations';
import { getAllPhysicallocationsInCity } from '../dataAccess';

class VestigingsplaatsenComponent {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.sriClient = sriClient;
    this.getClosestSchoolYearSwitch = getClosestSchoolYearSwitch;
    this.getNextDay = getNextDay;
    this.showLocationHistory = false;
    this.physicalLocationsCache = {};
    this.discimusSerialNumberErrors = [];
    this.discimusPattern = {
      pattern: /^[1-9][0-9]?$/,
      message: 'Het discimusnummer is een getal tussen 1 en 99',
    };
  }

  calculateVm() {
    this.$scope.$emit('onCalculateVm');
  }

  locStartDateChanged(e, value) {
    this.editLocation.startDate = value;
  }

  locEndDateChanged(e, value) {
    this.editLocation.endDate = value;
  }

  discimusSerialNumberChanged(e, value) {
    const valueAsNumber = value ? parseInt(value, 10) : null;
    if (
      value &&
      this.ou.locations.some(
        (loc) =>
          (!this.editLocation.resource || loc.resource.key !== this.editLocation.resource.key) &&
          loc.discimusSerialNumber === valueAsNumber
      )
    ) {
      console.log('Komen we hier?');
      this.discimusSerialNumberErrors = [
        { message: `Er is al een vestigingsplaats met discimus nummmer ${value}` },
      ];
    } else {
      this.discimusSerialNumberErrors = [];
    }
    this.editLocation.discimusSerialNumber = valueAsNumber;
  }

  historyFilter(location) {
    return location.alwaysShow || location.isActiveOrFuture;
  }

  toggleShowHistory() {
    this.showLocationHistory = !this.showLocationHistory;
    this.ou.locations.forEach((loc) => {
      loc.alwaysShow = this.showLocationHistory;
    });
  }

  async addressChanged(e, value) {
    this.editLocation.address = value;
    this.addressDirty = true;
    if (value.cityHref && this.physicalLocationsCache.cityHref !== value.cityHref) {
      console.log('we are going to update the cache....');
      const allPhysicalLocations = await getAllPhysicallocationsInCity(
        value.cityHref,
        'DOMAIN'
      );
      this.physicalLocationsCache = {
        cityHref: value.cityHref,
        physicalLocations: allPhysicalLocations,
      };
      console.log('cache updated!');
    } else {
      console.log('else?', value);
    }
  }

  editThisLocation(vpl) {
    this.editLocation = { ...vpl };
    this.discimusSerialNumberErrors = [];
  }

  getPhysicalLocation() {
    if (
      !this.physicalLocationsCache.cityHref ||
      this.physicalLocationsCache.cityHref !== this.editLocation.address.cityHref
    ) {
      console.log('physical locations cache', this.physicalLocationsCache);
      console.log('location', this.editLocation);
      throw new Error(
        'The cityHref of the address does not correspond with the cityHref of the physical locations cache'
      );
    }
    const existingPhysicalLocation = this.physicalLocationsCache.physicalLocations.find(
      (pl) =>
        isSameStreet(pl.address, this.editLocation.address) &&
        isSameHouseNumberAndMailbox(pl.address, this.editLocation.address)
    );
    const physicalLocation = existingPhysicalLocation || {
      ...getKeyAndMeta('/sam/physicallocations'),
      type: 'DOMAIN',
      address: this.editLocation.address,
    };
    if (!existingPhysicalLocation) {
      this.pendingResources.physicalLocations[physicalLocation.$$meta.permalink] = physicalLocation;
      this.physicalLocationsCache.physicalLocations.push(physicalLocation);
    }
    return physicalLocation;
  }

  confirmLocation() {
    if (!this.editLocationForm.valid && this.disimusSerialNumberErrors.length === 0) {
      this.$ngRedux.dispatch(addErrorNotification('Vul alle velden voor de locatie correct in.'));
      return;
    }
    if (this.editLocation.isNew) {
      const physicalLocation = this.getPhysicalLocation();
      const newLocation = {
        ...getKeyAndMeta('/sam/organisationalunits/locations'),
        type: 'VESTIGINGSPLAATS',
        physicalLocation: { href: physicalLocation.$$meta.permalink, $$expanded: physicalLocation },
        organisationalUnit: { href: this.ou.href },
        discimusSerialNumber: this.editLocation.discimusSerialNumber,
        startDate: this.editLocation.startDate,
        endDate: this.editLocation.endDate,
      };
      this.pendingResources.locations[newLocation.$$meta.permalink] = newLocation;
    } else {
      const physicalLocation = !this.addressDirty
        ? this.editLocation.resource.physicalLocation.$$expanded
        : this.getPhysicalLocation();
      this.pendingResources.locations[this.editLocation.resource.$$meta.permalink] = {
        ...this.editLocation.resource,
        physicalLocation: { href: physicalLocation.$$meta.permalink, $$expanded: physicalLocation },
        discimusSerialNumber: this.editLocation.discimusSerialNumber,
        startDate: this.editLocation.startDate,
        endDate: this.editLocation.endDate,
      };
    }

    this.calculateVm();
    this.editLocation = null;
  }
}

export const myLocationsComponent = {
  template: require('./locationsComponent.html'),
  controllerAs: 'ctrl',
  controller: VestigingsplaatsenComponent,
  bindings: {
    ou: '<',
    ouType: '<',
    pendingResources: '<',
  },
};
