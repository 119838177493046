import { getPositionDefinition } from 'ReduxLoop/utils/responsibilityUtils';
import { getPositionClass } from 'ReduxLoop/ouManager/schoolManagerModel';
import {
  BESTUURDER,
  LERARENNETWERK,
  LID_VAN_HET_DAGELIJKS_BESTUUR,
  COORDINEREND_DIRECTEUR,
  DIRECTEUR_COORDINATIE,
  VOORZITTER,
  LID,
  SCHOOLENTITY,
  SCHOOLCOMMUNITY,
  GOVERNINGINSTITUTION,
  VERGADERGROEP,
  LEERPLANCOMMISSIE,
} from 'ReduxLoop/constants';

const POSSIBLE_OU_TYPES = [
  {
    display: 'Een <strong>basisschool</strong>',
    displayType: 'school',
    typeArticle: 'de',
    ouType: 'SCHOOL',
    mainstructures: [
      '/sam/commons/mainstructures/a3eb0c96-a3a4-11e3-ace8-005056872b95', // 111
      '/sam/commons/mainstructures/a3eb0f5c-a3a4-11e3-ace8-005056872b95', // 121
      '/sam/commons/mainstructures/a3eb10b0-a3a4-11e3-ace8-005056872b95', // 211
      '/sam/commons/mainstructures/a3eb151a-a3a4-11e3-ace8-005056872b95', // 221
    ],
  },
  {
    display: 'Een <strong>secundaire school</strong>',
    displayType: 'school',
    typeArticle: 'de',
    ouType: SCHOOLENTITY,
    mainstructures: [
      '/sam/commons/mainstructures/a3eb1646-a3a4-11e3-ace8-005056872b95', // 311
      '/sam/commons/mainstructures/a3eb17b8-a3a4-11e3-ace8-005056872b95', // 312
      '/sam/commons/mainstructures/a3eb2212-a3a4-11e3-ace8-005056872b95', // 316
      '/sam/commons/mainstructures/a3eb1c72-a3a4-11e3-ace8-005056872b95', // 321
    ],
  },
  {
    display: 'Een <strong>hogeschool</strong></br>of <strong>universiteit</strong>',
    displayType: 'school',
    typeArticle: 'de',
    ouType: 'SCHOOL',
    mainstructures: [
      '/sam/commons/mainstructures/a3eb1d94-a3a4-11e3-ace8-005056872b95', // 411
      '/sam/commons/mainstructures/a3eb20fa-a3a4-11e3-ace8-005056872b95', // 511
    ],
  },
  {
    display: 'Een <strong>CVO</strong>',
    displayType: 'centrum',
    typeArticle: 'het',
    ouType: 'SCHOOL',
    mainstructures: [
      '/sam/commons/mainstructures/a3eb1b50-a3a4-11e3-ace8-005056872b95', // 317
      '/sam/commons/mainstructures/a3eb1eac-a3a4-11e3-ace8-005056872b95', // 417
      '/sam/commons/mainstructures/a3eb1fce-a3a4-11e3-ace8-005056872b95', // 618
    ],
  },
  {
    display: 'Een <strong>scholengemeenschap</strong>',
    displayType: 'scholengemeenschap',
    typeArticle: 'de',
    ouType: SCHOOLCOMMUNITY,
  },
  {
    display: 'Een <strong>bestuur</strong>',
    displayType: 'bestuur',
    typeArticle: 'het',
    ouType: GOVERNINGINSTITUTION,
  },
  {
    display: 'Een <strong>CLB</strong>',
    displayType: 'CLB',
    typeArticle: 'het',
    ouType: 'CLB',
  },
  {
    display: 'Een <strong>leersteuncentrum</strong>',
    displayType: 'leersteuncentrum',
    typeArticle: 'het',
    ouType: 'LEERSTEUNCENTRUM',
  },
  {
    display: 'Een <strong>internaat</strong>',
    displayType: 'internaat',
    typeArticle: 'het',
    ouType: 'BOARDING',
  },
  {
    display: 'Een <strong>vergadergroep</strong> of <strong>leerplancommissie</strong>',
    displayType: 'team',
    typeArticle: 'het',
    ouType: 'VERGADERGROEP,LEERPLANCOMMISSIE',
    isOnlyForKathOndVlaEmployees: true,
  },
  {
    display: 'Een <strong>lerarennetwerk</strong>',
    displayType: 'lerarennetwerk',
    typeArticle: 'het',
    ouType: LERARENNETWERK,
  },
  {
    display: '<strong>Andere</strong>',
    displayType: 'organisatie',
    typeArticle: 'de',
    ouType: 'ANDERE',
  },
];

const getPosition = (position, ouType) => {
  if (!position) {
    return {
      name: '',
      positionClass: {
        name: 'geen functie',
        sortOrder: 5,
      },
    };
  }
  const definition = getPositionDefinition(position, ouType);
  return {
    permalink: position.$$meta.permalink,
    name: position.name,
    definition,
    positionClass: getPositionClass(position, definition, ouType),
  };
};

export const getRequestResponsibilityViewModel = (state) => {
  let possiblePositions = state.requestResponsibility.possiblePositions
    ? state.requestResponsibility.possiblePositions
        .map((position) => getPosition(position, state.requestResponsibility.ou.types[0]))
        .filter((position) => position.definition.positionClass !== 'DELEGATION_ROLE')
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    : null;
  if (possiblePositions) {
    if (state.requestResponsibility.ou.type === 'SCHOOLCOMMUNITY') {
      if (
        state.requestResponsibility.ou.schools &&
        state.requestResponsibility.ou.schools.some(
          (school) =>
            school.mainstructures &&
            school.mainstructures.some((ms) => ms.code.match(/^[1-2][1-9]{2}$/))
        )
      ) {
        possiblePositions = possiblePositions.filter(
          (pos) => pos.permalink !== COORDINEREND_DIRECTEUR
        );
      } else {
        possiblePositions = possiblePositions.filter(
          (pos) => pos.permalink !== DIRECTEUR_COORDINATIE
        );
      }
    }
    if (state.requestResponsibility.ou.type === 'GOVERNINGINSTITUTION') {
      // possiblePositions = possiblePositions.filter(position => position.permalink !== BESTUURDER && position.permalink !== LID_VAN_HET_DAGELIJKS_BESTUUR);
      possiblePositions.push({ name: 'bestuursmedewerker', none: true });
    } else if (
      state.requestResponsibility.ou.type === VERGADERGROEP ||
      state.requestResponsibility.ou.type === LEERPLANCOMMISSIE
    ) {
      if (state.requestResponsibility.ou.$$meta.isCreatedByLoket) {
        possiblePositions = possiblePositions.filter(
          (pos) => pos.permalink === VOORZITTER || pos.permalink === LID
        );
      }
    } else if (state.requestResponsibility.ou.type !== LERARENNETWERK) {
      possiblePositions.push({ name: '(geen van bovenstaanden)', none: true });
    }
  }
  let selectedPosition = null;
  let bestuurderSelected = null;
  if (state.requestResponsibility.emptyPositionSelected) {
    selectedPosition = possiblePositions.filter((posPos) => posPos.none)[0];
  } else if (state.requestResponsibility.position && possiblePositions) {
    selectedPosition = possiblePositions.filter(
      (posPos) => posPos.permalink === state.requestResponsibility.position.$$meta.permalink
    )[0];
    if (
      state.requestResponsibility.ou.types[0] === 'GOVERNINGINSTITUTION' &&
      (state.requestResponsibility.position.$$meta.permalink === BESTUURDER ||
        state.requestResponsibility.position.$$meta.permalink === LID_VAN_HET_DAGELIJKS_BESTUUR)
    ) {
      bestuurderSelected = `zonder aanvraag als ${state.requestResponsibility.position.name}`;
    }
  }
  let contactHelpdeskForm = null;
  if (state.requestResponsibility.ouTypeFilter) {
    if (state.register.stage && state.register.stage === 'ADD_REQUEST_TO_REGISTRATION') {
      contactHelpdeskForm = {
        firstName: state.register.firstName,
        lastName: state.register.lastName,
        personHref: `/persons/${state.register.key}`,
        ouType: state.requestResponsibility.ouTypeFilter,
        ouTypeDescription: state.requestResponsibility.ouTypeDescription,
        emailAddress: state.register.emailAddress,
        isRegistering: true,
      };
    } else if (state.userState.user) {
      contactHelpdeskForm = {
        firstName: state.userState.user.firstName,
        lastName: state.userState.user.lastName,
        personHref: state.userState.user.$$meta.permalink,
        ouType: state.requestResponsibility.ouTypeFilter,
        ouTypeDescription: state.requestResponsibility.ouTypeDescription,
        emailAddress: state.userState.user.$$email,
      };
    }
  }
  return {
    ...state.requestResponsibility,
    isRegistering: state.register.stage && state.register.stage === 'ADD_REQUEST_TO_REGISTRATION',
    subTitle:
      state.register.stage && state.register.stage === 'ADD_REQUEST_TO_REGISTRATION'
        ? `Zodra jouw registratie voltooid is, sturen we een mail naar de verantwoordelijken van jouw ${state.requestResponsibility.displayType} om toegang te vragen tot het team`
        : `Stuur een mail naar de verantwoordelijken van jouw ${state.requestResponsibility.displayType} om toegang te vragen tot het team`,
    noTypeOption:
      state.register.stage && state.register.stage === 'ADD_REQUEST_TO_REGISTRATION'
        ? 'Ik behoor niet tot een organisatie'
        : null,
    noOuOption:
      state.register.stage &&
      state.register.stage === 'ADD_REQUEST_TO_REGISTRATION' &&
      state.requestResponsibility.ouTypeFilter === 'ANDERE'
        ? 'Ik behoor niet tot een organisatie'
        : null,
    possiblePositions,
    selectedPosition,
    bestuurderSelected,
    contactHelpdeskForm,
    possibleOuTypes:
      state.vm && state.vm.user.isKathOndVlaEmployee
        ? POSSIBLE_OU_TYPES
        : POSSIBLE_OU_TYPES.filter((type) => !type.isOnlyForKathOndVlaEmployees),
    positionErrors:
      state.requestResponsibility.ouErrors.length > 0
        ? []
        : state.requestResponsibility.positionErrors,
  };
};
