export const GOTO_PROFILE_PAGE = 'goto_profile_page';
export const SELECT_PROFILE = 'select_profile';
export const PROFILE_INFO_FETCHED = 'profile_info_fetched';
export const PROFILE_TEAM_INFO_FETCHED = 'profile_team_info_fetched';
export const KANTHONDVLA_RESPONSIBILITY_RELATIONS_FETCHED =
  'kathondvla_responsibility_relations_fetched';
export const FETCH_CONTACTDETAILS = 'fetch_contactdetails';
export const SECURITY_MAILBOX_FETCHED = 'security_mailbox_fetched';
export const CONTACTDETAILS_FETCHED = 'contactdetails_fetched';
export const BANKACCOUNTS_FETCHED = 'bankaccounts_fetched';
export const ACCOUNT_INFO_FETCHED = 'account_info_fetched';
export const PENDING_REQUESTS_FETCHED = 'pending_requests_fetched';
export const REFRESH_PERSON = 'refresh_person';
export const PERSON_REFRESHED = 'person_fetched';
export const REPLACE_PROFILE_PICTURE = 'replace_profile_picture';
export const ADD_PROFILE_PICTURE = 'add_profile_picture';
export const DELETE_PROFILE_PICTURE = 'delete_profile_picture';
export const UNLINK_ACCOUNT = 'unlink_account';
export const EDIT_BIOGRAPHY = 'edit_biography';
export const PERSIST_PROFILE_CHANGES = 'persist_profile_changes';
export const PERSIST_PROFILE_CHANGES_FAILED = 'persist_profile_changes_failed';
export const UPDATE_PROFILE_FORM = 'update_profile_form';
export const UPDATE_GENDER = 'update_gender';
export const UPDATE_FIRSTNAME = 'update_firstname';
export const UPDATE_LASTNAME = 'update_lastname';
export const UPDATE_REGISTRATION_NUMBER = 'update_registration_number';
export const UPDATE_DATE_OF_BIRTH = 'update_date_of_birth';
export const UPDATE_USERNAME = 'update_username';
export const USERNAME_IS_FREE = 'validate_is_free';
export const USERNAME_IS_RESERVED = 'username_is_reserved';
export const UPDATE_PRIMARY_EMAIL = 'update_primary_email';
export const VALIDATE_EMAIL_ADDRESS = 'validate_email_address';
export const EMAIL_ADDRESS_IS_FREE = 'email_address_is_free';
export const EMAIL_ADDRESS_IS_RESERVED = 'email_address_is_reserved';
export const UPDATE_SECONDARY_EMAIL = 'update_secondary_email';
export const UPDATE_OFFICE_PHONE = 'update_office_phone';
export const UPDATE_OFFICE_MOBILE_PHONE = 'update_office_mobile_phone';
export const UPDATE_OFFICE_ADDRESS = 'update_office_address';
export const UPDATE_PERSONAL_PHONE = 'update_personal_phone';
export const UPDATE_PERSONAL_MOBILE_PHONE = 'update_personal_mobile_phone';
export const UPDATE_PERSONAL_ADDRESS = 'update_personal_address';
export const UPDATE_IBAN_EXPENSES = 'update_iban_expenses';
export const ADD_PERSONAL_CONTACTDETAILS = 'add_personal_contactdetails';
export const OU_FOR_NEW_RESPONSIBILITY_FETCHED = 'ou_for_new_responsibility_fetched';
export const SEND_REMINDER_REQUEST_RESP = 'send_reminder_request_resp';

export const selectProfile = (personKey) => ({
  type: SELECT_PROFILE,
  payload: personKey,
});

export const profileInfoFetched = (profileInfo) => ({
  type: PROFILE_INFO_FETCHED,
  payload: profileInfo,
});

export const fetchContactDetails = () => ({
  type: FETCH_CONTACTDETAILS,
});

export const contactDetailsFetched = (contactDetails) => ({
  type: CONTACTDETAILS_FETCHED,
  payload: contactDetails,
});

export const bankAccountsFetched = (bankAccounts) => ({
  type: BANKACCOUNTS_FETCHED,
  payload: bankAccounts,
});

export const profileTeamInfoFetched = (teamInfo) => ({
  type: PROFILE_TEAM_INFO_FETCHED,
  payload: teamInfo,
});

export const replaceProfilePicture = (imagesObj, personHref) => ({
  type: REPLACE_PROFILE_PICTURE,
  payload: {
    images: imagesObj,
    personHref,
  },
});

export const addProfilePicture = (imagesObj, personHref) => ({
  type: ADD_PROFILE_PICTURE,
  payload: {
    images: imagesObj,
    personHref,
  },
});

export const deleteProfilePicture = (personHref) => ({
  type: DELETE_PROFILE_PICTURE,
  payload: personHref,
});

export const kathondvlaResponsibilityRelationsfetched = (payload) => ({
  type: KANTHONDVLA_RESPONSIBILITY_RELATIONS_FETCHED,
  payload,
});

export const accountInfoFetched = (account) => ({
  type: ACCOUNT_INFO_FETCHED,
  payload: account,
});

export const pendingRequestsFetched = (requestsInfo) => ({
  type: PENDING_REQUESTS_FETCHED,
  payload: requestsInfo,
});

export const unlinkAccount = (vmAccount) => ({
  type: UNLINK_ACCOUNT,
  payload: vmAccount,
});

export const editBiography = (biography) => ({
  type: EDIT_BIOGRAPHY,
  payload: biography,
});

export const securityMailboxFetched = (hasKathOndVlaMailboxRights) => ({
  type: SECURITY_MAILBOX_FETCHED,
  payload: hasKathOndVlaMailboxRights,
});

export const refreshPerson = () => ({
  type: REFRESH_PERSON,
});

export const personRefreshed = (person) => ({
  type: PERSON_REFRESHED,
  payload: person,
});

export const persistProfileChanges = () => ({
  type: PERSIST_PROFILE_CHANGES,
});

export const persistProfileChangesFailed = (error) => ({
  type: PERSIST_PROFILE_CHANGES_FAILED,
  payload: error,
});

export const updateProfileForm = (errorSummary) => ({
  type: UPDATE_PROFILE_FORM,
  payload: errorSummary,
});

export const updateGender = (sex) => ({
  type: UPDATE_GENDER,
  payload: sex,
});

export const updateFirstName = (firstName) => ({
  type: UPDATE_FIRSTNAME,
  payload: firstName,
});

export const updateLastName = (lastName) => ({
  type: UPDATE_LASTNAME,
  payload: lastName,
});

export const updateRegistrationNumber = (registrationNumber) => ({
  type: UPDATE_REGISTRATION_NUMBER,
  payload: registrationNumber,
});

export const updateDateOfBirth = (dateOfBirth) => ({
  type: UPDATE_DATE_OF_BIRTH,
  payload: dateOfBirth,
});

export const updateUsername = (username) => ({
  type: UPDATE_USERNAME,
  payload: username,
});

export const usernameIsFree = (username) => ({
  type: USERNAME_IS_FREE,
  payload: username,
});

export const usernameIsReserved = (username) => ({
  type: USERNAME_IS_RESERVED,
  payload: username,
});

export const updatePrimaryEmail = (emailAddress) => ({
  type: UPDATE_PRIMARY_EMAIL,
  payload: emailAddress,
});

export const updateSecondaryEmail = (emailAddress) => ({
  type: UPDATE_SECONDARY_EMAIL,
  payload: emailAddress,
});

export const validateEmailAddress = (emailAddress) => ({
  type: VALIDATE_EMAIL_ADDRESS,
  payload: emailAddress,
});

export const emailAddressIsFree = (emailAddress, doNotPersist) => ({
  type: EMAIL_ADDRESS_IS_FREE,
  payload: {
    emailAddress,
    doNotPersist,
  },
});

export const emailAddressIsReserved = (
  emailAddress,
  typeDescription,
  duplicateError,
  doNotPersist
) => ({
  type: EMAIL_ADDRESS_IS_RESERVED,
  payload: {
    emailAddress,
    typeDescription,
    duplicateError,
    doNotPersist,
  },
});

export const updateOfficePhone = (phone) => ({
  type: UPDATE_OFFICE_PHONE,
  payload: phone,
});

export const updateOfficeMobilePhone = (phone) => ({
  type: UPDATE_OFFICE_MOBILE_PHONE,
  payload: phone,
});

export const updateOfficeAddress = (address) => ({
  type: UPDATE_OFFICE_ADDRESS,
  payload: address,
});

export const updatePersonalPhone = (phone) => ({
  type: UPDATE_PERSONAL_PHONE,
  payload: phone,
});

export const updatePersonalMobilePhone = (phone) => ({
  type: UPDATE_PERSONAL_MOBILE_PHONE,
  payload: phone,
});

export const updatePersonalAddress = (address) => ({
  type: UPDATE_PERSONAL_ADDRESS,
  payload: address,
});

export const updateIbanExpenses = (ibanExpenses) => ({
  type: UPDATE_IBAN_EXPENSES,
  payload: ibanExpenses,
});

export const addPersonalContactDetails = () => ({
  type: ADD_PERSONAL_CONTACTDETAILS,
});

export const ouForNewResponsibilityFetched = (ou, responsibility) => ({
  type: OU_FOR_NEW_RESPONSIBILITY_FETCHED,
  payload: {
    ou,
    responsibility,
  },
});

export const END_RESPONSIBILITIES_AND_REQUESTS = 'END_RESPONSIBILITIES_AND_REQUESTS';
export const endResponsibilitiesAndRequests = (hrefs, endDate, teamInfo) => ({
  type: END_RESPONSIBILITIES_AND_REQUESTS,
  payload: { hrefs, endDate, teamInfo },
});

export const PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS =
  'PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS';
export const persistResponsibilitiesAndRequestsSuccess = (payload) => ({
  type: PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS,
  payload,
});

export const PERSIST_RESPONSIBILITIES_AND_REQUESTS_FAILED =
  'PERSIST_RESPONSIBILITIES_AND_REQUESTS_FAILED';
export const persistResponsibilitiesAndRequestsFailed = () => ({
  type: PERSIST_RESPONSIBILITIES_AND_REQUESTS_FAILED,
});

export const RESPONSIBILITIES_AND_REQUESTS_REFETCHED = 'RESPONSIBILITIES_AND_REQUESTS_REFETCHED';
export const responsibilitiesAndRequestsRefetched = (payload) => ({
  type: RESPONSIBILITIES_AND_REQUESTS_REFETCHED,
  payload,
});

export const sendReminderRequestResponsibility = (pendingResponsibilityHrefs) => ({
  type: SEND_REMINDER_REQUEST_RESP,
  payload: { pendingResponsibilityHrefs },
});

export const REQUEST_RESPONSIBILITY_REMINDER_SENT = 'REQUEST_RESPONSIBILITY_REMINDER_SENT';
export const requestResponsiblityReminderSent = (response) => ({
  type: REQUEST_RESPONSIBILITY_REMINDER_SENT,
  payload: { response }
});
