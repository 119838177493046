export const FETCH_POSSIBLE_STUDYPROGRAMMES = 'fetch_possible_studyprogrammes';
export const POSSIBLE_STUDYPROGRAMMES_FETCHED = 'possible_studyprogrammes_fetched';
export const FETCH_STUDYPROGRAMMES = 'fetch_studyprogrammes';
export const STUDYPROGRAMMES_FETCHED = 'studyprogrammes_fetched';
export const ADD_STUDYPROGRAMME_TO_SCHOOL = 'add_studyprogramme_to_school';
export const DELETE_STUDYPROGRAMME_FROM_SCHOOL = 'delete_studyprogramme_from_school';
export const CHANGE_STUDYPROGRAMME_GRADE_FILTER = 'change_studyprogramme_grade_filter';

export const fetchPossibleStudyProgrammes = () => ({
  type: FETCH_POSSIBLE_STUDYPROGRAMMES,
});

export const possibleStudyProgrammesFetched = (studyProgrammes) => ({
  type: POSSIBLE_STUDYPROGRAMMES_FETCHED,
  payload: studyProgrammes,
});

export const fetchStudyProgrammes = (ou) => ({
  type: FETCH_STUDYPROGRAMMES,
  payload: ou,
});

export const studyProgrammesFetched = (studyProgrammes) => ({
  type: STUDYPROGRAMMES_FETCHED,
  payload: studyProgrammes,
});

export const addStudyProgrammeToSchool = (studyProgrammeRelation) => ({
  type: ADD_STUDYPROGRAMME_TO_SCHOOL,
  payload: studyProgrammeRelation,
});

export const deleteStudyProgrammeFromSchool = (studyProgramme) => ({
  type: DELETE_STUDYPROGRAMME_FROM_SCHOOL,
  payload: studyProgramme,
});

export const changeStudyProgrammeGradeFilter = (grade) => ({
  type: CHANGE_STUDYPROGRAMME_GRADE_FILTER,
  payload: grade,
});
