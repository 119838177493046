import { MyMemberList } from './myMemberList';

export const myMemberListComponent = {
  template: require('./myMemberList.html'),
  controllerAs: 'ctrl',
  controller: MyMemberList,
  transclude: true,
  bindings: {
    sMembers: '<',
    sShowBorder: '<',
    sOuType: '<',
  },
};
