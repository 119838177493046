import { AddEditClassModal } from './addEditClassModal';

export const myAddEditClassModal = {
  template: require('./addEditClassModal.html'),
  controllerAs: 'ctrl',
  controller: AddEditClassModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
