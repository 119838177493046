import { createStore, compose, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import { install } from 'redux-loop';
import LogRocket from 'logrocket';
import { rootReducer } from 'ReduxLoop/index';

let store = null;

// Create a store
export function createAppStore() {
  // const logger = createLogger({});

  const enhancer = compose(
    install(),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (v) => v,
    applyMiddleware(LogRocket.reduxMiddleware())
    // applyMiddleware(logger)
  );

  store = createStore(rootReducer, enhancer);

  return store;
}

export function getStore() {
  return store;
}
