import {
  getStartOfSchoolYear,
  getNow,
  isOverlapping,
  isBefore,
  isAfter,
} from '@kathondvla/sri-client/date-utils';
import { printAddress } from '@kathondvla/sri-client/address-utils';

function displayCampus(campus) {
  if (!campus) {
    return null;
  }

  return `${campus.name ? `${campus.name} - ` : ''}${printAddress(campus.address)}`;
}
export class AddEditClassModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;

    this.displayCampus = displayCampus;
    this.addIcon = require('Img/add.svg');
    this.editIcon = require('Img/edit.svg');
  }

  $onInit() {
    this.endDate = null;
    this.school = this.resolve.school;
    this.campusClasses = this.resolve.campusClasses;

    if (this.resolve.clazz) {
      this.startDate = this.resolve.clazz.startDate;
      this.endDate = this.resolve.clazz.endDate;
      this.name = this.resolve.clazz.name;
      this.campus = this.campusClasses.find((campus) =>
        campus.classes.some((clazz) => clazz.permalink === this.resolve.clazz.permalink)
      );
      this.relationPermalink = this.resolve.clazz.relationPermalink;
    } else {
      this.startDate = getStartOfSchoolYear(getNow());
      this.campus = this.campusClasses.length > 0 ? this.campusClasses[0] : null;
    }

    this.schoolStartDate = this.school.startDate;
    this.schoolEndDate = this.school.endDate;
  }

  cancel() {
    this.dismiss({
      $value: 'cancel',
    });
  }

  onChangeStartDate(e, startDate) {
    this.startDate = startDate;
  }

  onChangeClassName(e, className) {
    this.name = className;
  }

  validateClassStartDate(classStartDate, schoolStartDate, schoolEndDate) {
    if (isBefore(classStartDate, schoolStartDate)) {
      return {
        code: 'class.startdate.before.school.startdate',
        message: `De school is pas ingericht vanaf ${schoolStartDate
          .split('-')
          .reverse()
          .join('/')}.`,
      };
    }
    if (isAfter(classStartDate, schoolEndDate)) {
      return {
        code: 'class.startdate.after.school.enddate',
        message: `De school is alleen ingericht tot ${schoolEndDate
          .split('-')
          .reverse()
          .join('/')}.`,
      };
    }
  }

  validateClassName(className, campuses, relationPermalink, startDate, endDate) {
    let campus;

    for (campus of campuses) {
      if (
        campus &&
        campus.classes.some(
          (clazz) =>
            clazz.name.trim().toLowerCase() === className.trim().toLowerCase() &&
            clazz.relationPermalink !== relationPermalink &&
            isOverlapping(clazz, {
              startDate,
              endDate,
            })
        )
      ) {
        return {
          code: 'duplicate.class',
          message: 'Een klas met dezelfde naam bestaat reeds binnen de school.',
        };
      }
    }
  }

  onSelectCampus(e, campus) {
    this.campus = campus;
  }

  addEditClass() {
    if (!this.addEditClassForm.valid) {
      return;
    }

    this.close({
      $value: {
        name: this.name,
        startDate: this.startDate,
        clazz: this.resolve.clazz,
        campusPermalink: this.campus.permalink,
      },
    });
  }
}
