import { loop, Cmd } from 'redux-loop';
import { Map } from 'immutable';
import * as USER_COMMANDS from 'ReduxLoop/user/userCommands';
import * as ACTIONS from 'ReduxLoop/user/userActions';
import { arrayToMap } from 'ReduxLoop/index';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import {
  SAVE_ORGANISATION_DETAILS_SUCCESS,
  SELECT_ORGANISATIONAL_UNIT,
} from 'ReduxLoop/ouManager/schoolManagerActions';
import {
  PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS,
  PERSON_REFRESHED,
} from 'ReduxLoop/profile/profileActions';
import { PERSIST_NEW_TEAM_SUCCESS } from 'ReduxLoop/ouManager/createTeamWizard/createTeamWizardActions';
import {
  UPDATE_CONTACT_DETAIL_SUCCESS,
  REMOVE_CONTACT_DETAIL_SUCCESS,
} from '../ouManager/contactDetails/contactDetailsActions';
import * as PIWIK_ANALYTICS_ACTIONS from 'ReduxLoop/piwikAnalytics/piwikAnalyticsActions';

const inititalState = {
  user: null,
  bearerToken: null,
  logInCmd: null,
  isSuperAdmin: false,
  isKerndataAdmin: false,
  ousWithReadAccessToResps: [],
  ousWithAccessToInvitations: [],
  ousWithUpdateAccessToResps: [],
  ousWithGrantAccessToSensitiveInformation: [],
  popUpIsOpened: false,
  positions: null,
  // studyProgrammes: null,
  responsibilities: {
    sam: null,
    vos: null,
  },
  ous: Map(),
  vosOus: null,
  dienstParentRelations: [],
  parentRelations: Map(),
  campusses: Map(),
  ouContactDetails: Map(),
  ouContactDetailsFetched: false,
  userDidSkipContactDetailsModal: false,
  modalMissingInformationIsOpened: false,
};

export const userReducer = (state = inititalState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_USER:
      if (state.user && !action.payload) {
        return state;
      }
      return loop(
        state,
        Cmd.run(USER_COMMANDS.getUser, {
          args: [],
          successActionCreator: ACTIONS.userFetched,
        })
      );

    case ACTIONS.USER_FETCHED:
      return loop(
        { ...state, user: action.payload },
        Cmd.list([
          Cmd.run(USER_COMMANDS.getUserResponsibilities, {
            args: [action.payload],
            successActionCreator: ACTIONS.userResponsibilitiesFetched,
            failActionCreator: ACTIONS.fetchUserResponsibilitiesFailed,
          }),
          Cmd.run(USER_COMMANDS.getUserLoginMethod, {
            args: [action.payload],
            successActionCreator: ACTIONS.userLoginMethodFetched,
            failActionCreator: ACTIONS.userLoginMethodFailed,
          }),
          Cmd.run(USER_COMMANDS.identifyUserInLogRocket, { args: [action.payload] }),
        ])
      );

    case ACTIONS.ON_USER_IS_NOT_LOGGED_IN: {
      if (state.logInCmd) {
        return loop(state, Cmd.list([state.logInCmd]));
      }
      return loop(state, Cmd.run(USER_COMMANDS.goToOauthLoginScreen));
    }

    case ACTIONS.SET_LOG_IN_CMD: {
      return { ...state, logInCmd: action.payload };
    }

    case PERSON_REFRESHED: {
      if (state.user && action.payload.key === state.user.key) {
        return { ...state, user: action.payload };
      }
      return state;
    }

    case ACTIONS.FETCH_USER_RESPONSIBILITIES_FAILED:
    case ACTIONS.USER_LOGIN_METHOD_FAILED:
    case ACTIONS.USER_TARGET_GROUP_FAILED: {
      console.error(`SEVERE ERROR: ${action.type.toUpperCase()}`, action.payload);
      const addError = addErrorNotification(
        'Er is een onbekende fout opgetreden bij het ophalen van jouw gegevens.'
      );
      return loop(state, Cmd.action(addError));
    }

    case ACTIONS.USER_RESPONSIBILITIES_FETCHED: {
      const vosResps = action.payload.userResponsibilities.filter(
        (resp) => !resp.organisation.href.match(/^\/sam\//g)
      );
      return loop(
        {
          ...state,
          responsibilities: {
            sam: Map(
              arrayToMap(
                action.payload.userResponsibilities.filter((resp) =>
                  resp.organisation.href.match(/^\/sam\//g)
                )
              )
            ),
            vos: vosResps,
          },
          ous: Map(arrayToMap(action.payload.ous)),
          isSuperAdmin: action.payload.isSuperAdmin,
          isKerndataAdmin: action.payload.isKerndataAdmin,
          ousWithReadAccessToResps: action.payload.ousWithReadAccessToResps,
          ousWithAccessToInvitations: action.payload.ousWithAccessToInvitations,
          ousWithUpdateAccessToResps: action.payload.ousWithUpdateAccessToResps,
          ousWithGrantAccessToSensitiveInformation:
            action.payload.ousWithGrantAccessToSensitiveInformation,
          parentRelations: Map(arrayToMap(action.payload.parentRelations)),
        },
        Cmd.list([
          Cmd.run(USER_COMMANDS.getAllContactDetails, {
            args: [action.payload.ous],
            successActionCreator: ACTIONS.userOuContactDetailsFetched,
          }),
          Cmd.run(USER_COMMANDS.getVosOus, {
            args: [vosResps],
            successActionCreator: ACTIONS.vosOusFetched,
          }),
        ])
      );
    }

    case ACTIONS.USER_LOGIN_METHOD_FETCHED: {
      const newState = { ...state };
      newState.user.userLoginMethod = action.payload;
      return newState;
    }

    case ACTIONS.USER_TARGET_GROUP_FETCHED: {
      const newState = { ...state };
      newState.user.userTargetGroup = action.payload;
      return loop(newState, Cmd.action(PIWIK_ANALYTICS_ACTIONS.trackPiwikEvent('view_page')));
    }

    case ACTIONS.VOS_OUS_FETCHED:
      return loop(
        {
          ...state,
          vosOus: action.payload.ous,
          dienstParentRelations: action.payload.parentRelations,
        },
        Cmd.run(USER_COMMANDS.calculateUserTargetGroup, {
          args: [
            [...Object.values(state.responsibilities.sam.toJS()), ...state.responsibilities.vos],
            { ...state.ous.toJS(), ...action.payload.ous },
            action.payload.parentRelations,
          ],
          successActionCreator: ACTIONS.userTargetGroupFetched,
          failActionCreator: ACTIONS.userTargetGroupFailed,
        })
      );

    case ACTIONS.USER_OU_CONTACTDETAILS_FETHED:
      return {
        ...state,
        ouContactDetails: Map(arrayToMap(action.payload)),
        ouContactDetailsFetched: true,
      };

    case ACTIONS.FETCH_POSITIONS:
      if (state.positions) {
        return state;
      }
      return loop(
        state,
        Cmd.run(USER_COMMANDS.getPositions, {
          successActionCreator: ACTIONS.positionsFetched,
        })
      );

    case ACTIONS.POSITIONS_FETCHED: {
      return { ...state, positions: Map(arrayToMap(action.payload)) };
    }

    case ACTIONS.LOG_OUT:
      return loop(inititalState, Cmd.run(USER_COMMANDS.doLogOut));

    case ACTIONS.POP_UP_IS_OPENED: {
      return { ...state, popUpIsOpened: true };
    }

    case SAVE_ORGANISATION_DETAILS_SUCCESS: {
      const newVosOus = { ...state.vosOus };
      newVosOus[action.payload.$$meta.permalink] = action.payload;
      return { ...state, vosOus: newVosOus };
    }

    case PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS: {
      if (action.payload.deactivatedResponsibilities.length === 0) {
        return state;
      }
      let newSamResps = state.responsibilities.sam;
      let newVosResps = state.responsibilities.vos;
      action.payload.deactivatedResponsibilities.forEach((href) => {
        newVosResps = newVosResps.filter((resp) => resp.$$meta.permalink !== href);
        newSamResps = newSamResps.remove(href);
      });
      return { ...state, responsibilities: { vos: newVosResps, sam: newSamResps } };
    }

    case PERSIST_NEW_TEAM_SUCCESS: {
      const newVosResps = [...state.responsibilities.vos, action.payload.responsibility];
      const newVosOus = { ...state.vosOus };
      newVosOus[action.payload.organisation.$$meta.permalink] = action.payload.organisation;
      return {
        ...state,
        responsibilities: { vos: newVosResps, sam: state.responsibilities.sam },
        vosOus: newVosOus,
      };
    }

    case ACTIONS.USER_DID_SKIP_CONTACT_DETAILS_MODAL: {
      return { ...state, userDidSkipContactDetailsModal: true };
    }

    case ACTIONS.MODAL_MISSING_INFORMATION_IS_OPENED: {
      return { ...state, modalMissingInformationIsOpened: true };
    }

    case SELECT_ORGANISATIONAL_UNIT: {
      return { ...state, modalMissingInformationIsOpened: false };
    }

    case UPDATE_CONTACT_DETAIL_SUCCESS: {
      return {
        ...state,
        ouContactDetails: state.ouContactDetails.set(
          action.payload.contactDetail.$$meta.permalink,
          action.payload.contactDetail
        ),
      };
    }

    case REMOVE_CONTACT_DETAIL_SUCCESS: {
      return {
        ...state,
        ouContactDetails: state.ouContactDetails.delete(
          action.payload.contactDetail.$$meta.permalink
        ),
      };
    }

    default:
      return state;
  }
};
