import { MyAskPasswordResetLink } from './myAskPasswordRestLink';
import { MyChangePasswordFromLink } from './myChangePasswordFromLink';
import { MyChangePasswordFromLinkSuccess } from './myChangePasswordFromLinkSuccess';

require('./AskPasswordResetLink.scss');

export const myAskPasswordResetLink = {
  template: require('./myAskPasswordResetLink.html'),
  controllerAs: 'ctrl',
  controller: MyAskPasswordResetLink,
};

export const myAskPasswordResetLinkSent = {
  template: require('./myAskPasswordResetLinkSent.html'),
};

export const myChangePasswordFromLink = {
  template: require('./myChangePasswordFromLink.html'),
  controllerAs: 'ctrl',
  controller: MyChangePasswordFromLink,
};

export const myChangePasswordFromLinkSuccess = {
  template: require('./myChangePasswordFromLinkSuccess.html'),
  controllerAs: 'ctrl',
  controller: MyChangePasswordFromLinkSuccess,
};
