import { settings } from 'Js/config/settings';

const sriClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apis.samApi,
});

export const fetchOuData = async (ouHref) => {
  const ouPromise = sriClient.get(ouHref);
  const cdPromise = sriClient.getAll('/sam/organisationalunits/contactdetails', {
    organisationalUnit: ouHref,
  });
  const relationsFromPromise = sriClient.getAll('/sam/organisationalunits/relations', {
    to: ouHref,
    expand: 'results.from',
  });
  const relationsToPromise = sriClient.getAll('/sam/organisationalunits/relations', {
    from: ouHref,
    expand: 'results.to',
  });
  const locationsPromise = sriClient.getAll('/sam/organisationalunits/locations', {
    organisationalUnit: ouHref,
    expand: 'results.physicalLocation',
  });
  const ouResource = await ouPromise;
  const contactDetails = await cdPromise;
  const relationsFrom = await relationsFromPromise;
  const relationsTo = await relationsToPromise;
  const locations = await locationsPromise;
  const allIdentifierHrefs = [
    ouHref,
    ...relationsFrom.map((rel) => rel.from.href),
    ...relationsTo.map((rel) => rel.to.href),
  ];
  const identifiers = await sriClient.getAllReferencesTo(
    '/sam/organisationalunits/externalIdentifiers',
    { typeIn: 'INSTITUTION_NUMBER,COMPANY_NUMBER,VAT_NUMBER' },
    'organisationalUnit',
    allIdentifierHrefs
  );
  return {
    ouResource,
    contactDetails,
    relations: [...relationsFrom, ...relationsTo],
    locations,
    identifiers,
  };
};

export const getOuByInstitutionNumber = async (institutionNumber) => {
  const results = await sriClient.getList('/sam/organisationalunits/externalidentifiers', {
    value: institutionNumber,
    type: 'INSTITUTION_NUMBER',
  });
  if (results.length === 0) {
    return null;
  }
  return {
    $$meta: { permalink: results[0].organisationalUnit.href },
    institutionNumber,
  };
};

export const sendBatch = async (batch, validate) => {
  try {
    await sriClient.put(`/batch${validate ? '?dryRun=true' : ''}`, batch);
  } catch (error) {
    console.error('ERROR OCCURED', error);
    throw error;
  }
};

export const validateInstitutionNumber = async (value) => {
  const existing = await sriClient.getList('/sam/organisationalunits/externalidentifiers', {
    type: 'INSTITUTION_NUMBER',
    value,
  });
  if (existing.length > 0) {
    return [{ message: 'Er is al een instelling met dit instellingsnummer' }];
  }
  return [];
};

export const getAllPhysicallocationsInCity = async (cityHref, type) => {
  return sriClient.getAll('/sam/physicallocations', {
    type,
    'address.cityHref': cityHref,
    limit: 5000,
  });
};
