import { sriClient, oauthClient } from '../../utils/apiConfig';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export const getPositionAndOus = async (positionKey, ouKeys) => {
  const position = positionKey ? await sriClient.get(`/positions/${positionKey}`) : null;
  let ous = [];
  if (ouKeys.length === 1) {
    try {
      ous = [await sriClient.get(`/sam/organisationalunits/${ouKeys[0]}`)];
    } catch (err) {
      if (err instanceof SriClientError && err.status === 404) {
        ous = [await sriClient.get(`/organisations/${ouKeys[0]}`)];
      }
    }
  } else {
    ous = await sriClient.getAllHrefs(ouKeys.map((ouKey) => `/sam/organisationalunits/${ouKey}`));
  }

  let possiblePositions;

  if (ous.length === 1) {
    possiblePositions = await sriClient.getAll('/positions', {
      organisation: ous[0].$$meta.permalink,
      expand: 'FULL',
    });
    possiblePositions = possiblePositions.map((possiblePosition) => ({
      ...possiblePosition,
      definition: possiblePosition.definitions ? possiblePosition.definitions[0] : null,
    }));
  } else {
    possiblePositions = [];
  }

  return {
    position,
    ous,
    possiblePositions,
  };
};

export const getUser = () => {
  return oauthClient.get('/me', undefined, { credentials: 'include' });
};

export const sendEmailToOtrsHelpdesk = (report, customRecipient) => {
  return sriClient.post('/persons/contact-helpdesk', {
    customRecipient,
    subject: report.subject,
    description: report.description,
    invitations: report.invitation,
    person: {
      href: report.personHref,
      firstName: report.firstName,
      lastName: report.lastName,
      emailAddress: report.emailAddress,
    },
    organisationalUnit: {
      type: report.ouType,
      role: report.role,
      name: report.ouName,
      address: report.address,
    },
    startDate: report.startDate,
    remark: report.remark,
  });
};
