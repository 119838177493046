import { printAddress } from '@kathondvla/sri-client/address-utils';
import { mapToArray } from '../../viewModel';

export const getContactDetailsModel = (state) => {
  return {
    mail: state.contactDetails.mail ? state.contactDetails.mail.value : null,
    phone: state.contactDetails.phone ? state.contactDetails.phone.value.phone : null,
    email: state.contactDetails.email ? state.contactDetails.email.value.email : null,
    website: state.contactDetails.website ? state.contactDetails.website.value.website : null,
    phoneErrors: state.contactDetails.phoneErrors,
    campuses: mapToArray(state.classesTab.campuses.toJS()).map((c) => {
      const okIdResource = state.classesTab.okIds.find(
        (okId) => okId.location.href === c.$$meta.permalink
      );
      return {
        name: c.name,
        address: printAddress(c.physicalLocation.$$expanded.address),
        href: okIdResource
          ? `https://www.onderwijskiezer.be/v2/secundair/sec_scholen_aanbod.php?detail=${okIdResource.value}`
          : null,
      };
    }),
  };
};
