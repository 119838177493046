import { executeRegistration } from 'ReduxLoop/registerAndConfirmInvitation/register/registerActions';

export class AcceptTerms {
  constructor($ngRedux, $state) {
    'ngInject';

    this.userIcon = require('Img/user.svg');
    this.arrowRightIcon = require('Img/arrow-right.svg');
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.accepted = false;
    this.errors = [];

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      registerError: state.register.registrationFailed,
      invitationRevoked: state.register.invitationRevoked,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  toggleAccept() {
    this.accepted = !this.accepted;
    if (this.accepted) {
      this.isNotAccepted = false;
    }
  }

  acceptConditions() {
    if (!this.accepted) {
      this.isNotAccepted = true;
      // this.errors.push({code: 'not.accepted', message: 'Je moet de algemene voorwaarden accepteren om je account aan te maken.'});
      return;
    }
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      this.$ngRedux.dispatch(executeRegistration());
    } catch (err) {
      console.error('err in accept terms -> execute registration', err);
    } finally {
      this.loading = false;
    }
  }
}
