export const GROUPS_INFO_FETCHED = 'group_info_fetched';
export const SELECT_GROUP = 'select_group';
export const SELECT_NOGROUP = 'select_nogroup';
export const TOGGLE_DELETED_GROUPS = 'toggle_deleted_groups';
export const ADD_GROUP = 'add_group';
export const EDIT_GROUP = 'edit_group';
export const END_GROUP = 'end_group';
export const DELETE_GROUP = 'delete_group';
export const REACTIVATE_GROUP = 'reactivate_group';
export const ADD_MEMBER_TO_GROUP = 'add_member_to_group';
export const ADD_MEMBER_TO_GROUP_FAILED = 'add_member_to_group_failed';
export const EDIT_MEMBER_RESPONSIBILITY = 'edit_member_responsibility';
export const END_MEMBER_RESPONSIBILITY = 'end_member_responsibility';
export const DELETE_MEMBER_RESPONSIBILITY = 'delete_member_responsibility';
export const MAKE_GROUP_ADMINISTRATOR = 'make_group_administrator';
export const REMOVE_GROUP_ADMINISTRATOR = 'remove_group_administrator';
export const ADD_CURRICULUM_TO_GROUP = 'add_curriculum_to_group';
export const REMOVE_CURRICULUM_FROM_GROUP = 'remove_curriculum_from_group';
export const GROUP_ACTION_FAILED = 'group_action_failed';
export const APPLY_FILTER = 'apply_filter';

export const groupsInfoFetchedAction = (groupInfo) => ({
  type: GROUPS_INFO_FETCHED,
  payload: groupInfo,
});

export const selectGroupAction = (groupInfo) => ({
  type: SELECT_GROUP,
  payload: groupInfo,
});

export const selectNoGroupAction = () => ({
  type: SELECT_NOGROUP,
});

export const addGroupAction = (groupInfo) => ({
  type: ADD_GROUP,
  payload: groupInfo,
});

export const addMemberToGroupAction = (membershipInfo) => ({
  type: ADD_MEMBER_TO_GROUP,
  payload: membershipInfo,
});

export const addMemberToGroupFailed = (responsibilityHref, error, targetGroup) => ({
  type: ADD_MEMBER_TO_GROUP_FAILED,
  payload: {
    responsibilityHref,
    error,
    targetGroup,
  },
});

export const editGroupAction = (groupInfo) => ({
  type: EDIT_GROUP,
  payload: groupInfo,
});

export const editMemberResponsibilityAction = (membership) => ({
  type: EDIT_MEMBER_RESPONSIBILITY,
  payload: membership,
});

export const endMemberResponsibilityAction = (membership) => ({
  type: END_MEMBER_RESPONSIBILITY,
  payload: membership,
});

export const deleteMemberResponsibilityAction = (membership) => ({
  type: DELETE_MEMBER_RESPONSIBILITY,
  payload: membership,
});

export const makeGroupAdministratorAction = (membership) => ({
  type: MAKE_GROUP_ADMINISTRATOR,
  payload: membership,
});

export const removeGroupAdminstratorAction = (membership) => ({
  type: REMOVE_GROUP_ADMINISTRATOR,
  payload: membership,
});

export const endGroupAction = (group, endDate) => ({
  type: END_GROUP,
  payload: {
    group,
    endDate,
  },
});

export const deleteGroupAction = (groupInfo) => ({
  type: DELETE_GROUP,
  payload: groupInfo,
});

export const reActivateGroup = (group) => ({
  type: REACTIVATE_GROUP,
  payload: group,
});

export const toggleDeletedGroups = () => ({
  type: TOGGLE_DELETED_GROUPS,
});

export const groupActionFailed = (error, state) => {
  return {
    type: GROUP_ACTION_FAILED,
    payload: {
      error,
      previousState: state,
    },
  };
};

export const applyFilterAction = (filter) => ({
  type: APPLY_FILTER,
  payload: filter,
});
