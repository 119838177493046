import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { sriClient, cachedSriClient } from 'ReduxLoop/utils/apiConfig';
import {
  REFERENCE_FRAME_THEMES,
  REFERENCE_FRAME_SUBJECTS,
  ANDERE_FUNCTION,
} from 'ReduxLoop/constants';

const Batch = require('@kathondvla/sri-client/batch');

const getThemeCategories = async () => {
  const themes = await cachedSriClient.getAll('/content', {
    root: '/content/dfa63c53-a63c-4f61-90bd-6ab13644c538',
  });

  const themeMap = new Map();

  themes.forEach((theme) => {
    themeMap[theme.$$meta.permalink] = theme;
  });

  return REFERENCE_FRAME_THEMES.map((themeHref) => {
    const themeContent = themeMap[themeHref];

    return {
      ...themeContent,
      href: themeContent.$$meta.permalink,
      checked: true,
    };
  });
};

const getNamedSets = async () => {
  const namedSets = await sriClient.getAll('/namedsets');
  const positions = namedSets
    .filter((n) => n.tags.includes('doelgroepen') /* && n.selectors */)
    .map((n) => {
      n.href = n.$$meta.permalink;
      n.checked = true;
      return n;
    });

  positions.sort((p1, p2) => {
    if (![p1.href, p2.href].includes(ANDERE_FUNCTION)) {
      return p1.name.localeCompare(p2.name);
    }

    return p1.href === ANDERE_FUNCTION ? 1 : -1;
  });

  const educationLevels = namedSets
    .filter((n) => n.tags.includes('mainstructure_outype_ext_comm') && n.selectors)
    .map((n) => {
      n.href = n.$$meta.permalink;
      n.checked = true;
      return n;
    });

  educationLevels.sort((el1, el2) => el1.name.localeCompare(el2.name));

  return [positions, educationLevels];
};

const getSubjects = async () => {
  const [
    sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade,
    sectionCurrVakkenEnLeerplannenModerniseringSo,
  ] = await Promise.all([
    sriClient.getAll('/content', {
      root: REFERENCE_FRAME_SUBJECTS.sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade,
      limit: 6000,
    }),
    sriClient.getAll('/content', {
      root: REFERENCE_FRAME_SUBJECTS.sectionCurrVakkenEnLeerplannenModerniseringSo,
      limit: 6000,
    }),
  ]);

  const referenceFrameSubjects = [
    ...sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade,
    ...sectionCurrVakkenEnLeerplannenModerniseringSo,
  ];

  const subjectsMap = {};

  referenceFrameSubjects.forEach((resource) => {
    subjectsMap[resource.$$meta.permalink] = resource;
  });

  return subjectsMap;
};

export const getUnsubscribeReasons = async () => {
  return sriClient.getAll('/newsletter/unsubscriptions/reasons', { limit: 500 });
};

export const getNewsletterOptions = async () => {
  const themes = await getThemeCategories();
  const [positions, educationLevels] = await getNamedSets();
  const subjects = await getSubjects();
  const unsubscribeReasons = await getUnsubscribeReasons();

  return {
    themes,
    positions,
    educationLevels,
    subjects,
    unsubscribeReasons,
  };
};

// stored on newsletter preferences api
export const getUserNewsletterPreferences = async (state) => {
  const user = state.user.$$meta.permalink;

  return sriClient.getAll('/newsletter/preferences', {
    person: user,
  });
};

let currentPromise = null;
export const debounceNewsletterChanges = () => {
  if (currentPromise) {
    currentPromise.reject('CONSOLE.LOGENTRIES_IGNORE');
  }
  return new Promise((resolve, reject) => {
    const thisKey = generateUUID();
    currentPromise = {
      key: thisKey,
      reject,
    };
    setTimeout(() => {
      if (currentPromise.key === thisKey) {
        resolve();
        currentPromise = null;
      }
    }, 2500);
  });
};

export const persistNewsletterChanges = async (newslettersPreferences) => {
  return sriClient.put(
    `/newsletter/preferences/${newslettersPreferences.key}`,
    newslettersPreferences
  );
};

export const persistNewsletterSubscriptions = async (newslettersPreferences) => {
  const batch = new Batch(sriClient);
  newslettersPreferences.forEach((preferences) => {
    if (preferences) {
      batch.put(preferences.href, preferences);
    }
  });
  return batch.send('/newsletter/preferences/batch', sriClient);
};

export const persistNewsletterUnsubscription = async (newsletterPreferences) => {
  const batch = new Batch(sriClient);
  newsletterPreferences.forEach((preferences) => batch.put(preferences.href, preferences));
  return batch.send('/newsletter/batch', sriClient);
};

export const persistNewsletterUnsubscriptionReasons = async (unsubscriptionReasons) => {
  const batch = new Batch(sriClient);
  batch.put(`/newsletter/unsubscriptions/${unsubscriptionReasons.key}`, unsubscriptionReasons);
  return batch.send('/newsletter/batch', sriClient);
};
