module.exports = function ($provide, settings) {
  if (settings.googlAnalytics.enabled) {
    (function (i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    ga('create', settings.googlAnalytics.token, 'auto');
  }

  // If we want to inject other modules services during the .config phase, we need to inject them manually.
  // The injector needs to know the dependencies ( 'ng' is for things like $log and $http )
  // see http://stackoverflow.com/questions/19719729/angularjs-injecting-factory-from-another-module-into-a-provider
  // and also http://stackoverflow.com/a/21536845

  /* $provide.decorator('$exceptionHandler', ['$delegate', function ($delegate) {
    return function (exception, cause) {
      if (console) {console.error(exception)};
      $delegate(exception, cause);
    };
  }]); */
};
