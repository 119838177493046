import { isAfter, getNow } from '@kathondvla/sri-client/date-utils';
import { filterValueChanged } from 'ReduxLoop/ouManager/team/teamActions';

import * as schoolManagerActions from 'ReduxLoop/ouManager/schoolManagerActions';
import * as notificationsActions from 'ReduxLoop/notifications/notificationActions';
import { fetchPositions } from 'ReduxLoop/user/userActions';
import { getDefaultTab, getOuType, POSSIBLE_MANAGEMENT_TABS } from 'ReduxLoop/appConfig';

require('./schoolManager.scss');

export class SchoolManager {
  constructor($state, $stateParams, $ngRedux, settings) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.appVersion = this.settings.projectVersion;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ou: state.vm.schoolManager.currentOU,
      user: state.vm.user,
      tabs: state.vm.schoolManagementTabs,
      notificationList: state.vm.notifications,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  navigateUser() {
    const ouType =
      this.$stateParams.type === 'instelling' ? this.ou.type : getOuType(this.$stateParams.type);
    const mgmts = this.user.manages[ouType].filter(
      (mgmt) => mgmt.ouKey === this.$stateParams.schoolId
    );
    if (mgmts.length > 0 && isAfter(mgmts[0].startDate, getNow())) {
      this.$state.go('schoolSelecteer', { type: this.$stateParams.type });
    }
    if (!this.user.isSuperAdmin) {
      if (mgmts.length === 0) {
        this.$state.go('home');
      } else if (mgmts[0].noAccessYet) {
        this.$state.go('schoolSelecteer', { type: this.$stateParams.type });
      } else if (this.$state.$current.name === 'school') {
        const defaultTab = getDefaultTab(ouType, mgmts[0].role);
        this.$ngRedux.dispatch(schoolManagerActions.selectTab(defaultTab.state));
        this.$state.go(defaultTab.state, {
          schoolId: this.$stateParams.schoolId,
          type: this.$stateParams.type,
        });
      }
    } else if (this.$state.$current.name === 'school') {
      this.$state.go('school.team', {
        schoolId: this.$stateParams.schoolId,
        type: this.$stateParams.type,
      });
    }
    if (this.$stateParams.filter) {
      this.$ngRedux.dispatch(filterValueChanged(this.$stateParams.filter));
    }
  }

  $onInit() {
    if (this.user && this.user.manages) {
      this.navigateUser();
    }
    this.$ngRedux
      .dispatch(schoolManagerActions.initialiseSchoolManager(this.$stateParams.schoolId))
      .then(() => {
        this.navigateUser();
      });

    this.$ngRedux.dispatch(fetchPositions());

    const selectedTab = Object.values(POSSIBLE_MANAGEMENT_TABS).find((tab) =>
      this.$state.current.name.startsWith(tab.state)
    );
    if (selectedTab) {
      this.$ngRedux.dispatch(schoolManagerActions.selectTab(selectedTab.state));
    }
  }

  onClickTab(ev, selectedTab) {
    this.$ngRedux.dispatch(schoolManagerActions.selectTab(selectedTab.state));
    this.$state.go(selectedTab.state, selectedTab.data);
  }

  goToHome() {
    this.$state.go('home');
  }

  footerHelpClicked() {
    window.open(
      'https://drive.google.com/file/d/16MqanYSb0-NIg5xfsrZj5QviEZm1nd2I/view?usp=sharing',
      '_blank'
    );
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(notificationsActions.removeNotification(notificationKey));
  }
}
