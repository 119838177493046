import { loop, Cmd } from 'redux-loop';
import { Map, fromJS } from 'immutable';
import {
  getNow,
  isBefore,
  isBeforeOrEqual,
  isAfter,
  getPreviousDay,
  getNextDay,
  getActiveResources,
  printDate,
} from '@kathondvla/sri-client/date-utils';
import { arrayToMap, getCurrentUserRole } from 'ReduxLoop/index';
import * as COMMANDS from 'ReduxLoop/utils';
import * as SCHOOL_MANAGER_ACTIONS from 'ReduxLoop/ouManager/schoolManagerActions';
import * as NOTIFICATION_ACTIONS from 'ReduxLoop/notifications/notificationActions';
import * as RESOURCE_FACTORY from 'ReduxLoop/utils/apiResourceFactory';
import {
  SUPER_ADMIN,
  MANAGER,
  DELEGATED_ADMIN,
  CONTACTPERSOON,
  BESTUURDER,
  STRATEGIC_PURCHASER,
  VOORZITTER,
} from 'ReduxLoop/constants';
import { mapToArray } from 'ReduxLoop/viewModel';
import { OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import { createResponsibility } from 'ReduxLoop/utils/apiResourceFactory';
import {
  APPROVE_REQUEST,
  APPROVE_REQUEST_FAILED,
  APPROVE_REQUEST_SUCCEEDED,
  DECLINE_REQUEST,
} from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import { getManagingResponsibilityOfCurrentOrganisationalUnit } from 'ReduxLoop/user/userModel';
import * as TEAM_COMMANDS from './teamDataAccess';
import {
  addSuccessNotification,
  addErrorNotification,
  addWarningNotification,
} from '../../notifications/notificationActions';
import * as ACTIONS from './teamActions';

const inititalState = {
  loadingTeam: true,
  filterValue: null,
  possiblePositions: [],
  persons: Map(),
  responsibilities: Map(),
  invitations: Map(),
  undeliveredInvitations: [],
  emailJobs: {},
  requests: Map(),
};

export const teamStateReducer = (state = inititalState, action, rootState) => {
  switch (action.type) {
    case SCHOOL_MANAGER_ACTIONS.SELECT_ORGANISATIONAL_UNIT:
      return inititalState;

    case SCHOOL_MANAGER_ACTIONS.CURRENT_OU_FETCHED: {
      if (
        action.payload.type === 'SCHOOL' ||
        action.payload.type === 'BOARDING' ||
        action.payload.type === 'SCHOOLCOMMUNITY'
      ) {
        return state;
      }
      const currentUserRole = getCurrentUserRole(rootState, action.payload);
      const isAllowedToSeeTeam =
        currentUserRole === MANAGER ||
        currentUserRole === DELEGATED_ADMIN ||
        currentUserRole === SUPER_ADMIN ||
        currentUserRole === STRATEGIC_PURCHASER;
      //! rootState.vm.user.manages[action.payload.type].some(management => management.ouKey === action.payload.key && (management.role === 'GROUP_MANAGER' || management.role === 'DELEGATED_PEDAGOGIC'));
      return loop(
        { ...state, loadingTeam: true },
        Cmd.run(TEAM_COMMANDS.getTeamInfo, {
          args: [action.payload.$$meta.permalink, isAllowedToSeeTeam],
          successActionCreator: ACTIONS.teamInfoFetched,
        })
      );
    }

    case ACTIONS.TEAM_INFO_FETCHED:
      return loop(
        {
          ...state,
          loadingTeam: false,
          persons: fromJS(arrayToMap(action.payload.persons)),
          responsibilities: fromJS(arrayToMap(action.payload.responsibilities)),
          invitations: Map(arrayToMap(action.payload.invitations)),
          requests: Map(arrayToMap(action.payload.requests)),
        },
        Cmd.list([
          Cmd.run(TEAM_COMMANDS.getPossiblePositionsForOu, {
            args: [rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink],
            successActionCreator: ACTIONS.possiblePositionsFetched,
          }),
          // Cmd.run(TEAM_COMMANDS.getUndeliveredInvitations, {
          //   args: [action.payload.invitations],
          //   successActionCreator: ACTIONS.invitationDeliveryInfoFetched
          // })
          Cmd.run(TEAM_COMMANDS.getEmailJobs, {
            args: [action.payload.invitations],
            successActionCreator: ACTIONS.emailJobsFetched,
          }),
        ])
      );

    case ACTIONS.PENDING_RESPONSIBILITIES_FETCHED: {
      return {
        ...state,
        invitations: Map(arrayToMap(action.payload.filter((resp) => resp.type === 'INVITATION'))),
        requests: Map(arrayToMap(action.payload.filter((resp) => resp.type === 'REQUEST'))),
      };
    }

    case ACTIONS.INVITATION_DELIVERY_INFO_FETCHED: {
      return { ...state, undeliveredInvitations: action.payload };
    }

    case ACTIONS.FETCH_EMAIL_JOBS: {
      return loop(
        state,
        Cmd.run(TEAM_COMMANDS.getEmailJobs, {
          args: [action.payload],
          successActionCreator: ACTIONS.emailJobsFetched,
        })
      );
    }

    case ACTIONS.EMAIL_JOBS_FETCHED: {
      return { ...state, emailJobs: Object.assign(state.emailJobs, action.payload) };
    }

    case ACTIONS.POSSIBLE_POSITIONS_FETCHED:
      return { ...state, possiblePositions: action.payload };

    case ACTIONS.SEND_TEAM_INVITATION: {
      const invitations = action.payload.invitees.map((invitee) => {
        const invitation = RESOURCE_FACTORY.createInvitation(
          rootState.userState.user.$$meta.permalink,
          invitee,
          rootState.schoolManager.currentOrganisationalUnit,
          action.payload.position,
          action.payload.startDate
        );
        if (rootState.schoolManager.vosEquivalent) {
          // TODO temporary solution for Nashcling still looking at VOS
          invitation.vosOrganisationalUnit = { href: rootState.schoolManager.vosEquivalent };
        }
        const ouTypeConfigKey = OU_TYPE_CONFIG[
          rootState.schoolManager.currentOrganisationalUnit.type
        ]
          ? rootState.schoolManager.currentOrganisationalUnit.type
          : Object.keys(OU_TYPE_CONFIG).filter(
              (configKey) =>
                OU_TYPE_CONFIG[configKey].types &&
                OU_TYPE_CONFIG[configKey].types.some(
                  (type) => type === rootState.schoolManager.currentOrganisationalUnit.type
                )
            )[0];
        const ouMgmts = rootState.vm.user.manages[ouTypeConfigKey];
        const mgmt = ouMgmts.filter(
          (ouMgmt) => ouMgmt.ouKey === rootState.schoolManager.currentOrganisationalUnit.key
        )[0];
        if (mgmt) {
          let fromResponsibility = rootState.userState.responsibilities.sam.get(mgmt.permalink);
          if (!fromResponsibility) {
            [fromResponsibility] = rootState.userState.responsibilities.vos.filter(
              (resp) => resp.$$meta.permalink === mgmt.permalink
            );
          }
          const position = rootState.userState.positions.get(fromResponsibility.position.href);
          invitation.fromRoleDescription = `${position.name} van ${mgmt.name}`;
        } else if (rootState.userState.isSuperAdmin) {
          invitation.fromRoleDescription = 'medewerker van Katholiek Onderwijs Vlaanderen';
        }
        return invitation;
      });
      return loop(
        { ...state, invitations: state.invitations.concat(arrayToMap(invitations)) },
        Cmd.run(COMMANDS.storeResourcesConcurent, {
          args: [invitations, 4],
          successActionCreator: () => ACTIONS.sendTeamInvitationSucceeded(invitations),
          failActionCreator: (error) => ACTIONS.sendInvitationFailed(error, invitations),
        })
      );
    }

    case ACTIONS.SEND_TEAM_INVITATION_SUCCEEDED: {
      const successMsg = `De uitnodiging${
        action.payload.length > 1 ? 'en zijn' : ' is'
      } uitgestuurd.`;
      return loop(
        state,
        Cmd.list([
          Cmd.action(ACTIONS.fetchEmailJobs(action.payload)),
          Cmd.action(addSuccessNotification(successMsg)),
        ])
      );
    }

    case ACTIONS.FILTER_VALUE_CHANGED: {
      return { ...state, filterValue: action.payload };
    }

    case ACTIONS.SEND_REMINDER: {
      const invitation = state.invitations.get(action.payload.invitationHref);
      const newInvitation = { ...invitation, expiresOn: RESOURCE_FACTORY.getExpirationDate() };
      return loop(
        state,
        Cmd.run(COMMANDS.storeResource, {
          args: [newInvitation],
          successActionCreator: () => ACTIONS.reminderSent(newInvitation),
        })
      );
    }

    case ACTIONS.REMINDER_SENT: {
      const addSuccess = NOTIFICATION_ACTIONS.addSuccessNotification(
        `Er is een herinneringsmail gestuurd naar ${action.payload.to}`
      );
      const fetchEmailJobs = ACTIONS.fetchEmailJobs([action.payload]);
      return loop(state, Cmd.list([Cmd.action(addSuccess), Cmd.action(fetchEmailJobs)]));
    }

    case ACTIONS.CANCEL_TEAM_INVITATION: {
      const invitation = state.invitations.get(action.payload.invitationHref);
      return loop(
        { ...state, invitations: state.invitations.delete(invitation.$$meta.permalink) },
        Cmd.run(COMMANDS.removeResource, {
          args: [invitation.$$meta.permalink],
          failActionCreator: (error) => ACTIONS.cancelInvitationFailed(error, invitation),
        })
      );
    }

    case ACTIONS.SEND_INVITATION_FAILED: {
      console.log('send invitation went wrong', action.payload);
      const newInvitations = {};
      action.payload.error.forEach((err) => {
        const invitation = state.invitations.get(err.resource.$$meta.permalink);
        newInvitations[err.resource.$$meta.permalink] = {
          ...invitation,
          error: err.error.body,
        };
      });
      const isSecurityError = !action.payload.error.some((err) => err.error.status !== 403);
      if (isSecurityError) {
        const managingResp = getManagingResponsibilityOfCurrentOrganisationalUnit(rootState);
        if (managingResp) {
          const tenMinutesAgo = new Date(new Date() - 10 * 60000);
          if (managingResp.$$meta.created > tenMinutesAgo.toISOString()) {
            return loop(
              { ...state, invitations: state.invitations.concat(newInvitations) },
              Cmd.action(
                NOTIFICATION_ACTIONS.addErrorNotification(
                  'De toegangsrechten worden nog gesynchroniseerd waardoor jouw toegangsrechten nog onvolledig zijn. Probeer het binnen 10 minuten nog eens.'
                )
              )
            );
          }
        }
        return loop(
          { ...state, invitations: state.invitations.concat(newInvitations) },
          Cmd.action(
            NOTIFICATION_ACTIONS.addErrorNotification(
              'Je hebt geen toegangsrechten om uitnodigingen te versturen.'
            )
          )
        );
      }

      return loop(
        { ...state, invitations: state.invitations.concat(newInvitations) },
        Cmd.action(
          NOTIFICATION_ACTIONS.addErrorNotification(
            'Er is een onverwachte fout opgetreden bij het sturen van de uitnodigingen.'
          )
        )
      );
    }

    case ACTIONS.DELEGATE_ROLE: {
      let responsibility = RESOURCE_FACTORY.createResponsibility(
        rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink,
        action.payload.member.permalink,
        action.payload.role,
        getNow(),
        action.payload.member.endDate
      );
      const sameRespEndingOnStart = state.responsibilities.filter(
        (resp) =>
          resp.getIn(['person', 'href']) === responsibility.person.href &&
          resp.getIn(['position', 'href']) === responsibility.position.href &&
          resp.get('endDate') === responsibility.startDate
      );
      if (sameRespEndingOnStart.size > 0) {
        responsibility = sameRespEndingOnStart.valueSeq().get(0).toJS();
        responsibility.endDate = undefined;
      }
      let notificationMsg;
      if (action.payload.roleDescription) {
        notificationMsg = `Jouw ${action.payload.roleDescription} zijn nu gedelgeerd aan ${
          action.payload.member.familiarName
        }. ${action.payload.member.gender === 'FEMALE' ? 'Zij' : 'Hij'} beschikt nu over ${
          action.payload.member.gender === 'FEMALE' ? 'haar' : 'zijn'
        } nieuwe toegangsrechten.`;
      } else {
        const position = rootState.userState.positions.get(responsibility.position.href);
        notificationMsg = `${action.payload.member.familiarName} is geregistreerd als ${position.name}`;
      }
      return loop(
        {
          ...state,
          responsibilities: state.responsibilities.set(
            responsibility.$$meta.permalink,
            fromJS(responsibility)
          ),
        },
        Cmd.run(COMMANDS.storeResource, {
          args: [responsibility],
          successActionCreator: () => NOTIFICATION_ACTIONS.addSuccessNotification(notificationMsg),
          failActionCreator: (error) => ACTIONS.roleDelegationFailed(error, responsibility),
        })
      );
    }

    case ACTIONS.ROLE_DELEGATION_FAILED: {
      let message = 'Er is een onverwachte fout opgetreden bij het delegeren van rechten.';
      if (action.payload.error.status === 403) {
        message = `Je kan geen rechten delegeren. Dit moet expleciet door de leidinggevende van ${rootState.vm.schoolManager.currentOU.name} gebeuren.`;
      }
      return loop(
        {
          ...state,
          responsibilities: state.responsibilities.delete(
            action.payload.responsibility.$$meta.permalink
          ),
        },
        Cmd.action(addErrorNotification(message))
      );
    }

    case ACTIONS.ROLE_REVOKATION_FAILED: {
      let message = 'Er is een onverwachte fout opgetreden bij het intrekken van rechten.';
      if (action.payload.error.status === 403) {
        message = `Je kan geen gedelegeerde rechten intrekken. Dit moet expleciet door de leidinggevende van ${rootState.vm.schoolManager.currentOU.name} gebeuren.`;
      }
      const responsibility = { ...action.payload.responsibility, endDate: undefined };
      return loop(
        {
          ...state,
          responsibilities: state.responsibilities.set(
            responsibility.$$meta.permalink,
            fromJS(responsibility)
          ),
        },
        Cmd.action(addErrorNotification(message))
      );
    }

    case ACTIONS.REVOKE_ROLE: {
      let responsibility = state.responsibilities.get(action.payload.member.responsibilityHref);
      // In each governing institution there has to be at least one contact person who is also bestuurder
      if (
        rootState.schoolManager.currentOrganisationalUnit.type === 'GOVERNINGINSTITUTION' &&
        responsibility.getIn(['position', 'href']) === CONTACTPERSOON
      ) {
        const allActiveResponsibilities = getActiveResources(
          mapToArray(state.responsibilities.toJS())
        );
        if (
          !allActiveResponsibilities.some(
            (contactResp) =>
              contactResp.position &&
              contactResp.position.href === CONTACTPERSOON &&
              contactResp.person.href !== action.payload.member.permalink &&
              allActiveResponsibilities.some(
                (bestResp) =>
                  bestResp.position &&
                  bestResp.position.href === BESTUURDER &&
                  bestResp.person.href === contactResp.person.href
              )
          )
        ) {
          const msg =
            `${action.payload.member.familiarName} is de enige bestuurder die contactpersoon is. Er moet altijd minstens één bestuurder contactpersoon zijn.` +
            ` Duid eerst een nieuwe bestuurder als contactpersoon aan voordat je ${action.payload.member.familiarName} verwijdert bij de contactpersonen.`;
          return loop(state, Cmd.action(NOTIFICATION_ACTIONS.addErrorNotification(msg)));
        }
      }
      responsibility = responsibility.set('endDate', getNow());
      return loop(
        {
          ...state,
          responsibilities: state.responsibilities.set(
            action.payload.member.responsibilityHref,
            responsibility
          ),
        },
        Cmd.run(COMMANDS.endResource, {
          args: [responsibility.toJS()],
          failActionCreator: (error) => ACTIONS.roleRevokationFailed(error, responsibility.toJS()),
        })
      );
    }

    case ACTIONS.END_TEAM_RESPONSIBILITY: {
      let responsibility = state.responsibilities.get(action.payload.responsibilityHref);
      responsibility = responsibility.set('endDate', action.payload.endDate);
      // correct corrupt data where the startDate of the resp is before the start of the organisational unit
      const ouStartDate =
        rootState.schoolManager.currentOrganisationalUnit.startDate ||
        rootState.schoolManager.currentOrganisationalUnit.$$startDate;
      if (isBefore(responsibility.get('startDate'), ouStartDate)) {
        console.warn(
          `${responsibility.getIn([
            '$$meta',
            'permalink',
          ])} its startDate is before the startDate of the OU!!! We will correct it...`
        );
        responsibility = responsibility.set('startDate', ouStartDate);
      }
      // In each governing institution there has to be at least one contact person who is also bestuurder
      if (
        rootState.schoolManager.currentOrganisationalUnit.type === 'GOVERNINGINSTITUTION' &&
        responsibility.getIn(['position', 'href']) === BESTUURDER &&
        isBefore(action.payload.endDate, rootState.schoolManager.currentOrganisationalUnit.endDate)
      ) {
        const allActiveResponsibilities = getActiveResources(
          mapToArray(state.responsibilities.toJS())
        );
        if (
          allActiveResponsibilities.some(
            (contactResp) =>
              contactResp.position.href === CONTACTPERSOON &&
              contactResp.person.href === responsibility.getIn(['person', 'href'])
          ) &&
          !allActiveResponsibilities.some(
            (contactResp) =>
              contactResp.position &&
              contactResp.position.href === CONTACTPERSOON &&
              contactResp.person.href !== responsibility.getIn(['person', 'href']) &&
              allActiveResponsibilities.some(
                (bestResp) =>
                  bestResp.position &&
                  bestResp.position.href === BESTUURDER &&
                  bestResp.person.href === contactResp.person.href
              )
          )
        ) {
          const person = state.persons.get(responsibility.getIn(['person', 'href']));
          const msg =
            `${person.get('firstName')} ${person.get(
              'lastName'
            )} is de enige bestuurder die contact persoon is. Er moet altijd minstens één bestuurder contactpersoon zijn.` +
            ` Duid eerst een nieuwe bestuurder als contact persoon aan voordat je ${person.get(
              'firstName'
            )} ${person.get('lastName')} ${
              person.get('sex') === 'MALE' ? 'zijn' : 'haar'
            } verantwoordelijkheid als bestuurder beëindigt.`;
          return loop(state, Cmd.action(NOTIFICATION_ACTIONS.addErrorNotification(msg)));
        }
      } else if (
        ['VERGADERGROEP', 'LEERPLANCOMMISSIE'].some(
          (teamType) => rootState.schoolManager.currentOrganisationalUnit.type === teamType
        ) &&
        responsibility.getIn(['position', 'href']) === VOORZITTER
      ) {
        const allActiveResponsibilities = getActiveResources(
          mapToArray(state.responsibilities.toJS())
        );
        if (
          !allActiveResponsibilities.some(
            (otherPresident) =>
              otherPresident.position.href === VOORZITTER &&
              otherPresident.person.href !== responsibility.getIn(['person', 'href'])
          )
        ) {
          const msg =
            `${action.payload.member.familiarName} is de enige voorzitter. Er moet altijd minstens één voorzitter zijn.` +
            ` Duid eerst een nieuwe voorzitter aan voordat je ${action.payload.member.familiarName} uit het team verwijdert.`;
          return loop(state, Cmd.action(NOTIFICATION_ACTIONS.addErrorNotification(msg)));
        }
      }
      let newResponsibilities = null;
      if (isBeforeOrEqual(action.payload.endDate, getNextDay(responsibility.get('startDate'), 4))) {
        newResponsibilities = state.responsibilities.remove(action.payload.responsibilityHref);
        return loop(
          { ...state, responsibilities: newResponsibilities },
          Cmd.run(COMMANDS.removeResource, {
            args: [responsibility.getIn(['$$meta', 'permalink'])],
            failActionCreator: (error) =>
              ACTIONS.endResponsibilityFailed(error, responsibility.toJS()),
          })
        );
      }
      newResponsibilities = state.responsibilities.set(
        action.payload.responsibilityHref,
        responsibility
      );
      return loop(
        { ...state, responsibilities: newResponsibilities },
        Cmd.run(COMMANDS.endResource, {
          args: [responsibility.toJS()],
          successActionCreator: () => {
            const position = rootState.userState.positions.get(
              responsibility.getIn(['position', 'href'])
            );
            return addSuccessNotification(
              `De rol van ${action.payload.member.familiarName} ${
                position ? `als ${position.name} ` : ''
              }is beëindigd ${
                isAfter(action.payload.endDate, getNow())
                  ? ` vanaf ${printDate(action.payload.endDate)}`
                  : ''
              }.`
            );
          },
          failActionCreator: (error) =>
            ACTIONS.endResponsibilityFailed(error, action.payload.member, responsibility.toJS()),
        })
      );
    }

    case ACTIONS.END_TEAM_MEMBERSHIP: {
      const { member } = action.payload;
      // console.log('member', member)

      const allMemberTeamResps = state.responsibilities.filter(
        (resp) =>
          resp.getIn(['person', 'href']) === member.permalink &&
          isAfter(resp.get('endDate'), getNow())
      );
      const allMemberGroupResps = rootState.groupsTab.responsibilities.filter(
        (resp) =>
          resp.getIn(['person', 'href']) === member.permalink &&
          isAfter(resp.get('endDate'), getNow())
      );
      const memberRespsToEnd = [];
      const memberRespsToDelete = [];
      let newResponsibilities = state.responsibilities;
      let errorMessage = null;
      allMemberTeamResps.valueSeq().forEach((resp) => {
        // In each governing institution there has to be at least one contact person who is also bestuurder
        if (
          rootState.schoolManager.currentOrganisationalUnit.type === 'GOVERNINGINSTITUTION' &&
          resp.getIn(['position', 'href']) === BESTUURDER &&
          isBefore(
            action.payload.endDate,
            rootState.schoolManager.currentOrganisationalUnit.endDate
          )
        ) {
          const allActiveResponsibilities = getActiveResources(
            mapToArray(state.responsibilities.toJS())
          );
          if (
            allActiveResponsibilities.some(
              (contactResp) =>
                contactResp.position &&
                contactResp.position.href === CONTACTPERSOON &&
                contactResp.person.href === resp.getIn(['person', 'href'])
            ) &&
            !allActiveResponsibilities.some(
              (contactResp) =>
                contactResp.position.href === CONTACTPERSOON &&
                contactResp.person.href !== resp.getIn(['person', 'href']) &&
                allActiveResponsibilities.some(
                  (bestResp) =>
                    bestResp.position.href === BESTUURDER &&
                    bestResp.person.href === contactResp.person.href
                )
            )
          ) {
            errorMessage =
              `${action.payload.member.familiarName} is de enige bestuurder die contact persoon is. Er moet altijd minstens één bestuurder contactpersoon zijn.` +
              ` Duid eerst een nieuwe bestuurder als contact persoon aan voordat je ${action.payload.member.familiarName} uit het team verwijdert.`;
          }
        } else if (
          ['VERGADERGROEP', 'LEERPLANCOMMISSIE'].some(
            (teamType) => rootState.schoolManager.currentOrganisationalUnit.type === teamType
          ) &&
          resp.getIn(['position', 'href']) === VOORZITTER
        ) {
          const allActiveResponsibilities = getActiveResources(
            mapToArray(state.responsibilities.toJS())
          );
          if (
            !allActiveResponsibilities.some(
              (otherPresident) =>
                otherPresident.position.href === VOORZITTER &&
                otherPresident.person.href !== resp.getIn(['person', 'href'])
            )
          ) {
            errorMessage =
              `${action.payload.member.familiarName} is de enige voorzitter. Er moet altijd minstens één voorzitter zijn.` +
              ` Duid eerst een nieuwe voorzitter aan voordat je ${action.payload.member.familiarName} uit het team verwijdert.`;
          }
        }
        const href = resp.getIn(['$$meta', 'permalink']);
        if (isBefore(action.payload.endDate, resp.get('endDate'))) {
          if (isBeforeOrEqual(action.payload.endDate, getNextDay(resp.get('startDate'), 4))) {
            newResponsibilities = newResponsibilities.remove(href);
            memberRespsToDelete.push(href);
          } else {
            let responsibility = resp.set('endDate', action.payload.endDate);
            // correct corrupt data where the startDate of the resp is before the start of the organisational unit
            const ouStartDate =
              rootState.schoolManager.currentOrganisationalUnit.startDate ||
              rootState.schoolManager.currentOrganisationalUnit.$$startDate;
            if (isBefore(resp.get('startDate'), ouStartDate)) {
              console.warn(
                `${resp.getIn([
                  '$$meta',
                  'permalink',
                ])} its startDate is before the startDate of the OU!!! We will correct it...`
              );
              responsibility = responsibility.set('startDate', ouStartDate);
            }
            newResponsibilities = newResponsibilities.set(href, responsibility);
            memberRespsToEnd.push(responsibility.toJS());
          }
        }
        // else if (resp.get('endDate') === action.payload.oldEndDate) {
        //   let responsibility = resp.set('endDate', action.payload.endDate);
        //   // correct corrupt data where the startDate of the resp is before the start of the organisational unit
        //   if (isBefore(resp.get('startDate'), rootState.schoolManager.currentOrganisationalUnit.startDate)) {
        //     console.warn(resp.getIn(['$$meta', 'permalink']) + ' its startDate is before the startDate of the OU!!! We will correct it...');
        //     responsibility = responsibility.set('startDate', rootState.schoolManager.currentOrganisationalUnit.startDate));
        //   }
        //   newResponsibilities = newResponsibilities.set(href, responsibility);
        //   memberRespsToEnd.push(responsibility.toJS());
        // }
      });
      if (errorMessage) {
        return loop(state, Cmd.action(NOTIFICATION_ACTIONS.addErrorNotification(errorMessage)));
      }
      allMemberGroupResps.valueSeq().forEach((resp) => {
        const href = resp.getIn(['$$meta', 'permalink']);
        if (isBefore(action.payload.endDate, resp.get('endDate'))) {
          if (isBeforeOrEqual(action.payload.endDate, getNextDay(resp.get('startDate'), 4))) {
            memberRespsToDelete.push(href);
          } else {
            const responsibility = resp.set('endDate', action.payload.endDate);
            memberRespsToEnd.push(responsibility.toJS());
          }
        } else if (resp.get('endDate') === action.payload.oldEndDate) {
          const responsibility = resp.set('endDate', action.payload.endDate);
          memberRespsToEnd.push(responsibility.toJS());
        }
      });
      if (memberRespsToEnd.length === 0 && memberRespsToDelete.length === 0) {
        return loop(
          { ...state, responsibilities: newResponsibilities },
          Cmd.action(
            addWarningNotification(
              `${action.payload.member.familiarName} is al uit het team verwijderd op ${printDate(
                action.payload.endDate
              )}`
            )
          )
        );
      }
      return loop(
        { ...state, responsibilities: newResponsibilities },
        Cmd.run(COMMANDS.persistResponsibilities, {
          args: [memberRespsToEnd, memberRespsToDelete],
          successActionCreator: () =>
            addSuccessNotification(
              `${action.payload.member.familiarName} is verwijderd uit het team ${
                isAfter(action.payload.endDate, getNow())
                  ? ` vanaf ${printDate(action.payload.endDate)}`
                  : ''
              }.`
            ),
          failActionCreator: (error) =>
            ACTIONS.endTeamMembershipFailed(error, action.payload.member),
        })
      );
    }

    case ACTIONS.END_TEAM_RESPONSIBILITY_FAILED: {
      const currentUserRole = getCurrentUserRole(
        rootState,
        rootState.schoolManager.currentOrganisationalUnit
      );
      const isAllowedToSeeTeam =
        currentUserRole === MANAGER ||
        currentUserRole === DELEGATED_ADMIN ||
        currentUserRole === SUPER_ADMIN ||
        currentUserRole === STRATEGIC_PURCHASER;
      const positionName =
        action.payload.responsibility.position &&
        rootState.userState.positions.get(action.payload.responsibility.position.href).name;
      let errorMessage = `Er is een onverwachte fout opgetreden bij het beëindigen van de rol van ${
        action.payload.member.familiarName
      }${positionName ? ` als ${positionName}` : ''}.`;
      if (action.payload.error && action.payload.error.status === 403) {
        errorMessage = `Je hebt onvoldoende rechten om de rol van ${
          action.payload.member.familiarName
        }${positionName ? ` als ${positionName}` : ''} te beëindigen.`;
      }
      return loop(
        { ...state, loadingTeam: true },
        Cmd.list([
          Cmd.action(addErrorNotification(errorMessage)),
          Cmd.run(TEAM_COMMANDS.getTeamInfo, {
            args: [
              rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink,
              isAllowedToSeeTeam,
            ],
            successActionCreator: ACTIONS.teamInfoFetched,
          }),
        ])
      );
    }

    case ACTIONS.END_TEAM_MEMBERSHIP_FAILED: {
      const currentUserRole = getCurrentUserRole(
        rootState,
        rootState.schoolManager.currentOrganisationalUnit
      );
      const isAllowedToSeeTeam =
        currentUserRole === MANAGER ||
        currentUserRole === DELEGATED_ADMIN ||
        currentUserRole === SUPER_ADMIN ||
        currentUserRole === STRATEGIC_PURCHASER;
      let errorMessage = `Er is een onverwachte fout opgetreden bij het verwijderen van ${action.payload.member.familiarName} uit het team.`;
      const errObj = action.payload.error;
      if (errObj && errObj.body && errObj.body[0] && errObj.body[0]) {
        if (errObj.body[0].status === 403) {
          errorMessage = `Je hebt onvoldoende rechten om ${action.payload.member.familiarName} te verwijderen uit het team.`;
        }
      }
      return loop(
        { ...state, loadingTeam: true },
        Cmd.list([
          Cmd.action(addErrorNotification(errorMessage)),
          Cmd.run(TEAM_COMMANDS.getTeamInfo, {
            args: [
              rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink,
              isAllowedToSeeTeam,
            ],
            successActionCreator: ACTIONS.teamInfoFetched,
          }),
        ])
      );
    }

    case APPROVE_REQUEST: {
      const request = state.requests.get(action.payload.requestPermalink);
      if (!request) {
        console.log('team reducer was not initialised with the relevant OU');
        return state;
      }
      const newResponsibility = fromJS({ ...request, person: request.from });
      const newRequest = {
        ...request,
        decision: 'APPROVED',
        errors: null,
      };
      return {
        ...state,
        responsibilities: state.responsibilities.set(
          action.payload.requestPermalink,
          newResponsibility
        ),
        requests: state.requests.set(action.payload.requestPermalink, newRequest),
      };
    }

    case APPROVE_REQUEST_SUCCEEDED: {
      const request = state.requests.get(action.payload.requestPermalink);
      if (!request) {
        console.log('team reducer was not initialised with the relevant OU');
        return state;
      }
      const newState = {
        ...state,
        requests: state.requests.delete(action.payload.requestPermalink),
      };
      if (request.decision === 'APPROVED') {
        newState.responsibilities = state.responsibilities
          .set(
            action.payload.responsibility.$$meta.permalink,
            fromJS(action.payload.responsibility)
          )
          .delete(action.payload.requestPermalink);
      }
      return newState;
    }

    case APPROVE_REQUEST_FAILED: {
      const request = { ...state.requests.get(action.payload.requestPermalink) };
      if (request.position) {
        request.position = {
          href: request.position.href,
          $$expanded: rootState.userState.positions.get(request.position.href),
        };
      }
      if (!request) {
        console.log('team reducer was not initialised with the relevant OU');
        return state;
      }
      const newRequest =
        action.payload.error && action.payload.error.status === 403
          ? { ...request, decision: null }
          : {
              ...request,
              errors: action.payload.error.body
                ? action.payload.error.body.errors
                : [action.payload.error.status],
            };
      const newState = {
        ...state,
        requests: state.requests.set(action.payload.requestPermalink, newRequest),
      };
      if (request.decision === 'APPROVED') {
        newState.responsibilities = state.responsibilities.delete(action.payload.requestPermalink);
      }
      if (action.payload.error && action.payload.error.status === 403) {
        return loop(
          newState,
          Cmd.action(
            addErrorNotification(
              `Je kan geen aanvragen goedkeuren of afkeuren. Dit moet door de leidinggevenden van ${rootState.vm.schoolManager.currentOU.name} gebeuren.`
            )
          )
        );
      }
      return newState;
    }

    case DECLINE_REQUEST: {
      const request = state.requests.get(action.payload.requestPermalink);
      if (!request) {
        console.log('team reducer was not initialised with the relevant OU');
        return state;
      }
      const newRequest = {
        ...request,
        decision: 'DECLINED',
        errors: null,
      };
      return {
        ...state,
        requests: state.requests.set(action.payload.requestPermalink, newRequest),
      };
    }

    case ACTIONS.CHANGE_ROLE: {
      let responsibility = state.responsibilities.get(action.payload.responsibilityHref);
      const oldPositionHref = responsibility.getIn(['position', 'href']);
      if (
        ['VERGADERGROEP', 'LEERPLANCOMMISSIE'].some(
          (teamType) => rootState.schoolManager.currentOrganisationalUnit.type === teamType
        ) &&
        oldPositionHref === VOORZITTER
      ) {
        const allActiveResponsibilities = getActiveResources(
          mapToArray(state.responsibilities.toJS())
        );
        if (
          !allActiveResponsibilities.some(
            (otherPresident) =>
              otherPresident.position.href === VOORZITTER &&
              otherPresident.person.href !== responsibility.getIn(['person', 'href'])
          )
        ) {
          const msg =
            `${action.payload.member.familiarName} is de enige voorzitter. Er moet altijd minstens één voorzitter zijn.` +
            ` Duid eerst een nieuwe voorzitter aan voordat je de rol van ${action.payload.member.familiarName} wijzigt.`;
          return loop(state, Cmd.action(addErrorNotification(msg)));
        }
      }
      if (!isBefore(responsibility.get('startDate'), getPreviousDay(getNow(), 14))) {
        responsibility = responsibility.setIn(['position', 'href'], action.payload.newPositionHref);
        const newResponsibilities = state.responsibilities.set(
          action.payload.responsibilityHref,
          responsibility
        );
        return loop(
          { ...state, responsibilities: newResponsibilities },
          Cmd.run(COMMANDS.storeResource, {
            args: [responsibility.toJS()],
            failActionCreator: (error) =>
              ACTIONS.changeRoleFailed(error, action.payload.responsibilityHref, oldPositionHref),
          })
        );
      }
      responsibility = responsibility.set('endDate', getNow());
      let newResponsibilities = state.responsibilities.set(
        action.payload.responsibilityHref,
        responsibility
      );
      const newResponsibility = createResponsibility(
        rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink,
        responsibility.getIn(['person', 'href']),
        action.payload.newPositionHref,
        getNow()
      );
      newResponsibilities = newResponsibilities.set(
        newResponsibility.$$meta.permalink,
        fromJS(newResponsibility)
      );
      return loop(
        { ...state, responsibilities: newResponsibilities },
        Cmd.run(COMMANDS.persistResponsibilities, {
          args: [[responsibility.toJS(), newResponsibility]],
          failActionCreator: (error) =>
            ACTIONS.changeRoleFailed(
              error,
              action.payload.responsibilityHref,
              oldPositionHref,
              newResponsibility.$$meta.permalink
            ),
        })
      );
    }

    case ACTIONS.CHANGE_ROLE_FAILED: {
      let responsibility = state.responsibilities.get(action.payload.responsibilityHref);
      responsibility = responsibility.setIn(['position', 'href'], action.payload.oldPositionHref);
      responsibility = responsibility.set('endDate', undefined);
      const newResponsibilities = state.responsibilities.set(
        action.payload.responsibilityHref,
        responsibility
      );
      if (action.payload.newResponsibilityHref) {
        newResponsibilities.delete(action.payload.newResponsibilityHref);
      }
      return loop(
        { ...state, responsibilities: newResponsibilities },
        Cmd.action(
          addErrorNotification('Er is een onverwachte fout opgetreden bij het wijzigen van de rol.')
        )
      );
    }

    default:
      return state;
  }
};
