import { getActiveResources } from '@kathondvla/sri-client/date-utils';
import { KATHOLIEK_ONDERWIJS_VLAANDEREN } from 'ReduxLoop/constants';

export const addWorkgroupInfo = (state, ou) => {
  if (state.workgroup.teamMetaInfo) {
    ou.teamMetaInfo.permalink = state.workgroup.teamMetaInfo.$$meta.permalink;
    ou.teamMetaInfo.isSamenwerkingsVerband =
      state.workgroup.teamMetaInfo.hasOffice365GroupIncludingMemberOUs;
    ou.teamMetaInfo.hasMicrosoftTeamsEnvironment =
      state.workgroup.teamMetaInfo.hasOffice365GroupIncludingMemberOUs ||
      state.workgroup.teamMetaInfo.hasOffice365Group ||
      state.workgroup.teamMetaInfo.hasOffice365GroupIncludingMemberOUsIndirectMembers ||
      state.workgroup.teamMetaInfo.hasOffice365GroupIndirectMembers;
    getActiveResources(Object.values(state.workgroup.memberRelations)).forEach((rel) => {
      const memberOu = { ...state.workgroup.memberOus[rel.from.href] };
      let currentOu = memberOu;
      let fullName = memberOu.$$name;
      while (currentOu) {
        let parent = null;
        // eslint-disable-next-line no-loop-func
        const parentRel = state.workgroup.parentRelations.find(
          (parentRelation) => parentRelation.from.href === currentOu.$$meta.permalink
        );
        if (parentRel) {
          parent = parentRel.to.$$expanded;
          currentOu.parent = { permalink: parent.$$meta.permalink, name: parent.$$name };
          if (parent.$$meta.permalink !== KATHOLIEK_ONDERWIJS_VLAANDEREN) {
            fullName += ` - ${parent.$$name}`;
          }
        } else if (memberOu.key !== currentOu.key) {
          memberOu.root = { permalink: currentOu.$$meta.permalink, name: currentOu.$$name };
        }
        currentOu = parent;
      }
      ou.teamMemberOus.push({
        permalink: memberOu.$$meta.permalink,
        name: memberOu.$$name,
        fullName,
        description: memberOu.description,
        type: memberOu.type,
        // presidents: state.schoolManager.teamMemberOus.presidents
        //   .filter(resp => resp.organisation.href === memberOu.$$meta.permalink)
        //   .map(resp => ({
        //     permalink: resp.person.href,
        //     responsibilityHref: resp.$$meta.permalink,
        //     name: resp.person.$$expanded.firstName + ' ' + resp.person.$$expanded.lastName
        //   }))
        parent: memberOu.parent,
        root: memberOu.root,
      });
    });
  }
};
