import './myDuplicateTeamSuggestion.scss';

class MyDuplicateTeamSuggestion {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  joinTeam() {
    this.$scope.$emit('joinTeam');
  }
}

export const myDuplicateTeamSuggestionComponent = {
  template: require('./myDuplicateTeamSuggestion.html'),
  controllerAs: 'ctrl',
  controller: MyDuplicateTeamSuggestion,
  bindings: {
    organisation: '<',
    type: '@',
  },
};
