import { MissingContactDetailsModal } from './missingContactDetailsModal';

export const myMissingContactDetailsModalComponent = {
  template: require('./missingContactDetailsModal.html'),
  controllerAs: 'ctrl',
  controller: MissingContactDetailsModal,
  bindings: {
    resolve: '<',
    skip: '&',
    close: '&',
  },
};
