import { MyOuTypeSelector } from './myOuTypeSelector';

require('./myOuTypeSelector.scss');

export const myOuTypeSelectorComponent = {
  template: require('./myOuTypeSelector.html'),
  controllerAs: 'ctrl',
  controller: MyOuTypeSelector,
  bindings: {
    sOuTypes: '<',
    sNoTypeOption: '@',
  },
};
