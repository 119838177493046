import { SCHOOLCOMMUNITY } from 'ReduxLoop/constants';

const sriUtils = require('@kathondvla/sri-client/common-utils');

export const createClass = (name, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/${key}`,
    },
    key,
    names: [
      {
        type: 'OFFICIAL',
        value: name,
        startDate,
      },
      {
        type: 'CALL',
        value: name,
        startDate,
      },
    ],
    type: 'CLASS',
    startDate,
    $$displayName: name,
  };
};

export const createSchoolClassRelation = (clazz, ou, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/relations/${key}`,
    },
    key,
    from: {
      href: clazz.$$meta.permalink,
    },
    to: {
      href: ou.$$meta.permalink,
    },
    type: 'IS_PART_OF',
    startDate,
  };
};

export const createCampusClassRelation = (clazz, campusPermalink, startDate, endDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/locations/${key}`,
    },
    key,
    organisationalUnit: {
      href: clazz.$$meta.permalink,
    },
    physicalLocation: {
      href: campusPermalink,
    },
    type: 'LESPLAATS',
    startDate,
    endDate,
  };
};

export const getExpirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 213);
  // date.setSeconds(date.getSeconds() + 20);
  return date.toISOString();
};

export const createInvitation = (invitor, invitee, ou, positionHref, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/responsibilities/pending/${key}`,
      type: 'PENDING_RESPONSIBILITY',
    },
    key,
    type: 'INVITATION',
    from: {
      href: invitor,
    },
    to: invitee,
    organisationalUnit: {
      href: ou.$$meta.permalink,
    },
    position: positionHref ? { href: positionHref } : undefined,
    startDate,
    endDate: ou.endDate || ou.$$endDate,
    expiresOn: getExpirationDate(),
    status: 'PENDING',
  };
};

export const createRequest = (person, ou, positionHref, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/responsibilities/pending/${key}`,
    },
    key,
    type: 'REQUEST',
    from: {
      href: person,
    },
    organisationalUnit: {
      href: [SCHOOLCOMMUNITY, 'SCHOOL', 'BOARDING'].some((ouType) => ouType === ou.types[0])
        ? ou.$$meta.vosPermalink
        : ou.$$meta.permalink,
    },
    position: positionHref ? { href: positionHref } : undefined,
    startDate,
    endDate: ou.endDate || ou.$$endDate,
    expiresOn: getExpirationDate(),
    status: 'PENDING',
  };
};

export const createGroup = (name, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/${key}`,
    },
    key,
    names: [
      {
        type: 'OFFICIAL',
        value: name,
        startDate,
      },
    ],
    type: 'TEACHER_GROUP',
    startDate,
    $$displayName: name,
  };
};

export const createSchoolGroupRelation = (group, ou, startDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/relations/${key}`,
    },
    key,
    from: {
      href: group.$$meta.permalink,
    },
    to: {
      href: ou.$$meta.permalink,
    },
    type: 'IS_PART_OF',
    startDate,
  };
};

export const createResponsibility = (ouHref, personHref, positionHref, startDate, endDate) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/responsibilities/${key}`,
      type: 'RESPONSIBILITY',
    },
    key,
    organisation: {
      href: ouHref,
    },
    position: {
      href: positionHref,
    },
    person: {
      href: personHref,
    },
    startDate,
    endDate,
  };
};

export const createSchoolStudyProgrammeRelation = (
  ouPermalink,
  studyProgrammePermalink,
  startDate
) => {
  const key = sriUtils.generateUUID();
  return {
    $$meta: {
      permalink: `/sam/educationalprogrammedetails/${key}`,
    },
    key,
    organisationalUnit: {
      href: ouPermalink,
    },
    studyProgramme: {
      href: studyProgrammePermalink,
    },
    startDate,
  };
};
