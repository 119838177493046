import { getActiveResources } from '@kathondvla/sri-client/date-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { storeResources, removeResources } from '../../utils';

export function storeClassInfo(resources) {
  return storeResources('/sam/organisationalunits/batch', resources);
}

export const deleteClassInfo = (resources) => {
  return removeResources('/sam/organisationalunits/batch', resources);
};

const getCampuses = async (ou) => {
  let campuses = await sriClient.getAll('/sam/organisationalunits/locations', {
    organisationalUnit: ou.$$meta.permalink,
    expand: 'results.physicalLocation',
  });

  campuses = getActiveResources(campuses);
  return campuses;
};

const getClasses = async (ou, campuses) => {
  const ouClasses = await sriClient.getAll('/sam/organisationalunits/relations', {
    type: 'IS_PART_OF',
    to: ou.$$meta.permalink,
    expand: 'results.from',
  });

  const classes = ouClasses.map((ouClass) => ouClass.from.$$expanded);

  const campusClasses = await sriClient.getAllReferencesTo(
    '/sam/organisationalunits/locations',
    {
      physicalLocationIn: campuses.map((campus) => campus.physicalLocation.href.split('/').pop()),
    },
    'organisationalUnit',
    classes.map((clazz) => clazz.$$meta.permalink),
    {}
  );

  ouClasses.forEach((ouClass) => {
    delete ouClass.from.$$expanded;
  });

  campusClasses.forEach((campusClass) => {
    delete campusClass.organisationalUnit.$$expanded;
  });

  return {
    classes,
    ouClasses,
    campusClasses,
  };
};

export async function fetchClassesInfo(ou) {
  let campuses = [];
  let classesInfo = {
    classes: [],
    ouClasses: [],
    campusClasses: [],
  };
  let okIds = [];

  if (ou) {
    campuses = await getCampuses(ou);
    okIds = await sriClient.getAllReferencesTo(
      '/sam/organisationalunits/locations/externalIdentifiers',
      { type: 'ONDERWIJSKIEZER_ID' },
      'location',
      campuses.map((c) => c.$$meta.permalink)
    );
    classesInfo = await getClasses(ou, campuses);
  }

  return Promise.resolve({
    campuses,
    classesInfo,
    okIds,
  });
}
