import { myChooseRespModalComponent } from 'Js/myComponents/myChooseRespModal';
import { myChangePositionModalComponent } from 'Js/myComponents/myChangePositionModal';
import { mySearchBarComponent } from 'Js/myComponents/mySearchBar';
import { myMemberListComponent } from 'Js/myComponents/myMemberList';
import { myOuTypeSelectorComponent } from 'Js/myComponents/myOuTypeSelector';
import { myPopup } from 'Js/myComponents/myPopup';
import { myTeamMemberOusListComponent } from 'Js/myComponents/myTeamMemberOusList';
import { myDuplicateTeamSuggestionComponent } from 'Js/myComponents/myDuplicateTeamSuggestion';
import { myClassDetailsComponent } from './myClassDetails';
import { myDeleteModalComponent } from './myDeleteModal';
import { myHeaderBarComponent } from './myHeaderBar';
import { myConfirmModal } from './myConfirmModal';
import { myOuListComponent } from './myOuList';
import { myTabContainerHeaderComponent } from './myTabContainerHeader';
import { myConfirmationScreenComponent } from './myConfirmationScreen';
import { myTeamCardsComponent } from './myTeamCards';
import { myDelegationComponent } from './myDelegateRole';
import { MyEmailListComponent } from './myEmailList';

const m = angular.module('myComponents', []);

m.component('myClassDetails', myClassDetailsComponent);
m.component('myDeleteModal', myDeleteModalComponent);
m.component('myChooseRespModal', myChooseRespModalComponent);
m.component('myChangePositionModal', myChangePositionModalComponent);
m.component('myHeaderBar', myHeaderBarComponent);
m.component('myEmailList', MyEmailListComponent);
m.component('myInlineEditor', require('./myInlineEditor'));

m.component('myMemberList', myMemberListComponent);
m.component('myDelegateRole', myDelegationComponent);
m.component('myTextTruncator', require('./myTextTruncator'));

m.component('myTabContainerHeader', myTabContainerHeaderComponent);
m.component('myOuList', myOuListComponent);
m.component('myConfirmationScreen', myConfirmationScreenComponent);
m.component('myConfirmModal', myConfirmModal);
m.component('myTeamCards', myTeamCardsComponent);
m.component('mySearchBar', mySearchBarComponent);
m.component('myNewsletterSubjectList', require('./myNewsletterSubjectList'));

m.component('myOuTypeSelector', myOuTypeSelectorComponent);
m.component('myPopup', myPopup);
m.component('myTeamMemberOusList', myTeamMemberOusListComponent);
m.component('myDuplicateTeamSuggestion', myDuplicateTeamSuggestionComponent);
