const { MyTeamCards } = require('./myTeamCards');

require('./mySchoolTeamCards.scss');

export const myTeamCardsComponent = {
  template: require('./myTeamCards.html'),
  controllerAs: 'ctrl',
  controller: MyTeamCards,
  bindings: {
    sTitle: '@',
    sOus: '<',
    sSize: '@',
    sNotClickable: '<',
  },
};
