import { Cmd, loop } from 'redux-loop';
import { getActiveResources, isBeforeOrEqual, getNow } from '@kathondvla/sri-client/date-utils';
import { settings } from 'Js/config/settings';
import {
  GOVERNINGINSTITUTION,
  LEERPLANCOMMISSIE,
  LEERSTEUNCENTRUM,
  LERARENNETWERK,
  VERGADERGROEP,
} from 'ReduxLoop/constants';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as COMMANDS from './helpdeskCommands';
import * as ACTIONS from './helpdeskActions';

const initialState = {
  searchValue: null,
  nbOfResultsShown: 30,
  ouType: null,
  institutionNumberSearch: false,
  abolishedInstitution: null,
  secondaryInstitution: null,
  searchingForInstitutionNumber: false,
  primarySchools: null,
  secondarySchools: null,
  higherEducationSchools: null,
  cvos: null,
  boardings: null,
  governingInstitutions: null,
  schoolCommunities: null,
  clbs: null,
  leersteuncentra: null,
  others: null,
};

export const getStatePropertyName = (ouType) => {
  switch (ouType) {
    case 'SECONDARY_SCHOOL':
      return 'secondarySchools';
    case 'PRIMARY_SCHOOL':
      return 'primarySchools';
    case 'HIGHER_EDUCATION_SCHOOL':
      return 'higherEducationSchools';
    case GOVERNINGINSTITUTION:
      return 'governingInstitutions';
    case LEERSTEUNCENTRUM:
      return 'leersteuncentra';
    case 'SCHOOLCOMMUNITY':
      return 'schoolCommunities';
    default:
      return `${ouType.toLowerCase()}s`;
  }
};

const getMijn2Type = (ou) => {
  switch (ou.type) {
    case 'ANDERE':
      return 'organisatie';
    case LERARENNETWERK:
      return 'lerarennetwerk';
    case 'CLB':
      return 'clb';
    case LEERSTEUNCENTRUM:
      return 'leersteuncentrum';
    case GOVERNINGINSTITUTION:
      return 'bestuur';
    case VERGADERGROEP:
      return 'team';
    case LEERPLANCOMMISSIE:
      return 'team';
    default:
      return null;
  }
};

const mapVosOuToViewModel = (ou) => {
  const type = ou.$$meta.type === 'ORGANISATION' ? ou.type : ou.$$meta.type;
  const mijn2Type = getMijn2Type(ou);
  const referenceDate = ou.$$startDate > getNow() ? ou.$$startDate : getNow();
  const currentDetail = getActiveResources(ou.details, referenceDate)[0];
  return {
    key: ou.key,
    type,
    name: ou.$$name,
    fullName:
      currentDetail.name !== ou.$$name &&
      (ou.type === LEERPLANCOMMISSIE || ou.type === VERGADERGROEP || ou.type === LERARENNETWERK)
        ? currentDetail.name
        : null,
    description:
      ou.description && ou.description.length > 750
        ? `${ou.description.substring(0, 700)}...`
        : ou.description,
    institutionNumber: ou.institutionNumber,
    addresses: getActiveResources(ou.seatAddresses).map((seat) => seat.address),
    href: mijn2Type
      ? `/#!/${mijn2Type}/${ou.key}/team`
      : `${settings.applications.mijn1}/#/team/${ou.institutionNumber}`,
  };
};

const mapSchoolEntities = (schools, campuses) => {
  return schools.map((school) => {
    const seCampuses = campuses
      .filter((campus) => campus.organisationalUnit.href === school.$$meta.permalink)
      .map((campus) => {
        return {
          ...campus.physicalLocation.$$expanded.address,
          name: campus.name,
        };
      });
    return {
      key: school.key,
      type: school.type,
      name: school.$$displayName,
      addresses: seCampuses,
      href: `/#!/school/${school.key}/team`,
    };
  });
};

const mapSamOus = (ous, mailAddresses, institutionNumbers) => {
  const mappedOus = ous.map((ou) => {
    const addresses = mailAddresses
      .filter((address) => address.organisationalUnit.href === ou.$$meta.permalink)
      .map((address) => {
        return {
          ...address.value,
        };
      });
    const institutionNumber = institutionNumbers
      .filter((instNr) => instNr.organisationalUnit.href === ou.$$meta.permalink)
      .map((instNr) => instNr.value)[0];
    return {
      key: ou.key,
      type: ou.type,
      name: ou.$$displayName,
      institutionNumber,
      addresses,
      href: `/#!/${getMijn2Type(ou)}/${ou.key}/team`,
    };
  });
  return {
    clbs: mappedOus.filter((ou) => ou.type === 'CLB'),
    governingInstitutions: mappedOus.filter((ou) => ou.type === GOVERNINGINSTITUTION),
    leersteuncentra: mappedOus.filter((ou) => ou.type === LEERSTEUNCENTRUM),
  };
};

export const helpdeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_VALUE_CHANGED: {
      const newSearchValue = action.payload ? action.payload.trim() : action.payload;
      const newState = {
        ...state,
        searchValue: newSearchValue,
        nbOfResultsShown: initialState.nbOfResultsShown,
        abolishedInstitution: null,
        secondaryInstitution: null,
        searchingForInstitutionNumber: false,
        institutionNumberSearch: false,
      };
      if (newSearchValue && newSearchValue.match(/\d{6}/)) {
        console.log('een instellingsnummer!');
        newState.institutionNumberSearch = true;
        newState.searchingForInstitutionNumber = true;
        return loop(
          newState,
          Cmd.run(COMMANDS.getOuByInstitutionNumber, {
            args: [newSearchValue, state],
            successActionCreator: ACTIONS.ouFetchedByInstitutionNumber,
          })
        );
      }
      return newState;
    }

    case ACTION_TYPES.SHOW_MORE_RESULTS: {
      return { ...state, nbOfResultsShown: state.nbOfResultsShown + 30 };
    }

    case ACTION_TYPES.OU_TYPE_SEARCH_FILTER_CHANGED: {
      const newOuType = action.payload;
      const newState = { ...state, ouType: newOuType };
      if (state[getStatePropertyName(newOuType)]) {
        return newState;
      }
      if (newOuType === 'SECONDARY_SCHOOL') {
        return loop(
          newState,
          Cmd.run(COMMANDS.getAllSecondarySchools, {
            successActionCreator: ACTIONS.allSecondarySchoolsFetched,
          })
        );
      }
      if (newOuType === 'PRIMARY_SCHOOL' || newOuType === 'HIGHER_EDUCATION_SCHOOL') {
        return loop(
          newState,
          Cmd.run(COMMANDS.getSchoolsFromVos, {
            args: [newOuType],
            successActionCreator: (ous) => ACTIONS.allVosOusOfTypeFetched(ous, newOuType),
          })
        );
      }
      if (
        newOuType === 'CLB' ||
        newOuType === GOVERNINGINSTITUTION ||
        newOuType === LEERSTEUNCENTRUM
      ) {
        return loop(
          newState,
          Cmd.run(COMMANDS.getAllSamOus, {
            successActionCreator: ACTIONS.allSamOusFetched,
          })
        );
      }
      return loop(
        newState,
        Cmd.run(COMMANDS.getOusFromVos, {
          args: [newOuType],
          successActionCreator: (ous) => ACTIONS.allVosOusOfTypeFetched(ous, newOuType),
        })
      );
    }

    case ACTION_TYPES.ALL_SECONDARY_SCHOOLS_FETCHED: {
      return {
        ...state,
        secondarySchools: mapSchoolEntities(action.payload.schools, action.payload.campuses),
      };
    }

    case ACTION_TYPES.ALL_SAM_OUS_FETCHED: {
      const mappedOus = mapSamOus(
        action.payload.ous,
        action.payload.mailAddresses,
        action.payload.institutionNumbers
      );
      return {
        ...state,
        clbs: mappedOus.clbs,
        governingInstitutions: mappedOus.governingInstitutions,
        leersteuncentra: mappedOus.leersteuncentra,
      };
    }

    case ACTION_TYPES.ALL_VOS_OUS_OF_TYPE_FETCHED: {
      const propertyName = getStatePropertyName(action.payload.ouType);
      const ous = action.payload.ous.map(mapVosOuToViewModel);
      const newState = { ...state };
      newState[propertyName] = ous;
      return newState;
    }

    case ACTION_TYPES.OU_FETCHED_BY_INSTITUTION_NUMBER: {
      if (!action.payload[0]) {
        return { ...state, searchingForInstitutionNumber: false };
      }
      const institutionNumber = action.payload[0].value;
      if (institutionNumber !== state.searchValue) {
        return state;
      }
      const ou = action.payload[0].organisationalUnit.$$expanded;
      if (isBeforeOrEqual(ou.endDate, getNow())) {
        console.warn('The user is searching for an abolished institution', ou);
        return {
          ...state,
          searchingForInstitutionNumber: false,
          abolishedInstitution: {
            key: ou.key,
            type: ou.type,
            name: ou.$$displayName,
            institutionNumber,
            endDate: ou.endDate,
            addresses: [],
          },
        };
      }
      if (ou.type === 'SCHOOL') {
        return loop(
          state,
          Cmd.run(COMMANDS.getAllMissingSchools, {
            args: [ou, institutionNumber, state],
            successActionCreator: (result) =>
              ACTIONS.missingSchoolsFetched(result, institutionNumber),
          })
        );
      }
      const ouType = ou.type;
      return loop(
        { ...state, searchingForInstitutionNumber: false },
        Cmd.action(ACTIONS.ouTypeSearchFilterChanged(ouType))
      );
    }

    case ACTION_TYPES.MISSING_SCHOOLS_FETCHED: {
      const newState = {
        ...state,
        searchingForInstitutionNumber: false,
        primarySchools:
          state.primarySchools ||
          (action.payload.primarySchools
            ? action.payload.primarySchools.map(mapVosOuToViewModel)
            : null),
        secondarySchools:
          state.secondarySchools ||
          (action.payload.secondarySchoolInfo
            ? mapSchoolEntities(
                action.payload.secondarySchoolInfo.schools,
                action.payload.secondarySchoolInfo.campuses
              )
            : null),
        higherEducationSchools:
          state.higherEducationSchools ||
          (action.payload.higherEducationSchools
            ? action.payload.higherEducationSchools.map(mapVosOuToViewModel)
            : null),
        cvos:
          state.cvos || (action.payload.cvos ? action.payload.cvos.map(mapVosOuToViewModel) : null),
      };
      if (action.payload.institutionNumber !== state.searchValue || !action.payload.result) {
        return newState;
      }
      newState.ouType = action.payload.educationLevel;
      if (action.payload.educationLevel === 'SECONDARY_SCHOOL') {
        newState.secondaryInstitution = {
          key: action.payload.result.key,
          type: 'SECONDARY_OFFICIAL_SCHOOL',
          name: action.payload.result.$$name,
          institutionNumber: action.payload.institutionNumber,
          addresses: getActiveResources(action.payload.result.seatAddresses).map(
            (seat) => seat.address
          ),
          relatedSchoolEntities: action.payload.relatedSchoolEntities,
          href: `${settings.applications.mijn1}/#/team/${action.payload.institutionNumber}`,
        };
      }
      return newState;
    }

    default:
      return state;
  }
};
