import * as ACTION_TYPES from 'ReduxLoop/constants/actionTypes';

export const setInvitations = (type, invitationKeys, emailAddress, startDate) => ({
  type: ACTION_TYPES.SET_INVITATIONS,
  payload: {
    type,
    invitationKeys,
    emailAddress,
    startDate,
  },
});

export const fetchPositionAndOus = (positionKey, ouKeys) => ({
  type: ACTION_TYPES.FETCH_POSITION_AND_OUS,
  payload: {
    positionKey,
    ouKeys,
  },
});

export const positionAndOusFetched = (payload) => ({
  type: ACTION_TYPES.POSITION_AND_OUS_FETCHED,
  payload,
});

export const fetchInvitationCampusses = (ous) => ({
  type: ACTION_TYPES.FETCH_INVITATION_CAMPUSSES,
  payload: ous,
});

export const invitationCampussesFetched = (campusses) => ({
  type: ACTION_TYPES.INVITATION_CAMPUSSES_FETCHED,
  payload: campusses,
});

export const toggleInvitationOuSelected = (ouKey) => ({
  type: ACTION_TYPES.TOGGLE_INVITATION_OU_SELECTED,
  payload: ouKey,
});

export const fetchInvitedUser = () => ({
  type: ACTION_TYPES.FETCH_INVITED_USER,
});

export const invitedUserFetched = (user) => ({
  type: ACTION_TYPES.INVITED_USER_FETCHED,
  payload: user,
});

export const contactHelpDesk = (report) => ({
  type: ACTION_TYPES.CONTACT_HELPDESK,
  payload: report,
});

export const missingSchoolReported = () => ({
  type: ACTION_TYPES.MISSING_SCHOOL_REPORTED,
});
