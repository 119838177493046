import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { sriClient } from 'ReduxLoop/utils/apiConfig';

require('./myTeamMemberOusList.scss');

class MyTeamMemberOusList {
  constructor($scope, $ngRedux, $uibModal) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.sriClient = sriClient;
    this.groupsIcon = require('Img/meeting.svg');
    this.addIcon = require('Img/add.svg');
    this.removeIcon = require('Img/cross.svg');

    this.subTitle =
      'Je kunt bestaande teams in hun geheel laten meewerken in je Microsoft Teams-omgeving. Je kunt bestaande teams zoeken en machtigingen geven, en de machtigingen ook weer intrekken. Je hoeft dus niet elke keer alle leden van een ander team lid te maken van jouw team, gewoon om in Microsoft Teams te kunnen samenwerken. (Wil je enkel bestanden delen met een ander team, kun je dat natuurlijk door een deelbare link te maken.)';
    this.ouSearchParams = {
      types: 'organisational_unit',
      searchTags: 'KATHONDVLA_DEPARTMENT,VERGADERGROEP,BELEIDSORGAAN,LEERPLANCOMMISSIE',
      expand: 'full',
    };
  }

  displayOu(resource) {
    if (!resource) {
      return null;
    }
    return `${resource.name} (${resource.type})`;
  }

  onMemberSelected(event, ou) {
    this.selectedOu = ou;
  }

  onMemberAddeded() {
    this.$scope.$emit('onMemberAdded', this.selectedOu);
  }

  onRemoveMember(member) {
    const removeMemberModal = this.createRemoveMemberModal(member);

    removeMemberModal.result.then(() => {
      this.$scope.$emit('onMemberRemoved', member);
    });
  }

  createRemoveMemberModal(member) {
    return this.$uibModal.open({
      animation: true,
      component: 'myConfirmModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        confirmationQuestion: () =>
          `Ben je zeker dat je ${member.fullName} wilt verwijderen als groep die meewerkt in je Microsoft Teams-omgeving?`,
        icon: () => this.removeIcon,
        title: () => 'Groep verwijderen',
      },
    });
  }

  searchApiErrorOccured() {
    this.$ngRedux.dispatch(
      addErrorNotification(
        'Er is een connectieprobleem met onze zoekmachine. Onze excuses. Probeer het later nog eens opnieuw.'
      )
    );
  }
}

export const myTeamMemberOusListComponent = {
  template: require('./myTeamMemberOusList.html'),
  controllerAs: 'ctrl',
  controller: MyTeamMemberOusList,
  bindings: {
    sOu: '<',
  },
};
