export class MyTeamCards {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  greyAreaClicked(team) {
    this.$scope.$emit('kovTeamCardClicked', team);
  }

  endResponsibility($event, team) {
    $event.stopPropagation();
    $event.preventDefault();
    this.$scope.$emit('kovTeamCardEndResponsibility', team);
  }

  onActionClicked(team, action) {
    this.$scope.$emit(action.event, team, action.eventArgs);
  }
}
