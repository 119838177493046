import { printDate, isAfter, getNow } from '@kathondvla/sri-client/date-utils';
import { validatePasswordStrength, validateRegistrationNumber } from 'Js/module/personUtils';
import * as ACTIONS from 'ReduxLoop/profile/profileActions';
import {
  removeNotification,
  addErrorNotification,
} from 'ReduxLoop/notifications/notificationActions';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  BESTUURDER,
  DE_HEER,
  FEMALE,
  LID_VAN_HET_DAGELIJKS_BESTUUR,
  MALE,
  MEVROUW,
} from 'ReduxLoop/constants';
import { deleteProfilePicture } from 'ReduxLoop/profile/profileActions';
import { sendReminderRequestResponsibility } from '../../reduxLoop/profile/profileActions';
import { emailAddressPattern } from '../../module/personUtils';

export class MyProfile {
  constructor($scope, $ngRedux, settings, $state, $stateParams, $uibModal) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.sriClient = sriClient;
    this.appVersion = this.settings.projectVersion;
    this.profileIcon = require('Img/user.svg');
    this.biographyIcon = require('Img/books-stack-of-three.svg');
    this.teamIcon = require('Img/people.svg');
    this.addIcon = require('Img/add.svg');
    this.lockIcon = require('Img/lock.svg');
    this.smartSchoolIcon = require('Img/smartschool_logo.svg');
    this.schoolwareIcon = require('Img/schoolware_logo.svg');
    this.office365Icon = require('Img/office365.svg');
    this.removeIcon = require('Img/cross.svg');
    this.titles = [DE_HEER, MEVROUW];
    this.validateRegistrationNumber = validateRegistrationNumber;
    this.validatePasswordStrength = validatePasswordStrength;
    this.registrationNumberWarnings = [];
    this.namePattern = {
      pattern:
        /^[^\s-!?.,;/&@#$£€*:+%()[\]{}<>0-9]{2,}([\s-][^\s-!?.,;/&@#$£€*:+%()[\]{}<>0-9]{2,})*$/,
      message: 'De naam mag geen cijfers, leestekens of haakjes bevatten',
    };
    this.usernamePattern = {
      pattern: /^[a-z0-9]+([._-][a-z0-9]+)*$/,
      message:
        'De gebruikersnaam mag bestaan uit enkel letters en cijfers, gescheiden door een komma, een koppelteken ("-") of een liggend streepje ("_")',
    };
    this.emailPattern = {
      pattern: emailAddressPattern,
      message: 'Dit is geen geldig e-mailadres',
    };

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ...state.vm.profile,
      user: state.vm.user,
      selectedTitle: state.vm.profile.person
        ? this.titles.filter((title) => title === state.vm.profile.person.title)[0]
        : null,
      profilePicture: state.vm.profile.profilePicture
        ? settings.apis.kathOndVla + state.vm.profile.profilePicture
        : null,
      notificationList: state.vm.notifications,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.$ngRedux.dispatch(ACTIONS.selectProfile(this.$stateParams.key)).then(() => {
      if (this.$stateParams['wijzig-profiel-foto']) {
        this.editProfilePicture();
      }
    });
    this.$scope.$on('onFormValidationChanged', (event, payload) => {
      this.$ngRedux.dispatch(ACTIONS.updateProfileForm(payload));
    });
    this.$scope.$on('onInvalidTypeNotification', () => {
      this.$ngRedux.dispatch(
        addErrorNotification('Het geselecteerde bestand is geen foto van het type jp(e)g of png.')
      );
    });
    this.$scope.$on('onInvalidSizeNotification', (event, payload) => {
      this.$ngRedux.dispatch(
        addErrorNotification(
          `De geselecteerde foto heeft maar een resolutie van ${payload.width}x${payload.height}. Gelieve een foto up te loaden van minstens ${payload.requiredWidth}x${payload.requiredHeight}.`
        )
      );
    });
    this.$scope.$on('onImageDelete', () => {
      this.$ngRedux.dispatch(deleteProfilePicture(this.personHref));
    });
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  goToHome() {
    if (this.$stateParams.back_url) {
      window.location = this.$stateParams.back_url;
    } else {
      this.$state.go('home');
    }
  }

  getAccountIcon(account) {
    if (!account) {
      return null;
    }
    if (account.type === 'Smartschool') {
      return this.smartSchoolIcon;
    }
    if (account.type === 'Schoolware') {
      return this.schoolwareIcon;
    }
    if (account.type === 'Office365') {
      return this.office365Icon;
    }
    return null;
  }

  unlinkAccount(account) {
    this.$ngRedux.dispatch(ACTIONS.unlinkAccount(account));
  }

  // PROFILE PICTURE

  editProfilePicture() {
    this.profPicModal = this.$uibModal.open({
      animation: true,
      component: 'kovImageEditor',
      size: 'md',
      backdrop: 'static',
      scope: this.$scope,
      resolve: {
        currentProfilePicture: () => this.profilePicture,
        aspectRatio: () => 1,
        requiredWidth: () => (this.teams.isKathOndVlaEmployee ? 1024 : 256),
        requiredHeight: () => (this.teams.isKathOndVlaEmployee ? 1024 : 256),
        title: () => 'Profielfoto',
      },
    });

    this.profPicModal.result.then(
      (result) => {
        if (this.profilePicture) {
          this.$ngRedux.dispatch(ACTIONS.replaceProfilePicture(result, this.person.permalink));
        } else {
          this.$ngRedux.dispatch(ACTIONS.addProfilePicture(result, this.person.permalink));
        }
      },
      () => {}
    );
  }

  // was not working because on-event is not working yet if html is not rendered yet
  /* profileFormChanged(e, errorSummary) {
    console.log('profile form changed')
    this.$ngRedux.dispatch(ACTIONS.updateProfileForm(errorSummary));
  } */

  // PERSONAL DATA
  titleSelected() {
    const sex = this.selectedTitle === this.titles[0] ? MALE : FEMALE;
    this.$ngRedux.dispatch(ACTIONS.updateGender(sex));
  }

  firstNameChanged(e, firstName) {
    this.$ngRedux.dispatch(ACTIONS.updateFirstName(firstName));
  }

  lastNameChanged(e, lastName) {
    this.$ngRedux.dispatch(ACTIONS.updateLastName(lastName));
  }

  registrationNumberChanged(e, rn) {
    this.$ngRedux.dispatch(ACTIONS.updateRegistrationNumber(rn));
  }

  dateOfBirthChanged(e, dateOfBirth) {
    this.$ngRedux.dispatch(ACTIONS.updateDateOfBirth(dateOfBirth));
  }

  usernameChanged(e, username) {
    this.$ngRedux.dispatch(ACTIONS.updateUsername(username));
  }

  changePassword() {
    this.$uibModal.open({
      animation: true,
      component: 'myChangePassword',
      size: 'md',
      backdrop: 'static',
      resolve: {
        personHref: () => this.person.permalink,
      },
    });
  }

  editBiography() {
    const biographyModal = this.$uibModal.open({
      animation: true,
      component: 'myEditBiography',
      size: 'md',
      backdrop: 'static',
      resolve: {
        biography: () => this.person.biography,
      },
    });

    biographyModal.result.then((result) => {
      console.log('biografie', result);
      this.$ngRedux.dispatch(ACTIONS.editBiography(result));
    });
  }

  // OFFICE CONTACT DETAILS

  emailAddressChanged(e, emailAddress) {
    this.$ngRedux.dispatch(ACTIONS.updatePrimaryEmail(emailAddress));
  }

  secondaryEmailAddressChanged(e, emailAddress) {
    this.$ngRedux.dispatch(ACTIONS.updateSecondaryEmail(emailAddress));
  }

  officePhoneChanged(e, phone) {
    this.$ngRedux.dispatch(ACTIONS.updateOfficePhone(phone));
  }

  officeMobilePhoneChanged(e, phone) {
    this.$ngRedux.dispatch(ACTIONS.updateOfficeMobilePhone(phone));
  }

  officeAddressChanged(e, address) {
    this.$ngRedux.dispatch(ACTIONS.updateOfficeAddress(address));
  }

  personalPhoneChanged(e, phone) {
    this.$ngRedux.dispatch(ACTIONS.updatePersonalPhone(phone));
  }

  personalMobilePhoneChanged(e, phone) {
    this.$ngRedux.dispatch(ACTIONS.updatePersonalMobilePhone(phone));
  }

  personalAddressChanged(e, address) {
    this.$ngRedux.dispatch(ACTIONS.updatePersonalAddress(address));
  }

  ibanExpensesChanged(e, iban) {
    this.$ngRedux.dispatch(ACTIONS.updateIbanExpenses(iban));
  }

  addPersonalContactDetails() {
    this.$ngRedux.dispatch(ACTIONS.addPersonalContactDetails());
  }

  addMissingResponsibility() {
    if (!this.person.dateOfBirth) {
      this.$ngRedux.dispatch(
        addErrorNotification(
          'Om aan dubbeldetectie te kunnen doen vragen we om eerst een stamboeknummer (of geboortedatum) in te vullen voordat je jezelf koppelt aan een organisatie. Vul eerst jouw stamboeknummer of geboortedatum in.'
        )
      );
    } else if (!this.person.primaryEmail) {
      this.$ngRedux.dispatch(
        addErrorNotification('Vul eerst een e-mailadres in voordat je een rol toevoegt.')
      );
    } else {
      this.$state.go('requestResponsibility');
    }
  }

  // TEAMS
  showAllTeamInfo(e, team) {
    this.$uibModal.open({
      animation: true,
      component: 'myTeamInfo',
      size: 'md',
      backdrop: 'static',
      resolve: {
        team: () => team,
      },
    });
  }

  endResponsibility(e, team) {
    let confirmationTitle = 'Team verlaten';
    let confirmationQuestion;
    if (team.positions.length === 1) {
      const position = team.positions[0];
      if (position.type === 'REQUEST') {
        const confirmRevokeRequest = this.createConfirmationModal(
          `Aanvraag intrekken', 'Ben je zeker dat je jouw aanvraag als ${position.name} in ${team.name} wilt intrekken.`,
          this.removeIcon
        );
        confirmRevokeRequest.result.then(() => {
          this.$ngRedux.dispatch(
            ACTIONS.endResponsibilitiesAndRequests([position.resource], null, team)
          );
        });
      } else {
        const leaveTeam = this.createLeaveTeamModal(team, [position]);
        leaveTeam.result.then((result) => {
          const chosenEndDate = printDate(result.endDate);
          confirmationQuestion = `Ben je zeker dat je ${team.name} wilt verlaten op ${chosenEndDate}?`;
          if (isAfter(result.endDate, getNow())) {
            confirmationQuestion += ` Op ${chosenEndDate} zal je alle bijhorende toegangsrechten verliezen.`;
          } else {
            confirmationQuestion += ' Je zal alle bijhorende toegangsrechten verliezen.';
          }
          const leaveTeamConfirmation = this.createConfirmationModal(
            confirmationTitle,
            confirmationQuestion,
            this.removeIcon
          );
          leaveTeamConfirmation.result.then(() => {
            this.$ngRedux.dispatch(
              ACTIONS.endResponsibilitiesAndRequests([position.resource], result.endDate, team)
            );
          });
        });
      }
    } else {
      const chooseResp = this.createChooseRespModal(team, true, 'beëindigen', this.removeIcon);
      chooseResp.result.then((resultChooseResp) => {
        const { selectedResponsibilities } = resultChooseResp;
        const leaveTeam = this.createLeaveTeamModal(team, selectedResponsibilities);
        leaveTeam.result.then((result) => {
          const chosenEndDate = printDate(result.endDate);
          if (selectedResponsibilities.length === team.positions.length) {
            confirmationQuestion = `Ben je zeker dat je ${team.name} wilt verlaten op ${chosenEndDate}?`;
          } else {
            confirmationTitle = 'Functie beëindigen';
            const positionsString = selectedResponsibilities.map((pos) => pos.name).join(', ');
            confirmationQuestion = `Ben je zeker dat je wilt stoppen als ${positionsString} in ${team.name} op ${chosenEndDate}?`;
          }
          if (isAfter(result.endDate, getNow())) {
            confirmationQuestion += ` Op ${chosenEndDate} zal je alle bijhorende toegangsrechten verliezen.`;
          } else {
            confirmationQuestion += ' Je zal alle bijhorende toegangsrechten verliezen.';
          }
          const allMainResponsibilitiesAreEnded = !team.positions
            .filter((pos) => pos.type === 'RESPONSIBILITY')
            .some(
              (pos) => !selectedResponsibilities.some((resp) => resp.resource === pos.resource)
            );
          if (
            allMainResponsibilitiesAreEnded &&
            team.positions.some((pos) => pos.type === 'REQUEST' && team.teacherGroups.length > 0)
          ) {
            confirmationQuestion +=
              ' Opgelet! Omdat jouw aanvraag nog niet goedgekeurd is, zal ook jouw lidmaadschap in alle vakgroepen van de school beëindigd worden.';
          }
          const confirmationModal = this.createConfirmationModal(
            confirmationTitle,
            confirmationQuestion,
            this.removeIcon
          );
          confirmationModal.result.then(() => {
            this.$ngRedux.dispatch(
              ACTIONS.endResponsibilitiesAndRequests(
                selectedResponsibilities.map((pos) => pos.resource),
                result.endDate,
                team
              )
            );
          });
        });
      });
    }
  }

  createLeaveTeamModal(team, positions) {
    let subtitle = null;
    let endDateHelpText = null;
    let positionsString = null;
    let positionStringWithStartDates = null;
    if (team.positions.length === positions.length) {
      subtitle =
        'Het team verlaten. Alle bijhorende toegangsrechten zullen op de gekozen einddatum worden stopgezet.';
      endDateHelpText = 'Wanneer verlaat je het team of heb je het team verlaten?';
    } else {
      positionsString = positions
        .map((position) => position.name.toLowerCase())
        .join(', ')
        .replace(/,\s([^,]+)$/, ' en $1');
      subtitle = `Functie als ${positionsString} beëindigen`;
      endDateHelpText = `Wanneer stop je of ben je gestopt als ${positionsString}?`;
    }
    positionStringWithStartDates = positions
      .map(
        (position) =>
          `${position.name ? position.name.toLowerCase() : 'lid van het team'} sinds ${
            position.startDate
          })`
      )
      .join(', ')
      .replace(/,\s([^,]+)$/, ' en $1');
    return this.$uibModal.open({
      animation: true,
      component: 'myDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        startDate: () => team.startDate,
        endDate: () => team.endDate,
        subtitle: () => subtitle,
        endDateHelpText: () => endDateHelpText,
        startDateInfo: () => `Je bent ${positionStringWithStartDates}`,
        externalConsequence: () =>
          positions
            .map((pos) => pos.externalConsequence)
            .join('</br>-------------------------------------------------------------</br>'),
      },
    });
  }

  createChooseRespModal(team, multiple, actionTitle, icon) {
    const responsibilities = team.positions
      .filter(
        (position) =>
          position.permalink !== BESTUURDER && position.permalink !== LID_VAN_HET_DAGELIJKS_BESTUUR
      )
      .map((position) => ({ ...position, responsibilityHref: position.resource }));
    return this.$uibModal.open({
      animation: true,
      component: 'myChooseRespModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        memberName: () => null,
        multiple: () => multiple,
        actionTitle: () => actionTitle,
        icon: () => icon,
        responsibilities: () => responsibilities,
      },
    });
  }

  createConfirmationModal(title, question, icon) {
    return this.$uibModal.open({
      animation: true,
      component: 'myConfirmModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        confirmationQuestion: () => question,
        icon: () => icon,
        title: () => title,
      },
    });
  }

  sendReminder(event, team) {
    this.$ngRedux.dispatch(sendReminderRequestResponsibility(
      team.positions
        .filter((pos) => pos.type === 'REQUEST')
        .map((pos) => pos.resource)
    ));
  }
}
