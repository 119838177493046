import { isBefore, isAfter } from '@kathondvla/sri-client/date-utils';
import { formatPhoneNumber } from '@kathondvla/sri-client/contactdetail-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  addErrorNotification,
  addWarningNotification,
  addSuccessNotification,
  removeNotification,
} from 'ReduxLoop/notifications/notificationActions';
import { fetchUser } from 'ReduxLoop/user/userActions';
import {
  fetchOuData,
  getOuByInstitutionNumber,
  sendBatch,
  validateInstitutionNumber,
  getAllPhysicallocationsInCity,
} from './dataAccess';
import { transformOu, getFormModel, calculateChanges } from './transformations';
import { emailAddressPattern } from '../../module/personUtils';

const OU_TYPES = [
  { value: 'SCHOOL', label: 'officiële school' },
  { value: 'GOVERNINGINSTITUTION', label: 'bestuur' },
  { value: 'BOARDING', label: 'internaat' },
  { value: 'CLB', label: 'clb' },
  { value: 'SCHOOLCOMMUNITY', label: 'scholengemeenschap' },
  { value: 'LEERSTEUNCENTRUM', label: 'leersteuncentrum' },
];

export class MyOuAdminForm {
  constructor($scope, $state, $stateParams, $location, $ngRedux, settings) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.sriClient = sriClient;
    this.OU_TYPES = OU_TYPES;
    this.searchParams = {
      types: 'organisational_unit',
      expand: 'full',
    };
    this.ouData = {};
    this.reset();

    this.emailPattern = {
      pattern: emailAddressPattern,
      message: 'Dit is geen geldig e-mailadres',
    };
    this.websitePattern = {
      pattern:
        /^(http(s)?:\/\/)?(w{3}\.)?([-a-zA-Z0-9@:%._+~#=]{2,256})(\.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))+$/,
      message: 'Dit is geen geldige website',
    };
    this.insitutionNumberPattern = {
      pattern: /\d{6}/,
      message: 'een instellingsnummer bestaat uit 6 cijfers',
    };
    this.companyNumberPattern = {
      pattern: /[0-9]{4}\.[0-9]{3}\.[0-9]{3}/,
      message:
        'een ondernemingsnummer bestaat uit 10 cijfers met een punt tussen de 3 groepen van cijfers',
    };
    this.vatNumberPattern = {
      pattern: /[A-Z]{2}[0-9]{10}/,
      message:
        'een BTW-nummer bestaat uit een landcode gevolgd door een aansluitend blok van 10 cijfers',
    };

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      user: state.vm.user,
      notificationList: state.vm.notifications,
    }))(this);
  }

  async $onInit() {
    this.$ngRedux.dispatch(fetchUser()).then(() => {
      if (!this.user.isKerndataAdmin) {
        this.$state.go('home');
      }
    });
    if (this.$stateParams.nr) {
      const ou = await getOuByInstitutionNumber(this.$stateParams.nr);
      if (ou) {
        this.ouSelected(ou);
      }
    }

    const handleUrlChange = async (event) => {
      const nrMatch = event.currentTarget.location.hash.match(/nr=(\d+)/);
      const urlInstitutionNumber = nrMatch && nrMatch.length >= 2 && nrMatch[1];
      if (this.ou && !urlInstitutionNumber) {
        this.ouData = {};
        this.ouType = null;
        this.reset();
        this.$scope.$apply();
      }
      if (
        urlInstitutionNumber &&
        (!this.ou || urlInstitutionNumber !== this.ou.institutionNumber)
      ) {
        console.log('[url watcher] instititution number changed by URL!');
        const ou = await getOuByInstitutionNumber(urlInstitutionNumber);
        if (ou) {
          this.ouType = null;
          this.searchParams.organisationalUnitTypes = null;
          this.ouSelected(ou);
        }
      }
    };
    window.addEventListener('popstate', handleUrlChange);
  }

  calculateVmExternal() {
    this.calculateVm();
  }

  calculateVm(useOuEndDateAsReference) {
    this.ou = this.ouData.ouResource
      ? transformOu(
          this.ouData,
          this.pendingResources,
          useOuEndDateAsReference ? this.ouData.ouResource.endDate : this.formModel.endDate
        )
      : null;
  }

  reset() {
    this.dirtyResources = {};
    this.validations = {
      endDateErrors: [],
      institutionNumberErrors: [],
      phoneErrors: [],
    };
    this.pendingResources = {
      relations: {},
      locations: {},
      physicalLocations: {},
    };
    this.calculateVm(true);
    this.formModel = this.ou ? getFormModel(this.ou) : {};
  }

  ouTypeChanged() {
    if (this.searchParams.organisationalUnitTypes) {
      this.$location.search('nr', []);
      this.ouData = {};
      this.reset();
    }
    this.searchParams.organisationalUnitTypes = this.ouType;
  }

  ouSelected = async (searchDocument) => {
    this.ouData = await fetchOuData(searchDocument.$$meta.permalink);
    if (
      (!this.$stateParams.nr && searchDocument.institutionNumber) ||
      this.$stateParams.nr !== searchDocument.institutionNumber
    ) {
      // this.$state.go('.', { nr: searchDocument.institutionNumber }, { reload: false });
      this.$location.search('nr', searchDocument.institutionNumber);
    }
    if (!this.ouType) {
      this.ouType = OU_TYPES.find((option) => option.value === this.ouData.ouResource.type).value;
      this.ouTypeChanged();
    }
    this.reset();
    this.$scope.$apply();
  };

  // ++++++++++++++++++++++++++++ SAVE ++++++++++++++++++++++++++++

  async onSubmit() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.submitAttempted = true;
    if (
      !this.ouAdminForm.valid ||
      Object.values(this.validations).some((errorList) => errorList.length > 0)
    ) {
      this.$ngRedux.dispatch(
        addErrorNotification(
          'Niet alle velden zijn correct ingevuld. Kijk de gemarkeerde velden na'
        )
      );
      this.isSaving = false;
      return;
    }
    try {
      const physicalLocationsInCityOfMail =
        this.formModel.mail && this.dirtyResources.mail
          ? await getAllPhysicallocationsInCity(this.formModel.mail.cityHref, 'DOMAIN')
          : null;
      const { batch, newOuData } = calculateChanges({
        ouType: this.ouType,
        ouModel: this.ou,
        formModel: this.formModel,
        dirtyResources: this.dirtyResources,
        pendingResources: this.pendingResources,
        ouData: this.ouData,
        physicalLocationsInCityOfMail,
      });
      if (batch.length === 0) {
        this.$ngRedux.dispatch(addWarningNotification('Er zijn geen wijzigingen'));
        this.isSaving = false;
        return;
      }
      await sendBatch(batch);
      this.$ngRedux.dispatch(addSuccessNotification('De wijzigingen zijn succesvol opgeslagen.'));
      if (!this.ou) {
        this.ouSelected({
          ...newOuData.ouResource,
          institutionNumber: this.formModel.institutionNumber,
        });
        return;
        // this.$state.go('.', { nr: this.formModel.institutionNumber });
      }
      this.ouData = newOuData;
      this.reset();
    } catch (error) {
      console.error(error);
      this.$ngRedux.dispatch(addErrorNotification('Er is een onverwachte fout opgetreden'));
    } finally {
      this.isSaving = false;
    }
  }

  // ++++++++++++++++++++++++++++ OU DETAILS ++++++++++++++++++++++++++++

  nameChanged(e, value) {
    this.dirtyResources.ou = true;
    this.formModel.name = value;
  }

  shortNameChanged(e, value) {
    this.dirtyResources.ou = true;
    this.formModel.shortName = value;
  }

  startDateChanged(e, value) {
    this.dirtyResources.ou = true;
    this.formModel.startDate = value;
  }

  endDateChanged(e, value) {
    this.dirtyResources.ou = true;
    this.validations.endDateErrors = [];
    this.relationsThatDependOnEndDateChange = [];
    this.formModel.endDate = value;
    if (isBefore(value, this.formModel.startDate)) {
      this.validations.endDateErrors = [
        { message: 'De einddatum mag niet voor de startdatum liggen.' },
      ];
    }
    if (
      this.ouAdminForm.formGroups.einddatum.valid &&
      this.validations.endDateErrors.length === 0
    ) {
      ['bestuur', 'schoolCommunity', 'koepel'].forEach((prop) => {
        if (this.ou[prop]) {
          this.pendingResources.relations[this.ou[prop].resource.$$meta.permalink] = {
            ...this.ou[prop].resource,
            endDate: value,
          };
        }
      });
      this.ou.locations
        .filter((loc) => isAfter(loc.endDate, value) || (!value && loc.endDate === this.ou.endDate))
        .forEach((loc) => {
          this.pendingResources.locations[loc.resource.$$meta.permalink] = {
            ...loc.resource,
            endDate: value,
          };
        });
      this.calculateVm();
    }
  }

  async institutionNumberChanged(e, value) {
    this.validations.institutionNumberErrors = [];
    this.formModel.institutionNumber = value;
    if (this.ouAdminForm.formGroups.instellingsnummer.valid) {
      this.validations.institutionNumberErrors = await validateInstitutionNumber(value);
    }
  }

  companyNumberChanged(e, value) {
    this.dirtyResources.companyNumber = true;
    this.formModel.companyNumber = value;
  }

  vatNumberChanged(e, value) {
    this.dirtyResources.vatNumber = true;
    this.formModel.vatNumber = value;
  }

  // ++++++++++++++++++++++++ CONTACT DETAILS ++++++++++++++++++++++++

  emailChanged(e, value) {
    this.dirtyResources.email = true;
    this.formModel.email = value;
  }

  phoneChanged(e, value) {
    this.dirtyResources.phone = true;
    this.validations.phoneErrors = [];
    this.formModel.phone = value;
    if (value && this.ouAdminForm.formGroups.phone.valid) {
      try {
        this.formModel.phone = formatPhoneNumber(value);
      } catch (error) {
        this.validations.phoneErrors.push({
          code: 'invalid.format',
          message: 'Dit is geen geldig telefoonnumer',
        });
      }
    }
  }

  websiteChanged(e, value) {
    this.dirtyResources.website = true;
    this.formModel.website = value;
  }

  mailChanged(e, value) {
    this.dirtyResources.mail = true;
    this.formModel.mail = value;
  }

  // ++++++++++++++++++++++++ PAGE ACTIONS ++++++++++++++++++++++++++++

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  viewInApi() {
    window.open(
      `${this.settings.apis.kathOndVla}/sam/organisationalunits/view?institutionNumber=${this.ou.institutionNumber}`,
      '_blank'
    );
  }

  goToHome() {
    this.$state.go('home');
  }
}
