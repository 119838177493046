import { printDate, getNow } from '@kathondvla/sri-client/date-utils';

export class CreateLinkModal {
  constructor($scope, $window) {
    'ngInject';

    this.$scope = $scope;
    this.$window = $window;
    this.linkIcon = require('Img/link.svg');
    this.isSpecificDate = 'false';
    this.startDate = getNow();
    this.startDateErrors = [];
  }

  $onInit() {
    this.subTitle = `Met deze link kan iemand een aanvraag doen voor dit team. Deze aanvraag moet nog worden goedgekeurd door ${this.resolve.managersDescription}.`;
    this.possiblePositions = this.resolve.possiblePositions.filter((pos) => pos.key);
    this.possiblePositions.unshift({ name: '(de gebruiker kiest zelf de rol)' });
    this.selectedPosition =
      this.possiblePositions.find(
        (pos) => pos.$$meta && pos.$$meta.permalink === this.resolve.defaultPosition
      ) || this.possiblePositions[0];
    this.calculateLink();
  }

  calculateLink() {
    this.requestLink = `${this.$window.location.origin}/#!/aanvraag-team?ou=${this.resolve.ou.key}`;
    if (this.isSpecificDate === 'true' && this.startDate) {
      this.requestLink += `&start=${this.startDate}`;
    }
    if (this.selectedPosition && this.selectedPosition.key) {
      this.requestLink += `&functie=${this.selectedPosition.key}`;
    }
  }

  setSpecificDate(value) {
    this.isSpecificDate = value;
    this.validateStartDate();
    this.calculateLink();
  }

  validateStartDate() {
    if (this.isSpecificDate === 'false') {
      this.startDateErrors = [];
    } else if (!this.startDate) {
      this.startDateErrors = [{ message: 'De startdatum is verplicht' }];
    } else if (this.startDate && this.startDate < this.resolve.ou.startDate) {
      this.startDateErrors = [
        {
          message: `De startdatum is voor de startdatum van ${this.resolve.ou.name} (${printDate(
            this.resolve.ou.startDate
          )})`,
        },
      ];
    } else {
      this.startDateErrors = [];
    }
  }

  startDateChanged(e, startDate) {
    console.log('startDate changed to ', startDate);
    this.startDate = startDate;
    this.validateStartDate();
    this.calculateLink();
  }

  copy() {
    navigator.clipboard.writeText(this.requestLink);
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
