export const UPDATE_IS_PIWIK_TAG_READY = 'update_is_piwik_tag_ready';
export const TRACK_PIWIK_EVENT = 'track_piwik_event';
export const SEND_PIWIK_EVENT_SUCCESS = 'send_piwik_event_success';
export const SEND_PIWIK_EVENT_ERROR = 'send_piwik_event_error';

export const updateIsPiwikTagReady = (piwikTagReady) => ({
  type: UPDATE_IS_PIWIK_TAG_READY,
  payload: piwikTagReady,
});

export const trackPiwikEvent = (eventName, props) => ({
  type: TRACK_PIWIK_EVENT,
  payload: { eventName, props },
});

export const sendPiwikEventSuccess = () => ({
  type: SEND_PIWIK_EVENT_SUCCESS,
});

export const sendPiwikEventError = () => ({
  type: SEND_PIWIK_EVENT_ERROR,
});
