import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { CurriculaSelector } from '@kathondvla/curricula-selector';
import { sriClient, cachedSriClient } from 'ReduxLoop/utils/apiConfig';
import { settings } from 'Js/config/settings';

const CurriculumSelectorModal = ({ handleSubmit, handleClose, selectedThemes }) => {
  const apiConfig = {
    sriClient,
    cachedSriClient,
  };
  const userHref = useSelector((state) => state.vm.user.permalink);
  const loginUrl = `${settings.oauth.oauthURL}?redirect_uri=${self.location.origin}`;

  return ReactDOM.createPortal(
    <CurriculaSelector
      initialSelection={selectedThemes}
      apiConfig={apiConfig}
      userHref={userHref}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loginUrl={loginUrl}
    />,
    document.body
  );
};

export default CurriculumSelectorModal;
