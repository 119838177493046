require('./myClassDetails.scss');

const DELETE_CLASS = 'deleteClass';
const EDIT_CLASS = 'editClass';

export class ClassDetails {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  deleteClass() {
    this.$scope.$emit(DELETE_CLASS, this.sClass);
  }

  editClass() {
    this.$scope.$emit(EDIT_CLASS, this.sClass);
  }
}
