import * as ACTIONS from 'ReduxLoop/ouManager/schoolManagerActions';

export class OuDetailsTab {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.teamsIcon = require('Img/meeting.svg');
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ...state.vm.schoolManager.details,
      ou: state.vm.schoolManager.currentOU,
    }))(this);
  }

  $onInit() {
    this.$scope.$on('onFormValidationChanged', (event, formObj) => {
      this.$ngRedux.dispatch(ACTIONS.setDetailsFormValidation(formObj.valid));
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }

  nameChanged(ev, data) {
    this.$ngRedux.dispatch(ACTIONS.changeDetailsName(data));
  }

  shortNameChanged(ev, data) {
    this.$ngRedux.dispatch(ACTIONS.changeDetailsShortName(data));
  }

  keyPressedInDescription(ev) {
    if (ev.keyCode !== 32) {
      // ignore space;
      this.isTypingInDescription = true;
    }
  }

  descriptionChanged() {
    this.isTypingInDescription = false;
    this.descriptionDirty = true;
    this.$ngRedux.dispatch(ACTIONS.changeDetailsDescription(this.description));
  }

  typeChanged() {
    this.$ngRedux.dispatch(ACTIONS.changeDetailsType(this.type));
  }
}
