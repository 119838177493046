// import * as ACTION_TYPES from 'ReduxLoop/constants/actionTypes';
export const TEAM_INFO_FETCHED = 'team_info_fetched';
export const PENDING_RESPONSIBILITIES_FETCHED = 'pending_responsibilities_fetched';
export const POSSIBLE_POSITIONS_FETCHED = 'possible_positions_fetched';
export const INVITATION_DELIVERY_INFO_FETCHED = 'invitation_delivery_info_fetched';
export const FETCH_EMAIL_JOBS = 'fetch_email_jobs';
export const EMAIL_JOBS_FETCHED = 'email_jobs_fetched';
export const FILTER_VALUE_CHANGED = 'filter_value_changed';
export const SEND_TEAM_INVITATION = 'send_team_invitation';
export const SEND_TEAM_INVITATION_SUCCEEDED = 'send_team_invitation_succeeded';
export const CHANGE_ROLE = 'change_role';
export const CHANGE_ROLE_FAILED = 'change_role_failed';
export const SEND_REMINDER = 'send_reminder';
export const REMINDER_SENT = 'reminder sent';
export const CANCEL_TEAM_INVITATION = 'cancel_team_invitation';
export const END_TEAM_RESPONSIBILITY = 'end_team_responsibility';
export const END_TEAM_RESPONSIBILITY_FAILED = 'end_team_responsibility_failed';
export const END_TEAM_MEMBERSHIP = 'end_team_membership';
export const END_TEAM_MEMBERSHIP_FAILED = 'end_team_membership_failed';
export const DELEGATE_ROLE = 'delegate_role';
export const REVOKE_ROLE = 'revoke_role';
export const ROLE_DELEGATION_FAILED = 'role_delegation_failed';
export const ROLE_REVOKATION_FAILED = 'role_revokation_failed';
export const EMAIL_ADDRESS_BOUNCED = 'email_address_bounced';
export const SEND_INVITATION_FAILED = 'send_invitation_failed';

export const teamInfoFetched = (teamInfo) => ({
  type: TEAM_INFO_FETCHED,
  payload: teamInfo,
});

export const pendingResponsibilitiesFetched = (pendingResponsibilities) => ({
  type: PENDING_RESPONSIBILITIES_FETCHED,
  payload: pendingResponsibilities,
});

export const possiblePositionsFetched = (possiblePositions) => ({
  type: POSSIBLE_POSITIONS_FETCHED,
  payload: possiblePositions,
});

export const invitationDeliveryInfoFetched = (undeliveredInvitations) => ({
  type: INVITATION_DELIVERY_INFO_FETCHED,
  payload: undeliveredInvitations,
});

export const fetchEmailJobs = (invitations) => ({
  type: FETCH_EMAIL_JOBS,
  payload: invitations,
});

export const emailJobsFetched = (emailJobs) => ({
  type: EMAIL_JOBS_FETCHED,
  payload: emailJobs,
});

export const filterValueChanged = (value) => ({
  type: FILTER_VALUE_CHANGED,
  payload: value,
});

export const sendTeamInvitation = (invitationInfo) => ({
  type: SEND_TEAM_INVITATION,
  payload: invitationInfo,
});

export const sendTeamInvitationSucceeded = (invitations) => ({
  type: SEND_TEAM_INVITATION_SUCCEEDED,
  payload: invitations,
});

export const sendInvitationFailed = (error, invitation) => ({
  type: SEND_INVITATION_FAILED,
  payload: {
    error,
    invitation,
  },
});

export const sendReminder = (invitation) => ({
  type: SEND_REMINDER,
  payload: invitation,
});

export const reminderSent = (invitation) => ({
  type: REMINDER_SENT,
  payload: invitation,
});

export const cancelTeamInvitation = (invitation) => ({
  type: CANCEL_TEAM_INVITATION,
  payload: invitation,
});

export const cancelInvitationFailed = (error, invitation) => {
  console.error('cancelInvitationFailed', {
    error,
    invitation,
  });
};

export const endResponsibility = (member, responsibilityHref, endDate) => ({
  type: END_TEAM_RESPONSIBILITY,
  payload: {
    member,
    responsibilityHref,
    endDate,
  },
});

export const endResponsibilityFailed = (error, member, responsibility) => ({
  type: END_TEAM_RESPONSIBILITY_FAILED,
  payload: {
    member,
    error,
    responsibility,
  },
});

export const endTeamMembership = (member, endDate, oldEndDate) => ({
  type: END_TEAM_MEMBERSHIP,
  payload: {
    member,
    endDate,
    oldEndDate,
  },
});

export const endTeamMembershipFailed = (error, member) => ({
  type: END_TEAM_MEMBERSHIP_FAILED,
  payload: {
    member,
    error,
  },
});

export const delegateRole = (member, role, roleDescription) => ({
  type: DELEGATE_ROLE,
  payload: {
    member,
    role,
    roleDescription,
  },
});

export const roleDelegationFailed = (error, responsibility) => ({
  type: ROLE_DELEGATION_FAILED,
  payload: {
    error,
    responsibility,
  },
});

export const revokeRole = (member, role) => ({
  type: REVOKE_ROLE,
  payload: {
    member,
    role,
  },
});

export const roleRevokationFailed = (error, responsibility) => ({
  type: ROLE_REVOKATION_FAILED,
  payload: {
    error,
    responsibility,
  },
});

export const changeRole = (responsibilityHref, newPositionHref, member) => ({
  type: CHANGE_ROLE,
  payload: {
    responsibilityHref,
    newPositionHref,
    member,
  },
});

export const changeRoleFailed = (
  error,
  responsibilityHref,
  oldPositionHref,
  newResponsibilityHref
) => ({
  type: CHANGE_ROLE_FAILED,
  payload: {
    error,
    responsibilityHref,
    oldPositionHref,
    newResponsibilityHref,
  },
});
