import { contactHelpDesk } from 'ReduxLoop/registerAndConfirmInvitation/inviteConfirmation/invitationActions';
import { addRequestToRegistration } from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import { addSuccessNotification } from 'ReduxLoop/notifications/notificationActions';
import { emailAddressPattern } from '../../module/personUtils';

export class MyContactHelpdesk {
  constructor($scope, $ngRedux, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.envelopmentIcon = require('Img/icon_invited_member.svg');
    this.arrowLeftIcon = require('Img/arrow-left.svg');
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.addressRequired = { message: 'Vul het adres van de {{ctrl.ouLabel}} in' };
    if ($stateParams.type === 'school-ontbreekt') {
      this.type = 'MISSING_ORGANISATIONAL_UNIT';
      this.title = 'Welke school ontbreekt er?';
      this.ouLabel = 'school';
    } else if ($stateParams.type === 'geen-toegang-tot-email') {
      this.type = 'NO_ACCESS_TO_EMAIL';
      this.title = 'Geef uw gegevens door aan onze helpdesk';
      this.ouLabel = 'organisatie';
    } else if ($stateParams.type === 'ik-vind-mijn-organisatie-niet') {
      this.subTitle = `Vul het onderstaande formulier in om onze helpdesk te contacteren. Zij zullen proberen te achterhalen waarom jouw ${$stateParams['organisatie-type']} niet kan gevonden worden.`;
      this.type = 'CAN_NOT_FIND_OU';
      this.ouLabel = $stateParams['organisatie-type'];
      if (this.ouLabel === 'lerarennetwerk' || this.ouLabel === 'team') {
        this.addressRequired = false;
      }
      this.title = `Geef de naam${this.addressRequired ? ' en het adres' : ''} van ${
        $stateParams.lidwoord
      } ${this.ouLabel}`;
    }
    this.emailPattern = {
      pattern: emailAddressPattern,
      message: 'Dit is geen geldig e-mailadres',
    };

    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.vm.requestResponsibility.contactHelpdeskForm) {
        this.$state.go('requestResponsibility');
      }
      return {
        ...state.vm.requestResponsibility.contactHelpdeskForm,
        user: state.invite.user,
        duplicate: state.register.duplicates ? state.register.duplicates[0] : null,
        invitationMap: state.invite.invitationMap,
        invitationKey: state.invite.invitationKey,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  firstNameChanged(ev, firstName) {
    this.firstName = firstName;
  }

  lastNameChanged(ev, lastName) {
    this.lastName = lastName;
  }

  emailChanged(ev, email) {
    this.emailAddress = email;
  }

  schoolChanged(ev, school) {
    this.school = school;
  }

  addressChanged(ev, address) {
    this.address = address;
  }

  roleChanged(ev, role) {
    this.role = role;
  }

  remarkChanged(ev, remark) {
    this.remark = remark;
  }

  goBack(ev) {
    if (this.type === 'MISSING_ORGANISATIONAL_UNIT') {
      this.$state.go('selectOuForInit');
    } else if (this.type === 'NO_ACCESS_TO_EMAIL') {
      this.$state.go('showDuplicate');
    } else if (this.type === 'CAN_NOT_FIND_OU') {
      this.$state.go('requestResponsibility');
    }
  }

  reportMissingSchool() {
    this.submitAttempted = true;
    if (!this.schoolOntbreektForm.valid) {
      return;
    }

    const report = {
      type: this.type,
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      ouName: this.school,
      address: this.address,
      remark: this.remark,
    };
    if (this.type === 'MISSING_ORGANISATIONAL_UNIT') {
      report.subject = 'Mijn school ontbreekt';
      report.desciption =
        'Een directeur van een officiële instelling gaf aan dat er een school ontbreekt nadat hij een uitnodiging heeft gekregen om zich aan een schoolentitieit te koppelen die via aanbod gerelateerd is aan zijn officiële instelling.';
      report.ouType = `SCHOOLENTITY${this.ouTypeDescription ? ` (${this.ouTypeDescription})` : ''}`;
      report.personHref = `/persons/${this.user.uuid}`;
      report.role = 'directeur';
      report.invitation = Object.keys(this.invitationMap).map(
        (ouKey) => this.invitationMap[ouKey].invitationKey
      );
    } else if (this.type === 'NO_ACCESS_TO_EMAIL') {
      report.subject = 'Geen toegang tot email';
      report.desciption =
        'Een gebruiker die aan het registreren was lijkt dubbel in de databank te zitten, maar kan niet aan zijn e-mailadres. Kijk na of het om dezelfde persoon gaat. uuid is de uuid van de gesugereerde dubbel.';
      report.personHref = this.duplicate.permalink;
      report.role = this.role;
      report.invitation = [this.invitationKey];
    } else if (this.type === 'CAN_NOT_FIND_OU') {
      report.subject = `Ik vind mijn ${this.ouLabel} niet`;
      report.personHref = this.personHref;
      report.ouType = this.ouType + (this.ouTypeDescription ? ` (${this.ouTypeDescription})` : '');
      if (this.isRegistering) {
        report.subject += ' (REGISTRATIE)';
        report.description = `Bij het toevoegen van een rol bij de registratie heeft de registrerende gebruiker aangegeven dat hij/zij zijn/haar ${this.ouLabel} niet kan vinden. Het gaat om iemand die registreert dus misschien heeft die zijn registratie nog niet voltooid en zit die nog niet in de personen databank.`;
      } else {
        report.description = `Bij het toevoegen van een rol vanuit de profielpagina heeft de gebruiker aangegveven dat hij/zij zijn/haar ${this.ouLabel} niet kan vinden.`;
      }
      report.role = this.role;
    }

    this.$ngRedux.dispatch(contactHelpDesk(report)).then(() => {
      if (this.type === 'MISSING_ORGANISATIONAL_UNIT') {
        this.$state.go('selectOuForInit');
      } else if (this.type === 'NO_ACCESS_TO_EMAIL') {
        this.$state.go('showDuplicate');
      } else if (this.type === 'CAN_NOT_FIND_OU') {
        if (this.isRegistering) {
          this.$ngRedux.dispatch(addRequestToRegistration());
        } else {
          this.$ngRedux.dispatch(
            addSuccessNotification(
              'Jouw e-mail is naar de helpdesk verstuurd. Zij proberen dit binnen de 5 werkdagen te verwerken.'
            )
          );
          this.$state.go('profile');
        }
      }
    });
  }
}
