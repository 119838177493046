export class MyChangePasswordFromLinkSuccess {
  constructor($state, $stateParams) {
    'ngInject';

    this.$state = $state;
    this.redirectUrl = $stateParams.redirect_url;
  }

  goToLogin() {
    if (this.redirectUrl) {
      window.location = decodeURIComponent(this.redirectUrl);
    } else {
      this.$state.go('profile');
    }
  }
}