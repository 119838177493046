import { MyTabContainerHeader } from './myTabContainerHeader';

export const myTabContainerHeaderComponent = {
  template: require('./myTabContainerHeader.html'),
  controllerAs: 'ctrl',
  controller: MyTabContainerHeader,
  transclude: true,
  bindings: {
    sSchoolName: '@',
    sMailAddress: '<',
    sOu: '<',
    sUserRole: '@',
    sHeaderIcon: '@',
    sHeaderTitle: '@',
    sHeaderSubtitle: '@',
  },
};
