import { getNow, isAfter, getActiveResources } from '@kathondvla/sri-client/date-utils';
import {
  DELEGATED_ADMIN,
  DELEGATED_PEDAGOGIC,
  FORBIDDEN,
  GROUP_MANAGER,
  KATHOLIEK_ONDERWIJS_VLAANDEREN,
  MANAGER,
  STRATEGIC_PURCHASER,
} from 'ReduxLoop/constants';
import {
  displayOuType,
  OLD_MIJN_OU_TYPE_CONFIG,
  OU_TYPE_CONFIG,
  POSSIBLE_MANAGEMENT_TABS,
} from 'ReduxLoop/appConfig';
import { settings } from 'Js/config/settings';
import { mapToArray } from 'ReduxLoop/viewModel';

const getUserResponsibilityInfo = (state, responsibility, ou, role) => {
  let hasAccess = false;
  if (role === GROUP_MANAGER || ou.$$meta.type === 'ORGANISATION') {
    hasAccess = true;
  } else if (ou.type === 'TEACHER_GROUP' && (role === MANAGER || role === DELEGATED_ADMIN)) {
    hasAccess = state.userState.ousWithUpdateAccessToResps.some((ouKey) => ouKey === ou.key);
  } else if (role === MANAGER || role === DELEGATED_ADMIN || role === STRATEGIC_PURCHASER) {
    hasAccess = state.userState.ousWithAccessToInvitations.some((ouKey) => ouKey === ou.key);
  } else if (role === DELEGATED_PEDAGOGIC) {
    hasAccess =
      state.groupsTab.groups.size === 0 ||
      !mapToArray(state.groupsTab.groups.toJS()).some(
        (group) => !state.userState.ousWithReadAccessToResps.some((ouKey) => ouKey === group.key)
      );
  }
  return {
    name: ou.$$meta.type === 'ORGANISATION' ? ou.$$name : ou.$$displayName,
    ouKey: ou.key,
    ouType: ou.type,
    ouPermalink: ou.$$meta.permalink,
    permalink: responsibility ? responsibility.$$meta.permalink : null,
    startDate: responsibility ? responsibility.startDate : null,
    endDate: responsibility ? responsibility.endDate : null,
    inFuture:
      responsibility && responsibility.startDate && isAfter(responsibility.startDate, getNow()),
    locations:
      ou.$$meta.type === 'ORGANISATION'
        ? getActiveResources(ou.seatAddresses).map((sa) => ({ address: sa.address }))
        : [],
    role,
    noAccessYet: !hasAccess,
    canGrantAccessToSensitiveInformation:
      state.userState.ousWithGrantAccessToSensitiveInformation.some((ouKey) => ouKey === ou.key),
    href: `/#!/${displayOuType(ou.type).toLowerCase()}/${ou.key}`, // TODO use this with ng-href and calculate default tab in schoolmanager
  };
};

export const getUserInfo = (state) => {
  let userInfo = {};

  if (state.userState.user) {
    const profilePictures = state.userState.user.attachments.filter(
      (att) =>
        att.$$expanded.type === 'PROFILE_IMAGE' && att.$$expanded.name === 'profile-small.jpg'
    );
    userInfo = {
      key: state.userState.user.key,
      firstName: state.userState.user.firstName,
      lastName: state.userState.user.lastName,
      username: state.userState.user.username,
      permalink: state.userState.user.$$meta.permalink,
      imageUrl:
        profilePictures.length > 0
          ? `${settings.apis.kathOndVla + profilePictures[0].$$expanded.href}?${
              profilePictures[0].$$expanded.key
            }`
          : null,
      isSuperAdmin: state.userState.isSuperAdmin,
      isKerndataAdmin: state.userState.isKerndataAdmin,
      isDemoAccount: state.userState.user.firstName.match(
        /^Demo\s([Dd]irecteur|[Ll]eerkracht)\s[a-zA-Z]\s?/
      ),
      popUpIsOpened: state.userState.popUpIsOpened,
      isKathOndVlaEmployee: false,
    };

    if (state.userState.responsibilities.sam) {
      userInfo.manages = {};
      Object.keys(OLD_MIJN_OU_TYPE_CONFIG).forEach((ouType) => {
        userInfo.manages[ouType] = [];
      });
      let samResps = mapToArray(state.userState.responsibilities.sam.toJS());
      samResps.forEach((resp) => {
        resp.$ou = state.userState.ous.get(resp.organisation.href);
      });
      samResps = samResps.filter((resp) => resp.$ou && isAfter(resp.$ou.endDate, getNow()));
      let vosResps = [];
      if (state.userState.responsibilities.vos && state.userState.vosOus) {
        vosResps = mapToArray(state.userState.responsibilities.vos).map((resp) => ({
          ...resp,
          $ou: state.userState.vosOus[resp.organisation.href],
        }));
        vosResps = vosResps.filter(
          (resp) =>
            resp.$ou &&
            resp.$ou.$$meta.type === 'ORGANISATION' &&
            isAfter(resp.$ou.$$endDate, getNow())
        );
      }
      const parentRelations = mapToArray(state.userState.parentRelations.toJS());
      Object.keys(OU_TYPE_CONFIG).forEach((ouType) => {
        if (!OU_TYPE_CONFIG[ouType].childOf) {
          userInfo.manages[ouType] = {};
        }
        const respsOfType = [...samResps, ...vosResps].filter(
          (resp) =>
            (resp.$ou.type === ouType ||
              (OU_TYPE_CONFIG[ouType].types &&
                OU_TYPE_CONFIG[ouType].types.some((type) => type === resp.$ou.type))) &&
            resp.position
        );
        const managers = respsOfType
          .filter((resp) =>
            OU_TYPE_CONFIG[ouType].managers.some(
              (positionHref) => positionHref === resp.position.href
            )
          )
          .map((resp) => ({ responsibility: resp, role: MANAGER }));
        const admins = !OU_TYPE_CONFIG[ouType].admins
          ? []
          : respsOfType
              .filter((resp) =>
                OU_TYPE_CONFIG[ouType].admins.some(
                  (positionHref) =>
                    positionHref === resp.position.href &&
                    !managers.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    )
                )
              )
              .map((resp) => ({ responsibility: resp, role: DELEGATED_ADMIN }));
        const pedagogics = !OU_TYPE_CONFIG[ouType].pedagogicalAdmins
          ? []
          : respsOfType
              .filter((resp) =>
                OU_TYPE_CONFIG[ouType].pedagogicalAdmins.some(
                  (positionHref) =>
                    positionHref === resp.position.href &&
                    !managers.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    ) &&
                    !admins.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    )
                )
              )
              .map((resp) => ({ responsibility: resp, role: DELEGATED_PEDAGOGIC }));
        const strategicPurchasers = !OU_TYPE_CONFIG[ouType].strategicPurchasers
          ? []
          : respsOfType
              .filter((resp) =>
                OU_TYPE_CONFIG[ouType].strategicPurchasers.some(
                  (positionHref) =>
                    positionHref === resp.position.href &&
                    !managers.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    ) &&
                    !admins.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    ) &&
                    !pedagogics.some(
                      (managerResp) =>
                        managerResp.responsibility.organisation.href === resp.organisation.href
                    )
                )
              )
              .map((resp) => ({ responsibility: resp, role: STRATEGIC_PURCHASER }));
        [...managers, ...admins, ...pedagogics, ...strategicPurchasers].forEach((obj) => {
          const resp = obj.responsibility;
          if (OU_TYPE_CONFIG[ouType].childOf) {
            const parentRels = parentRelations.filter(
              (rel) => rel.from.href === resp.organisation.href
            );
            const parent = state.userState.ous.get(parentRels[0].to.href);
            if (!userInfo.manages[parent.type][parent.key]) {
              userInfo.manages[parent.type][parent.key] = getUserResponsibilityInfo(
                state,
                null,
                parent,
                GROUP_MANAGER,
                null
              );
              userInfo.manages[parent.type][parent.key].groups = [];
            }
            if (userInfo.manages[parent.type][parent.key].groups) {
              userInfo.manages[parent.type][parent.key].groups.push(
                getUserResponsibilityInfo(state, resp, resp.$ou, obj.role)
              );
            }
          } else {
            userInfo.manages[ouType][resp.$ou.key] = getUserResponsibilityInfo(
              state,
              resp,
              resp.$ou,
              obj.role
            );
          }
        });
      });
      Object.keys(userInfo.manages).forEach((ouType) => {
        userInfo.manages[ouType] = mapToArray(userInfo.manages[ouType]);
        if (ouType !== 'ANDERE') {
          const mailAddresses = mapToArray(state.userState.ouContactDetails.toJS());
          // const campuses = mapToArray(state.userState.campusses.toJS());
          userInfo.manages[ouType].forEach((management) => {
            const ouMailAddress = mailAddresses.find(
              (cd) => cd.organisationalUnit.href === management.ouPermalink && cd.type === 'MAIL'
            );
            if (ouMailAddress) {
              management.mailAddress = ouMailAddress.value;
            }
            /* else if (ouType === 'SCHOOLENTITY') {
              management.locations = campuses
                .filter(campus => campus.organisationalUnit.href === management.ouPermalink)
                .map(campus => ({ name: campus.name, address: campus.physicalLocation.$$expanded.address }));
            } */
          });
        }
        if (ouType === 'SCHOOLENTITY') {
          userInfo.manages[ouType]
            .filter((mgmt) => mgmt.role === GROUP_MANAGER && !mgmt.startDate)
            .forEach((mgmt) => {
              mgmt.startDate = mgmt.groups
                .map((groupMgmt) => groupMgmt.startDate)
                .reduce((acc, cur) => (cur < acc ? cur : acc));
            });
        }
      });
    }
  }

  if (state.userState.vosOus) {
    state.userState.responsibilities.vos.forEach((resp) => {
      const ou = state.userState.vosOus[resp.organisation.href];
      if (ou && isAfter(ou.$$endDate, getNow())) {
        if (
          ((ou.$$meta.type === 'SCHOOL' && ou.educationLevel !== 'SECUNDAIR') ||
            ou.$$meta.type === 'CVO') &&
          OLD_MIJN_OU_TYPE_CONFIG.SCHOOL.managingPositions.some(
            (posHref) => resp.position && posHref === resp.position.href
          ) &&
          !userInfo.manages.SCHOOL.some((mgmt) => mgmt.ouKey === ou.key)
        ) {
          userInfo.manages.SCHOOL.push({
            name: ou.$$name,
            locations: [{ address: ou.seatAddresses[0].address }],
            ouKey: ou.key,
            institutionNumber: ou.institutionNumber,
            role: 'OLD_MIJN_MANAGER',
            href: `${settings.applications.mijn1}/#/team/${ou.institutionNumber}`,
          });
        } else if (
          (ou.$$meta.type === 'BOARDING' || ou.$$meta.type === 'SCHOOLCOMMUNITY') &&
          OLD_MIJN_OU_TYPE_CONFIG[ou.$$meta.type].managingPositions.some(
            (posHref) => resp.position && posHref === resp.position.href
          ) &&
          !userInfo.manages[ou.$$meta.type].some((mgmt) => mgmt.ouKey === ou.key)
        ) {
          userInfo.manages[ou.$$meta.type].push({
            name: ou.$$name,
            locations: [{ address: ou.seatAddresses[0].address }],
            ouKey: ou.key,
            institutionNumber: ou.institutionNumber,
            role: 'OLD_MIJN_MANAGER',
            href: `${settings.applications.mijn1}/#/team/${ou.institutionNumber}`,
          });
        } else if (ou.$$meta.type === 'ORGANISATION' && ou.type === 'DIENST') {
          let currentTeam = ou;
          while (currentTeam.type === 'DIENST') {
            // eslint-disable-next-line no-loop-func
            const parentRel = state.userState.dienstParentRelations.filter(
              (rel) => rel.from.href === currentTeam.$$meta.permalink
            )[0];
            if (!parentRel) {
              console.error(
                `${currentTeam.$$name} (${currentTeam.$$meta.permalink}) has no active parent!!!!!!!!!!!!!!!!!!!!!! This is a data error!`
              );
              break;
            }
            currentTeam = state.userState.vosOus[parentRel.to.href];
            if (!currentTeam) {
              console.log('geen to van parentRel?', parentRel);
              break;
            }
          }
          if (currentTeam && currentTeam.$$meta.permalink === KATHOLIEK_ONDERWIJS_VLAANDEREN) {
            userInfo.isKathOndVlaEmployee = true;
          }
        }
      }
    });

    // calculate if there should be a Pop-Up for Pro release and which system the manager is in for delegating
    let oldMijnManager = false;
    let newMijnManager = false;
    Object.keys(userInfo.manages).forEach((ouType) => {
      userInfo.manages[ouType].forEach((mgmt) => {
        if (mgmt.role === 'OLD_MIJN_MANAGER') {
          oldMijnManager = true;
        }
        if (mgmt.canGrantAccessToSensitiveInformation) {
          newMijnManager = true;
        }
      });
    });
    const managingSchoolEntititiesWithoutMailAddress = userInfo.manages.SCHOOLENTITY.filter(
      (mgmt) => {
        const contactDetails = mapToArray(state.userState.ouContactDetails.toJS()).filter(
          (cd) => cd.organisationalUnit.href === mgmt.ouPermalink
        );
        return (
          (mgmt.role === MANAGER || mgmt.role === DELEGATED_ADMIN) &&
          (!contactDetails.some((cd) => cd.type === 'MAIL') ||
            !contactDetails.some((cd) => cd.type === 'EMAIL') ||
            !contactDetails.some((cd) => cd.type === 'PHONE') ||
            !contactDetails.some((cd) => cd.type === 'WEBSITE'))
        );
      }
    ).map((mgmt) => ({ name: mgmt.name, href: `${mgmt.href}/contactgegevens`, ouKey: mgmt.ouKey }));
    userInfo.missingContactDetailsModal = {
      managingSchoolEntititiesWithoutMailAddress,
      showModal:
        state.userState.ouContactDetailsFetched &&
        managingSchoolEntititiesWithoutMailAddress.length > 0 &&
        !state.userState.userDidSkipContactDetailsModal,
      modalMissingInformationIsOpened: state.userState.modalMissingInformationIsOpened,
    };

    if (oldMijnManager || newMijnManager) {
      // userInfo.popupTitle = '<h1>Nieuw: Toegang tot gevoelige informatie website</h1>';
      userInfo.popupInfo = `
            <h3>Nieuw: Toegang tot gevoelige informatie website</h3>
            <p>Een leidinggevende heeft automatisch toegang tot gevoelige informatie op onze website. Een administratief verantwoordelijke kan via zijn leidinggevende toegang krijgen.</p>
            <p>Daarnaast kun je als leidinggevende of administratief verantwoordelijke nu ook <strong>extra mensen</strong> van het team toegang geven tot die informatie.</p>
            <strong>Hoe?</strong></br>`;
    }
    if (oldMijnManager && newMijnManager) {
      userInfo.popupInfo += `<ul>
          <li><strong>in het basisonderwijs, volwassenenonderwijs, hoger onderwijs, scholengemeenschap of internaat:</strong> geef je teamleden de functie "Raadpleger gevoelige informatie website".</li>
          <li><strong>in het secundair onderwijs, een CLB of een bestuur: </strong>vul bij het veld "Wie krijgt bijkomend toegang tot gevoelige informatie op de website?" de namen van die teamleden in.</li>
        </ul>`;
    } else if (oldMijnManager) {
      userInfo.popupInfo +=
        '<p>geef je teamleden de functie "Raadpleger gevoelige informatie website"</p>';
    } else if (newMijnManager) {
      userInfo.popupInfo +=
        '<p>vul bij het veld "Wie krijgt bijkomend toegang tot gevoelige informatie op de website?" de namen van die teamleden in.</p>';
    }
  }

  return userInfo;
};

export const getUserTabPermission = (tabName, currentUserRole) => {
  let permission = {
    role: currentUserRole,
    permission: FORBIDDEN,
  };

  if (currentUserRole) {
    const tabPermission = POSSIBLE_MANAGEMENT_TABS[tabName].permissions.find(
      (permissionForTab) => permissionForTab.role === currentUserRole
    );
    permission = tabPermission || permission;
  }

  return permission;
};

export const getManagingResponsibilityOfCurrentOrganisationalUnit = (rootState) => {
  if (!rootState.schoolManager.currentOrganisationalUnit) {
    return null;
  }
  const mgmtsOfCurrentType =
    rootState.vm.user.manages[rootState.vm.schoolManager.currentOU.ouTypeConfigKey];
  if (!mgmtsOfCurrentType) {
    return null;
  }
  const currentMgmt = mgmtsOfCurrentType.find(
    (mgmt) => rootState.vm.schoolManager.currentOU.permalink === mgmt.ouPermalink
  );
  if (!currentMgmt) {
    return null;
  }
  const managingRespHref = currentMgmt.permalink;
  const managingResp =
    rootState.userState.responsibilities.sam.toJS()[managingRespHref] ||
    rootState.userState.responsibilities.vos.find(
      (resp) => resp.$$meta.permalink === managingRespHref
    );
  return managingResp;
};
