import * as ACTIONS from 'ReduxLoop/helpDesk/helpdeskActions';
import { fetchUser } from 'ReduxLoop/user/userActions';
import {
  GOVERNINGINSTITUTION,
  LEERSTEUNCENTRUM,
  LEERPLANCOMMISSIE,
  LERARENNETWERK,
  VERGADERGROEP,
} from 'ReduxLoop/constants';

export class MyHelpdeskSearch {
  constructor($state, $ngRedux, $stateParams) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
    this.searchIcon = require('Img/search.svg');

    this.ouTypes = [
      { type: 'PRIMARY_SCHOOL', description: 'basisscholen' },
      { type: 'SECONDARY_SCHOOL', description: 'secundaire scholen' },
      { type: 'HIGHER_EDUCATION_SCHOOL', description: 'hoger onderwijs' },
      { type: 'CVO', description: `CVO's` },
      { type: 'BOARDING', description: 'internaten' },
      { type: GOVERNINGINSTITUTION, description: 'besturen' },
      { type: 'CLB', description: `CLB's` },
      { type: 'SCHOOLCOMMUNITY', description: 'scholengemeenschappen' },
      { type: LEERSTEUNCENTRUM, description: 'leersteuncentra' },
      { type: 'OTHER', description: 'organisaties' },
      // { type: 'BISDOM', description: 'bisdommen' },
      { type: LERARENNETWERK, description: 'lerarennetwerken' },
      { type: VERGADERGROEP, description: 'vergadergroepen' },
      { type: LEERPLANCOMMISSIE, description: 'leerplancommissies' },
    ];

    this.unsubscribe = this.$ngRedux.connect((state) => {
      /* if (state.vm.helpdesk.ouType !== this.$stateParams.type) {
        const newStateParams = {
          filter: this.$stateParams.filter,
          type: state.vm.helpdesk.ouType
        };
        this.$state.go('.', newStateParams);
      } */
      return {
        user: state.vm.user,
        searchValue: state.vm.helpdesk.searchValue,
        loading: state.vm.helpdesk.loading,
        isMore: state.vm.helpdesk.isMore,
        ouType: state.vm.helpdesk.ouType,
        results: state.vm.helpdesk.results,
      };
    })(this);
  }

  $onInit() {
    if (!this.user.key) {
      this.$ngRedux.dispatch(fetchUser());
    }
    const newStateParams = {};
    if (
      this.$stateParams.type &&
      this.$stateParams.type.toUpperCase() !== this.ouType &&
      this.ouTypes.some((ouType) => ouType.type === this.$stateParams.type.toUpperCase())
    ) {
      this.$ngRedux.dispatch(
        ACTIONS.ouTypeSearchFilterChanged(this.$stateParams.type.toUpperCase())
      );
      newStateParams.type = this.$stateParams.type;
    } else if (!this.ouType) {
      newStateParams.type = 'SECONDARY_SCHOOL'.toLowerCase();
      this.$ngRedux.dispatch(ACTIONS.ouTypeSearchFilterChanged('SECONDARY_SCHOOL'));
    } else if (!this.$stateParams.type || this.$stateParams.type.toUpperCase() !== this.ouType) {
      newStateParams.type = this.ouType.toLowerCase();
    }
    if (this.$stateParams.filter) {
      newStateParams.filter = this.$stateParams.filter;
      this.$ngRedux.dispatch(ACTIONS.searchValueChanged(this.$stateParams.filter));
    } else if (this.searchValue) {
      newStateParams.filter = this.searchValue;
    }
    if (newStateParams.type || newStateParams.searchValue) {
      this.$state.go('.', newStateParams);
    }
  }

  searchValueChanged(ev, value) {
    $('#scroll').scrollTop(0);
    this.$ngRedux.dispatch(ACTIONS.searchValueChanged(value));
    this.$state.go('.', { filter: value });
  }

  selectOuType(ouType) {
    this.$ngRedux.dispatch(ACTIONS.ouTypeSearchFilterChanged(ouType));
    this.$state.go('.', { type: ouType.toLowerCase() });
  }

  typeChanged() {
    this.selectOuType(this.ouType);
    // this.$ngRedux.dispatch(ACTIONS.ouTypeSearchFilterChanged(this.ouType));
    // this.$state.go('.', { type: this.ouType.toLowerCase() });
  }

  goToHome() {
    this.$state.go('home');
  }

  scrolledToBottom() {
    console.log('scrolledToBottom');
    if (this.isMore) {
      this.$ngRedux.dispatch(ACTIONS.showMoreResults());
    }
  }

  isManagedInMijn2(type) {
    return [
      'SCHOOLENTITY',
      GOVERNINGINSTITUTION,
      'CLB',
      'ANDERE',
      LERARENNETWERK,
      VERGADERGROEP,
      LERARENNETWERK,
    ].some((mijn2type) => mijn2type === type);
  }

  goTo(ou) {
    if (this.isManagedInMijn2(ou.$$meta.type)) {
      this.$state.go('school.team', { schoolId: ou.key });
    }
  }
}
