import * as ACTION_TYPES from 'ReduxLoop/constants/actionTypes';

export const addNotification = (message, type, title, timeout, showIcon) => ({
  type: ACTION_TYPES.ADD_NOTIFICATION,
  payload: {
    title,
    message,
    type,
    timeout,
    showIcon,
  },
});

export const addSuccessNotification = (message, title, timeout) => ({
  type: ACTION_TYPES.ADD_SUCCESS_NOTIFICATION,
  payload: {
    title,
    message,
    timeout,
  },
});

export const addInfoNotification = (message, title, timeout) => ({
  type: ACTION_TYPES.ADD_INFO_NOTIFICATION,
  payload: {
    title,
    message,
    timeout,
  },
});

export const addWarningNotification = (message, title) => ({
  type: ACTION_TYPES.ADD_WARNING_NOTIFICATION,
  payload: {
    title,
    message,
  },
});

export const addErrorNotification = (message, title) => ({
  type: ACTION_TYPES.ADD_ERROR_NOTIFICATION,
  payload: {
    title,
    message,
  },
});

export const removeNotification = (notificationKey) => ({
  type: ACTION_TYPES.REMOVE_NOTIFICATION,
  payload: notificationKey,
});

export const clearAllNotifications = () => ({
  type: ACTION_TYPES.CLEAR_ALL_NOTIFICATIONS,
});
