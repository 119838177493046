export const FETCH_USER = 'fetch_user';
export const USER_FETCHED = 'user_fetched';
export const ON_USER_IS_NOT_LOGGED_IN = 'on_user_is_not_logged_in';
export const SET_LOG_IN_CMD = 'set_log_in_cmd';
export const FETCH_USER_RESPONSIBILITIES = 'fetch_user_responsibilities';
export const USER_RESPONSIBILITIES_FETCHED = 'user_responsibilities_fetched';
export const USER_LOGIN_METHOD_FETCHED = 'user_login_method_fetched';
export const USER_LOGIN_METHOD_FAILED = 'user_login_method_failed';
export const IS_USER_KOV_FETCHED = 'is_user_kov_fetched';
export const IS_USER_KOV_FAILED = 'is_user_kov_failed';
export const USER_TARGET_GROUP_FETCHED = 'user_target_group_fetched';
export const USER_TARGET_GROUP_FAILED = 'user_target_group_failed';
export const FETCH_USER_RESPONSIBILITIES_FAILED = 'fetch_user_responsibilities';
export const USER_OU_CONTACTDETAILS_FETHED = 'user_ou_contactdetails_fetched';
export const VOS_OUS_FETCHED = 'vos_ous_fetched';
export const REDIRECT_TO_OAUTH = 'redirect_to_oauth';
export const LOG_OUT = 'log_out';
export const POP_UP_IS_OPENED = 'pop_up_is_opened';

export const FETCH_POSITIONS = 'fetch_positions';
export const POSITIONS_FETCHED = 'positions_fetched';

export const USER_DID_SKIP_CONTACT_DETAILS_MODAL = 'USER_DID_SKIP_CONTACT_DETAILS_MODAL';
export const MODAL_MISSING_INFORMATION_IS_OPENED = 'modal_missing_information_is_opened';

export const fetchUser = (newUser) => ({
  type: FETCH_USER,
  payload: newUser,
});

export const userFetched = (user) => ({
  type: USER_FETCHED,
  payload: user,
});

export const onUserIsNotLoggedIn = () => ({
  type: ON_USER_IS_NOT_LOGGED_IN,
});

export const setLogInCmd = (cmd) => ({
  type: SET_LOG_IN_CMD,
  payload: cmd,
});

export const userResponsibilitiesFetched = (payload) => ({
  type: USER_RESPONSIBILITIES_FETCHED,
  payload,
});

export const userLoginMethodFetched = (payload) => ({
  type: USER_LOGIN_METHOD_FETCHED,
  payload,
});

export const userLoginMethodFailed = (payload) => ({
  type: USER_LOGIN_METHOD_FAILED,
  payload,
});

export const userTargetGroupFetched = (payload) => ({
  type: USER_TARGET_GROUP_FETCHED,
  payload,
});

export const userTargetGroupFailed = (payload) => ({
  type: USER_TARGET_GROUP_FAILED,
  payload,
});

export const fetchUserResponsibilitiesFailed = (payload) => ({
  type: FETCH_USER_RESPONSIBILITIES_FAILED,
  payload,
});

export const userOuContactDetailsFetched = (payload) => ({
  type: USER_OU_CONTACTDETAILS_FETHED,
  payload,
});

export const vosOusFetched = (payload) => ({
  type: VOS_OUS_FETCHED,
  payload,
});

export const fetchPositions = () => ({
  type: FETCH_POSITIONS,
});

export const positionsFetched = (positions) => ({
  type: POSITIONS_FETCHED,
  payload: positions,
});

export const redirectToOauth = () => ({
  type: REDIRECT_TO_OAUTH,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const popUpIsOpened = () => ({
  type: POP_UP_IS_OPENED,
});

export const userDidSkipContactDetailsModal = () => ({
  type: USER_DID_SKIP_CONTACT_DETAILS_MODAL,
});

export const modalMissingInformationIsOpened = () => ({
  type: MODAL_MISSING_INFORMATION_IS_OPENED,
});
