import { AppRoot } from './appRoot';

export default {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  controller: AppRoot,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
