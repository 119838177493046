export class MySearchBar {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.searchIcon = require('Img/search.svg');
  }

  searchValueChanged() {
    this.$scope.$emit('mySearchValueChanged', this.sSearchValue);
  }
}
