import { isEmpty } from 'lodash';
import * as reduxActions from 'ReduxLoop/ouManager/vakGroepen/groupsActions';

require('./groupsTab.scss');

export class GroupsTab {
  constructor($uibModal, $state, $stateParams, $ngRedux) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;

    this.addIcon = require('Img/add.svg');
    this.removeIcon = require('Img/cross.svg');
    this.calendarIcon = require('Img/calendar.svg');
    this.peopleIcon = require('Img/people.svg');
    this.groupsIcon = require('Img/groups.svg');

    this.showDeletedGroups = false;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      school: state.vm.schoolManager.currentOU,
      userRole: state.vm.schoolManager.currentOU.userRole,
      coordinatorPosition: state.vm.groupsTab.coordinatorPosition,
      candidateMembers: state.vm.groupsTab.candidateMembers,
      loadingGroupInfo: state.vm.groupsTab.loadingGroupInfo,
      groupTabs: state.vm.groupsTab.groupInfoTabs,
      groups: state.vm.schoolManager.groups,
      selectedGroup: state.vm.groupsTab.selectedGroup,
      showDeletedGroups: state.vm.groupsTab.showDeletedGroups,
      showGroupInfo: state.vm.groupsTab.groupInfoTabs.some((groupInfoTab) => groupInfoTab.active),
      userCanCreateGroups: state.vm.groupsTab.userCanCreateGroups,
      groupFilter: state.vm.groupsTab.filter,
    }))(this);
  }

  mergeToTarget(nextState, actions) {
    // watch for change, comparing current and next state
    if (nextState.selectedGroup) {
      this.showGroupInfo(nextState.selectedGroup);
    }
    // merge state and actions onto controller
    Object.assign(this, nextState);
    Object.assign(this, actions);
  }

  showGroupInfo(groupKey) {
    this.$state.go('school.group.info', {
      groupId: groupKey,
    });
  }

  $onInit() {
    if (!isEmpty(this.selectedGroup)) {
      const groupKey = this.selectedGroup.permalink.split('/').pop();
      this.$state.go('school.group.info', {
        groupId: groupKey,
      });
      this.showGroupInfo = true;
      this.$ngRedux.dispatch(reduxActions.selectGroupAction({ groupKey }));
    } else if (this.$state.current.name === 'school.group.info') {
      this.showGroupInfo = true;
      this.$ngRedux.dispatch(
        reduxActions.selectGroupAction({ groupKey: this.$stateParams.groupId })
      );
    }
  }

  $doCheck() {
    if (!isEmpty(this.selectedGroup)) {
      const groupKey = this.selectedGroup.permalink.split('/').pop();

      if (this.$stateParams.groupId !== groupKey) {
        this.$state.go('school.group.info', {
          groupId: groupKey,
        });
      }
    } else if (this.$state.current.name !== 'school.group') {
      this.$state.go('school.group');
    }
  }

  applyFilter(e) {
    this.$ngRedux.dispatch(reduxActions.applyFilterAction(this.groupFilter));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  onClickTab(ev, selectedGroup) {
    this.showGroupInfo = true;
    this.$ngRedux.dispatch(
      reduxActions.selectGroupAction({ groupKey: selectedGroup.data.groupId })
    );
  }

  addEditGroup(e, group) {
    const addEditGroupModal = this.addEditGroupModal();

    addEditGroupModal.result.then(
      (modalResult) => {
        if (group) {
          this.$ngRedux.dispatch(reduxActions.editGroupAction(modalResult));
        } else {
          this.$ngRedux.dispatch(reduxActions.addGroupAction(modalResult));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  addEditGroupModal() {
    return this.$uibModal.open({
      animation: true,
      component: 'myAddEditGroupModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        coordinatorPosition: () => this.coordinatorPosition,
        groups: () => this.groups,
        schoolStartDate: () => this.school.startDate,
        schoolEndDate: () => this.school.endDate,
        candidateMembers: () => this.candidateMembers,
      },
    });
  }

  toggleDeletedGroups() {
    this.$ngRedux.dispatch(reduxActions.toggleDeletedGroups());
  }
}
