import { loop, Cmd } from 'redux-loop';
import { settings } from 'Js/config/settings';
import * as ACTION_TYPES from '../../constants/actionTypes';
import * as COMMANDS from './invitationCommands';
import * as ACTIONS from './invitationActions';

const initialState = {
  type: null,
  invitationKey: null,
  invitationMap: null,
  position: null,
  organisationalUnits: [],
  startDate: null,
  user: null,
  missingSchoolIsReported: false,
};

export const invitationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_POSITION_AND_OUS: {
      return loop(
        state,
        Cmd.run(COMMANDS.getPositionAndOus, {
          args: [action.payload.positionKey, action.payload.ouKeys],
          successActionCreator: ACTIONS.positionAndOusFetched,
        })
      );
    }

    case ACTION_TYPES.POSITION_AND_OUS_FETCHED: {
      const newState = {
        ...state,
        position: action.payload.position,
        organisationalUnits: action.payload.ous.map((ou) => ({
          ...ou,
          name: ou.$$meta.type === 'ORGANISATION' ? ou.$$name : ou.$$displayName,
          ouKey: ou.key,
        })),
      };
      return newState;
    }

    /* case ACTION_TYPES.FETCH_INVITATION_CAMPUSSES: {
      return loop(state,
        Cmd.run(getCampusses, {
          args: [state.organisationalUnits],
          successActionCreator: ACTIONS.invitationCampussesFetched
        }));
    } */

    /* case ACTION_TYPES.INVITATION_CAMPUSSES_FETCHED: {
      const newOrganisationalUnits = [];
      state.organisationalUnits.forEach(ou => {
        const ouCampusses = action.payload
          .filter(campus => campus.organisationalUnit.href === ou.$$meta.permalink)
          .map(campus => ({ name: campus.name, address: campus.physicalLocation.$$expanded.address }));
        newOrganisationalUnits.push(Object.assign({}, ou, { campusses: ouCampusses }));
      });
      return Object.assign({}, state, { organisationalUnits: newOrganisationalUnits });
    } */

    case ACTION_TYPES.SET_INVITATIONS: {
      if (action.payload.type === 'INVITATION') {
        return {
          ...state,
          type: 'INVITATION',
          invitationKey: action.payload.invitationKeys,
          startDate: action.payload.startDate,
        };
      }
      const map = {};
      action.payload.invitationKeys.forEach((elem) => {
        map[elem.ou] = {
          invitationKey: elem.key,
          selected: false,
        };
      });
      return { ...state, type: 'MULTIPLE_INVITATIONS', invitationMap: map };
    }

    case ACTION_TYPES.TOGGLE_INVITATION_OU_SELECTED: {
      const invitation = state.invitationMap[action.payload];
      const newInvitation = { ...invitation, selected: !invitation.selected };
      const newInvitationMap = { ...state.invitationMap, [action.payload]: newInvitation };
      return { ...state, invitationMap: newInvitationMap };
    }

    case ACTION_TYPES.CONTACT_HELPDESK: {
      let customRecipient;
      if (
        action.payload.ouType &&
        action.payload.ouType.match(/VERGADERGROEP,LEERPLANCOMMISSIE.*/)
      ) {
        customRecipient = settings.loketResponsible.email;
      } else if (action.payload.ouType && action.payload.ouType.match(/LERARENNETWERK.*/)) {
        customRecipient = 'leraernnetwerk@katholiekonderwijs.vlaanderen';
      }
      return loop(
        state,
        Cmd.run(COMMANDS.sendEmailToOtrsHelpdesk, {
          args: [action.payload, customRecipient],
          successActionCreator: ACTIONS.missingSchoolReported,
        })
      );
    }

    case ACTION_TYPES.MISSING_SCHOOL_REPORTED:
      return { ...state, missingSchoolIsReported: true };

    case ACTION_TYPES.FETCH_INVITED_USER:
      return loop(
        state,
        Cmd.run(COMMANDS.getUser, { successActionCreator: ACTIONS.invitedUserFetched })
      );

    case ACTION_TYPES.INVITED_USER_FETCHED:
      return { ...state, user: action.payload };

    default:
      return state;
  }
};
