// roles
export const SUPER_ADMIN = 'SUPER_ADMIN'; // super user role for our employees who can search in all organisational units and have full access
export const MANAGER = 'MANAGER'; // Manager has all access rights on the organisational unit
export const GROUP_MANAGER = 'GROUP_MANAGER'; // A group manager can only manage some groups that are part of the organisational unit (for example teacher groups)
export const DELEGATED_ADMIN = 'DELEGATED_ADMIN'; // An administrator can do most things, which the manager can do but he can not delegate roles himself
export const DELEGATED_PEDAGOGIC = 'DELEGATED_PEDAGOGIC'; // special for schoolentities: can only manage things that are necessesary for the pedagogical managemnet of the school, not the administrative part
export const STRATEGIC_PURCHASER = 'STRATEGIC_PURCHASER';
export const READ = 'read';
export const READ_WRITE = 'read/write';
export const FORBIDDEN = 'forbidden';

// tabs
export const TEAM_TAB = 'TEAM_TAB';
export const VAKGROEPEN_TAB = 'VAKGROEPEN_TAB';
export const STUDYPROGRAMMES_TAB = 'STUDYPROGRAMMES_TAB';
export const CLASSES_TAB = 'CLASSES_TAB';
export const CONTACTDETAILS_TAB = 'CONTACTDETAILS_TAB';
export const OU_DETAILS_TAB = 'OU_DETAILS_TAB';
export const WORKGROUP_TOOLS_TAB = 'WORKGROUP_TOOLS_TAB';

// genders
export const DE_HEER = 'De heer';
export const MEVROUW = 'Mevrouw';
export const MALE = 'MALE';
export const FEMALE = 'FEMALE';

// positions
export const DIRECTEUR = '/positions/f9435fd7-276e-7007-e040-fd0a049a282a';
export const COORDINATOR = '/positions/f9435fd7-276c-7007-e040-fd0a049a282a';
export const ALGEMEEN_DIRECTEUR = '/positions/f9435fd7-2764-7007-e040-fd0a049a282a';
export const LID = '/positions/1b0f457f-000a-4506-bef9-7376cd2721cf';
export const LERAAR = '/positions/f9435fd7-2772-7007-e040-fd0a049a282a';
export const VOORZITTER = '/positions/f6a2869e-790b-4d55-aae1-838fe1b8f2b4';
export const BESTUURDER = '/positions/f9435fd7-2767-7007-e040-fd0a049a282a';
export const LID_VAN_HET_DAGELIJKS_BESTUUR = '/positions/1ce72593-1b2c-4ea4-8167-95dd7e4d02bb';
export const LID_VAN_DE_ALGEMENE_VERGADERING = '/positions/c5eb7b83-167f-43e5-9efc-22d9231ad76e';
export const CONTACTPERSOON = '/positions/f9435fd7-276a-7007-e040-fd0a049a282a';
export const VSKO_VERANTWOORDELIJKE = '/positions/fc88fbb1-a7f9-1933-e040-007f0101234c';
export const PEDAGOGISCH_VERANTWOORDELIJKE = '/positions/7d3b7af1-b46f-46ce-946b-855eafa2899e';
export const NASCHOLINGSVERANTWOORDELIJKE = '/positions/fb96a800-18b8-e11e-e040-fd0a049a5e9e';
export const RAADPLEGER_GEVOELIGE_INFORMATIE = '/positions/0f61688e-05c2-4e3f-8cea-9da46f5d92cc';
export const BEHEERDER = '/positions/eaefae68-c188-4618-98c6-effdd848faa7';
export const EXTERNE_MEDEWERKER = '/positions/bc464b58-a4c3-11e5-a568-005056872b95';
export const UITTREDEND = '/positions/2aed4278-605e-43a8-b542-6bd37bd080c4';
export const INTREDEND = '/positions/74c74a1e-8c2b-42ec-a48a-99497e8e6808';
export const COORDINEREND_DIRECTEUR = '/positions/f9435fd7-276d-7007-e040-fd0a049a282a';
export const DIRECTEUR_COORDINATIE = '/positions/197d5479-5878-49fd-ba01-97d8685f9f70';
export const TREKKER = '/positions/f0630ffb-c143-45c5-83ba-7737d9f36e52';
export const STRATEGISCH_INKOPER = '/positions/deff6d04-819c-4de2-af55-9cc653371ff4';
export const OPERATIONEEL_INKOPER = '/positions/3e83bceb-b7ff-4f25-8170-67fe75fba61c';
export const STUDENT = '/positions/0f5ad654-2b99-489a-a575-b2eb10654358';
export const TEAMVERANTWOORDELIJKE = '/positions/f2d9f7eb-857d-47b2-9224-6b82e2b9c046';
export const LEIDINGGEVENDE_VAN_DE_VICARIALE_DIENST =
  '/positions/c918ae77-966a-4758-9cbd-e873f4288238';
export const VERANTWOORDELIJKE = '/positions/905118fc-18d2-11e5-99ac-005056872b95';

// ou types
export const SCHOOLENTITY = 'SCHOOLENTITY';
export const BOARDING = 'BOARDING';
export const GOVERNINGINSTITUTION = 'GOVERNINGINSTITUTION';
export const SCHOOLCOMMUNITY = 'SCHOOLCOMMUNITY';
export const LEERSTEUNCENTRUM = 'LEERSTEUNCENTRUM';
export const LERARENNETWERK = 'LERARENNETWERK';
export const VERGADERGROEP = 'VERGADERGROEP';
export const LEERPLANCOMMISSIE = 'LEERPLANCOMMISSIE';
export const ANDERE = 'ANDERE';

// organisational units
export const KATHOLIEK_ONDERWIJS_VLAANDEREN = '/organisations/c000eaea-c451-2590-e044-d4856467bfb8';
export const PEDAGOGISCHE_BEGELEIDING = '/organisations/66bdb2c0-c153-4e01-b3fb-687b342f6501';

export const NEWSLETTER_TYPES = {
  SUBJECTS: '/newsletter/types/974c6b12-2b90-4d8d-a98f-67b2daab0d8b',
  THEMATIC: '/newsletter/types/f64b33d8-dbd9-49b8-845b-4f3f2a6c2b78',
};

export const REGIONS = [
  {
    tag: 'ant',
    href: '/dioceses/1',
    title: 'Antwerpen',
    checked: true,
  },
  {
    tag: 'm-b',
    href: '/dioceses/2',
    title: 'Mechelen-Brussel',
    checked: true,
  },
  {
    tag: 'lim',
    href: '/dioceses/3',
    title: 'Limburg',
    checked: true,
  },
  {
    tag: 'o-vl',
    href: '/dioceses/4',
    title: 'Oost-Vlaanderen',
    checked: true,
  },
  {
    tag: 'w-vl',
    href: '/dioceses/5',
    title: 'West-Vlaanderen',
    checked: true,
  },
];

export const REFERENCE_FRAME_SUBJECTS = {
  sectionCurrVakkenEnLeerplannenOldStructureForThirdGrade:
    '/content/2b254a9a-9774-4869-81fc-133d95cb8568',
  sectionCurrVakkenEnLeerplannenModerniseringSo: '/content/e7c8b4bc-e77e-48f4-b1e8-11c26965fe9f',
};
export const REFERENCE_FRAME_THEMES = [
  '/content/5e88881b-e731-48e8-84c7-344664e93623',
  '/content/52286c99-13b9-4d58-95da-76770012eeb1',
  '/content/8c92170b-f88c-46d0-85eb-0463b152695a',
  '/content/b520c4f2-c409-44a3-87eb-1a93829333bb',
  '/content/41fa46b1-80f0-409c-890b-d4de55a60ca0',
  '/content/da038442-e86b-4f27-92dd-e7ac63af1558',
  '/content/3c1f2b96-3896-41be-8919-8aa69ef6346b',
  '/content/199bcf8c-e6ad-4249-b21f-6541a4f233a0',
  '/content/75f232df-6315-4213-beb8-20e3d8f5defc',
  '/content/8f89ed60-c17a-473e-9058-c27e02312e8c',
];

export const SECONDARY_EDUCATION = '/namedsets/0dba1aec-ee5d-4c85-98ab-1bef27afb673';
export const ANDERE_FUNCTION = '/namedsets/326fcbc1-0805-46dc-9c0d-5ed5e47a9fa2';

export const COOKIE_TYPE = {
  ANALYTIC: 'cookies__analytic', // allow analytics
  CONSENT: 'cookies__consent', // allow cookies
};

export const LOGIN_METHODS = {
  '/loginproviders/9111b767-bba3-48d2-a2e8-dcbe43399e7f': 'kathondvla',
  '/loginproviders/bd0f545a-71a0-11e9-845d-9fdda140c8d8': 'smartschool',
  '/loginproviders/e6d08737-e075-4f27-96ce-66239e10bfe0': 'google',
  '/loginproviders/1b0760e8-71a1-11e9-b9f8-43f786c5ce5d': 'office365',
};

export const ORDERED_USER_TARGET_GROUPS = [
  '/namedsets/813197eb-a73b-4183-9072-d3b5d1041f38', // bestuurder
  '/namedsets/c952becd-8e84-4eda-b0d7-967e7033d6da', // directeur
  '/namedsets/2eac4ef8-f06f-41ac-a676-579b9eaa1b6a', // middenkader
  '/namedsets/451cca0f-5fd4-445a-ac56-e6b3de012e15', // leraar
  '/namedsets/e56c4d81-8bf5-4f6c-a36b-0604f37f94ca', // preventie-adviseur
  '/namedsets/9e4b4caf-6cd4-42aa-8ba9-92e819b54b10', // ondersteunend personeel
];

// TODO add anonymous events as 'create_account'.
export const ALLOWED_ANONYMOUS_EVENTS = ['create_account'];
