require('./myDelegateRole.scss');

const matchConfirmedMemberName = (member, str) => {
  const words = str.split(' ');
  return (
    member.type === 'RESPONSIBILITY' &&
    !words.some((word) => !new RegExp(word, 'gi').test(member.familiarName))
  );
};

export class MyDelegateRole {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  filterMembers(data, str) {
    return data.filter((member) => {
      return matchConfirmedMemberName(member, str);
    });
  }

  displayMember(member) {
    if (!member) {
      return null;
    }
    return `${member.familiarName}`;
  }

  delegeeSelected(event, member) {
    this.$scope.$emit('onDelegeeSelected', {
      delegation: this.sDelegation,
      member,
    });
  }

  delegeeRemoved(event, member) {
    this.$scope.$emit('onDelegeeRemoved', {
      delegation: this.sDelegation,
      member,
    });
  }
}
