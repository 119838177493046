export const FETCH_WORKGROUP_INFO = 'fetch_workgroup_info';
export const fetchWorkgroupInfo = () => ({
  type: FETCH_WORKGROUP_INFO,
});

export const WORKGROUP_INFO_FETCHED = 'workgroup_info_fetched';
export const workgroupInfoFetched = (payload) => ({
  type: WORKGROUP_INFO_FETCHED,
  payload,
});

export const ADD_MEMBER_OU = 'add_member_ou';
export const addMemberOu = (memberOu) => ({
  type: ADD_MEMBER_OU,
  payload: memberOu,
});

export const ADD_MEMBER_OU_FAILED = 'add_member_ou_failed';
export const addMemberOuFailed = (error, memberRelation) => ({
  type: ADD_MEMBER_OU_FAILED,
  payload: {
    error,
    memberRelation,
  },
});

export const REMOVE_MEMBER_OU = 'remove_member_ou';
export const removeMemberOu = (memberOu) => ({
  type: REMOVE_MEMBER_OU,
  payload: memberOu,
});

export const REMOVE_MEMBER_OU_FAILED = 'remove_member_ou_failed';
export const removeMemberOuFailed = (error, memberRelation) => ({
  type: REMOVE_MEMBER_OU_FAILED,
  payload: {
    error,
    memberRelation,
  },
});
