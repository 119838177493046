require('./myNewsletterSubjectList.scss');
const { NEWSLETTER_TYPES } = require('ReduxLoop/constants');

class MyNewsletterSubjects {
  constructor($scope, $window) {
    'ngInject';

    this.$scope = $scope;
    this.ceil = $window.Math.ceil;
  }

  subjectChanged(e, selected, name) {
    console.log('subjectChange');
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.SUBJECTS,
      selected,
      name,
      'SUBJECTS'
    );
  }

  showAll(e) {
    if (this.sSubjects.every((subject) => subject.checked)) {
      this.$scope.$emit('newsletters:showAllSelected');
    } else {
      this.$scope.$emit('newsletters:showAllDeselected');
    }
  }

  showLess(e) {
    console.log('newsletters:showLessSelected');
    if (this.sSubjects.every((subject) => subject.checked)) {
      this.$scope.$emit('newsletters:showLessSelected');
    } else {
      this.$scope.$emit('newsletters:showLessDeselected');
    }
  }
}

module.exports = MyNewsletterSubjects;
