import { ChooseRespModal } from './chooseRespModal';

require('./chooseRespModal.scss');

export const myChooseRespModalComponent = {
  template: require('./chooseRespModal.html'),
  controllerAs: 'ctrl',
  controller: ChooseRespModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
