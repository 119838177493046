import { Cmd, loop } from 'redux-loop';
import * as ACTIONS from 'ReduxLoop/piwikAnalytics/piwikAnalyticsActions';
import { analyticCookiesAccepted } from 'ReduxLoop/utils/cookiesUtils';
import { sendPiwikEvent } from './piwikAnalyticsCommands';
import { ALLOWED_ANONYMOUS_EVENTS } from 'ReduxLoop/constants';

const INITIAL_COMMON_PROPERTIES = {
  user_id: undefined,
  user_login_method: undefined,
  user_region: undefined,
  user_edu_level: undefined,
  user_target_group: undefined,
  page_hostname: undefined,
  page_pathname: undefined,
  page_referrer: undefined,
  page_template: undefined,
  page_title: undefined,
  page_category_1: undefined,
  page_category_2: undefined,
  page_category_3: undefined,
  page_category_4: undefined,
  page_category_5: undefined,
  page_category_6: undefined,
  // At page level, MIJN doesn't have "denied" access status, so page_access will always be "granted"...
  page_access: 'granted',
  // ... and it doesn't have a any 404 page either, so it page_status_code will always be a 200.
  page_status_code: 200,
};

const initialState = {
  commonProperties: INITIAL_COMMON_PROPERTIES,
  isPiwikTagReady: false,
};

export const piwikAnalyticsReducer = (state = initialState, action, rootState) => {
  const { eventName, props } = action.payload || {};

  switch (action.type) {
    case ACTIONS.UPDATE_IS_PIWIK_TAG_READY: {
      state.isPiwikTagReady = action.payload;
      return state;
    }

    case ACTIONS.TRACK_PIWIK_EVENT: {
      if (
        state.isPiwikTagReady &&
        analyticCookiesAccepted() &&
        (rootState.userState.user || ALLOWED_ANONYMOUS_EVENTS.includes(eventName))
      ) {
        return loop(
          state,
          Cmd.run(sendPiwikEvent, {
            args: [eventName, rootState, props],
            successActionCreator: ACTIONS.sendPiwikEventSuccess,
            failActionCreator: ACTIONS.sendPiwikEventError,
          })
        );
      }
    }

    case ACTIONS.SEND_PIWIK_EVENT_SUCCESS: {
      return state;
    }

    case ACTIONS.SEND_PIWIK_EVENT_ERROR: {
      console.error('ERROR: SEND_PIWIK_EVENT failed', action.payload);
      return state;
    }

    default:
      return state;
  }
};
