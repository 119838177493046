import { loop, Cmd } from 'redux-loop';
import { Map, fromJS } from 'immutable';
import * as ACTION_TYPES from 'ReduxLoop/constants/actionTypes';
import * as COMMANDS from 'ReduxLoop/utils';
import * as ACTIONS from 'ReduxLoop/notifications/notificationActions';

const sriUtils = require('@kathondvla/sri-client/common-utils');

const initialState = {
  notificationList: Map(),
};

const createNewNotification = (state, newNotification) => {
  const notificationList = state.notificationList.toJS();

  if (
    Object.values(notificationList).find(
      (notification) =>
        notification.message === newNotification.message &&
        notification.type === newNotification.type
    )
  ) {
    return state;
  }

  newNotification.key = sriUtils.generateUUID();

  const nextState = {
    ...state,
    notificationList: state.notificationList.set(newNotification.key, fromJS(newNotification)),
  };

  if (!newNotification.timeout) {
    return { ...nextState };
  }
  return loop(
    nextState,
    Cmd.run(COMMANDS.doTimeout, {
      args: [newNotification.timeout],
      successActionCreator: () => ACTIONS.removeNotification(newNotification.key),
    })
  );
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_NOTIFICATION: {
      const newNotification = Object.assign(action.payload, {
        type: action.payload.type,
        timeout: action.payload.timeout,
        showIcon: action.payload.showIcon,
      });
      return createNewNotification(state, newNotification);
    }

    case ACTION_TYPES.ADD_SUCCESS_NOTIFICATION: {
      const newNotification = Object.assign(action.payload, {
        type: 'SUCCESS',
        timeout: action.payload.timeout ? action.payload.timeout : 5000 + (action.payload.message.length / 100) * 5000,
        showIcon: false,
      });
      return createNewNotification(state, newNotification);
    }

    case ACTION_TYPES.ADD_INFO_NOTIFICATION: {
      const newNotification = Object.assign(action.payload, {
        type: 'INFO',
        showIcon: true,
      });
      return createNewNotification(state, newNotification);
    }

    case ACTION_TYPES.ADD_WARNING_NOTIFICATION: {
      const newNotification = Object.assign(action.payload, {
        type: 'WARNING',
        timeout: 30000,
        showIcon: true,
      });
      return createNewNotification(state, newNotification);
    }

    case ACTION_TYPES.ADD_ERROR_NOTIFICATION: {
      const newNotification = Object.assign(action.payload, {
        type: 'ERROR',
        timeout: 60000,
        showIcon: true,
      });
      return createNewNotification(state, newNotification);
    }

    case ACTION_TYPES.REMOVE_NOTIFICATION: {
      return { ...state, notificationList: state.notificationList.delete(action.payload) };
    }

    case ACTION_TYPES.CLEAR_ALL_NOTIFICATIONS: {
      return { ...state, notificationList: Map() };
    }

    default:
      return state;
  }
};
