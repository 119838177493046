import { sriClient } from 'ReduxLoop/utils/apiConfig';
import * as ACTIONS from 'ReduxLoop/ouManager/contactDetails/contactDetailsActions';
import { emailAddressPattern } from '../../module/personUtils';

require('./contactDetailsTab.scss');

export class ContactDetailsTab {
  constructor($scope, $uibModal, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;
    this.sriClient = sriClient;
    this.mailIcon = require('Img/mail.svg');

    this.emailPattern = {
      pattern: emailAddressPattern,
      message: 'Dit is geen geldig e-mailadres',
    };

    this.websitePattern = {
      pattern:
        /^(http(s)?:\/\/)?(w{3}\.)?([-a-zA-Z0-9@:%._+~#=]{2,256})(\.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))+$/,
      message: 'Dit is geen geldige website',
    };

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ou: state.vm.schoolManager.currentOU,
      userRole: state.vm.schoolManager.currentOU.userRole,
      governingInstitution: state.vm.schoolManager.currentOU.governingInstitution,
      contactDetails: { ...state.vm.contactDetailsTab },
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.$scope.$on('onFormValidationChanged', (event, payload) => {
      this.$ngRedux.dispatch(ACTIONS.updateContactDetailsForm(payload));
    });
  }

  emailChanged(e, emailAddress) {
    this.$ngRedux.dispatch(ACTIONS.updateEmail(emailAddress));
  }

  phoneChanged(e, phone) {
    this.$ngRedux.dispatch(ACTIONS.updatePhone(phone));
  }

  websiteChanged(e, website) {
    this.$ngRedux.dispatch(ACTIONS.updateWebsite(website));
  }

  addressChanged(e, address) {
    this.$ngRedux.dispatch(ACTIONS.updateAddress(address));
  }
}
