import React from 'react';
import { createRoot } from 'react-dom/client';

function toBindings(propTypes) {
  const bindings = {};
  Object.keys(propTypes).forEach((key) => (bindings[key] = '<'));
  return bindings;
}

function toProps(propTypes, controller) {
  const props = {};
  Object.keys(propTypes).forEach((key) => (props[key] = controller[key]));
  return props;
}

export function reactToAngularComponent(Component, Provider) {
  const propTypes = Component.propTypes || {};

  return {
    bindings: toBindings(propTypes),
    controller: /* @ngInject */ function ctrl($element) {
      const root = createRoot($element[0]);
      let rootIsMounted = true;
      this.$onChanges = () => {
        if (rootIsMounted) {
          const props = toProps(propTypes, this);

          root.render(
            <>
              {Provider && (
                <Provider>
                  <Component {...props} />
                </Provider>
              )}
              {!Provider && <Component {...props} />}
            </>
          );
        }
      };
      this.$onDestroy = () => {
        rootIsMounted = false;
        root.unmount();
      };
    },
  };
}

function serviceInjector(name) {
  const injector = angular.element(document.body).injector();
  if (!injector || !injector.get) {
    throw new Error(`Couldn't find angular injector to get "${name}" service`);
  }

  const service = injector.get(name);
  if (!service) {
    throw new Error(`Couldn't find "${name}" angular service`);
  }

  return service;
}

export function getAngularService(...names) {
  const services = names.map((name) => serviceInjector(name));
  return names?.length === 1 ? services[0] : services;
}
