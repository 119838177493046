import { InvitationModal } from './invitationModal';

export const myInvitationModalComponent = {
  template: require('./invitationModal.html'),
  controllerAs: 'ctrl',
  controller: InvitationModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
