export class MyUnsubscribeModal {
  constructor($scope, $ngRedux, $uibModal) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.mailIcon = require('Img/mail.svg');
    this.atLeastOneValidReason = false;
    this.reasons = [];
  }

  reasonChanged(_event, value, reasonKey) {
    if (value) {
      this.reasons.push(reasonKey);
    } else {
      this.reasons = this.reasons.filter((key) => key !== reasonKey);
    }

    if (reasonKey === 'andere') {
      this.otherReason = value;
      this.otherReasonText = '';
    }

    this.atLeastOneValidReason =
      this.reasons.length > 0 &&
      (!this.reasons.find((key) => key === 'andere') ||
        (this.reasons.find((key) => key === 'andere') && this.otherReasonText.length > 0));
  }

  otherReasonChanged() {
    this.atLeastOneValidReason = this.otherReasonText.length > 0;
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  save() {
    this.close({
      $value: {
        customReason: this.otherReasonText,
        reasons: this.reasons.filter((reason) => reason !== 'andere'),
      },
    });
  }
}
