import { myCreateLinkModalComponent } from 'Js/myScreenComponents/mySmTeamTab/myCreateLinkModal';

import { myOuDetailsTab } from 'Js/myScreenComponents/mySmOuDetails';
import { myOuToolsTab } from 'Js/myScreenComponents/mySmOuToolsTab';
import { myCreateTeamComponent } from 'Js/myScreenComponents/myCreateTeam';
import { myProfileComponent } from 'Js/myScreenComponents/myProfile';
import { myChangePasswordComponent } from 'Js/myScreenComponents/myProfile/changePassword';
import { myEditBiographyComponent } from 'Js/myScreenComponents/myProfile/myEditBiography';
import { myTeamInfoComponent } from 'Js/myScreenComponents/myProfile/teamInfoModal';
import { myRequestResponsibility } from 'Js/myScreenComponents/myRequestResponsibility';
import { myConfirmRequestComponent } from 'Js/myScreenComponents/MyRequestResponsibilityApprove';
import { myLinkAccounts } from 'Js/myScreenComponents/oauthScreens/linkAccounts';
import {
  myAskPasswordResetLink,
  myAskPasswordResetLinkSent,
  myChangePasswordFromLink,
  myChangePasswordFromLinkSuccess
} from 'Js/myScreenComponents/oauthScreens/passwordReset';
import { myHelpdeskSearchComponent } from 'Js/myScreenComponents/myHelpdeskSearch';
import { myNewsletterPreferencesComponent } from 'Js/myScreenComponents/myNewsletterPreferences';
import { myThemanieuwsbriefSectionComponent } from 'Js/myScreenComponents/myNewsletterPreferences/myThemanieuwsbriefSection';
import { myVakkennieuwsbriefSectionComponent } from 'Js/myScreenComponents/myNewsletterPreferences/myVakkennieuwsbriefSection';
import { myUnsubscribeModalComponent } from 'Js/myScreenComponents/myNewsletterPreferences/myUnsubscribeModal';
import { myNewsletterUnsubscriptionComponent } from 'Js/myScreenComponents/myNewsletterUnsubscription';
import { myGroupInfoComponent } from './mySmGroupsTab/myGroupInfo';
import { myGroupsTabComponent } from './mySmGroupsTab';
import { myAddEditGroupModalComponent } from './mySmGroupsTab/myAddEditGroupModal';

import { myKlassenTabComponent } from './mySmKlassenTab';
import { myStudyProgrammesTabComponent } from './mySmStudyProgrammesTab';
import { myAddEditClassModal } from './mySmKlassenTab/myAddEditClassModal';
import { myContactDetailsTabComponent } from './mySmContactDetails';

import { myTeamTabComponent } from './mySmTeamTab';
import { myInvitationModalComponent } from './mySmTeamTab/myInvitationModal';

import { mySchoolManager } from './mySchoolManager';
import { myConfirmInvitationOuSelector } from './myConfirmInvitationOuSelector';
import { myHomeOuSelectorComponent } from './myHomeOuSelector';
import { myHome } from './myHome';
import { myMissingContactDetailsModalComponent } from './myHome/myMissingContactDetailsModal';

import { myAanmeldenComponent } from './myAanmelden';
import { myRegistreren } from './myRegistreren';
import { myConfirmNoRegistrationNumberComponent } from './myRegistreren/myRegisterConfirmNoRegistrationNumber';
import { myRegisterConfirmEmailComponent } from './myRegistreren/myRegisterConfirmEmail';
import { myConfirmInvitationComponent } from './myConfirmInvitation';
import { myContactHelpdeskComponent } from './myContactHelpdesk';
import { myRegistrerenShowDuplicateComponent } from './myRegistrerenShowDuplicate';
import { myAcceptTermsComponent } from './myRegistrerenAcceptTerms';
import { myRegisterActivateComponent } from './myRegistrerenActivate';

import { myOuAdminForm } from './myOuAdminFormNg';
import { myRelationsComponents } from './myOuAdminFormNg/relationsComponents';
import { myLocationsComponent } from './myOuAdminFormNg/locationsComponent';

const app = require('../app/app');

app.component('appRoot', require('./appRoot').default);

app.component('myHome', myHome);

app.component('myConfirmInvitationOuSelector', myConfirmInvitationOuSelector);
app.component('myHomeOuSelector', myHomeOuSelectorComponent);
app.component('myCreateTeam', myCreateTeamComponent);
app.component('mySchoolManager', mySchoolManager);
app.component('mySchoolManagerTeam', myTeamTabComponent);
app.component('myInvitationModal', myInvitationModalComponent);
app.component('myCreateLinkModal', myCreateLinkModalComponent);
app.component('myMissingContactDetailsModal', myMissingContactDetailsModalComponent);

app.component('mySchoolManagerGroups', myGroupsTabComponent);
app.component('mySchoolManagerGroupInfo', myGroupInfoComponent);
app.component('myAddEditGroupModal', myAddEditGroupModalComponent);

app.component('mySchoolManagerStudyProgrammes', myStudyProgrammesTabComponent);

app.component('mySchoolManagerKlassen', myKlassenTabComponent);
app.component('myAddEditClassModal', myAddEditClassModal);

app.component('mySchoolManagerContactDetails', myContactDetailsTabComponent);

app.component('mySchoolManagerOuDetails', myOuDetailsTab);
app.component('mySchoolManagerOuTools', myOuToolsTab);
app.component('myAanmelden', myAanmeldenComponent);
app.component('myRegistreren', myRegistreren);
app.component('myConfirmNoRegistrationNumber', myConfirmNoRegistrationNumberComponent);
app.component('myRegisterConfirmEmail', myRegisterConfirmEmailComponent);
app.component('myRegistrerenAcceptTerms', myAcceptTermsComponent);
app.component('myConfirmInvitation', myConfirmInvitationComponent);
app.component('myContactHelpdesk', myContactHelpdeskComponent);
app.component('myRegisterMailSend', require('./myMailSendConfirmed'));

app.component('myRegistrerenShowDuplicate', myRegistrerenShowDuplicateComponent);
app.component('myRegisterActivate', myRegisterActivateComponent);
app.component('myProfile', myProfileComponent);
app.component('myChangePassword', myChangePasswordComponent);
app.component('myEditBiography', myEditBiographyComponent);
app.component('myTeamInfo', myTeamInfoComponent);

app.component('myRequestResponsibility', myRequestResponsibility);
app.component('myConfirmRequest', myConfirmRequestComponent);
app.component('myLinkAccounts', myLinkAccounts);
app.component('myAskPasswordResetLink', myAskPasswordResetLink);
app.component('myAskPasswordResetLinkSent', myAskPasswordResetLinkSent);
app.component('myChangePasswordFromLink', myChangePasswordFromLink);
app.component('myChangePasswordFromLinkSuccess', myChangePasswordFromLinkSuccess);
app.component('myHelpdeskSearch', myHelpdeskSearchComponent);

app.component('myOuAdminForm', myOuAdminForm);
app.component('adminRelationsComponents', myRelationsComponents);
app.component('adminLocationsComponent', myLocationsComponent);

app.component('myNewsletterPreferences', myNewsletterPreferencesComponent);
app.component('myThemanieuwsbriefSection', myThemanieuwsbriefSectionComponent);
app.component('myVakkennieuwsbriefSection', myVakkennieuwsbriefSectionComponent);
app.component('myUnsubscribeModal', myUnsubscribeModalComponent);
app.component('myNewsletterUnsubscription', myNewsletterUnsubscriptionComponent);
