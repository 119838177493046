import { MyUnsubscribeModal } from './myUnsubscribeModal';

require('./myUnsubscribeModal.scss');

export const myUnsubscribeModalComponent = {
  template: require('./myUnsubscribeModal.html'),
  controllerAs: 'ctrl',
  controller: MyUnsubscribeModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
