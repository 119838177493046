import { ConfirmationModal } from './myConfirmModal';

export const myConfirmModal = {
  template: require('./myConfirmModal.html'),
  controllerAs: 'ctrl',
  controller: ConfirmationModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
