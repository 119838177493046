export const getResponsibilityPermalink = (ouSearchDocument) => {
  if (
    ['SCHOOL', 'BOARDING', 'SCHOOLCOMMUNITY'].some((ouType) => ouType === ouSearchDocument.types[0])
  ) {
    return ouSearchDocument.$$meta.vosPermalink;
  }
  return ouSearchDocument.$$meta.permalink;
};

export const getPositionDefinition = (position, ouType, mainstructures) => {
  return position.definitions.filter(
    (definition) =>
      definition.applicableIn.ouTypes.some((type) => ouType === type) &&
      (!definition.applicableIn.mainstructures ||
        !mainstructures ||
        mainstructures.some((ms) =>
          definition.applicableIn.mainstructures.some((defMs) => defMs === ms)
        ))
  )[0];
};

export const getRequestDescription = (request) => {
  const ouName = request.organisationalUnit.$$expanded.$$displayName
    ? request.organisationalUnit.$$expanded.$$displayName
    : request.organisationalUnit.$$expanded.$$name;
  return `${request.from.$$expanded.firstName} ${request.from.$$expanded.lastName}${
    request.position ? ` als ${request.position.$$expanded.name}` : ''
  } in ${ouName}`;
};
