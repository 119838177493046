import { printAddress } from '@kathondvla/sri-client/address-utils';
import { printDate } from '@kathondvla/sri-client/date-utils';

require('./myOuList.scss');

const CLICK_ON_OU = 'clickOnOu';

export class MyOuList {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.confirmIcon = require('Img/check.svg');
    this.selected = {};
  }

  $onInit() {
    if (this.sSelectedKeys) {
      this.selected = this.sSelectedKeys.reduce((acc, cur) => ({ ...acc, [cur]: true }), {});
    }
  }

  printAddress(address) {
    return printAddress(address);
  }

  printDate(date) {
    if (!date) {
      return null;
    }
    return printDate(date);
  }

  onSelectOu(ev, ouKey) {
    this.selected[ouKey] = !this.selected[ouKey];
    this.$scope.$emit(CLICK_ON_OU, ouKey);
  }
}
