import React from 'react';

import './Checkbox.scss';

const Checkbox = ({ id, label, checked, className = '', disabled, onChange }) => {
  const onChangeHandler = (ev) => {
    onChange(ev.target.checked);
  };

  return (
    <div className={`custom-checkbox ${className}`}>
      <div className="checkbox-wrapper">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChangeHandler}
        />
        <span className="checkmark"></span>
      </div>
      {label && (
        <label className="labeltext" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
