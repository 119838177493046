const mock = require('./group.data');

const service = () => {
  'ngInject';

  const getGroups = () => {
    return mock.groups;
  };

  const getGroup = (groupKey) => {
    return mock.groups.find((group) => group.key === groupKey);
  };

  return {
    getGroup,
    getGroups,
  };
};

angular.module('myServices').factory('groupService', service);
