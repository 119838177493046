import { logOut } from 'ReduxLoop/user/userActions';

export class MyHeaderBar {
  constructor($state, $ngRedux) {
    'ngInject';

    this.$state = $state;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        user: state.vm.user,
      };
    })(this);
  }

  goToHome() {
    this.$state.go('home');
  }

  userLogsOut() {
    this.$ngRedux.dispatch(logOut());
  }

  userLogsIn() {
    console.log('user clicked login');
    this.$ngRedux.dispatch(logOut());
  }
}
