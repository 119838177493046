import { toggleInvitationOuSelected } from 'ReduxLoop/registerAndConfirmInvitation/inviteConfirmation/invitationActions';

export class OuSelector {
  constructor($state, $ngRedux, $timeout) {
    'ngInject';

    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$timeout = $timeout;

    this.arrowRightIcon = require('Img/arrow-right.svg');

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      user: state.invite.user,
      position: state.invite.position,
      ous: state.invite.organisationalUnits,
      invitationMap: state.invite.invitationMap,
      isSelected:
        state.invite.invitationMap &&
        Object.keys(state.invite.invitationMap).some(
          (ou) => state.invite.invitationMap[ou].selected
        ),
      missingSchoolIsReported: state.invite.missingSchoolIsReported,
    }))(this);
  }

  $onInit() {
    if (this.invitationMap) {
      this.selectedKeys = Object.keys(this.invitationMap).filter(
        (ouKey) => this.invitationMap[ouKey].selected
      );
    }
  }

  clickOnOu(ev, ouKey) {
    this.$ngRedux.dispatch(toggleInvitationOuSelected(ouKey));
    if (this.ous.length === 1) {
      this.$state.go('acceptInvitation');
    }
  }

  onConfirmSelection() {
    this.$state.go('acceptInvitation');
  }

  notDirector() {
    console.warn(
      `NOT_A_DIRECTOR: director ${this.user.username} - ${
        this.user.uuid
      } clicked on I am not a director for invitations ${Object.keys(this.invitationMap)}`
    );
    this.notDirectorConfirmed = true;
    this.$timeout(() => {
      this.timeoutOver = true;
    }, 1000);
  }

  reportMissingSchool() {
    this.$state.go('contactHelpdesk', { type: 'school-ontbreekt' });
  }

  $onDestroy() {
    this.unsubscribe();
  }
}
