import { ORDERED_USER_TARGET_GROUPS } from 'ReduxLoop/constants';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { KATHOLIEK_ONDERWIJS_VLAANDEREN } from '../constants';

/**
 * This function extract and map to an object the information we need from the targetGroups API response.
 * @param {*} targetGroups
 * @returns
 */
const getTargetGroupsRelevantInformation = (targetGroups) =>
  targetGroups
    .filter((tg) => ORDERED_USER_TARGET_GROUPS.includes(tg.$$meta.permalink)) // take only the relevant target Groups for piwik
    .map((targetGroup) => ({
      name: targetGroup.name,
      href: targetGroup.$$meta.permalink,
      positions: targetGroup.selectors
        ?.filter((tgSelector) => tgSelector.type === 'FIXED_LIST')
        .map((tgSelector) => tgSelector.value)
        .flat()
        .map((tgSelector) => ({
          onlyAppliesForOUTypes: tgSelector.ouTypes,
          href: tgSelector.position,
        })),
    }))
    .filter((targetGroup) => targetGroup.positions);

const getOuType = (ou) => {
  if (ou.$$meta.type === 'CVO') {
    // CVO's are schools of mainstructure 317, 417, 618 but are a separate $$meta.type in VOS
    return 'SCHOOL';
  }
  return ou.type || ou.$$meta.type;
};

const checkIsKathOndVlaEmployee = (userResponsibilities, ous, dienstParentRelations) =>
  userResponsibilities.some((userResp) => {
    let rootOu = ous[userResp.organisation.href];
    while (rootOu && rootOu.type === 'DIENST') {
      const parentRel = dienstParentRelations.find(
        (rel) => rel.from.href === rootOu.$$meta.permalink
      );
      rootOu = parentRel && ous[parentRel.to.href];
    }
    return rootOu && rootOu.$$meta.permalink === KATHOLIEK_ONDERWIJS_VLAANDEREN;
  });

export const getUserTargetGroup = (state) => {
  const { userResponsibilities, targetGroups, ous, dienstParentRelations } = state;
  // If the user has a responsibility in the organisation of 'Katholiek Onderwijs Vlaanderen' or any of its suborganisations, the user will be labeled as 'Medewerker'
  const isKathOndVlaEmployee = checkIsKathOndVlaEmployee(
    userResponsibilities,
    ous,
    dienstParentRelations
  );
  if (isKathOndVlaEmployee) {
    return { name: 'medewerker' };
  }
  const userTargetGroups =
    (targetGroups?.length && getTargetGroupsRelevantInformation(targetGroups)) || [];

  // We cross the positions of the user with the target groups and the organisation types for which they apply,
  // and order them based on the criteria provided for this property.
  // Finally, we return only the first positions, which will be the "highest" position that the user has towards an institution
  return userTargetGroups
    .filter((targetGroup) =>
      targetGroup.positions.some((tgPosition) =>
        userResponsibilities.some((userResp) => {
          const positionMatch = userResp.position && userResp.position.href === tgPosition.href;
          if (!positionMatch) return false;
          if (!tgPosition.onlyAppliesForOUTypes) return true;
          const ou = ous[userResp.organisation.href];
          const ouType = getOuType(ou);
          return tgPosition.onlyAppliesForOUTypes?.includes(ouType);
        })
      )
    )
    .sort(
      (a, b) =>
        ORDERED_USER_TARGET_GROUPS.indexOf(a.href) - ORDERED_USER_TARGET_GROUPS.indexOf(b.href)
    )[0];
};

/**
 * Method to return a valid user_target_group for an user with only one responsibility.
 */
export const getUserTargetGroupOneResp = async (positionKey, ouType) => {
  if (!positionKey || !ouType) return;
  const targetGroups = await sriClient.getAll('/namedsets', {
    tags: 'doelgroepen',
  });

  const userTargetGroups =
    (targetGroups?.length && getTargetGroupsRelevantInformation(targetGroups)) || [];

  return userTargetGroups.find((targetGroup) =>
    targetGroup.positions.some((tgPosition) => {
      const positionMatch = positionKey === tgPosition.href.split('/').pop();
      if (!positionMatch) return false;
      if (!tgPosition.onlyAppliesForOUTypes) return true;
      return tgPosition.onlyAppliesForOUTypes?.includes(ouType);
    })
  );
};
