import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';

export const haveCommonItems = (array1, array2) => array1.some((x) => array2.includes(x));
export const commonItems = (array1, array2) => array1.filter((x) => array2.includes(x.href));

export function buildPreferences(storedPreferences, preferences) {
  if (!storedPreferences) {
    return { ...preferences };
  }

  preferences.coverage.forEach((region) => {
    const item = storedPreferences.coverage.find((i) => i.href === region.href);
    region.checked = !!item;
    region.locked = item && item.locked;
  });

  if (storedPreferences.positions) {
    preferences.positions.forEach((position) => {
      const item = storedPreferences.positions.find((i) => i.href === position.href);
      position.checked = !!item;
      position.locked = item && item.locked;
    });
  }

  if (preferences.themes) {
    preferences.themes.forEach((theme) => {
      const item = storedPreferences.themes.find(
        (i) => i.href === theme.href || (theme.hrefs && theme.hrefs.includes(i.href))
      );
      theme.checked = !!item;
      theme.locked = item && item.locked;
    });
  } else {
    preferences.subjects = storedPreferences.themes.map((theme) => ({
      ...theme,
      checked: !!theme,
      locked: false,
    }));
  }

  if (storedPreferences.newsletterType.href === NEWSLETTER_TYPES.THEMATIC) {
    preferences.educationLevels.forEach((educationLevel) => {
      const item = storedPreferences.mainstructuresOuTypeCombinations.find(
        (i) => i.href === educationLevel.href
      );
      educationLevel.checked = !!item;
      educationLevel.locked = item && item.locked;
    });
  }

  return {
    ...preferences,
    key: storedPreferences.key,
    newsletterType: storedPreferences.newsletterType,
    subscribed: storedPreferences.subscribed,
    subscribedJobOffers: storedPreferences.subscribedJobOffers,
  };
}

export function buildNewsletterPreferences(
  storedPreferences,
  thematicPreferences,
  subjectsPreferences
) {
  const storedSubjectsNewletterPreferences = storedPreferences.find(
    (preferences) => preferences.newsletterType.href === NEWSLETTER_TYPES.SUBJECTS
  );

  const thematicNewsletterPreferences = buildPreferences(
    storedPreferences.find(
      (preferences) => preferences.newsletterType.href === NEWSLETTER_TYPES.THEMATIC
    ),
    thematicPreferences
  );
  const subjectsNewsletterPreferences = buildPreferences(
    storedSubjectsNewletterPreferences,
    subjectsPreferences
  );

  if (!storedSubjectsNewletterPreferences) {
    subjectsNewsletterPreferences.coverage.forEach((region) => {
      const item = thematicNewsletterPreferences.coverage.find((i) => i.href === region.href);
      region.checked = item && item.checked;
      region.locked = item && item.locked;
    });

    subjectsNewsletterPreferences.educationLevels.forEach((edl) => {
      const item = thematicNewsletterPreferences.educationLevels.find((e) => e.href === edl.href);
      edl.checked = item && item.checked;
      edl.locked = item && item.locked;
    });
  }

  return [thematicNewsletterPreferences, subjectsNewsletterPreferences];
}
