import { getNow, getActiveResources } from '@kathondvla/sri-client/date-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  GOVERNINGINSTITUTION,
  // LEERSTEUNCENTRUM,
  LEERPLANCOMMISSIE,
  LERARENNETWERK,
  VERGADERGROEP,
} from 'ReduxLoop/constants';

export const searchOus = async (type) => {
  return sriClient.getRaw('/search', {
    types: 'organisational_unit',
    organisationalUnitTypes: type,
    limit: 20,
  });
};

export const getAllSecondarySchools = async () => {
  const schools = await sriClient.getAll('/sam/organisationalunits', {
    type: 'SCHOOLENTITY',
    havingMainstructure: '311,312,316,321',
    endDateAfter: getNow(),
    limit: 5000,
  });

  const campuses = await sriClient.getAll('/sam/organisationalunits/locations', {
    'organisationalUnit.type': 'SCHOOLENTITY',
    expand: 'results.physicalLocation',
    endDateAfter: getNow(),
    limit: 5000,
  });

  return {
    schools,
    campuses,
  };
};

export const getAllSamOus = async () => {
  const ous = await sriClient.getAll('/sam/organisationalunits', {
    typeIn: 'CLB,GOVERNINGINSTITUTION,LEERSTEUNCENTRUM',
    endDateAfter: getNow(),
    limit: 5000,
  });

  const mailAddresses = await sriClient.getAll(
    '/sam/organisationalunits/contactdetails',
    {
      type: 'MAIL',
      organisationalUnit: ous.map((ou) => ou.$$meta.permalink),
      limit: 5000,
    },
    {
      inBatch: '/sam/organisationalunits/batch',
    }
  );

  const institutionNumbers = await sriClient.getAll(
    '/sam/organisationalunits/externalidentifiers',
    {
      type: 'INSTITUTION_NUMBER',
      organisationalUnit: ous.map((ou) => ou.$$meta.permalink),
      limit: 5000,
    },
    {
      inBatch: '/sam/organisationalunits/batch',
    }
  );

  return {
    ous,
    mailAddresses,
    institutionNumbers,
  };
};

export const getSchoolsFromVos = (ouType) => {
  return sriClient.getAll('/schools', {
    statuses: 'ACTIVE,FUTURE',
    educationLevels: ouType === 'PRIMARY_SCHOOL' ? 'KLEUTER,LAGER' : 'HOGER,UNIVERSITAIR',
  });
};

export const getOusFromVos = (ouType) => {
  if (ouType === 'CVO') {
    return sriClient.getAll('/cvos', { statuses: 'ACTIVE,FUTURE', limit: 5000 });
  }
  if (ouType === 'BOARDING') {
    return sriClient.getAll('/boardings', { statuses: 'ACTIVE,FUTURE', limit: 5000 });
  }
  if (ouType === GOVERNINGINSTITUTION) {
    console.log(
      'Komen we hier nog?????????????????????????????????????? Dat is de vos zoeker voor besturen!'
    );
    return sriClient.getAll('/governinginstitutions', { statuses: 'ACTIVE,FUTURE', limit: 5000 });
  }
  if (ouType === 'SCHOOLCOMMUNITY') {
    return sriClient.getAll('/schoolcommunities', { statuses: 'ACTIVE,FUTURE', limit: 5000 });
  }
  if (ouType === 'CLB') {
    console.log('Komen we hier nog??????????????????????????????????????');
    return sriClient.getAll('/cvos', { statuses: 'ACTIVE,FUTURE', limit: 5000 });
  }
  if (ouType === 'OTHER') {
    return sriClient.getAll('/organisations', {
      organisationTypes: 'ANDERE',
      statuses: 'ACTIVE,FUTURE',
      limit: 5000,
    });
  }
  if ([LERARENNETWERK, VERGADERGROEP, LEERPLANCOMMISSIE].some((type) => type === ouType)) {
    return sriClient.getAll('/organisations', {
      organisationTypes: ouType,
      statuses: 'ACTIVE,FUTURE',
      limit: 5000,
    });
  }
  throw new Error({ message: `${ouType} is not supported as an ou type` });
};

export const getOuByInstitutionNumber = (institutionNumber) => {
  return sriClient.getAll('/sam/organisationalunits/externalidentifiers', {
    value: institutionNumber,
    expand: 'results.organisationalUnit',
  });
};

export const getAllMissingSchools = async (ou, institutionNumber, state) => {
  const ret = {};
  let eLevelString = null;
  if (!state.primarySchools && !state.higherEducationSchools) {
    eLevelString = 'KLEUTER,LAGER,HOGER,UNIVERSITAIR';
  } else if (!state.primarySchools) {
    eLevelString = 'KLEUTER,LAGER';
  } else if (!state.higherEducationSchools) {
    eLevelString = 'HOGER,UNIVERSITAIR';
  }
  if (eLevelString) {
    const vosSchools = await sriClient.getAll('/schools', {
      statuses: 'ACTIVE,FUTURE',
      educationLevels: eLevelString,
      limit: 5000,
    });
    if (!state.primarySchools) {
      ret.primarySchools = vosSchools.filter((school) => school.educationLevel === 'BASIS');
    }
    if (!state.higherEducationSchools) {
      ret.higherEducationSchools = vosSchools.filter((school) => school.educationLevel === 'HOGER');
    }
  }
  const primarySchools = state.primarySchools || ret.primarySchools;
  const higherEducationSchools = state.higherEducationSchools || ret.higherEducationSchools;
  const foundPrimary = primarySchools.filter(
    (school) => school.institutionNumber === institutionNumber
  )[0];
  if (foundPrimary) {
    ret.result = foundPrimary;
    ret.educationLevel = 'PRIMARY_SCHOOL';
    return ret;
  }
  const foundHigher = higherEducationSchools.filter(
    (school) => school.institutionNumber === institutionNumber
  )[0];
  if (foundHigher) {
    ret.result = foundHigher;
    ret.educationLevel = 'HIGHER_EDUCATION_SCHOOL';
    console.log('we doen ret!');
    return ret;
  }
  let { cvos } = state;
  if (!cvos) {
    cvos = await sriClient.getAll('/cvos', { statuses: 'ACTIVE,FUTURE' });
    ret.cvos = cvos;
  }
  const foundCvo = cvos.filter((school) => school.institutionNumber === institutionNumber)[0];
  if (foundCvo) {
    ret.result = foundCvo;
    ret.educationLevel = 'CVO';
    return ret;
  }
  if (!state.secondarySchools) {
    ret.secondarySchoolInfo = await getAllSecondarySchools();
  }
  let foundSecondary = null;
  try {
    foundSecondary = await sriClient.get(`/schools/${institutionNumber}`);
  } catch (err) {
    console.warn(`${institutionNumber} can not be traced to a school...`);
  }
  if (foundSecondary) {
    ret.result = foundSecondary;
    ret.educationLevel = 'SECONDARY_SCHOOL';
    // const secondarySchoolPermalink = '/sam/organisationalunits/' + foundSecondary.key;
    const canActRelations = await sriClient.getAll('/sam/organisationalunits/relations', {
      type: 'CAN_ACT_AS',
      to: ou.$$meta.permalink,
    });
    const relatedSchoolEntitites = getActiveResources(canActRelations).map((rel) => rel.from.href);
    ret.relatedSchoolEntities = relatedSchoolEntitites;
  }

  return ret;
};
