import { isBeforeOrEqual } from '@kathondvla/sri-client/date-utils';
import { sriClient } from './apiConfig';

export * from '../ouManager/vakGroepen/groupsCommands';
export * from '../ouManager/classes/classesCommands';
export * from '../ouManager/schoolManagerCommands';

const Batch = require('@kathondvla/sri-client/batch');

export const storeResource = (resource) => {
  return sriClient.put(resource.$$meta.permalink, resource);
};

export const storeResourcesConcurent = async (resourcesList) => {
  const errors = [];
  for (const resource of resourcesList) {
    try {
      await sriClient.put(resource.$$meta.permalink, resource);
    } catch (err) {
      errors.push({
        resource,
        error: err,
      });
    }
  }
  if (errors.length > 0) {
    throw errors;
  }
};

export const endResource = (resource) => {
  if (isBeforeOrEqual(resource.endDate, resource.startDate)) {
    return sriClient.delete(resource.$$meta.permalink);
  }
  return sriClient.put(resource.$$meta.permalink, resource);
};

export const removeResource = (resourcePermalink) => {
  return sriClient.delete(resourcePermalink);
};

export const storeResources = (batchPath, resources) => {
  const batch = new Batch(sriClient);
  resources.forEach((r) => batch.put(r));

  return batch.send(batchPath, sriClient);
};

export const removeResources = (batchPath, resources) => {
  const batch = new Batch(sriClient);
  resources.forEach((r) => batch.delete(r));

  return batch.send(batchPath, sriClient);
};

export const persistResponsibilities = (respsToUpdate, respHrefsToDelete) => {
  const batch = new Batch();

  respsToUpdate.forEach((resp) => batch.put(resp));
  if (respHrefsToDelete) {
    respHrefsToDelete.forEach((href) => batch.delete(href));
  }

  return sriClient.put('/persons/batch', batch.array);
};

export const goTo = (url) => {
  window.location.href = url;
};

export const doTimeout = (timeout) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, timeout);
  });
};

export const fetchResource = (href) => {
  return sriClient.get(href);
};

export const getRaw = (href, params) => {
  return sriClient.getRaw(href, params);
};

export const getAll = (path, params) => {
  return sriClient.getAll(path, params);
};
