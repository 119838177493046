import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { NEWSLETTER_TYPES, SECONDARY_EDUCATION } from '../constants';

const createItem = (id, locked = false, type = false) => {
  const item = type
    ? {
        type: id,
      }
    : {
        href: id,
      };

  return locked
    ? {
        ...item,
        locked: true,
      }
    : item;
};

export const createPreferencesBody = (preferences, storedPreferences, user) => {
  if (!storedPreferences && !preferences.subscribed) {
    return undefined; // Explicitly return undefined
  }

  const coverage = preferences.coverage
    .filter((preference) => preference.checked)
    .map((preference) => createItem(preference.href, preference.locked));
  const positions = preferences.positions
    .filter((position) => position.checked)
    .map((position) => createItem(position.href, position.locked));

  let themes;

  if (preferences.themes) {
    themes = preferences.themes.filter((t) => t.checked).map((t) => createItem(t.href));
  } else {
    themes = preferences.subjects.filter((s) => s.checked).map((s) => createItem(s.href));
  }

  const educationLevels = preferences.educationLevels
    .filter((educationLevel) => educationLevel.checked)
    .map((educationLevel) => createItem(educationLevel.href, educationLevel.locked));

  const key = storedPreferences ? storedPreferences.key : generateUUID();

  return {
    key,
    href: `/newsletter/preferences/${key}`,
    person: { href: `/persons/${user}` },
    newsletterType: preferences.newsletterType,
    mainstructuresOuTypeCombinations:
      // Always set secondary education as level for the curriculum newsletter preferences.
      preferences.newsletterType.href === NEWSLETTER_TYPES.SUBJECTS
        ? [{ href: SECONDARY_EDUCATION }]
        : educationLevels,
    coverage,
    positions,
    themes,
    subscribed: preferences.subscribed,
    subscribedJobOffers: true, // preferences.subscribedJobOffers
  };
};

export const createUnsubscriptionBody = (unsubscription, user) => {
  const key = generateUUID();
  return {
    key,
    href: `/newsletter/unsubscriptions/${key}`,
    person: { href: `/persons/${user}` },
    newsletterTypes: unsubscription.newsletterTypes.map((newsletterTypeHref) => {
      return {
        href: newsletterTypeHref,
      };
    }),
    reasons: unsubscription.predefinedReasons
      .map((predefinedReasonKey) => {
        return {
          href: `/newsletter/unsubscriptions/reasons/${predefinedReasonKey}`,
        };
      })
      .concat(
        unsubscription.customReason
          ? [
              {
                text: unsubscription.customReason,
              },
            ]
          : []
      ),
  };
};

/**
 * Helper function to add scrollIntoView behaviour for links on kov-notifications messages
 * @param {string} linkClassName
 */
export const createKovNotificationsLinkScrolling = (linkClassName) => {
  document
    .getElementsByClassName('notification-list')[0] // kov-notifications class name present in the DOM.
    .addEventListener('click', function (event) {
      // Filtering only clicks on linkClassName.
      if (event.target.classList.contains(linkClassName)) {
        // Calculating header height:
        const headerHeight = document.querySelector('.headerbar-container').offsetHeight;

        // Calculating "INSCHRIJVEN" section position:
        const optionsSectionPosition = document
          .getElementById('input-options-section')
          .getBoundingClientRect().top;

        window.scrollTo({
          top: optionsSectionPosition - headerHeight,
          behavior: 'smooth',
        });
      }
    });
};
