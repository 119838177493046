const svgStyle = {
  width: '16px',
  height: '16px',
  verticalAlign: 'text-top',
  marginRight: '10px',
};

const customStyle = {
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 'unset',
    height: '100%',
    position: 'static',
    svg: svgStyle,
  }),
  menu: (provided) => ({
    ...provided,
    // border: 'none',
    marginTop: '1px',
    overflow: 'inherit !important',
    position: 'absolute',
    width: 'max-content',
    minWidth: '100%',
    zIndex: 100,
  }),
  menuList: (provided) => ({
    ...provided,
    background: '#FFF',
    border: 'none',
    padding: '5px 5px !important',
    width: '100%',
    textAlign: 'left',
    boxShadow: '0 5px 17px 0 rgb(0 0 0 / 20%)',
    overflowY: 'auto',
    svg: svgStyle,
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    padding: '7px 10px',
    cursor: isFocused ? 'pointer' : 'default',
    background: isFocused || isSelected ? '#9B009B' : '',
    borderBottom: '1px solid #E7E7E8',
    color: isFocused || isSelected ? '#fff' : '#333',
    fontFamily: 'Roboto, Helvetica Neue, Helvetica, sans-serif !important',
    fontSize: '14px',
    fontWeight: 400,
    transition: 'all .2s',
  }),
  container: (provided) => ({
    ...provided,
    margin: 'unset',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#777777',
    fontSize: '14px',
    fontWeight: 400,
  }),
  control: (provided) => ({
    ...provided,
    paddingLeft: '10px',
    minHeight: '36px',
    // height: '36px',
    // height: '40px',
    // minHeight: 'unset',
    '.subtitle': { display: 'none' },
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: 'calc(100% - 35px)',
  }),
};

export default customStyle;
