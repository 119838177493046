import * as ACTION_TYPES from '../../constants/actionTypes';

export const getUserNewsletterPreferences = () => ({
  type: ACTION_TYPES.NEWSLETTER_USER_PREFERENCES,
});

export const userNewsletterPreferencesFecthed = (newsletterPreferences) => ({
  type: ACTION_TYPES.NEWSLETTER_USER_PREFERENCES_FETCHED,
  payload: newsletterPreferences,
});

export const getNewsletterOptions = () => ({
  type: ACTION_TYPES.NEWSLETTER_GET_OPTIONS,
});

export const newsletterOptionsFecthed = (newsletterOptions) => ({
  type: ACTION_TYPES.NEWSLETTER_OPTIONS_FETCHED,
  payload: newsletterOptions,
});

export const newsletterSetOption = (value, newsletterType, id, optionType, property) => ({
  type: ACTION_TYPES.NEWSLETTER_SET_OPTION,
  payload: { value, newsletterType, id, optionType, property: property || 'href' },
});

export const newsletterSetSubscriptions = (value) => ({
  type: ACTION_TYPES.NEWSLETTER_SET_SUBSCRIPTIONS,
  payload: { value },
});

export const persistNewsletterChanges = (newsletterType) => ({
  type: ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES,
  payload: {
    newsletterType,
  },
});

export const persistNewsletterChangesFailed = (error) => ({
  type: ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES_FAILED,
  payload: error,
});

export const persistNewsletterChangesSucceeded = (body) => ({
  type: ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES_SUCCEEDED,
  payload: body,
});

export const persistNewslettersSubscriptions = () => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS,
});

export const persistNewslettersSubscriptionsFailed = (error) => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS_FAILED,
  payload: error,
});

export const persistNewslettersSubscriptionsSucceeded = () => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS_SUCCEEDED,
});

export const persistNewslettersUnsubscription = (unsubscription) => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION,
  payload: unsubscription,
});

export const persistNewslettersUnsubscriptionFailed = (error, unsubscription) => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_FAILED,
  payload: {
    unsubscription,
    error,
  },
});

export const persistNewslettersUnsubscriptionSucceeded = () => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_SUCCEEDED,
});

export const persistNewslettersUnsubscriptionReasons = (
  newsletterTypes,
  predefinedReasons,
  customReason
) => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS,
  payload: { newsletterTypes, predefinedReasons, customReason },
});

export const persistNewslettersUnsubscriptionReasonsFailed = (error, unsubscription) => ({
  type: ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS_FAILED,
  payload: {
    unsubscription,
    error,
  },
});

export const newsletterShowAllSelectedSujects = () => ({
  type: ACTION_TYPES.NEWSLETTER_SHOW_ALL_SELECTED_SUBJECTS,
});

export const newsletterShowAllDeselectedSujects = () => ({
  type: ACTION_TYPES.NEWSLETTER_SHOW_ALL_DESELECTED_SUBJECTS,
});

export const newsletterShowLessSelectedSujects = () => ({
  type: ACTION_TYPES.NEWSLETTER_SHOW_LESS_SELECTED_SUBJECTS,
});

export const newsletterShowLessDeselectedSujects = () => ({
  type: ACTION_TYPES.NEWSLETTER_SHOW_LESS_DESELECTED_SUBJECTS,
});

export const newsletterFilterDeselectedSujects = (text) => ({
  type: ACTION_TYPES.NEWSLETTER_FILTER_DESELECTED_SUBJECTS,
  payload: text,
});

export const unsubscribeModalOpened = () => ({
  type: ACTION_TYPES.UNSUBSCRIBE_MODEL_OPENED,
})
