// This contains most code for supporting OAuth, translations, and remote logging.

module.exports = [
  '$provide',
  '$stateProvider',
  '$urlRouterProvider',
  'settings',
  function ($provide, $stateProvider, $urlRouterProvider, settings) {
    require('./vskoconfig')($provide, settings);
    require('./routes')($stateProvider, $urlRouterProvider);
  },
];
