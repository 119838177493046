import { interceptFactory } from '@kathondvla/fetch-oauth-interceptor';
import { settings } from 'Js/config/settings';

import { getStore } from 'ReduxLoop/store';
import { fetchUser, onUserIsNotLoggedIn } from 'ReduxLoop/user/userActions';

interceptFactory({
  urlPatterns: [new RegExp(settings.apis.kathOndVla, 'g'), new RegExp(settings.apis.samApi, 'g')],
  oauth: settings.oauth,
  ignore: {
    get: [
      /\/positions/g,
      /\/namedsets/g,
      /\/sam\/organisationalunits/g,
      /\/organisations/g,
      /\/search/g,
      /\/newsletter\/unsubscriptions\/reasons/g,
    ],
    post: [/\/persons\/register/g, /\/persons\/generateusername/g, /\/persons\/contact-helpdesk/g, /\/persons\/ask-password-reset-link/g, /\/persons\/changepassword-with-token/g],
    put: [
      /\/newsletter\/anonymousunsubscribe\/batch/g,
      /\/newsletter\/anonymousunsubscriptions\/batch/g,
    ],
  },
  onUserChanged: (newUser) => {
    getStore().dispatch(fetchUser(newUser));
  },
  onLoggedOut: () => {
    getStore().dispatch(onUserIsNotLoggedIn());
  },
});

export const sriClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apis.kathOndVla,
});

export const cachedSriClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apis.cachedKathOndVla,
});

export const securityClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apis.security,
});

export const security2Client = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.apis.security.replace('nodejs', 'nodejs2'),
});

export const oauthClient = require('@kathondvla/sri-client/fetch-sri-client')({
  baseUrl: settings.oauth.oauthURL,
});

const getAuthenticateUrl = () => {
  return `${settings.oauth.authenticate}?scope=${
    settings.oauth.scope
  }&response_type=none&client_id=${settings.oauth.clientID}&redirect_uri=${
    settings.oauth.redirectUri
  }&state=${encodeURIComponent(window.location.href)}`;
};

export const goToOauthLogin = () => {
  const redirectTo = getAuthenticateUrl();
  console.log('redirect to', redirectTo);
  window.location.href = redirectTo;
};

export const goToLogOut = async () => {
  await oauthClient.get('', undefined, { baseUrl: settings.oauth.logOut, credentials: 'include' });
  goToOauthLogin();
  /* await oauthClient.get('', undefined, {baseUrl: getAuthenticateUrl(), credentials: 'include'});
    window.location.href = settings.oauth.logOut; */
};
