import {
  goFromConfirmRequestToTeam,
  initialiseHandleRequest,
} from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';

export class MyConfirmRequest {
  constructor($scope, $ngRedux, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.successIcon = require('Img/check.svg');
    this.failIcon = require('Img/cross.svg');
    this.homeIcon = require('Img/home-icon-grey.svg');
    this.teamIcon = require('Img/people.svg');
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$stateParams = $stateParams;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      this.showIcon = null;
      this.showTitle = null;
      if (state.vm.requestResponsibility.confirmErrors.length > 0) {
        this.showTitle = 'Mislukt!';
        this.showIcon = this.failIcon;
      } else if (
        state.vm.requestResponsibility.success &&
        this.$stateParams.actie === 'goedkeuren'
      ) {
        this.showTitle = 'Gelukt!';
        this.showIcon = this.successIcon;
      }
      return {
        ...state.vm.requestResponsibility,
        user: state.vm.user,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.$ngRedux.dispatch(initialiseHandleRequest(this.$stateParams));
  }

  goToTeam() {
    this.$ngRedux.dispatch(goFromConfirmRequestToTeam());
  }

  goToHome() {
    this.$state.go('home');
  }
}
