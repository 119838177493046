import { MyEditBiography } from './myEditBiography';

export const myEditBiographyComponent = {
  template: require('./myEditBiography.html'),
  controllerAs: 'ctrl',
  controller: MyEditBiography,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
