const MyTextTruncator = require('./myTextTruncator');

module.exports = {
  template: require('./myTextTruncator.html'),
  controllerAs: 'ctrl',
  controller: MyTextTruncator,
  transclude: true,
  bindings: {
    sTooltip: '@',
  },
};
