import { Cmd, loop } from 'redux-loop';
import { formatPhoneNumber } from '@kathondvla/sri-client/contactdetail-utils';
import {
  getPreviousDay,
  isBeforeOrEqual,
  isBefore,
  getNow,
} from '@kathondvla/sri-client/date-utils';
import * as COMMANDS from 'ReduxLoop/profile/profileCommands';
import * as USER_ACTIONS from 'ReduxLoop/user/userActions';
import * as ACTIONS from 'ReduxLoop/profile/profileActions';
import {
  addSuccessNotification,
  addWarningNotification,
  clearAllNotifications,
  addErrorNotification,
  addNotification,
} from 'ReduxLoop/notifications/notificationActions';
import { arrayToMap } from 'ReduxLoop/index';
import {
  getDateOfBirthFromRegistrationNumber,
  getTitleErrors,
  getDateOfBirthErrors,
} from 'Js/module/personUtils';
import { DE_HEER, FEMALE, MALE, MEVROUW } from 'ReduxLoop/constants';
import {
  getPersonsBatch,
  createEmailAddress,
  createPhone,
  createAddress,
  createBankAccount,
} from 'ReduxLoop/utils/personResourceFactory';
import { pendingRequestsFetched } from 'ReduxLoop/profile/profileActions';
import { mapToArray } from 'ReduxLoop/viewModel';
import { formatAndValidateIban } from 'ReduxLoop/utils/stringUtils';
import {
  REQUEST_RESPONSIBILITY_SUCCEEDED,
  TRY_CREATING_RESPONSIBILITY_SUCCEEDED,
} from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import * as GLOBAL_COMMANDS from '../utils';
import { sendRemindersForRequestResponsibility } from './profileCommands';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

const initialState = {
  selectedPersonKey: undefined,
  person: null,
  contactDetailsFetched: false,
  fetchContactDetailsOnUserFetched: false,
  primaryEmail: null,
  secondaryEmail: null,
  officeAddress: null,
  officePhone: null,
  officeMobilePhone: null,
  personalAddress: null,
  personalPhone: null,
  personalMobilePhone: null,
  expensesBankAccount: null,
  showPersonalContactDetails: false,
  responsibilities: {
    sam: null,
    vos: null,
  },
  pendingRequests: {
    sam: {},
    vos: {},
  },
  ous: {
    sam: null,
    vos: null,
    groupParentRelations: null,
    dienstParentRelations: null,
  },
  account: null,
  hasKathOndVlaMailbox: false,
  profileForm: null,
  titleErrors: [],
  dateOfBirthErrors: [],
  reservedRegistrationNumbers: [],
  freeUsernames: [],
  reservedUsernames: [],
  freeEmailAddresses: [],
  reservedEmailAddresses: [],
  officePhoneErrors: [],
  officeMobilePhoneErrors: [],
  personalPhoneErrors: [],
  personalMobilePhoneErrors: [],
  ibanExpenseErrors: [],
  lastSavedState: null,
};

export const profileReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    // FETCHING
    case ACTIONS.SELECT_PROFILE: {
      const newState = { ...state, selectedPersonKey: action.payload };
      const cmds = [];
      if (!rootState.userState.user) {
        // init application from the profile page
        const cmdList = [
          Cmd.action(USER_ACTIONS.fetchUser()),
          Cmd.action(USER_ACTIONS.fetchPositions()),
        ];
        if (action.payload) {
          cmdList.push(
            Cmd.run(COMMANDS.getProfileInfo, {
              args: [action.payload],
              successActionCreator: ACTIONS.profileInfoFetched,
            })
          );
        } else {
          newState.fetchContactDetailsOnUserFetched = true;
        }
        return loop(newState, Cmd.list(cmdList));
      }
      if (!rootState.userState.positions) {
        cmds.push(Cmd.action(USER_ACTIONS.fetchPositions()));
      }
      if (state.selectedPersonKey === action.payload) {
        // user just came to profile page but nothing has changed since last time he was here.
        if (!state.selectedPersonKey && !state.contactDetailsFetched) {
          cmds.push(
            Cmd.run(COMMANDS.getContactDetails, {
              args: [rootState.userState.user.$$meta.permalink],
              successActionCreator: (contactDetails) =>
                ACTIONS.profileInfoFetched({
                  person: rootState.userState.user,
                  contactDetails,
                }),
            })
          );
          cmds.push(
            Cmd.run(COMMANDS.getBankAccounts, {
              args: [rootState.userState.user.$$meta.permalink],
              successActionCreator: ACTIONS.bankAccountsFetched,
            })
          );
        }
        if (cmds.length > 0) {
          return loop(state, Cmd.list(cmds));
        }
        return state;
      }
      if (!action.payload || action.payload === rootState.userState.user.key) {
        // switchted back to user profile from strangers profile
        cmds.push(
          Cmd.run(COMMANDS.getProfileInfo, {
            args: [action.payload],
            successActionCreator: ACTIONS.profileInfoFetched,
          })
        );
        return loop(
          {
            ...initialState,
            selectedPersonKey: action.payload,
            responsibilities: {
              sam: rootState.userState.responsibilities.sam.toJS(),
              vos: rootState.userState.responsibilities.responsibilities,
            },
            ous: {
              sam: rootState.userState.ous.toJS(),
              vos: rootState.userState.vosOus,
              groupParentRelations: rootState.userState.parentRelations,
              dienstParentRelations: rootState.userState.dienstParentRelations,
            },
          },
          Cmd.list(cmds)
        );
      }
      // else a strangers profile should be fetched which comes from the url parameter key
      cmds.push(
        Cmd.run(COMMANDS.getProfileInfo, {
          args: [action.payload],
          successActionCreator: ACTIONS.profileInfoFetched,
        })
      );
      return loop(
        { ...initialState, selectedPersonKey: action.payload, profileForm: state.profileForm },
        Cmd.list(cmds)
      );
    }

    case USER_ACTIONS.USER_FETCHED: {
      if (state.selectedPersonKey || !state.fetchContactDetailsOnUserFetched) {
        return state;
      }
      return loop(
        state,
        Cmd.list([
          Cmd.run(COMMANDS.getContactDetails, {
            args: [action.payload.$$meta.permalink],
            successActionCreator: (contactDetails) =>
              ACTIONS.profileInfoFetched({
                person: action.payload,
                contactDetails,
              }),
          }),
          Cmd.run(COMMANDS.getBankAccounts, {
            args: [action.payload.$$meta.permalink],
            successActionCreator: ACTIONS.bankAccountsFetched,
          }),
        ])
      );
    }

    case ACTIONS.PROFILE_INFO_FETCHED: {
      const newState = {
        ...state,
        person: action.payload.person,
        contactDetailsFetched: true,
        freeUsernames: [action.payload.person.username],
        primaryEmail: action.payload.contactDetails.filter(
          (cd) => cd.type === 'EMAILADDRESS_PRIMARY'
        )[0],
        secondaryEmail: action.payload.contactDetails.filter(
          (cd) => cd.type === 'EMAILADDRESS_OFFICE'
        )[0],
        officeAddress: action.payload.contactDetails.filter(
          (cd) => cd.type === 'ADDRESS_OFFICE'
        )[0],
        officePhone: action.payload.contactDetails.filter((cd) => cd.type === 'PHONE_OFFICE')[0],
        officeMobilePhone: action.payload.contactDetails.filter(
          (cd) => cd.type === 'PHONE_MOBILE_OFFICE'
        )[0],
        personalAddress: action.payload.contactDetails.filter(
          (cd) => cd.type === 'ADDRESS_PERSONAL'
        )[0],
        personalPhone: action.payload.contactDetails.filter(
          (cd) => cd.type === 'PHONE_PERSONAL'
        )[0],
        personalMobilePhone: action.payload.contactDetails.filter(
          (cd) => cd.type === 'PHONE_MOBILE'
        )[0],
        expensesBankAccount: action.payload.bankAccounts
          ? action.payload.bankAccounts.filter((ba) => ba.type === 'BANKACCOUNT_EXPENSE_NOTES')[0]
          : state.expensesBankAccount,
      };
      newState.showPersonalContactDetails =
        newState.personalAddress || newState.personalPhone || newState.personalMobilePhone;
      delete newState.lastSavedState;
      newState.lastSavedState = newState;
      const commandList = [
        Cmd.run(COMMANDS.hasKathOndVlaMailbox, {
          args: [action.payload.person.$$meta.permalink],
          successActionCreator: ACTIONS.securityMailboxFetched,
        }),
      ];
      if (newState.primaryEmail) {
        commandList.push(
          Cmd.run(COMMANDS.validateEmailAddress, {
            args: [newState.primaryEmail, action.payload.person.$$meta.permalink],
            successActionCreator: () => ACTIONS.emailAddressIsFree(newState.primaryEmail, true),
            failActionCreator: (duplicateError) =>
              ACTIONS.emailAddressIsReserved(
                newState.primaryEmail,
                'primair e-mailaderes',
                duplicateError,
                true
              ),
          })
        );
      }
      if (newState.secondaryEmail) {
        commandList.push(
          Cmd.run(COMMANDS.validateEmailAddress, {
            args: [newState.secondaryEmail, action.payload.person.$$meta.permalink],
            successActionCreator: () => ACTIONS.emailAddressIsFree(newState.secondaryEmail, true),
            failActionCreator: (duplicateError) =>
              ACTIONS.emailAddressIsReserved(
                newState.secondaryEmail,
                'reserve e-mailaderes',
                duplicateError,
                true
              ),
          })
        );
      }
      commandList.push(
        Cmd.run(COMMANDS.getAccountInfo, {
          args: [action.payload.person.$$meta.permalink],
          successActionCreator: ACTIONS.accountInfoFetched,
        })
      );
      if (
        state.selectedPersonKey &&
        (!rootState.userState.user || state.selectedPersonKey !== rootState.userState.user.key)
      ) {
        commandList.push(
          Cmd.run(COMMANDS.getProfileTeamInfo, {
            args: [action.payload.person.$$meta.permalink],
            successActionCreator: ACTIONS.profileTeamInfoFetched,
          })
        );
      }
      return loop(newState, Cmd.list(commandList));
    }

    case ACTIONS.BANKACCOUNTS_FETCHED: {
      const newState = {
        ...state,
        expensesBankAccount: action.payload.filter(
          (ba) => ba.type === 'BANKACCOUNT_EXPENSE_NOTES'
        )[0],
      };
      delete newState.lastSavedState;
      newState.lastSavedState = newState;
      return newState;
    }

    case ACTIONS.PROFILE_TEAM_INFO_FETCHED: {
      const vosResps = arrayToMap(
        action.payload.responsibilities.filter((resp) => !resp.organisation.href.match(/^\/sam\//g))
      );
      const samOus = arrayToMap(action.payload.samOus);
      const vosOus = arrayToMap(action.payload.vosOus);
      return loop(
        {
          ...state,
          responsibilities: {
            sam: arrayToMap(
              action.payload.responsibilities.filter((resp) =>
                resp.organisation.href.match(/^\/sam\//g)
              )
            ),
            vos: vosResps,
          },
          ous: {
            sam: samOus,
            vos: vosOus,
            groupParentRelations: arrayToMap(action.payload.groupParentRelations),
            dienstParentRelations: state.ous.dienstParentRelations,
          },
        },
        Cmd.list([
          Cmd.run(COMMANDS.getKathOndvlaRespInfo, {
            args: [vosResps, vosOus],
            successActionCreator: ACTIONS.kathondvlaResponsibilityRelationsfetched,
          }),
          Cmd.run(COMMANDS.getPendingRequests, {
            args: [state.person.$$meta.permalink, samOus, vosOus],
            successActionCreator: pendingRequestsFetched,
          }),
        ])
      );
    }

    case ACTIONS.PENDING_REQUESTS_FETCHED: {
      return {
        ...state,
        pendingRequests: {
          sam: arrayToMap(
            action.payload.requests.filter((resp) =>
              resp.organisationalUnit.href.match(/^\/sam\//g)
            )
          ),
          vos: arrayToMap(
            action.payload.requests.filter(
              (resp) => !resp.organisationalUnit.href.match(/^\/sam\//g)
            )
          ),
        },
        ous: {
          ...state.ous,
          sam:
            action.payload.samOus.length > 0
              ? arrayToMap([...mapToArray(state.ous.sam), ...action.payload.samOus])
              : state.ous.sam,
          vos:
            action.payload.vosOus.length > 0
              ? arrayToMap([...mapToArray(state.ous.vos), ...action.payload.vosOus])
              : state.ous.vos,
        },
      };
    }

    case USER_ACTIONS.USER_RESPONSIBILITIES_FETCHED: {
      if (state.selectedPersonKey) {
        return state;
      }
      return {
        ...state,
        responsibilities: {
          sam: arrayToMap(
            action.payload.userResponsibilities.filter((resp) =>
              resp.organisation.href.match(/^\/sam\//g)
            )
          ),
          vos: arrayToMap(
            action.payload.userResponsibilities.filter(
              (resp) => !resp.organisation.href.match(/^\/sam\//g)
            )
          ),
        },
        ous: {
          sam: arrayToMap(action.payload.ous),
          vos: state.ous.vos,
          groupParentRelations: arrayToMap(action.payload.parentRelations),
          dienstParentRelations: state.ous.dienstParentRelations,
        },
      };
    }

    case USER_ACTIONS.VOS_OUS_FETCHED: {
      if (state.selectedPersonKey) {
        return state;
      }
      return loop(
        {
          ...state,
          ous: {
            sam: state.ous.sam,
            vos: action.payload.ous,
            groupParentRelations: state.ous.groupParentRelations,
            dienstParentRelations: action.payload.parentRelations,
          },
        },
        Cmd.list([
          /* Cmd.run(COMMANDS.getKathOndvlaRespInfo, {
            args: [state.responsibilities.vos, action.payload],
            successActionCreator: ACTIONS.kathondvlaResponsibilityRelationsfetched
          }), */
          Cmd.run(COMMANDS.getPendingRequests, {
            args: [rootState.userState.user.$$meta.permalink, state.ous.sam, action.payload],
            successActionCreator: pendingRequestsFetched,
          }),
        ])
      );
    }

    case ACTIONS.KANTHONDVLA_RESPONSIBILITY_RELATIONS_FETCHED: {
      return {
        ...state,
        ous: {
          sam: state.ous.sam,
          vos: { ...state.ous.vos, ...arrayToMap(action.payload.parentOus) },
          groupParentRelations: state.ous.groupParentRelations,
          dienstParentRelations: action.payload.parentRelations,
        },
      };
    }

    case ACTIONS.ACCOUNT_INFO_FETCHED: {
      return { ...state, account: action.payload };
    }

    case ACTIONS.UNLINK_ACCOUNT: {
      const newExternalLoginProviders = state.account.externalLoginProviders.filter(
        (elp) =>
          elp.providerKey !== action.payload.providerId ||
          elp.loginProviderAlias !== action.payload.loginProviderAlias
      );
      const newAccount = { ...state.account, externalLoginProviders: newExternalLoginProviders };
      return loop(
        { ...state, account: newAccount },
        Cmd.run(GLOBAL_COMMANDS.storeResource, {
          args: [newAccount],
          successActionCreator: () =>
            addSuccessNotification(
              `${action.payload.alias} bij ${action.payload.type} is logsgekoppeld van jouw Katholiek Onderwijs Vlaanderen account`
            ),
          failActionCreator: () =>
            addErrorNotification(
              `Er is een onverwachte fout opgetreden bij het loskoppelen van ${action.payload.alias} bij ${action.payload.type} en jouw Katholiek Onderwijs Vlaanderen account`
            ),
        })
      );
    }

    case ACTIONS.SECURITY_MAILBOX_FETCHED: {
      return { ...state, hasKathOndVlaMailbox: action.payload };
    }

    // PROFILE PICTURE

    case ACTIONS.ADD_PROFILE_PICTURE: {
      return loop(
        state,
        Cmd.run(COMMANDS.replacePersonProfilePictures, {
          args: [action.payload.images, action.payload.personHref, state.person.attachments],
          successActionCreator: ACTIONS.refreshPerson,
          failActionCreator: () =>
            addErrorNotification(
              'Er is een onverwachte fout opgetreden bij het uploaden van de profielfoto. Probeer het later nog eens'
            ),
        })
      );
    }

    case ACTIONS.REPLACE_PROFILE_PICTURE: {
      return loop(
        state,
        Cmd.run(COMMANDS.replacePersonProfilePictures, {
          args: [action.payload.images, action.payload.personHref, state.person.attachments],
          successActionCreator: ACTIONS.refreshPerson,
          failActionCreator: () =>
            addErrorNotification(
              'Er is een onverwachte fout opgetreden bij het uploaden van de profielfoto. Probeer het later nog eens'
            ),
        })
      );
    }

    case ACTIONS.DELETE_PROFILE_PICTURE: {
      return loop(
        { ...state, ...{ ...state.person, attachments: [] } },
        Cmd.run(COMMANDS.deleteProfilePictures, {
          args: [state.person.attachments],
          successActionCreator: ACTIONS.refreshPerson,
          failActionCreator: () =>
            addErrorNotification(
              'Er is een onverwachte fout opgetreden bij het verwijderen van de profielfoto. Probeer het later nog eens'
            ),
        })
      );
    }

    case ACTIONS.REFRESH_PERSON: {
      return loop(
        state,
        Cmd.run(COMMANDS.getPerson, {
          args: [state.person.$$meta.permalink],
          successActionCreator: ACTIONS.personRefreshed,
        })
      );
    }

    case ACTIONS.PERSON_REFRESHED: {
      return { ...state, person: action.payload };
    }

    // UPDATES

    case ACTIONS.UPDATE_PROFILE_FORM: {
      return { ...state, profileForm: action.payload };
    }

    case ACTIONS.PERSIST_PROFILE_CHANGES: {
      if (
        !state.profileForm.valid ||
        state.titleErrors.length > 0 ||
        state.dateOfBirthErrors.length > 0 ||
        state.reservedRegistrationNumbers.some(
          (registrationNumber) => registrationNumber === state.person.registrationNumber
        ) ||
        state.reservedUsernames.some((username) => username === state.person.username) ||
        rootState.vm.profile.validation.primaryEmailErrors.length > 0 ||
        rootState.vm.profile.validation.secondaryEmailErrors.length > 0 ||
        state.officePhoneErrors.length > 0 ||
        state.officeMobilePhoneErrors.length > 0 ||
        state.personalPhoneErrors.length > 0 ||
        state.personalMobilePhoneErrors.length > 0 ||
        state.ibanExpenseErrors.length > 0
      ) {
        const invalidFormWarning = addNotification(
          'Sommige velden zijn niet correct ingevuld. Kijk de in het rood gemarkeerde velden na.',
          'WARNING',
          null,
          5000
        );
        return loop(state, Cmd.action(invalidFormWarning));
      }
      const batch = getPersonsBatch(state);
      if (batch.array.length === 0) {
        console.log('Er zijn geen wijzigingen, alles is terug zoals het was');
        return state;
      }
      const newState = { ...state };
      delete newState.lastSavedState;
      newState.lastSavedState = newState;
      return loop(
        newState,
        Cmd.list([
          Cmd.action(clearAllNotifications()),
          Cmd.run(COMMANDS.persistProfileChanges, {
            args: [batch],
            successActionCreator: () => addSuccessNotification('Jouw gegevens zijn opgeslagen.'),
            failActionCreator: ACTIONS.persistProfileChangesFailed,
          }),
        ])
      );
    }

    case ACTIONS.PERSIST_PROFILE_CHANGES_FAILED: {
      let onlyHandledErrors = true;
      const reservedRegistrationNumbers = [...state.reservedRegistrationNumbers];
      const cmdList = [];
      if (action.payload instanceof SriClientError && action.payload.body) {
        action.payload.body.forEach((elem) => {
          if (elem.href === state.person.$$meta.permalink && elem.status === 409) {
            elem.body.errors.forEach((error) => {
              if (error.code === 'duplicate.registration.number') {
                console.warn(
                  `DUPLICATE_PERSON: ${state.person.username} - ${state.person.key} tries to fill in ${state.person.registrationNumber} as stamboeknummer but this is already registered for another user: ${error.duplicate.href}`
                );
                reservedRegistrationNumbers.push(state.person.registrationNumber);
              } else if (error.code === 'hand.over.person.full.name') {
                console.warn(
                  `STEEL_ACCOUNT_ATTEMPT: ${state.person.username} - ${state.person.key} tries to change his/her name to ${state.person.firstName} ${state.person.lastName} [do not know what name was...]`
                );
                const accountSteelError = addNotification(
                  'Probeer niet een bestaande account van iemand anders over te nemen! Vraag jouw directeur om jou uit te nodigen en registreer jezelf met een eigen account.<br/> Bij vragen neem contact op met Katholiek Onderwijs Vlaanderen  op 02 507 07 80 (tussen 8u en 11u45) of mail naar gegevensbeheer@katholiekonderwijs.vlaanderen',
                  'error',
                  undefined,
                  9999999999,
                  true
                );
                cmdList.push(Cmd.action(accountSteelError));
              } else {
                onlyHandledErrors = false;
              }
            });
          } else {
            onlyHandledErrors = false;
          }
        });
      } else {
        onlyHandledErrors = false;
      }
      const newState = { ...state, reservedRegistrationNumbers };
      if (!onlyHandledErrors) {
        console.error(
          `SAVE_PROFILE_ERROR: unexpected error happened when saving profile of ${state.person.username}-${state.person.key}`,
          action.payload
        );
        const addErrorAction = addErrorNotification(
          'Jouw gegevens konden niet worden opgeslagen. Er deed zich een onverwachte fout voor.'
        );
        cmdList.push(Cmd.action(addErrorAction));
      }
      return loop(newState, Cmd.list(cmdList));
    }

    // PERSON DETAILS

    case ACTIONS.UPDATE_GENDER: {
      return loop(
        {
          ...state,
          person: {
            ...state.person,
            sex: action.payload,
            title: action.payload === FEMALE ? MEVROUW : DE_HEER,
          },
          titleErrors: getTitleErrors(
            action.payload,
            state.person.registrationNumber,
            state.profileForm.formGroups.stamboeknummer.valid
          ),
        },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_FIRSTNAME: {
      return loop(
        {
          ...state,
          person: { ...state.person, firstName: action.payload },
        },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_LASTNAME: {
      return loop(
        {
          ...state,
          person: { ...state.person, lastName: action.payload },
        },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_REGISTRATION_NUMBER: {
      const registrationNumber = action.payload || undefined;
      const personChanges = {
        title: state.person.title,
        sex: state.person.sex,
        dateOfBirth: state.person.dateOfBirth,
        registrationNumber,
      };
      if (registrationNumber && state.profileForm.formGroups.stamboeknummer.valid) {
        personChanges.dateOfBirth = getDateOfBirthFromRegistrationNumber(registrationNumber);
        if (registrationNumber.charAt(0) === '1') {
          personChanges.title = DE_HEER;
          personChanges.sex = MALE;
        } else if (registrationNumber.charAt(0) === '2') {
          personChanges.title = MEVROUW;
          personChanges.sex = FEMALE;
        }
      }
      return loop(
        {
          ...state,
          person: { ...state.person, ...personChanges },
          titleErrors: getTitleErrors(
            personChanges.sex,
            registrationNumber,
            state.profileForm.formGroups.stamboeknummer.valid
          ),
          dateOfBirthErrors: getDateOfBirthErrors(
            personChanges.dateOfBirth,
            registrationNumber,
            state.profileForm.formGroups.stamboeknummer.valid
          ),
        },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_DATE_OF_BIRTH: {
      if (action.payload === state.person.dateOfBirth) {
        return state;
      }
      return loop(
        {
          ...state,
          person: { ...state.person, dateOfBirth: action.payload },
          dateOfBirthErrors: getDateOfBirthErrors(
            action.payload,
            state.person.registrationNumber,
            state.profileForm.formGroups.stamboeknummer.valid
          ),
        },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_USERNAME: {
      const newUsername = action.payload.toLowerCase().trim();
      const newPerson = { ...state.person, username: newUsername };
      const newState = {
        ...state,
        person: newPerson,
        pendingChanges: { ...state.pendingChanges, person: 'UPDATE' },
      };
      if (
        !action.payload ||
        !state.profileForm.formGroups.gebruikersnaam.valid ||
        state.freeUsernames.some((username) => username === newUsername) ||
        state.reservedUsernames.some((username) => username === newUsername)
      ) {
        return loop(
          newState,
          Cmd.run(COMMANDS.debounceProfileChanges, {
            successActionCreator: ACTIONS.persistProfileChanges,
          })
        );
      }
      return loop(
        newState,
        Cmd.run(COMMANDS.validateUsername, {
          args: [newPerson],
          successActionCreator: () => ACTIONS.usernameIsFree(newUsername),
          failActionCreator: () => ACTIONS.usernameIsReserved(newUsername),
        })
      );
    }

    case ACTIONS.USERNAME_IS_FREE: {
      return loop(
        { ...state, freeUsernames: [...state.freeUsernames, action.payload] },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.USERNAME_IS_RESERVED: {
      return { ...state, reservedUsernames: [...state.reservedUsernames, action.payload] };
    }

    // EMAIL ADDRESSES

    case ACTIONS.UPDATE_PRIMARY_EMAIL: {
      let newEmail = null;
      if (action.payload && !state.primaryEmail) {
        if (state.lastSavedState.primaryEmail) {
          newEmail = { ...state.lastSavedState.secondaryEmail, value: action.payload };
        } else {
          newEmail = createEmailAddress(action.payload, state.person);
        }
      } else if (!action.payload) {
        newEmail = null;
      } else {
        newEmail = { ...state.primaryEmail, value: action.payload };
      }
      const newState = { ...state, primaryEmail: newEmail };
      if (
        !newEmail ||
        !state.profileForm.formGroups.email.valid ||
        state.freeEmailAddresses.some((email) => email === action.payload) ||
        state.reservedEmailAddresses.some((email) => email === action.payload)
      ) {
        return loop(
          newState,
          Cmd.run(COMMANDS.debounceProfileChanges, {
            successActionCreator: ACTIONS.persistProfileChanges,
          })
        );
      }
      return loop(
        newState,
        Cmd.run(COMMANDS.validateEmailAddress, {
          args: [newEmail, state.person.$$meta.permalink],
          successActionCreator: () => ACTIONS.emailAddressIsFree(action.payload, false),
          failActionCreator: (duplicateError) =>
            ACTIONS.emailAddressIsReserved(newEmail, 'primair e-mailaderes', duplicateError, false),
        })
      );
    }

    case ACTIONS.UPDATE_SECONDARY_EMAIL: {
      let newEmail = null;
      if (action.payload && !state.secondaryEmail) {
        if (state.lastSavedState.secondaryEmail) {
          newEmail = { ...state.lastSavedState.secondaryEmail, value: action.payload };
        } else {
          newEmail = createEmailAddress(action.payload, state.person, true);
        }
      } else if (!action.payload) {
        newEmail = null;
      } else {
        newEmail = { ...state.secondaryEmail, value: action.payload };
      }
      const newState = { ...state, secondaryEmail: newEmail };
      if (
        !newEmail ||
        !state.profileForm.formGroups.secondaryEmail.valid ||
        state.freeEmailAddresses.some((email) => email === action.payload) ||
        state.reservedEmailAddresses.some((email) => email === action.payload)
      ) {
        return loop(
          newState,
          Cmd.run(COMMANDS.debounceProfileChanges, {
            successActionCreator: ACTIONS.persistProfileChanges,
          })
        );
      }
      return loop(
        newState,
        Cmd.run(COMMANDS.validateEmailAddress, {
          args: [newEmail, state.person.$$meta.permalink],
          successActionCreator: () => ACTIONS.emailAddressIsFree(action.payload, false),
          failActionCreator: (duplicateError) =>
            ACTIONS.emailAddressIsReserved(newEmail, 'primair e-mailaderes', duplicateError, false),
        })
      );
    }

    case ACTIONS.EMAIL_ADDRESS_IS_FREE: {
      const newState = {
        ...state,
        freeEmailAddresses: [...state.freeEmailAddresses, action.payload.emailAddress],
      };
      if (action.payload.doNotPersist) {
        return newState;
      }
      return loop(
        newState,
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.EMAIL_ADDRESS_IS_RESERVED: {
      const newState = {
        ...state,
        reservedEmailAddresses: [
          ...state.reservedEmailAddresses,
          action.payload.emailAddress.value,
        ],
      };
      if (action.payload.doNotPersist) {
        return newState;
      }
      console.warn(
        `DUPLICATE_EMAIL: ${state.lastSavedState.person.username} (${state.person.key}) probeerde ${
          action.payload.emailAddress.value
        } in te vullen als ${action.payload.typeDescription}, maar dit is al gekoppeld aan ${
          action.payload.duplicateError.duplicates.length
        } andere gebruiker${action.payload.duplicateError.duplicates.length > 1 ? 's' : ''}`,
        action.payload.duplicateError.duplicates.map((duplicate) => duplicate.$$details)
      );
      return loop(
        newState,
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    // PHONES

    case ACTIONS.UPDATE_OFFICE_PHONE: {
      let newPhone = null;
      const phoneErrors = [];

      if (action.payload) {
        let phoneNumber = action.payload;
        try {
          phoneNumber = formatPhoneNumber(action.payload);
        } catch (error) {
          phoneNumber = action.payload;
          phoneErrors.push({ code: 'invalid.format', message: 'Dit is geen geldig telefoonnumer' });
        }
        if (!state.officePhone) {
          if (state.lastSavedState.officePhone) {
            newPhone = { ...state.lastSavedState.officePhone, number: phoneNumber };
          } else {
            newPhone = createPhone(phoneNumber, state.person, 'PHONE_OFFICE');
          }
        } else {
          newPhone = { ...state.officePhone, number: phoneNumber };
        }
      }
      return loop(
        { ...state, officePhone: newPhone, officePhoneErrors: phoneErrors },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_OFFICE_MOBILE_PHONE: {
      let newPhone = null;
      const phoneErrors = [];

      if (action.payload) {
        let phoneNumber = action.payload;
        try {
          phoneNumber = formatPhoneNumber(action.payload);
        } catch (error) {
          phoneNumber = action.payload;
          phoneErrors.push({ code: 'invalid.format', message: 'Dit is geen geldig telefoonnumer' });
        }
        if (!state.officeMobilePhone) {
          if (state.lastSavedState.officeMobilePhone) {
            newPhone = { ...state.lastSavedState.officeMobilePhone, number: phoneNumber };
          } else {
            newPhone = createPhone(phoneNumber, state.person, 'PHONE_MOBILE_OFFICE');
          }
        } else {
          newPhone = { ...state.officeMobilePhone, number: phoneNumber };
        }
      }
      return loop(
        { ...state, officeMobilePhone: newPhone, officeMobilePhoneErrors: phoneErrors },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_PERSONAL_PHONE: {
      let newPhone = null;
      const phoneErrors = [];

      if (action.payload) {
        let phoneNumber = action.payload;
        try {
          phoneNumber = formatPhoneNumber(action.payload);
        } catch (error) {
          phoneNumber = action.payload;
          phoneErrors.push({ code: 'invalid.format', message: 'Dit is geen geldig telefoonnumer' });
        }
        if (!state.personalPhone) {
          if (state.lastSavedState.personalPhone) {
            newPhone = { ...state.lastSavedState.personalPhone, number: phoneNumber };
          } else {
            newPhone = createPhone(phoneNumber, state.person, 'PHONE_PERSONAL');
          }
        } else {
          newPhone = { ...state.personalPhone, number: phoneNumber };
        }
      }
      return loop(
        { ...state, personalPhone: newPhone, personalPhoneErrors: phoneErrors },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_PERSONAL_MOBILE_PHONE: {
      let newPhone = null;
      const phoneErrors = [];

      if (action.payload) {
        let phoneNumber = action.payload;
        try {
          phoneNumber = formatPhoneNumber(action.payload);
        } catch (error) {
          phoneNumber = action.payload;
          phoneErrors.push({ code: 'invalid.format', message: 'Dit is geen geldig telefoonnumer' });
        }
        if (!state.personalMobilePhone) {
          if (state.lastSavedState.personalMobilePhone) {
            newPhone = { ...state.lastSavedState.personalMobilePhone, number: phoneNumber };
          } else {
            newPhone = createPhone(phoneNumber, state.person, 'PHONE_MOBILE');
          }
        } else {
          newPhone = { ...state.personalMobilePhone, number: phoneNumber };
        }
      }
      return loop(
        { ...state, personalMobilePhone: newPhone, personalMobilePhoneErrors: phoneErrors },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    // BANKACCOUNTS
    case ACTIONS.UPDATE_IBAN_EXPENSES: {
      let newIban = null;
      let iban = action.payload;
      const ibanExpenseErrors = [];
      if (iban) {
        try {
          iban = formatAndValidateIban(iban);
        } catch (error) {
          ibanExpenseErrors.push({ code: 'invalid.format', message: error.message });
        }
        if (!state.expensesBankAccount) {
          if (state.lastSavedState.expensesBankAccount) {
            newIban = { ...state.lastSavedState.expensesBankAccount, iban };
          } else {
            newIban = createBankAccount(iban, state.person, 'BANKACCOUNT_EXPENSE_NOTES');
          }
        } else {
          newIban = { ...state.expensesBankAccount, iban };
        }
      }
      return loop(
        { ...state, expensesBankAccount: newIban, ibanExpenseErrors },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    // ADDRESSES

    case ACTIONS.UPDATE_OFFICE_ADDRESS: {
      let newAddress = null;
      if (action.payload) {
        const address = { ...action.payload };
        address.mailboxNumber = address.mailboxNumber || undefined;
        address.streetHref = address.streetHref || undefined;
        if (!state.officeAddress) {
          if (state.lastSavedState.officeAddress) {
            newAddress = { ...state.lastSavedState.officeAddress, ...address };
          } else {
            newAddress = createAddress(address, state.person, 'ADDRESS_OFFICE');
          }
        } else {
          newAddress = { ...state.officeAddress, ...address };
        }
      }
      return loop(
        { ...state, officeAddress: newAddress },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.UPDATE_PERSONAL_ADDRESS: {
      let newAddress = null;
      if (action.payload) {
        const address = { ...action.payload };
        address.mailboxNumber = address.mailboxNumber || undefined;
        address.streetHref = address.streetHref || undefined;
        if (!state.personalAddress) {
          if (state.lastSavedState.personalAddress) {
            newAddress = { ...state.lastSavedState.personalAddress, ...address };
          } else {
            newAddress = createAddress(address, state.person, 'ADDRESS_PERSONAL');
          }
        } else {
          newAddress = { ...state.personalAddress, ...address };
        }
      }
      return loop(
        { ...state, personalAddress: newAddress },
        Cmd.run(COMMANDS.debounceProfileChanges, {
          successActionCreator: ACTIONS.persistProfileChanges,
        })
      );
    }

    case ACTIONS.ADD_PERSONAL_CONTACTDETAILS: {
      return { ...state, showPersonalContactDetails: true };
    }

    case ACTIONS.EDIT_BIOGRAPHY: {
      const newPerson = { ...state.person, biography: action.payload };
      return loop(
        { ...state, person: newPerson },
        Cmd.run(GLOBAL_COMMANDS.storeResource, {
          args: [newPerson],
          successActionCreator: () => addSuccessNotification('Jouw biografie is opgeslagen.'),
        })
      );
    }

    case REQUEST_RESPONSIBILITY_SUCCEEDED: {
      if (!state.person) {
        return loop(
          state,
          Cmd.run(COMMANDS.getPendingRequests, {
            args: [rootState.userState.user.$$meta.permalink, state.ous.sam, state.ous.vos],
            successActionCreator: pendingRequestsFetched,
          })
        );
      }
      return loop(
        state,
        Cmd.run(COMMANDS.getPendingRequests, {
          args: [state.person.$$meta.permalink, state.ous.sam, state.ous.vos],
          successActionCreator: pendingRequestsFetched,
        })
      );
    }

    case TRY_CREATING_RESPONSIBILITY_SUCCEEDED: {
      return loop(
        state,
        Cmd.run(GLOBAL_COMMANDS.fetchResource, {
          args: [action.payload.responsibility.organisation.href],
          successActionCreator: (ou) =>
            ACTIONS.ouForNewResponsibilityFetched(ou, action.payload.responsibility),
        })
      );
    }

    case ACTIONS.OU_FOR_NEW_RESPONSIBILITY_FETCHED: {
      const newVosOus = { ...state.ous.vos };
      newVosOus[action.payload.ou.$$meta.permalink] = action.payload.ou;
      const newVosResponsibilities = { ...state.responsibilities.vos };
      newVosResponsibilities[action.payload.responsibility.$$meta.permalink] =
        action.payload.responsibility;
      return {
        ...state,
        responsibilities: { ...state.responsibilities, vos: newVosResponsibilities },
        ous: { ...state.ous, vos: newVosOus },
      };
    }

    case ACTIONS.END_RESPONSIBILITIES_AND_REQUESTS: {
      const { teamInfo } = action.payload;
      const newSamResps = { ...state.responsibilities.sam };
      const newVosResps = { ...state.responsibilities.vos };
      const newSamRequests = { ...state.pendingRequests.sam };
      const newVosRequests = { ...state.pendingRequests.vos };
      let respsToEnd;
      const allMainResponsibilitiesAreEnded = !teamInfo.positions
        .filter((pos) => pos.type === 'RESPONSIBILITY')
        .some((pos) => !action.payload.hrefs.some((href) => href === pos.resource));
      if (allMainResponsibilitiesAreEnded) {
        // member leaves team
        const allTeacherGroupHrefs = Object.values(state.ous.groupParentRelations)
          .filter((rel) => rel.to.href === teamInfo.permalink)
          .map((rel) => rel.from.href);
        respsToEnd = [
          ...Object.values(state.responsibilities.sam),
          ...Object.values(state.responsibilities.vos),
        ].filter(
          (resp) =>
            resp.organisation.href === teamInfo.permalink ||
            allTeacherGroupHrefs.some(
              (teacherGroupHref) => teacherGroupHref === resp.organisation.href
            )
        );
      } else {
        respsToEnd = [
          ...Object.values(state.responsibilities.sam),
          ...Object.values(state.responsibilities.vos),
        ].filter((resp) => action.payload.hrefs.some((href) => href === resp.$$meta.permalink));
      }
      console.log('resps to end', respsToEnd);
      const updates = [];
      const deletes = [];
      const deactivatedResponsibilities = [];
      respsToEnd.forEach((resp) => {
        // you can only make the endDate to a sooner date, because otherwise you can prolong a resp ended by the manager
        if (isBefore(action.payload.endDate, resp.endDate)) {
          if (isBeforeOrEqual(getPreviousDay(action.payload.endDate, 10), resp.startDate)) {
            deletes.push(resp.$$meta.permalink);
            delete newSamResps[resp.$$meta.permalink];
            delete newVosResps[resp.$$meta.permalink];
          } else {
            const newResp = { ...resp, endDate: action.payload.endDate };
            updates.push(newResp);
            if (teamInfo.permalink.match(/^\/sam.+/)) {
              newSamResps[resp.$$meta.permalink] = newResp;
            } else {
              newVosResps[resp.$$meta.permalink] = newResp;
            }
          }
        }
        if (isBeforeOrEqual(action.payload.endDate, getNow)) {
          deactivatedResponsibilities.push(resp.$$meta.permalink);
        }
      });
      const requestsToRevoke = [
        ...Object.values(state.pendingRequests.sam),
        ...Object.values(state.pendingRequests.vos),
      ].filter((request) => action.payload.hrefs.some((href) => href === request.$$meta.permalink));
      requestsToRevoke.forEach((request) => {
        deletes.push(request.$$meta.permalink);
        delete newSamRequests[request.$$meta.permalink];
        delete newVosRequests[request.$$meta.permalink];
      });
      return loop(
        {
          ...state,
          responsibilities: {
            sam: newSamResps,
            vos: newVosResps,
          },
          pendingRequests: {
            sam: newSamRequests,
            vos: newVosRequests,
          },
        },
        Cmd.run(GLOBAL_COMMANDS.persistResponsibilities, {
          args: [updates, deletes],
          successActionCreator: () =>
            ACTIONS.persistResponsibilitiesAndRequestsSuccess({
              deactivatedResponsibilities,
              teamInfo,
              endDate: action.payload.endDate,
            }),
          failActionCreator: ACTIONS.persistResponsibilitiesAndRequestsFailed,
        })
      );
    }

    case ACTIONS.PERSIST_RESPONSIBILITIES_AND_REQUESTS_SUCCESS: {
      return loop(
        state,
        Cmd.action(
          addSuccessNotification(
            `Je functie binnen ${action.payload.teamInfo.name} is succesvol beëindigd.`
          )
        )
      );
    }

    case ACTIONS.PERSIST_RESPONSIBILITIES_AND_REQUESTS_FAILED: {
      return loop(
        state,
        Cmd.list([
          Cmd.action(
            addErrorNotification(
              'Er is een onverwachte fout opgetreden bij het beëindigen van de functie'
            )
          ),
          Cmd.run(COMMANDS.refreshResponsibilitiesAndRequests, {
            args: [state.person.$$meta.permalink],
            successActionCreator: ACTIONS.responsibilitiesAndRequestsRefetched,
          }),
        ])
      );
    }

    case ACTIONS.RESPONSIBILITIES_AND_REQUESTS_REFETCHED: {
      return {
        ...state,
        responsibilities: {
          sam: arrayToMap(
            action.payload.responsibilities.filter((resp) =>
              resp.organisation.href.match(/^\/sam\//g)
            )
          ),
          vos: arrayToMap(
            action.payload.responsibilities.filter(
              (resp) => !resp.organisation.href.match(/^\/sam\//g)
            )
          ),
        },
        pendingRequests: {
          sam: arrayToMap(
            action.payload.requests.filter((resp) => resp.organisation.href.match(/^\/sam\//g))
          ),
          vos: arrayToMap(
            action.payload.requests.filter((resp) => !resp.organisation.href.match(/^\/sam\//g))
          ),
        },
      };
    }

    case ACTIONS.SEND_REMINDER_REQUEST_RESP: {
      const { pendingResponsibilityHrefs } = action.payload;
      const rePutPeningResponsibilities = pendingResponsibilityHrefs.map((href) => {
        const requestResp = state.pendingRequests.sam[href] ? state.pendingRequests.sam[href] : state.pendingRequests.vos[href];
        return requestResp;
      });
      return loop(state, Cmd.run(sendRemindersForRequestResponsibility, {
        args: [rePutPeningResponsibilities],
        successActionCreator: (response) => {
          if (!response) {
            console.warn('Je hebt al een herinning gestuurd de voorbije 24 uur.')
            return addWarningNotification('Je hebt al een herinning gestuurd de voorbije 24 uur.');
          }
          console.log('response is', response)
          const message = response.managerNames.length === 1
            ? `Een mail is verstuurd naar <strong>${response.managerNames[0]}</strong></br>
              Pas als ${response.managerNames[0]} jouw aanvraag goedkeurt ben je toegevoegd aan het team van <strong>${response.organisationalUnitName}</strong> en krijg je de bijhorende toegangsrechten`
            : `Een mail is verstuurd naar ${
              response.managerNames.map((name) => `<strong>${name}</strong>`).slice(0, -1).join(', ')} en <strong>${response.managerNames[response.managerNames.length - 1]
              }</strong></br>
              Pas als één van hen jouw aanvraag goedkeurt ben je toegevoegd aan het team van <strong>${response.organisationalUnitName}</strong> en krijg je de bijhorende toegangsrechten`;
          return addSuccessNotification(message, null, 60 * 60 * 1000);
        },
        failActionCreator: (error) => addErrorNotification(`Er is een onverwachte fout opgetreden bij het sturen van de herinnering`),
      }));
    }

    default:
      return state;
  }
};
