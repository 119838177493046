import {
  getNow,
  isBeforeOrEqual,
  getStartOfSchoolYear,
  getEndOfSchoolYear,
} from '@kathondvla/sri-client/date-utils';

const validateEndDateAfterStartDate = (endDate, startDate) => {
  if (isBeforeOrEqual(endDate, startDate)) {
    return {
      code: 'enddate.not.after.startdate',
      message: 'De einddatum kan niet voor de startdatum vallen.',
    };
  }
  return null;
};

export class DeleteModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.deleteIcon = require('Img/cross.svg');
  }

  $onInit() {
    this.startDate = this.resolve.startDate;
    this.endDate = this.resolve.endDate || getNow();
    this.subtitle = this.resolve.subtitle;
    this.endDateHelpText = this.resolve.endDateHelpText;
    this.doNotValidatePeriod = !this.resolve.validatePeriod;
    this.startDateInfo = this.resolve.startDateInfo;
    this.externalConsequence = this.resolve.externalConsequence;
    this.validationRules = [];
    if (this.resolve.validatePeriod) {
      this.validationRules.push({
        validate: validateEndDateAfterStartDate,
        args: [this.startDate],
      });
    }
    if (this.resolve.extraValidation) {
      this.validationRules = [...this.validationRules, this.resolve.extraValidation];
    }
    this.adaptSchoolYearText();
  }

  goToPreviousSchoolYear() {
    if (this.endDate !== getStartOfSchoolYear()) {
      this.endDate = getStartOfSchoolYear();
    } else {
      this.endDate = getNow();
    }
    this.adaptSchoolYearText();
  }

  goToNextSchoolYear() {
    if (this.endDate !== getEndOfSchoolYear()) {
      this.endDate = getEndOfSchoolYear();
    } else {
      this.endDate = getNow();
    }
    this.adaptSchoolYearText();
  }

  adaptSchoolYearText() {
    if (this.endDate !== getStartOfSchoolYear()) {
      this.prevSchoolYear = 'begin huidig schooljaar';
    } else {
      this.prevSchoolYear = 'vandaag';
    }

    if (this.endDate !== getEndOfSchoolYear()) {
      this.nextSchoolYear = 'einde huidig schooljaar';
    } else {
      this.nextSchoolYear = 'vandaag';
    }
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  onDateChanged($event, endDate) {
    this.endDate = endDate || null;
  }

  onDeleteEntity() {
    if (!this.deleteForm.valid) {
      return;
    }
    this.close({
      $value: {
        endDate: this.endDate,
      },
    });
  }
}
