import { CreateLinkModal } from './createLinkModal';

require('./createLinkModal.scss');

export const myCreateLinkModalComponent = {
  template: require('./createLinkModal.html'),
  controllerAs: 'ctrl',
  controller: CreateLinkModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
