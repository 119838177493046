export const START_CREATE_TEAM_WIZARD = 'start_create_team_wizard';
export const startCreateTeamWizard = () => ({
  type: START_CREATE_TEAM_WIZARD,
});

export const GO_TO_NEXT_STEP_IN_CREATE_TEAM_WIZARD = 'go_to_next_step_in_create_team_wizard';
export const goToNextStepInCreateTeamWizard = () => ({
  type: GO_TO_NEXT_STEP_IN_CREATE_TEAM_WIZARD,
});

export const GO_TO_PREVIOUS_STEP_IN_CREATE_TEAM_WIZARD =
  'go_to_previous_step_in_create_team_wizard';
export const goToPreviousStepInCreateTeamWizard = () => ({
  type: GO_TO_PREVIOUS_STEP_IN_CREATE_TEAM_WIZARD,
});

export const CHANGE_NAME = 'change_name';
export const changeName = (name) => ({
  type: CHANGE_NAME,
  payload: name,
});

export const CHANGE_SHORT_NAME = 'change_short_name';
export const changeShortName = (shortName) => ({
  type: CHANGE_SHORT_NAME,
  payload: shortName,
});

export const CHANGE_DESCRIPTION = 'change_description';
export const changeDescription = (description) => ({
  type: CHANGE_DESCRIPTION,
  payload: description,
});

export const CHANGE_START_DATE = 'change_startDate';
export const changeStartDate = (startDate) => ({
  type: CHANGE_START_DATE,
  payload: startDate,
});

export const CHANGE_TYPE = 'change_type';
export const changeType = (type) => ({
  type: CHANGE_TYPE,
  payload: type,
});

export const SET_CREATE_TEAM_FORM_VALIDATION = 'set_create_team_form_validation';
export const setCreateTeamFormValidation = (isValid) => ({
  type: SET_CREATE_TEAM_FORM_VALIDATION,
  payload: isValid,
});

export const PERSIST_NEW_TEAM_SUCCESS = 'persist_new_team_success';
export const persistNewTeamSuccess = (result) => ({
  type: PERSIST_NEW_TEAM_SUCCESS,
  payload: result,
});

export const PERSIST_NEW_TEAM_FAILED = 'persist_new_team_failed';
export const persistNewTeamFailed = (error) => ({
  type: PERSIST_NEW_TEAM_FAILED,
  payload: error,
});

export const VALIDATE_ORGANISATION_SUCCESS = 'validate_organisation_success';
export const validateOrganisationSuccess = (result) => ({
  type: VALIDATE_ORGANISATION_SUCCESS,
  payload: result,
});

export const VALIDATE_ORGANISATION_FAILED = 'validate_organisation_failed';
export const validateOrganisationFailed = (result) => ({
  type: VALIDATE_ORGANISATION_FAILED,
  payload: result,
});

export const VALIDATE_WORKGROUP_SUCCESS = 'validate_workgroup_success';
export const validateWorkgroupSuccess = () => ({
  type: VALIDATE_WORKGROUP_SUCCESS,
});

export const VALIDATE_WORKGROUP_FAILED = 'validate_workgroup_failed';
export const validateWorkgroupFailed = (error) => ({
  type: VALIDATE_WORKGROUP_FAILED,
  payload: error,
});

export const REQUEST_TO_JOIN_SUGGESTED_TEAM = 'request_to_join_suggested_team';
export const requestToJoinSuggestedTeam = (ouKey) => ({
  type: REQUEST_TO_JOIN_SUGGESTED_TEAM,
  payload: ouKey,
});
