import * as ACTIONS from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import { capitaliseFirstLetter } from 'ReduxLoop/utils/stringUtils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  addErrorNotification,
  removeNotification,
} from 'ReduxLoop/notifications/notificationActions';

require('./myRequestResponsibility.scss');

export class MyRequestResponsibility {
  constructor($state, $stateParams, $ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.emailIcon = require('Img/mail.svg');
    this.nextIcon = require('Img/arrow-right.svg');
    this.profileIcon = require('Img/user.svg');
    this.capitaliseFirstLetter = capitaliseFirstLetter;
    this.sriClient = sriClient;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.vm.requestResponsibility,
        user: state.vm.user,
        ouSearchParams: {
          types: 'organisational_unit',
          organisationalUnitTypes: state.vm.requestResponsibility.ouTypeFilter,
          mainstructures: state.vm.requestResponsibility.mainstructureFilter,
          expand: 'full',
        },
        notificationList: state.vm.notifications,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.$ngRedux.dispatch(ACTIONS.initialiseRequestResponsibility(this.$stateParams));
  }

  onOuTypeSelected(ev, ouType) {
    this.$ngRedux.dispatch(ACTIONS.selectOuTypeForRequest(ouType));
  }

  // eslint-disable-next-line class-methods-use-this
  displayOu(resource) {
    if (!resource) {
      return null;
    }
    if (!resource.mailAddresses && !resource.locations) {
      return resource.name;
    }
    const address =
      resource.mailAddresses && resource.mailAddresses.length > 0
        ? resource.mailAddresses[0]
        : resource.locations[0];
    return resource.name + (address ? ` (${address.address.subCity})` : '');
  }

  ouSelected = (resource) => {
    this.$ngRedux.dispatch(ACTIONS.selectOuForRequest(resource));
  };

  searchApiErrorOccured() {
    this.$ngRedux.dispatch(
      addErrorNotification(
        'Er is een connectieprobleem met onze zoekmachine. Onze excuses. Probeer het later nog eens opnieuw.'
      )
    );
  }

  ouSearched(ev, data) {
    console.log('an ou was searched', data);
    this.userHasSearchedForOu = true;
  }

  startDateChanged(ev, startDate) {
    this.$ngRedux.dispatch(ACTIONS.changeStartDateForRequest(startDate));
  }

  positionChanged() {
    this.$ngRedux.dispatch(ACTIONS.changePositionForRequest(this.selectedPosition));
  }

  roleDescriptionChanged(ev, roleDescription) {
    this.$ngRedux.dispatch(ACTIONS.changeRoleDescriptionForRequest(roleDescription));
  }

  sendRequest() {
    this.submitAttempted = true;
    if (
      this.ouErrors.length > 0 ||
      this.positionErrors.length > 0 ||
      this.startDateErrors.length > 0
    ) {
      return;
    }
    this.$ngRedux.dispatch(ACTIONS.requestResponsibility());
  }

  addRequestToRegistration() {
    this.submitAttempted = true;
    if (this.bestuurderSelected) {
      this.$ngRedux.dispatch(ACTIONS.addRequestToRegistration());
      return;
    }
    if (
      this.ouErrors.length > 0 ||
      this.positionErrors.length > 0 ||
      this.startDateErrors.length > 0
    ) {
      return;
    }
    this.$ngRedux.dispatch(ACTIONS.addRequestToRegistration());
  }

  noRespInOu() {
    this.$ngRedux.dispatch(ACTIONS.addRequestToRegistration());
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  goBack() {
    this.userHasSearchedForOu = false;
    if (!this.ou && !this.ouTypeFilter) {
      this.$state.go('profile');
    } else {
      this.$ngRedux.dispatch(ACTIONS.selectOuForRequest(null));
    }
  }

  goToProfile() {
    this.$state.go('profile');
  }

  ouNotFound() {
    this.$state.go('contactHelpdesk', {
      type: 'ik-vind-mijn-organisatie-niet',
      'organisatie-type': this.displayType,
      lidwoord: this.typeArticle,
    });
  }
}
