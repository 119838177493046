import { printAddress } from '@kathondvla/sri-client/address-utils';

require('./myTabContainerHeader.scss');

export class MyTabContainerHeader {
  constructor($state, $ngRedux) {
    'ngInject';

    this.$state = $state;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        managementsSchools: state.vm.user.manages ? state.vm.user.manages.SCHOOLENTITY : [],
      };
    })(this);
  }

  // eslint-disable-next-line class-methods-use-this
  printAddress(address) {
    if (!address) {
      return '';
    }
    return printAddress(address);
  }

  goToSchoolSelectScreen() {
    this.$state.go('schoolSelecteer', { type: 'school' });
  }

  goToContactDetailsTab() {
    this.$state.go('school.contactdetails', {
      type: 'school',
      id: this.sOu.key,
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }
}
