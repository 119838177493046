export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const modulo = (divident, divisor) => {
  const partLength = 10;
  let updatedDivident = divident;

  while (updatedDivident.length > partLength) {
    const part = updatedDivident.substring(0, partLength);
    updatedDivident = (part % divisor) + updatedDivident.substring(partLength);
  }

  return updatedDivident % divisor;
};

export const formatAndValidateIban = (iban) => {
  const newValue = iban.replace(/[/.\s()\-';]/g, '').toUpperCase();
  if (!newValue.match(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/)) {
    throw new Error(`${newValue} is geen geldig iban rekeningnummer`);
  }
  let numberStr = '';
  [...newValue.substring(4), ...newValue.substring(0, 4)].forEach((character) => {
    if (character.match(/\d/)) {
      numberStr += character;
    } else {
      numberStr += character.charCodeAt(0) - 65 + 10;
    }
  });
  const mod97 = modulo(numberStr, 97);
  if (mod97 !== 1) {
    throw new Error(`${newValue} is geen geldig iban rekeningnummer`);
  }
  return newValue;
};

export const printIban = (iban) => {
  if (!iban.match(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/)) {
    return iban;
  }
  return iban.match(/.{1,4}/g).join(' ');
  // if (!iban.match(/^BE[0-9]{14}$/)) {
  //   return iban;
  // }
  // return iban.substring(0, 4) + ' ' + iban.substring(4, 8) + ' ' + iban.substring(8, 12) + ' ' + iban.substring(12);
};
