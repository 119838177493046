import { GroupInfo } from './groupInfo';

export const myGroupInfoComponent = {
  template: require('./groupInfo.html'),
  controllerAs: 'ctrl',
  controller: GroupInfo,
  bindings: {
    sGroup: '<',
  },
};
