import { getNow, getClosestSchoolYearSwitch, getNextDay } from '@kathondvla/sri-client/date-utils';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { getKeyAndMeta } from '../transformations';

const KATH_OND_VLA_HREFS = {
  vos: '/organisations/c000eaea-c451-2590-e044-d4856467bfb8',
  sam: '/sam/organisationalunits/c000eaea-c451-2590-e044-d4856467bfb8',
};

class RelationsComponents {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.getNow = getNow;
    this.getNextDay = getNextDay;
    this.getClosestSchoolYearSwitch = getClosestSchoolYearSwitch;
  }

  calculateVm() {
    this.$scope.$emit('onCalculateVm');
  }

  selectGoverningInstitution = (governingInstitution) => {
    this.newBestuur.href = governingInstitution.$$meta.permalink;
    this.newBestuur.name = governingInstitution.name;
  };

  newBestuurStartDateChanged(e, value) {
    this.newBestuurStartDateErrors = [];
    this.newBestuur.startDate = value;
  }

  addBestuur() {
    if (
      !this.newBestuurForm.valid ||
      !this.newBestuur.href ||
      (this.ou.bestuur && this.newBestuur.href === this.ou.bestuur.href)
    ) {
      this.$ngRedux.dispatch(
        addErrorNotification('Vul alle velden voor het nieuwe bestuur correct in.')
      );
      return;
    }
    if (this.ou.bestuur) {
      this.pendingRelations[this.ou.bestuur.resource.$$meta.permalink] = {
        ...this.ou.bestuur.resource,
        endDate: this.newBestuur.startDate,
      };
    }
    const newRelation = {
      ...getKeyAndMeta('/sam/organisationalunits/relations'),
      type: 'GOVERNS',
      from: { href: this.newBestuur.href, $$expanded: { $$displayName: this.newBestuur.name } },
      to: { href: this.ou.href },
      startDate: this.newBestuur.startDate,
    };
    this.pendingRelations[newRelation.$$meta.permalink] = newRelation;

    this.calculateVm();
    this.showBestuurHistory = true;
    this.newBestuur = null;
  }

  selectSchoolCommunity = (schoolCommunity) => {
    this.newSchoolCommunity.href = schoolCommunity.$$meta.permalink;
    this.newSchoolCommunity.name = schoolCommunity.name;
  };

  newSchoolCommunityStartDateChanged(e, value) {
    this.newSchoolCommunityStartDateErrors = [];
    this.newSchoolCommunity.startDate = value;
  }

  addSchoolCommunity() {
    if (
      !this.newSchoolCommunityForm.valid ||
      !this.newSchoolCommunity.href ||
      (this.ou.schoolCommunity && this.newSchoolCommunity.href === this.ou.schoolCommunity.href)
    ) {
      this.$ngRedux.dispatch(
        addErrorNotification('Vul alle velden voor de nieuwe scholengemeenschap correct in.')
      );
      return;
    }
    if (this.ou.schoolCommunity) {
      this.pendingRelations[this.ou.schoolCommunity.resource.$$meta.permalink] = {
        ...this.ou.schoolCommunity.resource,
        endDate: this.newSchoolCommunity.startDate,
      };
    }
    const newRelation = {
      ...getKeyAndMeta('/sam/organisationalunits/relations'),
      type: 'IS_MEMBER_OF',
      to: {
        href: this.newSchoolCommunity.href,
        $$expanded: { $$displayName: this.newSchoolCommunity.name },
      },
      from: { href: this.ou.href },
      startDate: this.newSchoolCommunity.startDate,
    };
    this.pendingRelations[newRelation.$$meta.permalink] = newRelation;

    this.calculateVm();
    this.showSchoolCommunityHistory = true;
    this.newSchoolCommunity = null;
  }

  selectKoepel = (koepel) => {
    this.newKoepel.href =
      koepel.$$meta.permalink === KATH_OND_VLA_HREFS.vos
        ? KATH_OND_VLA_HREFS.sam
        : koepel.$$meta.permalink;
    this.newKoepel.name = koepel.name;
  };

  newKoepelStartDateChanged(e, value) {
    this.newKoepelStartDateErrors = [];
    this.newKoepel.startDate = value;
  }

  addKoepel() {
    if (
      !this.newKoepelForm.valid ||
      !this.newKoepel.href ||
      (this.ou.koepel && this.newKoepel.href === this.ou.koepel.href)
    ) {
      this.$ngRedux.dispatch(
        addErrorNotification('Vul alle velden voor de nieuwe koepel correct in.')
      );
      return;
    }
    if (this.ou.koepel) {
      this.pendingRelations[this.ou.koepel.resource.$$meta.permalink] = {
        ...this.ou.koepel.resource,
        endDate: this.newKoepel.startDate,
      };
    }
    const newRelation = {
      ...getKeyAndMeta('/sam/organisationalunits/relations'),
      type: 'IS_MEMBER_OF',
      to: { href: this.newKoepel.href, $$expanded: { $$displayName: this.newKoepel.name } },
      from: { href: this.ou.href },
      startDate: this.newKoepel.startDate,
    };
    this.pendingRelations[newRelation.$$meta.permalink] = newRelation;

    this.calculateVm();
    this.showKoepelHistory = true;
    this.newBestuur = null;
  }
}

export const myRelationsComponents = {
  template: require('./relationsComponents.html'),
  controllerAs: 'ctrl',
  controller: RelationsComponents,
  bindings: {
    ou: '<',
    ouType: '<',
    pendingRelations: '<',
  },
};
