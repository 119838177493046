import { loop, Cmd } from 'redux-loop';
import { formatPhoneNumber } from '@kathondvla/sri-client/contactdetail-utils';
import * as ACTIONS from 'ReduxLoop/ouManager/contactDetails/contactDetailsActions';
import { getAll, storeResource, removeResource } from 'ReduxLoop/utils';
import { mapToArray } from 'ReduxLoop/viewModel';
import {
  addSuccessNotification,
  addErrorNotification,
} from 'ReduxLoop/notifications/notificationActions';
import {
  createContactDetailsResource,
  getLabel,
} from 'ReduxLoop/utils/contactDetailsResourceFactory';
import { SELECT_ORGANISATIONAL_UNIT, CURRENT_OU_FETCHED } from '../schoolManagerActions';

const inititalState = {
  phone: null,
  mail: null,
  email: null,
  website: null,
  phoneErrors: [],
  contactDetailsForm: null,
};

export const contactDetailsReducer = (state = inititalState, action, rootState) => {
  switch (action.type) {
    case SELECT_ORGANISATIONAL_UNIT:
      return { ...inititalState, contactDetailsForm: state.contactDetailsForm };

    case CURRENT_OU_FETCHED: {
      if (action.payload.$$meta.type === 'ORGANISATION') {
        return state;
      }
      if (action.payload.type !== 'SCHOOLENTITY') {
        const mailContactDetail = mapToArray(rootState.userState.ouContactDetails.toJS()).find(
          (cd) =>
            cd.organisationalUnit.href === action.payload.$$meta.permalink && cd.type === 'MAIL'
        );
        if (mailContactDetail) {
          return { ...state, mail: mailContactDetail };
        }
      }
      return loop(
        state,
        Cmd.run(getAll, {
          args: [
            '/sam/organisationalunits/contactdetails',
            { organisationalUnit: action.payload.$$meta.permalink },
          ],
          successActionCreator: ACTIONS.ouContactDetaislFetched,
        })
      );
    }

    case ACTIONS.OU_CONTACT_DETAILS_FETCHED: {
      const newState = { ...state };
      action.payload
        .filter((cd) => !cd.physicalLocation)
        .forEach((cd) => {
          newState[cd.type.toLowerCase()] = cd;
        });
      return newState;
    }

    case ACTIONS.UPDATE_CONTACT_DETAILS_FORM: {
      return { ...state, contactDetailsForm: action.payload };
    }

    case ACTIONS.UPDATE_EMAIL: {
      let newEmail = null;

      if (action.payload) {
        if (!state.email) {
          const type = 'EMAIL';
          const organisationalUnit =
            rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink;
          newEmail = createContactDetailsResource(type, action.payload, organisationalUnit);
        } else {
          newEmail = { ...state.email, value: { email: action.payload } };
        }
      }

      const newState = { ...state, email: newEmail };

      if ((!newEmail && !state.email) || !state.contactDetailsForm.formGroups.email.valid) {
        return newState;
      }

      if (!newEmail && state.email) {
        return loop(
          newState,
          Cmd.run(removeResource, {
            args: [state.email.$$meta.permalink],
            successActionCreator: () =>
              ACTIONS.removeContactDetailSuccess(state.email, 'Het e-mailadres is verwijderd.'),
            failActionCreator: () =>
              addErrorNotification(
                'Er is een onverwachte fout opgetreden bij het verwijderen van het e-mailadres.'
              ),
          })
        );
      }

      return loop(
        newState,
        Cmd.run(storeResource, {
          args: [newState.email],
          successActionCreator: () => ACTIONS.updateContactDetailSuccess(newState.email),
          failActionCreator: (error) =>
            ACTIONS.updateContactDetailFailed(newState.email.type, error),
        })
      );
    }

    case ACTIONS.UPDATE_PHONE: {
      let newPhone = null;
      const phoneErrors = [];

      if (action.payload) {
        let phoneNumber = action.payload;
        newPhone = action.payload;
        try {
          phoneNumber = formatPhoneNumber(action.payload);
        } catch (error) {
          phoneNumber = action.payload;
          phoneErrors.push({ code: 'invalid.format', message: 'Dit is geen geldig telefoonnumer' });
        }

        if (!state.phone) {
          const type = 'PHONE';
          const organisationalUnit =
            rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink;
          newPhone = createContactDetailsResource(type, phoneNumber, organisationalUnit);
        } else {
          newPhone = { ...state.phone, value: { phone: phoneNumber } };
        }
      }

      const newState = { ...state, phone: newPhone, phoneErrors };

      if (
        (!newPhone && !state.phone) ||
        !state.contactDetailsForm.formGroups.phone.valid ||
        phoneErrors.length > 0
      ) {
        return newState;
      }

      if (!newPhone && state.phone) {
        return loop(
          newState,
          Cmd.run(removeResource, {
            args: [state.phone.$$meta.permalink],
            successActionCreator: () =>
              ACTIONS.removeContactDetailSuccess(state.phone, 'Het telefoonnummer is verwijderd'),
            failActionCreator: () =>
              addErrorNotification(
                'Er is een onverwachte fout opgetreden bij het verwijderen van het telefoonnummer.'
              ),
          })
        );
      }

      return loop(
        newState,
        Cmd.run(storeResource, {
          args: [newState.phone, newState.phoneErrors],
          successActionCreator: () => ACTIONS.updateContactDetailSuccess(newState.phone),
          failActionCreator: (error) =>
            ACTIONS.updateContactDetailFailed(newState.phone.type, error),
        })
      );
    }

    case ACTIONS.UPDATE_WEBSITE: {
      let newWebsite = null;

      if (action.payload) {
        if (!state.website) {
          const type = 'WEBSITE';
          const organisationalUnit =
            rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink;
          newWebsite = createContactDetailsResource(type, action.payload, organisationalUnit);
        } else {
          newWebsite = { ...state.website, value: { website: action.payload } };
        }
      }

      const newState = { ...state, website: newWebsite };

      if ((!newWebsite && !state.website) || !state.contactDetailsForm.formGroups.website.valid) {
        return newState;
      }

      if (!newWebsite && state.website) {
        return loop(
          newState,
          Cmd.run(removeResource, {
            args: [state.website.$$meta.permalink],
            successActionCreator: () =>
              ACTIONS.removeContactDetailSuccess(state.website, 'Het website-adres is verwijderd.'),
            failActionCreator: () =>
              addErrorNotification(
                'Er is een onverwachte fout opgetreden bij het verwijderen van het website-adres.'
              ),
          })
        );
      }

      return loop(
        newState,
        Cmd.run(storeResource, {
          args: [newState.website],
          successActionCreator: () => ACTIONS.updateContactDetailSuccess(newState.website),
          failActionCreator: (error) =>
            ACTIONS.updateContactDetailFailed(newState.website.type, error),
        })
      );
    }

    case ACTIONS.UPDATE_ADDRESS: {
      let newAddress = null;

      if (action.payload) {
        if (!state.mail) {
          const type = 'MAIL';
          const organisationalUnit =
            rootState.schoolManager.currentOrganisationalUnit.$$meta.permalink;
          newAddress = createContactDetailsResource(type, action.payload, organisationalUnit);
        } else {
          newAddress = { ...state.mail, value: action.payload };
        }
      }

      const newState = { ...state, mail: newAddress };

      if (!state.contactDetailsForm.formGroups.mail.valid) {
        return newState;
      }

      if (!newAddress && state.mail) {
        return loop(
          newState,
          Cmd.run(removeResource, {
            args: [state.mail.$$meta.permalink],
            successActionCreator: () =>
              ACTIONS.removeContactDetailSuccess(state.mail, 'Het adres is verwijderd.'),
            failActionCreator: () =>
              addErrorNotification(
                'Er is een onverwachte fout opgetreden bij het verwijderen van het adres.'
              ),
          })
        );
      }

      if (newState.mail.value.streetHref === null) {
        newState.mail.value.streetHref = undefined; // api does not accept null
      }
      return loop(
        newState,
        Cmd.run(storeResource, {
          args: [newState.mail],
          successActionCreator: () => ACTIONS.updateContactDetailSuccess(newState.mail),
          failActionCreator: (error) =>
            ACTIONS.updateContactDetailFailed(newState.mail.type, error),
        })
      );
    }

    case ACTIONS.UPDATE_CONTACT_DETAIL_SUCCESS: {
      return loop(
        state,
        Cmd.action(
          addSuccessNotification(`Het ${getLabel(action.payload.contactDetail.type)} is opgeslagen`)
        )
      );
    }

    case ACTIONS.REMOVE_CONTACT_DETAIL_SUCCESS: {
      return loop(state, Cmd.action(addSuccessNotification(action.payload.successMessage)));
    }

    case ACTIONS.UPDATE_CONTACT_DETAIL_FAILED: {
      if (action.payload.error && action.payload.error.body && action.payload.error.body.errors) {
        const validationError = action.payload.error.body.errors[0];
        if (validationError.code === 'duplicate.contactdetail.priority') {
          const cd = state[action.payload.type.toLowerCase()];
          const newCd = { ...cd, priority: cd.priority - 1 };
          return loop(
            { ...state, [action.payload.type.toLowerCase()]: newCd },
            Cmd.run(storeResource, {
              args: [newCd],
              successActionCreator: () => ACTIONS.updateContactDetailSuccess(newCd),
              failActionCreator: (error) => ACTIONS.updateContactDetailFailed(cd.type, error),
            })
          );
        }
      }
      return loop(
        state,
        Cmd.action(
          addErrorNotification(
            `Er is een onverwachte fout opgetreden bij het opslaan van het ${getLabel(
              action.payload.type
            )}.`
          )
        )
      );
    }

    default:
      return state;
  }
};
