import { isOverlapping } from '@kathondvla/sri-client/date-utils';
import { Map, fromJS } from 'immutable';
import { loop, Cmd } from 'redux-loop';
import {
  createClass,
  createSchoolClassRelation,
  createCampusClassRelation,
} from 'ReduxLoop/utils/apiResourceFactory';
import * as ACTIONS from 'ReduxLoop/ouManager/classes/classesActions';
import { fetchClassesInfo, storeClassInfo, deleteClassInfo } from 'ReduxLoop/utils';
import { arrayToMap } from 'ReduxLoop/index';
import { SCHOOLENTITY } from 'ReduxLoop/constants';
import { CURRENT_OU_FETCHED } from 'ReduxLoop/ouManager/schoolManagerActions';

const findOuClassRelation = (state, clazz) => {
  return state.ouClasses.find((ouClass) => {
    return (
      ouClass.getIn(['from', 'href']) === clazz.getIn(['$$meta', 'permalink']) &&
      isOverlapping(
        {
          startDate: clazz.get('startDate'),
          endDate: clazz.get('endDate'),
        },
        {
          startDate: ouClass.get('startDate'),
          endDate: ouClass.get('endDate'),
        }
      )
    );
  });
};

const findCampusClassRelation = (state, clazz) => {
  return state.campusClasses.find(
    (campusClass) =>
      campusClass.getIn(['organisationalUnit', 'href']) === clazz.getIn(['$$meta', 'permalink']) &&
      isOverlapping(
        {
          startDate: clazz.get('startDate'),
          endDate: clazz.get('endDate'),
        },
        {
          startDate: campusClass.get('startDate'),
          endDate: campusClass.get('endDate'),
        }
      )
  );
};

const inititalState = {
  campuses: Map(),
  classes: Map(),
  ouClasses: Map(),
  campusClasses: Map(),
  okIds: [],
  filter: '',
  errors: [],
};

export const classesReducer = (state = inititalState, action, rootState) => {
  let updatedState;

  switch (action.type) {
    case CURRENT_OU_FETCHED:
      if (action.payload.type !== SCHOOLENTITY) {
        return state;
      }
      return loop(
        state,
        Cmd.run(fetchClassesInfo, {
          args: [action.payload],
          successActionCreator: ACTIONS.classesInfoFetchedAction,
          failActionCreator: (error) => ACTIONS.classActionFailed(error, state),
        })
      );

    case ACTIONS.CLASSES_INFO_FETCHED:
      return {
        ...state,
        campuses: fromJS(arrayToMap(action.payload.campuses)),
        classes: fromJS(arrayToMap(action.payload.classesInfo.classes)),
        ouClasses: fromJS(arrayToMap(action.payload.classesInfo.ouClasses)),
        campusClasses: fromJS(arrayToMap(action.payload.classesInfo.campusClasses)),
        okIds: action.payload.okIds,
      };

    case ACTIONS.ADD_CLASS: {
      const clazz = createClass(action.payload.name, action.payload.startDate);
      const schoolClassRelation = createSchoolClassRelation(
        clazz,
        rootState.schoolManager.currentOrganisationalUnit,
        action.payload.startDate
      );
      const campusClassRelation = createCampusClassRelation(
        clazz,
        action.payload.campusPermalink,
        action.payload.startDate
      );

      updatedState = {
        classes: state.classes.set(clazz.$$meta.permalink, fromJS(clazz)),
        ouClasses: state.ouClasses.set(
          schoolClassRelation.$$meta.permalink,
          fromJS(schoolClassRelation)
        ),
        campusClasses: state.campusClasses.set(
          campusClassRelation.$$meta.permalink,
          fromJS(campusClassRelation)
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeClassInfo, {
          args: [[clazz, schoolClassRelation, campusClassRelation]],
          failActionCreator: (error) => ACTIONS.classActionFailed(error, state),
        })
      );
    }

    case ACTIONS.END_CLASS: {
      const newEndDate = action.payload.endDate || undefined;

      let classToEnd = state.classes.get(action.payload.clazz.permalink);
      const classNamesToEnd = classToEnd
        .get('names')
        .map((name) => name.set('endDate', newEndDate));
      classToEnd = classToEnd.set('names', classNamesToEnd).set('endDate', newEndDate);

      const schoolClassRelationToEnd = findOuClassRelation(state, classToEnd).set(
        'endDate',
        newEndDate
      );
      const campusClassRelationToEnd = findCampusClassRelation(state, classToEnd).set(
        'endDate',
        newEndDate
      );

      updatedState = {
        classes: state.classes.set(action.payload.clazz.permalink, classToEnd),
        ouClasses: state.ouClasses.set(
          campusClassRelationToEnd.getIn(['$$meta', 'permalink']),
          schoolClassRelationToEnd
        ),
        campusClasses: state.campusClasses.set(
          campusClassRelationToEnd.getIn(['$$meta', 'permalink']),
          campusClassRelationToEnd
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeClassInfo, {
          args: [
            [classToEnd.toJS(), schoolClassRelationToEnd.toJS(), campusClassRelationToEnd.toJS()],
          ],
          failActionCreator: (error) => ACTIONS.classActionFailed(error, state),
        })
      );
    }

    case ACTIONS.DELETE_CLASS: {
      const classToDelete = state.classes.get(action.payload.clazz.permalink);
      const schoolClassRelationToDelete = findOuClassRelation(state, classToDelete);
      const campusClassRelationToDelete = findCampusClassRelation(state, classToDelete);

      updatedState = {
        classes: state.classes.delete(action.payload.clazz.permalink),
        ouClasses: state.ouClasses.delete(
          schoolClassRelationToDelete.getIn(['$$meta', 'permalink'])
        ),
        campusClasses: state.campusClasses.delete(
          campusClassRelationToDelete.getIn(['$$meta', 'permalink'])
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(deleteClassInfo, {
          args: [
            [
              classToDelete.toJS(),
              schoolClassRelationToDelete.toJS(),
              campusClassRelationToDelete.toJS(),
            ],
          ],
          failActionCreator: (error) => ACTIONS.classActionFailed(error, state),
        })
      );
    }

    case ACTIONS.EDIT_CLASS: {
      let classToUpdate = state.classes.get(action.payload.clazz.permalink);
      const classNameToUpdate = classToUpdate
        .get('names')
        .map((name) =>
          name.set('value', action.payload.name).set('startDate', action.payload.startDate)
        );
      classToUpdate = classToUpdate
        .set('$$displayName', action.payload.name)
        .set('names', classNameToUpdate)
        .set('startDate', action.payload.startDate);

      const schoolClassRelationToUpdate = findOuClassRelation(state, classToUpdate).set(
        'startDate',
        action.payload.startDate
      );
      const campusClassRelationToUpdate = findCampusClassRelation(state, classToUpdate)
        .set('startDate', action.payload.startDate)
        .setIn(['physicalLocation', 'href'], action.payload.campusPermalink);

      updatedState = {
        classes: state.classes.set(action.payload.clazz.permalink, classToUpdate),
        ouClasses: state.ouClasses.set(
          schoolClassRelationToUpdate.getIn(['$$meta', 'permalink']),
          schoolClassRelationToUpdate
        ),
        campusClasses: state.campusClasses.set(
          campusClassRelationToUpdate.getIn(['$$meta', 'permalink']),
          campusClassRelationToUpdate
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeClassInfo, {
          args: [
            [
              classToUpdate.toJS(),
              schoolClassRelationToUpdate.toJS(),
              campusClassRelationToUpdate.toJS(),
            ],
          ],
          failActionCreator: (error) => ACTIONS.classActionFailed(error, state),
        })
      );
    }

    case ACTIONS.APPLY_FILTER:
      return { ...state, filter: action.payload };

    case ACTIONS.CLASS_ACTION_FAILED:
      state.errors.push(action.payload.error);
      return { ...state, errors: state.errors };

    default:
      return state;
  }
};
