import { loop, Cmd } from 'redux-loop';
import { getEndOfSchoolYear, getNow, printDate } from '@kathondvla/sri-client/date-utils';
import { printAddress } from '@kathondvla/sri-client/address-utils';
import { settings } from 'Js/config/settings';
import {
  getPossiblePositionsForOu,
  getRequest,
  postAcceptRequest,
  postDeclineRequest,
} from 'ReduxLoop/ouManager/team/teamDataAccess';
import {
  getRequestDescription,
  getResponsibilityPermalink,
} from 'ReduxLoop/utils/responsibilityUtils';
import { storeResource, goTo, getRaw } from 'ReduxLoop/utils';
import { createRequest, createResponsibility } from 'ReduxLoop/utils/apiResourceFactory';
import {
  COORDINEREND_DIRECTEUR,
  DIRECTEUR,
  DIRECTEUR_COORDINATIE,
  GOVERNINGINSTITUTION,
  LEERPLANCOMMISSIE,
  LERARENNETWERK,
  LID,
  SCHOOLCOMMUNITY,
  STUDENT,
  VERGADERGROEP,
  VSKO_VERANTWOORDELIJKE,
} from 'ReduxLoop/constants';
import { displayOuType, OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import { fetchUser, setLogInCmd } from 'ReduxLoop/user/userActions';
import { sendEmailToOtrsHelpdesk } from 'ReduxLoop/registerAndConfirmInvitation/inviteConfirmation/invitationCommands';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { EXECUTE_REGISTRATION_FAILED } from 'ReduxLoop/constants/actionTypes';
import * as ACTIONS from './requestResponsibilityActions';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

const initialState = {
  ou: null,
  startDate: getNow(),
  position: null,
  positionKey: null,
  emptyPositionSelected: false,
  roleDescription: null,
  ouEditable: true,
  positionEditable: true,
  startDateEditable: true,
  ouErrors: [],
  positionErrors: [{ message: 'Selecteer een functie.' }],
  startDateErrors: [],
  ouTypeDescription: null,
  ouTypeFilter: null,
  mainstructureFilter: null,
  possiblePositions: null,
  apiError: null,
  processingRequest: false,
  request: null,
  action: null,
  success: null,
  confirmErrors: [],
  loading: false,
  noManagers: false,
  managerNames: null,
};

const getManagerDescreption = (rootState) => {
  const { user } = rootState.userState;
  let managerDescription = `${user.firstName} ${user.lastName}`;
  if (rootState.userState.isSuperAdmin) {
    managerDescription += ', medewerker van Katholiek Onderwijs Vlaanderen,';
  }
  return managerDescription;
};

const createNoManagersReport = (state, rootState, params) => {
  let ouName = state.ou.name;
  if (state.ou.institutionNumber) {
    ouName += ` (${state.ou.institutionNumber})`;
  }
  let address = '(organisatie heeft geen adres)';
  if (state.ou.mailAddresses && state.ou.mailAddresses.length > 0) {
    address = printAddress(state.ou.mailAddresses[0].address);
  }
  if (state.ou.locations && state.ou.locations.length > 0) {
    address = printAddress(state.ou.locations[0].address);
  }
  let role = '(geen van bovenstaanden)';
  if (state.position) {
    role = state.position.name;
  } else if (state.roleDescription) {
    role += ` -> ${state.roleDescription}`;
  }
  return {
    ...params,
    ouType: `${state.ou.types[0]} (${state.ouTypeDescription})`,
    ouName,
    role,
    address,
    startDate: state.startDate,
  };
};

export const requestResponsibilityReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTIONS.INITIALISE_REQUEST_RESPONSIBILITY: {
      const newState = { ...initialState };
      const cmdList = [];
      if (
        !rootState.userState.user &&
        !(rootState.register.stage && rootState.register.stage === 'ADD_REQUEST_TO_REGISTRATION')
      ) {
        let questionMarkAdded = false;
        let uri = '/#!/aanmelden';
        if (action.payload.ou) {
          questionMarkAdded = true;
          uri += `?ou=${action.payload.ou}`;
        }
        if (action.payload.start) {
          questionMarkAdded = true;
          uri += `${questionMarkAdded ? '&' : '?'}start=${action.payload.start}`;
        }
        if (action.payload.functie) {
          questionMarkAdded = true;
          uri += `${questionMarkAdded ? '&' : '?'}functie=${action.payload.functie}`;
        }
        cmdList.push(Cmd.action(setLogInCmd(Cmd.run(goTo, { args: [uri] }))));
        cmdList.push(Cmd.action(fetchUser()));
        cmdList.push(Cmd.action(setLogInCmd(null)));
      }
      if (action.payload.ou) {
        newState.ouEditable = false;
        if (state.ou && state.ou.key === action.payload.ou) {
          newState.ou = state.ou;
          newState.possiblePositions = state.possiblePositions;
          newState.displayType = state.displayType;
          newState.typeArticle = state.typeArticle;
        } else {
          newState.loading = true;
          cmdList.push(
            Cmd.run(getRaw, {
              args: [
                '/search',
                {
                  types: 'organisational_unit',
                  key: action.payload.ou,
                  expand: 'full',
                },
              ],
              successActionCreator: (resp) => ACTIONS.selectOuForRequest(resp.results[0]),
              failActionCreator: () =>
                addErrorNotification(
                  'Er is een connectieprobleem met onze zoekmachine. Onze excuses. Probeer het later nog eens opniuew.'
                ),
            })
          );
        }
      }
      if (action.payload.start) {
        newState.startDate = action.payload.start;
        newState.startDateEditable = false;
      }
      if (action.payload.functie) {
        newState.positionKey = action.payload.functie;
        if (state.position && state.position.key === action.payload.functie) {
          newState.position = state.position;
          newState.positionErrors = state.positionErrors;
        }
        newState.positionEditable = false;
      }
      return loop(newState, Cmd.list(cmdList, { sequence: true }));
    }

    case ACTIONS.SELECT_OU_TYPE_FOR_REQUEST: {
      return {
        ...state,
        ouTypeFilter: action.payload.ouType,
        ouTypeDescription: action.payload.display.match(/<strong>(.+)<\/strong>/)[1],
        mainstructureFilter: action.payload.mainstructures,
        displayType: action.payload.displayType,
        ouErrors: [{ message: `Selecteer een ${action.payload.displayType}` }],
        typeArticle: action.payload.typeArticle,
      };
    }

    case ACTIONS.SELECT_OU_FOR_REQUEST: {
      if (!action.payload) {
        // TODO if loading is true => show error message that link is wrong
        return initialState;
      }
      const errors = action.payload ? [] : [{ message: `Selecteer een ${state.displayType}` }];
      return loop(
        {
          ...state,
          ou: action.payload,
          loading: false,
          displayType: state.displayType || displayOuType(action.payload.types[0]),
          typeArticle: [GOVERNINGINSTITUTION, 'CLB', 'BOARDING', LERARENNETWERK].some(
            (hetType) => hetType === action.payload.types[0]
          )
            ? 'het'
            : 'de',
          startDate:
            action.payload.startDate > state.startDate ? action.payload.startDate : state.startDate,
          ouErrors: errors,
          apiError: null,
        },
        Cmd.run(getPossiblePositionsForOu, {
          args: [getResponsibilityPermalink(action.payload), 'FULL'],
          successActionCreator: ACTIONS.possiblePositionsForRequestFetched,
        })
      );
    }

    case ACTIONS.POSSIBLE_POSITIONS_FOR_REQUEST_FETCHED: {
      const newState = { ...state, possiblePositions: action.payload };
      if (state.positionKey) {
        newState.position = action.payload.find((position) => position.key === state.positionKey);
        newState.positionErrors = [];
        if (!newState.position) {
          newState.positionErrors.push({
            message: `De functie uit de url is geen geldige functie voor ${state.ou.name}`,
          });
        }
      } else if (
        [LERARENNETWERK, VERGADERGROEP, LEERPLANCOMMISSIE].some((type) => type === state.ou.type)
      ) {
        newState.position = action.payload.find((position) => position.$$meta.permalink === LID);
        newState.positionErrors = [];
      }
      return newState;
    }

    case ACTIONS.CHANGE_POSITION_FOR_REQUEST: {
      const errors = !action.payload ? [{ message: 'Selecteer een functie.' }] : [];
      if (!action.payload) {
        return {
          ...state,
          position: null,
          emptyPositionSelected: false,
          positionErrors: errors,
        };
      }
      if (action.payload.none) {
        return {
          ...state,
          position: null,
          emptyPositionSelected: true,
          positionErrors: errors,
        };
      }
      const position = state.possiblePositions.filter(
        (possiblePosition) => possiblePosition.$$meta.permalink === action.payload.permalink
      )[0];
      if (!position) {
        errors.push({
          message: `De geselecteerde functie is geen geldige functie voor ${state.ou.name}`,
        });
      }
      return {
        ...state,
        position,
        emptyPositionSelected: false,
        positionErrors: errors,
      };
    }

    case ACTIONS.CHANGE_ROLE_DESCRIPTION_FOR_REQUEST: {
      return { ...state, roleDescription: action.payload };
    }

    case ACTIONS.CHANGE_STARTDATE_FOR_REQUEST: {
      const errors = [];
      if (!action.payload) {
        errors.push({ message: 'Geef een startdatum op.' });
      }
      if (state.ou && state.ou.startDate > action.payload) {
        errors.push({
          message: `${state.ou.name} start pas in ${printDate(
            state.ou.startDate
          )}. De startdatum moet groter zijn dan ${printDate(state.ou.startDate)}.`,
        });
      }
      return { ...state, startDate: action.payload, startDateErrors: errors };
    }

    case ACTIONS.REQUEST_RESPONSIBILITY: {
      let responsibility = null;
      const request = createRequest(
        rootState.userState.user.$$meta.permalink,
        state.ou,
        state.position ? state.position.$$meta.permalink : null,
        state.startDate
      );
      request.remark = state.roleDescription ? state.roleDescription : undefined;
      const ouType = state.ou.types[0];
      if (
        ouType === LERARENNETWERK ||
        (state.position && state.position.$$meta.permalink === STUDENT)
      ) {
        const ouPermalink = [SCHOOLCOMMUNITY, 'SCHOOL', 'BOARDING'].some(
          (vosOuType) => vosOuType === ouType
        )
          ? state.ou.$$meta.vosPermalink
          : state.ou.$$meta.permalink;
        responsibility = createResponsibility(
          ouPermalink,
          rootState.userState.user.$$meta.permalink,
          state.position ? state.position.$$meta.permalink : null,
          state.startDate
        );
        responsibility.remark = state.roleDescription ? state.roleDescription : undefined;
        if (state.position && state.position.$$meta.permalink === STUDENT) {
          responsibility.endDate = getEndOfSchoolYear();
        }
      }
      if (state.ou.$$meta.vosPermalink && (ouType === GOVERNINGINSTITUTION || ouType === 'CLB')) {
        request.vosOrganisationalUnit = { href: state.ou.$$meta.vosPermalink };
      }
      const typeConfig =
        OU_TYPE_CONFIG[ouType] ||
        Object.values(OU_TYPE_CONFIG).find(
          (ouTypeConfig) => ouTypeConfig.types && ouTypeConfig.types.some((type) => type === ouType)
        );
      if (typeConfig) {
        request.to = typeConfig.managers;
        if (typeConfig.admins) {
          request.to = [...request.to, ...typeConfig.admins];
        }
      } else if (ouType === 'SCHOOL') {
        request.to = [DIRECTEUR, VSKO_VERANTWOORDELIJKE];
      } else if (ouType === 'BOARDING') {
        request.to = [DIRECTEUR, VSKO_VERANTWOORDELIJKE];
      } else if (ouType === SCHOOLCOMMUNITY) {
        request.to = [COORDINEREND_DIRECTEUR, DIRECTEUR_COORDINATIE, VSKO_VERANTWOORDELIJKE];
      }

      if (responsibility) {
        return loop(
          state,
          Cmd.run(storeResource, {
            args: [responsibility],
            successActionCreator: () => ACTIONS.tryCreatingResponsibilitySucceeded(responsibility),
            failActionCreator: (error) => ACTIONS.tryCreatingResponsibilityFailed(error, request),
          })
        );
      }

      return loop(
        state,
        Cmd.run(storeResource, {
          args: [request],
          successActionCreator: (apiResponse) => ACTIONS.requestResponsibilitySucceeded(request, state.ou, apiResponse),
          failActionCreator: ACTIONS.requestResponsibilityFailed,
        })
      );
    }

    case ACTIONS.TRY_CREATING_RESPONSIBILITY_SUCCEEDED: {
      return loop(
        { ...state },
        Cmd.run(goTo, { args: [`/#!/mail-verzonden?type=rol-toegevoegd&ou=${state.ou.name}`] })
      );
    }

    case ACTIONS.TRY_CREATING_RESPONSIBILITY_FAILED: {
      if (action.payload.error && action.payload.error.status === 403) {
        return loop(
          state,
          Cmd.run(storeResource, {
            args: [action.payload.request],
            successActionCreator: () =>
              ACTIONS.requestResponsibilitySucceeded(action.payload.request, state.ou),
            failActionCreator: ACTIONS.requestResponsibilityFailed,
          })
        );
      }
      let apiError = 'Er is een onverwachte fout opgetreden. Onze excuses.';
      if (action.payload.error instanceof SriClientError && action.payload.error.status === 409) {
        if (action.payload.error.body.errors[0].code === 'overlapping.period') {
          if (state.position) {
            apiError = `Je bent al ${state.position.name} in ${state.ou.name}.`;
          } else {
            apiError = `Je zit al in het team van ${state.ou.name}.`;
          }
        }
      }
      return { ...state, apiError };
    }

    case ACTIONS.REQUEST_RESPONSIBILITY_SUCCEEDED: {
      return loop(
        { ...state, managerNames: action.payload.apiResponse.managerNames, requestResponsibilitySucceeded: true },
        Cmd.run(goTo, { args: ['/#!/mail-verzonden?type=aanvraag'] })
      );
    }

    case ACTIONS.REQUEST_RESPONSIBILITY_FAILED: {
      let apiError = 'Er is een onverwachte fout opgetreden. Onze excuses.';
      if (action.payload instanceof SriClientError && action.payload.status === 409) {
        if (action.payload.body.errors[0].code === 'overlapping.period') {
          if (state.position) {
            apiError = `Je hebt al een openstaande aanvraag als ${state.position.name} in ${state.ou.name}.`;
          } else {
            apiError = `Je hebt al een openstaande aanvraag in het team van ${state.ou.name}.`;
          }
        } else if (action.payload.body.errors[0].code === 'overlapping.responsibility') {
          if (state.position) {
            apiError = `Je bent al ${state.position.name} in ${state.ou.name}.`;
          } else {
            apiError = `Je zit al in het team van ${state.ou.name}.`;
          }
        } else if (action.payload.body.errors[0].code === 'no.managers') {
          const report = createNoManagersReport(state, rootState, {
            subject: 'Geen beheerders voor organisatie bij aanvraag vanuit profiel',
            description: `Deze persoon geeft aan dat hij actief is in ${state.ou.name} maar er zijn geen beheerders voor deze organisatie om de aanvraag goed te keuren. Bekijk of we ${state.ou.name} moeten contacteren om te kijken wie beheerder moet zijn.`,
            personHref: rootState.userState.user.$$meta.permalink,
            firstName: rootState.userState.user.firstName,
            lastName: rootState.userState.user.lastName,
            emailAddress: rootState.userState.user.$$email,
          });
          const args = [report];
          if (['VERGADERGROEP', 'LEERPLANCOMMISSIE'].some((type) => type === state.ou.type)) {
            args.push(settings.loketResponsible.email);
          } else if (state.ou.type === LERARENNETWERK) {
            args.push('lerarennetwerk@katholiekonderwijs.vlaanderen');
          }
          return loop(
            { ...state, requestResponsibilitySucceeded: true },
            Cmd.list([
              Cmd.run(sendEmailToOtrsHelpdesk, { args }),
              Cmd.run(goTo, {
                args: [`/#!/mail-verzonden?type=aanvraag&geen-beheerders=true&ou=${state.ou.name}`],
              }),
            ])
          );
          // apiError = 'Er is geen leidinggevende voor ' + state.ou.name + ' geregistreerd om jouw aanvraag goed te keuren. Vraag aan je leidinggevende om contact op te nemen met Katholiek Onderwijs Vlaanderen.';
        } else {
          console.error(action.payload);
        }
      }
      return { ...state, apiError };
    }

    case ACTIONS.APPROVE_REQUEST: {
      return loop(
        state,
        Cmd.run(postAcceptRequest, {
          args: [action.payload.requestPermalink, getManagerDescreption(rootState)],
          successActionCreator: (responseBody) =>
            ACTIONS.approveRequestSucceeded(responseBody, action.payload.requestPermalink),
          failActionCreator: (error) =>
            ACTIONS.approveRequestFailed(error, action.payload.requestPermalink),
        })
      );
    }

    case ACTIONS.DECLINE_REQUEST: {
      return loop(
        state,
        Cmd.run(postDeclineRequest, {
          args: [action.payload.requestPermalink, getManagerDescreption(rootState)],
          successActionCreator: (responseBody) =>
            ACTIONS.approveRequestSucceeded(responseBody, action.payload.requestPermalink),
          failActionCreator: (error) =>
            ACTIONS.approveRequestFailed(error, action.payload.requestPermalink),
        })
      );
    }

    case ACTIONS.INITIALISE_HANDLE_REQUEST: {
      if (
        !action.payload.actie ||
        (action.payload.actie.toUpperCase() !== 'GOEDKEUREN' &&
          action.payload.actie.toUpperCase() !== 'AFWIJZEN')
      ) {
        return { ...state, confirmErrors: [{ message: 'Deze link is ongeldig' }] };
      }
      const requestPermalink = `/responsibilities/pending/${action.payload.key}`;
      const cmdList = [
        Cmd.action(fetchUser()),
        Cmd.run(getRequest, {
          args: [requestPermalink],
          successActionCreator: ACTIONS.requestFetched,
          failActionCreator: ACTIONS.fetchRequestFailed,
        }),
      ];
      return loop(
        { ...initialState, processingRequest: true, action: action.payload.actie.toUpperCase() },
        Cmd.list(cmdList, { sequence: true })
      );
    }

    case ACTIONS.REQUEST_FETCHED: {
      const request = action.payload;
      const hem = request.from.$$expanded.sex === 'MALE' ? 'hem' : 'haar';
      const description = getRequestDescription(request);
      if (request.status === 'EXPIRED') {
        return {
          ...state,
          request,
          processingRequest: false,
          confirmErrors: [
            {
              message: `Deze aanvrag van ${description} is vervallen. Je kan ${hem} zelf een uitnoding sturen.`,
            },
          ],
        };
      }
      if (request.status !== 'PENDING') {
        const decision = request.status === 'DECLINED' ? 'afgewezen' : 'goedgekeurd';
        return {
          ...state,
          request,
          processingRequest: false,
          confirmErrors: [
            {
              message: `Deze aanvraag van ${description} is al ${decision} door een collega.`,
            },
          ],
        };
      }
      if (state.action === 'GOEDKEUREN') {
        return loop(
          { ...state, request },
          Cmd.action(ACTIONS.approveRequest(request.$$meta.permalink))
        );
      }
      return loop(
        { ...state, request },
        Cmd.action(ACTIONS.declineRequest(request.$$meta.permalink))
      );
    }

    case ACTIONS.FETCH_REQUEST_FAILED: {
      if (action.payload.status === 403) {
        return {
          ...state,
          processingRequest: false,
          confirmErrors: [
            {
              message:
                'Je hebt onvoldoende rechten om deze aanvraag te behandelen. Meld je aan met de juiste account.',
            },
          ],
        };
      }
      if (action.payload.status === 404) {
        return {
          ...state,
          processingRequest: false,
          confirmErrors: [{ message: 'Deze link is ongeldig' }],
        };
      }
      if (action.payload.status === 410) {
        return {
          ...state,
          processingRequest: false,
          confirmErrors: [
            {
              message:
                'Deze aanvraag is ondertussen verwijderd. Je hoeft geen actie te ondernemen.',
            },
          ],
        };
      }
      return {
        ...state,
        processingRequest: false,
        confirmErrors: [
          {
            message:
              'Er is een onverwachte fout opgetreden bij het ophalen van de aanvraag. Probeer het later nog eens opnieuw.',
          },
        ],
      };
    }

    case ACTIONS.APPROVE_REQUEST_SUCCEEDED: {
      const person = state.request.from.$$expanded;
      const zijn = person.sex === 'MALE' ? 'zijn' : 'haar';
      const hij = person.sex === 'MALE' ? 'Hij' : 'Zij';
      const ouName = state.request.organisationalUnit.$$expanded.$$displayName
        ? state.request.organisationalUnit.$$expanded.$$displayName
        : state.request.organisationalUnit.$$expanded.$$name;
      let success = null;
      if (state.action === 'AFWIJZEN') {
        success = `<span class="text-purple">${person.firstName} ${
          person.lastName
        }</span> ${zijn} aanvraag ${
          state.request.position
            ? `als <span class="text-purple">${state.request.position.$$expanded.name}</span> `
            : ''
        } in <span class="text-purple">${ouName}</span> is <span class="text-red">afgewezen</span>. ${hij} ontvangt hiervan een bericht via mail.`;
      } else {
        success = `<span class="text-purple">${person.firstName} ${
          person.lastName
        }</span> is nu toegevoegd ${
          state.request.position
            ? `als <span class="text-purple">${state.request.position.$$expanded.name}</span> `
            : ''
        } aan het team van <span class="text-purple">${ouName}</span>. ${hij} ontvangt een bevestiging hiervan via mail.`;
      }
      return { ...state, processingRequest: false, success };
    }

    case ACTIONS.APPROVE_REQUEST_FAILED: {
      if (action.payload.status === 403) {
        return {
          ...state,
          processingRequest: false,
          confirmErrors: [
            {
              message:
                'Je hebt onvoldoende rechten om deze aanvraag te behandelen. Meld je aan met de juiste account.',
            },
          ],
        };
      }
      if (!state.action) {
        // approve is happening from team screen
        return { ...state, processingRequest: false };
      }
      if (
        action.payload.error instanceof SriClientError &&
        action.payload.error.body.errors.some((error) => error.code === 'overlapping.period')
      ) {
        const person = state.request.from.$$expanded;
        const ouName = state.request.organisationalUnit.$$expanded.$$displayName
          ? state.request.organisationalUnit.$$expanded.$$displayName
          : state.request.organisationalUnit.$$expanded.$$name;
        const message = `<span class="text-purple">${person.firstName} ${
          person.lastName
        }</span> is ondertussen al ${
          state.request.position
            ? `<span class="text-purple">${state.request.position.$$expanded.name}</span> `
            : 'geregistreerd'
        } in <span class="text-purple">${ouName}</span>. Je mag deze aanvraag gewoon afwijzen.`;
        return { ...state, processingRequest: false, confirmErrors: [{ message }] };
      }
      // TODO handle overlapping resp error
      return {
        ...state,
        processingRequest: false,
        confirmErrors: [
          {
            message: `Er is een onverwachte fout opgetreden bij het ${state.action.toLowerCase()} van de aanvraag. Probeer het later nog eens opnieuw.`,
          },
        ],
      };
    }

    case ACTIONS.GO_FROM_CONFIRM_REQUEST_TO_TEAM: {
      let url = null;
      if (
        ['SCHOOL', 'CVO', 'BOARDING', SCHOOLCOMMUNITY].some(
          (ouType) => ouType === state.request.organisationalUnit.$$expanded.type
        )
      ) {
        url = `${settings.applications.mijn1}/#/team/${state.request.organisationalUnit.$$expanded.institutionNumber}`;
      }
      url = `/#!/${displayOuType(state.request.organisationalUnit.$$expanded.type)}/${
        state.request.organisationalUnit.$$expanded.key
      }/team`;
      return loop(state, Cmd.run(goTo, { args: [url] }));
    }

    case EXECUTE_REGISTRATION_FAILED: {
      const request = action.payload.body.filter((batchObj) =>
        batchObj.href.match(/\/responsibilities\/pending\/[0-9a-f-]{36}/)
      )[0];
      if (
        request &&
        request.status === 409 &&
        request.body.errors.some((error) => error.code === 'no.managers')
      ) {
        const report = createNoManagersReport(state, rootState, {
          subject: 'Geen beheerders voor organisatie bij REGISTRATIE',
          description: `Deze persoon gaf aan bij het registreren dat hij actief is in ${state.ou.name} maar er zijn geen beheerders voor deze organisatie om de aanvraag goed te keuren. Bekijk of we ${state.ou.name} moeten contacteren om te kijken wie beheerder moet zijn. Hou er rekening mee dat deze persoon pas in de databank zit als hij zijn registratie bevestigd heeft via e-mail.`,
          personHref: action.payload.body.filter((batchObj) =>
            batchObj.href.match(/\/persons\/[0-9a-f-]{36}/)
          )[0].href,
          firstName: rootState.register.firstName,
          lastName: rootState.register.lastName,
          emailAddress: rootState.register.emailAddress,
        });
        return loop(
          { ...state, noManagers: true },
          Cmd.list([
            Cmd.action(ACTIONS.addRequestToRegistration()),
            Cmd.run(sendEmailToOtrsHelpdesk, { args: [report] }),
          ])
        );
      }
      return state;
    }

    default:
      return state;
  }
};
