import { printAddress } from '@kathondvla/sri-client/address-utils';
import {
  getAbolishedResources,
  getActiveResources,
  getNonAbolishedResources,
  getStartOfSchoolYearIncludingSummerGap,
  getNow,
  getPreviousDay,
  isAfter,
  isBefore,
  isBeforeOrEqual,
  printDate,
  printFutureForPeriodic,
} from '@kathondvla/sri-client/date-utils';
import { orderBy } from 'lodash';
import {
  GROUP_MANAGER,
  DIRECTEUR,
  BEHEERDER,
  COORDINATOR,
  BESTUURDER,
  LID,
  LID_VAN_HET_DAGELIJKS_BESTUUR,
  LID_VAN_DE_ALGEMENE_VERGADERING,
  NASCHOLINGSVERANTWOORDELIJKE,
} from 'ReduxLoop/constants';
import { OU_TYPE_CONFIG, GROUP_POSITIONS } from 'ReduxLoop/appConfig';
import { addWorkgroupInfo } from 'ReduxLoop/ouManager/workgroups/workgroupsModel';
import { settings } from 'Js/config/settings';
import { mapToArray } from '../viewModel';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export const getOuName = (ou) => {
  if (!ou) {
    return '';
  }
  if (ou.$$meta.type === 'ORGANISATION') {
    return ou.$$name;
  }
  return ou.$$displayName;
};

export const getCurrentOuMailAddress = (state) => {
  if (!state.schoolManager.currentOrganisationalUnit) {
    return null;
  }
  if (state.schoolManager.currentOrganisationalUnit.$$meta.type === 'ORGANISATION') {
    return getActiveResources(state.schoolManager.currentOrganisationalUnit.seatAddresses).map(
      (sa) => sa.address
    )[0];
  }
  if (!state.contactDetails.mail) {
    return null;
  }
  return state.contactDetails.mail.value;
};

export const getGoverningInstitution = (state) => {
  const { governingInstitution } = state.schoolManager;

  if (
    governingInstitution &&
    governingInstitution.ou !== null &&
    governingInstitution.mailAddress !== null
  ) {
    return {
      name: governingInstitution.ou.$$displayName,
      address: printAddress(governingInstitution.mailAddress.value),
    };
  }

  return null;
};

export const getCurrentOrganisationalUnit = (state, currentUserRole, ouTypeConfigKey) => {
  if (!state.schoolManager.currentOrganisationalUnit) {
    return { addresses: [], teamMetaInfo: {} };
  }
  const ou = {
    permalink: state.schoolManager.currentOrganisationalUnit.$$meta.permalink,
    key: state.schoolManager.currentOrganisationalUnit.key,
    name: getOuName(state.schoolManager.currentOrganisationalUnit),
    startDate:
      state.schoolManager.currentOrganisationalUnit.$$meta.type === 'ORGANISATION'
        ? state.schoolManager.currentOrganisationalUnit.$$startDate
        : state.schoolManager.currentOrganisationalUnit.startDate,
    endDate:
      state.schoolManager.currentOrganisationalUnit.$$meta.type === 'ORGANISATION'
        ? state.schoolManager.currentOrganisationalUnit.$$endDate
        : state.schoolManager.currentOrganisationalUnit.endDate,
    mailAddress: getCurrentOuMailAddress(state),
    type: state.schoolManager.currentOrganisationalUnit.type,
    userRole: currentUserRole.role,
    canGrantAccessToSensitiveInformation:
      currentUserRole.management && currentUserRole.management.canGrantAccessToSensitiveInformation,
    ouTypeConfigKey,
    teamMetaInfo: {},
    teamMemberOus: [],
    governingInstitution: getGoverningInstitution(state),
  };
  addWorkgroupInfo(state, ou);
  return ou;
};

export const sortMembers = (members) => {
  members.sort((a, b) => {
    if (a.mainPosition && !b.mainPosition) {
      return -1;
    }
    if (!a.mainPosition && b.mainPosition) {
      return 1;
    }
    if (a.mainPosition && b.mainPosition) {
      if (a.mainPosition.positionClass.sortOrder !== b.mainPosition.positionClass.sortOrder) {
        return a.mainPosition.positionClass.sortOrder - b.mainPosition.positionClass.sortOrder;
      }
      if (a.type === 'INVITATION' && b.type !== 'INVITATION') {
        return -1;
      }
      if (a.type !== 'INVITATION' && b.type === 'INVITATION') {
        return 1;
      }
      if (a.mainPosition.permalink !== b.mainPosition.permalink) {
        return a.mainPosition.name.toLowerCase() < b.mainPosition.name.toLowerCase() ? -1 : 1;
      }
    }
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  });
};

export const applyFilter = (members, filterValue) => {
  if (!filterValue) {
    return members;
  }
  const words = filterValue
    .split(' ')
    .filter((word) => word.length >= 2)
    .map((word) => word.toLowerCase());
  if (words.length === 0) {
    return members;
  }
  return members.filter((member) => {
    let matches = 0;
    words.forEach((word) => {
      if (
        member.name.toLowerCase().includes(word) ||
        (member.type === 'RESPONSIBILITY' &&
          member.emailAddress &&
          member.emailAddress.toLowerCase().includes(word)) ||
        member.positions.some((position) => position.name.toLowerCase().includes(word))
      ) {
        matches += 1;
      } else if (member.type === 'REQUEST' && word.match(/^aanv(?:r|ra|raa|raag)?$/)) {
        matches += 1;
      }
    });
    return matches === words.length;
  });
};

export const getPositionClass = (position, definition, ouType) => {
  if (ouType === 'GOVERNINGINSTITUTION') {
    if (position.$$meta.permalink === BESTUURDER) {
      return { name: 'bestuurders', sortOrder: 1 };
    }
    if (position.$$meta.permalink === LID_VAN_HET_DAGELIJKS_BESTUUR) {
      return { name: 'leden dagelijks bestuur', sortOrder: 2 };
    }
    if (position.$$meta.permalink === LID_VAN_DE_ALGEMENE_VERGADERING) {
      return { name: 'leden algemene vergadering', sortOrder: 3 };
    }
    return { name: 'bestuursmedewerkers', sortOrder: 4 };
  }
  if (definition.positionClass === 'COORDINATOR') {
    if (position.$$meta.permalink === DIRECTEUR) {
      return { name: 'directie', sortOrder: 0 };
    }
    return { name: 'directie', sortOrder: 1 };
  }
  if (definition.positionClass === 'MIDDLE_MANAGEMENT') {
    return { name: 'middenkader', sortOrder: 2 };
  }
  if (definition.positionClass === 'MEMBER') {
    return { name: 'medewerkers', sortOrder: 3 };
  }
  return { name: 'ondersteunend personeel', sortOrder: 4 };
};

export const getPosition = (state, positionObj, organisationalUnitType) => {
  if (
    !positionObj ||
    !state.userState.positions ||
    !state.userState.positions.has(positionObj.href)
  ) {
    return {
      name: '',
      positionClass: {
        name: 'geen functie',
        sortOrder: 5,
      },
    };
  }
  const position = state.userState.positions.get(positionObj.href);
  const definition = position.definitions.filter((def) => {
    return def.applicableIn.ouTypes.some((ouType) => ouType === organisationalUnitType);
  })[0];
  return {
    permalink: position.$$meta.permalink,
    name: position.name,
    definition,
    positionClass: getPositionClass(position, definition, organisationalUnitType),
  };
};

export const getAdmin = (resp, person) => {
  return {
    key: person.key,
    familiarName: `${person.firstName} ${person.lastName}`,
    responsibilityHref: resp.$$meta.permalink,
  };
};

// TEAM
const capitalize = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTeam = (state, currentUserRole) => {
  const invitations = mapToArray(state.teamTab.invitations.toJS());
  const requests = mapToArray(state.teamTab.requests.toJS());
  let responsibilities = state.teamTab.responsibilities.toJS();
  responsibilities = getNonAbolishedResources(mapToArray(responsibilities));

  let delegations = [];
  let ouTypeConfig = null;
  if (state.schoolManager.currentOrganisationalUnit) {
    ouTypeConfig =
      OU_TYPE_CONFIG[state.schoolManager.currentOrganisationalUnit.type] ||
      Object.values(OU_TYPE_CONFIG).filter(
        (config) =>
          config.types &&
          config.types.some((type) => type === state.schoolManager.currentOrganisationalUnit.type)
      )[0];
    if (ouTypeConfig) {
      delegations = ouTypeConfig.delegations
        .filter(
          (del) =>
            (del.showFunction && del.showFunction(currentUserRole, settings)) ||
            (!del.showFunction &&
              (currentUserRole.role === 'SUPER_ADMIN' || currentUserRole.role === 'MANAGER'))
        )
        .map((del) => ({ ...del, persons: [] }));
    }
  }

  const members = [];
  responsibilities.forEach((resp) => {
    const person = state.teamTab.persons.get(resp.person.href).toJS();
    const position = getPosition(
      state,
      resp.position,
      state.schoolManager.currentOrganisationalUnit.type
    );
    const delegation = delegations.find((del) => del.position === position.permalink);
    if (delegation) {
      delegation.persons.push({
        key: person.key,
        permalink: person.$$meta.permalink,
        familiarName: `${person.firstName} ${person.lastName}`,
        responsibilityHref: resp.$$meta.permalink,
      });
    } else if (
      !person.firstName.match(/^Demo\s([Dd]irecteur|[Ll]eerkracht)\s[a-zA-Z]\s?/) &&
      position.permalink !== NASCHOLINGSVERANTWOORDELIJKE
    ) {
      // nascholingsverantw. can be removed if the delegation is added again
      const existingMember = members.find((member) => member.key === person.key);
      if (existingMember) {
        existingMember.responsibilities.push({
          ...position,
          name: capitalize(position.name),
          responsibilityHref: resp.$$meta.permalink,
          startDate: resp.startDate,
          endDate: resp.endDate,
        });
        if (isBefore(resp.startDate, existingMember.startDate)) {
          existingMember.startDate = resp.startDate;
        }
        if (isAfter(resp.endDate, existingMember.endDate)) {
          existingMember.endDate = resp.endDate;
        }
      } else {
        members.push({
          key: person.key,
          name: `${person.lastName}, ${person.firstName}`,
          gender: person.sex,
          familiarName: `${person.firstName} ${person.lastName}`,
          type: 'RESPONSIBILITY',
          permalink: person.$$meta.permalink,
          responsibilityHref: resp.$$meta.permalink,
          responsibilities: existingMember
            ? existingMember.positions
            : [
                {
                  ...position,
                  name: capitalize(position.name),
                  responsibilityHref: resp.$$meta.permalink,
                  startDate: resp.startDate,
                  endDate: resp.endDate,
                },
              ],
          emailAddress: person.$$email,
          startDate: resp.startDate,
          endDate: resp.endDate,
          actions: [],
        });
      }
    }
  });

  members.forEach((member) => {
    // users that do not have all delegations on the screen need to filter out the delegation roles at this point.
    member.responsibilities = member.responsibilities.filter(
      (resp) => !resp.definition || resp.definition.positionClass !== 'DELEGATION_ROLE'
    );
    member.responsibilities.sort((a, b) => {
      if (a.positionClass.sortOrder !== b.positionClass.sortOrder) {
        return a.positionClass.sortOrder - b.positionClass.sortOrder;
      }
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
    [member.mainPosition] = member.responsibilities;
    member.positions = member.responsibilities.filter((position) => position.name);
    if (
      state.userState.isSuperAdmin ||
      member.positions.length === 0 ||
      member.positions.some(
        (pos) => pos.permalink !== BESTUURDER && pos.permalink !== LID_VAN_HET_DAGELIJKS_BESTUUR
      )
    ) {
      member.actions = [
        {
          glyphicon: 'remove',
          event: 'removePersonFromTeam',
        },
      ];
    }
    const dropdownOptions = [];
    if (state.userState.isSuperAdmin) {
      dropdownOptions.push({
        label: 'Bekijk gegevens',
        glyphicon: 'user',
        event: 'goToPersonsApp',
      });
    }
    if (ouTypeConfig.positionEditable) {
      dropdownOptions.push({ label: 'Wijzig rol', glyphicon: 'edit', event: 'editPosition' });
    }
    if (dropdownOptions.length > 0) {
      member.actions.unshift({
        glyphicon: 'option-vertical',
        dropdownOptions,
      });
    }
  });

  requests
    .filter((request) => !request.decision || request.errors)
    .forEach((request) => {
      const person = state.teamTab.persons.get(request.from.href).toJS();
      const position = getPosition(
        state,
        request.position,
        state.schoolManager.currentOrganisationalUnit.type
      );
      let approveError = null;
      if (request.errors) {
        console.log('approveError', request.errors);
        const decision = request.decision === 'APPROVED' ? 'goedkeuren' : 'afwijzen';
        if (request.errors.some((error) => error.code === 'overlapping.period')) {
          if (request.position) {
            approveError = `${person.firstName} ${person.lastName} is al ${request.position.$$expanded.name} in jouw team. Je kan deze aanvraag gewoon verwijderen.`;
          } else {
            approveError = `${person.firstName} ${person.lastName} is al lid van jouw team. Je kan deze aanvraag gewoon verwijderen.`;
          }
        } else if (!request.errors.some((error) => error.status !== 403)) {
          approveError = `Je hebt geen toegansrechten voor het ${decision} van aanvragen.`;
        } else {
          approveError = `Er is een onverwachte fout opgetreden bij het ${decision} van de aanvraag.`;
        }
      }
      const member = {
        key: person.key,
        name: `${person.lastName}, ${person.firstName}`,
        familiarName: `${person.firstName} ${person.lastName}`,
        type: 'REQUEST',
        requestHref: request.$$meta.permalink,
        mainPosition: position,
        positions: position.name
          ? [
              {
                ...position,
                name: capitalize(position.name),
                startDate: request.startDate,
                endDate: request.endDate,
              },
            ]
          : [],
        emailAddress: person.$$email,
        startDate: request.startDate,
        endDate: request.endDate,
        approveError,
        actions: [
          {
            glyphicon: 'ok',
            event: 'acceptTeamMembershipRequest',
          },
          {
            glyphicon: 'remove',
            event: 'discardTeamMembershipRequest',
          },
        ],
      };
      if (state.userState.isSuperAdmin) {
        member.actions.unshift({
          glyphicon: 'option-vertical',
          dropdownOptions: [
            { label: 'Bekijk gegevens', glyphicon: 'user', event: 'goToPersonsApp' },
          ],
        });
      }
      members.push(member);
    });

  invitations.forEach((invitation) => {
    let invitationErrors = null;
    if (invitation.error) {
      if (invitation.error.errors) {
        invitationErrors = invitation.error.errors;
      } else {
        invitationErrors = invitation.error;
      }
    }

    let email = {};
    if (state.teamTab.emailJobs[invitation.$$meta.permalink]) {
      if (state.teamTab.emailJobs[invitation.$$meta.permalink].status === 'INPROGRESS') {
        email = {
          sentOn: getNow(),
        };
      } else {
        email = {
          sentOn:
            state.teamTab.emailJobs[invitation.$$meta.permalink].$$email &&
            state.teamTab.emailJobs[invitation.$$meta.permalink].$$email.sentDate
              ? state.teamTab.emailJobs[invitation.$$meta.permalink].$$email.sentDate.split('T')[0]
              : state.teamTab.emailJobs[invitation.$$meta.permalink].$$meta.created.split('T')[0],
          bounced:
            state.teamTab.emailJobs[invitation.$$meta.permalink].$$email &&
            ['REJECTED', 'SOFT-BOUNCE', 'HARD-BOUNCE'].some(
              (status) =>
                status === state.teamTab.emailJobs[invitation.$$meta.permalink].$$email.status
            ),
        };
      }
    }

    const position = getPosition(
      state,
      invitation.position,
      state.schoolManager.currentOrganisationalUnit.type
    );
    const member = {
      name: invitation.to,
      type: 'INVITATION',
      invitationHref: invitation.$$meta.permalink,
      position,
      mainPosition: position,
      positions: position.name
        ? [
            {
              ...position,
              name: capitalize(position.name),
              startDate: invitation.startDate,
              endDate: invitation.endDate,
            },
          ]
        : [],
      emailAddress: invitation.to,
      startDate: invitation.startDate,
      endDate: invitation.endDate,
      email,
      // emailAddressBounced: state.teamTab.undeliveredInvitations.some(undelivedInvitation => undelivedInvitation === invitation.$$meta.permalink),
      errors: invitationErrors,
      actions: [],
    };
    if (
      state.userState.isSuperAdmin ||
      !position.permalink ||
      (position.permalink !== BESTUURDER && position.permalink !== LID_VAN_HET_DAGELIJKS_BESTUUR)
    ) {
      member.actions = [
        {
          glyphicon: 'option-vertical',
          dropdownOptions: [
            { label: 'Stuur Herinnering', glyphicon: 'envelope', event: 'sendReminder' },
          ],
        },
        {
          glyphicon: 'remove',
          event: 'discardTeamInvitation',
        },
      ];
    }
    members.push(member);
  });

  sortMembers(members);

  return {
    members,
    filteredMembers: applyFilter(members, state.teamTab.filterValue),
    delegations,
  };
};

export const calculateDelegationCandidates = (state, teamInfo) => {
  if (state.schoolManager.currentOrganisationalUnit && state.userState.user) {
    const ouTypeConfig =
      OU_TYPE_CONFIG[state.schoolManager.currentOrganisationalUnit.type] ||
      Object.values(OU_TYPE_CONFIG).filter(
        (config) =>
          config.types &&
          config.types.some((type) => type === state.schoolManager.currentOrganisationalUnit.type)
      )[0];
    teamInfo.delegations.forEach((delegation) => {
      delegation.candidates = teamInfo.members.filter((member) => {
        return (
          member.type === 'RESPONSIBILITY' &&
          !delegation.persons.some((delegee) => delegee.key === member.key) &&
          (!delegation.ownedByManagers ||
            !ouTypeConfig.managers.some(
              (managerPosition) => managerPosition === member.mainPosition.permalink
            )) &&
          (!delegation.removeSelf || member.key !== state.userState.user.key)
        );
      });
    });
  }
};

// GROUPS

export const getGroups = (state) => {
  const ouGroups = state.groupsTab.showDeletedGroups
    ? getAbolishedResources(mapToArray(state.groupsTab.ouGroups.toJS()))
    : getNonAbolishedResources(mapToArray(state.groupsTab.ouGroups.toJS()));

  let groups = ouGroups.map((ouGroup) => {
    const group = state.groupsTab.groups.get(ouGroup.from.href).toJS();
    let referenceDate = getNow();
    if (group.endDate) {
      referenceDate = getPreviousDay(group.endDate);
    } else if (isAfter(group.startDate, getNow())) {
      referenceDate = group.startDate;
    }

    return {
      key: group.key,
      relationPermalink: ouGroup.$$meta.permalink,
      permalink: ouGroup.from.href,
      name: getActiveResources(group.names, referenceDate).filter(
        (name) => name.type === 'OFFICIAL'
      )[0].value,
      startDate: ouGroup.startDate,
      endDate: ouGroup.endDate,
    };
  });

  // if (settings.environment === '' || settings.environment === 'test') {
  groups = groups.filter((ouGroup) => !ouGroup.name.match(/^[a-z0-9]{6,7}\sVakgroep\s[A-Z]{1,2}$/));
  // }

  return groups;
};

export const getGroupInfoTabs = (state, groupsInfo, currentUserRole) => {
  let groupsInfoTabs = groupsInfo.map((group) => {
    const noAccessYet =
      currentUserRole.role === GROUP_MANAGER &&
      !state.userState.ousWithUpdateAccessToResps.some((ou) => ou === group.key);
    let subTitle = '';
    if (noAccessYet) {
      subTitle += '[configureren toegangsrechten...]';
    }
    subTitle += isBeforeOrEqual(group.endDate, getNow())
      ? `${printDate(group.startDate)} - ${printDate(group.endDate)}`
      : printFutureForPeriodic(group).replace(/[()]/g, '').trim();
    return {
      state: 'school.group.info',
      title: group.name,
      subTitle,
      fontColor: isBeforeOrEqual(group.endDate, getNow()) ? 'grey-light' : undefined,
      active: state.groupsTab.selectedGroup === group.permalink,
      disabled: noAccessYet,
      data: {
        groupId: group.permalink.split('/').pop(),
      },
    };
  });

  if (state.groupsTab.filter !== '') {
    groupsInfoTabs = groupsInfoTabs.filter((group) =>
      group.title.toUpperCase().includes(state.groupsTab.filter.toUpperCase())
    );
  }

  groupsInfoTabs = orderBy(groupsInfoTabs, [(groupsInfoTab) => groupsInfoTab.title.toLowerCase()]);

  return groupsInfoTabs;
};

export const getSelectedGroupInfo = (state, teamMembers) => {
  let selectedGroupInfo = {};

  if (state.groupsTab.selectedGroup && state.groupsTab.groups.size > 0) {
    const group = state.groupsTab.groups.get(state.groupsTab.selectedGroup).toJS();
    const members = Object.values(state.groupsTab.responsibilities.toJS());
    let groupResponsabilities = members.filter(
      (groupsMember) => groupsMember.organisation.href === group.$$meta.permalink
    );
    groupResponsabilities = isBeforeOrEqual(group.endDate, getNow())
      ? getNonAbolishedResources(groupResponsabilities, getPreviousDay(group.endDate))
      : getNonAbolishedResources(groupResponsabilities);
    const groupAdmins = [];
    const groupMembers = [];

    groupResponsabilities.forEach((responsibility) => {
      const person = state.teamTab.persons.get(responsibility.person.href).toJS();
      const position = getPosition(state, responsibility.position, group.type);

      if (position.permalink === BEHEERDER) {
        groupAdmins.push(getAdmin(responsibility, person));
      } else {
        groupMembers.push({
          responsibilityPermalink: responsibility.$$meta.permalink,
          key: person.key,
          name: `${person.firstName} ${person.lastName}`,
          mainPosition: position,
          positions: [
            {
              ...position,
              startDate: responsibility.startDate,
              endDate: responsibility.endDate,
            },
          ],
          permalink: person.$$meta.permalink,
          emailAddress: person.$$email,
          startDate: responsibility.startDate,
          endDate: responsibility.endDate,
          actions: isBeforeOrEqual(group.endDate, getNow())
            ? []
            : [
                {
                  glyphicon: 'option-vertical',
                  dropdownOptions: GROUP_POSITIONS.filter((p) => p.name !== position.name).map(
                    (p) => ({
                      label: `Maak ${p.name}`,
                      event: 'modifyGroupMemberPosition',
                      eventArgs: { position: p },
                    })
                  ),
                },
                {
                  glyphicon: 'remove',
                  event: 'cancelGroupMembership',
                },
              ],
        });
      }
    });

    sortMembers(groupMembers);

    selectedGroupInfo = {
      permalink: group.$$meta.permalink,
      startDate: group.startDate,
      endDate: group.endDate,
      isDeletedGroup: isBeforeOrEqual(group.endDate, getNow()),
      name: group.$$displayName,
      type: group.type,
      members: groupMembers,
      memberCandidates: teamMembers.filter(
        (member) =>
          member.type === 'RESPONSIBILITY' &&
          !groupMembers.map((groupMember) => groupMember.key).includes(member.key)
      ),
      admins: groupAdmins,
      adminCandidates: teamMembers.filter(
        (member) =>
          member.type === 'RESPONSIBILITY' &&
          !groupMembers
            .filter((groupAdmin) => groupAdmin.mainPosition.permalink === COORDINATOR)
            .map((groupMember) => groupMember.key)
            .includes(member.key) &&
          !groupAdmins.map((groupAdmin) => groupAdmin.key).includes(member.key)
      ),
    };
  }
  return selectedGroupInfo;
};

export const getCoordinatorPosition = (state) => {
  if (
    !state.userState.positions ||
    state.userState.positions.size === 0 ||
    !state.userState.positions.has(COORDINATOR)
  ) {
    return {};
  }

  let coordinatorPosition = state.userState.positions.get(COORDINATOR);
  coordinatorPosition = { ...coordinatorPosition, definition: coordinatorPosition.definitions[0] };
  delete coordinatorPosition.definitions;
  return coordinatorPosition;
};

// STUDY PROGRAMS

export const getPossibleStudyProgrammes = (state) => {
  if (!state.studyProgrammesTab.possibleStudyProgrammes) {
    return [];
  }

  let possibleStudyProgrammes = mapToArray(state.studyProgrammesTab.possibleStudyProgrammes.toJS());
  possibleStudyProgrammes = possibleStudyProgrammes.filter(
    (possibleStudyProgramme) => !['Steiner'].includes(possibleStudyProgramme.title)
  );
  possibleStudyProgrammes = orderBy(possibleStudyProgrammes, [
    (possibleStudyProgramme) => possibleStudyProgramme.title.toLowerCase(),
  ]);

  return possibleStudyProgrammes;
};

export const getStudyProgrammeInfo = (state) => {
  if (state.studyProgrammesTab.studyProgrammes.size === 0) {
    return [];
  }

  const studyProgrammes = getNonAbolishedResources(
    mapToArray(
      state.studyProgrammesTab.studyProgrammes.toJS(),
      getStartOfSchoolYearIncludingSummerGap()
    )
  );

  return studyProgrammes.reduce((hash, educationalProgrammeDetail) => {
    hash[educationalProgrammeDetail.studyProgramme.href.split('/').pop()] = {
      relationPermalink: educationalProgrammeDetail.$$meta.permalink,
      permalink: educationalProgrammeDetail.studyProgramme.href,
      startDate: educationalProgrammeDetail.startDate,
      endDate: educationalProgrammeDetail.endDate,
    };
    return hash;
  }, {});
};

// CLASSES

const isFailedClass = (classPermalink, error) =>
  error.body.some((body) => body.href === classPermalink);

const getClasses = (state, campus, errors) => {
  let classes = mapToArray(state.classesTab.campusClasses.toJS()).filter(
    (campusClass) => campusClass.physicalLocation.href === campus.physicalLocation.href
  );

  classes = getNonAbolishedResources(classes);
  return mapToArray(classes).map((campusClass) => {
    const clazz = state.classesTab.classes.get(campusClass.organisationalUnit.href).toJS();
    return {
      relationPermalink: campusClass.$$meta.permalink,
      permalink: campusClass.organisationalUnit.href,
      name: clazz.$$displayName,
      startDate: campusClass.startDate,
      endDate: campusClass.endDate,
      errors: errors.filter(
        (error) =>
          error instanceof SriClientError &&
          isFailedClass(campusClass.organisationalUnit.href, error)
      ),
    };
  });
};

export const getCampusClasses = (state) => {
  const classesTabErrors =
    state.classesTab && state.classesTab.errors ? state.classesTab.errors : [];

  let campuses = getNonAbolishedResources(mapToArray(state.classesTab.campuses.toJS()));
  campuses = orderBy(campuses, [
    'physicalLocation.$$expanded.address.city',
    'physicalLocation.$$expanded.address.subCity',
    'physicalLocation.$$expanded.address.street',
    'physicalLocation.$$expanded.address.houseNumber',
  ]);

  return campuses.map((campus) => ({
    relationPermalink: campus.$$meta.permalink,
    permalink: campus.physicalLocation.href,
    name: campus.name,
    startDate: campus.startDate,
    endDate: campus.endDate,
    address: campus.physicalLocation.$$expanded.address,
    classes: getClasses(state, campus, classesTabErrors),
  }));
};

export const getDefaultPosition = (ou) => {
  if (
    ou &&
    ['VERGADERGROEP', 'LEERPLANCOMMISSIE', 'LERARENNETWERK'].some((type) => type === ou.type)
  ) {
    return LID;
  }
  return null;
};

export const getOuDetails = (state) => {
  if (
    !state.schoolManager.currentOrganisationalUnit ||
    !state.schoolManager.currentOrganisationalUnit.details
  ) {
    return {};
  }
  const referenceDate =
    state.schoolManager.currentOrganisationalUnit.$$startDate > getNow()
      ? state.schoolManager.currentOrganisationalUnit.$$startDate
      : getNow();
  const currentDetail = getActiveResources(
    state.schoolManager.currentOrganisationalUnit.details,
    referenceDate
  )[0];
  const { possibleTypes } = state.createTeamWizard;
  return {
    name: currentDetail.name,
    shortName: currentDetail.shortName,
    description: state.schoolManager.currentOrganisationalUnit.description,
    type: possibleTypes.find((type) => type === state.schoolManager.currentOrganisationalUnit.type),
    formErrors: {
      descriptionErrors: state.schoolManager.descriptionErrors,
      nameErrors: state.schoolManager.nameErrors,
      shortNameErrors: state.schoolManager.shortNameErrors,
    },
    possibleTypes,
    shortNamePattern: state.createTeamWizard.shortNamePattern,
  };
};
