import { settings } from 'Js/config/settings';

export class ShowDuplicate {
  constructor($scope, $ngRedux, $state) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.arrowRight = require('Img/arrow-right.svg');
    this.profileIcon = require('Img/user.svg');
    this.askPasswordReset = `/#!/vraag-nieuw-wachtwoord?redirect_url=${encodeURIComponent(window.location.href)}`;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      duplicate: state.register.duplicates[0],
      invitationKey: state.invite.invitationKey,
      organisationalUnit: state.invite.organisationalUnits[0],
      position: state.invite.position,
      emailAddress: state.register.emailAddress,
      startDate: state.invite.startDate,
      missingSchoolIsReported: state.invite.missingSchoolIsReported,
      userinfoParam: state.register.userinfoParam,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  goToLogin() {
    if (this.userinfoParam) {
      this.$state.go('linkAccounts', {
        userinfo: this.userinfoParam,
        username: this.duplicate.username,
      });
    } else {
      this.$state.go('logIn', {
        key: this.invitationKey,
        functie: this.position ? this.position.key : undefined,
        ou: this.organisationalUnit ? this.organisationalUnit.key : undefined,
        email: this.emailAddress,
        start: this.startDate,
        username: this.duplicate.username,
      });
    }
  }

  goToPasswordReset() {
    console.log('ask password');
    console.log(this.askPasswordReset);
    window.open(`${this.askPasswordReset}?username=${this.duplicate.username}`);
  }

  noAccessToEmailAddress() {
    console.log('go to helpdesk page');
    this.$state.go('contactHelpdesk', { type: 'geen-toegang-tot-email' });
  }
}
