import { MyConfirmNoRegistrationNumber } from './myConfirmNoRegistrationNumber';

export const myConfirmNoRegistrationNumberComponent = {
  template: require('./myConfirmNoRegistrationNumber.html'),
  controllerAs: 'ctrl',
  controller: MyConfirmNoRegistrationNumber,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
