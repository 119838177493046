import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';

require('./myVakkennieuwsbriefSection.scss');

export class myVakkennieuwsbriefSection {
  constructor(settings, $scope) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.mailIcon = require('Img/mail.svg');
    this.searchName = '';
  }

  regionChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.SUBJECTS,
      selected,
      href,
      'REGIONS'
    );
  }

  /* educationalLevelChanged(e, selected, href) {
    this.$scope.$emit('newsletters:optionChanged', NEWSLETTER_TYPES.SUBJECTS, selected, href, 'EDUCATION_LEVELS');
  } */

  subjectChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.SUBJECTS,
      selected,
      href,
      'SUBJECTS'
    );
  }

  otherChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.SUBJECTS,
      selected,
      href,
      'OTHERS'
    );
  }

  algemeenNieuwsChanged(e, selected) {
    this.$scope.$emit(
      'newsletters:algemeenNieuwsChanged',
      NEWSLETTER_TYPES.SUBJECTS,
      selected,
      id,
      'ALGEMEEN_NIEUWS'
    );
  }

  searchTextChanged(e) {
    this.$scope.$emit('newsletters:filterDeselectedSubjects', this.searchName);
  }

  showAllSelectedSubjects(e) {
    this.$scope.$emit('newsletters:showAllSelected');
  }

  showAllDeselectedSubjects(e) {
    this.$scope.$emit('newsletters:showAllDeselected');
  }

  showLessSelectedSubjects(e) {
    this.$scope.$emit('newsletters:showLessSelected');
  }

  showLessDeselectedSubjects(e) {
    this.$scope.$emit('newsletters:showLessDeselected');
  }
}
