require('./myConfirmationScreen.scss');

class MyConfirmationScreen {}

export const myConfirmationScreenComponent = {
  template: require('./myConfirmationScreen.html'),
  controllerAs: 'ctrl',
  controller: MyConfirmationScreen,
  transclude: true,
  bindings: {
    sIcon: '@',
    sTitle: '@',
  },
};
