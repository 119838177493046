import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';

export class myThemanieuwsbriefSection {
  constructor(settings, $scope) {
    'ngInject';

    this.settings = settings;
    this.$scope = $scope;
    this.mailIcon = require('Img/mail.svg');
    this.ceil = Math.ceil;
  }

  getIcon(item) {
    const icon = item.attachments.find((att) => att.type === 'ICON');
    return icon ? this.settings.apis.kathOndVla + icon.href : null;
  }

  educationalLevelChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.THEMATIC,
      selected,
      href,
      'EDUCATION_LEVELS'
    );
  }

  regionChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.THEMATIC,
      selected,
      href,
      'REGIONS'
    );
  }

  positionChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.THEMATIC,
      selected,
      href,
      'POSITIONS'
    );
  }

  themeCategoryChanged(e, selected, href) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.THEMATIC,
      selected,
      href,
      'THEMES'
    );
  }

  jobsOfferingChanged(e, selected) {
    this.$scope.$emit(
      'newsletters:optionChanged',
      NEWSLETTER_TYPES.THEMATIC,
      selected,
      null,
      'JOBS_OFFERING'
    );
  }
}
