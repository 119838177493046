import { validateRegistrationNumber } from 'Js/module/personUtils';

export class MyConfirmNoRegistrationNumber {
  constructor() {
    this.validateRegistrationNumber = validateRegistrationNumber;
    this.editIcon = require('Img/edit.svg');
    this.arrowRight = require('Img/arrow-right.svg');
  }

  $onInit() {
    this.position = this.resolve.position;
  }

  registrationNumberChanged(e, rn) {
    this.registrationNumber = rn;
  }

  onContinue() {
    this.submitAttempted = true;
    if (!this.rnForm.valid) {
      return;
    }
    this.close({ $value: this.registrationNumber });
  }

  onHaveNone() {
    this.close({ $value: null });
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
