require('./myInlineEditor.scss');

const INLINE_TEXT_EDITED = 'inlineTextEdited';

class MyInlineEditor {
  constructor($scope, $element) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;

    this.editable = false;
    this.previousText = this.sEditableText;
    this.editIcon = require('Img/edit.svg');
  }

  $onInit() {
    this.editableWrapper = this.$element.find('div')[0];
  }

  preventNewLines(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.editable = false;
      this.textEdited();
      return false;
    }
    return true;
  }

  enableEdition() {
    this.editable = true;
  }

  disableEdition() {
    this.editable = false;

    if (this.previousText !== this.editableWrapper.innerText) {
      this.textEdited();
      this.previousText = this.editableWrapper.innerText;
    }
  }

  textEdited() {
    this.$scope.$emit(INLINE_TEXT_EDITED, this.editableWrapper.innerText.trim());
  }
}

module.exports = MyInlineEditor;
