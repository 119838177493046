import { printDate } from '@kathondvla/sri-client/date-utils';

export class ChooseRespModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.deleteIcon = require('Img/cross.svg');
    this.selected = [];
    this.printDate = (date) => {
      if (!date) {
        return null;
      }
      return printDate(date);
    };
  }

  $onInit() {
    this.responsibilities = this.resolve.responsibilities;
    this.memberName = this.resolve.memberName;
    this.title =
      this.resolve.actionTitle.charAt(0).toUpperCase() + this.resolve.actionTitle.substring(1);
  }

  toggleResponsibility(ev, checkboxValue, responsibilityHref) {
    if (checkboxValue) {
      if (this.resolve.multiple) {
        this.selected.push(
          this.responsibilities.find((resp) => resp.responsibilityHref === responsibilityHref)
        );
      } else {
        this.selected = [
          this.responsibilities.find((resp) => resp.responsibilityHref === responsibilityHref),
        ];
      }
    } else {
      this.selected = this.selected.filter(
        (resp) => resp.responsibilityHref !== responsibilityHref
      );
    }
  }

  isSelected(responsibilityHref) {
    return this.selected.some((resp) => resp.responsibilityHref === responsibilityHref);
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  next() {
    this.close({
      $value: {
        selectedResponsibilities: this.selected,
      },
    });
  }
}
