import { MyRegisterConfirmEmail } from './myRegisterConfirmEmail';

export const myRegisterConfirmEmailComponent = {
  template: require('./myRegisterConfirmEmail.html'),
  controllerAs: 'ctrl',
  controller: MyRegisterConfirmEmail,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
