import * as ACTION_TYPES from '../constants/actionTypes';

export const searchValueChanged = (searchValue) => ({
  type: ACTION_TYPES.SEARCH_VALUE_CHANGED,
  payload: searchValue,
});

export const showMoreResults = () => ({
  type: ACTION_TYPES.SHOW_MORE_RESULTS,
});

export const ouTypeSearchFilterChanged = (ouType) => ({
  type: ACTION_TYPES.OU_TYPE_SEARCH_FILTER_CHANGED,
  payload: ouType,
});

export const searchResultsFetched = (searchResults) => ({
  type: ACTION_TYPES.SEARCH_RESULTS_FETCHED,
  payload: searchResults,
});

export const allSecondarySchoolsFetched = (schoolInfo) => ({
  type: ACTION_TYPES.ALL_SECONDARY_SCHOOLS_FETCHED,
  payload: schoolInfo,
});

export const allSamOusFetched = (samOuInfo) => ({
  type: ACTION_TYPES.ALL_SAM_OUS_FETCHED,
  payload: samOuInfo,
});

export const allVosOusOfTypeFetched = (ous, ouType) => ({
  type: ACTION_TYPES.ALL_VOS_OUS_OF_TYPE_FETCHED,
  payload: {
    ous,
    ouType,
  },
});

export const ouFetchedByInstitutionNumber = (institutionNumber) => ({
  type: ACTION_TYPES.OU_FETCHED_BY_INSTITUTION_NUMBER,
  payload: institutionNumber,
});

export const missingSchoolsFetched = (result, institutionNumber) => ({
  type: ACTION_TYPES.MISSING_SCHOOLS_FETCHED,
  payload: Object.assign(result, { institutionNumber }),
});
