import { fetchUser } from 'ReduxLoop/user/userActions';
import { getDefaultTab } from 'ReduxLoop/appConfig';

export class OuSelector {
  constructor($state, $stateParams, $ngRedux, settings) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
    this.appVersion = settings.projectVersion;
    this.settings = settings;
    this.addIcon = require('Img/add.svg');

    this.options = [];

    this.unsubscribe = this.$ngRedux.connect((state) => {
      let options = [];
      if (state.vm.user.manages) {
        if (this.$stateParams.type === 'school') {
          options = [...state.vm.user.manages.SCHOOLENTITY, ...state.vm.user.manages.SCHOOL];
        } else if (this.$stateParams.type === 'internaat') {
          options = state.vm.user.manages.BOARDING;
        } else if (this.$stateParams.type === 'scholengemeenschap') {
          options = state.vm.user.manages.SCHOOLCOMMUNITY;
        } else if (this.$stateParams.type === 'bestuur') {
          options = state.vm.user.manages.GOVERNINGINSTITUTION;
        } else if (this.$stateParams.type === 'clb') {
          options = state.vm.user.manages.CLB;
        } else if (this.$stateParams.type === 'leersteuncentrum') {
          options = state.vm.user.manages.LEERSTEUNCENTRUM;
        } else if (this.$stateParams.type === 'organisatie') {
          options = state.vm.user.manages.ANDERE;
        } else if (this.$stateParams.type === 'team') {
          options = state.vm.user.manages.TEAM;
        } else if (this.$stateParams.type === 'lerarennetwerk') {
          options = state.vm.user.manages.LERARENNETWERK;
        }

        options.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
      }

      return {
        initialised: !!state.vm.user.manages,
        user: state.vm.user,
        options,
      };
    })(this);
  }

  $onInit() {
    if (this.$stateParams.type === 'school') {
      this.what = 'Welke school';
    } else if (this.$stateParams.type === 'internaat') {
      this.what = 'Welk internaat';
    } else if (this.$stateParams.type === 'scholengemeenschap') {
      this.what = 'Welke scholengemeenschap';
    } else if (this.$stateParams.type === 'bestuur') {
      this.what = 'Welk bestuur';
    } else if (this.$stateParams.type === 'clb') {
      this.what = 'Welk CLB';
    } else if (this.$stateParams.type === 'leersteuncentrum') {
      this.what = 'Welk leersteuncentrum';
    } else if (this.$stateParams.type === 'organisatie') {
      this.what = 'Welke organisatie';
    } else if (this.$stateParams.type === 'team') {
      this.what = 'Welk team';
    } else if (this.$stateParams.type === 'lerarennetwerk') {
      this.what = 'Welk lerarennetwerk';
    }
    this.$ngRedux.dispatch(fetchUser());
  }

  onSelectOu(ev, ouKey) {
    const selectedOuInfo = this.options.find((management) => management.ouKey === ouKey);
    if (selectedOuInfo.role === 'OLD_MIJN_MANAGER') {
      window.location.href = `${this.settings.applications.mijn1}/#/team/${selectedOuInfo.institutionNumber}`;
    } else {
      const defaultTab = getDefaultTab(selectedOuInfo.ouType, selectedOuInfo.role);
      this.$state.go(defaultTab.state, {
        schoolId: ouKey,
        type: this.$stateParams.type,
      });
    }
  }

  createNewTeam() {
    this.$state.go('createTeam');
  }

  goToHome() {
    this.$state.go('home');
  }

  $onDestroy() {
    this.unsubscribe();
  }
}
