export class ConfirmationModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.confirmIcon = require('Img/check.svg');
  }

  $onInit() {
    this.sIcon = this.resolve.icon;
    this.sTitle = this.resolve.title;
    this.confirmationQuestion = this.resolve.confirmationQuestion;
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  onConfirmed() {
    this.close({ $value: {} });
  }
}
