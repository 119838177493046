import {
  getStartOfSchoolYearIncludingSummerGap,
  getNow,
  printDate,
} from '@kathondvla/sri-client/date-utils';
import { displayOuType } from 'ReduxLoop/appConfig';

require('./invitationModal.scss');

export class InvitationModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.emailIcon = require('Img/mail.svg');
    this.addIcon = require('Img/add.svg');
  }

  $onInit() {
    this.guestEmails = this.resolve.sGuestEmails;
    if (this.guestEmails.length > 5) {
      this.guestListString = `${this.guestEmails.slice(0, 5).join(', ')} en nog ${
        this.guestEmails.length - 5
      } anderen`;
    } else {
      this.guestListString = this.guestEmails.join(', ');
    }

    this.startDate =
      this.resolve.school.type === 'SCHOOLENTITY'
        ? getStartOfSchoolYearIncludingSummerGap()
        : getNow();

    this.possiblePositions = this.resolve.possiblePositions;
    if (this.resolve.defaultPosition) {
      this.selectedPosition = this.possiblePositions.filter(
        (pos) => pos.$$meta.permalink === this.resolve.defaultPosition
      )[0];
    }

    this.school = this.resolve.school;

    this.$scope.$watch(
      () => this.selectedPosition,
      (n, o) => {
        if (n !== o) {
          this.functionErrors = [];
        }
      }
    );
  }

  startDateChanged(e, startDate) {
    console.log('startDate changed to ', startDate);
    this.startDate = startDate;
    this.startDateErrors = [];
  }

  isValid() {
    if (!this.startDate) {
      this.startDateErrors.push({
        message: 'Vul in vanaf wanneer de functie ingaat/is ingegaan',
      });
    }
    if (this.startDate < this.school.startDate) {
      this.startDateErrors.push({
        message: `${displayOuType(
          this.resolve.school.type,
          true,
          true
        )} is pas ingericht vanaf ${this.school.startDate.split('-').reverse().join('/')}`,
      });
    }
    if (!this.selectedPosition) {
      this.functionErrors.push({
        message: 'Functie is een verplicht veld',
      });
    }
    if (
      this.selectedPosition.definition &&
      this.startDate < this.selectedPosition.definition.startDate
    ) {
      this.functionErrors.push({
        message: `${this.selectedPosition.name} kan je pas kiezen vanaf ${printDate(
          this.selectedPosition.definition.startDate
        )}. Selecteer de rol vanaf ${printDate(this.selectedPosition.definition.startDate)}`,
      });
    }
    return this.startDateErrors.length === 0 && this.functionErrors.length === 0;
  }

  clearErrors() {
    this.startDateErrors = [];
    this.functionErrors = [];
  }

  sendInvitations() {
    this.clearErrors();
    if (!this.isValid()) {
      return;
    }

    this.close({
      $value: {
        invitees: this.guestEmails,
        startDate: this.startDate,
        position: this.selectedPosition,
      },
    });
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
