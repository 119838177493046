import { sriClient } from 'ReduxLoop/utils/apiConfig';

export const getWorkgroupInfo = async (ou) => {
  const workgroup = (
    await sriClient.getAll('/workgroups', { organisation: ou.$$meta.permalink })
  )[0];
  const memberRelations = await sriClient.getAll('/organisationalunits/relations', {
    types: 'IS_MEMBER_OF',
    tos: ou.$$meta.permalink,
    expandFrom: 'SUMMARY',
    statuses: 'ACTIVE',
  });
  const memberOus = memberRelations.map((rel) => rel.from.$$expanded);
  // const presidents = memberOus.length === 0 ? [] : await sriClient.getAll('/responsibilities', { organisations: memberRelations.map(rel => rel.from.href), positions: VOORZITTER, expandPerson: 'SUMMARY' });
  const memberParentRelations =
    memberOus.length === 0
      ? []
      : await sriClient.getList('/organisationalunits/relations', {
          froms: memberRelations.map((rel) => rel.from.href),
          types: 'IS_PART_OF',
          recurseDepth: '*',
          statuses: 'ACTIVE',
          expandTo: 'SUMMARY',
        });
  return {
    workgroup,
    memberRelations,
    memberOus,
    // presidents,
    memberParentRelations,
  };
};

export const getWorkgroup = async (ouHref) => {
  return (await sriClient.getAll('/workgroups', { organisation: ouHref }))[0];
};
