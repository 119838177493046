import {
  getNow,
  getActiveResources,
  isBefore,
  getPreviousMonth,
  getPreviousDay,
} from '@kathondvla/sri-client/date-utils';
import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { loop, Cmd } from 'redux-loop';
import SriClientError from '@kathondvla/sri-client/sri-client-error';
import { fetchUser } from 'ReduxLoop/user/userActions';
import { getOrganisationalUnit } from 'ReduxLoop/user/userCommands';
import {
  redirectToMijn1Team,
  getGoverningInstitutionOfOu,
} from 'ReduxLoop/ouManager/schoolManagerCommands';
import { getDefaultTab, getCurrentUserRole } from 'ReduxLoop/appConfig';
import { storeResource } from 'ReduxLoop/utils';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'ReduxLoop/notifications/notificationActions';
import * as ACTIONS from './schoolManagerActions';

const initialState = {
  currentOUKey: null,
  currentOrganisationalUnit: null,
  teamMetaInfo: null,
  teamMemberOus: {
    ous: {},
    relations: {},
    presidents: [],
    parentRelations: [],
  },
  // contactDetails: new Map(),
  // contactDetailsFetched: false,
  governingInstitution: {
    ou: null,
    mailAddress: null,
  },
  selectedTab: null,
  vosEquivalent: null,
  isDetailsFormValid: true,
  nameErrors: [],
  shortNameErrors: [],
  descriptionErrors: [],
};

const saveDetails = (newState) => {
  if (
    !newState.isDetailsFormValid ||
    newState.descriptionErrors.length > 0 ||
    newState.nameErrors.length > 0 ||
    newState.shortNameErrors.length > 0
  ) {
    return newState;
  }
  return loop(
    newState,
    Cmd.run(storeResource, {
      args: [newState.currentOrganisationalUnit],
      successActionCreator: () =>
        ACTIONS.saveOrganisationDetailsSuccess(newState.currentOrganisationalUnit),
      failActionCreator: ACTIONS.saveOrganisationDetailsFailed,
    })
  );
};

export const schoolManagerReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTIONS.INITIALISE_SCHOOLMANAGER: {
      const newState = { ...state, currentOUKey: action.payload };
      const commandList = [];
      if (!rootState.userState.user) {
        commandList.push(Cmd.action(fetchUser()));
      }
      commandList.push(Cmd.action(ACTIONS.selectOrganisationalUnit(action.payload)));
      return loop(newState, Cmd.list(commandList, { sequence: true }));
    }

    case ACTIONS.SELECT_ORGANISATIONAL_UNIT: {
      const newState = {
        ...initialState,
        currentOUKey: action.payload,
        vosEquivalent: null,
        selectedTab: state.selectedTab,
      };
      const ou = rootState.userState.ous.get(`/organisationalunits/${action.payload}`);
      if (ou) {
        // newState.contactDetails = rootState.userState.contactDetails;
        // newState.contactDetailsFetched = true;
        return loop(newState, Cmd.action(ACTIONS.currentOuFetched(ou)));
      }
      return loop(
        newState,
        Cmd.run(getOrganisationalUnit, {
          args: [action.payload],
          successActionCreator: ACTIONS.currentOuFetched,
        })
      );
    }

    case ACTIONS.CURRENT_OU_FETCHED: {
      const commandList = [];
      const ou = action.payload;
      const newState = { ...state, currentOrganisationalUnit: ou };
      if (ou.type === 'SCHOOL' || ou.type === 'BOARDING' || ou.type === 'SCHOOLCOMMUNITY') {
        return loop(state, Cmd.run(redirectToMijn1Team, { args: [ou, Cmd.dispatch] }));
      }
      if (!state.selectedTab) {
        const defaultTab = getDefaultTab(
          action.payload.type,
          getCurrentUserRole(action.payload, rootState.vm.user)
        );
        commandList.push(Cmd.action(ACTIONS.selectTab(defaultTab.state)));
      }
      if (ou.type === 'SCHOOLENTITY') {
        commandList.push(
          Cmd.run(getGoverningInstitutionOfOu, {
            args: [ou],
            successActionCreator: ACTIONS.currentOuGoverningInstitutionFetched,
          })
        );
      }
      /* if (!state.contactDetailsFetched && ou.$$meta.type !== 'ORGANISATION') {
        commandList.push(Cmd.run(getContactDetails, {
          args: [ou],
          successActionCreator: ACTIONS.currentOuContactDetailsFetched
        }));
      } */
      // TODO temporary solution for Nashcling still looking at VOS
      /* if (ou.type === GOVERNINGINSTITUTION || ou.type === 'CLB') {
        commandList.push(Cmd.run(getVosEquivalent, {
          args: [ou],
          successActionCreator: ACTIONS.vosEquivalentFetched
        }));
      } */
      return loop(newState, Cmd.list(commandList));
    }

    /* case ACTIONS.VOS_EQUIVALENT_FETCHED: {
      return { ...state, vosEquivalent: action.payload };
    } */

    /* case ACTIONS.CURRENT_OU_CONTACTDETAILS_FETCHED: {
      return Object.assign({}, state, { contactDetails: Map(arrayToMap(action.payload)) });
    } */

    case ACTIONS.CURRENT_OU_GOVERNING_INSTITUTION_FETCHED: {
      return {
        ...state,
        governingInstitution: {
          ou: action.payload.governingInstitution,
          mailAddress: action.payload.mailContactDetail,
        },
      };
    }

    case ACTIONS.SELECT_TAB:
      return { ...state, selectedTab: action.payload };

    case ACTIONS.SET_DETAILS_FORM_VALIDATION: {
      return { ...state, isDetailsFormValid: action.payload };
    }

    case ACTIONS.CHANGE_DETAILS_NAME: {
      const currentDetail = getActiveResources(state.currentOrganisationalUnit.details)[0];
      if (action.payload === currentDetail.name) {
        return state;
      }
      let newDetails = state.currentOrganisationalUnit.details.filter(
        (detail) => detail.key !== currentDetail.key
      );
      // case 1: the detail is already 3 months old so the previous name should be stored historically
      if (isBefore(currentDetail.startDate, getPreviousMonth(getNow(), 3))) {
        const newKey = generateUUID();
        const newDetail = {
          ...currentDetail,
          key: newKey,
          name: action.payload,
          startDate: getNow(),
        };
        const endedCurrentDetail = { ...currentDetail, endDate: getPreviousDay(getNow()) };
        newDetails.push(endedCurrentDetail);
        newDetails.push(newDetail);
      } else {
        const previousDetail = getActiveResources(
          state.currentOrganisationalUnit.details,
          getPreviousDay(currentDetail.startDate)
        );
        if (
          previousDetail.name === action.payload &&
          previousDetail.shortName === currentDetail.shortName
        ) {
          // case 2: the current detail is younger than 3 months old and the name is changed back to what it was previously
          newDetails = state.currentOrganisationalUnit.details.filter(
            (detail) => detail.key === previousDetail.key
          );
          const newDetail = { ...previousDetail, endDate: undefined };
          newDetails.push(newDetail);
        } else {
          // case 3: the current detail is younger than 3 months old and the name is just replaced
          const newDetail = { ...currentDetail, name: action.payload };
          newDetails.push(newDetail);
        }
      }
      const newState = {
        ...state,
        nameErrors: [],
        currentOrganisationalUnit: { ...state.currentOrganisationalUnit, details: newDetails },
      };
      return saveDetails(newState);
    }

    case ACTIONS.CHANGE_DETAILS_SHORT_NAME: {
      const currentDetail = getActiveResources(state.currentOrganisationalUnit.details)[0];
      if (action.payload === currentDetail.shortName) {
        return state;
      }
      let newDetails = state.currentOrganisationalUnit.details.filter(
        (detail) => detail.key !== currentDetail.key
      );
      // case 1: the detail is already 3 months old so the previous name should be stored historically
      if (isBefore(currentDetail.startDate, getPreviousMonth(getNow(), 3))) {
        const newKey = generateUUID();
        const newDetail = {
          ...currentDetail,
          key: newKey,
          shortName: action.payload,
          startDate: getNow(),
        };
        const endedCurrentDetail = { ...currentDetail, endDate: getPreviousDay(getNow()) };
        newDetails.push(endedCurrentDetail);
        newDetails.push(newDetail);
      } else {
        const previousDetail = getActiveResources(
          state.currentOrganisationalUnit.details,
          getPreviousDay(currentDetail.startDate)
        );
        if (
          previousDetail.shortName === action.payload &&
          previousDetail.name === currentDetail.name
        ) {
          // case 2: the current detail is younger than 3 months old and the shortName is changed back to what it was previously
          newDetails = state.currentOrganisationalUnit.details.filter(
            (detail) => detail.key === previousDetail.key
          );
          const newDetail = { ...previousDetail, endDate: undefined };
          newDetails.push(newDetail);
        } else {
          // case 3: the current detail is younger than 3 months old and the shortName is just replaced
          const newDetail = { ...currentDetail, shortName: action.payload };
          newDetails.push(newDetail);
        }
      }
      const newState = {
        ...state,
        shortNameErrors: [],
        currentOrganisationalUnit: {
          ...state.currentOrganisationalUnit,
          details: newDetails,
          $$name: action.payload,
        },
      };
      return saveDetails(newState);
    }

    case ACTIONS.CHANGE_DETAILS_DESCRIPTION: {
      const newState = {
        ...state,
        currentOrganisationalUnit: {
          ...state.currentOrganisationalUnit,
          description: action.payload,
        },
      };
      if (!action.payload || action.payload.length === 0) {
        newState.descriptionErrors = [
          { code: 'description.required', message: 'De beschrijving is verplicht' },
        ];
      } else if (action.payload.trim().length < 140) {
        newState.descriptionErrors = [
          {
            message: `De beschrijving moet minimum 140 tekens lang zijn. Huidig aantal tekens: ${action.payload.length}`,
          },
        ];
      } else if (action.payload.trim().length > 2048) {
        newState.descriptionErrors = [
          {
            message: `De beschrijving mag maximum 2000 tekens lang zijn. Huidig aantal tekens: ${action.payload.length}`,
          },
        ];
      } else {
        newState.descriptionErrors = [];
      }
      return saveDetails(newState);
    }

    case ACTIONS.CHANGE_DETAILS_TYPE: {
      const newState = {
        ...state,
        currentOrganisationalUnit: { ...state.currentOrganisationalUnit, type: action.payload },
      };
      return saveDetails(newState);
    }

    case ACTIONS.SAVE_ORGANISATION_DETAILS_SUCCESS: {
      return loop(
        state,
        Cmd.action(addSuccessNotification('De details van het team zijn succesvol opgeslagen'))
      );
    }

    case ACTIONS.SAVE_ORGANISATION_DETAILS_FAILED: {
      if (
        action.payload instanceof SriClientError &&
        action.payload.body &&
        action.payload.body.errors
      ) {
        const { errors } = action.payload.body;
        const newState = { ...state };
        if (errors.some((error) => error.code === 'identical.organisation.in.overlapping.period')) {
          newState.nameErrors = [{ message: 'Er is al een ander team met dezelfde naam' }];
        }
        if (errors.some((error) => error.code === 'shortname.not.unique')) {
          newState.shortNameErrors = [
            { message: 'Er is al een ander team met dezelfde korte naam' },
          ];
        }
        if (newState.nameErrors.length > 0 || newState.shortNameErrors.length > 0) {
          return newState;
        }
        console.log('errors', errors);
      }
      return loop(
        state,
        Cmd.action(
          addErrorNotification(
            'Er is een onverwachte fout opgetreden bij het opslaan van de details van het team'
          )
        )
      );
    }

    default:
      return state;
  }
};
