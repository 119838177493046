import { MyEmailList } from './myEmailList';

export const MyEmailListComponent = {
  template: require('./myEmailList.html'),
  controllerAs: 'ctrl',
  controller: MyEmailList,
  bindings: {
    sEmailList: '<',
  },
};
