import { loop, Cmd } from 'redux-loop';
import { displayOuType } from 'ReduxLoop/appConfig';
import {
  getDateOfBirthFromRegistrationNumber,
  validateRegistrationNumber,
} from 'Js/module/personUtils';
import { addErrorNotification } from 'ReduxLoop/notifications/notificationActions';
import { ADD_REQUEST_TO_REGISTRATION } from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import * as ACTION_TYPES from '../../constants/actionTypes';
import * as ACTIONS from './registerActions';
import * as COMMANDS from './registerCommands';
import * as GLOBAL_COMMANDS from '../../utils';
import { executeRegistration } from './registerActions';

const initialState = {
  key: null,
  stage: null,
  registrationNumber: null,
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  emailAddress: null,
  possiblePositions: [],
  validationCode: null,
  duplicates: [],
  duplicateEmails: [],
  batch: null,
  registrationFailed: null,
  invitationRevoked: false,
  loginProviderKey: null,
  profilePictureMedium: undefined,
  profilePictureSmall: undefined,
  redirectUrl: undefined,
  userKey: null,
  userAlias: null,
  loginProviderDomain: null,
  userinfoParam: null,
  personDoesNotBelongToAnOu: false,
};

export const registerReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.TRY_GOING_TO_REGISTER_PAGE: {
      return loop(
        {
          ...state,
          stage: 'LOGIN_OR_REGISTER',
          registrationNumber: action.payload.registrationNumber,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          dateOfBirth: action.payload.dateOfBirth,
        },
        Cmd.run(COMMANDS.validate, {
          args: [action.payload, undefined, undefined, undefined, 'LOGIN_OR_REGISTER'],
          successActionCreator: ACTIONS.goToRegisterPage,
          failActionCreator: ACTIONS.duplicatesDetected,
        })
      );
    }

    case ACTION_TYPES.GO_TO_REGISTER_PAGE: {
      return loop({ ...state, stage: 'REGISTER_FORM' }, Cmd.run(COMMANDS.goToRegisterPage, {}));
    }

    case ACTION_TYPES.SET_REDIRECT_URL: {
      return { ...state, redirectUrl: action.payload };
    }

    /* case ACTION_TYPES.SET_USERINFO_FROM_OAUTH: {
      const registerForm = {
        registrationNumber: action.payload.registerForm.registrationNumber,
        dateOfBirth: action.payload.registerForm.registrationNumber ? getDateOfBirthFromRegistrationNumber(action.payload.registerForm.registrationNumber) : undefined,
        firstName: action.payload.registerForm.firstName,
        lastName: action.payload.registerForm.lastName,
        emailAddress: action.payload.registerForm.email,
        loginProviderKey: action.payload.registerForm.loginProviderKey,
        userKey: action.payload.registerForm.uuid
      };
      return loop(
        Object.assign({}, state, registerForm, { userinfoParam: action.payload.userinfoParam }),
        Cmd.run(COMMANDS.validate, {
          args: [registerForm, 'SET_USERINFO_FROM_OAUTH'],
          failActionCreator: ACTIONS.duplicatesDetected
        })
      );
    } */

    case ACTION_TYPES.VALIDATE_REGISTRATION: {
      const newState = {
        ...state,
        registrationNumber: action.payload.registrationNumber,
        title: action.payload.title,
        sex: action.payload.sex,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        dateOfBirth: action.payload.dateOfBirth,
        emailAddress: action.payload.emailAddress,
        password: action.payload.password,
      };
      return loop(
        newState,
        Cmd.run(COMMANDS.validate, {
          args: [
            newState,
            action.payload.password,
            rootState.invite.invitationKey,
            rootState.requestResponsibility,
            state.stage,
          ],
          successActionCreator: ACTIONS.validateRegistrationSuccess,
          failActionCreator: ACTIONS.duplicatesDetected,
        })
      );
    }

    case ADD_REQUEST_TO_REGISTRATION: {
      return loop(
        { ...state, personDoesNotBelongToAnOu: !rootState.requestResponsibility.ou },
        Cmd.run(COMMANDS.validate, {
          args: [
            state,
            state.password,
            rootState.invite.invitationKey,
            rootState.requestResponsibility,
            state.stage,
          ],
          successActionCreator: ACTIONS.validateRegistrationSuccess,
          failActionCreator: ACTIONS.duplicatesDetected,
        })
      );
    }

    case ACTION_TYPES.VALIDATE_REGISTRATION_SUCCESS: {
      if (rootState.requestResponsibility.noManagers) {
        const newState = {
          ...state,
          validationCode: action.payload.validationCode,
          batch: action.payload.batch,
          key: action.payload.batch[0].body.key,
        };
        return loop(newState, Cmd.action(executeRegistration()));
      }
      if (
        rootState.invite.invitationKey ||
        rootState.requestResponsibility.ou ||
        state.personDoesNotBelongToAnOu
      ) {
        const newState = {
          ...state,
          stage: 'ACCEPT_CONDITIONS',
          validationCode: action.payload.validationCode,
          batch: action.payload.batch,
          key: action.payload.batch[0].body.key,
        };
        return loop(newState, Cmd.run(COMMANDS.goToAcceptConditionsPage, {}));
      }
      return loop(
        { ...state, stage: 'ADD_REQUEST_TO_REGISTRATION', key: action.payload.batch[0].body.key },
        Cmd.run(GLOBAL_COMMANDS.goTo, { args: ['/#!/aanvraag-team'] })
      );
    }

    case ACTION_TYPES.DUPLICATES_DETECTED: {
      if (!action.payload.duplicates) {
        console.error('Unhandled error', action.payload);
        return loop(
          state,
          Cmd.action(
            addErrorNotification(
              'Er is een onverwachte fout opgetreden. Onze excuses. Probeer het later nog eens opnieuw.'
            )
          )
        );
      }
      return loop(
        {
          ...state,
          validationCode: action.payload.validationCode,
          duplicates: action.payload.duplicates,
        },
        Cmd.run(COMMANDS.goToDuplicatesPage, {})
      );
    }

    case ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS: {
      return loop(
        state,
        Cmd.run(COMMANDS.validateEmailAddress, {
          args: [action.payload],
          successActionCreator: ACTIONS.validateEmailAddressSuccess,
          failActionCreator: ACTIONS.validateEmailAddressFailed,
        })
      );
    }

    case ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS_SUCCESS:
      return { ...state, duplicateEmails: [] };

    case ACTION_TYPES.VALIDATE_REGESTERING_EMAIL_ADDRESS_FAILED:
      return { ...state, duplicateEmails: action.payload };

    case ACTION_TYPES.POSITION_AND_OUS_FETCHED: {
      return { ...state, possiblePositions: action.payload.possiblePositions };
    }

    case ACTION_TYPES.SET_INVITATIONS:
      return { ...state, emailAddress: action.payload.emailAddress };

    case ACTION_TYPES.GENERATE_USERNAME: {
      if (action.payload.firstName && action.payload.lastName) {
        return loop(
          state,
          Cmd.run(COMMANDS.getUsername, {
            args: [action.payload.firstName, action.payload.lastName],
            successActionCreator: ACTIONS.usernameGenerated,
          })
        );
      }
      return state;
    }

    case ACTION_TYPES.USERNAME_GENERATED: {
      return { ...state, username: action.payload.username };
    }

    case ACTION_TYPES.EXECUTE_REGISTRATION: {
      return loop(
        state,
        Cmd.run(COMMANDS.execute, {
          args: [
            state.batch,
            state.profilePictureSmall,
            state.profilePictureMedium,
            state.redirectUrl,
            state.validationCode,
          ],
          successActionCreator: ACTIONS.executeRegistrationSuccess,
          failActionCreator: ACTIONS.executeRegistrationFailed,
        })
      );
    }

    case ACTION_TYPES.EXECUTE_REGISTRATION_SUCCESS: {
      const destination = `/#!/mail-verzonden?type=registreer${
        rootState.requestResponsibility.noManagers
          ? `&geen-beheerders=true&ou=${rootState.requestResponsibility.ou.name}`
          : ''
      }`;
      return loop(
        { ...state, registrationFailed: null },
        Cmd.run(GLOBAL_COMMANDS.goTo, { args: [destination] })
      );
    }

    case ACTION_TYPES.EXECUTE_REGISTRATION_FAILED: {
      if (!Array.isArray(action.payload.body)) {
        return { ...state, registrationFailed: action.payload };
      }
      const acceptInvitation = action.payload.body.filter(
        (batchObj) => batchObj.href === '/responsibilities/pending/accept'
      )[0];
      if (
        acceptInvitation &&
        acceptInvitation.status === 409 &&
        acceptInvitation.body.errors.some((error) => error.code === 'invitation.declined')
      ) {
        const ouType = displayOuType(rootState.invite.organisationalUnits[0].type);
        const alsPositionName = rootState.invite.position
          ? ` als ${rootState.invite.position.name}`
          : '';
        const positionName = rootState.invite.position ? rootState.invite.position.name : 'actief';
        const ouName = rootState.invite.organisationalUnits[0].name;
        const invitationRevoked = `Een beheerder van jouw ${ouType} heeft de uitnodiging${alsPositionName} in ${ouName} terug ingetrokken. Kijk in jouw mailbox of je niet al een nieuwe uitnodiging ontvangen hebt. Als dit niet het geval is en je bent wel degelijk ${positionName} in ${ouName}, vraag dan aan jouw directie om jou opnieuw uit te nodigen.`;
        return { ...state, invitationRevoked };
      }
      if (
        acceptInvitation &&
        acceptInvitation.status === 409 &&
        acceptInvitation.body.errors.some((error) => error.code === 'link.expired')
      ) {
        const alsPositionName = rootState.invite.position
          ? ` als ${rootState.invite.position.name}`
          : '';
        const ouName = rootState.invite.organisationalUnits[0].$$displayName;
        const invitationRevoked = `De uitnodiging${alsPositionName} in ${ouName} is meer dan een half jaar oud en is vervallen. Vraag aan jouw directie om jou een nieuwe uitnodiging te sturen.`;
        return { ...state, invitationRevoked };
      }
      if (
        acceptInvitation &&
        acceptInvitation.status === 409 &&
        acceptInvitation.body.errors.some((error) => error.code === 'link.already.used')
      ) {
        const invitationRevoked =
          'De uitnodigingslink is al eens gebruikt. Je kan de link geen twee keer gebruiken.';
        return { ...state, invitationRevoked };
      }
      const request = action.payload.body.filter((batchObj) =>
        batchObj.href.match(/\/responsibilities\/pending\/[0-9a-f-]{36}/)
      )[0];
      if (
        request &&
        request.status === 409 &&
        request.body.errors.some((error) => error.code === 'no.managers')
      ) {
        return state;
      }
      console.error('SAVE_PROFILE_ERROR: REGISTRATION_FAILED:', action.payload);
      return { ...state, registrationFailed: action.payload };
    }

    case ACTION_TYPES.REGISTER_FOR_LINK_ACCOUNTS: {
      let stamboekNummer =
        action.payload.userInfo.stamboeknummer &&
        action.payload.userInfo.stamboeknummer.toLowerCase() !== 'null'
          ? action.payload.userInfo.stamboeknummer
          : null;
      if (stamboekNummer) {
        stamboekNummer = stamboekNummer.replace(/\s/g, '').replace(/-/g, '');
        if (
          stamboekNummer.length > 11 &&
          validateRegistrationNumber(stamboekNummer.substring(0, 11))
        ) {
          stamboekNummer = stamboekNummer.substring(0, 11);
        }
        if (validateRegistrationNumber(stamboekNummer)) {
          console.warn(
            `INVALID_EXTERNAL_REGISTRATION_NUMBER: ${stamboekNummer} received from loginprovider-${action.payload.userInfo.loginProviderKey} for ${action.payload.userInfo.alias} is not valid (user is regestering).`
          );
          stamboekNummer = undefined;
        }
      }
      const registerForm = {
        loginProviderKey: action.payload.userInfo.loginProviderKey,
        userKey: action.payload.userInfo.uuid,
        userAlias: action.payload.userInfo.alias,
        loginProviderDomain: action.payload.userInfo.domain,
        firstName: action.payload.userInfo.firstName,
        lastName: action.payload.userInfo.lastName,
        registrationNumber: stamboekNummer,
        dateOfBirth: stamboekNummer
          ? getDateOfBirthFromRegistrationNumber(stamboekNummer)
          : undefined,
        emailAddress: action.payload.userInfo.email
          ? action.payload.userInfo.email.trim()
          : undefined,
        profilePictureMedium: action.payload.profilePictures
          ? action.payload.profilePictures.image256.replace(/data:image\/jpeg;base64,/, '')
          : undefined,
        profilePictureSmall: action.payload.profilePictures
          ? action.payload.profilePictures.image128.replace(/data:image\/jpeg;base64,/, '')
          : undefined,
      };
      return loop(
        { ...state, ...registerForm, userinfoParam: action.payload.userinfoParam },
        Cmd.run(COMMANDS.validate, {
          args: [registerForm, undefined, undefined, undefined, 'SET_USERINFO_FROM_OAUTH'],
          successActionCreator: ACTIONS.goToRegisterPage,
          failActionCreator: ACTIONS.duplicatesDetected,
        })
      );
    }

    default:
      return state;
  }
};
