const classes = [
  {
    key: '1',
    name: 'klas 1A1',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '2',
    name: 'klas 1A2',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '3',
    name: 'klas 1A3',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '4',
    name: 'klas 1A4',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '5',
    name: 'klas 1A5',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '6',
    name: 'klas 1B',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '7',
    name: 'klas 2A1',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '8',
    name: 'klas 2A2',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '9',
    name: 'klas 2A3',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '10',
    name: 'klas 2A4',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '11',
    name: 'klas 2A5',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '12',
    name: 'klas 2B',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '13',
    name: 'klas 3A1',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
  {
    key: '13',
    name: 'klas 3A2',
    pupilCount: 23,
    tutor: 'Jan Janssens',
  },
];

module.exports = {
  classes,
};
