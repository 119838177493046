import { validateRegisteringEmailAddress } from 'ReduxLoop/registerAndConfirmInvitation/register/registerActions';

export class MyRegisterConfirmEmail {
  constructor($ngRedux) {
    'ngInject';

    this.editIcon = require('Img/edit.svg');
    this.arrowRight = require('Img/arrow-right.svg');
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      emailWarnings:
        state.register.duplicateEmails.length > 0
          ? [
              {
                code: 'duplicate.email',
                message: `Dit e-mailadres staat al genoteerd bij ${
                  state.register.duplicateEmails.length
                } andere gebruiker${state.register.duplicateEmails.length > 1 ? 's.' : '.'}`,
              },
            ]
          : [],
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.emailAddress = this.resolve.emailAddress;
  }

  emailAddressChanged(e, emailAddress) {
    this.emailAddress = emailAddress;
    if (this.eForm.formGroups.email.valid) {
      this.$ngRedux.dispatch(validateRegisteringEmailAddress(this.emailAddress));
    }
  }

  onContinue() {
    this.submitAttempted = true;
    if (!this.eForm.valid || this.emailWarnings.length > 0) {
      return;
    }
    this.close({ $value: this.emailAddress });
  }

  onIsMine() {
    console.warn(
      `DUPLICATE_EMAIL: registering user, ${this.resolve.name}, claims ${this.emailAddress} is only his.`
    );
    this.close({ $value: null });
  }

  acceptRisks() {
    console.warn(
      `DUPLICATE_EMAIL: registering user, ${this.resolve.name}, indicates he shares ${this.emailAddress} with a colleague!!!!!.`
    );
    this.close({ $value: null });
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
