import { printAddress } from '@kathondvla/sri-client/address-utils';
import {
  isAfterOrEqual,
  printFutureForPeriodic,
  getClosestSchoolYearSwitch,
  printDate,
} from '@kathondvla/sri-client/date-utils';
import * as reduxActions from 'ReduxLoop/ouManager/classes/classesActions';

require('./klassenTab.scss');

export class KlassenTab {
  constructor($uibModal, $ngRedux, settings) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$ngRedux = $ngRedux;
    this.settings = settings;

    this.pupilsIcon = require('Img/pupils.svg');
    this.addIcon = require('Img/add.svg');

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      school: state.vm.schoolManager.currentOU,
      userRole: state.vm.schoolManager.currentOU.userRole,
      campusClasses: state.vm.schoolManager.campusClasses,
      classNameFilter: state.classesTab.filter,
      userCanEditClasses: state.vm.classesTab.userCanEditClasses,
    }))(this);
  }

  $onInit() {
    this.printFutureForPeriodic = printFutureForPeriodic;
    this.showClassPeriod = ['test', 'local'].includes(this.settings.environment);
  }

  applyFilter(e) {
    this.$ngRedux.dispatch(reduxActions.applyFilterAction(this.classNameFilter));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  deleteClass(e, clazz) {
    const deleteClassModal = this.deleteClassModal(clazz);

    deleteClassModal.result.then(
      (result) => {
        if (isAfterOrEqual(clazz.startDate, result.endDate)) {
          this.$ngRedux.dispatch(
            reduxActions.deleteClassAction({
              clazz,
            })
          );
        } else {
          this.$ngRedux.dispatch(
            reduxActions.endClassAction({
              clazz,
              endDate: result.endDate,
            })
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteClassModal(clazz) {
    return this.$uibModal.open({
      animation: true,
      component: 'myDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        startDate: () => clazz.startDate,
        endDate: () => getClosestSchoolYearSwitch(),
        subtitle: () => 'Deze klas afschaffen.',
        endDateHelpText: () => 'Wanneer stop(te) deze klas?',
        startDateInfo: () => `${clazz.name} bestaat sinds ${printDate(clazz.startDate)}`,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  printCampusAddress(campus) {
    return printAddress(campus.address);
  }

  addEditClass(e, clazz) {
    const addClassModal = this.addEditClassModal(clazz);

    addClassModal.result.then(
      (modalResult) => {
        if (clazz) {
          this.$ngRedux.dispatch(reduxActions.editClassAction(modalResult));
        } else {
          this.$ngRedux.dispatch(reduxActions.addClassAction(modalResult));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  addEditClassModal(clazz) {
    return this.$uibModal.open({
      animation: true,
      component: 'myAddEditClassModal',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        clazz: () => clazz,
        school: () => this.school,
        campusClasses: () => this.campusClasses,
      },
    });
  }
}
