import { MyOuList } from './myOuList';

export const myOuListComponent = {
  template: require('./myOuList.html'),
  controllerAs: 'ctrl',
  controller: MyOuList,
  transclude: true,
  bindings: {
    sManagements: '<',
    sSelectedKeys: '<',
    sMultiple: '<',
  },
};
