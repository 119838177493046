const groups = [
  {
    key: 'g1',
    name: 'Jaarproject duurzame tuinbouw',
    members: [
      {
        key: '6',
        name: 'Freddy Mangelhof',
        position: {
          name: 'Voorzitter',
          class: {
            sortOrder: 0,
          },
        },
        emailAddress: 'freddy.mangelhof@katholiekonderwijs.vlaanderen',
      },
      {
        key: '8',
        name: 'Jan Dediener',
        position: {
          name: 'Lid',
          class: {
            sortOrder: 1,
          },
        },
        emailAddress: 'jan.dediener@katholiekonderwijs.vlaanderen',
      },
    ],
  },
  {
    key: 'g2',
    name: 'Vakgroep Aardrijkskunde',
    members: [],
  },
  {
    key: 'g3',
    name: 'Vakgroep Wiskunde',
    members: [],
  },
  {
    key: 'g4',
    name: 'Vakgroep Frans',
    members: [],
  },
  {
    key: 'g5',
    name: 'Vakgroep Nederlands',
    members: [],
  },
];

module.exports = {
  groups,
};
