import { MyTeamInfo } from './teamInfo';

require('./teamInfo.scss');

export const myTeamInfoComponent = {
  template: require('./teamInfo.html'),
  controllerAs: 'ctrl',
  controller: MyTeamInfo,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
