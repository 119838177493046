import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from 'Js/myComponents/myReactCheckbox/Checkbox';
import './CurriculumSelectorListOfSubjects.scss';

import * as NEWSLETTER_ACTIONS from 'ReduxLoop/newsletters/preferences/newsletterActions';
import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';

const CurriculumSelectorListOfSubjects = ({ subjects, showMoreOption }) => {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(8);

  const updateCheckbox = (subject) => {
    return dispatch(
      NEWSLETTER_ACTIONS.newsletterSetOption(
        !subject.checked,
        NEWSLETTER_TYPES.SUBJECTS,
        subject.href,
        'SUBJECTS',
        null
      )
    );
  };

  const toggleShowMore = () => (limit === 8 ? setLimit(subjects.lenght) : setLimit(8));

  return (
    <div>
      <div className="subjects col-sm-12">
        <div className="subject-list curriculum-theme-list">
          {subjects.slice(0, limit).map((subject) => (
            <Checkbox
              key={subject.key}
              id={subject.key}
              label={subject.name}
              checked={subject.checked}
              onChange={() => updateCheckbox(subject)}
            />
          ))}
        </div>
      </div>
      {showMoreOption && subjects.length > 8 ? (
        <div className="col-md-12 show-more">
          <a onClick={() => toggleShowMore()}>
            <span
              className={`${
                limit === 8 ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-up'
              }`}
            />
            {limit === 8 ? <span> Toon alle</span> : <span> Laat minder zien</span>}
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default CurriculumSelectorListOfSubjects;
