import { generateUUID } from '@kathondvla/sri-client/common-utils';
import SriClientError from '@kathondvla/sri-client/sri-client-error';
import { getNow } from '@kathondvla/sri-client/date-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import {
  COORDINATOR,
  DIRECTEUR,
  LEIDINGGEVENDE_VAN_DE_VICARIALE_DIENST,
  TEAMVERANTWOORDELIJKE,
  VERANTWOORDELIJKE,
  VOORZITTER,
} from 'ReduxLoop/constants';
import { settings } from 'Js/config/settings';

class ValidationError {
  constructor(obj) {
    this.code = obj.code;
    this.duplicate = obj.duplicate;
    this.suggestedCode = obj.suggestedCode;
    this.duplicateVoorziterResponsibilities = obj.duplicateVoorziterResponsibilities;
  }
}

export const createOu = (state, validate) => {
  const ouKey = generateUUID();
  const ou = {
    $$meta: { permalink: `/organisations/${ouKey}`, type: 'ORGANISATION' },
    key: ouKey,
    description: state.description,
    details: [
      {
        key: generateUUID(),
        name: state.name,
        shortName:
          validate && !state.shortName ? 'A very very very unique shortName' : state.shortName,
        startDate: state.startDate,
      },
    ],
    seatAddresses: [],
    $$name: state.shortName,
    telecoms: {
      phones: [],
      faxes: [],
      emails: [],
      websites: [],
    },
    type: state.selectedType,
  };
  if (!ou.description) {
    delete ou.description;
  }
  return ou;
};

export const createWorkgroup = (state, ou, validate) => {
  let organisationalUnit = ou;
  if (!organisationalUnit && state.organisation) {
    organisationalUnit = state.organisation;
  } else if (!organisationalUnit) {
    if (!validate) {
      throw new Error(
        'HOW! We komen in een stuk waar je alleen maar mag komen als we in validatiemode zitten en dat is niet het geval!'
      );
    }
    // Workgroups Api and Organisational Units Api should be one because you want to create and validate them both at the same time
    // Because they are now two separate api's we now have to do validation with an existing organisation, which is a workaround.
    organisationalUnit = {
      $$meta: { permalink: '/organisations/ed036446-2913-476e-8528-485e0bbdfd3f' },
      key: 'ed036446-2913-476e-8528-485e0bbdfd3f',
    };
  }
  const wgKey = organisationalUnit.key;
  return {
    $$meta: { permalink: `/workgroups/${wgKey}` },
    key: wgKey,
    code: state.code || state.codeRaw,
    description: state.description,
    organisation: { href: organisationalUnit.$$meta.permalink },
    hasOffice365GroupIncludingMemberOUs: true,
    hasDrupalCollaboration: false,
    hasSharepoint: false,
    hasOutlookDistributionList: false,
    hasOutlookDistributionListIndirectMembers: false,
    hasOffice365Group: false,
    hasOffice365GroupIndirectMembers: false,
    hasOffice365GroupIncludingMemberOUsIndirectMembers: false,
    isCreatedByLoket: true,
  };
};

export const createResponsibility = (state, rootState, ou) => {
  const respKey = generateUUID();
  return {
    $$meta: { permalink: `/responsibilities/${respKey}`, created: new Date().toISOString() },
    key: respKey,
    person: { href: rootState.userState.user.$$meta.permalink },
    organisation: { href: ou.$$meta.permalink },
    position: { href: VOORZITTER },
    startDate: state.startDate,
  };
};

let currentAbortWorkgroupValidationController = null;
export const validateWorkgroup = async (state) => {
  if (!state.codeRaw) {
    return;
  }
  try {
    const workgroup = createWorkgroup(state, null, true);
    if (currentAbortWorkgroupValidationController) {
      currentAbortWorkgroupValidationController.abort();
    }
    currentAbortWorkgroupValidationController = new AbortController();
    await sriClient.put(`${workgroup.$$meta.permalink}?dryRun=true`, workgroup, {
      cancel: currentAbortWorkgroupValidationController.signal,
    });
  } catch (error) {
    if (error instanceof SriClientError) {
      const { errors } = error.body;
      const codeNotUniqueError = errors.find((err) => err.code === 'code.not.unique');
      if (codeNotUniqueError) {
        throw new ValidationError(codeNotUniqueError);
      }
    }
    if (error.name && error.name === 'AbortError') {
      console.log(
        'A new request was send before the response of another request was received. The first request is canceled'
      );
      return;
    }
    console.log('UNEXPECTED_VALIDATE_WORKGROUP_ERROR', error);
    throw error;
  }
};

let currentAbortOrganisationValidationController = null;
export const validateOrganisation = async (state) => {
  const ou = createOu(state, true);
  let workgroup = null;
  try {
    if (currentAbortOrganisationValidationController) {
      currentAbortOrganisationValidationController.abort();
    }
    currentAbortOrganisationValidationController = new AbortController();
    await sriClient.post(`${ou.$$meta.permalink}/validate`, ou, {
      cancel: currentAbortOrganisationValidationController.signal,
    });
    return {};
  } catch (error) {
    if (error instanceof SriClientError) {
      const { errors } = error.body;
      let voorzitterResps = null;
      const notUniqueNameError = errors.find(
        (err) => err.code === 'identical.organisation.in.overlapping.period'
      );
      if (notUniqueNameError) {
        const ouHref = notUniqueNameError.hrefs[0];
        const organisationalUnit = await sriClient.get(ouHref);
        const responsibilities = await sriClient.getAll('/responsibilities', {
          organisations: ouHref,
          expandPerson: 'SUMMARY',
        });
        voorzitterResps = responsibilities.filter(
          (resp) => resp.position && resp.position.href === VOORZITTER
        );
        if (responsibilities.length === 0) {
          [workgroup] = await sriClient.getAll('/workgroups', { organisation: ouHref });
          return {
            organisation: organisationalUnit,
            workgroup,
          };
        }
        if (!voorzitterResps) {
          voorzitterResps = await sriClient.getAll('/responsibilities', {
            organisations: ouHref,
            positions: VOORZITTER,
            expandPerson: 'SUMMARY',
          });
        }
        throw new ValidationError({
          code: 'name.not.unique',
          duplicate: organisationalUnit,
          duplicateVoorziterResponsibilities: voorzitterResps,
        });
      }
      const shortNameNotUniqueError = errors.find((err) => err.code === 'shortname.not.unique');
      if (shortNameNotUniqueError) {
        const ouHref = shortNameNotUniqueError.hrefs[0];
        const organisationalUnit = await sriClient.get(ouHref);
        voorzitterResps = await sriClient.getAll('/responsibilities', {
          organisations: ouHref,
          positions: VOORZITTER,
          expandPerson: 'SUMMARY',
        });
        throw new ValidationError({
          code: 'shortname.not.unique',
          duplicate: organisationalUnit,
          duplicateVoorziterResponsibilities: voorzitterResps,
        });
      }
      const similarOrganisationWarning = errors.find(
        (err) => err.code === 'similar.organisationalunit'
      );
      if (similarOrganisationWarning) {
        const mostSimilar = similarOrganisationWarning.similarOrganisations.reduce((acc, cur) =>
          !acc ||
          acc.dlDistance > cur.dlDistance ||
          (acc.dlDistance === cur.dlDistance &&
            cur.properties.some((prop) => prop === 'name') &&
            acc.properties.some((prop) => prop === 'shortName'))
            ? cur
            : acc
        );
        const ouHref = mostSimilar.similar.href;
        const organisationalUnit = await sriClient.get(ouHref);
        voorzitterResps = await sriClient.getAll('/responsibilities', {
          organisations: ouHref,
          positions: VOORZITTER,
          expandPerson: 'SUMMARY',
        });
        throw new ValidationError({
          code: 'similar.organisation',
          duplicate: organisationalUnit,
          duplicateVoorziterResponsibilities: voorzitterResps,
        });
      }
    }
    if (error.name && error.name === 'AbortError') {
      console.log(
        'A new request was send before the response of another request was received. The first request is canceled'
      );
      return {};
    }
    console.log('UNEXPECTED_VALIDATE_ORGANISATION_ERROR', { ou, error });
    throw error;
  }
};

const notifyDepartment = async (workgroup, rootState) => {
  let recipients = [settings.loketResponsible.href];
  const allKathOndVlaDepartments = [
    ...Object.values(settings.kathOndVlaCentralDepartments),
    ...Object.values(settings.vicarialeDiensten),
    ...Object.values(settings.congregaties),
  ];
  const departments = [
    ...new Set([
      ...rootState.userState.dienstParentRelations
        .filter((rel) => allKathOndVlaDepartments.some((dep) => dep === rel.to.href))
        .map((rel) => rel.to.href),
      ...rootState.userState.responsibilities.vos
        .filter((resp) => allKathOndVlaDepartments.some((dep) => dep === resp.organisation.href))
        .map((resp) => resp.organisation.href),
    ]),
  ];
  console.log('departments', departments);
  if (departments.length > 0) {
    const directorsOfDepartments = (
      await sriClient.getAll('/responsibilities', {
        organisations: departments.join(','),
        positions: [DIRECTEUR, LEIDINGGEVENDE_VAN_DE_VICARIALE_DIENST, VERANTWOORDELIJKE].join(','),
        date: getNow(),
      })
    ).map((resp) => resp.person.href);
    console.log('directeur van het Departement', directorsOfDepartments);
    recipients = [...recipients, ...directorsOfDepartments];
    const subDepartmentRelations = await sriClient.getAll('/organisationalunits/relations', {
      types: 'IS_PART_OF',
      tos: departments.join(','),
      statuses: 'ACTIVE',
      expandFrom: 'SUMMARY',
    });
    console.log('subRels', subDepartmentRelations);
    if (subDepartmentRelations.length > 0) {
      const administrativeSubDepartments = subDepartmentRelations
        .filter(
          (rel) =>
            rel.from.$$expanded.$$name.toLowerCase() === 'administratie' ||
            rel.from.$$expanded.$$name.toLowerCase() === 'beleidsondersteuning & administratie'
        )
        .map((rel) => rel.from.href);
      const adminCoordinatorsOfDepartments = (
        await sriClient.getAll('/responsibilities', {
          organisations: administrativeSubDepartments.join(','),
          positions: COORDINATOR,
          date: getNow(),
        })
      ).map((resp) => resp.person.href);
      console.log('admin coordinators van het Departement', adminCoordinatorsOfDepartments);
      recipients = [...recipients, ...adminCoordinatorsOfDepartments];
      const teamsOfCur = subDepartmentRelations
        .filter((rel) => rel.to.href === settings.kathOndVlaCentralDepartments.CUR_VOR)
        .map((rel) => rel.from.href);
      if (teamsOfCur.length > 0) {
        const teamsOfCurOfUser = [
          ...new Set([
            ...rootState.userState.dienstParentRelations
              .filter((rel) => teamsOfCur.some((dep) => dep === rel.to.href))
              .map((rel) => rel.to.href),
            ...rootState.userState.responsibilities.vos
              .filter((resp) => teamsOfCur.some((dep) => dep === resp.organisation.href))
              .map((resp) => resp.organisation.href),
          ]),
        ];
        const teamVerantwoordelijkeOfTeamOfCur = (
          await sriClient.getAll('/responsibilities', {
            organisations: teamsOfCurOfUser.join(','),
            positions: TEAMVERANTWOORDELIJKE,
            date: getNow(),
          })
        ).map((resp) => resp.person.href);
        console.log('teamverantwoordelijken van Cur & Vorm', teamVerantwoordelijkeOfTeamOfCur);
        recipients = [...recipients, ...teamVerantwoordelijkeOfTeamOfCur];
      }
    }
  }
  console.log('all recipients', recipients);
  await sriClient.post('/workgroups/notify-new-workgroup-created', {
    recipients,
    workgroup: { href: workgroup.$$meta.permalink },
    loketResponsible: settings.loketResponsible,
  });
};

export const persistNewTeam = async (state, rootState) => {
  let ou = state.organisation;
  if (!ou) {
    ou = createOu(state);
    await sriClient.put(ou.$$meta.permalink, ou);
  } else if (
    ou.details[0].name !== state.name ||
    ou.details[0].shortName !== state.shortName ||
    ou.description !== state.description
  ) {
    ou.details[0].name = state.name;
    ou.details[0].shortName = state.shortName;
    ou.description = state.description;
    await sriClient.updateResource(ou);
  }

  let { workgroup } = state;
  if (!workgroup) {
    workgroup = createWorkgroup(state, ou);
  } else {
    // TODO check code!
    workgroup.hasOffice365GroupIncludingMemberOUs = true;
    workgroup.isCreatedByLoket = true;
  }

  const responsibility = createResponsibility(state, rootState, ou);
  await sriClient.post(`${responsibility.$$meta.permalink}/validate`, responsibility);
  await sriClient.updateResource(workgroup);
  await sriClient.updateResource(responsibility);

  const ret = {
    organisation: ou,
    workgroup,
    responsibility,
  };

  try {
    await notifyDepartment(workgroup, rootState);
  } catch (sendNotificationError) {
    console.error(sendNotificationError);
    ret.notifyDepartmentFailed = true;
  }

  return ret;
};
