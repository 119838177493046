// user action types
// moved!

// profile action types
// moved!

// team action types
// moved!

// group action types
// moved!

// study programmes action types
// moved!

// class action types
// moved!

// request responsibility action types
// moved!

// invitation action types
export const SET_INVITATIONS = 'set_invitations';
export const FETCH_POSITION_AND_OUS = 'fetch_position_and_ous';
export const POSITION_AND_OUS_FETCHED = 'position_and_ous_fetched';
export const FETCH_INVITATION_CAMPUSSES = 'fetch_invitation_campusses';
export const INVITATION_CAMPUSSES_FETCHED = 'invitation_campusses_fetched';
export const TOGGLE_INVITATION_OU_SELECTED = 'toggle_invitation_ou_selected';
export const FETCH_INVITED_USER = 'fetch_invited_user';
export const INVITED_USER_FETCHED = 'invited_user_fetched';
export const CONTACT_HELPDESK = 'report_missing_school';
export const MISSING_SCHOOL_REPORTED = 'missing_school_reported';

// register action types
export const REGISTER_FOR_LINK_ACCOUNTS = 'register_for_link_accounts';
export const TRY_GOING_TO_REGISTER_PAGE = 'try_going_to_register_page';
export const GO_TO_REGISTER_PAGE = 'go_to_register_page';
export const SET_USERINFO_FROM_OAUTH = 'set_userinfo_from_oauth';
export const SET_REDIRECT_URL = 'set_redirect_url';
export const VALIDATE_REGISTRATION = 'validate_registration';
export const VALIDATE_REGISTRATION_SUCCESS = 'validate_registration_success';
export const DUPLICATES_DETECTED = 'duplicates_detected';
export const EXECUTE_REGISTRATION = 'execute_registration';
export const EXECUTE_REGISTRATION_SUCCESS = 'execute_registration_success';
export const EXECUTE_REGISTRATION_FAILED = 'execute_registration_failed';
export const GENERATE_USERNAME = 'generate_username';
export const USERNAME_GENERATED = 'username_generated';
export const VALIDATE_REGESTERING_EMAIL_ADDRESS = 'validate_registering_email_address';
export const VALIDATE_REGESTERING_EMAIL_ADDRESS_SUCCESS =
  'validate_registering_email_address_success';
export const VALIDATE_REGESTERING_EMAIL_ADDRESS_FAILED =
  'validate_registering_email_address_failed';

// Helpdesk
export const SEARCH_VALUE_CHANGED = 'search_value_changed';
export const SHOW_MORE_RESULTS = 'show_more_results';
export const OU_TYPE_SEARCH_FILTER_CHANGED = 'ou_type_changed';
export const SEARCH_RESULTS_FETCHED = 'search_results_fetched';
export const ALL_SECONDARY_SCHOOLS_FETCHED = 'all_secondary_schools_fetched';
export const ALL_SAM_OUS_FETCHED = 'all_sam_ous_fetched';
export const ALL_VOS_OUS_OF_TYPE_FETCHED = 'all_vos_ous_of_type_fetched';
export const OU_FETCHED_BY_INSTITUTION_NUMBER = 'ou_fetched_by_institution_number';
export const MISSING_SCHOOLS_FETCHED = 'missing_schools_fetched';

// notifications action types
export const ADD_NOTIFICATION = 'add_notification';
export const ADD_SUCCESS_NOTIFICATION = 'add_success_notification';
export const ADD_INFO_NOTIFICATION = 'add_info_notification';
export const ADD_WARNING_NOTIFICATION = 'add_warning_notification';
export const ADD_ERROR_NOTIFICATION = 'add_error_notification';
export const REMOVE_NOTIFICATION = 'remove_notification';
export const CLEAR_ALL_NOTIFICATIONS = 'clear_alls_notifications';

// Newsletter
export const NEWSLETTER_USER_DEFAULT_PREFERENCES = 'newsletter_user_default_preferences';
export const NEWSLETTER_USER_DEFAULT_PREFERENCES_FETCHED =
  'newsletter_user_default_preferences_fetched';

export const NEWSLETTER_USER_PREFERENCES = 'newsletter_user_preferences';
export const NEWSLETTER_USER_PREFERENCES_FETCHED = 'newsletter_user_preferences_fetched';
export const NEWSLETTER_GET_OPTIONS = 'newsletter_get_options';
export const NEWSLETTER_OPTIONS_FETCHED = 'newsletter_options_fetched';
export const NEWSLETTER_SET_OPTION = 'newsletter_set_option';
export const NEWSLETTER_SET_SUBSCRIPTIONS = 'newsletter_set_subscriptions';
export const NEWSLETTER_PERSIST_CHANGES = 'newsletter_persist_changes';
export const NEWSLETTER_PERSIST_CHANGES_FAILED = 'newsletter_persist_changes_failed';
export const NEWSLETTER_PERSIST_CHANGES_SUCCEEDED = 'newsletter_persist_changes_succeeded';
export const PERSIST_NEWSLETTERS_SUBSCRIPTIONS = 'persist_newsletter_subscriptions';
export const PERSIST_NEWSLETTERS_SUBSCRIPTIONS_FAILED = 'persist_newsletter_subscriptions_failed';
export const PERSIST_NEWSLETTERS_SUBSCRIPTIONS_SUCCEEDED =
  'persist_newsletter_subscriptions_succeeded';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION = 'persist_newsletter_unsubscription';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION_FAILED = 'persist_newsletter_unsubscription_failed';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION_SUCCEEDED =
  'persist_newsletter_unsubscription_succeeded';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS =
  'persist_newsletter_unsubscription_reasons';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS_FAILED =
  'persist_newsletter_unsubscription_reasons_failed';
export const PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS_SUCCEEDED =
  'persist_newsletter_unsubscription_reasons_succeeded';
export const UNSUBSCRIBE_MODEL_OPENED = 'UNSUBSCRIBE_MODEL_OPENED';

export const NEWSLETTER_SHOW_ALL_SELECTED_SUBJECTS = 'newsletter_show_all_selected_subjects';
export const NEWSLETTER_SHOW_ALL_DESELECTED_SUBJECTS = 'newsletter_show_all_deselected_subjects';
export const NEWSLETTER_SHOW_LESS_SELECTED_SUBJECTS = 'newsletter_show_less_selected_subjects';
export const NEWSLETTER_SHOW_LESS_DESELECTED_SUBJECTS = 'newsletter_show_less_deselected_subjects';
export const NEWSLETTER_FILTER_DESELECTED_SUBJECTS = 'newsletter_filter_deselected_subjects';

export const NEWSLETTER_GET_UNSUBSCRIBE_REASONS_FROM_EXTERNAL_LINK =
  'newsletter_get_unsubscribe_reasons_from_external_link';
export const NEWSLETTER_OPTIONS_FETCHED_FROM_EXTERNAL_LINK =
  'newsletter_options_fetched_from_external_link';
export const NEWSLETTERS_UNSUBSCRIBE_FROM_EXTERNAL_LINK =
  'newsletters_unsubscribe_from_external_link';
export const NEWSLETTERS_UNSUBSCRIBE_SUCCEEDED_FROM_EXTERNAL_LINK =
  'newsletters_unsubscribe_succeeded_from_external_link';
export const NEWSLETTERS_UNSUBSCRIBE_FAILED_FROM_EXTERNAL_LINK =
  'newsletters_unsubscribe_failed_from_external_link';
export const NEWSLETTER_SET_UNSUBSCRIBE_REASON_FROM_EXTERNAL_LINK =
  'newsletter_set_unsubscribe_reason_from_external_link';
export const NEWSLETTER_SET_UNSUBSCRIBE_REASON_SUCCEEDED_FROM_EXTERNAL_LINK =
  'newsletter_set_unsubscribe_reason_succeeded_from_external_link';
export const NEWSLETTER_SET_UNSUBSCRIBE_REASON_FAILED_FROM_EXTERNAL_LINK =
  'newsletter_set_unsubscribe_reason_failed_from_external_link';
