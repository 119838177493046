import {
  getStartOfSchoolYear,
  getNow,
  isOverlapping,
  isBefore,
  isAfterOrEqual,
} from '@kathondvla/sri-client/date-utils';
import { COORDINATOR } from 'ReduxLoop/constants';

require('./addEditGroupModal.scss');

export class AddEditGroupModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.addIcon = require('Img/add.svg');
  }

  $onInit() {
    if (this.resolve.group) {
      this.startDate = this.resolve.group.startDate;
      this.name = this.resolve.group.name;
      this.groupPermalink = this.resolve.group.permalink;
    } else {
      this.startDate = getStartOfSchoolYear(getNow());
    }
    this.schoolStartDate = this.resolve.schoolStartDate;
    this.schoolEndDate = this.resolve.schoolEndDate;
    this.candidateMembers = this.resolve.candidateMembers;
    this.coordinatorPosition = this.resolve.coordinatorPosition;

    this.existingGroups = this.resolve.groups;
  }

  filterMembers(data, str) {
    return data.filter((member) => {
      return new RegExp(str, 'gi').test(member.familiarName);
    });
  }

  displayMember(member) {
    if (!member) {
      return null;
    }

    return `${member.familiarName}`;
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }

  onChangeStartDate(e, startDate) {
    this.startDate = startDate;
  }

  validateGroupStartDate(groupStartDate, schoolStartDate, schoolEndDate) {
    if (isBefore(groupStartDate, schoolStartDate)) {
      return {
        code: 'group.startdate.before.school.startdate',
        message: `De school is pas ingericht vanaf ${schoolStartDate
          .split('-')
          .reverse()
          .join('/')}.`,
      };
    }
    if (isAfterOrEqual(groupStartDate, schoolEndDate)) {
      return {
        code: 'group.startdate.after.school.enddate',
        message: `De school heeft een einddatum op ${schoolEndDate
          .split('-')
          .reverse()
          .join('/')}.`,
      };
    }
    return null;
  }

  onChangeGroupName(e, groupName) {
    this.name = groupName;
  }

  validateGroupName(groupName, groupPermalink, existingGroups, startDate, endDate) {
    if (
      existingGroups.some(
        (group) =>
          groupPermalink !== group.permalink &&
          group.name.trim().toLowerCase() === groupName.trim().toLowerCase() &&
          isOverlapping(group, {
            startDate,
            endDate,
          })
      )
    ) {
      return {
        code: 'duplicate.groupname',
        message: 'Een groep met dezelfde naam bestaat al binnen de school.',
      };
    }
    return null;
  }

  onSelectChairman(e, chairman) {
    this.chairman = chairman;
  }

  addEditGroup() {
    this.submitAttempted = true;
    if (!this.addEditGroupForm.valid) {
      return;
    }
    this.close({
      $value: {
        group: this.resolve.group,
        name: this.name,
        startDate: this.startDate,
        chairman: this.chairman
          ? {
              person: this.chairman,
              startDate: this.startDate,
              endDate: this.endDate,
              position: COORDINATOR,
            }
          : {},
      },
    });
  }
}
