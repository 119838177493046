import { printDate, isBefore, isAfter } from '@kathondvla/sri-client/date-utils';
import { BESTUURDER, LID_VAN_HET_DAGELIJKS_BESTUUR, SUPER_ADMIN } from 'ReduxLoop/constants';

import * as reduxActions from 'ReduxLoop/ouManager/team/teamActions';
import { addWarningNotification } from 'ReduxLoop/notifications/notificationActions';
import { OU_TYPE_CONFIG } from 'ReduxLoop/appConfig';
import {
  approveRequest,
  declineRequest,
} from 'ReduxLoop/requestResponsibility/requestResponsibilityActions';
import { settings } from 'Js/config/settings';
import { addMemberOu, removeMemberOu } from 'ReduxLoop/ouManager/workgroups/workgroupActions';

export class TeamTab {
  constructor($scope, $uibModal, $ngRedux, $state, $stateParams) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModal = $uibModal;
    this.guestEmails = [];
    this.peopleIcon = require('Img/people.svg');
    this.uploadIcon = require('Img/upload.svg');
    this.editIcon = require('Img/edit.svg');
    this.deleteIcon = require('Img/delete.svg');
    this.removeIcon = require('Img/cross.svg');
    this.approveIcon = require('Img/check.svg');
    this.$state = $state;
    this.$stateParams = $stateParams;

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ou: state.vm.schoolManager.currentOU,
      subTitle: state.vm.schoolManager.currentOU.type
        ? OU_TYPE_CONFIG[state.vm.schoolManager.currentOU.ouTypeConfigKey].teamSubTitle
        : '',
      teamInfoMessage: state.vm.schoolManager.currentOU.type
        ? OU_TYPE_CONFIG[state.vm.schoolManager.currentOU.ouTypeConfigKey].teamInfoMessage
        : null,
      loadingTeam: state.teamTab.loadingTeam,
      userRole: state.vm.schoolManager.currentOU.userRole,
      filterValue: state.vm.teamTab.filterValue,
      members: state.vm.schoolManager.team,
      filteredMembers: state.vm.teamTab.members,
      possiblePositions: state.vm.teamTab.possiblePositions,
      defaultPosition: state.vm.teamTab.defaultPosition,
      delegations: state.vm.teamTab.delegations,
      showCreateInvitationLink: state.vm.teamTab.showCreateInvitationLink,
    }))(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    if (
      this.ou &&
      this.ou.key === this.$stateParams.schoolId &&
      this.$stateParams.filter !== this.filterValue
    ) {
      this.$state.go('.', { filter: this.filterValue });
    }
  }

  teamFilterChanged(ev, value) {
    this.$ngRedux.dispatch(reduxActions.filterValueChanged(value));
    this.$state.go('.', { filter: value });
  }

  approveRequest(ev, member) {
    const question = member.mainPosition.name
      ? `Klopt het dat ${member.familiarName} ${member.mainPosition.name} is in dit team?`
      : `Klopt het dat ${member.familiarName} deel uitmaakt van dit team?`;
    const confirmApproveInvitation = this.createConfirmationModal(
      'Aanvraag goedkeuren',
      question,
      this.approveIcon
    );

    confirmApproveInvitation.result.then(() => {
      this.$ngRedux.dispatch(approveRequest(member.requestHref));
    });
  }

  discardRequest(ev, member) {
    const question = member.mainPosition.name
      ? `Ben je zeker dat je de aanvraag van ${member.familiarName} als ${member.mainPosition.name} wilt afwijzen?`
      : `Ben je zeker dat je de aanvraag van ${member.familiarName} om toe te treden tot het team wilt afwijzen?`;
    const confirmDiscardInvitation = this.createConfirmationModal(
      'Aanvraag afwijzen',
      question,
      this.removeIcon
    );

    confirmDiscardInvitation.result.then(() => {
      this.$ngRedux.dispatch(declineRequest(member.requestHref));
    });
  }

  removeMember(ev, member) {
    if (member.responsibilities.length > 1) {
      const chooseRespModal = this.createChooseRespModal(
        member,
        true,
        'beëindigen',
        this.removeIcon
      );

      chooseRespModal.result.then((result) => {
        this.removeResponsibilities(member, result.selectedResponsibilities);
      });
    } else {
      this.removeResponsibilities(member, [member.responsibilities[0]]);
    }
  }

  removeResponsibilities(member, responsibilities) {
    const removeMemberModal = this.createRemoveMemberModal(member, responsibilities);

    // this logic should have been in the reducer
    removeMemberModal.result.then((result) => {
      if (responsibilities.length === member.responsibilities.length) {
        this.$ngRedux.dispatch(
          reduxActions.endTeamMembership(member, result.endDate, member.endDate)
        );
      } else {
        const latestResp = member.responsibilities.reduce(
          (acc, cur) =>
            !responsibilities.some((resp) => resp.responsibilityHref === cur.responsibilityHref) &&
            (!acc || isAfter(cur.endDate, acc.endDate))
              ? cur
              : acc,
          null
        );
        if (isBefore(latestResp.endDate, result.endDate)) {
          this.$ngRedux.dispatch(
            reduxActions.endTeamMembership(member, result.endDate, member.endDate)
          );
        } else {
          responsibilities.forEach((resp) => {
            this.$ngRedux.dispatch(
              reduxActions.endResponsibility(member, resp.responsibilityHref, result.endDate)
            );
          });
          if (latestResp.endDate) {
            this.$ngRedux.dispatch(
              reduxActions.endTeamMembership(member, latestResp.endDate, member.endDate)
            );
          }
        }
      }
    });
  }

  createChooseRespModal(member, multiple, actionTitle, icon) {
    return this.$uibModal.open({
      animation: true,
      component: 'myChooseRespModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        memberName: () => member.familiarName,
        multiple: () => multiple,
        actionTitle: () => actionTitle,
        icon: () => icon,
        responsibilities: () =>
          this.userRole === SUPER_ADMIN
            ? member.responsibilities
            : member.responsibilities.filter(
                (resp) =>
                  resp.permalink !== BESTUURDER && resp.permalink !== LID_VAN_HET_DAGELIJKS_BESTUUR
              ),
      },
    });
  }

  createRemoveMemberModal(member, responsibilities) {
    let subtitle = null;
    let endDateHelpText = null;
    let positionsString = null;
    let positionStringWithStartDates = null;
    if (member.responsibilities.length === responsibilities.length) {
      subtitle = `${member.familiarName} uit het team verwijderen.`;
      endDateHelpText = `Wanneer verlaat ${member.familiarName} het team?`;
    } else {
      positionsString = responsibilities
        .map((position) => position.name.toLowerCase())
        .join(',')
        .replace(/,\s[^,]+$/, ' en');
      subtitle = `Functie als ${positionsString} beëindigen`;
      endDateHelpText = `Wanneer stopt ${member.familiarName} als ${positionsString}?`;
    }
    positionStringWithStartDates = responsibilities
      .map((position) => `${position.name.toLowerCase()} sinds ${printDate(position.startDate)}`)
      .join(',')
      .replace(/,\s[^,]+$/, ' en');
    return this.$uibModal.open({
      animation: true,
      component: 'myDeleteModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        startDate: () => member.startDate,
        endDate: () => member.endDate,
        subtitle: () => subtitle,
        endDateHelpText: () => endDateHelpText,
        startDateInfo: () => `${member.familiarName} is ${positionStringWithStartDates}`,
      },
    });
  }

  sendReminder(ev, member) {
    this.$ngRedux.dispatch(reduxActions.sendReminder(member));
  }

  discardInvitation(ev, member) {
    const confirmDiscardInvitation = this.createConfirmationModal(
      'Verwijder uitnodiging',
      `Ben je zeker dat je de uitnoding voor ${member.emailAddress} als ${member.mainPosition.name} terug wilt intrekken?`,
      this.deleteIcon
    );

    confirmDiscardInvitation.result.then(
      (result) => {
        this.$ngRedux.dispatch(reduxActions.cancelTeamInvitation(member));
      },
      (err) => {}
    );
  }

  createConfirmationModal(title, question, icon) {
    return this.$uibModal.open({
      animation: true,
      component: 'myConfirmModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        confirmationQuestion: () => question,
        icon: () => icon,
        title: () => title,
      },
    });
  }

  sendInvitations(e, emailAddresses) {
    if (emailAddresses.length === 0) {
      this.$ngRedux.dispatch(
        addWarningNotification('Er zijn geen geldige e-mailadressen geselecteerd.')
      );
      return;
    }
    const invitationModal = this.createInvitationModal(emailAddresses);

    invitationModal.result.then(
      (result) => {
        const invitees = [];
        result.invitees.forEach((invitee) => {
          const duplicates = this.members.filter((member) => {
            return (
              member.emailAddress &&
              member.emailAddress.toLowerCase() === invitee.toLowerCase() &&
              ((member.type === 'RESPONSIBILITY' &&
                member.responsibilities.some(
                  (position) => position.permalink === result.position.$$meta.permalink
                )) ||
                (member.type === 'INVITATION' &&
                  member.mainPosition.permalink === result.position.$$meta.permalink)) &&
              (!member.errors || member.errors.length === 0)
            );
          });
          if (duplicates.length > 0) {
            console.warn('[SEND_INVITE] Duplicate invitee, we will ignore this one: ', invitee);
            /* if (duplicates.length === 1 && duplicates[0].type === 'INVITATION') {
            this.$ngRedux.dispatch(reduxActions.sendReminder(duplicates[0]));
          } */
          } else if (invitees.some((i) => i === invitee)) {
            console.warn(
              '[SEND_INVITE] ',
              invitee,
              'appears two times in the list, we will only send one invitation for him'
            );
          } else {
            invitees.push(invitee);
          }
        });

        if (invitees.length > 0) {
          this.$ngRedux.dispatch(
            reduxActions.sendTeamInvitation({
              invitees,
              startDate: result.startDate,
              position: result.position.$$meta.permalink,
            })
          );
        } else {
          this.$ngRedux.dispatch(
            addWarningNotification(
              `${
                emailAddresses.length === 1 ? 'De persoon' : 'De personen'
              } die je wilt uitnodigen ${
                emailAddresses.length === 1 ? 'is' : 'zijn'
              } al uitgenodigd als ${
                result.position.name
              }. Kies 'Stuur herinnering' om een herinneringsmail te sturen.`
            )
          );
        }

        this.guestEmails = [];
      },
      (error) => {}
    );
  }

  createInvitationModal(emailAddresses) {
    return this.$uibModal.open({
      animation: true,
      component: 'myInvitationModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        sGuestEmails: () => emailAddresses.map((guest) => guest.text),
        possiblePositions: () => this.possiblePositions,
        defaultPosition: () => this.defaultPosition,
        school: () => this.ou,
      },
    });
  }

  editPosition(event, member) {
    if (member.positions.length > 1) {
      const chooseResp = this.createChooseRespModal(member, false, 'wijzigen', this.editIcon);
      chooseResp.result.then((result) => {
        console.log('user picked', result);
        this.editPositionForResp(member, result.selectedResponsibilities[0]);
      });
    } else {
      this.editPositionForResp(member, member.positions[0]);
    }
  }

  editPositionForResp(member, position) {
    const editPositionModal = this.createEditPositionModal(member, position);
    editPositionModal.result.then((result) => {
      if (member.positions.some((pos) => pos.permalink === result.position.$$meta.permalink)) {
        this.$ngRedux.dispatch(
          addWarningNotification(`${member.familiarName} is al ${result.position.name}`)
        );
      } else {
        this.$ngRedux.dispatch(
          reduxActions.changeRole(
            position.responsibilityHref,
            result.position.$$meta.permalink,
            member
          )
        );
      }
    });
  }

  createEditPositionModal(member, position) {
    return this.$uibModal.open({
      animation: true,
      component: 'myChangePositionModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        possiblePositions: () => this.possiblePositions,
        responsibility: () => ({ startDate: position.startDate, positionHref: position.permalink }),
        person: () => ({
          name: member.familiarName,
          gender: member.gender,
          possesivePronoun: member.gender === 'FEMALE' ? 'haar' : 'zijn',
        }),
        school: () => this.ou,
      },
    });
  }

  delegeeSelected(event, delegee) {
    this.$ngRedux.dispatch(
      reduxActions.delegateRole(
        delegee.member,
        delegee.delegation.position,
        delegee.delegation.roleDescription
      )
    );
  }

  delegeeRemoved(event, delegee) {
    this.$ngRedux.dispatch(reduxActions.revokeRole(delegee.member, delegee.delegation.position));
  }

  // eslint-disable-next-line class-methods-use-this
  goToPersonsApp(event, member) {
    window.open(`${settings.applications.personen}/#/profiel/${member.key}`);
  }

  openCreateLinkModal() {
    this.$uibModal.open({
      animation: true,
      component: 'myCreateLinkModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        ou: () => this.ou,
        possiblePositions: () => this.possiblePositions,
        defaultPosition: () => this.defaultPosition,
        managersDescription: () => OU_TYPE_CONFIG[this.ou.ouTypeConfigKey].managersDescription,
      },
    });
  }

  onMemberOuAdded(event, memberOu) {
    if (this.ou.teamMemberOus.some((mou) => mou.permalink === memberOu.$$meta.permalink)) {
      this.$ngRedux.dispatch(
        addWarningNotification(
          `${memberOu.name} is al een groep die meerwerkt in je Microsoft Teams-omgeving`
        )
      );
    } else {
      this.$ngRedux.dispatch(addMemberOu(memberOu));
    }
  }

  onMemberOuRemoved(event, memberOu) {
    this.$ngRedux.dispatch(removeMemberOu(memberOu));
  }
}
