const mock = require('./class.data');

const service = () => {
  'ngInject';

  const getClasses = () => {
    return mock.classes;
  };

  return {
    getClasses,
  };
};

angular.module('myServices').factory('classService', service);
