// require('./myProfilePictureEditor.scss');

import { MyChangePassword } from './changePassword';

export const myChangePasswordComponent = {
  template: require('./changePassword.html'),
  controllerAs: 'ctrl',
  controller: MyChangePassword,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
