import React from 'react';
import Button from '../../../../myComponents/myReactButton/Button';

const CurriculumSelectorButton = ({ label, icon, onClick }) => {
  return (
    <div className="kov-form-group">
      <span className="input-group curriculumSelectorInputGroup">
        <span className="kov-btn--block">
          <Button icon={icon} onClick={onClick} className={'curriculumSelectorButton'}>
            {label}
          </Button>
        </span>
      </span>
    </div>
  );
};

export default CurriculumSelectorButton;
