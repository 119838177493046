const MyInlineEditor = require('./myInlineEditor');

module.exports = {
  template: require('./myInlineEditor.html'),
  controllerAs: 'ctrl',
  controller: MyInlineEditor,
  bindings: {
    sEditableText: '<',
    sId: '<',
  },
};
