import { settings } from 'Js/config/settings';
import { analyticCookiesAccepted } from 'ReduxLoop/utils/cookiesUtils';

export const sendPiwikEvent = (eventName, rootState, props = {}) => {
  if (!(settings.piwikAnalytics.enabled && analyticCookiesAccepted())) return;

  const { key, userLoginMethod, userTargetGroup } = rootState.userState?.user || {};

  // MIJN uses a hash-based routing, so we build the full pathname from hash.
  const hash = document.location.hash.split('?')[0];
  // As document.title in MIJN is always the same, we add a value to page_title from URL parameters.
  const title = hash === '#!/' ? 'home' : hash.split('/')[1].replace(/[-]/g, ' ').toLowerCase();

  const commonProperties = {
    ...rootState.piwikAnalyticsState.commonProperties,
    user_id: key || undefined,
    user_target_group: userTargetGroup?.name?.toLowerCase() || undefined,
    page_hostname: document.location.hostname || undefined,
    page_pathname: `${document.location.pathname}${hash}`.toLowerCase() || undefined,
    page_referrer: document.referrer.toLowerCase() || undefined,
    user_authstatus: key ? 'authenticated' : 'anonymous',
    user_region: undefined,
    user_edu_level: undefined,
    page_title: `${document.title} ${title}`.toLowerCase().trim() || undefined,
    user_login_method: userLoginMethod?.toLowerCase() || undefined,
  };

  const eventProps = {
    event: eventName,
    ...commonProperties,
    ...props,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventProps);

  console.log(
    'piwik-event-dispatch',
    (['test', 'acc', 'local'].includes(settings.environment) && eventProps) || ''
  );
};
