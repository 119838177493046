import { LEERPLANCOMMISSIE, VERGADERGROEP } from 'ReduxLoop/constants';

const stepsConfig = {
  1: {
    subtitle: 'Bevestig dat je een nieuw team nodig hebt',
    continueText: 'Ja, ik wil een nieuw team creëren',
  },
  2: {
    subtitle: 'Vul alle velden in',
    continueText: 'Ga verder',
  },
  3: {
    subtitle: 'Voltooi de registratie',
    continueText: 'Verstuur en ga verder',
  },
  4: {
    subtitle: '',
    continueText: 'Beheer je team',
  },
};

const getDuplicateSuggestion = (state) => {
  if (!state.createTeamWizard.duplicateSuggestion) {
    return null;
  }
  return {
    permalink: state.createTeamWizard.duplicateSuggestion.$$meta.permalink,
    key: state.createTeamWizard.duplicateSuggestion.key,
    name: state.createTeamWizard.duplicateSuggestion.details[0].name,
    shortName: state.createTeamWizard.duplicateSuggestion.details[0].shortName,
    description: state.createTeamWizard.duplicateSuggestion.description,
    type: state.createTeamWizard.duplicateSuggestion.type,
    isJoinable: [VERGADERGROEP, LEERPLANCOMMISSIE].some(
      (type) => type === state.createTeamWizard.duplicateSuggestion.type
    ),
    presidents: state.createTeamWizard.duplicateVoorziterResponsibilities.map((resp) => ({
      permalink: resp.person.href,
      fullName: `${resp.person.$$expanded.firstName} ${resp.person.$$expanded.lastName}`,
      responsibilityHref: resp.$$meta.permalink,
    })),
  };
};

export const getCreateTeamWizardModel = (state) => {
  return {
    currentStep: state.createTeamWizard.currentStep,
    currentStepConfig: stepsConfig[state.createTeamWizard.currentStep],
    possibleTypes: state.createTeamWizard.possibleTypes,
    shortNamePattern: state.createTeamWizard.shortNamePattern,
    code: state.createTeamWizard.code,
    submitAttempted: state.createTeamWizard.submitAttempted,
    persistingNewTeam: state.createTeamWizard.persistingNewTeam,
    form: {
      name: state.createTeamWizard.name,
      shortName: state.createTeamWizard.shortName,
      description: state.createTeamWizard.description,
      type: state.createTeamWizard.selectedType,
      startDate: state.createTeamWizard.startDate,
    },
    formErrors: {
      descriptionErrors: state.createTeamWizard.descriptionErrors,
      nameErrors: state.createTeamWizard.nameErrors,
      shortNameErrors: state.createTeamWizard.shortNameErrors,
      nameWarnings: state.createTeamWizard.nameWarnings,
    },
    duplicateSuggestion: getDuplicateSuggestion(state),
    duplicateErrorType:
      (state.createTeamWizard.nameErrors && state.createTeamWizard.nameErrors.length > 0) ||
      (state.createTeamWizard.shortNameErrors && state.createTeamWizard.shortNameErrors.length > 0)
        ? 'ERROR'
        : 'WARNING',
    notifyDepartmentFailed: state.createTeamWizard.notifyDepartmentFailed,
  };
};
