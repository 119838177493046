const MyNewsletterSubjectList = require('./myNewsletterSubjectList');

module.exports = {
  template: require('./myNewsletterSubjectList.html'),
  controllerAs: 'ctrl',
  controller: MyNewsletterSubjectList,
  transclude: true,
  bindings: {
    sSubjects: '<',
    sShowAll: '<',
    sCollapsible: '<',
  },
};
