import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { storeResources, removeResources } from '../../utils';

export const storeGroupInfo = (resources) => {
  return storeResources('/sam/organisationalunits/batch', resources);
};

export const deleteGroupInfo = (resources) => {
  return removeResources('/sam/organisationalunits/batch', resources);
};

const getGroupMembers = async (groups, limitGroups) => {
  if (
    (limitGroups && limitGroups.filter((group) => !group.noAccessYet).length === 0) ||
    groups.length === 0
  ) {
    return [];
  }

  const ouHrefs = limitGroups
    ? limitGroups.filter((group) => !group.noAccessYet).map((group) => group.href)
    : groups.map((group) => group.$$meta.permalink);
  const responsibilities = await sriClient.getAll(
    '/responsibilities',
    {
      organisations: ouHrefs.join(','),
      expandPosition: 'NONE',
    },
    {
      inBatch: '/persons/batch',
    }
  );

  return responsibilities;
};

const getGroups = async (ou, limitGroups) => {
  let ouGroups = [];

  if (limitGroups) {
    ouGroups = await sriClient.getAll(
      '/sam/organisationalunits/relations',
      {
        from: limitGroups.map((group) => group.href).join(','),
        // 'from.type':'TEACHER_GROUP',
        expand: 'results.from',
      },
      {
        inBatch: '/sam/organisationalunits/batch',
      }
    );
  } else {
    ouGroups = await sriClient.getAll('/sam/organisationalunits/relations', {
      to: ou.$$meta.permalink,
      'from.type': 'TEACHER_GROUP',
      expand: 'results.from',
    });
  }

  const groups = ouGroups.map((ouGroup) => ouGroup.from.$$expanded);

  const responsibilities = await getGroupMembers(groups, limitGroups);

  /* for (const group of groups) {
    const groupMembers = await getGroupMembers(group);
    groupMembers.forEach(groupMember => responsibilities.push(groupMember));
  } */

  ouGroups.forEach((ouGroup) => {
    delete ouGroup.from.$$expanded;
  });

  return {
    groups,
    ouGroups,
    responsibilities,
  };
};

export async function fetchGroupsInfo(ou, limitGroups) {
  let groupsInfo = {
    groups: [],
    ouGroups: [],
    responsibilities: [],
  };

  if (ou) {
    groupsInfo = await getGroups(ou, limitGroups);
  }

  return Promise.resolve({
    groupsInfo,
  });
}
