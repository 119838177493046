import { getNow } from '@kathondvla/sri-client/date-utils';
import { GOVERNINGINSTITUTION } from 'ReduxLoop/constants';
import { settings } from 'Js/config/settings';
import { currentOuFetched } from 'ReduxLoop/ouManager/schoolManagerActions';
import { sriClient } from '../utils/apiConfig';

/* const getCurrentPerson = async () => {
  const me = await oauthClient.get('/me', undefined, {credentials: 'include'});
  return sriClient.get('/persons/' + me.uuid);
}; */

/* const getTeam = async (ouPermalink) => {
  const responsibilities = await sriClient.getAll('/responsibilities', {
    organisations: ouPermalink,
    expandPosition: 'NONE'});
  const nonAbolishedResps = getNonAbolishedResources(responsibilities);
  const pendingResponsibilities = await sriClient.getAll('/responsibilities/pending', {organisations: ouPermalink, status: 'PENDING'});
  const personHrefs = [...nonAbolishedResps.map(resp => resp.person.href), ...pendingResponsibilities.filter(resp => resp.type === 'REQUEST').map(resp => resp.from.href)];

  let persons = [];

  if (personHrefs.length > 0) {
    persons = await sriClient.getAllHrefs(personHrefs, {expand: 'SUMMARY'});
  }

  //const persons = await sriClient.getAllHrefs(personHrefs, {expand: 'SUMMARY'});
  //const emails = sriClient.getAllReferencesTo('/contactdetails', {types: 'EMAILADDRESS_PRIMARY'}, 'person', personHrefs);
  return {
    responsibilities: responsibilities,
    invitations: pendingResponsibilities.filter(resp => resp.type === 'INVITATION'),
    requests: pendingResponsibilities.filter(resp => resp.type === 'REQUEST'),
    persons: persons
  };
}; */

export const getOrganisationalUnit = (key) => {
  const ouPermalink = `/sam/organisationalunits/${key}`;
  return sriClient.get(ouPermalink);
};

/* export const getContactDetails = (ou) => {
  return sriClient.getAll('/sam/organisationalunits/contactdetails', { organisationalUnit: ou.$$meta.permalink });
}; */

export const getGoverningInstitutionOfOu = async (ou) => {
  const giRels = await sriClient.getAll('/sam/organisationalunits/relations', {
    to: ou.$$meta.permalink,
    type: 'GOVERNS',
    endDateAfter: getNow(),
    expand: 'results.from',
  });
  if (giRels.lenght === 0) {
    return {};
  }
  const governingInstitution = giRels[0].from.$$expanded;
  const mailContactDetails = await sriClient.getAll('/sam/organisationalunits/contactdetails', {
    organisationalUnit: governingInstitution.$$meta.permalink,
    type: 'MAIL',
  });
  return { governingInstitution, mailContactDetail: mailContactDetails[0] };
};

export const redirectToMijn1Team = async (ou, dispatch) => {
  if (ou.type === 'SCHOOL') {
    const vosSchool = await sriClient.get(`/schools/${ou.key}`);
    if (vosSchool.educationLevel === 'SECUNDAIR') {
      const canActAsRelations = await sriClient.getAll('/sam/organisationalunits/relations', {
        endDateAfter: getNow(),
        type: 'CAN_ACT_AS',
        to: ou.$$meta.permalink,
      });
      if (canActAsRelations.length === 1) {
        const se = await sriClient.get(canActAsRelations[0].from.href);
        console.log('Hallo Hallo Hallo! Er is maar 1 SE');
        dispatch(currentOuFetched(se));
      } else {
        console.log('Hallo Hallo Hallo! We gaan redirecten naar School Selecteer');
        window.location.href = '/#!/school/selecteer';
      }
    } else {
      console.log('Hallo Hallo Hallo! We gaan redirecten naar Mijn 1');
      window.location.href = `${settings.applications.mijn1}/#/team/${vosSchool.institutionNumber}`;
    }
  } else {
    const instNumber = (
      await sriClient.getAll('/sam/organisationalunits/externalidentifiers', {
        organisationalUnit: ou.$$meta.permalink,
        type: 'INSTITUTION_NUMBER',
      })
    )[0];
    console.log('Hallo Hallo Hallo! We gaan redirecten naar Mijn 1');
    window.location.href = `${settings.applications.mijn1}/#/team/${instNumber.value}`;
  }
};

export const getVosEquivalent = async (ou) => {
  const externalIdentifiers = await sriClient.getAll(
    '/sam/organisationalunits/externalidentifiers',
    {
      typeIn: 'INSTITUTION_NUMBER,VOS_PERMALINK',
      organisationalUnit: ou.$$meta.permalink,
    }
  );
  const vosPermalink = externalIdentifiers.filter((eid) => eid.type === 'VOS_PERMALINK')[0];
  if (vosPermalink) {
    return vosPermalink.value;
  }
  const institutionNumber = externalIdentifiers.filter(
    (eid) => eid.type === 'INSTITUTION_NUMBER'
  )[0];
  if (institutionNumber) {
    let vosOu = null;
    if (ou.type === GOVERNINGINSTITUTION) {
      vosOu = await sriClient.get(`/governinginstitutions/${institutionNumber.value}`);
    } else if (ou.type === 'CLB') {
      vosOu = await sriClient.get(`/clbs/${institutionNumber.value}`);
    }
    if (vosOu) {
      return vosOu.$$meta.permalink;
    }
  }
  return null;
};

/* export async function fetchSecondarySchoolEntityInformation(key) {
  let positionsForOu = [],
    teamInfo = {
      responsibilities: [],
      invitations: [],
      requests: [],
      persons: []
    };

  const ouPermalink = '/sam/organisationalunits/' + key;

  //const user = await getCurrentPerson();
  const ou = await sriClient.get('/sam/organisationalunits/' + key);
  //const positions = await sriClient.getAll('/positions', {expand: 'FULL'}); //This should be moved to a higher level

  if (ou) {
    positionsForOu = await sriClient.getAll('/positions', {organisation: ouPermalink, expand: 'NONE', });
    teamInfo = await getTeam(ouPermalink);
  }

  return Promise.resolve({
    currentOU: ou,

    //positions: positions,
    positionsForOu: positionsForOu,
    team: teamInfo
  });
} */
