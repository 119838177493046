import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { settings } from 'Js/config/settings';

const MyCookiesModal = ({ completeCookies }) => {
  const modalRef = useRef(null);
  const handleCompleted = (ev) => completeCookies(ev.detail);

  useEffect(() => {
    const div = modalRef.current;
    div.addEventListener('onSubmitCookies', handleCompleted);
    return () => {
      div.removeEventListener('onSubmitCookies', handleCompleted);
    };
  }, []);

  return (
    <kov-cookie-modal
      ref={modalRef}
      id="CookiesModal"
      essential-id={settings.cookies.essentialId}
      analytic-id={settings.cookies.analyticId}
      content-href={settings.cookies.contentHref}
    ></kov-cookie-modal>
  );
};

MyCookiesModal.propTypes = {
  isShown: PropTypes.bool,
  completeCookies: PropTypes.func,
  cookiesTheme: PropTypes.object,
  cookiesSource: PropTypes.string,
};
export default MyCookiesModal;
