export const INITIALISE_SCHOOLMANAGER = 'initialise_schoolmanager';
export const SELECT_ORGANISATIONAL_UNIT = 'select_organisational_unit';
export const CURRENT_OU_FETCHED = 'current_ou_fetched';
// export const CURRENT_OU_CONTACTDETAILS_FETCHED = 'current_ou_contactdetails_fetched';
export const CURRENT_OU_GOVERNING_INSTITUTION_FETCHED = 'CURRENT_OU_GOVERNING_INSTITUTION_FETCHED';
export const SELECT_TAB = 'select_tab';

export const initialiseSchoolManager = (key) => ({
  type: INITIALISE_SCHOOLMANAGER,
  payload: key,
});

export const selectOrganisationalUnit = (key) => ({
  type: SELECT_ORGANISATIONAL_UNIT,
  payload: key,
});

export const currentOuFetched = (ou) => ({
  type: CURRENT_OU_FETCHED,
  payload: ou,
});

export const currentOuGoverningInstitutionFetched = ({
  governingInstitution,
  mailContactDetail,
}) => ({
  type: CURRENT_OU_GOVERNING_INSTITUTION_FETCHED,
  payload: {
    governingInstitution,
    mailContactDetail,
  },
});
// export const currentOuContactDetailsFetched = (contactDetails) => ({
//   type: CURRENT_OU_CONTACTDETAILS_FETCHED,
//   payload: contactDetails
// });

export const selectTab = (tabName) => ({
  type: SELECT_TAB,
  payload: tabName,
});

export const VOS_EQUIVALENT_FETCHED = 'vos_equivalent_fetched';
export const vosEquivalentFetched = (vosPermalink) => ({
  type: VOS_EQUIVALENT_FETCHED,
  payload: vosPermalink,
});

export const CHANGE_DETAILS_NAME = 'change_details_name';
export const changeDetailsName = (name) => ({
  type: CHANGE_DETAILS_NAME,
  payload: name,
});

export const CHANGE_DETAILS_SHORT_NAME = 'change_details_short_name';
export const changeDetailsShortName = (shortName) => ({
  type: CHANGE_DETAILS_SHORT_NAME,
  payload: shortName,
});

export const CHANGE_DETAILS_DESCRIPTION = 'change_details_description';
export const changeDetailsDescription = (description) => ({
  type: CHANGE_DETAILS_DESCRIPTION,
  payload: description,
});

export const CHANGE_DETAILS_TYPE = 'change_details_type';
export const changeDetailsType = (type) => ({
  type: CHANGE_DETAILS_TYPE,
  payload: type,
});

export const SET_DETAILS_FORM_VALIDATION = 'set_details_form_validation';
export const setDetailsFormValidation = (isFormValid) => ({
  type: SET_DETAILS_FORM_VALIDATION,
  payload: isFormValid,
});

export const SAVE_ORGANISATION_DETAILS_FAILED = 'save_organisation_details_failed';
export const saveOrganisationDetailsFailed = (error) => ({
  type: SAVE_ORGANISATION_DETAILS_FAILED,
  payload: error,
});

export const SAVE_ORGANISATION_DETAILS_SUCCESS = 'save_organisation_details_success';
export const saveOrganisationDetailsSuccess = (ou) => ({
  type: SAVE_ORGANISATION_DETAILS_SUCCESS,
  payload: ou,
});
