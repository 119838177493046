import * as CONSTANTS from 'ReduxLoop/constants';

const delegations = {
  teamAdmin: {
    position: CONSTANTS.VSKO_VERANTWOORDELIJKE,
    title: 'Naar wie delegeer je administratieve rechten?',
    roleDescription: 'administratieve rechten',
    ownedByManagers: true,
  },
  pedagogicalAdmin: {
    position: CONSTANTS.PEDAGOGISCH_VERANTWOORDELIJKE,
    title: 'Naar wie delegeer je pedagogische rechten?',
    roleDescription: 'pedagogische rechten',
    subTitle:
      'Deze personen kunnen de (vak)groepen beheren en kunnen in LLinkid de leidraad invullen, de gemeenschappelijke doelen distribueren en krijgen een overzicht over <strong>alle</strong> leerplannen en vorderingsplannen.',
    ownedByManagers: true,
  },
  trainingAdmin: {
    position: CONSTANTS.NASCHOLINGSVERANTWOORDELIJKE,
    title: 'Wie mag er (bijkomend) nascholingen beheren?',
    roleDescription: 'rechten voor de nascholingstoepassing',
    ownedByManagers: true,
    showFunction: (currentUserRole) =>
      currentUserRole.role === 'SUPER_ADMIN' ||
      currentUserRole.role === 'MANAGER' ||
      currentUserRole.role === 'DELEGATED_ADMIN',
  },
  accessToSensitiveInformation: {
    position: CONSTANTS.RAADPLEGER_GEVOELIGE_INFORMATIE,
    title: 'Wie krijgt bijkomend toegang tot gevoelige informatie op de website?',
    roleDescription: 'toegang tot gevoelige informatie op de website',
    ownedByManagers: true,
    removeSelf: true,
    showFunction: (currentUserRole) =>
      currentUserRole.role === 'SUPER_ADMIN' ||
      (currentUserRole.management &&
        currentUserRole.management.canGrantAccessToSensitiveInformation),
  },
  groupAdmins: {
    position: CONSTANTS.BEHEERDER,
    title: 'Wie mag er (bijkomend) de groep beheren?',
  },
};

export const OU_TYPE_CONFIG = {
  SCHOOLENTITY: {
    tabs: [
      CONSTANTS.TEAM_TAB,
      CONSTANTS.VAKGROEPEN_TAB,
      CONSTANTS.STUDYPROGRAMMES_TAB,
      CONSTANTS.CLASSES_TAB,
      CONSTANTS.CONTACTDETAILS_TAB,
    ],
    managers: [CONSTANTS.DIRECTEUR],
    admins: [CONSTANTS.VSKO_VERANTWOORDELIJKE],
    strategicPurchasers: [CONSTANTS.STRATEGISCH_INKOPER],
    pedagogicalAdmins: [CONSTANTS.PEDAGOGISCH_VERANTWOORDELIJKE],
    delegations: [
      {
        ...delegations.teamAdmin,
        subTitle:
          'Deze personen kunnen het schoolteam, de (vak)groepen, de studierichtingen en de klassen beheren.',
      },
      delegations.pedagogicalAdmin,
      delegations.accessToSensitiveInformation,
      {
        ...delegations.trainingAdmin,
        subTitle:
          'Deze personen kunnen voor jouw school in de nascholingstoepassing de inschrijving voor een opleiding beheren en het facturatieadres wijzigen.',
      },
    ],
    teamSubTitle: 'Alle personeelsleden',
    emptyPosition: '(geen van bovenstaanden)',
    managersDescription: 'de directeur van de school',
  },
  GOVERNINGINSTITUTION: {
    tabs: [CONSTANTS.TEAM_TAB],
    managers: [CONSTANTS.BESTUURDER, CONSTANTS.LID_VAN_HET_DAGELIJKS_BESTUUR],
    admins: [CONSTANTS.VSKO_VERANTWOORDELIJKE],
    strategicPurchasers: [CONSTANTS.STRATEGISCH_INKOPER],
    delegations: [
      {
        position: CONSTANTS.CONTACTPERSOON,
        title: 'Wie is contactpersoon voor jouw bestuur?',
        subTitle:
          'Katholiek Onderwijs Vlaanderen communiceert via deze contactpersonen met het bestuur. Minstens één bestuurder moet contactpersoon zijn.',
        showFunction: () => true,
      },
      { ...delegations.teamAdmin, subTitle: 'Deze personen kunnen het team beheren.' },
      delegations.accessToSensitiveInformation,
      {
        ...delegations.trainingAdmin,
        subTitle:
          'Deze personen kunnen voor jouw bestuur in de nascholingstoepassing de inschrijving voor een opleiding beheren en het facturatieadres wijzigen.',
      },
    ],
    teamSubTitle:
      'Beheer je bestuursteam één keer voor alle toepassingen van Katholiek Onderwijs Vlaanderen',
    teamTabSubTitle: 'Alle personeelsleden',
    teamInfoMessage:
      '<span class="font-color-purple"><strong>Bestuurders</strong> en <strong>leden van het dagelijks bestuur</strong>\n' +
      ' worden door Katholiek Onderwijs Vlaanderen beheerd. Het zijn die personen die <strong>in het Belgisch staatsblad</strong> gepubliceerd zijn.</span></br>\n' +
      ' De <strong>andere</strong> rollen kunnen door de bestuurders hier beheerd worden. Heb je hulp nodig? Mail naar <a href="mailto:helpdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>',
    excludePositions: [CONSTANTS.BESTUURDER, CONSTANTS.LID_VAN_HET_DAGELIJKS_BESTUUR],
    emptyPosition: 'bestuursmedewerker',
    managersDescription: 'de bestuurders en leden van het dagelijks bestuur',
  },
  CLB: {
    tabs: [CONSTANTS.TEAM_TAB],
    managers: [CONSTANTS.DIRECTEUR],
    admins: [CONSTANTS.VSKO_VERANTWOORDELIJKE],
    strategicPurchasers: [CONSTANTS.STRATEGISCH_INKOPER],
    delegations: [
      { ...delegations.teamAdmin, subTitle: 'Deze personen kunnen het team van jouw CLB beheren.' },
      delegations.accessToSensitiveInformation,
      {
        ...delegations.trainingAdmin,
        subTitle:
          'Deze personen kunnen voor jouw CLB in de nascholingstoepassing de inschrijving voor een opleiding beheren en het facturatieadres wijzigen.',
      },
    ],
    teamSubTitle: 'Alle personeelsleden',
    teamInfoMessage:
      'Je kan hier de rollen die jouw teamleden opnemen beheren. Heb je hulp nodig? Mail naar <a href="mailto:hepdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>',
    emptyPosition: '(geen van bovenstaanden)',
    managersDescription: 'de directeur van het CLB',
  },
  LEERSTEUNCENTRUM: {
    tabs: [CONSTANTS.TEAM_TAB],
    managers: [CONSTANTS.DIRECTEUR],
    admins: [CONSTANTS.VSKO_VERANTWOORDELIJKE],
    strategicPurchasers: [CONSTANTS.STRATEGISCH_INKOPER],
    delegations: [
      {
        ...delegations.teamAdmin,
        subTitle: 'Deze personen kunnen het team van jouw leersteuncentrum beheren.',
      },
      delegations.accessToSensitiveInformation,
      {
        ...delegations.trainingAdmin,
        subTitle:
          'Deze personen kunnen voor jouw leersteuncentrum in de nascholingstoepassing de inschrijving voor een opleiding beheren en het facturatieadres wijzigen.',
      },
    ],
    teamSubTitle: 'Alle personeelsleden',
    teamInfoMessage:
      'Je kan hier de rollen die jouw teamleden opnemen beheren. Heb je hulp nodig? Mail naar <a href="mailto:hepdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>',
    emptyPosition: '(geen van bovenstaanden)',
    managersDescription: 'de directeur van het leersteuncentrum',
  },
  ANDERE: {
    tabs: [CONSTANTS.TEAM_TAB],
    managers: [CONSTANTS.BEHEERDER],
    types: ['ANDERE', 'BISDOM', 'DIENST'],
    strategicPurchasers: [CONSTANTS.STRATEGISCH_INKOPER],
    delegations: [
      {
        position: CONSTANTS.BEHEERDER,
        title: 'Wie is beheerder voor jouw organisatie?',
        subTitle:
          'Deze personen kunnen het team van jouw organisatie beheren en andere beheerders aanduiden.',
        showFunction: () => true,
      },
      {
        position: CONSTANTS.CONTACTPERSOON,
        title: 'Wie is contactpersoon voor jouw organisatie?',
        subTitle:
          'Katholiek Onderwijs Vlaanderen communiceert via deze contactpersonen met jouw organisatie.',
        showFunction: () => true,
      },
      {
        ...delegations.trainingAdmin,
        subTitle:
          'Deze personen kunnen voor jouw organisatie in de nascholingstoepassing de inschrijving voor een opleiding beheren en het facturatieadres wijzigen.',
      },
    ],
    teamSubtitle: 'Alle personeelsleden',
    teamInfoMessage:
      'Je kan hier de rollen die jouw teamleden opnemen beheren. Heb je hulp nodig? Mail naar <a href="mailto:hepdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>',
    emptyPosition: '(geen van bovenstaanden)',
    managersDescription: 'de beheerder van de organisatie',
  },
  LERARENNETWERK: {
    tabs: [CONSTANTS.TEAM_TAB],
    managers: [CONSTANTS.TREKKER, CONSTANTS.BEHEERDER],
    delegations: [
      {
        position: CONSTANTS.BEHEERDER,
        title: 'Wie is beheerder voor jouw lerarennetwerk?',
        subTitle:
          'Een beheerder kan de samenstelling van het lerarennetwerk beheren en kan communiceren en samenwerken op het digitale platform van het lerarennetwerk.',
        ownedByManagers: true,
      },
    ],
    teamSubTitle: 'Alle leden',
    managersDescription: 'de trekker van het lerarennetwerk',
  },
  TEAM: {
    tabs: [CONSTANTS.TEAM_TAB, CONSTANTS.OU_DETAILS_TAB, CONSTANTS.WORKGROUP_TOOLS_TAB],
    types: ['VERGADERGROEP', 'LEERPLANCOMMISSIE'],
    managers: [CONSTANTS.VOORZITTER, CONSTANTS.BEHEERDER],
    delegations: [
      {
        position: CONSTANTS.BEHEERDER,
        title: 'Wie mag bijkomend het team beheren?',
        subTitle: 'Een beheerder kan de samenstelling van het team beheren.',
        ownedByManagers: true,
      },
    ],
    positionEditable: true,
    teamSubTitle: 'Alle leden',
    managersDescription: 'de voorzitter van het team',
  },
  TEACHER_GROUP: {
    childOf: 'SCHOOLENTITY',
    managers: [CONSTANTS.COORDINATOR],
    admins: [CONSTANTS.BEHEERDER],
    possiblePositions: [
      {
        name: 'coördinator',
        permalink: CONSTANTS.COORDINATOR,
      },
      {
        name: 'lid',
        permalink: CONSTANTS.LID,
      },
    ],
    managersDescription: 'de voorzitter van de vakgroep',
  },
};

export const OLD_MIJN_OU_TYPE_CONFIG = {
  SCHOOL: {
    managingPositions: [
      CONSTANTS.DIRECTEUR,
      CONSTANTS.VSKO_VERANTWOORDELIJKE,
      CONSTANTS.STRATEGISCH_INKOPER,
    ],
  },
  BOARDING: {
    managingPositions: [
      CONSTANTS.DIRECTEUR,
      CONSTANTS.VSKO_VERANTWOORDELIJKE,
      CONSTANTS.STRATEGISCH_INKOPER,
    ],
  },
  SCHOOLCOMMUNITY: {
    managingPositions: [
      CONSTANTS.COORDINEREND_DIRECTEUR,
      CONSTANTS.DIRECTEUR_COORDINATIE,
      CONSTANTS.VSKO_VERANTWOORDELIJKE,
      CONSTANTS.STRATEGISCH_INKOPER,
    ],
  },
};

export const GROUP_POSITIONS = [
  {
    name: 'coördinator',
    permalink: CONSTANTS.COORDINATOR,
  },
  {
    name: 'lid',
    permalink: CONSTANTS.LID,
  },
];

export const POSSIBLE_MANAGEMENT_TABS = {
  TEAM_TAB: {
    state: 'school.team',
    title: 'Team',
    subTitle: 'Alle personeelsleden',
    icon: require('Img/people.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.STRATEGIC_PURCHASER,
        permission: CONSTANTS.READ_WRITE,
      },
    ],
  },
  VAKGROEPEN_TAB: {
    state: 'school.group',
    title: 'Groepen',
    subTitle: 'Vakgroep, ...',
    icon: require('Img/groups.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.GROUP_MANAGER,
        permission: CONSTANTS.READ,
      },
      {
        role: CONSTANTS.DELEGATED_PEDAGOGIC,
        permission: CONSTANTS.READ_WRITE,
      },
    ],
  },
  STUDYPROGRAMMES_TAB: {
    state: 'school.studyprogrammes',
    title: 'Studierichtingen',
    subTitle: '(en basisopties)',
    icon: require('Img/books-stack-of-three.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.GROUP_MANAGER,
        permission: CONSTANTS.READ,
      },
    ],
  },
  CLASSES_TAB: {
    state: 'school.klassen',
    title: 'Klassen',
    icon: require('Img/pupils.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.GROUP_MANAGER,
        permission: CONSTANTS.READ,
      },
    ],
  },
  OU_DETAILS_TAB: {
    state: 'school.oudetails',
    title: 'Details',
    icon: require('Img/meeting.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
    ],
  },
  WORKGROUP_TOOLS_TAB: {
    state: 'school.workgrouptools',
    title: 'Tools',
    icon: require('Img/collaboration.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
    ],
  },
  CONTACTDETAILS_TAB: {
    state: 'school.contactdetails',
    title: 'Contactgegevens',
    icon: require('Img/mail.svg'),
    permissions: [
      {
        role: CONSTANTS.SUPER_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.MANAGER,
        permission: CONSTANTS.READ_WRITE,
      },
      {
        role: CONSTANTS.DELEGATED_ADMIN,
        permission: CONSTANTS.READ_WRITE,
      },
    ],
  },
};

export const getCurrentUserManagement = function (currentOrganisationalUnit, userInfo) {
  let currentUserRole;
  let currentOuManagement;

  if (userInfo.manages && currentOrganisationalUnit) {
    const ouTypeConfigKey = OU_TYPE_CONFIG[currentOrganisationalUnit.type]
      ? currentOrganisationalUnit.type
      : Object.keys(OU_TYPE_CONFIG).filter(
          (configKey) =>
            OU_TYPE_CONFIG[configKey].types &&
            OU_TYPE_CONFIG[configKey].types.some((type) => type === currentOrganisationalUnit.type)
        )[0];
    const currentMgmts = userInfo.manages[ouTypeConfigKey].filter(
      (management) => management.ouKey === currentOrganisationalUnit.key
    );
    currentOuManagement = currentMgmts.length > 0 ? currentMgmts[0] : null;

    // TODO should be determined by security Api?
    if (currentOuManagement === undefined) {
      currentUserRole = null;
    } else if (currentOuManagement === null) {
      currentUserRole = userInfo.isSuperAdmin ? CONSTANTS.SUPER_ADMIN : null;
    } else {
      currentUserRole = currentOuManagement.role;
    }
  }

  return {
    role: currentUserRole,
    management: currentOuManagement,
  };
};

export const getCurrentUserRole = (currentOrganisationalUnit, userInfo) => {
  return getCurrentUserManagement(currentOrganisationalUnit, userInfo).role;
};

export const getPermittedTabs = (ouType, userRole) => {
  const ouTypeConfig =
    OU_TYPE_CONFIG[ouType] ||
    Object.values(OU_TYPE_CONFIG).filter(
      (config) => config.types && config.types.some((type) => type === ouType)
    )[0];
  const ouTabs = ouTypeConfig.tabs;
  const roleTabs = Object.keys(POSSIBLE_MANAGEMENT_TABS).filter((tabName) =>
    POSSIBLE_MANAGEMENT_TABS[tabName].permissions.some((permission) => permission.role === userRole)
  );
  const permittedTabs = ouTabs.filter((tabName) => roleTabs.includes(tabName));

  return Object.keys(POSSIBLE_MANAGEMENT_TABS)
    .filter((tabName) => permittedTabs.includes(tabName))
    .map((tabName) => ({
      ...POSSIBLE_MANAGEMENT_TABS[tabName],
      subTitle:
        tabName === CONSTANTS.TEAM_TAB && ouTypeConfig.teamSubTitle
          ? ouTypeConfig.teamTabSubTitle || ouTypeConfig.teamSubTitle
          : POSSIBLE_MANAGEMENT_TABS[tabName].subTitle,
    }));
};

export const getDefaultTab = (ouType, userRole) => {
  return getPermittedTabs(ouType, userRole)[0];
};

export const displayOuType = (type, includeArticle, capitalize) => {
  let ret;
  switch (type) {
    case CONSTANTS.SCHOOLENTITY:
      ret = `${includeArticle ? 'de ' : ''}school`;
      break;
    case 'BOARDING':
      ret = `${includeArticle ? 'het ' : ''}internaat`;
      break;
    case CONSTANTS.GOVERNINGINSTITUTION:
      ret = `${includeArticle ? 'het ' : ''}bestuur`;
      break;
    case CONSTANTS.SCHOOLCOMMUNITY:
      ret = `${includeArticle ? 'de ' : ''}scholengemeenschap`;
      break;
    case 'CLB':
      ret = `${includeArticle ? 'het ' : ''}CLB`;
      break;
    case CONSTANTS.LEERSTEUNCENTRUM:
      ret = `${includeArticle ? 'het ' : ''}leersteuncentrum`;
      break;
    case 'CVO':
      ret = `${includeArticle ? 'het ' : ''}CVO`;
      break;
    case 'BISDOM':
      ret = `${includeArticle ? 'de ' : ''}organisatie`;
      break;
    case 'ANDERE':
      ret = `${includeArticle ? 'de ' : ''}organisatie`;
      break;
    default:
      ret = type.toLowerCase();
  }
  if (capitalize) {
    ret = ret.charAt(0).toUpperCase() + ret.slice(1);
  }
  return ret;
};

export const getOuType = (displayedOuType) => {
  switch (displayedOuType) {
    case 'school':
      return CONSTANTS.SCHOOLENTITY;
    case 'bestuur':
      return CONSTANTS.GOVERNINGINSTITUTION;
    case 'organisatie':
      return 'ANDERE';
    default:
      return displayedOuType.toUpperCase();
  }
};
