import { generateUUID } from '@kathondvla/sri-client/common-utils';

export const priority = (type) => {
  switch (type) {
    case 'EMAIL':
      return 111;
    case 'PHONE':
      return 333;
    case 'WEBSITE':
      return 222;
    case 'MAIL':
      return 444;

    default:
      return 0;
  }
};

export const getLabel = (type) => {
  switch (type) {
    case 'EMAIL':
      return 'e-mailadres';
    case 'PHONE':
      return 'telefoonnumer';
    case 'WEBSITE':
      return 'website-adres';
    case 'MAIL':
      return 'adres';

    default:
      return '';
  }
};

export const createContactDetailsResource = (type, value, organisationalUnit) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/sam/organisationalunits/contactdetails/${key}`,
      type: 'ORGANISATIONAL_UNIT_CONTACT_DETAIL',
    },
    key,
    organisationalUnit: {
      href: organisationalUnit,
    },
    priority: priority(type),
    type,
    value:
      type !== 'MAIL'
        ? {
            [type.toLowerCase()]: value,
          }
        : value,
  };
};
