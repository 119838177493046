import { MyHeaderBar } from './myHeaderBar';

export const myHeaderBarComponent = {
  template: require('./myHeaderBar.html'),
  controllerAs: 'ctrl',
  controller: MyHeaderBar,
  bindings: {
    sUser: '<',
  },
};
