import { printDate } from '@kathondvla/sri-client/date-utils';

require('./myGhangePosition.scss');

class ChangePositionModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
    this.editIcon = require('Img/edit.svg');
  }

  $onInit() {
    this.possiblePositions = this.resolve.possiblePositions;
    this.selectedPosition = this.possiblePositions.filter(
      (pos) => pos.$$meta.permalink === this.resolve.responsibility.positionHref
    )[0];

    this.school = this.resolve.school;

    this.$scope.$watch(
      () => this.selectedPosition,
      (n, o) => {
        if (n !== o) {
          this.functionErrors = [];
        }
      }
    );
  }

  isValid() {
    if (!this.selectedPosition) {
      this.functionErrors.push({
        message: 'Functie is een verplicht veld',
      });
    }
    if (
      this.selectedPosition.definition &&
      this.resolve.responsibility.startDate < this.selectedPosition.definition.startDate
    ) {
      this.functionErrors.push({
        message: `${this.selectedPosition.name} kan je pas kiezen vanaf ${printDate(
          this.selectedPosition.definition.startDate
        )}. Selecteer de rol vanaf ${printDate(this.selectedPosition.definition.startDate)}`,
      });
    }
    return this.functionErrors.length === 0;
  }

  clearErrors() {
    this.functionErrors = [];
  }

  confirmNewPosition() {
    this.clearErrors();
    if (!this.isValid()) {
      return;
    }

    this.close({
      $value: {
        position: this.selectedPosition,
      },
    });
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}

export const myChangePositionModalComponent = {
  template: require('./myChangePosition.html'),
  controllerAs: 'ctrl',
  controller: ChangePositionModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
