require('./myRegisterMailSend.scss');

class MyRegisterMailSend {
  constructor($state, $stateParams, $ngRedux) {
    'ngInject';
    this.$ngRedux =  $ngRedux;

    if ($stateParams.type === 'aanvraag') {
      this.emailIcon = require('Img/icon_invited_member.svg');
      console.log('we hebben de managernames', this.managerNames);
      if ($stateParams['geen-beheerders'] === 'true') {
        this.title = 'Helpdesk van Katholiek Onderwijs Vlaanderen is gecontacteerd.';
        this.extraInfo =
          `Er zijn geen beheerders geregistreerd voor ${$stateParams.ou} om jouw aanvraag goed te keuren.` +
          ` We hebben een bericht gestuurd naar de helpdesk van Katholiek Onderwijs Vlaanderen om hen op de hoogte te brengen.` +
          ` Zij proberen dit te verwerken binnen de 3 werkdagen. Je kan eventueel vragen aan jouw leidinggevende om contact met hen op te nemen via <a href="mailto:helpdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>.`;
      } else {
        this.unsubscribe = this.$ngRedux.connect((state) => ({
          managerNames: state.requestResponsibility.managerNames,
          ou: state.requestResponsibility.ou,
        }))(this);
        if (!this.ou) {
          this.message = 'Je kan de link naar deze pagina niet kopiëren of de pagina vernieuwen. De mail wordt maar 1 keer verstuurd.';
          return;
        }
        this.title = 'Aanvraag verstuurd';
        if (this.managerNames.length === 1) {
          this.extraInfo =`Een mail is verstuurd naar <strong>${this.managerNames[0]}</strong></br>
          Pas als ${this.managerNames[0]} jouw aanvraag goedkeurt ben je toegevoegd aan het team van <strong>${this.ou.name}</strong> en krijg je de bijhorende toegangsrechten`;
        } else {
          const managerString = `${this.managerNames.map((name) => `<strong>${name}</strong>`).slice(0, -1).join(', ')} en <strong>${this.managerNames[this.managerNames.length - 1]}</strong>`;
          this.extraInfo =`Een mail is verstuurd naar ${managerString}</br>
          Pas als één van hen jouw aanvraag goedkeurt ben je toegevoegd aan het team van <strong>${this.ou.name}</strong> en krijg je de bijhorende toegangsrechten`;
        }
      }
      this.button = {
        icon: require('Img/user.svg'),
        title: 'Naar mijn profiel',
        action: () => $state.go('profile'),
      };
    } else if ($stateParams.type === 'rol-toegevoegd') {
      this.emailIcon = require('Img/check.svg');
      this.title = `Je bent toegevoegd aan ${$stateParams.ou}`;
      this.message = `Er is geen goedkeuring van een beheerder nodig. Je bent onmiddelijk toegevoegd aan ${$stateParams.ou}.`;
      this.button = {
        icon: require('Img/user.svg'),
        title: 'Naar mijn profiel',
        action: () => $state.go('profile'),
      };
    } else {
      this.emailIcon = require('Img/icon_invited_member.svg');
      this.title = 'Bijna Klaar';
      this.message = 'Controleer je mailbox en klik op de activatielink';
      if ($stateParams['geen-beheerders'] === 'true') {
        this.extraInfo =
          `Er zijn echter wel geen beheerders geregistreerd voor <strong>${$stateParams.ou}</strong> om een aanvraag op dit team goed te keuren. Jouw registratie bevat dus geen automatische aanvraag, je kan wel nog steeds op de activatielink in jouw mailbox klikken om een account te maken.` +
          ` We hebben een bericht gestuurd naar de helpdesk van Katholiek Onderwijs Vlaanderen om hen hiervan op de hoogte te brengen.` +
          ` Je kan eventueel vragen aan jouw leidinggevende om contact met hen op te nemen via <a href="mailto:helpdesk.mijn@katholiekonderwijs.vlaanderen">helpdesk.mijn@katholiekonderwijs.vlaanderen</a>.`;
      }
    }
  }

  $onInit() {
    console.log('hebben we managers on init', this.managerNames);
  }

  $onDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}

module.exports = {
  template: require('./myRegisterMailSend.html'),
  controllerAs: 'ctrl',
  controller: MyRegisterMailSend,
};
