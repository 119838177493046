import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { trackPiwikEvent } from 'ReduxLoop/piwikAnalytics/piwikAnalyticsActions';
import { getUserTargetGroupOneResp } from 'ReduxLoop/user/piwikUtils';

/* const scaleImage = (image, imageSize) => {
  const canvas = document.createElement('canvas');
  canvas.width = imageSize;
  canvas.height = imageSize;
  canvas.getContext('2d').drawImage(image, 0, 0, imageSize, imageSize);
  const result = canvas.toDataURL('image/jpeg', 0.6);
  return result;
};

const addProfilePicture = async (profilePicture, personHref) => {
  const image = new Image();
  image.onload = async () => {
    console.log('image', image);
    console.log('image size: ', image.width, 'x', image.height);
    const images = {
      image128: scaleImage(image, 128),
      image256: scaleImage(image, 256)
      // image1024: scaleImage(image, 1024)
    };
    try {
      await addProfilePicturesToPerson(images, personHref);
    } catch (err) {
      console.error('Adding profile picture failed for ' + personHref + '!!!');
    }
  };
  image.src = 'data:image/png;base64,' + profilePicture;
}; */

export class MyRegisterActivate {
  constructor($scope, $ngRedux, $state, $stateParams, settings) {
    'ngInject';

    this.$scope = $scope;
    this.settings = settings;
    this.showIcon = require('Img/check.svg');
    this.showTitle = '';
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.position = $stateParams.functie;
    this.ou = $stateParams.ou;
    this.ouType = $stateParams.ouType;
    this.positionKey = $stateParams.position_key;
    this.registrationKey = $stateParams.key;
    this.type = $stateParams.type.toUpperCase();
    this.$ngRedux = $ngRedux;
    this.errors = [];
    this.successes = [];
    this.hasDispatchedCreateAccountEvent = false;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (state.piwikAnalyticsState.isPiwikTagReady && !this.hasDispatchedCreateAccountEvent) {
        this.hasDispatchedCreateAccountEvent = true;
        this.dispatchCreateAccountEvent();
      }
      return state;
    })(this);
  }

  $onInit() {
    this.execute();
  }

  $onDestroy() {
    this.unsubscribe();
  }

  goToAanmelden() {
    if (this.$stateParams.redirect_url) {
      window.location.href = this.$stateParams.redirect_url;
    } else {
      this.$state.go('home');
    }
  }

  async dispatchCreateAccountEvent() {
    const sendCreateAccountTrackingEvent = !window?.dataLayer?.some(
      (e) => e?.event === 'create_account'
    );

    const userTargetGroup =
      this.type.toLowerCase() !== 'request' &&
      (await getUserTargetGroupOneResp(this.positionKey, this.ouType));

    if (sendCreateAccountTrackingEvent) {
      this.$ngRedux.dispatch(
        trackPiwikEvent('create_account', {
          user_target_group: userTargetGroup?.name?.toLowerCase() || undefined,
        })
      );
    } else {
      console.log('Cannot send create_account event to Piwik.');
    }
  }

  async execute() {
    const error = await this.confirmRegistration(this.registrationKey);
    if (error) {
      this.showIcon = require('Img/cross.svg');
      this.showTitle = 'Mislukt';
      this.errors.push(error);
    }
    this.$scope.$apply();
  }

  async confirmRegistration(key) {
    try {
      const response = await sriClient.post('/persons/register/confirm', { registrationCode: key });
      console.log(response);
      this.showTitle = 'Gelukt!';
      const success = { type: this.type, position: this.position, ou: this.ou };
      if (response.managerNames) {
        this.managerNames = response.managerNames;
        console.log('Er zijn manager names!');
        const managerNamesString =
          response.managerNames.length === 1
            ? response.managerNames[0]
            : `${response.managerNames.slice(0, -1).join(', ')} en ${response.managerNames.slice(
                -1
              )}`;
        success.managerNamesString = managerNamesString;
      }
      this.successes.push(success);
      /* if (response.profilePicture) {
        addProfilePicture(response.profilePicture, response.personHref);
      } */

      this.dispatchCreateAccountEvent();
      return null;
    } catch (err) {
      console.log('error', err);
      if (!err.body || !err.body.errors) {
        return {
          code: 500,
          message: 'Er is een onverwachte fout opgetreden. Probeer het later nog eens opnieuw.',
        };
      }
      let ret = null;
      err.body.errors.forEach((error) => {
        switch (error.code) {
          case 'link.expired':
            ret = {
              code: error.code,
              message: 'De activeringslink is niet langer geldig. Registreer je nog eens opniuew.',
            };
            break;
          case 'link.already.used':
            ret = {
              code: error.code,
              message: 'Deze link is al eens gebruikt. Je account is al geactiveerd.',
            };
            break;
          case 'forbidden':
            ret = { code: error.code, message: error.message };
            break;
          default:
            ret = { code: error.code, message: error.code };
            break;
        }
      });
      return ret;
    }
  }
}
