import { generateUUID } from '@kathondvla/sri-client/common-utils';

const Batch = require('@kathondvla/sri-client/batch');

export const createPerson = (personForm) => {
  const key = personForm.key ? personForm.key : generateUUID();
  return {
    $$meta: {
      permalink: `/persons/${key}`,
    },
    key,
    sex: personForm.sex ? personForm.sex : undefined,
    title: personForm.title ? personForm.title : undefined,
    firstName: personForm.firstName,
    lastName: personForm.lastName,
    username: personForm.username,
    dateOfBirth: personForm.dateOfBirth,
    registrationNumber: personForm.registrationNumber ? personForm.registrationNumber : undefined,
  };
};

export const createEmailAddress = (emailAddress, person, isSecondary) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/contactdetails/${key}`,
    },
    key,
    value: emailAddress,
    person: {
      href: person.$$meta.permalink,
    },
    type: isSecondary ? 'EMAILADDRESS_OFFICE' : 'EMAILADDRESS_PRIMARY',
  };
};

export const createPhone = (phoneNumber, person, type) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/contactdetails/${key}`,
    },
    key,
    number: phoneNumber,
    person: {
      href: person.$$meta.permalink,
    },
    type,
  };
};

export const createAddress = (address, person, type) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/contactdetails/${key}`,
    },
    key,
    person: {
      href: person.$$meta.permalink,
    },
    type,
    ...address,
  };
};

export const createBankAccount = (iban, person, type) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/bankaccounts/${key}`,
    },
    key,
    iban,
    person: {
      href: person.$$meta.permalink,
    },
    type,
  };
};

export const createChangePassword = (password, person, oldPassword) => {
  return {
    person: person.$$meta.permalink,
    newPassword: password,
    oldPassword,
  };
};

export const createAccount = (
  person,
  loginProviderKey,
  userKey,
  userAlias,
  loginProviderDomain
) => {
  const key = generateUUID();
  return {
    $$meta: {
      permalink: `/accounts/${key}`,
    },
    key,
    person: {
      href: person.$$meta.permalink,
    },
    acknowledgedClients: [],
    externalLoginProviders: [
      {
        providerKey: userKey,
        alias: userAlias,
        domain: loginProviderDomain,
        loginProvider: {
          href: `/loginproviders/${loginProviderKey}`,
        },
      },
    ],
  };
};

export const getPersonsBatch = (profileState) => {
  const batch = new Batch();
  if (
    profileState.person.title !== profileState.lastSavedState.person.title ||
    profileState.person.sex !== profileState.lastSavedState.person.sex ||
    profileState.person.firstName !== profileState.lastSavedState.person.firstName ||
    profileState.person.lastName !== profileState.lastSavedState.person.lastName ||
    profileState.person.registrationNumber !==
      profileState.lastSavedState.person.registrationNumber ||
    profileState.person.dateOfBirth !== profileState.lastSavedState.person.dateOfBirth ||
    profileState.person.username !== profileState.lastSavedState.person.username
  ) {
    batch.put(profileState.person);
  }
  if (
    !profileState.lastSavedState.primaryEmail ||
    profileState.lastSavedState.primaryEmail.value !== profileState.primaryEmail.value
  ) {
    batch.put(profileState.primaryEmail);
  }
  if (
    profileState.secondaryEmail &&
    (!profileState.lastSavedState.secondaryEmail ||
      profileState.lastSavedState.secondaryEmail.value !== profileState.secondaryEmail.value)
  ) {
    batch.put(profileState.secondaryEmail);
  }
  if (!profileState.secondaryEmail && profileState.lastSavedState.secondaryEmail) {
    batch.delete(profileState.lastSavedState.secondaryEmail.$$meta.permalink);
  }
  if (
    profileState.officePhone &&
    (!profileState.lastSavedState.officePhone ||
      profileState.lastSavedState.officePhone.number !== profileState.officePhone.number)
  ) {
    batch.put(profileState.officePhone);
  }
  if (!profileState.officePhone && profileState.lastSavedState.officePhone) {
    batch.delete(profileState.lastSavedState.officePhone.$$meta.permalink);
  }
  if (
    profileState.officeMobilePhone &&
    (!profileState.lastSavedState.officeMobilePhone ||
      profileState.lastSavedState.officeMobilePhone.number !==
        profileState.officeMobilePhone.number)
  ) {
    batch.put(profileState.officeMobilePhone);
  }
  if (!profileState.officeMobilePhone && profileState.lastSavedState.officeMobilePhone) {
    batch.delete(profileState.lastSavedState.officeMobilePhone.$$meta.permalink);
  }
  if (
    profileState.officeAddress &&
    (!profileState.lastSavedState.officeAddress ||
      profileState.lastSavedState.officeAddress.zipCode !== profileState.officeAddress.zipCode ||
      profileState.lastSavedState.officeAddress.subCity !== profileState.officeAddress.subCity ||
      profileState.lastSavedState.officeAddress.street !== profileState.officeAddress.street ||
      profileState.lastSavedState.officeAddress.houseNumber !==
        profileState.officeAddress.houseNumber ||
      profileState.lastSavedState.officeAddress.mailboxNumber !==
        profileState.officeAddress.mailboxNumber ||
      profileState.lastSavedState.officeAddress.streetHref !==
        profileState.officeAddress.streetHref ||
      profileState.lastSavedState.officeAddress.subCityHref !==
        profileState.officeAddress.subCityHref)
  ) {
    batch.put(profileState.officeAddress);
  }
  if (!profileState.officeAddress && profileState.lastSavedState.officeAddress) {
    batch.delete(profileState.lastSavedState.officeAddress.$$meta.permalink);
  }
  if (
    profileState.personalPhone &&
    (!profileState.lastSavedState.personalPhone ||
      profileState.lastSavedState.personalPhone.number !== profileState.personalPhone.number)
  ) {
    batch.put(profileState.personalPhone);
  }
  if (!profileState.personalPhone && profileState.lastSavedState.personalPhone) {
    batch.delete(profileState.lastSavedState.personalPhone.$$meta.permalink);
  }
  if (
    profileState.personalMobilePhone &&
    (!profileState.lastSavedState.personalMobilePhone ||
      profileState.lastSavedState.personalMobilePhone.number !==
        profileState.personalMobilePhone.number)
  ) {
    batch.put(profileState.personalMobilePhone);
  }
  if (!profileState.personalMobilePhone && profileState.lastSavedState.personalMobilePhone) {
    batch.delete(profileState.lastSavedState.personalMobilePhone.$$meta.permalink);
  }
  if (
    profileState.personalAddress &&
    (!profileState.lastSavedState.personalAddress ||
      profileState.lastSavedState.personalAddress.zipCode !==
        profileState.personalAddress.zipCode ||
      profileState.lastSavedState.personalAddress.subCity !==
        profileState.personalAddress.subCity ||
      profileState.lastSavedState.personalAddress.street !== profileState.personalAddress.street ||
      profileState.lastSavedState.personalAddress.houseNumber !==
        profileState.personalAddress.houseNumber ||
      profileState.lastSavedState.personalAddress.mailboxNumber !==
        profileState.personalAddress.mailboxNumber ||
      profileState.lastSavedState.personalAddress.streetHref !==
        profileState.personalAddress.streetHref ||
      profileState.lastSavedState.personalAddress.subCityHref !==
        profileState.personalAddress.subCityHref)
  ) {
    batch.put(profileState.personalAddress);
  }
  if (
    profileState.expensesBankAccount &&
    (!profileState.lastSavedState.expensesBankAccount ||
      profileState.lastSavedState.expensesBankAccount.iban !==
        profileState.expensesBankAccount.iban)
  ) {
    batch.put(profileState.expensesBankAccount);
  }
  if (!profileState.expensesBankAccount && profileState.lastSavedState.expensesBankAccount) {
    batch.delete(profileState.lastSavedState.expensesBankAccount.$$meta.permalink);
  }
  return batch;
};
