import {
  fetchUser,
  popUpIsOpened,
  modalMissingInformationIsOpened,
} from 'ReduxLoop/user/userActions';
import { getDefaultTab } from 'ReduxLoop/appConfig';
import * as notificationsActions from 'ReduxLoop/notifications/notificationActions';
import { createExpiredCookieForPopup, shouldShowPopup } from 'Js/module/popupUtils';

export class Home {
  constructor($state, $ngRedux, settings, $uibModal, $cookies, $timeout) {
    'ngInject';

    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.appVersion = settings.projectVersion;
    this.leeromgevingEnabled = settings.leeromgevingEnabled;
    this.$uibModal = $uibModal;
    this.$cookies = $cookies;

    this.icons = {
      user: require('Img/user.svg'),
      school: require('Img/school.svg'),
      boarding: require('Img/boarding.svg'),
      schoolCommunity: require('Img/scholengemeenschap.svg'),
      lerarennetwerk: require('Img/teacher_network.svg'),
      team: require('Img/meeting.svg'),
      newsletter: require('Img/newsletter.svg'),
      nascholing: require('Img/nascholing.svg'),
      leerpaden: require('Img/i_leerpladen_mijn.svg'),
      helpdesk: require('Img/search.svg'),
    };

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const ouButtons = [];
      if (state.vm.user.manages) {
        const schools = [...state.vm.user.manages.SCHOOLENTITY, ...state.vm.user.manages.SCHOOL];
        if (schools.length > 0) {
          ouButtons.push({
            title: schools.length > 1 ? `Mijn scholen (${schools.length})` : 'Mijn school',
            displayType: 'school',
            icon: this.icons.school,
            mgmts: schools,
            href: schools.length > 1 ? '/#!/school/selecteer' : schools[0].href,
          });
        }
        const gis = state.vm.user.manages.GOVERNINGINSTITUTION;
        if (gis.length > 0) {
          ouButtons.push({
            title: gis.length > 1 ? `Mijn besturen (${gis.length})` : 'Mijn bestuur',
            displayType: 'bestuur',
            icon: this.icons.schoolCommunity,
            mgmts: gis,
            href: gis.length > 1 ? '/#!/bestuur/selecteer' : gis[0].href,
          });
        }
        const boardings = state.vm.user.manages.BOARDING;
        if (boardings.length > 0) {
          ouButtons.push({
            title:
              boardings.length > 1 ? `Mijn internaten (${boardings.length})` : 'Mijn internaat',
            displayType: 'internaat',
            icon: this.icons.boarding,
            mgmts: boardings,
            href: boardings.length > 1 ? '/#!/internaat/selecteer' : boardings[0].href,
          });
        }
        const clbs = state.vm.user.manages.CLB;
        if (clbs.length > 0) {
          ouButtons.push({
            title: clbs.length > 1 ? `Mijn CLB's (${clbs.length})'` : 'Mijn CLB',
            displayType: 'CLB',
            icon: this.icons.school,
            mgmts: clbs,
            href: clbs.length > 1 ? '/#!/clb/selecteer' : clbs[0].href,
          });
        }
        const leersteuncentra = state.vm.user.manages.LEERSTEUNCENTRUM;
        if (leersteuncentra.length > 0) {
          ouButtons.push({
            title:
              clbs.length > 1
                ? `Mijn leersteuncenctra (${leersteuncentra.length})`
                : 'Mijn leersteuncentrum',
            displayType: 'leersteuncentrum',
            icon: this.icons.school,
            mgmts: leersteuncentra,
            href:
              leersteuncentra.length > 1
                ? '/#!/leersteuncentrum/selecteer'
                : leersteuncentra[0].href,
          });
        }
        const scs = state.vm.user.manages.SCHOOLCOMMUNITY;
        if (scs.length > 0) {
          ouButtons.push({
            title:
              scs.length > 1
                ? `Mijn scholengemeenschappen (${scs.length})`
                : 'Mijn scholengemeenschap',
            displayType: 'scholengemeenschap',
            icon: this.icons.schoolCommunity,
            mgmts: scs,
            href: scs.length > 1 ? '/#!/scholengemeenschap/selecteer' : scs[0].href,
          });
        }
        const lns = state.vm.user.manages.LERARENNETWERK;
        if (lns.length > 0) {
          ouButtons.push({
            title: lns.length > 1 ? `Mijn lerarennetwerken (${lns.length})` : 'Mijn lerarennetwerk',
            displayType: 'lerarennetwerk',
            icon: this.icons.lerarennetwerk,
            mgmts: lns,
            href: lns.length > 1 ? '/#!/lerarennetwerk/selecteer' : lns[0].href,
          });
        }
        const teams = state.vm.user.manages.TEAM;
        if (state.vm.user.isKathOndVlaEmployee) {
          ouButtons.push({
            title: `Mijn teams (${teams.length})`,
            displayType: 'team',
            icon: this.icons.team,
            mgmts: teams,
            href: '/#!/team/selecteer',
          });
        }
        const others = state.vm.user.manages.ANDERE;
        if (others.length > 0) {
          ouButtons.push({
            title: others.length > 1 ? `Mijn organisaties (${others.length})` : 'Mijn organisatie',
            displayType: 'organisatie',
            icon: this.icons.school,
            mgmts: others,
            href: others.length > 1 ? '/#!/organisatie/selecteer' : others[0].href,
          });
        }
      }

      if (state.vm.user.popupInfo && !state.vm.user.popUpIsOpened) {
        // eslint-disable-next-line prefer-destructuring
        this.popup = this.settings.popup[0];
        const cookie = this.$cookies.get(`popup-${this.popup.key}`);
        if (!cookie && shouldShowPopup(this.popup)) {
          this.createPopup(state.vm.user.popupTitle, state.vm.user.popupInfo);
          this.$ngRedux.dispatch(popUpIsOpened());
        }
      }

      if (
        state.vm.user.missingContactDetailsModal &&
        state.vm.user.missingContactDetailsModal.showModal &&
        !state.vm.user.missingContactDetailsModal.modalMissingInformationIsOpened
      ) {
        this.$ngRedux.dispatch(modalMissingInformationIsOpened());
        // if you come from another screen, you first want to go to the home screen and then show the modal
        // without the timeout the modal is shown on the previous screen.
        $timeout(() => {
          this.createMissingContactDetailsModal(
            state.vm.user.missingContactDetailsModal.managingSchoolEntititiesWithoutMailAddress
          );
        }, 50);
      }

      return {
        user: state.vm.user,
        ouButtons,
        loading: !state.vm.user || !state.vm.user.manages,
        notificationList: state.vm.notifications,
      };
    })(this);
  }

  $onInit() {
    if (!this.user.key) {
      this.$ngRedux.dispatch(fetchUser());
    }
  }

  goToMyProfile() {
    this.$state.go('profile');
  }

  goToHelpDesk() {
    // window.location.href = this.settings.applications.mijn1 + '/#/helpdesk';
    this.$state.go('helpdesk-search');
  }

  goToOu(mgmt, displayType) {
    if (mgmt.role === 'OLD_MIJN_MANAGER') {
      window.location.href = `${this.settings.applications.mijn1}/#/team/${mgmt.institutionNumber}`;
    } else {
      const defaultTab = getDefaultTab(mgmt.ouType, mgmt.role);
      this.$state.go(defaultTab.state, {
        schoolId: mgmt.ouKey,
        type: displayType,
      });
    }
  }

  goToMyOus(ouButton) {
    if (ouButton.mgmts.length === 1) {
      this.goToOu(ouButton.mgmts[0], ouButton.displayType);
    } else {
      this.$state.go('schoolSelecteer', { type: ouButton.displayType });
    }
  }

  goToMyNewsletters() {
    this.$state.go('newsletterPreferences');
  }

  goToMyNascholingen() {
    window.location = this.settings.applications.nascholingen;
  }

  goToMyLeeromgeving() {
    window.open(this.settings.applications.leeromgeving, '_blank');
  }

  goToMijn1() {
    window.location = `${this.settings.applications.mijn1}/#/`;
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(notificationsActions.removeNotification(notificationKey));
  }

  createPopup(title, html) {
    const popup = this.$uibModal.open({
      animation: true,
      component: 'myPopup',
      size: 'md',
      backdrop: 'static',
      resolve: {
        title: () => title,
        body: () => html,
      },
    });

    popup.result.then(
      () => createExpiredCookieForPopup(this.$cookies, this.popup),
      () => createExpiredCookieForPopup(this.$cookies, this.popup)
    );

    return popup;
  }

  createMissingContactDetailsModal(managingSchoolEntititiesWithoutMailAddress) {
    this.$uibModal.open({
      animation: true,
      component: 'myMissingContactDetailsModal',
      size: 'md',
      backdrop: 'static',
      resolve: {
        schoolEntititiesWithoutMailAddress: () => managingSchoolEntititiesWithoutMailAddress,
      },
    });
    // missingContactDetailsModal.result.finally(() => {});
  }

  $onDestroy() {
    this.unsubscribe();
  }
}
