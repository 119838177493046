import { printDate } from '@kathondvla/sri-client/date-utils';
import * as notificationsActions from 'ReduxLoop/notifications/notificationActions';
import * as ACTIONS from 'Js/reduxLoop/ouManager/createTeamWizard/createTeamWizardActions';

require('./myCreateTeam.scss');

class MyCreateTeam {
  constructor($ngRedux, $scope, $state) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.groupIcon = require('Img/meeting.svg');
    this.backIcon = require('Img/arrow-left.svg');
    this.continueIcon = require('Img/arrow-right.svg');
    this.isTypingInDescription = false;
    this.descriptionDirty = false;
    this.printDate = printDate;
    this.$scope = $scope;
    this.$state = $state;

    this.steps = [
      { title: 'Overleggen' },
      { title: 'Gegevens invoeren' },
      { title: 'Registratie bevestigen' },
      { title: 'Aan de slag gaan' },
    ];

    this.unsubscribe = this.$ngRedux.connect((state) => ({
      ...state.vm.createTeamWizard,
      notificationList: state.vm.notifications,
    }))(this);
  }

  $onInit() {
    this.$ngRedux.dispatch(ACTIONS.startCreateTeamWizard());

    this.$scope.$on('onFormValidationChanged', (event, formObj) => {
      this.$ngRedux.dispatch(ACTIONS.setCreateTeamFormValidation(formObj.valid));
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }

  cancel() {
    this.$state.go('schoolSelecteer', { type: 'team' });
  }

  goToPreviousStep() {
    this.$ngRedux.dispatch(ACTIONS.goToPreviousStepInCreateTeamWizard());
  }

  goToNextStep() {
    this.$ngRedux.dispatch(ACTIONS.goToNextStepInCreateTeamWizard());
  }

  nameChanged(ev, data) {
    this.$ngRedux.dispatch(ACTIONS.changeName(data));
  }

  shortNameChanged(ev, data) {
    this.$ngRedux.dispatch(ACTIONS.changeShortName(data));
  }

  keyPressedInDescription(ev) {
    if (ev.keyCode !== 32) {
      // ignore space;
      this.isTypingInDescription = true;
    }
  }

  descriptionChanged(ev, data) {
    this.isTypingInDescription = false;
    this.descriptionDirty = true;
    this.$ngRedux.dispatch(ACTIONS.changeDescription(this.form.description));
  }

  startDateChanged(ev, data) {
    this.$ngRedux.dispatch(ACTIONS.changeStartDate(data));
  }

  typeChanged() {
    this.$ngRedux.dispatch(ACTIONS.changeType(this.form.type));
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(notificationsActions.removeNotification(notificationKey));
  }

  joinSuggestedTeam() {
    this.$ngRedux.dispatch(ACTIONS.requestToJoinSuggestedTeam(this.duplicateSuggestion.key));
  }
}

export const myCreateTeamComponent = {
  template: require('./myCreateTeam.html'),
  controllerAs: 'ctrl',
  controller: MyCreateTeam,
};
