import {
  isOverlapping,
  isBefore,
  isBeforeOrEqual,
  isAfter,
  getNextDay,
  getNow,
} from '@kathondvla/sri-client/date-utils';
import { loop, Cmd } from 'redux-loop';
import { Map, fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { arrayToMap, getCurrentUserMgmt } from 'ReduxLoop/index';
import {
  storeResource,
  removeResource,
  storeGroupInfo,
  persistResponsibilities,
  fetchGroupsInfo,
  deleteGroupInfo,
} from 'ReduxLoop/utils';
import {
  createGroup,
  createSchoolGroupRelation,
  createResponsibility,
} from 'ReduxLoop/utils/apiResourceFactory';
import * as ACTIONS from 'ReduxLoop/ouManager/vakGroepen/groupsActions';
import { GROUP_MANAGER, BEHEERDER, SCHOOLENTITY, STRATEGIC_PURCHASER } from 'ReduxLoop/constants';
import { mapToArray } from 'ReduxLoop/viewModel';
import * as NOTIFICATION_ACTIONS from 'ReduxLoop/notifications/notificationActions';
import {
  CURRENT_OU_FETCHED,
  SELECT_ORGANISATIONAL_UNIT,
} from 'ReduxLoop/ouManager/schoolManagerActions';
import { END_TEAM_MEMBERSHIP } from 'ReduxLoop/ouManager/team/teamActions';

const findOuGroupRelation = (state, group) => {
  return state.ouGroups.find((ouGroup) => {
    return (
      ouGroup.getIn(['from', 'href']) === group.getIn(['$$meta', 'permalink']) &&
      isOverlapping(
        {
          startDate: group.get('startDate'),
          endDate: group.get('endDate'),
        },
        {
          startDate: ouGroup.get('startDate'),
          endDate: ouGroup.get('endDate'),
        }
      )
    );
  });
};

const inititalState = {
  selectedGroup: null,
  showDeletedGroups: false,
  loadingGroupInfo: true,
  groups: Map(),
  ouGroups: Map(),
  responsibilities: Map(),
  groupInfoTabs: [],
  errors: [],
  filter: '',
};

export const groupsReducer = (state = inititalState, action, rootState) => {
  let updatedState;
  let selectedGroup;

  if (action.payload && action.payload.targetGroup) {
    selectedGroup = state.groups.get(`/sam/organisationalunits/${action.payload.targetGroup}`);
  } else if (state.selectedGroup) {
    selectedGroup = state.groups.get(state.selectedGroup);
  }

  switch (action.type) {
    case SELECT_ORGANISATIONAL_UNIT:
      return inititalState;
    // return Object.assign({}, state, {ouGroups: Map(), groups: Map(), selectedGroup: null, showDeletedGroups: false});

    case CURRENT_OU_FETCHED: {
      if (action.payload.type !== SCHOOLENTITY) {
        return state;
      }
      const currentUserMgmt = getCurrentUserMgmt(rootState, action.payload);
      if (currentUserMgmt && currentUserMgmt.role === STRATEGIC_PURCHASER) {
        return state;
      }
      let limitGroups = null;
      if (currentUserMgmt && currentUserMgmt.role === GROUP_MANAGER) {
        limitGroups = currentUserMgmt.groups.map((group) => ({
          href: group.ouPermalink,
          noAccessYet: group.noAccessYet,
        }));
      }
      return loop(
        state,
        Cmd.run(fetchGroupsInfo, {
          args: [action.payload, limitGroups],
          successActionCreator: ACTIONS.groupsInfoFetchedAction,
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.GROUPS_INFO_FETCHED:
      return {
        ...state,
        loadingGroupInfo: false,
        groups: fromJS(arrayToMap(action.payload.groupsInfo.groups)),
        ouGroups: fromJS(arrayToMap(action.payload.groupsInfo.ouGroups)),
        responsibilities: fromJS(arrayToMap(action.payload.groupsInfo.responsibilities)),
      };

    case ACTIONS.SELECT_GROUP:
      return { ...state, selectedGroup: `/sam/organisationalunits/${action.payload.groupKey}` };

    case ACTIONS.SELECT_NOGROUP:
      return { ...state, selectedGroup: null };

    case ACTIONS.ADD_GROUP: {
      const group = createGroup(action.payload.name, action.payload.startDate);
      const schoolGroupRelation = createSchoolGroupRelation(
        group,
        rootState.schoolManager.currentOrganisationalUnit,
        action.payload.startDate
      );

      updatedState = {
        groups: state.groups.set(group.$$meta.permalink, fromJS(group)),
        ouGroups: state.ouGroups.set(
          schoolGroupRelation.$$meta.permalink,
          fromJS(schoolGroupRelation)
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeGroupInfo, {
          args: [[group, schoolGroupRelation]],
          successActionCreator: () => {
            if (action.payload.chairman && !isEmpty(action.payload.chairman)) {
              return ACTIONS.addMemberToGroupAction({
                targetGroup: group.key,
                ...action.payload.chairman,
              });
            }
            return ACTIONS.selectGroupAction({ groupKey: group.key });
          },
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.EDIT_GROUP: {
      const groupNamesToEdit = selectedGroup.get('names').map((name) => {
        if (name.type !== 'SHORT') {
          return name.set('value', action.payload.name);
        }
        return name;
      });

      selectedGroup = selectedGroup
        .set('$$displayName', action.payload.name)
        .set('names', groupNamesToEdit);

      updatedState = {
        groups: state.groups.set(state.selectedGroup, selectedGroup),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeGroupInfo, {
          args: [[selectedGroup.toJS()]],
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.END_GROUP: {
      let groupToEnd = state.groups.get(action.payload.group.permalink);

      const groupResponsabilities = state.responsibilities.filter(
        (resp) => resp.getIn(['organisation', 'href']) === groupToEnd.getIn(['$$meta', 'permalink'])
      );

      if (groupResponsabilities.size === 0) {
        // delete the group if there was never registered a responsibility, otherwise always set an endDate
        const schoolGroupRelationToDelete = findOuGroupRelation(state, groupToEnd);

        updatedState = {
          selectedGroup: null,
          groups: state.groups.delete(groupToEnd.getIn(['$$meta', 'permalink'])),
          ouGroups: state.ouGroups.delete(
            schoolGroupRelationToDelete.getIn(['$$meta', 'permalink'])
          ),
        };

        return loop(
          { ...state, ...updatedState },
          Cmd.run(deleteGroupInfo, {
            args: [[groupToEnd.toJS(), schoolGroupRelationToDelete.toJS()]],
            failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
          })
        );

        /* const deleteGroupCmd = Cmd.run(deleteGroupInfo, {
          args: [[groupToEnd.toJS(), schoolGroupRelationToDelete.toJS()]],
          failActionCreator: (error) => groupActionFailed(error, state)
        });

        const selectNoGroupCmd = Cmd.action({type: ACTIONS.SELECT_NOGROUP});

        return loop(
          Object.assign({}, state, updatedState),
          Cmd.list([deleteGroupCmd, selectNoGroupCmd])
        ); */
      }

      const groupNamesToEnd = groupToEnd
        .get('names')
        .map((name) => name.set('endDate', action.payload.endDate));
      groupToEnd = groupToEnd.set('names', groupNamesToEnd).set('endDate', action.payload.endDate);

      const schoolGroupRelationToEnd = findOuGroupRelation(state, groupToEnd).set(
        'endDate',
        action.payload.endDate
      );

      // also delete all responsibilities in the group
      let newResponsibilities = state.responsibilities;
      const respsToEnd = [];
      const respsToDelete = [];
      groupResponsabilities.valueSeq().forEach((resp) => {
        if (isBefore(action.payload.endDate, resp.get('endDate'))) {
          if (isBeforeOrEqual(action.payload.endDate, resp.get('startDate'))) {
            respsToDelete.push(resp.toJS());
            newResponsibilities = newResponsibilities.delete(resp.getIn(['$$meta', 'permalink']));
          } else {
            const newResp = resp.set('endDate', action.payload.endDate);
            newResponsibilities = newResponsibilities.set(
              resp.getIn(['$$meta', 'permalink']),
              newResp
            );
            respsToEnd.push(newResp.toJS());
          }
        }
      });

      updatedState = {
        groups: state.groups.set(state.selectedGroup, groupToEnd),
        ouGroups: state.ouGroups.set(
          schoolGroupRelationToEnd.getIn(['$$meta', 'permalink']),
          schoolGroupRelationToEnd
        ),
        responsibilities: newResponsibilities,
      };

      const cmds = [];
      const storeGroupInfoCmd = Cmd.run(storeGroupInfo, {
        args: [[groupToEnd.toJS(), schoolGroupRelationToEnd.toJS()]],
        failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
      });
      cmds.push(storeGroupInfoCmd);

      if (respsToEnd.length > 0 || respsToDelete.length > 0) {
        const storeResponsibilitiesCmd = Cmd.run(persistResponsibilities, {
          args: [respsToEnd, respsToDelete],
        });
        cmds.push(storeResponsibilitiesCmd);
      }

      return loop({ ...state, ...updatedState }, Cmd.list(cmds, { sequence: true }));
    }

    case ACTIONS.REACTIVATE_GROUP: {
      let groupToReactivate = state.groups.get(action.payload.permalink);
      const oldEndDate = groupToReactivate.get('endDate');

      const groupNamesToReactivate = groupToReactivate
        .get('names')
        .map((name) => name.set('endDate', undefined));
      groupToReactivate = groupToReactivate
        .set('names', groupNamesToReactivate)
        .set('endDate', undefined);

      const schoolGroupRelationToReactivate = findOuGroupRelation(state, groupToReactivate).set(
        'endDate',
        undefined
      );

      // also reopen the responsibilities in the group with the same endDate as the group itself
      let newResponsibilities = state.responsibilities;
      const groupResponsabilities = state.responsibilities.filter(
        (resp) =>
          resp.getIn(['organisation', 'href']) === groupToReactivate.getIn(['$$meta', 'permalink'])
      );
      const teamResponsibilities = mapToArray(rootState.teamTab.responsibilities.toJS());
      const respsToReopen = [];
      groupResponsabilities.valueSeq().forEach((resp) => {
        const stillActiveTeamMember = teamResponsibilities.some(
          (teamResp) =>
            teamResp.person.href === resp.getIn(['person', 'href']) &&
            isAfter(teamResp.endDate, getNow())
        );
        if (oldEndDate === resp.get('endDate') && stillActiveTeamMember) {
          const newResp = resp.set('endDate', undefined);
          newResponsibilities = newResponsibilities.set(
            resp.getIn(['$$meta', 'permalink']),
            newResp
          );
          respsToReopen.push(newResp.toJS());
        }
      });

      updatedState = {
        showDeletedGroups: false,
        groups: state.groups.set(state.selectedGroup, groupToReactivate),
        ouGroups: state.ouGroups.set(
          schoolGroupRelationToReactivate.getIn(['$$meta', 'permalink']),
          schoolGroupRelationToReactivate
        ),
        responsibilities: newResponsibilities,
      };

      const cmds = [];
      const storeGroupInfoCmd = Cmd.run(storeGroupInfo, {
        args: [[groupToReactivate.toJS(), schoolGroupRelationToReactivate.toJS()]],
        failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
      });
      cmds.push(storeGroupInfoCmd);

      if (respsToReopen.length > 0) {
        const storeResponsibilitiesCmd = Cmd.run(persistResponsibilities, {
          args: [respsToReopen, []],
        });
        cmds.push(storeResponsibilitiesCmd);
      }

      return loop({ ...state, ...updatedState }, Cmd.list(cmds));
    }

    case ACTIONS.DELETE_GROUP: {
      const schoolGroupRelationToDelete = findOuGroupRelation(state, selectedGroup);

      updatedState = {
        groups: state.groups.delete(selectedGroup.getIn(['$$meta', 'permalink'])),
        ouGroups: state.ouGroups.delete(schoolGroupRelationToDelete.getIn(['$$meta', 'permalink'])),
      };

      const deleteGroupCmd = Cmd.run(deleteGroupInfo, {
        args: [[selectedGroup.toJS(), schoolGroupRelationToDelete.toJS()]],
        failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
      });

      const selectNoGroupCmd = Cmd.action({ type: ACTIONS.SELECT_NOGROUP });

      return loop({ ...state, ...updatedState }, Cmd.list([deleteGroupCmd, selectNoGroupCmd]));
    }

    case ACTIONS.ADD_MEMBER_TO_GROUP: {
      const groupResponbility = createResponsibility(
        selectedGroup.toJS().$$meta.permalink,
        action.payload.person.permalink,
        action.payload.position,
        action.payload.startDate,
        selectedGroup.get('endDate')
      );

      updatedState = {
        responsibilities: state.responsibilities.set(
          groupResponbility.$$meta.permalink,
          fromJS(groupResponbility)
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeResource, {
          args: [groupResponbility],
          successActionCreator: () => {
            if (action.payload.targetGroup) {
              return ACTIONS.selectGroupAction({ groupKey: action.payload.targetGroup });
            }
            return undefined;
          },
          failActionCreator: (error) =>
            ACTIONS.addMemberToGroupFailed(
              groupResponbility.$$meta.permalink,
              error,
              action.payload.targetGroup
            ),
        })
      );
    }

    case ACTIONS.ADD_MEMBER_TO_GROUP_FAILED: {
      let errorMessage =
        'Er is een onverwachte fout opgetreden bij het toevoegen van een lid aan de vakgroep.';
      console.log('addMemberToGroupError', action.payload.error);
      if (action.payload.error && action.payload.error.status === 403) {
        errorMessage = `De rechten om leden aan de nieuwe vakgroep ${
          selectedGroup ? `- ${selectedGroup.get('$$displayName')} -` : ''
        } te koppelen zijn nog niet gesynchroniseerd. Probeer het over enkele minuten nog eens opnieuw.`;
      }
      const cmds = [Cmd.action(NOTIFICATION_ACTIONS.addErrorNotification(errorMessage))];
      if (action.payload.targetGroup) {
        cmds.push(Cmd.action(ACTIONS.selectGroupAction({ groupKey: action.payload.targetGroup })));
      }
      return loop(
        {
          ...state,
          responsibilities: state.responsibilities.delete(action.payload.responsibilityHref),
        },
        Cmd.list(cmds)
      );
    }

    case ACTIONS.MAKE_GROUP_ADMINISTRATOR: {
      const respGroup = selectedGroup.toJS(); // @Ismel: the group should be in the payload
      let startDate = getNow();
      if (isAfter(respGroup.startDate, startDate)) {
        startDate = respGroup.startDate;
      }
      if (isAfter(action.payload.person.startDate, startDate)) {
        startDate = action.payload.person.startDate;
      }
      let { endDate } = action.payload.person;
      if (isBefore(respGroup.endDate, endDate)) {
        endDate = respGroup.endDate;
      }
      const groupAdminResponbility = createResponsibility(
        selectedGroup.toJS().$$meta.permalink,
        action.payload.person.permalink,
        BEHEERDER,
        startDate,
        endDate
      );

      updatedState = {
        responsibilities: state.responsibilities.set(
          groupAdminResponbility.$$meta.permalink,
          fromJS(groupAdminResponbility)
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeResource, {
          args: [groupAdminResponbility],
          failActionCreator: (error) =>
            ACTIONS.addMemberToGroupFailed(groupAdminResponbility.$$meta.permalink, error),
        })
      );
    }

    case ACTIONS.REMOVE_GROUP_ADMINISTRATOR: {
      const responsibilityPermalinkToRemove = action.payload.responsibilityPermalink;

      updatedState = {
        responsibilities: state.responsibilities.delete(responsibilityPermalinkToRemove),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(removeResource, {
          args: [responsibilityPermalinkToRemove],
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.EDIT_MEMBER_RESPONSIBILITY: {
      const responsibilityPermalinkToEdit = action.payload.responsibilityPermalink;
      const responsibilityToEdit = state.responsibilities
        .get(responsibilityPermalinkToEdit)
        .setIn(['position', 'href'], action.payload.positionPermalink);

      updatedState = {
        responsibilities: state.responsibilities.set(
          action.payload.responsibilityPermalink,
          responsibilityToEdit
        ),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeResource, {
          args: [responsibilityToEdit.toJS()],
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.END_MEMBER_RESPONSIBILITY: {
      const responsibilityPermalinkToEnd = action.payload.responsibilityPermalink;
      const responsibilityToEnd = state.responsibilities
        .get(responsibilityPermalinkToEnd)
        .set('endDate', action.payload.endDate);

      if (
        isBeforeOrEqual(action.payload.endDate, getNextDay(responsibilityToEnd.get('startDate'), 4))
      ) {
        updatedState = {
          responsibilities: state.responsibilities.delete(action.payload.responsibilityPermalink),
        };
        return loop(
          { ...state, ...updatedState },
          Cmd.run(removeResource, {
            args: [responsibilityToEnd.getIn(['$$meta', 'permalink'])],
            failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
          })
        );
      }
      updatedState = {
        responsibilities: state.responsibilities.set(
          action.payload.responsibilityPermalink,
          responsibilityToEnd
        ),
      };
      return loop(
        { ...state, ...updatedState },
        Cmd.run(storeResource, {
          args: [responsibilityToEnd.toJS()],
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.DELETE_MEMBER_RESPONSIBILITY: {
      updatedState = {
        responsibilities: state.responsibilities.delete(action.payload.responsibilityPermalink),
      };

      return loop(
        { ...state, ...updatedState },
        Cmd.run(removeResource, {
          args: [action.payload.responsibilityPermalink],
          failActionCreator: (error) => ACTIONS.groupActionFailed(error, state),
        })
      );
    }

    case ACTIONS.GROUP_ACTION_FAILED:
      state.errors.push(action.payload.error);
      return { ...state, errors: state.errors };

    case END_TEAM_MEMBERSHIP: {
      const { member } = action.payload;

      const allMemberResps = state.responsibilities.filter(
        (resp) => resp.getIn(['person', 'href']) === member.permalink
      );
      let newResponsibilities = state.responsibilities;
      allMemberResps.valueSeq().forEach((resp) => {
        const href = resp.getIn(['$$meta', 'permalink']);
        if (isBefore(action.payload.endDate, resp.get('endDate'))) {
          if (isBeforeOrEqual(action.payload.endDate, resp.get('startDate'))) {
            newResponsibilities = newResponsibilities.remove(href);
          } else {
            const responsibility = resp.set('endDate', action.payload.endDate);
            newResponsibilities = newResponsibilities.set(href, responsibility);
          }
        } else if (resp.get('endDate') === action.payload.oldEndDate) {
          const responsibility = resp.set('endDate', action.payload.endDate);
          newResponsibilities = newResponsibilities.set(href, responsibility);
        }
      });
      return { ...state, responsibilities: newResponsibilities };
    }

    case ACTIONS.TOGGLE_DELETED_GROUPS:
      return { ...state, showDeletedGroups: !state.showDeletedGroups };

    case ACTIONS.APPLY_FILTER:
      return { ...state, filter: action.payload };

    default:
      return state;
  }
};
