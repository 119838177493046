import React from 'react';
import { func, object, string } from 'prop-types';
import { AsyncKovResourcePicker, useLoadSriOptions } from '@kathondvla/react-shared-components/src';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import customStyle from './customStyle';

const OuWithAddressPicker = ({ path, parameters, onSelect, className }) => {
  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient,
    href: path,
    parameters,
  });

  const onChangeHandler = (option) => {
    if (option) {
      onSelect(option);
    }
  };

  const resourcePickerOptions = {
    className,
    onChange: (optionSelected) => onChangeHandler(optionSelected),
    placeholder: 'zoek op naam of op gemeente',
    clearable: false,
    menuShouldScrollIntoView: false,
    optionValue: (option) => option.key,
    optionTemplate: (option) => <CustomTemplate option={option} />,
    style: customStyle,
    loadOptions: sriLoadOptionsFn,
  };

  return <AsyncKovResourcePicker {...resourcePickerOptions} />;
};

// eslint-disable-next-line no-unused-vars
const CustomTemplate = ({ option }) => (
  <div className="picker-result author">
    <strong className="title">{option.name}</strong>
    {option.mailAddresses && option.mailAddresses.length > 0 && (
      <div className="subtitle" style={{ fontSize: '12px', marginTop: '-4px' }}>
        {option.mailAddresses[0].address.street} {option.mailAddresses[0].address.houseNumber}{' '}
        {', '}
        {option.mailAddresses[0].address.zipCode} {option.mailAddresses[0].address.subCity}
      </div>
    )}
    {!(option.mailAddresses && option.mailAddresses.length > 0) &&
      option.locations &&
      option.locations.map((location) => (
        <div
          key={location.permalink}
          className="subtitle"
          style={{ fontSize: '12px', marginTop: '-4px' }}
        >
          {location.address.street} {location.address.houseNumber} {', '}
          {location.address.zipCode} {location.address.subCity}
        </div>
      ))}
  </div>
);

OuWithAddressPicker.propTypes = {
  className: string,
  path: string,
  parameters: object,
  onSelect: func,
};

export default OuWithAddressPicker;
