import { Cmd, loop } from 'redux-loop';
import * as COMMANDS from 'ReduxLoop/newsletters/preferences/newsletterCommands';
import * as ACTIONS from 'ReduxLoop/newsletters/preferences/newsletterActions';
import { REGIONS, NEWSLETTER_TYPES, SECONDARY_EDUCATION } from 'ReduxLoop/constants';
import {
  createPreferencesBody,
  createUnsubscriptionBody,
  createKovNotificationsLinkScrolling,
} from 'ReduxLoop/utils/newsletterResourceFactory';
import {
  addSuccessNotification,
  addErrorNotification,
  clearAllNotifications,
} from 'ReduxLoop/notifications/notificationActions';
import { buildNewsletterPreferences } from '../newsletterUtils';
import * as ACTION_TYPES from '../../constants/actionTypes';
import * as PIWIK_ANALYTICS_ACTIONS from 'ReduxLoop/piwikAnalytics/piwikAnalyticsActions';

const cloneDeep = require('lodash.clonedeep');

const initialState = {
  storedNewslettersPreferences: [],
  loading: true,
  thematicNewsletterPreferences: {
    positions: [],
    coverage: cloneDeep(REGIONS),
    educationLevels: [],
    themes: [],
    newsletterType: {
      href: NEWSLETTER_TYPES.THEMATIC,
    },
    subscribed: false,
    subscribedJobOffers: true, // false,
  },
  subjectsNewsletterPreferences: {
    positions: [],
    coverage: cloneDeep(REGIONS),
    educationLevels: [],
    subjects: [],
    newsletterType: {
      href: NEWSLETTER_TYPES.SUBJECTS,
    },
    subscribed: false,
    subscribedJobOffers: true, // false,
  },
  showAllSelectedSubjects: false,
  showAllDeselectedSubjects: false,
  openUnsubscribeModal: null,
  subjectTextSearch: '',
  unsubscribeReasons: [],
  subjectsMap: {},
};

function validatePreferences(preferences) {
  const errors = [];
  if (!preferences.coverage.some((region) => region.checked)) {
    errors.push('Je moet min 1 regio selecteren.');
  }

  if (preferences.newsletterType.href === NEWSLETTER_TYPES.THEMATIC) {
    if (!preferences.positions.some((position) => position.checked)) {
      errors.push('Je moet min 1 functie selecteren.');
    }

    if (!preferences.themes.some((theme) => theme.checked)) {
      errors.push('Je moet min 1 kanaal selecteren.');
    }

    if (
      preferences.educationLevels &&
      !preferences.educationLevels.some((educationLevel) => educationLevel.checked)
    ) {
      errors.push('Je moet min 1 niveau selecteren.');
    }
  } else if (preferences.subjects && !preferences.subjects.some((subjects) => subjects.checked)) {
    errors.push('Je moet min 1 leerplan selecteren.');
  }

  return errors;
}

export const newsletterReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.NEWSLETTER_GET_OPTIONS: {
      const cmds = [
        Cmd.run(COMMANDS.getNewsletterOptions, {
          args: [action.payload],
          successActionCreator: ACTIONS.newsletterOptionsFecthed,
        }),
      ];
      return loop({ ...state }, Cmd.list(cmds));
    }

    case ACTION_TYPES.NEWSLETTER_OPTIONS_FETCHED: {
      return {
        ...state,
        thematicNewsletterPreferences: {
          ...state.thematicNewsletterPreferences,
          educationLevels: cloneDeep(action.payload.educationLevels),
          positions: cloneDeep(action.payload.positions),
          themes: cloneDeep(action.payload.themes),
        },
        subjectsNewsletterPreferences: {
          ...state.subjectsNewsletterPreferences,
          positions: cloneDeep(action.payload.positions),
          educationLevels: cloneDeep([
            {
              href: SECONDARY_EDUCATION,
              checked: true,
            },
          ]),
        },
        unsubscribeReasons: action.payload.unsubscribeReasons,
        subjectsMap: cloneDeep(action.payload.subjects),
      };
    }

    case ACTION_TYPES.NEWSLETTER_USER_PREFERENCES: {
      const cmds = [
        Cmd.run(COMMANDS.getUserNewsletterPreferences, {
          args: [rootState.userState],
          successActionCreator: ACTIONS.userNewsletterPreferencesFecthed,
        }),
      ];
      return loop({ ...state }, Cmd.list(cmds));
    }

    case ACTION_TYPES.NEWSLETTER_USER_PREFERENCES_FETCHED: {
      const storedNewslettersPreferences = action.payload;
      const newState = { ...state };
      const [thematicNewsletterPreferences, subjectsNewsletterPreferences] =
        buildNewsletterPreferences(
          storedNewslettersPreferences,
          newState.thematicNewsletterPreferences,
          newState.subjectsNewsletterPreferences
        );

      return {
        ...newState,
        storedNewslettersPreferences,
        thematicNewsletterPreferences,
        subjectsNewsletterPreferences,
        loading: false,
      };
    }

    case ACTION_TYPES.NEWSLETTER_SET_OPTION: {
      const newState = { ...state };
      const { newsletterType, id, value, optionType } = action.payload;

      const preferences =
        newsletterType === NEWSLETTER_TYPES.THEMATIC
          ? newState.thematicNewsletterPreferences
          : newState.subjectsNewsletterPreferences;

      const options = {
        REGIONS: preferences.coverage,
        EDUCATION_LEVELS: preferences.educationLevels,
        POSITIONS: preferences.positions,
        THEMES: preferences.themes,
        SUBJECTS: preferences.subjects,
      };

      if (options[optionType]) {
        if (options[optionType].findIndex((item) => item.href === id) === -1) {
          options[optionType].push({
            href: id,
            checked: value,
            locked: false,
          });
        }

        options[optionType].forEach((item) => {
          if (item.href === id || item.name === id) {
            item.checked = value;
          }
        });
      } else {
        switch (optionType) {
          case 'JOBS_OFFERING':
            preferences.subscribedJobOffers = value;
            break;
          case 'SUBSCRIPTION':
            preferences.subscribed = value;
            break;
          default:
            break;
        }
      }

      if (optionType === 'SUBSCRIPTION' && !value) {
        const storedPreferences = state.storedNewslettersPreferences.find(
          (s) => s.newsletterType.href === newsletterType
        );
        if (storedPreferences && storedPreferences.subscribed) {
          newState.openUnsubscribeModal = newsletterType;
        }
      }

      const cmds = [
        Cmd.run(COMMANDS.debounceNewsletterChanges, {
          successActionCreator: () => ACTIONS.persistNewsletterChanges(newsletterType),
        }),
      ];

      if (optionType === 'SUBSCRIPTION') {
        cmds.push(
          Cmd.action(
            PIWIK_ANALYTICS_ACTIONS.trackPiwikEvent('update_newsletter', {
              subscription_name:
                newsletterType === NEWSLETTER_TYPES.THEMATIC ? 'general' : 'curricula',
              subscription_action: value ? 'subscribe' : 'unsubscribe',
            })
          )
        );
      }

      if (
        optionType === 'SUBSCRIPTION' &&
        !value &&
        newsletterType === NEWSLETTER_TYPES.SUBJECTS &&
        state.thematicNewsletterPreferences.subscribed
      ) {
        console.log(
          'Subjects newsletter is disabled while thematic newsletter was disabled which is not stored yet because form was invalid (not subject selected)'
        );
        const storedThematicNewsletterPreferences = state.storedNewslettersPreferences.find(
          (s) => s.newsletterType.href === NEWSLETTER_TYPES.THEMATIC
        );
        if (
          !storedThematicNewsletterPreferences ||
          !storedThematicNewsletterPreferences.subscribed
        ) {
          cmds.push(
            Cmd.run(COMMANDS.debounceNewsletterChanges, {
              successActionCreator: () =>
                ACTIONS.persistNewsletterChanges(NEWSLETTER_TYPES.THEMATIC),
            })
          );
        }
      }

      return loop({ ...newState }, Cmd.list(cmds));
    }

    case ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES: {
      const targetPreferences =
        NEWSLETTER_TYPES.THEMATIC === action.payload.newsletterType
          ? state.thematicNewsletterPreferences
          : state.subjectsNewsletterPreferences;
      const storedPreferences = state.storedNewslettersPreferences.find(
        (newsletter) => newsletter.newsletterType.href === action.payload.newsletterType
      );
      const cmdList = [Cmd.action(clearAllNotifications())];

      if (targetPreferences.subscribed) {
        const validationErrors = validatePreferences(targetPreferences);

        if (validationErrors.length > 0) {
          const isGeneralNewsletter = NEWSLETTER_TYPES.THEMATIC === action.payload.newsletterType;
          const kovNotificationsLink = 'kov-notifications-link';
          cmdList.push(
            Cmd.action(
              addErrorNotification(
                // kov-notifications message supports ng-bind-html, so here we build a string
                // that handles all the required html & scrolling functionality for this case.
                `${validationErrors.join(
                  ' '
                )} Of voor de <span class="${kovNotificationsLink}" onclick="${createKovNotificationsLinkScrolling(
                  kovNotificationsLink
                )}">${
                  isGeneralNewsletter ? 'algemene nieuwsbrief' : 'leerplannennieuwsbrief'
                } uitschrijven</span>.`
              )
            )
          );
          return loop({ ...state }, Cmd.list(cmdList));
        }
      }

      const user = rootState.userState.user.key;
      const preferencesBody = createPreferencesBody(targetPreferences, storedPreferences, user);

      if (preferencesBody) {
        cmdList.push(
          Cmd.run(COMMANDS.persistNewsletterChanges, {
            args: [preferencesBody],
            successActionCreator: () => ACTIONS.persistNewsletterChangesSucceeded(preferencesBody),
            failActionCreator: ACTIONS.persistNewsletterChangesFailed,
          })
        );
      }

      return loop({ ...state }, Cmd.list(cmdList));
    }

    case ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES_FAILED: {
      const newState = { ...state };
      const [thematicNewsletterPreferences, subjectsNewsletterPreferences] =
        buildNewsletterPreferences(
          newState.storedNewslettersPreferences,
          newState.thematicNewsletterPreferences,
          newState.subjectsNewsletterPreferences
        );

      return {
        ...newState,
        thematicNewsletterPreferences,
        subjectsNewsletterPreferences,
      };
    }

    case ACTION_TYPES.NEWSLETTER_PERSIST_CHANGES_SUCCEEDED: {
      const newState = { ...state };
      const updatedPreferences = action.payload;

      return loop(
        {
          ...newState,
          subjectTextSearch: state.subjectsNewsletterPreferences.subjects.every(
            (subject) => subject.checked
          )
            ? ''
            : state.subjectTextSearch,
          storedNewslettersPreferences: [
            ...newState.storedNewslettersPreferences.filter(
              (preferences) => preferences.key !== updatedPreferences.key
            ),
            updatedPreferences,
          ],
        },
        Cmd.action(addSuccessNotification('Jouw voorkeuren zijn opgeslagen.'))
      );
    }

    case ACTION_TYPES.NEWSLETTER_SET_SUBSCRIPTIONS: {
      const newState = { ...state };
      newState.thematicNewsletterPreferences.subscribed = action.payload.value;
      newState.subjectsNewsletterPreferences.subscribed = action.payload.value;

      const cmds = [
        Cmd.run(COMMANDS.debounceNewsletterChanges, {
          successActionCreator: ACTIONS.persistNewslettersSubscriptions,
        }),
        Cmd.action(
          PIWIK_ANALYTICS_ACTIONS.trackPiwikEvent('update_newsletter', {
            subscription_name: 'all',
            subscription_action: action.payload.value ? 'subscribe' : 'unsubscribe',
          })
        ),
      ];

      return loop({ ...newState }, Cmd.list(cmds));
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS: {
      const newState = { ...state };

      if (
        state.thematicNewsletterPreferences.subscribed &&
        state.subjectsNewsletterPreferences.subscribed
      ) {
        const thematicNewsletterPreferenceValidationErrors = validatePreferences(
          state.thematicNewsletterPreferences
        );
        const subjectsNewsletterPreferenceValidationErrors = validatePreferences(
          state.subjectsNewsletterPreferences
        );

        if (
          thematicNewsletterPreferenceValidationErrors.length > 0 ||
          subjectsNewsletterPreferenceValidationErrors.length > 0
        ) {
          const cmdList = [Cmd.action(clearAllNotifications())];

          /* if (thematicNewsletterPreferenceValidationErrors.length > 0) {
            cmdList.push(
              Cmd.action(
                addErrorNotification(
                  `Themanieuwsbrief: ${thematicNewsletterPreferenceValidationErrors.join(' ')}`
                )
              )
            );
          } */

          if (subjectsNewsletterPreferenceValidationErrors.length > 0) {
            cmdList.push(
              Cmd.action(
                addErrorNotification(
                  `Vakkennieuwsbrief: ${subjectsNewsletterPreferenceValidationErrors.join(' ')}`
                )
              )
            );
          }

          return loop({ ...state }, Cmd.list(cmdList));
        }
      }

      const user = rootState.userState.user.key;
      const storedThematicPreferences = state.storedNewslettersPreferences.find(
        (newsletter) => newsletter.newsletterType.href === NEWSLETTER_TYPES.THEMATIC
      );
      const storedSubjectsPreferences = state.storedNewslettersPreferences.find(
        (newsletter) => newsletter.newsletterType.href === NEWSLETTER_TYPES.SUBJECTS
      );

      const thematicNewsletterPreferences = createPreferencesBody(
        state.thematicNewsletterPreferences,
        storedThematicPreferences,
        user
      );
      const subjectsNewsletterPreferences = createPreferencesBody(
        state.subjectsNewsletterPreferences,
        storedSubjectsPreferences,
        user
      );

      return loop(
        { ...newState },
        Cmd.run(COMMANDS.persistNewsletterSubscriptions, {
          args: [[thematicNewsletterPreferences, subjectsNewsletterPreferences]],
          successActionCreator: ACTIONS.persistNewslettersSubscriptionsSucceeded,
          failActionCreator: ACTIONS.persistNewslettersSubscriptionsFailed,
        })
      );
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS_SUCCEEDED: {
      const newState = { ...state };
      newState.storedNewslettersPreferences.forEach((storedPreferences) => {
        storedPreferences.subscribed = !storedPreferences.subscribed;
      });
      return newState;
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_SUBSCRIPTIONS_FAILED: {
      const newState = { ...state };
      newState.thematicNewsletterPreferences.subscribed =
        !newState.thematicNewsletterPreferences.subscribed;
      newState.subjectsNewsletterPreferences.subscribed =
        !newState.subjectsNewsletterPreferences.subscribed;
      return newState;
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION: {
      const newState = { ...state };
      const user = rootState.userState.user.key;
      const storedThematicPreferences = state.storedNewslettersPreferences.find(
        (newsletter) => newsletter.newsletterType.href === NEWSLETTER_TYPES.THEMATIC
      );
      const storedSubjectsPreferences = state.storedNewslettersPreferences.find(
        (newsletter) => newsletter.newsletterType.href === NEWSLETTER_TYPES.SUBJECTS
      );

      const preferences = [];

      if (action.payload.newsletterTypes.includes(NEWSLETTER_TYPES.THEMATIC)) {
        preferences.push(
          createPreferencesBody(
            state.thematicNewsletterPreferences,
            storedThematicPreferences,
            user
          )
        );
      }

      if (action.payload.newsletterTypes.includes(NEWSLETTER_TYPES.SUBJECTS)) {
        preferences.push(
          createPreferencesBody(
            state.subjectsNewsletterPreferences,
            storedSubjectsPreferences,
            user
          )
        );
      }

      return loop(
        { ...newState },
        Cmd.run(COMMANDS.persistNewsletterUnsubscription, {
          args: [preferences],
          failActionCreator: (error) =>
            ACTIONS.persistNewslettersUnsubscriptionFailed(error, action.payload),
        })
      );
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS: {
      const user = rootState.userState.user.key;
      const unsubscriptionReasons = createUnsubscriptionBody(action.payload, user);

      return loop(
        { ...state },
        Cmd.run(COMMANDS.persistNewsletterUnsubscriptionReasons, {
          args: [unsubscriptionReasons],
          failActionCreator: (error) =>
            ACTIONS.persistNewslettersUnsubscriptionReasonsFailed(error, action.payload),
        })
      );
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_FAILED: {
      const { unsubscription } = action.payload;

      if (unsubscription.newsletterTypes.includes(NEWSLETTER_TYPES.THEMATIC)) {
        state.thematicNewsletterPreferences.subscribed = true;
      }

      if (unsubscription.newsletterTypes.includes(NEWSLETTER_TYPES.SUBJECTS)) {
        state.subjectsNewsletterPreferences.subscribed = true;
      }

      return loop(
        { ...state, errors: state.errors },
        Cmd.list([
          Cmd.action(clearAllNotifications()),
          Cmd.action(addErrorNotification('Er is een fout opgetreden tijdens het uitschrijven')),
        ])
      );
    }

    case ACTION_TYPES.PERSIST_NEWSLETTERS_UNSUBSCRIPTION_REASONS_FAILED: {
      return loop(
        {
          ...state,
          errors: state.errors,
        },
        Cmd.list([
          Cmd.action(clearAllNotifications()),
          Cmd.action(addErrorNotification('Er is een fout opgetreden tijdens het uitschrijven')),
        ])
      );
    }

    case ACTION_TYPES.NEWSLETTER_SHOW_ALL_SELECTED_SUBJECTS: {
      return {
        ...state,
        showAllSelectedSubjects: true,
      };
    }

    case ACTION_TYPES.NEWSLETTER_SHOW_ALL_DESELECTED_SUBJECTS: {
      return {
        ...state,
        showAllDeselectedSubjects: true,
      };
    }

    case ACTION_TYPES.NEWSLETTER_SHOW_LESS_SELECTED_SUBJECTS: {
      return {
        ...state,
        showAllSelectedSubjects: false,
      };
    }

    case ACTION_TYPES.NEWSLETTER_SHOW_LESS_DESELECTED_SUBJECTS: {
      return {
        ...state,
        showAllDeselectedSubjects: false,
      };
    }

    case ACTION_TYPES.NEWSLETTER_FILTER_DESELECTED_SUBJECTS: {
      return {
        ...state,
        subjectTextSearch: action.payload,
      };
    }

    case ACTION_TYPES.UNSUBSCRIBE_MODEL_OPENED: {
      return { ...state, openUnsubscribeModal: null };
    }

    default:
      return state;
  }
};
