import { loop, Cmd } from 'redux-loop';
import { getNow } from '@kathondvla/sri-client/date-utils';
import {
  addErrorNotification,
  addWarningNotification,
  clearAllNotifications,
} from 'ReduxLoop/notifications/notificationActions';
import { goTo } from 'ReduxLoop/utils';
import {
  persistNewTeam,
  validateOrganisation,
  validateWorkgroup,
} from 'ReduxLoop/ouManager/createTeamWizard/createTeamWizardDataAccess';
import { fetchUser } from 'ReduxLoop/user/userActions';
import * as ACTIONS from './createTeamWizardActions';

const possibleTypes = ['VERGADERGROEP', 'LEERPLANCOMMISSIE'];
const initialState = {
  currentStep: 1,
  possibleTypes,
  name: '',
  shortName: '',
  code: null,
  codeRaw: '',
  description: undefined,
  selectedType: possibleTypes[0],
  startDate: getNow(),
  nameErrors: [],
  nameWarnings: [],
  shortNameErrors: [],
  descriptionErrors: [{ code: 'description.required', message: 'De beschrijving is verplicht' }],
  isFormValid: true,
  submitAttempted: false,
  persistingNewTeam: false,
  duplicateSuggestion: null,
  duplicateVoorziterResponsibilities: null,
  organisation: null,
  workgroup: null,
  shortNamePattern: {
    pattern: /^[A-Za-z0-9\-_\sàéèëêïöü&()+]+$/,
    message:
      'Een naam mag enkel letters, cijfers, spaties, haakjes, koppeltekens, ampersands, plustekens en underscores bevatten.',
  },
  validateWorkgroupRequired: false,
};

export const createTeamWizardReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTIONS.START_CREATE_TEAM_WIZARD: {
      return loop(initialState, Cmd.action(fetchUser()));
    }

    case ACTIONS.GO_TO_NEXT_STEP_IN_CREATE_TEAM_WIZARD: {
      if (
        state.currentStep === 2 &&
        (!state.isFormValid ||
          state.descriptionErrors.length > 0 ||
          state.nameErrors.length > 0 ||
          state.shortNameErrors.length > 0)
      ) {
        return loop(
          { ...state, submitAttempted: true },
          Cmd.action(
            addErrorNotification(
              'Niet alle velden zijn correct ingevuld. Kijk de gemarkeerde velden na.'
            )
          )
        );
      }
      if (state.currentStep === 3) {
        return loop(
          { ...state, persistingNewTeam: true },
          Cmd.run(persistNewTeam, {
            args: [state, rootState],
            successActionCreator: ACTIONS.persistNewTeamSuccess,
            failActionCreator: ACTIONS.persistNewTeamFailed,
          })
        );
      }
      if (state.currentStep === 4) {
        return loop(
          state,
          Cmd.run(goTo, {
            args: [`/#!/team/${state.organisation.key}/team`],
          })
        );
      }
      const newState = { ...state, currentStep: state.currentStep + 1 };
      return loop(newState, Cmd.action(clearAllNotifications()));
    }

    case ACTIONS.VALIDATE_ORGANISATION_SUCCESS: {
      const newState = {
        ...state,
        nameErrors: [],
        shortNameErrors: [],
        nameWarnings: [],
        duplicateSuggestion: null,
      };
      if (!action.payload || !action.payload.organisation) {
        return newState;
      }
      return loop(
        {
          ...newState,
          organisation: action.payload.organisation,
          workgroup: action.payload.workgroup,
          selectedType: action.payload.organisation.type,
        },
        Cmd.list([
          Cmd.action(
            ACTIONS.changeShortName(
              state.shortName || action.payload.organisation.details[0].shortName
            )
          ),
          Cmd.action(
            ACTIONS.changeDescription(state.description || action.payload.organisation.description)
          ),
        ])
      );
    }

    case ACTIONS.VALIDATE_ORGANISATION_FAILED: {
      const newState = {
        ...state,
        nameErrors: [],
        shortNameErrors: [],
        nameWarnings: [],
        duplicateSuggestion: action.payload.duplicate,
        duplicateVoorziterResponsibilities: action.payload.duplicateVoorziterResponsibilities,
      };
      if (action.payload.code === 'name.not.unique') {
        newState.nameErrors = [
          {
            code: 'name.not.unique',
            message: 'Er is al een team met deze naam. De naam moet uniek zijn.',
          },
        ];
        return newState;
      }
      if (action.payload.code === 'shortname.not.unique') {
        newState.shortNameErrors = [
          {
            code: 'shortname.not.unique',
            message: 'Er is al een team met dezelfde korte naam. De korte naam moet uniek zijn.',
          },
        ];
        return newState;
      }
      if (action.payload.code === 'similar.organisation') {
        newState.nameWarnings = [
          { code: 'similar.name', message: 'Er is een team met een gelijkaardige naam' },
        ];
        return newState;
      }
      return loop(
        state,
        Cmd.action(
          addWarningNotification(
            'Er is een onverwachte fout opgetreden bij het valideren van het nieuwe team.'
          )
        )
      );
    }

    case ACTIONS.VALIDATE_WORKGROUP_SUCCESS: {
      return { ...state, code: state.codeRaw };
    }

    case ACTIONS.VALIDATE_WORKGROUP_FAILED: {
      console.log('payload', action.payload);
      if (action.payload.code && action.payload.code === 'code.not.unique') {
        return { ...state, code: action.payload.suggestedCode };
      }
      return loop(
        state,
        Cmd.action(
          addWarningNotification(
            'Er is een onverwacte fout opgetreden bij het genereren van de URL voor het team'
          )
        )
      );
    }

    case ACTIONS.PERSIST_NEW_TEAM_SUCCESS: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
        persistingNewTeam: false,
        organisation: action.payload.organisation,
        workgroup: action.payload.workgroup,
        notifyDepartmentFailed: action.payload.notifyDepartmentFailed,
      };
    }

    case ACTIONS.PERSIST_NEW_TEAM_FAILED: {
      console.error('error on persist team', action.payload.error);
      return loop(
        { ...state, persistingNewTeam: false, organisation: action.payload.organisation },
        Cmd.action(
          addErrorNotification(
            'Er is een onverwachte fout opgetreden bij het aanmaken van het nieuwe team.'
          )
        )
      );
    }

    case ACTIONS.GO_TO_PREVIOUS_STEP_IN_CREATE_TEAM_WIZARD: {
      return { ...state, currentStep: state.currentStep - 1 };
    }

    case ACTIONS.SET_CREATE_TEAM_FORM_VALIDATION: {
      return { ...state, isFormValid: action.payload };
    }

    case ACTIONS.CHANGE_NAME: {
      if (action.payload === state.name) {
        return state;
      }
      const cmdList = [];
      const newState = {
        ...state,
        name: action.payload,
        nameErrors: [],
        nameWarnings: [],
        duplicateSuggestion: null,
      };
      if (!state.isFormValid) {
        return newState;
      }
      if (state.validateWorkgroupRequired) {
        newState.validateWorkgroupRequired = false;
        cmdList.push(
          Cmd.run(validateWorkgroup, {
            args: [newState],
            successActionCreator: ACTIONS.validateWorkgroupSuccess,
            failActionCreator: ACTIONS.validateWorkgroupFailed,
          })
        );
      }
      cmdList.push(
        Cmd.run(validateOrganisation, {
          args: [newState],
          successActionCreator: ACTIONS.validateOrganisationSuccess,
          failActionCreator: ACTIONS.validateOrganisationFailed,
        })
      );
      return loop(newState, Cmd.list(cmdList));
    }

    case ACTIONS.CHANGE_SHORT_NAME: {
      if (action.payload === state.shortName) {
        return state;
      }
      const cmdList = [];
      const codeRaw = action.payload
        .replaceAll(/[()&+]/g, '')
        .replaceAll(/[ëéèê]/g, 'e')
        .replaceAll(/[àä]/g, 'a')
        .replaceAll(/[ï]/g, 'i')
        .replaceAll(/[ö]/g, 'o')
        .replaceAll(/[ü]/g, 'u')
        .split(/[\s\-_]/)
        .reduce((acc, cur) => acc + cur.substring(0, 2), '');
      const newState = { ...state, shortName: action.payload, codeRaw };
      if (!state.isFormValid) {
        if (codeRaw.toLowerCase() !== state.codeRaw.toLowerCase()) {
          newState.code = codeRaw;
          newState.validateWorkgroupRequired = true;
        }
        return newState;
      }
      if (codeRaw.toLowerCase() !== state.codeRaw.toLowerCase()) {
        newState.code = codeRaw;
        cmdList.push(
          Cmd.run(validateWorkgroup, {
            args: [newState],
            successActionCreator: ACTIONS.validateWorkgroupSuccess,
            failActionCreator: ACTIONS.validateWorkgroupFailed,
          })
        );
      }
      cmdList.push(
        Cmd.run(validateOrganisation, {
          args: [newState],
          successActionCreator: ACTIONS.validateOrganisationSuccess,
          failActionCreator: ACTIONS.validateOrganisationFailed,
        })
      );
      return loop(newState, Cmd.list(cmdList));
    }

    case ACTIONS.CHANGE_DESCRIPTION: {
      const newState = { ...state, description: action.payload };
      if (!action.payload || action.payload.length === 0) {
        newState.descriptionErrors = [
          { code: 'description.required', message: 'De beschrijving is verplicht' },
        ];
      } else if (action.payload.trim().length < 140) {
        newState.descriptionErrors = [
          {
            message: `De beschrijving moet minimum 140 tekens lang zijn. Huidig aantal tekens: ${action.payload.length}`,
          },
        ];
      } else if (action.payload.trim().length > 2048) {
        newState.descriptionErrors = [
          {
            message: `De beschrijving mag maximum 2000 tekens lang zijn. Huidig aantal tekens: ${action.payload.length}`,
          },
        ];
      } else {
        newState.descriptionErrors = [];
      }
      return newState;
    }

    case ACTIONS.CHANGE_START_DATE: {
      const newState = { ...state, startDate: action.payload };
      return newState;
    }

    case ACTIONS.CHANGE_TYPE: {
      return { ...state, selectedType: action.payload };
    }

    case ACTIONS.REQUEST_TO_JOIN_SUGGESTED_TEAM: {
      return loop(state, Cmd.run(goTo, { args: [`/#!/aanvraag-team?ou=${action.payload}`] }));
    }

    default:
      return state;
  }
};
