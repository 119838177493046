import { BESTUURDER, LID_VAN_HET_DAGELIJKS_BESTUUR } from 'ReduxLoop/constants';

require('./myMemberList.scss');

const dateUtils = require('@kathondvla/sri-client/date-utils');
const invitedMemberIcon = require('Img/icon_invited_member.svg');
const bouncedEmailIcon = require('Img/icon_bounced_email.svg');
const requestedMemberIcon = require('Img/icon_requested_member.svg');
const confirmedMemberIcon = require('Img/icon_confirmed_member.svg');
const confirmedMemberIconFaded = require('Img/user-grey-light.svg');

export class MyMemberList {
  constructor($scope, $document) {
    'ngInject';

    this.$scope = $scope;
    this.$document = $document;
    this.BESTUURDER = BESTUURDER;
    this.LID_VAN_HET_DAGELIJKS_BESTUUR = LID_VAN_HET_DAGELIJKS_BESTUUR;
    this.positionHelpIsOpen = {};
    this.isNotActive = function (member) {
      return dateUtils.isBeforeOrEqual(member.endDate, dateUtils.getNow());
    };
  }

  onClick(member, dropdownOption) {
    this.$scope.$emit(dropdownOption.event, member, dropdownOption.eventArgs);
  }

  getIcons(member) {
    const icons = [];

    if (member.type === 'INVITATION') {
      icons.push(invitedMemberIcon);

      if (member.email.bounced) {
        icons.push(bouncedEmailIcon);
      }
    } else if (member.type === 'REQUEST') {
      icons.push(requestedMemberIcon);
    } else if (this.isNotActive(member)) {
      icons.push(confirmedMemberIconFaded);
    } else {
      icons.push(confirmedMemberIcon);
    }

    return icons;
  }

  closeAllPopovers() {
    this.sMembers.forEach((member) => {
      member.positions.forEach((position) => {
        this.positionHelpIsOpen[position.responsibilityHref || position.invitationHref] = false;
      });
    });
  }

  openPositionPopover(position) {
    this.closeAllPopovers();
    this.positionHelpIsOpen[position.responsibilityHref || position.invitationHref] = true;
  }

  closePositionPopover() {
    /* const closeTimeout = setTimeout(() => {
      this.$scope.$applyAsync(() => {

      });
    }, 1); */
    this.closeAllPopovers();

    /* $('mml-member-list > .popover').mouseenter(() => {
      this.$scope.$applyAsync(() => {
        // clearTimeout(closeTimeout);
        this.openPositionPopover(position);
      });
    }).mouseleave(() => {
      this.$scope.$applyAsync(() => {
        this.closeAllPopovers();
      });
    }); */
  }

  getPositionPeriod(position) {
    if (!position.startDate) {
      console.log('position without startDate', position);
    }
    return dateUtils.printFutureForPeriodic(position).replace(/[()]/g, '').trim();
  }

  getEmailColumn(member) {
    let emailColumn;
    if (member.type === 'INVITATION') {
      if (member.errors && member.errors.length > 0) {
        if (!member.errors.some((error) => error.status !== 403)) {
          emailColumn =
            '<span class="font-color-red">Je hebt geen toegansrechten om uitnodigingen te sturen.</span>';
        } else {
          emailColumn =
            '<span class="font-color-red">Er is een onverwachte fout opgetreden bij het aanmaken van de uitnodiging.</span>';
        }
      } else if (member.email.bounced) {
        emailColumn =
          '<span class="font-color-red">Deze uitnodiging is <strong>niet</strong> aangekomen! Controleer het e-mailadres.</span>';
      } else if (member.email.sentOn) {
        emailColumn = `<strong>Uitnodiging verstuurd op ${dateUtils.printDate(
          member.email.sentOn
        )}. Nog niet bevestigd.</strong>`;
      } else {
        emailColumn = '<strong>Uitnodiging verstuurd. Nog niet bevestigd.</strong>';
      }
    }

    if (member.type === 'REQUEST' && member.approveError) {
      emailColumn = `<span class="font-color-red">${member.approveError}</span>`;
    }

    return emailColumn || member.emailAddress;
  }
}
