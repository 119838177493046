import { fetchUser } from 'ReduxLoop/user/userActions';
import * as ACTIONS from 'ReduxLoop/newsletters/preferences/newsletterActions';
import { removeNotification } from 'ReduxLoop/notifications/notificationActions';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { NEWSLETTER_TYPES } from 'ReduxLoop/constants';

export class myNewsletterPreferences {
  constructor($scope, $ngRedux, settings, $state, $stateParams, $timeout, $uibModal) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$ngRedux = $ngRedux;
    this.settings = settings;
    this.sriClient = sriClient;
    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.pencilIcon = require('Img/edit.svg');
    this.mailIcon = require('Img/mail.svg');
    this.inschrijven = 'THEMANIEUWSBRIEF';
    this.NEWSLETTER_TYPES = NEWSLETTER_TYPES;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (state.newsletters.openUnsubscribeModal) {
        this.$ngRedux.dispatch(ACTIONS.unsubscribeModalOpened());
        this.openUnsubscribeModal(state.newsletters.openUnsubscribeModal);
      }
      return {
        newsletters: state.vm.newsletters,
        notificationList: state.vm.notifications,
        loading: state.vm.newsletters.loading,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onInit() {
    this.$ngRedux.dispatch(ACTIONS.getNewsletterOptions()).then(() => {
      this.$ngRedux.dispatch(fetchUser()).then(() => {
        this.$ngRedux.dispatch(ACTIONS.getUserNewsletterPreferences());
      });
    });
  }

  removeNotification(ev, notificationKey) {
    this.$ngRedux.dispatch(removeNotification(notificationKey));
  }

  goToHome() {
    this.$state.go('home');
  }

  thematicSubscriptionChanged(e, selected) {
    this.$ngRedux.dispatch(
      ACTIONS.newsletterSetOption(selected, NEWSLETTER_TYPES.THEMATIC, null, 'SUBSCRIPTION', null)
    );
  }

  subjectsSubscriptionChanged(e, selected) {
    this.$ngRedux.dispatch(
      ACTIONS.newsletterSetOption(selected, NEWSLETTER_TYPES.SUBJECTS, null, 'SUBSCRIPTION', null)
    );
  }

  optionChanged(e, newsletterType, selected, id, optionType, property) {
    this.$ngRedux.dispatch(
      ACTIONS.newsletterSetOption(selected, newsletterType, id, optionType, property)
    );
  }

  subscriptionChanged(selected) {
    this.$ngRedux.dispatch(ACTIONS.newsletterSetSubscriptions(selected)).then(() => {
      if (!selected) {
        this.openUnsubscribeModal();
      }
    });
  }

  showAllSelectedSubjects(e) {
    this.$ngRedux.dispatch(ACTIONS.newsletterShowAllSelectedSujects());
  }

  showAllDeselectedSubjects(e) {
    this.$ngRedux.dispatch(ACTIONS.newsletterShowAllDeselectedSujects());
  }

  showLessSelectedSubjects(e) {
    this.$ngRedux.dispatch(ACTIONS.newsletterShowLessSelectedSujects());
  }

  showLessDeselectedSubjects(e) {
    this.$ngRedux.dispatch(ACTIONS.newsletterShowLessDeselectedSujects());
  }

  filterDeselectedSubjects(e, text) {
    this.$ngRedux.dispatch(ACTIONS.newsletterFilterDeselectedSujects(text));
  }

  openUnsubscribeModal(newsletterType) {
    this.unsubscribeModal = this.$uibModal.open({
      animation: true,
      component: 'myUnsubscribeModal',
      size: 'lg',
      backdrop: 'static',
      resolve: {
        reasons: () => this.newsletters.unsubscribeReasons,
      },
    });

    this.unsubscribeModal.result.then((result) => {
      const newsletterTypes = [];

      if (newsletterType === NEWSLETTER_TYPES.THEMATIC) {
        newsletterTypes.push(NEWSLETTER_TYPES.THEMATIC);
      } else if (newsletterType === NEWSLETTER_TYPES.SUBJECTS) {
        newsletterTypes.push(NEWSLETTER_TYPES.SUBJECTS);
      } else {
        newsletterTypes.push(NEWSLETTER_TYPES.THEMATIC);
        newsletterTypes.push(NEWSLETTER_TYPES.SUBJECTS);
      }

      this.$ngRedux.dispatch(
        ACTIONS.persistNewslettersUnsubscriptionReasons(
          newsletterTypes,
          result.reasons,
          result.customReason
        )
      );
    });
  }
}
