// global navigator, fetch
import { createAppStore } from 'ReduxLoop/store';
// import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import { settings } from 'Js/config/settings';

const isAlreadyLoaded = document.getElementsByTagName('body')[0].classList.contains('loaded');
if (isAlreadyLoaded) {
  console.warn(
    'The application was already loaded!!! We need to prevent the index file from running again'
  );
}

navigator.sayswho = (function () {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem !== null) {
      // return tem.slice(1).join(' ').replace('OPR', 'Opera');
      tem = tem.slice(1);
      return {
        browser: tem[0].replace('OPR', 'Opera'),
        version: tem[1],
      };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) { // eslint-disable-line
    M.splice(1, 1, tem[1]);
  }
  return {
    browser: M[0],
    version: M[1],
  };
})();

// BOOTSTRAP (kind of ugly, because it expects jQuery)
window.jQuery = require('jquery');

window.$ = window.jQuery;
require('../module/bootstrap');

require('../../css');

// Object.assign(window, {moment: require('moment')});

let angular = require('angular'); // eslint-disable-line
// require('./kovSentry');
// window.angular = angular;
export const app = require('./app');

require('../myScreenComponents');
require('../module/ngReactMigrationUtils/sharedReactComponentsBridges');

app.config(($ngReduxProvider) => {
  $ngReduxProvider.provideStore(createAppStore());
});

const LogRocket = require('logrocket');

app.constant('LogRocket', LogRocket);

if (settings.logRocket.enabled === true) {
  LogRocket.init(settings.logRocket.token);
}

// console.log(navigator.sayswho);

if (!window.fetch) {
  console.warn(
    `NO_FETCH: window.fetch is not defined!!!!!!!!!!!!!!!!!!!!!!!!! in ${navigator.sayswho.browser} ${navigator.sayswho.version}`
  );
}

app.config(require('./config')).config(function ($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript|data):/);
});

app.config([
  '$sceDelegateProvider',
  function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'data:**',
      'https://*.katholiekonderwijs.vlaanderen/**',
    ]);
  },
]);

app.directive('onScrollToBottom', function () {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const raw = element[0];
      element.bind('scroll', function () {
        // console.log('in scroll');
        // console.log(raw.scrollTop + raw.offsetHeight);
        // console.log(raw.scrollHeight);
        if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight - 1) {
          // console.log('I am at the bottom');
          scope.$apply(attrs.onScrollToBottom);
        }
      });
    },
  };
});

// app.value('$routerRootComponent', 'appRoot');

// also add the window.settings as a constant to our angular module
app.constant('settings', settings);

let bootstraped = false;

app.run([
  'settings',
  '$ngRedux',
  function (settings, $ngRedux) {
    console.log('app run with settings', settings);
    if (false && settings.logging.logentries.enabled) {
      let username = 'unknown';
      let key = null;
      $ngRedux.subscribe(() => {
        const store = $ngRedux.getState();
        if (store && store.vm && store.vm.user) {
          key = store.vm.user.key;
          username = store.vm.user.username ? store.vm.user.username : username;
        }
        // if (Raven) {
        //   Raven.setUserContext({
        //     id: username,
        //     key: key
        //   });
        // }
      });
      require('console.logentries')(
        settings.logging.logentries.token,
        settings.logging.logentries.debug,
        () => `USERNAME=${username}`
      );
    }
  },
]);

angular.bootstrap(document, ['Mijn2']);
bootstraped = true;

/* if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(

    function (registrations) {
      registrations.forEach(registration => {
        registration.unregister();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    }
  );
} */
