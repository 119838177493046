import { Cmd, loop } from 'redux-loop';
import * as COMMANDS from 'ReduxLoop/newsletters/preferences/newsletterCommands';
import {
  newsletterUnsubscribeCmd,
  newsletterSetUnsubscribeReasonsCmd,
} from 'ReduxLoop/newsletters/unsubscription/newsletterUnsubscriptionCommands';
import * as ACTIONS from 'ReduxLoop/newsletters/unsubscription/newsletterUnsubscriptionActions';
import * as ACTION_TYPES from '../../constants/actionTypes';

const initialState = {
  loading: true,
  unsubscribeReasons: [],
  showUnsubscriptionFeedbackForm: false,
  feedbackSavedSuccessfully: false,
};

export const newsletterUnsubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.NEWSLETTER_GET_UNSUBSCRIBE_REASONS_FROM_EXTERNAL_LINK: {
      const cmds = [
        Cmd.run(COMMANDS.getUnsubscribeReasons, {
          args: [action.payload],
          successActionCreator: ACTIONS.newsletterUnsubcribeReasonsFecthed,
        }),
      ];
      return loop({ ...state }, Cmd.list(cmds));
    }

    case ACTION_TYPES.NEWSLETTER_OPTIONS_FETCHED_FROM_EXTERNAL_LINK: {
      return {
        ...state,
        unsubscribeReasons: action.payload,
        loading: false,
      };
    }

    case ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_FROM_EXTERNAL_LINK: {
      const secret = action.payload;
      const newsletterSubscriptionKeys = atob(secret).split(',');

      return loop(
        { ...state },
        Cmd.run(newsletterUnsubscribeCmd, {
          args: [newsletterSubscriptionKeys],
          successActionCreator: () => ACTIONS.newsletterUnsubscriptionSucceeded(),
          failActionCreator: (error) => ACTIONS.newsletterUnsubscriptionFailed(error),
        })
      );
    }

    case ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_SUCCEEDED_FROM_EXTERNAL_LINK: {
      return { ...state, unsubscriptionSuccessed: true, showUnsubscriptionFeedbackForm: true };
    }

    case ACTION_TYPES.NEWSLETTERS_UNSUBSCRIBE_FAILED_FROM_EXTERNAL_LINK: {
      return {
        ...state,
        unsubscriptionSuccessed: false,
      };
    }

    case ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_FROM_EXTERNAL_LINK: {
      const { secret, predefinedReasons, customReason } = action.payload;
      const newsletterSubscriptionKeys = atob(secret).split(',');

      return loop(
        { ...state },
        Cmd.run(newsletterSetUnsubscribeReasonsCmd, {
          args: [newsletterSubscriptionKeys, predefinedReasons, customReason],
          successActionCreator: () => ACTIONS.newsletterSetUnsubscribeReasonSucceeded(),
          failActionCreator: (error) => ACTIONS.newsletterSetUnsubscribeReasonFailed(error),
        })
      );
    }

    case ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_SUCCEEDED_FROM_EXTERNAL_LINK: {
      return { ...state, feedbackSavedSuccessfully: true, showUnsubscriptionFeedbackForm: false };
    }

    case ACTION_TYPES.NEWSLETTER_SET_UNSUBSCRIBE_REASON_FAILED_FROM_EXTERNAL_LINK: {
      return { ...state, feedbackSavedSuccessfully: false, showUnsubscriptionFeedbackForm: false };
    }

    default:
      return state;
  }
};
