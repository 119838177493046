export class MyEditBiography {
  constructor() {
    this.biographyIcon = require('Img/books-stack-of-three.svg');
  }

  $onInit() {
    this.isNew = !this.resolve.biography;
    this.biography = this.resolve.biography;
  }

  save() {
    this.close({ $value: this.biography });
  }

  cancel() {
    this.dismiss({ $value: 'cancel' });
  }
}
