import he from 'he';

const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const getNewsletterPreferenceModel = (state) => {
  const thematicNewsletterModel = state.newsletters.thematicNewsletterPreferences
    ? {
        ...state.newsletters.thematicNewsletterPreferences,
        positions: state.newsletters.thematicNewsletterPreferences.positions,
        themes: state.newsletters.thematicNewsletterPreferences.themes,
        coverage: state.newsletters.thematicNewsletterPreferences.coverage,
        educationLevels: state.newsletters.thematicNewsletterPreferences.educationLevels,
      }
    : {};
  const subjectsNewsletterModel = state.newsletters.subjectsNewsletterPreferences
    ? {
        ...state.newsletters.subjectsNewsletterPreferences,
        positions: state.newsletters.subjectsNewsletterPreferences.positions,
        subjects: state.newsletters.subjectsNewsletterPreferences.subjects
          .map((subject) => {
            const subjectObj = state.newsletters.subjectsMap[subject.href];
            if (!subjectObj) {
              console.error(
                `subject href ${subject.href} is deleted!!! Run script to remove/replace dependencies in /newsletter/preferences api.`
              );
            }
            const name = subjectObj.name || subjectObj.title;
            const type = subjectObj.type || subjectObj.$$meta.type;

            return {
              ...subject,
              key: subjectObj.key,
              name: he.decode(`${name.charAt(0).toUpperCase() + name.slice(1)}`),
              type,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name)),
        coverage: state.newsletters.subjectsNewsletterPreferences.coverage,
        educationLevels: state.newsletters.subjectsNewsletterPreferences.educationLevels,
        hasSelectedSubjects: state.newsletters.subjectsNewsletterPreferences.subjects.some(
          (subject) => subject.checked === true
        ),
      }
    : {};

  const subscribed =
    thematicNewsletterModel || subjectsNewsletterModel
      ? thematicNewsletterModel.subscribed || subjectsNewsletterModel.subscribed
      : false;

  return {
    thematicNewsletterPreferences: thematicNewsletterModel,
    subjectsNewsletterPreferences: {
      ...subjectsNewsletterModel,
    },
    subscribed,
    userEmail: state.userState && state.userState.user ? state.userState.user.$$email : null,
    unsubscribeReasons: state.newsletters.unsubscribeReasons,
    loading: state.newsletters.loading,
  };
};
