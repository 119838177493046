import { generateUUID } from '@kathondvla/sri-client/common-utils';
import { getNonAbolishedResources, getNow } from '@kathondvla/sri-client/date-utils';
import { sriClient } from 'ReduxLoop/utils/apiConfig';
import { settings } from 'Js/config/settings';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

export const getProfileInfo = async (personKey) => {
  const person = await sriClient.get(`/persons/${personKey}`);
  const contactDetails = await sriClient.getAll('/contactdetails', {
    persons: person.$$meta.permalink,
  });
  const bankAccounts = await sriClient.getAll('/bankaccounts', {
    persons: person.$$meta.permalink,
  });
  return {
    person,
    contactDetails,
    bankAccounts,
  };
};

export const refreshResponsibilitiesAndRequests = async (personHref) => {
  const responsibilities = await sriClient.getAll('/responsibilities', {
    persons: personHref,
    expand: 'FULL',
    expandRelationsTo: 'SUMMARY',
    expandPosition: 'NONE',
  });
  const nonAbolishedResponsibilities = getNonAbolishedResources(responsibilities);
  const requests = await sriClient.getAll('/responsibilities/pending', {
    type: 'REQUEST',
    status: 'PENDING',
    from: personHref,
  });
  return {
    responsibilities: nonAbolishedResponsibilities,
    requests,
  };
};

export const getProfileTeamInfo = async (personHref) => {
  const responsibilities = await sriClient.getAll('/responsibilities', {
    persons: personHref,
    expand: 'FULL',
    expandRelationsTo: 'SUMMARY',
    expandPosition: 'NONE',
  });
  const nonAbolishedResponsibilities = getNonAbolishedResources(responsibilities);

  const samHrefs = Array.from(
    new Set(
      nonAbolishedResponsibilities
        .filter((resp) => resp.organisation.href.match(/^\/sam\//g))
        .map((resp) => resp.organisation.href)
    )
  );
  const samOus = await sriClient.getAllHrefs(samHrefs);

  const teacherGroupHrefs = [];
  nonAbolishedResponsibilities
    .filter((resp) => resp.organisation.href.match(/^\/sam\//g))
    .forEach((resp) => {
      const ou = samOus.filter((thisOu) => thisOu.$$meta.permalink === resp.organisation.href)[0];
      if (ou.type === 'TEACHER_GROUP') {
        teacherGroupHrefs.push(ou.$$meta.permalink);
      }
    });
  const parentRelations =
    teacherGroupHrefs.length === 0
      ? []
      : await sriClient.getAll('/sam/organisationalunits/relations', {
          type: 'IS_PART_OF',
          from: teacherGroupHrefs,
        });

  const campuses =
    samHrefs.length === 0
      ? []
      : await sriClient.getAll('/sam/organisationalunits/locations', {
          organisationalUnit: samHrefs,
          expand: 'results.physicalLocation',
          endDateAfter: getNow(),
        });

  const vosHrefs = Array.from(
    new Set(
      nonAbolishedResponsibilities
        .filter((resp) => !resp.organisation.href.match(/^\/sam\//g))
        .map((resp) => resp.organisation.href)
    )
  );
  const vosOus =
    vosHrefs.length === 0
      ? []
      : await sriClient.getAll('/organisationalunits', { hrefs: vosHrefs.join(',') });

  return {
    responsibilities: nonAbolishedResponsibilities,
    samOus,
    vosOus,
    groupParentRelations: parentRelations,
    campuses,
  };
};

export const getPendingRequests = async (personHref, existingSamOus, existingVosOus) => {
  const requests = await sriClient.getAll('/responsibilities/pending', {
    type: 'REQUEST',
    status: 'PENDING',
    from: personHref,
  });

  const samHrefs = Array.from(
    new Set(
      requests
        .filter(
          (resp) =>
            resp.organisationalUnit.href.match(/^\/sam\//g) &&
            !existingSamOus[resp.organisationalUnit.href]
        )
        .map((resp) => resp.organisationalUnit.href)
    )
  );
  const samOus = await sriClient.getAllHrefs(samHrefs);

  const campuses =
    samHrefs.length === 0
      ? []
      : await sriClient.getAll('/sam/organisationalunits/locations', {
          organisationalUnit: samHrefs,
          expand: 'results.physicalLocation',
          endDateAfter: getNow(),
        });

  const vosHrefs = Array.from(
    new Set(
      requests
        .filter(
          (resp) =>
            !resp.organisationalUnit.href.match(/^\/sam\//g) &&
            !existingVosOus[resp.organisationalUnit.href]
        )
        .map((resp) => resp.organisationalUnit.href)
    )
  );
  const vosOus =
    vosHrefs.length === 0
      ? []
      : await sriClient.getAll('/organisationalunits', { hrefs: vosHrefs.join(',') });

  return {
    requests,
    samOus,
    vosOus,
    campuses,
  };
};

export const getPerson = (personHref) => {
  return sriClient.get(personHref);
};

export const getContactDetails = (personHref) => {
  return sriClient.getAll('/contactdetails', { persons: personHref });
};

export const getBankAccounts = (personHref) => {
  return sriClient.getAll('/bankaccounts', { persons: personHref });
};

export const getAccountInfo = async (personHref) => {
  const accounts = await sriClient.getAll('/accounts', { persons: personHref });
  return accounts[0];
};

export const getKathOndvlaRespInfo = async (resps, ous) => {
  const diensten = [];

  Object.values(resps).forEach((resp) => {
    const ou = ous[resp.organisation.href];
    if (ou.$$meta.type === 'ORGANISATION' && ou.type === 'DIENST') {
      diensten.push({
        resp,
        ou,
      });
    }
  });

  const dienstenHrefs = Array.from(new Set(diensten.map((dienst) => dienst.ou.$$meta.permalink)));
  const parentRelations = await sriClient.getAllReferencesTo(
    '/organisationalunits/relations',
    {
      recurseDepth: '*',
      types: 'IS_PART_OF',
      statuses: 'ACTIVE',
      limit: null,
    },
    'froms',
    dienstenHrefs
  );
  const parentHrefs = Array.from(new Set(parentRelations.map((rel) => rel.to.href)));
  const parentOus = await sriClient.getAllHrefs(parentHrefs);

  return {
    parentRelations,
    parentOus,
  };
};

export const hasKathOndVlaMailbox = (personHref) => {
  return sriClient.get('/security/query/allowed', {
    ability: 'mailbox',
    component: '/security/components/sharepoint',
    person: personHref,
  });
};

const getUploadJson = (resolution, personHref, name) => {
  const key = generateUUID();
  const body = {
    file: `${name}-${key.split('-')[0]}.jpg`,
    attachment: {
      key,
      type: 'PROFILE_IMAGE',
      name: `${name}.jpg`,
      width: resolution,
      height: resolution,
    },
    resource: {
      href: personHref,
    },
  };

  return body;
};

const appendImage = async (dataForm, image, fileName) => {
  const imgFetch = await fetch(image);
  const blob = await imgFetch.blob();
  dataForm.append('data', blob, fileName);
};

const scaleImage = async (image, width, height) => {
  return new Promise(function (resolve) {
    const img = new Image();
    function scale() {
      if (this.width < width || this.height < height) {
        if (width <= 256 || height <= 256) {
          console.warn(
            `WARNING_SMALL_PROFILE_PICTURE: the orginal image has only a resolution of ${this.width}x${this.height} so we will have to scale it up to 256 pixels`
          );
        } else {
          resolve();
        }
      }
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(this, 0, 0, width, height);
      const result = canvas.toDataURL('image/jpeg', 0.6);
      resolve(result);
    }

    img.onload = scale;
    img.src = image;
  });
};

const postProfilePictures = async (image, personHref) => {
  const data = new FormData();
  const image128 = await scaleImage(image.original, 128, 128);
  const image256 = await scaleImage(image.original, 256, 256);
  const image1024 = await scaleImage(image.original, 1024, 1024);
  const body = [
    getUploadJson(128, personHref, 'profile-small'),
    getUploadJson(256, personHref, 'profile-medium'),
  ];
  if (image1024) {
    body.push(getUploadJson(1024, personHref, 'profile-large'));
  }
  data.append('body', JSON.stringify(body));
  await appendImage(data, image128, body[0].file);
  await appendImage(data, image256, body[1].file);
  if (image1024) {
    await appendImage(data, image1024, body[2].file);
  }

  const response = await fetch(`${settings.apis.kathOndVla}/persons/attachments`, {
    method: 'POST',
    body: data,
  });
  if (!response.ok) {
    return Promise.reject();
  }
  return Promise.resolve(body[1].attachment.key);
};

const deleteProfilePicture = async (attachmentHref) => {
  const response = await fetch(settings.apis.kathOndVla + attachmentHref, { method: 'DELETE' });
  if (!response.ok) {
    return Promise.reject();
  }
  return Promise.resolve();
};

export const deleteProfilePictures = (attachments) => {
  // TODO just send this in batch with sriClient; but Persons API needs to be able to handle a batch wchich needs to be forwarded.
  const promises = [];
  attachments.forEach((attachment) => {
    if (attachment.$$expanded.type === 'PROFILE_IMAGE') {
      promises.push(deleteProfilePicture(attachment.href));
    }
  });
  return Promise.all(promises);
};

export const replacePersonProfilePictures = async (images, personHref, attachments) => {
  await deleteProfilePictures(attachments);
  return postProfilePictures(images, personHref);
};

export const addProfilePicturesToPerson = async (images, personHref) => {
  return postProfilePictures(images, personHref);
};

let currentPromise = null;
export const debounceProfileChanges = () => {
  if (currentPromise) {
    currentPromise.reject('CONSOLE.LOGENTRIES_IGNORE');
  }
  return new Promise((resolve, reject) => {
    const thisKey = generateUUID();
    currentPromise = {
      key: thisKey,
      reject,
    };
    setTimeout(() => {
      if (currentPromise.key === thisKey) {
        resolve();
        currentPromise = null;
      }
    }, 4000);
  });
};

export const persistProfileChanges = (batch) => {
  return sriClient.post('/persons/batch', batch.array);
};

export const validateUsername = async (person) => {
  try {
    await sriClient.post(`${person.$$meta.permalink}/validate`, person);
  } catch (apiError) {
    let duplicateUsernameError = null;
    if (apiError instanceof SriClientError && apiError.body) {
      apiError.body.errors.forEach((error) => {
        if (error.code === 'duplicate.username') {
          duplicateUsernameError = error;
        }
      });
      if (duplicateUsernameError) {
        throw duplicateUsernameError;
      }
    } else {
      console.error(
        'UNEXPECTED_ERROR: An unexpected error occured when checking username uniqueness',
        apiError
      );
    }
  }
};

export const validateEmailAddress = async (emailAddress) => {
  try {
    await sriClient.post(`${emailAddress.$$meta.permalink}/validate`, emailAddress);
  } catch (apiError) {
    let duplicateError = null;
    if (apiError instanceof SriClientError && apiError.body) {
      apiError.body.errors.forEach((error) => {
        if (error.code === 'non.unique.primary.emailaddress') {
          duplicateError = error;
        }
      });
      if (duplicateError) {
        throw duplicateError;
      }
    } else {
      console.error(
        'UNEXPECTED_ERROR: An unexpected error occured when checking username uniqueness',
        apiError
      );
    }
  }
};

export const sendRemindersForRequestResponsibility = async (pendingRequests) => {
  // const response = await sriClient.put(pendingRequests[0].$$meta.permalink, pendingRequests[0]);
  const batchResp = await sriClient.put('/persons/batch', pendingRequests.map((request) => ({
    href: request.$$meta.permalink,
    verb: 'PUT',
    body: request,
  })));
  const emailSendResponse = batchResp.find((batchObj) => batchObj.body);
  if (emailSendResponse) {
    return emailSendResponse.body;
  }
  return null;
};
